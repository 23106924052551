import { Injectable } from '@angular/core';
import { Headers, RequestOptions, Response, URLSearchParams, Http, ResponseContentType } from '@angular/http';

import { UtilsComponent } from '../utils/utils';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ClientService {
    private dateRangePicker: Date[];
    orders_made: any;
    constructor(private http: HttpClient,
        private utils: UtilsComponent,
        private https: Http,
        private auth: AuthService
    ) { }

    //Variables para manejo de paginacion
    private page: number = 1;
    private itemsPerPage: number = 10;
    private sort: number = 1;
    private order_by: string = 'creation_date';

    //Variables para manejo de busqueda
    freeText: string = "";
    status: string = "";
    active_sessions: string = "";


    private omtUrl = this.utils.getUrlOmtSd();
    private getClientsUrl = this.utils.getUrlOmt() + 'sd';

    /**
     * Metodo encargado de solicitar al web service el listado completo de los 
     * clientes que se han registrado en la aplicacion movil de OMT
     * @param item: number -> Numero de items que se mostraran por paginacion
     * @param cleanSearch: boolean -> Variable para validar cuando se debe poner la pagina en 1
     * @param userLang: string -> Variable para controlar el idioma en que se solicitaran los resultados
     * @param sort: number -> Variable que controla el ordenamiento (Ascendente, Descendente)
     * @param order_by: string -> Variable que filtra ordenamiento (Nombre, email, username, creation_date)
     */
    getClients(items: number, cleanSearch: boolean, userLang: string, sort: number, order_by: string) {
        this.sort = sort;
        this.order_by = order_by

        if (items != 10) {
            this.itemsPerPage = items;
        } else if (items == 10 && cleanSearch) {
            this.itemsPerPage = items;
        }
        if (cleanSearch) {
            this.page = 1;
        }

        let url = `${this.getClientsUrl}/clients`;
        const params = this.__setParamsQueryClients();
        return this.http.get(url, { params: params });
    }

    /**
     * Metodo encargado de solicitar al web service la informacion detallada de un 
     * cliente registrado en la aplicacion movil de OMT
     * @param idClient: number -> Id del cliente a capturar los detalles
     * @param userLang: string -> Variable para controlar el idioma en que se solicitaran los resultados
     */
    getClientDetails(idClient: number, userLang: string) {
        let url = `${this.getClientsUrl}/client/${idClient}`;
        return this.http.get(url);
    }

    private extractDataSettings(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    /**
     * Metodo encargado de manejar las paginas del paginador
     */
    getPageClientsList(page: number, items: number, userLang: string) {
        this.page = page;
        const params = this.__setParamsQueryClients();
        let url = this.getClientsUrl + '/clients';

        return this.http.get(url, { params: params });
    }

    /**
     * Metodo encargado de realizar peticion de busqueda al web service de los restaurantes
     * recibe tres parametros para realizar la busqueda
     * @param: searchData : string -> Parametros para realizar la busqueda
     */
    getSearchClients(searchData: any, userLang: string) {
        this.page = 1;
        this.freeText = searchData.freeText;
        this.status = searchData.status;
        this.active_sessions = searchData.active_sessions;
        this.orders_made = searchData.orders_made;
        this.dateRangePicker = searchData.dateRangePicker;
        const params = this.__setParamsQueryClients();
        let url = this.getClientsUrl + '/clients';

        return this.http.get(url, { params: params });

    }


    /**
     * Metodo encargado de cambiar el estado a un cliente registrado en OMT
     * @param idClient: number -> ID del cliente que se cambiara el estado
     * @param status: number -> Estado que se seteara
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     */
    putChangeStatus(idClient: number, status: number, userLang: string) {
        var arrayData = {
            status: status
        }
        let array = JSON.stringify(arrayData);
        let url = `${this.getClientsUrl}/client/${idClient}/changeStatus`;
        return this.http.put(url, array)

    }

    /**
     * Metodo encargado de cerrar la sesion activa a un cliente registrado en OMT
     * @param idClient: number -> ID del cliente que se cambiara el estado
     * @param sesionId: number -> Estado que se seteara
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     */
    putLogoutClient(idClient: number, sesionId: number, userLang: string) {
        var arrayData = {}

        let array = JSON.stringify(arrayData);

        let url = `${this.getClientsUrl}/client/${idClient}/session/${sesionId}/logout`;
        return this.http.put(url, array)
    }

    private handleError(error: any) {
        return Promise.reject(error.message || error);
    }

    /**
     * Metodo encargado de retornar el valor de las variables de paginación
     * para funcionamiento del mismo en el listado de clientes 
     */
    valuesPagination() {
        return [this.page, this.itemsPerPage]
    }

    /**
     * Metodo encargado de limpiar las variables encargadas del manejo de filtro
     */
    cleanParametersSearch(fromSidebar: boolean) {
        this.freeText = "";
        this.status = "";
        this.active_sessions = "";
        this.sort = 1;
        this.order_by = 'creation_date';

        if (fromSidebar) {
            this.itemsPerPage = 25;
        }
    }

    /**
     * Se definen los parametros para hacer la consulta de clientes
     * 
     */
    private __setParamsQueryClients(isForExcel = false, itemsPerPage: number = 20) {
        let params = new HttpParams();
        // Si es para excel no se necesita enviar estos parametros ya que es necesario
        // que en el excel muestre toda la informacion
        // if (!isForExcel) {
        params = params.append('page', this.page.toString());
        params = params.append('items_per_page', this.itemsPerPage.toString());
        // }
        params = params.append('sort', this.sort.toString());
        params = params.append('order_by', this.order_by);
        if (this.orders_made) {
            params = params.append('hasOrders', this.orders_made);
        }
        if (this.active_sessions) {
            params = params.append('active_sessions', this.active_sessions);
        }
        if (this.status) {
            params = params.append('status', this.status);
        }
        if (this.freeText) {
            params = params.append('free_text', this.freeText);
        }
        if (this.dateRangePicker && this.dateRangePicker.length) {
            params = params.append('start_date', moment(this.dateRangePicker[0]).format('YYYY/MM/DD'));
            params = params.append('end_date', moment(this.dateRangePicker[1]).format('YYYY/MM/DD'));
        }
       
        return params;
    }

    /**
     * Obtiene el reporte de clientes
     */
    public getExcelReport(itemsPerPage: number = 20) {
        const paramsQuery = this.__setParamsQueryClients(true, itemsPerPage);
        const url = `${this.getClientsUrl}/download/excel/clients?${paramsQuery}`;

        const headers = {
            'Api-Key': this.auth.getToken()
        }
        const options = new RequestOptions({
            responseType: ResponseContentType.Blob,
            headers: new Headers(headers)
        });

        return this.https.get(url, options);

    }

}
