
<h1 class="page-title" *ngIf="allReviews">
  {{ 'Reviews' | translate }} 
</h1>
<div class="row">
  <div class="col-lg-12 col-xs-12">
    <section widget class="widget">
      <header>
        <h4>
          {{ 'Filters' | translate }}
        </h4>
      </header>
      <div class="widget-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-sm-2 col-xs-12">
                <select [(ngModel)]="filterStatus" class="form-control select2">
                  <option value=""> {{ 'ALL' | translate }} </option>
                  <option value="0"> {{ 'Pending' | translate }} </option>
                  <option value="1"> {{ 'PUBLISHED' | translate }} </option>
                  <option value="2"> {{ 'REJECTED' | translate }} </option>
                  <!-- <option id="3" value="false"> {{ 'No' | translate }} </option> -->
                </select>
              </div>
              <div class="col-sm-3 col-xs-12" *ngIf="allReviews">
                <div class="input-group">
                  <input type="text" [(ngModel)]="restaurantToSearch" #searchInput class="form-control" id="inputSearch"
                    placeholder="{{ 'RestaurantToSearch' | translate }}">
                </div>
              </div>
              <div class="col-sm-2 col-xs-12">
                <div class="input-group">
                  <input class="form-control" [(ngModel)]="username" #addressInput id="address" type="text"
                    placeholder="{{ 'User' | translate }}">
                </div>
              </div>
              <div class="col-sm-3 col-xs-12">
                <div class="input-group">
                  <input class="form-control" placeholder="{{ 'DATE_RANGE' | translate }}" bsDaterangepicker
                    #dpr="bsDaterangepicker" [(ngModel)]="dateRangePicker"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-red' }">
                </div>
              </div>
              <div class="col-sm-1 col-xs-12">
                <button [disabled]="buttonStatus" class="btn btn-info mb-xs"
                  (click)="getReviews(filterStatus)">{{ 'Search' | translate }}</button>
              </div>
              <div class=" col-sm-1 col-xs-12 text-right button-info">
                <button (click)="openModalInfo()" [disabled]="buttonStatus"
                    class="btn btn-info mb-xs"><em class="fa fa-info-circle"></em></button>
            </div>
            </div>
            <br>
          </div>
        </div>

      </div>
    </section>
  </div>
</div>

<div class="row">
  <div class="col-lg-12 col-xs-12">
    <section Class="widget">
      <div [ngClass]="{'widget-body' : idRestaurantParam}">
        <div class="form-group row">
          <div class="col-md-8 col-xs-12">
            <p class="text-xs-right">
              <a class="btn btn-gray mb-xs" (click)="goToRestaurant()">
                <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
              </a>
              <button [disabled]="buttonStatus" class="btn btn-success mb-xs" (click)="getReviewsRefresh()">
                <i class="fa fa-refresh"></i> {{ 'REFRESH' | translate }}
              </button>
            </p>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped {{actiClase}}" id="table-restaurant">
            <thead>
              <tr>
                <th>
                  <a style="cursor: pointer;">{{ 'ESTATUS' | translate }}
                    <i class="{{orderIconObject}}"></i>
                  </a>
                </th>
                <th *ngIf="allReviews">
                  <a style="cursor: pointer;">{{ 'Restaurant' | translate }}
                    <i class="{{orderIconObject}}"></i>
                  </a>
                </th>
                <th>
                  <a style="cursor: pointer;">{{ 'Name' | translate }}
                    <i class="{{orderIconObject}}"></i>
                  </a>
                </th>
                <th>
                  <a style="cursor: pointer;">{{ 'REVIEW' | translate }}
                    <i class="{{orderIcon2Object}}"></i>
                  </a>
                </th>
                <th>
                  <a style="cursor: pointer;">{{ 'CREATED' | translate }}
                    <i class="{{orderIcon2Object}}"></i>
                  </a>
                </th>
                <th class="text-center">
                  <a style="cursor: pointer;">{{ 'Status' | translate }}
                    <i class="{{orderIcon2Object}}"></i>
                  </a>
                </th>

                <th class="txtcntr">{{ 'Actions' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of reviews;let i = index">
                <td>
                  <!-- <div class="slideThree">
                    <input class="inputStatus" type="checkbox" value="None" id="slideThree_{{item.id}}" name="check" [checked]="item.status"
                      (click)="changeStatus(item)" />
                    <label for="slideThree_{{item.id}}"></label>
                  </div> -->
                  <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="changeStatus(item, 1)"
                      [ngClass]="{'active' : item.status == 1}">{{ "POST" | translate }}</button>
                    <button type="button" class="btn btn-secondary " (click)="changeStatus(item, 0)"
                      [ngClass]="{'active' : item.status == 0}">{{ "Pending" | translate }}</button>
                    <button type="button" class="btn btn-secondary" (click)="changeStatus(item, 2)"
                      [ngClass]="{'active' : item.status == 2}">{{ "REJECT" | translate }}</button>
                  </div>
                </td>
                <td *ngIf="allReviews">
                  {{item.name}}
                </td>
                <td>
                  {{item.client_name}} {{item.client_lastname}}
                </td>
                <td>
                  <div class="truncate">{{item.comment}}</div>
                </td>
                <td class="text-center" width="200px">
                  <span>{{item.date_create | dateTimeLocalize | async}}</span>
                  <span *ngIf="item.date_update && item.date_create !== item.date_update"
                    class="date">{{ 'UPDATED' | translate }} {{item.date_update | dateTimeLocalize | async}}</span>
                </td>

                <td class="text-center" *ngIf="item.status === 0" style="color: #cc7a00;" (click)="pendingAlert()">
                  <i class="fa fa-clock-o" style="color: #cc7a00;"></i>{{ "Pending" | translate }}
                </td>
                <td class="text-center" *ngIf="item.status === 1" style="color: #33982f">
                  <i class="fa fa-check" style="color: #33982f"></i> {{ "PUBLISHED" | translate }}
                </td>
                <td class="text-center" *ngIf="item.status === 2" style="color: #FF0000;" (click)="pendingAlert()">
                  <i class="fa fa-ban" style="color: #FF0000;"></i> {{ "REJECTED" | translate }}
                </td>

                <td class="width-150 actions" id="ractions-{{item.id}}">
                  <div class="">
                    <button class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}"
                      (click)="showDetails(item, showCategoryModal)">
                      <i class="fa fa-eye text-info"></i>
                    </button>
                    <button class="btn btn-secondary mb-xs" role="button" title="{{ 'Delete' | translate }}"
                      (click)="confirmDelete(i, item,canDeleteModal)">
                      <i class="fa fa-eraser text-danger"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="reviews">
          <div *ngIf="reviews.length <=0">
            <div class="alert alert-info alert-dismissable">
              <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
              <strong>{{'NoDataToShow' | translate }}</strong>
            </div>
          </div>
        </div>
        <!-- Botones Paginación -->
        <div class="form-group row" *ngIf="pagination">
          <div class="col-lg-12 col-md-12 d-flex justify-content-center">
            <label>{{ 'Page' | translate }} {{pagination.currentPage}}</label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
            <pagination class="pagination pagination-md justify-content-end active" [totalItems]="pagination.totalItems"
              [maxSize]="maxSize.maxSizePagination" [itemsPerPage]="pagination.itemsPerPage" [lastText]="lastPag"
              [firstText]="firstPag" [previousText]="prevPag" [nextText]="nextPag"
              (pageChanged)="getReviews(filterStatus, $event)" [boundaryLinks]="true" [rotate]="true">
            </pagination>
          </div>
        </div>
      </div>
    </section>


    <!-- MODAL DE CONFIRMACIÖN DE ELIMINACION -->
    <ng-template #canDeleteModal>
      <div class="modal-header">
        <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
          <i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body bg-gray-lighter">
          <p class="text-xs-center">
            {{ 'WANTDELETEREVIEW' | translate }}
          </p>
        </div>
        <div class="modal-footer">
          <div *ngIf="selectedEntity">
            <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true"
              (click)="modalRef.hide()">
              {{ 'Cancel' | translate }}
            </button>
            <button id="deleteBtn" type="button" class="btn btn-success" (click)="deleteReview()"
              [hidden]="processingResponse">
              {{ 'Confirm' | translate }}
            </button>
            <button class="btn btn-gray flx" [hidden]="!processingResponse"
              [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- Modal para mostrar los detalles de una categoria -->
<ng-template #showCategoryModal>
  <div class="modal-header">
    <h4 class="modal-title text-xs-center fw-bold mt">{{ 'REVIEW' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="detailReview">
      <div class="row">
        <div class="col-sm-12">
          <table class="table table-striped table-hover tablesorter">
            <thead>
            </thead>
            <tbody>
              <tr>
                <th>{{ 'Name' | translate }}</th>
                <td>{{ detailReview.client_name }} {{ detailReview.client_lastname }}</td>
              </tr>
              <tr>
                <th>{{ 'CREATED' | translate }}</th>
                <td>{{ detailReview.date_create }} </td>
              </tr>
              <tr>
                <th>{{ 'UPDATED' | translate }}</th>
                <td>{{ detailReview.date_update }} </td>
              </tr>
              <tr>
                <th>{{ 'Status' | translate }}</th>
                <td *ngIf="detailReview.status === 0" style="color: #cc7a00; cursor: pointer;" (click)="pendingAlert()">
                  <i class="fa fa-clock-o" style="color: #cc7a00;"></i>{{ "Pending" | translate }}
                </td>
                <td *ngIf="detailReview.status === 1" style="color: #33982f">
                  <i class="fa fa-check" style="color: #33982f"></i> {{ "PUBLISHED" | translate }}
                </td>
                <td *ngIf="detailReview.status === 2" style="color: #FF0000; cursor: pointer;" (click)="pendingAlert()">
                  <i class="fa fa-ban" style="color: #FF0000;"></i> {{ "REJECTED" | translate }}
                </td>
              </tr>
              <tr>
                <th>{{ 'REVIEW' | translate }}</th>
                <td>{{ detailReview.comment }} </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</ng-template>
