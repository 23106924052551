// import { RestaurantComponent } from './../../restaurant/restaurant';
import { LocalizeRouterService } from 'localize-router';

import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ClientService } from '../../services/client.service';
import { MailService } from '../../services/mail.service';
import { RestaurantService } from '../../services/restaurant.service';
//import { SlimScroll } from 'ng2-slimscroll/ng2-slimscroll';
import { ConfigService } from '../config';
import { Constants } from 'app/app.constants';

// import { TimerWrapper } from '@angular/core/src/facade/async';
declare var jQuery: any;

@Component({
  selector: '[sidebar]',
  // directives: [
  //   ROUTER_DIRECTIVES,
  //   SlimScroll
  // ],
  templateUrl: './sidebar.html',
  //pipes: [TranslatePipe]
})

export class Sidebar implements OnInit {
  $el: any;
  config: any;
  router: Router;
  location: Location;

  // Variable que recibe respuesta del listado completo de restaurantes
  fullResponse: any;

  // Variable para setear la cantidad de items en la busqueda
  items_per_page: number = 10;

  // Variable para manejo de error
  error: any;

  // Variable para limpiar parametros de busqueda en listado de restaurantes
  cleanSearch: boolean = true;

  // Variable para saber si es usuario administrativo o on
  isAdministrator: boolean = false;

  constructor(config: ConfigService,
    el: ElementRef,
    router: Router,
    location: Location,
    private restaurantService: RestaurantService,
    private mailService: MailService,
    private clientService: ClientService,
    // private restaurantComponent: RestaurantComponent
    private localize: LocalizeRouterService
  ) {
    this.$el = jQuery(el.nativeElement);
    this.config = config.getConfig();
    this.router = router;
    this.location = location;
  }

  initSidebarScroll(): void {
    let $sidebarContent = this.$el.find('.js-sidebar-content');
    if (this.$el.find('.slimScrollDiv').length !== 0) {
      $sidebarContent.slimscroll({
        destroy: true
      });
    }
    $sidebarContent.slimscroll({
      height: window.innerHeight,
      size: '4px'
    });
  }

  changeActiveNavigationItem(location): void {
    let $newActiveLink = this.$el.find('a[href="#' + location.path() + '"]');

    // collapse .collapse only if new and old active links belong to different .collapse
    // if (!$newActiveLink.is('.active > .collapse > li > a')) {
    //   this.$el.find('.active .active').closest('.collapse').collapse('hide');
    // }
    this.$el.find('.sidebar-nav .active').removeClass('active');

    $newActiveLink.closest('li').addClass('active')
      .parents('li').addClass('active');

    // uncollapse parent
    $newActiveLink.closest('.collapse').addClass('in')
      .siblings('a[data-toggle=collapse]').removeClass('collapsed');
  }

  ngAfterViewInit(): void {
    this.changeActiveNavigationItem(this.location);
  }

  ngOnInit(): void {
    jQuery(window).on('sn:resize', this.initSidebarScroll.bind(this));
    this.initSidebarScroll();

    this.router.events.subscribe(() => {
      this.changeActiveNavigationItem(this.location);
    });

    if (parseInt(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.TYPE)) == 1) {
      this.isAdministrator = true;
    }
  }

  /**
   * Metodo encargado de realizar la petición del listado completo de restaurantes
   * y de los items para realizar la paginación
   */
  getAllRestaurants() {
    this.restaurantService
      .getAllRestaurants(this.items_per_page, this.cleanSearch)
      .subscribe(fullResponse => {
        this.fullResponse = fullResponse;
      }, error => {
        this.error = error;
        console.info("ERROR: ", error);
      });
  }

  gotoRestaurants() {
    let fromSidebar: boolean = true;
    this.restaurantService.cleanParametersSearch(fromSidebar);

    localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL);

    setTimeout(() => {
      this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }, 400);
  }

  gotoEnableRestaurant() {
    let fromSidebar: boolean = true;
    this.restaurantService.cleanParametersSearch(fromSidebar);
    localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL);

    setTimeout(() => {
      this.router.navigate([this.localize.translateRoute('/app/enableRestaurant')]);
    }, 400);
  }

  gotoClients() {
    let fromSidebar: boolean = true;
    this.clientService.cleanParametersSearch(fromSidebar);

    setTimeout(() => {
      this.router.navigate([this.localize.translateRoute('/app/clients')]);
    }, 400);
  }

  gotoMails() {
    let fromSidebar: boolean = true;
    this.mailService.cleanParametersSearch(fromSidebar);

    setTimeout(() => {
      this.router.navigate([this.localize.translateRoute('/app/mail')]);
    }, 400);
  }

  showReviews() {
    this.router.navigate([this.localize.translateRoute('/app/reviews'), 'allReviews']);
  }

  /**
   * Eniva al componente de ordenes
   */
  public showOrders(): void {
    this.router.navigate([this.localize.translateRoute('/app/orders'), 'all']);
  }
  /**
   * Eniva al componente de cupones
   */
  public showCoupons(): void {
    this.router.navigate([this.localize.translateRoute('/app/coupons'), 'all']);
  }
  public showReports(): void {
    this.router.navigate([this.localize.translateRoute('/app/reports'), 'all']);
  }
}
