export class restaurant {
  createdAt: string;
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
  address: string;
  publicAddress: string;
  suitNumber: string;
  city: string;
  state: string;
  country: string;
  zipCode: number;
  latitude: string;
  longitude: string;
  contactPerson: string;
  website: string;
  openingDates: Array<Object>;
  urlMenu: string;
  street: string;
  route: string;
  is_verified: boolean;
  delivery_range: number;
  delivery_charge: number;
  deliveryRanges: Array<object>;
  phones: any;
  status: number = 5;
  availableCoupons: boolean;
  availableDelivery: boolean;
  availableDineIn: boolean;
  availablePickUp: boolean;
  multiplePrice: {
    brand: Array<any>;
    servicesType: Array<any>
  };
}
