<h1 class="page-title" *ngIf="allCoupons">
  {{ 'COUPONS' | translate }}
</h1>
<div class="row">
  <div class="col-lg-12 col-xs-12">
    <section Class="widget">
      <div class="form-group row" *ngIf="!allCoupons">
        <div class="col-md-8 col-xs-12">
          <p class="text-xs-right">
            <a class="btn btn-gray mb-xs" (click)="goToRestaurantList()">
              <em class="fa fa-arrow-left"></em> {{ 'Back' | translate }}
            </a>
          </p>
        </div>
      </div>
      <ngb-tabset #ngbtabset>
        <ngb-tab title="{{ 'RECLAIM' | translate }}" id="tab-selectbyid1">
          <ng-template ngbTabContent>
            <app-list-coupons></app-list-coupons>
          </ng-template>
        </ngb-tab>
        <!-- SE OBTIENE LAS ORDENES EN TODOS LOS ESTADOS -->
        <ngb-tab title="{{ 'REDEEM' | translate }}" id="tab-selectbyid2">
          <ng-template ngbTabContent>
            <app-list-coupons></app-list-coupons>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </section>
  </div>
</div>
