import { Injectable } from '@angular/core';

import { UserService } from '../services/user.service';
import { Constants } from 'app/app.constants';

@Injectable()
export class PermissionsUserService {

    constructor(private userService: UserService) { }

    /**
     * Metodo encargado de verificar los permisos que un usuario tiene sobre las secciones
     * de OMT
     * @param idModule: any -> Id del modulo al que se consultaran los permisos habilitados para el usuario
     */
    verifyPermissionsUser(idModule: any) {
        let canShowSection: boolean = false;
        let permissions = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.ENABLED_MODULES));
        for (var i = 0; i < permissions.length; i++) {
            if (permissions[i].module == idModule) {
                canShowSection = true;
            }
        }

        return canShowSection;
    }

    /**
     * Metodo encargado de mostrar u ocultar los elementos del sidebar, esta verificacion se realiza
     * en todos los componentes de las secciones principales
     */
    showHideButtonsSidebarByComponent() {
        let permissions = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.ENABLED_MODULES));
        for (var i = 0; i < permissions.length; i++) {
            this.verifyPermissionsFromEachComponent(permissions[i]['module']);
        }
    }

    verifyPermissionsFromEachComponent(idModule: any) {
        if (idModule == 1) {
            //jQuery('.restaurantList').show();
        }

        if (idModule == 22) {
            //jQuery('.userList').show();
        }

        if (idModule == 14) {
            //jQuery('.clientsList').show();
        }

        if (idModule == 11) {
            //jQuery('.mailsList').show();
        }

        if (idModule == 18) {
            //jQuery('.legalList').show();
        }

        if (idModule == 28) {
            //jQuery('.settingList').show();
        }

    }

    /** 
   * Metodo encargado de solicitar al web service el listado completo de los permisos que un usuario 
   * tiene sobre los modulos existentes en OMT 
   * @param idUser:number -> Id del usuario al que se solicitara el listado de permisos  
   */
    getPermissionsUser(idUser: string, userLang: string) {
        let newPermissions: Array<Object> = [];
        let permissions: any;
        let error: any;
        this.userService
            .getPermissionsUser(userLang, idUser)
            .then(userPermissions => {
                permissions = userPermissions;
                //Se recorre el array de Parent para capturar los permisos
                for (var i = 0; i < permissions.length; i++) {

                    //Validacion para mostrar u ocultar botones en el sidebar
                    this.showHideButtonsSideBar(permissions[i].permissions, permissions[i].id)

                    //Se valida que la seccion tenga permisos autorizados
                    if (permissions[i].permissions == true) {
                        //Se guardan las secciones que tienen permisos
                        newPermissions.push({
                            module: permissions[i].id,
                            Module: {
                                id: permissions[i].id,
                                name: permissions[i].name,
                                slug: permissions[i].slug
                            }
                        });
                        //Se recorre el array de children para capturar los permisos
                        for (var j = 0; j < permissions[i]['children'].length; j++) {
                            //Se valida que la seccion tenga permisos autorizados
                            if (permissions[i]['children'][j].permissions == true) {
                                //Se guardan las secciones que tienen permisos
                                newPermissions.push({
                                    module: permissions[i]['children'][j].id,
                                    Module: {
                                        id: permissions[i]['children'][j].id,
                                        name: permissions[i]['children'][j].name,
                                        slug: permissions[i]['children'][j].slug
                                    }
                                });
                            } else {
                                continue;
                            }
                        }
                    } else {
                        continue;
                    }
                }
                localStorage.setItem(Constants.LOCAL_STORAGE_DATA.ENABLED_MODULES, JSON.stringify(newPermissions));
            })
            .catch(error => error = error);
    }

    /**
     * Metodo encargado de habilitar o desahibilitar los botones del sidebar dependiendo de los
     * permisos que tenga autorizados el usuario
     */
    showHideButtonsSideBar(permissions: boolean, idModule: number) {
        if (idModule == 1 && permissions == true) {
            //jQuery('.restaurantList').show();
        } else if (idModule == 1 && permissions == false) {
            //jQuery('.restaurantList').hide();
        }

        if (idModule == 22 && permissions == true) {
            //jQuery('.userList').show();
        } else if (idModule == 22 && permissions == false) {
            //jQuery('.userList').hide();
        }

        if (idModule == 14 && permissions == true) {
            //jQuery('.clientsList').show();
        } else if (idModule == 14 && permissions == false) {
            //jQuery('.clientsList').hide();
        }

        if (idModule == 11 && permissions == true) {
            //jQuery('.mailsList').show();
        } else if (idModule == 11 && permissions == false) {
            //jQuery('.mailsList').hide();
        }

        if (idModule == 18 && permissions == true) {
            //jQuery('.legalList').show();
        } else if (idModule == 18 && permissions == false) {
            //jQuery('.legalList').hide();
        }

        if (idModule == 28 && permissions == true) {
            //jQuery('.settingList').show();
        } else if (idModule == 28 && permissions == false) {
            //jQuery('.settingList').hide();
        }

    }

}