//import { TimerWrapper } from '@angular/core/src/facade/async';
import { NotificationsService } from 'angular2-notifications';
import { LocalizeRouterService } from 'localize-router';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';

import {
    Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
//import { AlertComponent } from 'ng2-bootstrap/components/alert';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { categoryFood } from '../../entity/categoryFood';
import { menu } from '../../entity/menu';
import { menuCategory } from '../../entity/menuCategory';
import { menuItem } from '../../entity/menuItem';
import { restaurant } from '../../entity/restaurant';
import { CategoryService } from '../../services/category.service';
import { MenuesService } from '../../services/menues.service';
import { MenuProductsService } from '../../services/menuProducts.service';
import { PermissionsUserService } from '../../services/permissionsUser.service';
import { RestaurantService } from '../../services/restaurant.service';
import { UtilsComponent } from '../../utils/utils';
import { Constants } from 'app/app.constants';

//Manejo de modal para agregar/editar categoría
declare var jQuery: any;

@Component({
    selector: 'category',
    templateUrl: './menuItem.html',
    // directives: [Widget,
    //   ROUTER_DIRECTIVES,
    //   CategoryModalComponent,
    //   NewMenuItemModalComponent,
    //   AlertComponent,
    //   SimpleNotificationsComponent,
    //   REACTIVE_FORM_DIRECTIVES,
    //   InspectPermitsComponent
    // ],
    // providers: [NotificationsService],
    // pipes: [TranslatePipe, SearchPipe]
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }

//   return true;
// })
export class MenuItemComponent implements OnInit {
    @ViewChild('addEditCategoryModal') public addEditCategoryModal: ModalDirective;
    public modalRef: BsModalRef;
    constructor(
        // private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        //private routeParams: RouteParams,
        private categoryService: CategoryService,
        private menuProductsService: MenuProductsService,
        private restaurantService: RestaurantService,
        private menuesService: MenuesService,
        private _fb: FormBuilder,
        private utils: UtilsComponent,
        private _service: NotificationsService,
        private permissionsUserService: PermissionsUserService,
        private modalService: BsModalService,
        private translate: TranslateService,
        private localize: LocalizeRouterService,
        private toastr: ToastrService
    ) { }

    canEdit: boolean = false;
    category: categoryFood[];
    menuItem: menuItem[];
    menu: menu[];
    selectedCategory: categoryFood;
    error: any;
    navigated: boolean = false;
    @Input() restaurant: restaurant;
    @Input() categoryFood: categoryFood;
    restaurants: restaurant[];
    public categoryForm: FormGroup;
    submitted: boolean = false;
    errorMessage: string;
    errorSize: boolean = false;
    menuFullData: any;
    menuCategories: any;

    //Variable para manejo de lenguaje
    userLang: string;

    //Variable id del restaurante
    idRestaurant: number;
    categories: menuCategory[];
    @Input() menuCategory: menuCategory;
    @Input() categoryCreated: any;
    @Output() close = new EventEmitter();

    idMenu: number;
    searchText: any = '';
    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Mensaje de Restaurante creado exitosamente
    public title: string = 'Category';
    public contentDeleted: string = "Category deleted successfully";
    public content: string = "";

    //Reset formulario
    @Input() isResetting = false;


    //Array para agregar nuevos items sizes al momento de crear categoria
    arrayLiSizes: Array<Object> = ['size'];
    numberSize: number = 1;
    strSize: string = "";
    existSize: boolean = true;

    /**
    * Variable que controla si se muestra o no la seccion
    */
    canShowSection: boolean = false;
    canViewMenu: boolean = false;
    canEditMenu: boolean = false;


    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));

    /**
     * Variable con identificador de la seccion menu
     */
    idSectionMenu: any = this.allModules['module_restaurant_menu'];
    viewMenu: any = this.allModules['module_view_restaurant_menu'];
    editMenu: any = this.allModules['module_edit_restaurant_menu'];

    /*
      Metodo encargado de hacer peticion a web service y 
      capturar de las categorias
    */

    /*getCategory() {
      this.categoryService
        .getCategories()
        .subscribe(data => {
          this.category = data
        },
        error => {
          this.error = error
        });
      //.then(category => this.category = category)
      //.catch(error => this.error = error);
    }*/

    getMainMenu() {
        this.menuesService
            .getMainMenu(this.idRestaurant)
            .then(menu => {
                this.menu = menu;
            })
            .catch(error => this.error = error);
    }

    /*
      Metodo encargado de hacer peticion a web service y 
      capturar de las categorias
    */
    getAllCategories() {
        this.categoryService
            .getAllCategories(this.idRestaurant, this.idMenu)
            .subscribe(response => {
                this.categories = response['data'];
            }, error => {
                console.info("ERROR: ", error)
            });

        /*
          .then(category => {
            this.categories = category;
            console.info("CATEGORIES: ",category);
          })
          .catch(error => this.error = error);
        */
    }

    /**
     * Metodo encargado de solicitar al web service el menu del restaurate
     * Este contiene toda la informacion del menu todas las categorias y todos los productos que
     * estos contienen
     */
    getMenu() {
        this.menuesService
            .getMenu(this.idRestaurant, this.userLang)
            .then(menu => {
                this.menuFullData = menu;
                setTimeout(() => {
                    this.menuCategories = this.menuFullData.categories;
                }, 900);
            })
    }

    /*
      Metodo encargado de hacer peticion a web service y 
      capturar de las productos
    */
    getMenuItems() {
        this.menuProductsService
            .getMenuItems()
            .subscribe(menuItem => {
                menuItem = menuItem;
            }, error => {
                console.info("ERROR: ", error)
            });
    }

    goBack(restaurant: restaurant = null) {
        this.close.emit(restaurant);
        window.history.back();
    }

    gotoRestaurant() {
        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }

    putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }

    ngOnInit() {

        console.info(this.allModules);
        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionMenu);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canViewMenu = this.permissionsUserService.verifyPermissionsUser(this.viewMenu);
                this.canEditMenu = this.permissionsUserService.verifyPermissionsUser(this.editMenu);

                this.putClassActive();
                this.route.params.subscribe(params => this.idRestaurant = params.id);
                this.route.params.subscribe(params => this.idMenu = params.idMenu);


                this.navigated = true;
                this.restaurantService.getDetailRestaurant(this.idRestaurant, this.userLang)
                    .subscribe(restaurant => {
                        this.restaurants = restaurant['data'];
                    }, error => {
                        this.error = error;
                        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
                    });

                this.userLang = navigator.language.split('-')[0];
                // this.getCategory();
                // this.getMenuItems();
                this.getAllCategories();
                // this.getMenu();
            } else {
                jQuery('#permissionsDeniedModal').modal('show');

            }
        }, 100);

    }

    /*
      Metodo encargado de mostrar modal y manejar
      variable que sabe si se debe editar o agregar categoria
    */
    public addEditCategory(category: categoryFood): void {
        if (category != null) {
            this.selectedCategory = new categoryFood;
            // setTimeout(() => {      
            this.selectedCategory = category;
            this.canEdit = true;
            // }, 400);
        } else {
            this.canEdit = false;
            this.selectedCategory = new categoryFood;
        }
        this.addEditCategoryModal.show();
    }

    /**
     * Metodo que se encarga de capturar la informacion que esta en los inputs size 
     * al momento de crear o editar una categoria
     */
    captureInfoSize(typeAction: string) {
        let sizesChanges = [];
        let divSizes = document.getElementById(typeAction);
        let itemsSizes = divSizes.getElementsByTagName('li');

        for (var i = 0; i < itemsSizes.length; i++) {
            let inputSize = itemsSizes[i].getElementsByTagName('input');
            if (jQuery('#' + inputSize[0].id).val() != '') {
                sizesChanges.push(jQuery('#' + inputSize[0].id).val());
            }
        }

        for (var j = 0; j < sizesChanges.length; j++) {
            if (this.strSize == "") {
                this.strSize = '{"' + sizesChanges[j] + '":"' + sizesChanges[j] + '"';
            } else {
                this.strSize = this.strSize + ', "' + sizesChanges[j] + '":"' + sizesChanges[j] + '"';
            }
        }
        return this.strSize;
    }

    /**
     *Metodo encargado de enviar datos al webservice
     *Agredar o Editar categoria 
     */
    onSubmitCategory(category: any, isValid: boolean) {
        this.submitted = true;
        this.menuCategory = category;

        this.content = this.utils.defineContent(this.menuCategory, this.userLang, 'addEdit');
        if (this.userLang == 'es') {
            this.title = 'Categoría';
        }

        this.strSize = this.captureInfoSize('divSizesNew');

        if (this.menuCategory.id) {
            if (document.getElementById('divSizes')) {
                this.strSize = this.captureInfoSize('divSizes')
            }
        }
        this.strSize = this.strSize + '}';

        if (this.strSize.length <= 3) {
            this.strSize = '{"Regular":"Regular"}';
        }

        jQuery('#saveCategoryBtn').prop('disabled', true);

        this.categoryService
            .save(this.menuCategory, this.strSize, this.idRestaurant, this.idMenu)
            .subscribe(success => {
                this.menuCategory = success;
                setTimeout(() => {
                    if (this.validaError(this.menuCategory)) {
                        this.resetForm();
                        this.getAllCategories();
                        this.closeModal();
                    }
                }, 500);
            },
                error => {
                    console.log('ERROR: ', error);
                });
    }

    /**
     * Metodo encargado de eliminar inputs de size cuando se esta editando una categoria
     */
    removeItemSize(inputSize: any, liSize: any) {
        jQuery('#' + inputSize).val('');
        jQuery('#' + liSize).remove();
    }

    goBackCategory(menuCategory: menuCategory = null) {
        this.close.emit(menuCategory);
        this.getAllCategories();
        this.resetForm();
        jQuery('#addEditCategoryModal').modal('hide');
    }

    /**
     * Metodo encargado de cerrar el modal y limpiar el formulario de evio de datos
     */
    closeModal() {
        this.resetForm();
        this.selectedCategory = new categoryFood;
        this.canEdit = false;
        this.addEditCategoryModal.hide();
        this.errorSize = false;
    }

    resetForm() {
        jQuery('#saveCategoryBtn').prop('disabled', true);
        this.createForm();
        this.arrayLiSizes = ['size'];
        this.numberSize = 1;
        this.strSize = '';
        this.isResetting = true;
        setTimeout(() => {
            this.isResetting = false;
        }, 1);
        return false;
    }


    /**
     * Metodo encargado de devolver los keys del objeto form.size
     * para generar nuevos input de Size
     */
    objToArray(object: any) {
        if (typeof object === 'string') {
            return Object.keys(JSON.parse(object));
        }
        return Object.keys(object);
    }

    /**
     * Metodo encargado de devolver los valores de la categoría seleccionada
     */
    keysCategory(object: any) {
        if (typeof object === 'string') {
            return Object.keys(JSON.parse(object));
        }
        return Object.keys(object);
    }

    /**
     * Metodo encargado de crear la estructura del formulario que se 
     * utilizará para manejo de los datos
     */
    createForm() {
        this.categoryForm = new FormGroup({
            id: new FormControl(null),
            name: new FormControl(null, [<any>Validators.required]),
            size: this._fb.group({
                size: this._fb.group({
                    nameSize: this._fb.control(null)
                })
            })
        });
    }

    gotoMenuProducts(category: categoryFood) {
        let restaurant: any = this.restaurants;
        this.router.navigate([this.localize.translateRoute('/app/restaurant'), restaurant.id, 'menu', this.idMenu, 'category', category.id, 'products']);
    }

    /**
     * Metodo encargado de eliminar una categoria
     * @param: category: menuCategory -> Registro que se quiere eliminar 
     */
    deleteCategory(category: menuCategory, event: any) {
        this.content = this.utils.defineContent(this.menuCategory, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Categoría';
        }

        jQuery('#deleteCategoryBtn').prop('disabled', true);
        event.stopPropagation();
        this.categoryService
            .delete(category, this.idRestaurant, this.idMenu)
            .subscribe(success => {
                this.categoryCreated = success;
                this._service.success(this.title, this.content, { id: 123 });
                setTimeout(() => {
                    this.getAllCategories();
                    jQuery('#deleteCategoryBtn').prop('disabled', false);
                    this.modalRef.hide();
                    // jQuery('#canDeleteModal').modal('hide');
                }, 1000);
            }, error => {
                this.error = error
            });
    }

    /**
     * Metodo encargado de adicionar un nuevo input size cuando se esta creando una categoria
     */
    addArrayLiSize(arrayLiSize: any) {

        let divSizes = document.getElementById('divSizesNew');
        let itemsSizes = divSizes.getElementsByTagName('li');
        let lastItem = itemsSizes.length;
        let inputSize = itemsSizes[lastItem - 1].getElementsByTagName('input');
        let lastItemVal = jQuery('#' + inputSize[0].id).val();
        let canAddInput: boolean = false;

        setTimeout(() => {
            if (lastItemVal) {
                this.numberSize++;
                this.arrayLiSizes.push('size' + this.numberSize);
                canAddInput = true;
            } else {
                this.errorSize = true;
                this.translate.get(["errorMessage"]).subscribe(translations => {
                    this.errorMessage = (translations.errorMessage);
                    setTimeout(() => {
                        this.errorSize = false;
                    }, 4000);
                });
            }
        }, 300);

        setTimeout(() => {
            if (canAddInput) {
                inputSize = itemsSizes[lastItem].getElementsByTagName('input');
                jQuery('#' + inputSize[0].id).val('');
            }
        }, 400);

    }

    /**
     * Metodo encargado de remover un input cuando se esta creando una categoria
     */
    removeItemSizeNew(key: any) {
        if (this.arrayLiSizes.length > 1) {
            for (var i = 0; i < this.arrayLiSizes.length; i++) {
                if (this.arrayLiSizes[i] == key) {
                    this.arrayLiSizes.splice(i, 1);
                }
            }
        }
    }

    /**
     * Metodo encargado de abrir el modal para mostrar la categoria
     */
    public showCategory(category: any, modal) {
        this.modalRef = this.modalService.show(modal);
        this.selectedCategory = category;
    }
    /**
       * Metodo encargado de mostrar mensaje de confirmacion para eliminar una categoria
       */

    public confirmDelete(category: any, template: TemplateRef<any>) {
        this.selectedCategory = category;
        this.modalRef = this.modalService.show(template);
    }

    newSuccess() {
        this.translate.get(["CreatedSuccessfully"]).subscribe(translations => {
            this.toastr.success(translations.CreatedSuccessfully);
        });
    }

    newError() {
        this.translate.get(["ErrorCateg", "ErrorCategT"]).subscribe(translations => {
            this.toastr.warning(translations.ErrorCateg, translations.ErrorCategT);
        });
    }

    //funcion encargada de validar errores al momento de crear una categoria
    validaError(errorf) {
        if (errorf.errors) {
            this.newError();
            jQuery('#saveCategoryBtn').prop('disabled', false);
        } else {
            this.newSuccess();
            return true;
        }
        return false;
    }
}