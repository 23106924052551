import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';

import { menu } from '../entity/menu';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class MenuesService {
    constructor(private http: Http,
        private utils: UtilsComponent
    ) { }

    private omtUrl = this.utils.getUrlOmtSd();
    private getMenuUrl = this.utils.getUrlOmt() + 'restaurant';

    getAllMenues(id: number) {
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');

        let url = `${this.getMenuUrl}/${id}/menues`;
        return this.http.get(url)
            .toPromise()
            .then(this.extractDataMenues)
            .catch(this.handleError);
    }

    getMainMenu(idRestaurant: number) {
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');

        let url = `${this.getMenuUrl}/${idRestaurant}/menu`;
        return this.http.get(url)
            .toPromise()
            .then(this.extractDataMenues)
            .catch(this.handleError);

    }

    getMenu(idRestaurant: number, userLang: string) {
        let headers = new Headers();
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getMenuUrl}/${idRestaurant}/menu`;

        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataMenues)
            .catch(this.handleError);

    }

    private extractDataMenues(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    save(menu: menu, size: any, idRestaurant: number): Promise<menu> {
        if (menu.id) {
            return this.put(menu, size, idRestaurant);
        }
        return this.post(menu, size, idRestaurant);
    }

    delete(menu: menu, idRestaurant: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        let url = `${this.omtUrl}/${idRestaurant}/menu/4/menu/${menu.id}`;

        return this.http
            .delete(url, { headers })
            .toPromise()
            .catch(this.handleError);
    }

    // Add new menu
    private post(menu: menu, size: any, idRestaurant: number): Promise<menu> {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        var arrayData = {
            name: menu.name,
            sizes: size
        }

        let array = JSON.stringify(arrayData);

        let url = `${this.omtUrl}/${idRestaurant}/menu/4/menu`;

        return this.http
            .post(url, array, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    // Update existing menu
    private put(menu: menu, size: string, idRestaurant: number) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        var arrayData = {
            name: menu.name,
            sizes: size
        }

        let array = JSON.stringify(arrayData);

        let url = `${this.omtUrl}/${idRestaurant}/menu/4/menu/${menu.id}`;

        return this.http
            .put(url, array, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}
