<section class="text-xs-right">
    <a class="btn btn-gray mb-xs" (click)="goBack()">
        <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
    </a>
    <hr>

    <div *ngIf="dataUponDelivery" class="form-group panelForm">
        <label>Enable or disable credit card payment</label>
        <div class="slideThree">
            <input class="inputStatus" type="checkbox" [checked]="statusCreditCardPayment"
                (change)="creditCardPayment($event)" id="actNoact1" name="check" />
            <label for="actNoact1"></label>
        </div>
    </div>

    <div *ngIf="dataUponDelivery" class="form-group panelForm">
        <label>{{ 'upon_delivery_act' | translate }}</label>

        <div class="slideThree">
            <input class="inputStatus" type="checkbox" [checked]="statusUponDelivery" (change)="uponDelivery($event)"
                id="actNoact2" name="check" />
            <label for="actNoact2"></label>
        </div>


    </div>
    <div *ngIf="statusUponDelivery">
      <div class="hideForm" [ngClass]="{'showForm': statusUponDelivery }">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <label>{{'SELECT_YOUR_PAYMENT_UPON_DELIVERY_OPTIONS' | translate}}</label>
              </div>
              <div class="panel-body ">
                <div class="checkbox grey-box" >
                  <label><input type="checkbox" value="1" [(ngModel)]="uponDeliveryPaymentTypeCash" [ngModelOptions]="{standalone:
                    true}">{{'cash' | translate }}</label>
                </div>
                <div class="checkbox grey-box">
                  <label><input type="checkbox" value="1" [(ngModel)]="uponDeliveryPaymentTypeCreditCard" [ngModelOptions]="{standalone:
                    true}">{{'credit_card' | translate}}</label>
                </div>
                <div class="checkbox grey-box">
                  <label><input type="checkbox" value="1" [(ngModel)]="uponDeliveryPaymentTypeDebitCard" [ngModelOptions]="{standalone:
                    true}">{{'cardDeb' | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <label>{{'NOTES_UPON_DELIVERY' | translate}}</label>
              </div>
              <div class="panel-body">
                <textarea class="form-control" [(ngModel)]="messageUponDelivery"
                  (input)="messageUponDelivery = $event.target.value"
                  placeholder="{{ 'MESSAGE_UPON_DELIVERY' | translate }}" rows="4"></textarea>
                <br>
                <div class="pull-right">
                  <button id="btnSaveImage" type="button" class="btn btn-success mb-xs" [hidden]="processingResponse"
                    (click)="uponDelivery($event,true)">
                    <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="form-group panelForm" id="ExternalMenu">
        <ng-template #ExternalMenu>

            <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
                    {{ 'USE_FROM' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_MENU_ONLINE"
                    (click)="cancelExternalMenu(typesBrands.BRAND_MENU_ONLINE)" [hidden]="processingResponse">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close pull-right" aria-label="Close"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_WHITE_LABEL"
                    (click)="cancelExternalMenu(typesBrands.BRAND_WHITE_LABEL)" [hidden]="processingResponse">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-xs-center" *ngIf="selectedBrandConfig == typesBrands.BRAND_MENU_ONLINE">
                    {{ 'ASK_FROM' | translate }}
                </p>

                <p class="text-xs-center" *ngIf="selectedBrandConfig == typesBrands.BRAND_WHITE_LABEL">
                    {{ 'WHITE_LABEL' | translate }}
                </p>

                <div class="col-md-6 col-xs-12">
                    <select name="brandSelect" class="form-control" [(ngModel)]="brandSelect">
                        <option value="">{{ 'Select' | translate }}</option>
                        <option *ngFor="let brand of brandsArray | orderBy:'name'" [ngValue]="brand.id">
                            <div>
                                {{brand.name}}
                            </div>
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_MENU_ONLINE"
                    (click)="cancelExternalMenu(typesBrands.BRAND_MENU_ONLINE)" [hidden]="processingResponse">
                    {{ 'Cancel' | translate }}
                </button>
                <button id="acceptExternalMenu" type="button" class="btn btn-success"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_MENU_ONLINE"
                    (click)="activateExternalMenu(typesBrands.BRAND_MENU_ONLINE)" [hidden]="processingResponse">
                    {{ 'Confirm' | translate }}
                </button>
                <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_WHITE_LABEL"
                    (click)="cancelExternalMenu(typesBrands.BRAND_WHITE_LABEL)" [hidden]="processingResponse">
                    {{ 'Cancel' | translate }}
                </button>
                <button id="acceptWhiteLabel" type="button" class="btn btn-success"
                    *ngIf="selectedBrandConfig == typesBrands.BRAND_WHITE_LABEL"
                    (click)="activateExternalMenu(typesBrands.BRAND_WHITE_LABEL)" [hidden]="processingResponse">
                    {{ 'Confirm' | translate }}
                </button>
                <div>
                    <button class="btn btn-gray bont flx" [hidden]="!processingResponse"
                        [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template #ProcessingModal>

            <div class="modal-body">
                <app-loading-image [message]="'processing' | translate"></app-loading-image>
            </div>
        </ng-template>
        <div class="service-type-section">
            <label for="magicForm">{{ 'SELECT_YOUR_OPTIONS' | translate }}</label>
            <div class="checkbox">
                <label><input type="checkbox" value="" [(ngModel)]="restaurant.availableDineIn"
                        (ngModelChange)="privateEditCoupons()" [ngModelOptions]="{standalone:
            true}">{{'TYPE_DINE_IN' | translate }}</label>
            </div>
            <div class="checkbox">
                <label><input type="checkbox" value="" [(ngModel)]="restaurant.availableDelivery"
                        (ngModelChange)="privateEditCoupons()" [ngModelOptions]="{standalone:
            true}">{{'Delivery' | translate}}</label>
                <ul>
                    <li class="panel-default">
                        <div class="panel-heading d-table ml-3">
                            <label>{{'MINIMUN_PURCHASE_DESCRIPTION' | translate}}</label>
                        </div>
                    </li>
                    <li>
                        <input *ngIf="isFromColombia" class="form-control w-25 d-inline m-3"
                            placeholder="{{'MINIMUN_PURCHASE' | translate}}" currencyMask
                            [options]="{ align: 'start', prefix: '$ ', thousands: '.', decimal: ',', precision: 0}"
                            type="text" [(ngModel)]="minimumSeviceValueForDelivery">
                        <input *ngIf="!isFromColombia" class="form-control w-25 d-inline m-3"
                            placeholder="{{'MINIMUN_PURCHASE' | translate}}" currencyMask
                            [options]="{ align: 'start', prefix: '$ ', thousands: '.', decimal: ','}" type="text"
                            [(ngModel)]="minimumSeviceValueForDelivery">
                    </li>
                </ul>

            </div>
            <div class="checkbox">
                <label><input type="checkbox" value="" [(ngModel)]="restaurant.availablePickUp"
                        (ngModelChange)="privateEditCoupons()" [ngModelOptions]="{standalone:
            true}">{{'Pickups' | translate }}
                </label>
            </div>
            <div class="checkbox">

                <label><input type="checkbox" value="" [(ngModel)]="restaurant.availableCoupons"
                        (ngModelChange)="privateEditCoupons()" [ngModelOptions]="{standalone:
            true}">{{'COUPONS' | translate }}
                </label>
            </div>

            <div class="text-right">
                <button id="btnSaveImage" type="button" class="btn btn-success mb-xs" [hidden]="processingResponse"
                    (click)="privateEditCoupons()">
                    <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
                </button>
            </div>
        </div>
        <label for="magicForm">{{ 'activate_menu_ex' | translate }}</label>

        <input class="form-control form-check-inline nomar" type="checkbox" name="one" [(ngModel)]="statusCheck"
            id="defaultCheck1"
            (click)="changeStatusForBrands(ExternalMenu, typesBrands.BRAND_MENU_ONLINE, ProcessingModal)"
            id="magicForm">

        <div class="hideForm" [ngClass]="{'showForm': statusCheck }">
            <div class="row">
                <div class="col-md-6">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <label>Reset Color</label>
                            <input id="colorInput2" type="color" [(ngModel)]="colorCodeBackground" data-type="color" />
                        </div>
                        <div class="panel-body">
                            <div class="previewExternal">
                                <img src="assets/images/preview.jpg" style="filter: grayscale();" alt="open my tab">
                                <div class="rColor1"></div>
                                <div class="rColor2"></div>
                                <div class="rColor3"></div>
                                <div class="rColor4"></div>
                                <div class="rColor5"></div>
                                <div class="rColor6"></div>
                                <div class="rColor7"></div>
                                <div class="rColor8"></div>
                                <div class="rColor9"></div>
                                <div class="rColor10"></div>
                                <div class="rColor11"></div>
                                <div class="rColor12"></div>
                                <div class="rColor13"></div>
                                <div class="rColor14"></div>
                                <div class="rColor15"></div>
                                <div class="rColor16"></div>
                                <div class="rColor17"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <label>{{ 'Color_font' | translate}}</label>

                            <input id="colorInput1" type="color" [(ngModel)]="colorCodeScript" data-type="color" />
                        </div>
                        <div class="panel-body">
                            <div class="fromResetColor" [ngStyle]="sample()">Text Example</div>
                            <hr>
                            <div class="form-group">
                                <label>{{ 'URL_EXT' | translate }}</label>
                                <!-- <input class="form-control" type="text" value="{{url}}" id="copy" /> -->
                                <a class="cien alert alert-success" href="{{url}}" target="_blank">{{url}}</a>
                            </div>
                            <div class="mt-2">
                                <i class="fa fa-link" aria-hidden="true"></i> <label class="ml-2">{{'DOMINIO' | translate}}</label>
                                <!-- <i class="fa fa-apple" aria-hidden="true"></i> <label class="ml-2">App Store</label> -->
                                <input class="success"type="text" placeholder="" class="form-control" [(ngModel)]="domainUrl">
                                <!-- <a class="cien alert alert-success" href="{{domainUrl}}" target="_blank">{{domainUrl}}</a> -->
                                <!-- <a 
                                    class="cien alert alert-success mt-2" href="{{storeLinks.appStore}}"
                                    target="_blank">{{storeLinks.appStore}}</a> -->
                            </div>
                            <br>
                            <div class="form-group">
                                <button id="btnChangeColor" type="button" class="btn btn-success mb-xs"
                                    (click)="changeColor(true)">
                                    <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <label>{{ 'ACTIVATE_WHITE_LABEL' | translate }}</label>
    <input class="form-control form-check-inline nomar" type="checkbox" name="two" [(ngModel)]="statusCheckWhiteLabel"
        id="defaultCheck2"
        (click)="changeStatusForBrands(ExternalMenu, typesBrands.BRAND_WHITE_LABEL, ProcessingModal)">
    <div class="hideForm" [ngClass]="{'showForm': statusCheckWhiteLabel }">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <label>{{'SECRET_API_KEY' | translate}}</label>
                    </div>
                    <div class="panel-body">
                        <input type="text" placeholder="{{'SECRET_API_KEY_PLACEHOLDER' | translate}}"
                            class="form-control" [(ngModel)]="APIKeyPushy">
                    </div>

                    <div class="panel-heading">
                        <label>{{'FACEBOOK_SECRET_KEY' | translate}}</label>
                    </div>

                    <div class="panel-body">
                        <input type="text" placeholder="{{'SECRET_API_KEY_PLACEHOLDER' | translate}}"
                            class="form-control" [(ngModel)]="facebookSecretKey">
                        <br>
                        <div class="form-group">
                            <button id="btnChangeColor" type="button" class="btn btn-success mb-xs"
                                (click)="saveSecretKeys()">
                                <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <label>{{'URL_STORES' | translate}}</label>
                    </div>
                    <div class="panel-body">
                        <div class="mt-2">
                            <i class="fa fa-apple" aria-hidden="true"></i> <label class="ml-2">App Store</label>
                            <input type="text" placeholder="" class="form-control" [(ngModel)]="storeLinks.appStore">
                            <a *ngIf="storeLinks.appStore && storeLinks.appStore.length"
                                class="cien alert alert-success mt-2" href="{{storeLinks.appStore}}"
                                target="_blank">{{storeLinks.appStore}}</a>
                        </div>
                        <div class="mt-2">
                            <i class="fa fa-play" aria-hidden="true"></i> <label class="ml-2">Play Store</label>
                            <input type="text" placeholder="" class="form-control" [(ngModel)]="storeLinks.playStore">
                            <a *ngIf=" storeLinks.playStore && storeLinks.playStore.length"
                                class="cien alert alert-success mt-2" href="{{storeLinks.playStore}}"
                                target="_blank">{{storeLinks.playStore}}</a>
                        </div>
                        <br>
                        <div class="form-group">
                            <button id="btnChangeColor" type="button" class="btn btn-success mb-xs"
                                (click)="saveUrlStores()">
                                <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="form-group panelForm">
        <label>{{ 'TYPE_DINE_IN' | translate }}</label>
        <select class="form-control rad0" [(ngModel)]="dineInTypeId" (ngModelChange)="selectDineInType()"
            [disabled]="!dineInTypeId">
            <option *ngFor="let type of dineInTypes" [ngValue]="type.id"> {{type.type_name}} </option>
        </select>
    </div>

</section>