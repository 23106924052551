// import { Router } from '@angular/router';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
import { NotificationsService } from 'angular2-notifications';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { TimerWrapper } from '@angular/core/src/facade/async';
import { Response } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';

import { user } from '../entity/user';
import { AlertsService } from '../services/alerts.service';
//import { TitleCasePipe } from './../restaurant/pipes/titleCase-pipe';
import { PermissionsUserService } from '../services/permissionsUser.service';
import { UserService } from '../services/user.service';
import { ControlMessagesComponent } from '../utils/control-messages';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

// Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: 'user',
    templateUrl: './user.html',
    // directives: [Widget, SimpleNotificationsComponent,
    //   REACTIVE_FORM_DIRECTIVES, EqualValidator, InspectPermitsComponent],
    providers: [NotificationsService, ControlMessagesComponent],
    // pipes: [TranslatePipe, SearchUserPipe, TitleCasePipe, OrderBy],  
    styles: [('./user.scss')]
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }
//   if (!isAdministrator()) {
//     router.navigate(['/App']);
//     return false;
//   }

//   return true;
// })
export class UserComponent implements OnInit {
    processingResponse: boolean;

    constructor(
        // private modalService: NgbModal,
        // private router: Router,
        // private routeParams: RouteParams,
        private _fb: FormBuilder,
        private utils: UtilsComponent,
        private _service: NotificationsService,
        private permissionsUserService: PermissionsUserService,
        private userService: UserService,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private alertService: AlertsService

    ) { }

    //Variable para buscador
    searchText: string = "";
    roleUser: number;

    //modal
    public modalRef: BsModalRef;

    //Variable con entidad usuario
    users: user[];
    @Input() user: user;
    selectedUser: user;
    userDelete: any;

    //Variable para creacion de formulario
    public userForm: FormGroup;

    //Variable para creacion de formulario
    public userPermissionsForm: FormGroup;
    public restaurantPermissionForm: FormGroup;

    //Variable para saber cuando se va a editar un usario
    canEdit: boolean = false;

    //Variable para editar contraseña
    editPassword: boolean = false;

    //Variable para manejo de idioma
    userLang: string = "";

    validatePassR = false;

    //Variable para manejo de errores al realizar peticiones a los webservices
    error: any;

    //Variable para manejo de errores antes de enviar datos a los webservices
    errorUser: boolean = false;
    errorMessage: string = "";

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Variables para manejo de mensajes de notificacion
    public title: string = 'User';
    public content: string = "";

    //Variable para manejo de estado del usuario
    statesEs = ['Activo', 'Inactivo', 'Bloqueado'];
    statesEn = ['Active', 'Inactive', 'Blocked'];

    sort: any = {
        column: 'Name',
        descending: false
    };

    //Variables que contendran la informacion que cada modulo para editar permisos
    moduleRestaurant: Object = {};
    moduleMenuRestaurant: Object = {};
    moduleClient: Object = {};
    moduleMails: Object = {};
    moduleLegalTerms: Object = {};
    moduleUsers: Object = {};
    moduleGeneralSettings: Object = {};
    moduleReports: Object = {};
    moduleProducts: Object = {};
    userPermissions: any;

    /**
     * Variable que captura el listado de modulos habilitados en OMT
     */
    permissions: any;

    /**
     * Variable que almacena el id del usuario seleccionado
     */
    idUser: string;
    /**
     * Variable identificador de seccion restaurantes en permisos de usuario
     */
    restaurantSection: string = 'restaurantSection';
    legalTermSection: string = 'legalTermSection';
    menuSection: string = "menuSection";
    userSection: string = "userSection";
    mailSection: string = "mailSection";
    otherSection: string = "otherSection";

    /**
   * Variable que controla si se muestra o no la seccion
   */
    canShowSection: boolean = false;
    canViewUser: boolean = false;
    canCreateUser: boolean = false;
    canEditUser: boolean = false;
    canDeleteUser: boolean = false;
    canEditPermissions: boolean = false;

    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));

    /**
     * Variable con identificador de la seccion usuario
     */
    idSectionUser: any = this.allModules['module_users'];
    viewUser: any = this.allModules['module_view_users'];
    createUser: any = this.allModules['module_create_users'];
    editUser: any = this.allModules['module_edit_users'];
    dltUser: any = this.allModules['module_delete_users'];
    permissionsUser: any = this.allModules['module_edit_user_permissions'];

    ngOnInit() {

        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionUser);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canViewUser = this.permissionsUserService.verifyPermissionsUser(this.viewUser);
                this.canCreateUser = this.permissionsUserService.verifyPermissionsUser(this.createUser);
                this.canEditUser = this.permissionsUserService.verifyPermissionsUser(this.editUser);
                this.canDeleteUser = this.permissionsUserService.verifyPermissionsUser(this.dltUser);
                this.canEditPermissions = this.permissionsUserService.verifyPermissionsUser(this.permissionsUser);

                this.userLang = navigator.language.split('-')[0];
                this.createForm();
                this.createFormPermissions();
                this.getAllUsers();

                setTimeout(() => {
                    jQuery('#thName').addClass('cursor-pointerP');
                    jQuery('#thTypeDescription').addClass('cursor-pointerP');
                }, 1500);
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);
    }

    /**
     * Metodo encargado de solicitar peticion al web service para traer el listado
     * de todos los usuarios registrados en la aplicacion
     */
    getAllUsers() {
        this.userService
            .getAllUsers(this.userLang)
            .then(menuItem => {
                this.users = menuItem;
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de crear la estructura del formulario que se 
     * utilizará para manejo de los datos
     */
    createForm() {
        let emailRegex = `^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+.[a-zA-Z0-9-.]*)[\\\.]([a-zA-Z_.]{2,6})`;
        let id: FormControl = new FormControl(null);
        let name: FormControl = new FormControl('', [<any>Validators.required]);
        let lastname: FormControl = new FormControl('', [<any>Validators.required]);
        let email: FormControl = new FormControl('', [<any>Validators.required, <any>Validators.pattern(emailRegex)]);
        let username: FormControl = new FormControl('', [<any>Validators.required]);
        let password: FormControl = new FormControl('', Validators.minLength(6));
        let password_confirm: FormControl = new FormControl('', Validators.minLength(6));
        let type: FormControl = new FormControl('', [<any>Validators.required]);
        let phone: FormControl = new FormControl('');
        let status: FormControl = new FormControl('');

        this.userForm = this._fb.group({
            id: id,
            name: name,
            lastname: lastname,
            email: email,
            username: username,
            password: password,
            password_confirm: password_confirm,
            type: type,
            phone: phone,
            status: status
        })
    }

    /**
     * Metodo encargado de abrir el modal para agregar un nuevo usuario
     */

    public addUserModal(modal: TemplateRef<any>) {
        this.modalRef = this.modalService.show(modal);
        // }

        // addUserModal( modal) {


        this.selectedUser = new user;
        this.selectedUser.phone = "";
        this.selectedUser.password = "";
        this.selectedUser.password_confirm = "";


        setTimeout(() => {
            // jQuery('#addEditUserModal').modal('show');
            //  this.modalService.open(modal);
            jQuery("#typeProduct").prop('selectedIndex', 0);
            jQuery('#usernameUser').val('');
            jQuery('#password').val('');
            jQuery('#password_confirm').val('');
            jQuery('#usernameUser').val('');
            jQuery('#phone').val('');
        }, 500);
    }

    /**
     * Metodo encargado de cerra el modal de agregar/editar usuario
     * Debe limpiar los datos que se hayan ingresado en el formulario en caso que no se
     * hayan enviado
     */
    closeModal() {
        this.canEdit = false;
        this.editPassword = false;
        jQuery('#addEditUserModal').modal('hide');
        jQuery('#phone').val('');

        if (this.userForm.valid) {
            for (let name in this.userForm.controls) {
                this.userForm.controls[name]['updateValue']('');
            }
        }
    }

    /** validar en tiempo real las contraseñas que coincidan */

    validatepass(userData: any) {

        if (userData.password != userData.password_confirm) {
            this.validatePassR = true;
        } else {
            this.validatePassR = false;
        }


    }

    /**
     * Metodo encargado de validar que la informacion que se enviara al web service es correcta
     * y se puede realizar la adicion/edicion del usuario
     */
    validateForm(userData: any, formValid: boolean) {
        let specialChrctr = /[^a-zA-Z0-9\-\/]/;
        //Validacion que todos los campos hayan sido llenados
        if (!formValid) {
            this.errorUser = true;
            if (this.userLang == 'es') {
                this.errorMessage = 'Por favor llene todos los campos';
            } else {
                this.errorMessage = "Please complete all fields";
            }
            setTimeout(() => {
                this.errorUser = false;
            }, 3000);
            return false;
        }

        //Validacion que los campos de contraseña coincidan
        if ((!userData.password || !userData.password_confirm) && this.editPassword === false) {
            this.errorUser = true;
            if (userData.id) {
                if (this.userLang == 'es') {
                    this.errorMessage = 'El usuario debe ser editado con una contraseña';
                } else {
                    this.errorMessage = "The user must be edited with a password";
                }
            } else {
                if (this.userLang == 'es') {
                    this.errorMessage = 'El usuario debe ser creado con una contraseña';
                } else {
                    this.errorMessage = "The user must be created with a password";
                }
            }
            setTimeout(() => {
                this.errorUser = false;
            }, 3000);
            if (!userData.password) {
                jQuery("#password").focus();
            } else if (!userData.password_confirm) {
                jQuery("#password_confirm").focus();
            }
            return false;
        }

        // //Validacion que los campos de contraseña coincidan

        // if (userData.password != userData.password_confirm) {
        //   this.errorUser = true;
        //   if (this.userLang == 'es') {
        //     console.warn("password ==> ", userData.password, " Repassword ==> ", userData.password_confirm);
        //     this.errorMessage = 'Las contraseñas ingresadas no coinciden';
        //   } else {
        //     this.errorMessage = "Entered passwords don't match";
        //   }
        //   setTimeout(() => {
        //     this.errorUser = false;
        //   }, 3000);
        //   jQuery("#password_confirm").focus();
        //   return false;
        // }

        //Validacion caracteres especiales en campo username
        if (specialChrctr.test(userData.username)) {
            this.errorUser = true;
            if (this.userLang == 'es') {
                this.errorMessage = 'Campo "usuario" no puede contener carácteres especiales';
            } else {
                this.errorMessage = "Field 'username' can't contain special characters";
            }
            setTimeout(() => {
                this.errorUser = false;
            }, 3000);
            jQuery("#usernameUser").focus();
            return false
        }

        return true;
    }

    /**
     * Metodo encargado de enviar los datos para crear un nuevo usuario en el sistema
     * ya sea para crear o editar un usuario
     * @param userData: user -> Información del usuario a crear/editar
     * @param formValid: boolean -> Validacion que el formulario se haya llenado correctamente
     */
    onSubmit(userData: any, formValid: boolean, validatePassR: boolean) {
        this.processingResponse = true;
        this.selectedUser = userData;
        if (!this.validateForm(userData, formValid)) {
            return false;
        }

        this.content = this.utils.defineContent(this.selectedUser, this.userLang, 'addEdit');
        if (this.userLang === 'es') {
            this.title = "Usuario";
        }

        let phone = jQuery('#phone').val();
        if (!phone || phone.substr(2, 1) == '_') {
            phone = "";
        }

        this.selectedUser.phone = phone;

        if (!validatePassR) {

            this.userService
                .save(userData, this.userLang)
                .subscribe(user => {
                    // user = user;
                    this.processingResponse = false;
                    this._service.success(this.title, this.content, { id: 123 });

                    if (userData.id) {
                        this.alertService.alertGestionSuccess("editedSuccessfully");
                    } else {
                        this.alertService.alertGestionSuccess("SuccessfullyCreated");
                    }

                    setTimeout(() => {
                        this.getAllUsers();
                        this.modalRef.hide();
                        this.closeModal();
                    }, 1500);

                }, error => {
                    this.processingResponse = false;
                    this.error = this.extractError(error);
                    this.errorUser = true;
                    this.errorMessage = this.error.message;

                    if (userData.id) {
                        this.alertService.alertGestionError("failedEdition");
                    } else {
                        this.alertService.alertGestionError("errorSaving");
                    }

                    setTimeout(() => {
                        this.errorUser = false;
                    }, 3500);

                })

        } else {
            this.processingResponse = false;
            console.warn('Error al crear el usuario');
            //this.alertService.alertGestionError("errorSaving");
            this.alertService.WarningAdd("errorSaving", "PLEASE_VERIFY");
        }
    }

    /**
     * Metodo encargado de abrir el modal para editar un usuario
     */

    public editUserModal(userData: user, modal) {
        this.modalRef = this.modalService.show(modal);

        this.selectedUser = new user;
        this.canEdit = true;
        this.editPassword = true;
        this.selectedUser.phone = "";
        jQuery('#phone').val('');

        setTimeout(() => {
            this.selectedUser = userData;
            // this.modalService.open(modal);
            // jQuery('#addEditUserModal').modal('show');
        }, 500);
    }
    // editUserModal(userData: user, modal) {
    //   this.selectedUser = new user;
    //   this.canEdit = true;
    //   this.editPassword = true;
    //   this.selectedUser.phone = "";
    //   jQuery('#phone').val('');

    //   setTimeout(() => {
    //     this.selectedUser = userData;
    //     // this.modalService.open(modal);
    //     // jQuery('#addEditUserModal').modal('show');
    //   }, 500);
    // }

    /**
     * Validacion para que no se ingresen espacios en campos de email y contraseña
     */
    withoutSpaces(event: any) {
        if (event.which == 32) {
            return false;
        }
    }

    /**
     * Metodo que permite habilitar campos para editar la contraseña
     */
    editPasswords() {
        this.userForm.controls['password']['updateValue']('');
        this.userForm.controls['password_confirm']['updateValue']('');
        jQuery('#password').val('');
        jQuery('#password_confirm').val('');

        this.selectedUser.password = "";
        this.selectedUser.password_confirm = "";

        if (this.editPassword) {
            this.editPassword = false;
        } else if (!this.editPassword) {
            this.editPassword = true;
        }
    }

    /**
     * Metodo encargado de abrir el modal para mostrar los detalles del usuario seleccionado
     * @param userData: any -> Informacion del usuario a mostrar
     */

    public showDetailsModal(userData: any, modal) {
        this.modalRef = this.modalService.show(modal);
        this.selectedUser = userData;
    }

    // showDetailsModal(userData: any, modal) {
    //   this.selectedUser = userData;
    //   // this.modalService.open(modal);
    //   // setTimeout(() => {
    //   //   jQuery('#showUserModal').modal('show');
    //   // }, 400);
    // }

    /**
    * Metodo encargado de mostrar mensaje de confirmacion para eliminar un usuario
    */

    public confirmDelete(userData: any, modal) {
        this.modalRef = this.modalService.show(modal);
        this.selectedUser = userData;
    }

    // confirmDelete(userData: any, modal) {
    //   this.selectedUser = userData;
    //   // this.modalService.open(modal);
    //   //jQuery('#canDeleteModal').modal('show');
    // }

    /**
     * Metodo encargado de eliminar un usuario
     * @param userData: any -> Registro que se quiere eliminar 
     */
    deleteUser(userData: user, event: any) {
        this.processingResponse = true;
        this.content = this.utils.defineContent(this.selectedUser, this.userLang, 'Deleted');
        if (this.userLang === 'es') {
            this.title = "Usuario";
        }
        event.stopPropagation();
        this.userService
            .delete(userData, this.userLang)
            .then(user => {
                this.processingResponse = false;
                this.userDelete = user;
                this._service.success(this.title, this.content, { id: 123 });
                //this.alertService.alertDeletedSuccess();
                this.alertService.alertGestionSuccess("deletedSuccessfully");
                setTimeout(() => {
                    this.modalRef.hide();
                    // jQuery('#canDeleteModal').modal('hide');
                    this.getAllUsers();
                }, 1000)
            })
            .catch(error => {
                this.error = this.extractError(error);
                this.errorUser = true;
                this.errorMessage = this.error.message;
                //this.alertService.alertDeletedError();
                this.alertService.alertGestionError("eliminationFailed");
                setTimeout(() => {
                    this.errorUser = false;
                }, 3000);
            });
    }

    /**
     * Metodo encargado de capturar el error que puede llegar a generarse al crear un usuario
     */
    extractError(res: Response) {
        let body = res.json();
        return body.errors || {};
    }

    /**
     * Metodo encargado de realizar la busqueda por el rol del usuario
     */
    searchRoleUser(roleUser: string, statusUser: string) {
        console.log('roleUser=', roleUser, 'statusUser=', statusUser);
        this.getAllUsers();

        setTimeout(() => {
            if (roleUser && statusUser) {
                this.users = this.users.filter(it => it['type'] == parseInt(roleUser) && it['status'] == parseInt(statusUser));
            } else if (roleUser) {
                this.users = this.users.filter(it => it['type'] == parseInt(roleUser));
            } else if (statusUser) {
                this.users = this.users.filter(it => it['status'] == parseInt(statusUser));
            }
        }, 1000);
    }

    /**
     * Metodo encargado de establecer la clase que indica si el ordenamiento es
     * descendente o ascendente (Icono en el th)
     */
    selectedClass(columnName) {
        return columnName == this.sort.column ? 'sort-' + this.sort.descending : false;
    }

    /**
     * Metodo encargado de cambiar la forma de ordenamiento 
     * (Ascendente o Descendente)
     */
    changeSorting(columnName): void {
        var sort = this.sort;
        if (sort.column == columnName) {
            sort.descending = !sort.descending;
        } else {
            sort.column = columnName;
            sort.descending = false;
        }
    }

    convertSorting(): string {
        return this.sort.descending ? '-' + this.sort.column : this.sort.column;
    }

    autoFocus(event: any) {

        setTimeout(() => {
            jQuery('#usernameUser').focus();
        }, 300);
    }

    /**
     * Metodo encargado de desahibilitar los checkbox hijos de cada seccion si esta no tiene permisos
     */
    disabledChildCheckbox(permissions: any) {
        if (!permissions.permissions) {
            for (var i = 0; i < permissions.children.length; i++) {
                jQuery('#' + permissions.slug + (i + 1)).prop('disabled', true);
            }
        }
    }

    /**
     * Metodo encargado de solicitar al web service el listado completo de los permisos que un usuario
     * tiene sobre los modulos existentes en OMT
     * @param idUser:number -> Id del usuario al que se solicitara el listado de permisos 
     */
    getPermissionsUser(idUser: string) {
        this.userService
            .getPermissionsUser(this.userLang, this.idUser)
            .then(userPermissions => {
                this.permissions = userPermissions;
                //console.log('Permissions', this.permissions);
                for (var i = 0; i < this.permissions.length; i++) {
                    if (this.permissions[i].id == 14) {
                        this.moduleClient = this.permissions[i];
                    } else if (this.permissions[i].id == 1) {
                        this.moduleRestaurant = this.permissions[i];
                    } else if (this.permissions[i].id == 8) {
                        this.moduleMenuRestaurant = this.permissions[i];
                    } else if (this.permissions[i].id == 11) {
                        this.moduleMails = this.permissions[i];
                    } else if (this.permissions[i].id == 18) {
                        this.moduleLegalTerms = this.permissions[i];
                    } else if (this.permissions[i].id == 22) {
                        this.moduleUsers = this.permissions[i];
                    } else if (this.permissions[i].id == 28) {
                        this.moduleGeneralSettings = this.permissions[i];
                    } else if (this.permissions[i].id == 31) {
                        this.moduleReports = this.permissions[i];
                    } else if (this.permissions[i].id == 33) {
                        this.moduleProducts = this.permissions[i];
                    }
                }
            })
            .catch(error => this.error = error);

    }

    /**
     * Metodo encargado de abrir el modal para el manejo de los permisos de los usuarios
     * @param userSelected: user -> Usuario seleccionado para asignar o quitar permisos
     */

    public setPermissions(userSelected: user, modal) {
        this.modalRef = this.modalService.show(modal);
        this.idUser = userSelected.id;
        this.selectedUser = userSelected;

        this.getPermissionsUser(this.idUser);
    }

    // setPermissions(userSelected: user, modal) {
    //   this.idUser = userSelected.id;
    //   this.selectedUser = userSelected;

    //   this.getPermissionsUser(this.idUser);

    //   setTimeout(() => {
    //     this.doDisabledChildCheckbox();
    //     // this.modalService.open(modal);
    //     //jQuery('#permissionsUserModal').modal('show');
    //   }, 500);
    // }

    /**
     * Metodo encargado de llamar al metodo para desahibilitar los checkbox de todas
     * las secciones
     */
    doDisabledChildCheckbox() {
        this.disabledChildCheckbox(this.moduleRestaurant);
        this.disabledChildCheckbox(this.moduleMenuRestaurant);
        this.disabledChildCheckbox(this.moduleMails);
        this.disabledChildCheckbox(this.moduleLegalTerms);
        this.disabledChildCheckbox(this.moduleUsers);
        this.disabledChildCheckbox(this.moduleGeneralSettings);
        this.disabledChildCheckbox(this.moduleClient);
    }

    /**
       * Metodo encargado de crear el formulario para manejo de la informacion necesaria para asignar
       * permisos a un usario
       */
    createFormPermissions() {
        this.userPermissionsForm = new FormGroup({
            createRestaurant: new FormControl(''),
            viewRestaurantLevel: new FormControl(''),
            viewRestaurantDirectory: new FormControl(''),
            editRestaurantLevel: new FormControl(''),
            editRestaurantDirectory: new FormControl(''),
            deleteRestaurantDirectory: new FormControl(''),
            viewLegalTerms: new FormControl(''),
            editLegalTerms: new FormControl(''),
            deleteLegalTerms: new FormControl(''),
            viewMenu: new FormControl(''),
            editMenu: new FormControl(''),
            accessModuleStatics: new FormControl(''),
            viewUser: new FormControl(''),
            createUser: new FormControl(''),
            editUser: new FormControl(''),
            deleteUser: new FormControl(''),
            assignPermissions: new FormControl(''),
            generalSettings: new FormControl(''),
            viewMail: new FormControl(''),
            editNoteMail: new FormControl('')
        });
    }

    /**
     * Metodo encargado de enviar al web services los permisos que se hayan asignado al usuario
     * @param userPermissionsData: any -> Informacion de permisos otorgados al usuario
     * @param isValid: boolean -> Variable booleana que identifica si se lleno correctamente el formulario de permisos
     */
    onSubmitUserPermissions(userPermissionsData: any, isValid: boolean) {
        console.log(userPermissionsData, isValid, this.idUser);
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    selectAllChckbx(event: any) {
        if (event.target.checked) {
            jQuery('#restaurantSelectedAll').prop('checked', true);
            jQuery('#legalTermsSelectedAll').prop('checked', true);
            jQuery('#menuSelectedAll').prop('checked', true);
            jQuery('#userSelectedAll').prop('checked', true);
            jQuery('#mailSelectedAll').prop('checked', true);
            jQuery('#otherSelectedAll').prop('checked', true);
            if (this.userPermissionsForm.valid) {
                for (let name in this.userPermissionsForm.controls) {
                    this.userPermissionsForm.controls[name]['updateValue'](true);
                }
            }
        } else {
            jQuery('#restaurantSelectedAll').prop('checked', false);
            jQuery('#legalTermsSelectedAll').prop('checked', false);
            jQuery('#menuSelectedAll').prop('checked', false);
            jQuery('#userSelectedAll').prop('checked', false);
            jQuery('#mailSelectedAll').prop('checked', false);
            jQuery('#otherSelectedAll').prop('checked', false);
            if (this.userPermissionsForm.valid) {
                for (let name in this.userPermissionsForm.controls) {
                    this.userPermissionsForm.controls[name]['updateValue'](false);
                }
            }
        }
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion restaurantes
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    restaurantSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['createRestaurant']['updateValue'](true);
            this.userPermissionsForm.controls['viewRestaurantLevel']['updateValue'](true);
            this.userPermissionsForm.controls['viewRestaurantDirectory']['updateValue'](true);
            this.userPermissionsForm.controls['editRestaurantLevel']['updateValue'](true);
            this.userPermissionsForm.controls['editRestaurantDirectory']['updateValue'](true);
            this.userPermissionsForm.controls['deleteRestaurantDirectory']['updateValue'](true);

        } else {
            this.userPermissionsForm.controls['createRestaurant']['updateValue'](false);
            this.userPermissionsForm.controls['viewRestaurantLevel']['updateValue'](false);
            this.userPermissionsForm.controls['viewRestaurantDirectory']['updateValue'](false);
            this.userPermissionsForm.controls['editRestaurantLevel']['updateValue'](false);
            this.userPermissionsForm.controls['editRestaurantDirectory']['updateValue'](false);
            this.userPermissionsForm.controls['deleteRestaurantDirectory']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);

        }
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion Términos legales
     * y Políticas de privacidad
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    legalTermsSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['viewLegalTerms']['updateValue'](true);
            this.userPermissionsForm.controls['editLegalTerms']['updateValue'](true);
            this.userPermissionsForm.controls['deleteLegalTerms']['updateValue'](true);
        } else {
            this.userPermissionsForm.controls['viewLegalTerms']['updateValue'](false);
            this.userPermissionsForm.controls['editLegalTerms']['updateValue'](false);
            this.userPermissionsForm.controls['deleteLegalTerms']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);
        }
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion Menú
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    menuSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['viewMenu']['updateValue'](true);
            this.userPermissionsForm.controls['editMenu']['updateValue'](true);
        } else {
            this.userPermissionsForm.controls['viewMenu']['updateValue'](false);
            this.userPermissionsForm.controls['editMenu']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);
        }
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion usuarios
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    userSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['viewUser']['updateValue'](true);
            this.userPermissionsForm.controls['createUser']['updateValue'](true);
            this.userPermissionsForm.controls['editUser']['updateValue'](true);
            this.userPermissionsForm.controls['deleteUser']['updateValue'](true);
            this.userPermissionsForm.controls['assignPermissions']['updateValue'](true);
        } else {
            this.userPermissionsForm.controls['viewUser']['updateValue'](false);
            this.userPermissionsForm.controls['createUser']['updateValue'](false);
            this.userPermissionsForm.controls['editUser']['updateValue'](false);
            this.userPermissionsForm.controls['deleteUser']['updateValue'](false);
            this.userPermissionsForm.controls['assignPermissions']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);
        }
    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion Correos
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    mailSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['viewMail']['updateValue'](true);
            this.userPermissionsForm.controls['editNoteMail']['updateValue'](true);
        } else {
            this.userPermissionsForm.controls['viewMail']['updateValue'](false);
            this.userPermissionsForm.controls['editNoteMail']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);
        }

    }

    /**
     * Metodo encargado de seleccionar/deseleccionar los campos del formulario de permisos seccion Correos
     * @param event: any -> Variable para seleccionar/deseleccionar todos los checkbox
     */
    otherSelectAllChckbx(event: any) {
        if (event.target.checked) {
            this.userPermissionsForm.controls['accessModuleStatics']['updateValue'](true);
            this.userPermissionsForm.controls['generalSettings']['updateValue'](true);
        } else {
            this.userPermissionsForm.controls['accessModuleStatics']['updateValue'](false);
            this.userPermissionsForm.controls['generalSettings']['updateValue'](false);
            jQuery('#selectedAll').prop('checked', false);
        }

    }

    /**
     * Metodo encargado de cambiar el valor del checkbox selectedAll a false,
     * debe ocurrir cuando se modifique el valor de los permisos de los usuarios
     * @param section: string -> Identificador de seccion donde se clickeo el checkbox
     */
    changeValueselectedAll(section: string) {
        jQuery('#selectedAll').prop('checked', false);

        if (section == this.restaurantSection) {
            jQuery('#restaurantSelectedAll').prop('checked', false);
        } else if (section == this.legalTermSection) {
            jQuery('#legalTermsSelectedAll').prop('checked', false);
        } else if (section == this.menuSection) {
            jQuery('#menuSelectedAll').prop('checked', false);
        } else if (section == this.userSection) {
            jQuery('#userSelectedAll').prop('checked', false);
        } else if (section == this.mailSection) {
            jQuery('#mailSelectedAll').prop('checked', false);
        } else if (section == this.otherSection) {
            jQuery('#otherSelectedAll').prop('checked', false);
        }

    }

    /**
     * Metodo encargado de editar los permisos que un usuario tendra sobre un modulo
     * o seccion del modulo
     * @param event: any -> Elemento checkbox para determinar si esa checkeada o no
     * @param idModule: string -> Id del modulo
     * @param idUser: string -> Id del usario que se esta editando
     */
    editPermissionsUser(event: any, idModule: string, idUser: string) {
        if (this.userLang == 'es') {
            this.title = 'Permisos de Usuario';
            this.content = 'editados correctamente';
        } else {
            this.title = 'User Permissions';
            this.content = 'edited successfully';
        }

        this.userService
            .postPermissionsUser(idUser, parseInt(idModule), this.userLang)
            .then(userPermissions => {
                this.userPermissions = userPermissions;
                this.getPermissionsUser(idUser);
                setTimeout(() => {
                    this.doDisabledChildCheckbox();
                }, 500);

            })
            .catch(error => this.error = error);

    }

}

