import { ToastrService } from 'ngx-toastr';

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// import { TranslatePipe } from 'ng2-translate/ng2-translate';
import { restaurant } from '../../entity/restaurant';
import { RestaurantService } from '../../services/restaurant.service';
import { UtilsComponent } from '../../utils/utils';

//Manejo de modal para eliminar un restaurante
declare var jQuery: any;

@Component({
    selector: 'enable-restaurant',
    templateUrl: './enableRestaurant.component.html'
})

export class EnableRestaurantComponent {

    public titulo: string;
    //Variable para paginación por defecto
    private items_per_page: number = 10;
    private page: number = 1;
    cleanSearch: boolean = false;
    //Variable que recibe respuesta del listado completo de restaurantes
    fullResponse: any;
    //Variables para manejo de paginación
    currentPage: number;
    firstPage: number;
    lastPage: number;
    totalItems: number;
    itemsInCurrentPage: number;
    itemsPerPage: number;
    prevPage: number;
    nextPage: number;
    actualPage: number;
    allRestaurants: restaurant[];
    error: any;
    private acordeon: Array<string>;
    private acordeon1: Array<string>;
    private cuisinesList: Array<string>;
    userLang: any;


    filterLevel: string = "";

    constructor(
        private restaurantService: RestaurantService,
        private utils: UtilsComponent,
        private toastr: ToastrService,
        private translate: TranslateService
    ) {
        this.titulo = "probando que funcione bien el router";
        this.acordeon = [];
        this.acordeon1 = [];

        this.cuisinesList = [""];

    }

    ngOnInit() {
        this.putClassActive();
        this.getAllRestaurants();
    }

    putClassActive() {
        jQuery('.restaurantList').removeClass('active');
        jQuery('.reviewsSidebar').removeClass('active');
        jQuery('.userList').removeClass('active');
        jQuery('.legalList').removeClass('active');
        jQuery('.mailsList').removeClass('active');
        jQuery('.clientsList').removeClass('active');
        jQuery('.settingList').removeClass('active');
        jQuery('.enableRestaurant').addClass('active');
    }

    /**
     * Metodo encargado de realizar la petición del listado completo de restaurantes
     * y de los items para realizar la paginación
     */
    getAllRestaurants() {
        this.restaurantService
            .getAllRestaurantsToEnable(this.items_per_page, this.cleanSearch, this.userLang)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getListRestaurants(this.fullResponse);
                this.getPagination(this.fullResponse);
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de capturar el listado completo de restaurantes
     */
    getListRestaurants(res: any) {
        this.allRestaurants = res.data || {};

        for (var i = 0; i < this.allRestaurants.length; i++) {
            this.allRestaurants[i]["checked"] = false;
            this.acordeon.push("#accordion" + i);
            this.acordeon1.push("accordion" + i);
        }
    }

    enableRestaurant(item: any, index: any) {

        //for(var item of this.allRestaurants){
        //if(item['checked']){
        this.restaurantService.putEnableRestaurant(item, this.userLang).then(fullResponse => {
            this.fullResponse = fullResponse;
            this.allRestaurants.splice(index, 1);
            this.actionSuccess();
        })
            .catch(error => {
                this.error = error;
                this.actionError();
            });
        //}
        //}

    }

    /*doIfChecked(checkStatus){
      if(checkStatus){
        alert("Es verdadero: " + checkStatus);
      }else{
        alert("Es falso: " + checkStatus);
      }
    }*/

    /**
     * Metodo encargado de manejar la navegacion entre paginas del listado de restaurantes
     */
    getPage(page: number) {

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage' + page).addClass('active');

        this.restaurantService
            .getPageRestaurantsToEnable(page, this.items_per_page)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getListRestaurants(this.fullResponse);
                this.getPagination(this.fullResponse);
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de capturar los items necesarios para realizar
     * la paginación
     */
    getPagination(res: any) {
        this.currentPage = res.pagination.currentPage;
        this.totalItems = res.pagination.totalItems;
        this.itemsInCurrentPage = res.pagination.itemsInCurrentPage;
        this.firstPage = res.pagination.firstPage;
        this.lastPage = res.pagination.lastPage;
        this.itemsPerPage = res.pagination.itemsPerPage;
        this.nextPage = res.pagination.nextPage;
        this.prevPage = res.pagination.prevPage;
        this.actualPage = res.pagination.currentPage;

        if (res.pagination.currentPage > 1) {
            this.currentPage = this.currentPage + (res.pagination.itemsPerPage * (this.currentPage - 1)) - (this.currentPage - 1);
            this.itemsPerPage = this.currentPage + (res.pagination.itemsPerPage - 1);
        }

        if (res.pagination.itemsPerPage >= res.pagination.totalItems) {
            this.itemsPerPage = this.totalItems;
        }

        if (res.pagination.currentPage == res.pagination.lastPage) {
            this.itemsPerPage = this.totalItems;
        }
    }

    /**
     * Metodo encargado de generar array para iterar en la vista para realizar la paginacion
     */
    createRange(numberBtn) {
        // console.log(numberBtn);
        let items: any;
        return items = this.utils.createRange(numberBtn);
    }

    /**
     * Metodo encargado de hacer paginación manejando el numero de items que se quieren ver en
     * el listado
     */
    doPagination(items: number) {

        this.items_per_page = items;
        this.cleanSearch = true;
        this.restaurantService
            .getAllRestaurantsToEnable(this.items_per_page, this.cleanSearch, this.userLang)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getListRestaurants(this.fullResponse);
                this.getPagination(this.fullResponse);
            })
            .catch(error => this.error = error);

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage1').addClass('active');
    }

    actionSuccess() {
        this.translate.get(["Restaurant", "actionOk"]).subscribe(translations => {
            this.toastr.success(translations.actionOk, translations.Restaurant);
        });
    }

    actionError() {
        this.translate.get(["Restaurant", "actionError"]).subscribe(translations => {
            this.toastr.warning(translations.actionError, translations.Restaurant);
        });
    }


}