import * as moment from 'moment';
import { Observable, Subscriber } from 'rxjs';

import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

/**
 * Filtro que permite obtener una fecha con hora formato AM - PM
 *
 * @author Jhonier Gaviria
 * @version 1.0
 */
@Pipe({ name: 'dateTimeLocalize' })
export class DateTimeLocalizePipe implements PipeTransform {

    /**
     * Constructor de la clase
     *
     * @param translate Es el servicio de traductor de la aplicación
     */
    constructor(private translate: TranslateService) { }

    /**
     * Permite modificar el formato de una fecha
     *
     * @param date Es la fecha a transformar al formato solicitado
     */
    getDate(date: string) {
        if (this.translate.currentLang === 'en') {
            return moment(date).format('MM/DD/YYYY hh:mm A');
        } else {
            return moment(date).format('DD/MM/YYYY hh:mm A');
        }
    }

    /**
     *
     * @{see PipeTransform#transform(any,any[]) transform}
     */
    transform(date: string): any {

        return new Observable<string>((observer: Subscriber<any>) => {
            observer.next(this.getDate(date));

            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                observer.next(this.getDate(date));
            });
        });
    }
}
