<div class="container-fluid">
  <!-- .navbar-header contains links seen on xs & sm screens -->
  <div class="navbar-header">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <!-- whether to automatically collapse sidebar on mouseleave. If activated acts more like usual admin templates -->
        <a (click)="toggleSidebar('static')" class="nav-link hidden-md-down" href="#" data-toggle="tooltip" data-html="true" data-original-title="Turn<br>on/off<br>sidebar<br>collapsing"
          data-placement="bottom">
          <i class="fa fa-bars fa-lg"></i>
        </a>
        
        <!-- shown on xs & sm screen. collapses and expands navigation -->
        <a (click)="toggleSidebar('collapse')" class="hidden-lg-up nav-link" href="#" data-html="true" title="Show/hide<br>sidebar"
          data-placement="bottom">
          <span class="rounded rounded-lg bg-gray text-white hidden-md-up"><i class="fa fa-bars fa-lg"></i></span>
          <i class="fa fa-bars fa-lg hidden-sm-down"></i>
        </a>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right hidden-md-up">
      <li class="nav-item">
        <!-- toggles chat -->
        <a class="nav-link" href="#" (click)="toggleChat()">
          <span class="rounded rounded-lg bg-gray text-white"><i class="fa fa-globe fa-lg"></i></span>
        </a>
      </li>
    </ul>
    <a class="navbar-brand hidden-md-up" href="index.html">
      <i class="fa fa-circle text-gray mr-n-sm"></i>
      <i class="fa fa-circle text-warning"></i> &nbsp; {{config.name}} &nbsp;
      <i class="fa fa-circle text-warning mr-n-sm"></i>
      <i class="fa fa-circle text-gray"></i>
    </a>
  </div>

  <!-- this part is hidden for xs screens -->
  <div class="collapse navbar-collapse">
    <ul class="nav navbar-nav pull-xs-left">
      <h2>Open My Tab</h2>
    </ul>

    <ul class="nav navbar-nav pull-xs-right" (click)="$event.preventDefault()">
      <li class="btn-group" dropdown>

        <a href dropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown">
          <span class="thumb-sm avatar pull-xs-left">
                    <img class="img-circle" src="assets/images/avatar.png" alt="...">
                </span> &nbsp;
          <strong>{{username}}</strong>&nbsp;
        </a>

        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <!-- <li role="menuitem">
            <a class="dropdown-item" href="#">
              <userProfile></userProfile>
            </a>
          <li> -->

              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item cursor-pointer" (click)="logOut()"><i class="fa fa-sign-out"></i> &nbsp; {{ 'LogOut' | translate }}</a></li>
        </ul>
        </li>
    </ul>
  
  </div>
</div>

