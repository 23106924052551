import { BsModalRef } from 'ngx-bootstrap/modal';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'activate-modal',
    templateUrl: './activateRestaurantLevel.component.html',

})

export class ActivateRestaurantLevel implements OnInit {

    @Input() title: string;
    @Input() accountName: string;
    @Input() contactPerson: string;
    @Input() mainPhone: string;
    @Input() mainEmail: string;
    @Input() street: string;
    @Input() suitNumber: string;
    @Input() zipCode: string;
    @Input() cityName: string;
    @Input() stateName: string;
    @Input() countryName: string;
    @Input() nickname: string;
    @Input() idRestaurant: string;
    @Input() res: any;
    @Input() typeModal: string;
    @Input() notify: string;

    public createAccountForm: FormGroup;

    constructor(
        public bsModalRef: BsModalRef
    ) { }

    ngOnInit() {

    }

    // ngOnChanges(bsModalRef: SimpleChanges) {
    //     if(this.bsModalRef.content.typeModal == 'listAccount'){
    //     } 
    //   }
    onNotify(message: string): void {
        this.bsModalRef.hide();
    }
}

