<input type="file" #selIm (change)="innnerChangeImage($event)" accept="image/jpeg,image/png">
<hr>
<div *ngIf="errorExist" class="alert alert-danger cien">{{textWarn}}</div>

<image-cropper
[imageChangedEvent]="imageChangedEvent"
[maintainAspectRatio]="true"
[aspectRatio]="imageCroped.x / imageCroped.y"
[resizeToWidth]="imageCroped.width"
format="jpg"
(imageCropped)="imageCropped($event)"
(imageLoaded)="imageLoaded()"
(loadImageFailed)="loadImageFailed()"
></image-cropper>
<!-- Linea para activar imagen de muestra del recorte -->
<!-- <img [src]="croppedImage" /> -->
