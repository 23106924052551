<h1 class="page-title" *ngIf="allReports">
    {{ 'REPORTS' | translate }}
</h1>
<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section Class="widget">

            <div class="row">
                <div class="col-lg-12 col-xs-12">
                    <section widget class="widget">
                        <header>
                            <h4>
                                {{ 'Filters' | translate }}
                            </h4>
                        </header>
                        <div class="widget-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <div class="col-sm-2 col-xs-12" *ngIf="allReports">
                                            <select class="form-control select2" [(ngModel)]="filterReport.restaurantId" placeholder="works">
                        <option id="1" value="" disabled> {{ 'Restaurant' | translate }} </option>
                        <option value=""> {{ 'SelectedAll' | translate }} </option>
                        <option *ngFor="let restaurant of restaurants" [ngValue]="restaurant.restaurantId">
                          {{ restaurant.restaurantName}}
                        </option>
                      </select>
                                        </div>
                                        <div class="col-sm-2 col-xs-12">
                                            <select class="form-control select2" [(ngModel)]="filterReport.type">
                        <option id="1" value="" disabled> {{'SECTION' | translate}} </option>
                        <option value=""> {{ 'SelectedAll' | translate }} </option>
                        <option *ngFor="let type of typesItirables" [ngValue]="type.number">
                          {{type.name | translate}}</option>
                      </select>
                                        </div>

                                        <div class="col-sm-2 col-xs-12">
                                            <select class="form-control select2" [(ngModel)]="filterReport.userType">
                        <option id="1" value="" disabled> {{'USER_TYPE' | translate}} </option>
                        <option value=""> {{ 'SelectedAll' | translate }} </option>
                        <option value="1">
                          {{'USER_NOT_LOGGED_IN' | translate}}
                        </option>
                        <option value="0">
                          {{'USER_LOGGED_IN' | translate}}
                        </option>
                      </select>
                                        </div>
                                        <div class="col-sm-3 col-xs-12">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="{{ 'DATE_RANGE' | translate }}" bsDaterangepicker #dpr="bsDaterangepicker" [(ngModel)]="filterReport.dateRangePicker" [bsConfig]="{
                          dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-green' }">
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-xs-12">
                                            <button [disabled]="buttonStatus" class="btn btn-info mb-xs" (click)="getReports()">{{ 'Search' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>

        <section Class="widget">
            <div class="table-responsive">
                <div class="form-group row">

                    <div class="col-md-12 col-xs-12 items-per-page-report">
                        <ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
                            <li class="nav-item" *ngFor="let page of paginationArray">
                                <a (click)="getReports(1, page)" [ngClass]="{'active': page === itemsPerPageSelected }" class="nav-link" id="items10" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">{{page}}</a>
                                <div class="lds-dual-ring" *ngIf="paginationSelected == page"></div>
                            </li>
                        </ul>
                    </div>



                    <div class="col-md-12 col-xs-12">
                        <div class="text-xs-right pull-left">
                            <a class="btn btn-gray mb-xs" (click)="goToRestaurantList()">
                                <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                            </a>
                            <button [disabled]="buttonStatus" class="btn btn-success mb-xs" (click)="refreshReports()">
                <i class="fa fa-refresh"></i> {{ 'REFRESH' | translate }}
              </button>
                        </div>
                        <div class="text-xs-left pull-right">
                            <button [disabled]="downloadExcel" [hidden]="downloadExcel" class="btn btn-success mb-xs" (click)="exportToExcel()">
                <i class="fa fa-file"></i> {{ 'EXPORT_TO_EXCEL' | translate }}
              </button>
                            <button class="btn btn-gray flx" [hidden]="!downloadExcel" [disabled]="!downloadExcel">{{ 'processing' | translate }}&nbsp;
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="pagination">
                    <div class="col-md-8 col-xs-12">
                        <p>{{ 'Showing' | translate }} {{pagination.currentPage}} {{ 'To' | translate }} {{pagination.itemsPerPage}} {{ 'Of' | translate }} {{pagination.totalItems}}
                        </p>
                    </div>
                </div>
                <table class="table table-striped" id="table-restaurant">
                    <caption></caption>
                    <thead>
                        <tr>
                            <th id="type">
                                <a>{{'CREATION_DATE' | translate}}
                </a>
                            </th>
                            <!-- <th id="type">
                  <a>{{'Name' | translate}}
                  </a>
              </th> -->
                            <th id="type">
                                <a>{{'CLICK_SECTION' | translate}}
                </a>
                            </th>
                            <th id="restaurant" *ngIf="allReports">
                                <a>{{ 'Restaurant' | translate }} | PTA
                </a>
                            </th>
                            <th id="brand">
                                <a>{{ 'USER_TYPE' | translate }}
                </a>
                            </th>
                            <th id="restaurant">
                                <a>{{'BRAND' | translate}}
                </a>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let report of reports">
                            <td>
                                {{report.generatedAt| dateTimeLocalize | async}}
                            </td>
                            <!-- <td class="white-space-normal">
                  {{report.clientFullName}}
              </td> -->
                            <td class="white-space-normal">
                                {{ typesItirables[(report.type - 1)].name | translate}}
                            </td>

                            <td *ngIf="allReports" class="white-space-normal">
                                <span *ngIf="report.restaurantName" class="d-block">{{ report.restaurantName }}</span> <span *ngIf="report.frpName" class="d-block">PTA: {{report.frpName}}</span>
                                <span *ngIf="report.type === 20">{{ 'RESTAURANT_RELATED_PTA' | translate}}</span>
                                <span *ngIf="report.type === 23">{{ 'OFFERS_AVAILABLE_FROM_RESTAURANTS' | translate}}</span>
                            </td>

                            <td class="white-space-normal">
                                <ng-container *ngIf="report.wasAnonymous === types_user.TYPE_IS_ANONIMUS">
                                    {{'USER_NOT_LOGGED_IN' | translate}}
                                </ng-container>
                                <ng-container *ngIf="report.wasAnonymous === types_user.TYPE_NOT_WAS_ANONYMUS">
                                    <!-- {{'USER_LOGGED_IN' | translate}} -->
                                    {{report.clientFullName}}
                                </ng-container>
                            </td>

                            <td>
                                <ng-container *ngIf="report.brand === types_brand.TYPE_KIND_OMT_WEB">
                                    Web
                                </ng-container>
                                <ng-container *ngIf="report.brand === types_brand.TYPE_KIND_OMT_APP">
                                    App
                                </ng-container>
                                <ng-container *ngIf="report.brand === types_brand.TYPE_KIND_MENU_ONLINE">
                                    Menu Online
                                </ng-container>
                                <ng-container *ngIf="report.brand === types_brand.TYPE_KIND_WHITE_LABEL">
                                    White Label
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="reports">
                <div *ngIf="reports.length <=0">
                    <div class="alert alert-info alert-dismissable">
                        <strong>{{'NoDataToShow' | translate }}</strong>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="form-group row" *ngIf="pagination && pagination.totalItems > 0">
    <div class="col-lg-12 col-md-12 d-flex justify-content-center">
        <label>{{ 'Page' | translate }} {{pagination.currentPage}}</label>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
        <pagination class="pagination pagination-md justify-content-end active" [totalItems]="pagination.totalItems" [maxSize]="maxSize.maxSizePagination" [itemsPerPage]="pagination.itemsPerPage" [lastText]="lastPag" [firstText]="firstPag" [previousText]="prevPag"
            [nextText]="nextPag" (pageChanged)="getReports($event)" [boundaryLinks]="true" [rotate]="true">
        </pagination>
    </div>
</div>