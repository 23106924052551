import { Headers, Http, RequestOptions, Response, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Constants } from 'app/app.constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreatedImage } from '../entity/treatedImage';
// import { detailRestaurant } from './../entity/detailRestaurant';
// import { openingDate } from './../entity/openingDate';
import { UtilsComponent } from '../utils/utils';
import { restaurant } from '../entity/restaurant';

@Injectable()
export class RestaurantService {
    constructor(
        private httpc: HttpClient,
        private http: Http,
        private utils: UtilsComponent
    ) { }
    
    license: any;
    
    // private restaurantUrl = 'https://www.mocky.io/v2/579151c2110000590e5af589';
    private omtUrl = this.utils.getUrlOmtSd();
    private accountUrl = this.utils.getUrlOmt() + 'sd/account';
    private detailsUrl = this.utils.getUrlOmt() + 'restaurant/';
    private omtUrlSdOnly = this.utils.getUrlOmt() + 'sd/';
    private allRestaurantsUrl = this.utils.getUrlOmt() + 'sd/restaurants';
    private allRestaurantsToEnable = this.allRestaurantsUrl + '?is_verified=false';
    private city: string = "";
    private state: string = "";
    private country: string = "";
    private freeText: string = "";
    private level: string = "";
    private page: number = 1;
    private itemsPerPage: number = 10;
    private restaurantsList: any;

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    save(restaurant: restaurant, userLang: string, isEditFromConfig = false): Observable<restaurant> {
        if (restaurant.id) {
            return this.put(restaurant, isEditFromConfig);
        }
        return this.post(restaurant, userLang);
    }

    delete(restaurant: restaurant, userLang: string) {
        return this.httpc.delete(`${this.omtUrl}/${restaurant.id}`);
    }

    // Add new restaurant
    private post(restaurant: restaurant, userLang: string): Observable<restaurant> {

        const minValueDelivery = this.getMinimumSeviceValueForDelivery(restaurant);

        var arrayData = {
            name: restaurant.name,
            description: restaurant.description,
            main_phone: restaurant.phone,
            main_email: restaurant.email,
            street: restaurant.street,
            suit_number: restaurant.suitNumber,
            zip_code: restaurant.zipCode,
            city_name: restaurant.city,
            state_name: restaurant.state,
            country_name: restaurant.country,
            contact_person: restaurant.contactPerson,
            website: restaurant.website,
            latitude: restaurant.latitude,
            longitude: restaurant.longitude,
            url_menu: restaurant.urlMenu,
            is_verified: "true",
            delivery_range: restaurant.delivery_range,
            delivery_charge: restaurant.delivery_charge,
            status: restaurant.status,
            min_value_delivery: minValueDelivery
        }
        let array = JSON.stringify(arrayData);
        return this.httpc.post<restaurant>(this.omtUrl, array);

    }

    // Update existing restaurant
    private put(restaurant: restaurant, isEditFromConfig): Observable<restaurant> {
        let arrayData;
        const minValueDelivery = this.getMinimumSeviceValueForDelivery(restaurant);
        if (isEditFromConfig) {
            let charge, range;
            if (!restaurant.deliveryRanges.length) {
                charge = '';
                range = '';
            } else {
                range = restaurant.deliveryRanges[0]['rangeMax'];
                charge = restaurant.deliveryRanges[0]['rangeCharge'];
            }
            arrayData = {
                id: restaurant.id,
                name: restaurant.name,
                description: restaurant.description,
                main_phone: restaurant.phones[0]['number'],
                main_email: restaurant.email,
                street: restaurant.address['street'],
                public_address: restaurant.publicAddress,
                suit_number: restaurant.address['suitNumber'],
                zip_code: restaurant.address['zipCode'],
                city_name: restaurant.address['cityName'],
                state_name: restaurant.address['stateName'],
                country_name: restaurant.address['countryName'],
                contact_person: restaurant.contactPerson,
                website: restaurant.website,
                latitude: restaurant.latitude,
                longitude: restaurant.longitude,
                url_menu: restaurant.urlMenu,
                delivery_range: range,
                delivery_charge: charge,
                status: restaurant.status,
                availableCoupons: restaurant.availableCoupons,
                availableDelivery: restaurant.availableDelivery,
                availableDineIn: restaurant.availableDineIn,
                availablePickUp: restaurant.availablePickUp,
                min_value_delivery: minValueDelivery

            }
            console.log('ifseje', arrayData);

        } else {
            arrayData = {
                id: restaurant.id,
                name: restaurant.name,
                description: restaurant.description,
                main_phone: restaurant.phone,
                main_email: restaurant.email,
                street: restaurant.street,
                public_address: restaurant.publicAddress,
                suit_number: restaurant.suitNumber,
                zip_code: restaurant.zipCode,
                city_name: restaurant.city,
                state_name: restaurant.state,
                country_name: restaurant.country,
                contact_person: restaurant.contactPerson,
                website: restaurant.website,
                latitude: restaurant.latitude,
                longitude: restaurant.longitude,
                url_menu: restaurant.urlMenu,
                delivery_range: restaurant.delivery_range,
                delivery_charge: restaurant.delivery_charge,
                status: restaurant.status,
                min_value_delivery: minValueDelivery
            }
        }

        //   var arrayData = {
        //     main_phone: restaurant.phone,
        //     street: restaurant.street,
        //     suit_number: restaurant.suitNumber,
        //     zip_code: restaurant.zipCode,
        //     city_name: restaurant.city,
        //     state_name: restaurant.state,
        //     country_name: restaurant.country,
        //     delivery_range: restaurant.delivery_range,
        //     delivery_charge: restaurant.delivery_charge,
        // }

        let url = `${this.omtUrl}/${restaurant.id}`;
        let array = JSON.stringify(arrayData);
        return this.httpc.put<restaurant>(url, array);
    }

    /**
     * @author Anderson Barreto B. - Ago 6-2020
     * @version 1.0.0
     * @description Obtiene del array el valor minimo del delivery
     * @param restaurant Detalle del restaurante.
     */
    private getMinimumSeviceValueForDelivery(restaurant) {
        let minimumSeviceValue;
        if (restaurant.multiplePrice && restaurant.multiplePrice.servicesType) {
            restaurant.multiplePrice.servicesType.forEach((servicesType) => {
                if (servicesType.ostType === 2) {
                    minimumSeviceValue = servicesType.minimumSeviceValue;
                }
            });
        }


        return minimumSeviceValue;
    }

    private handleError(error: any) {
        return Promise.reject(error.message || error);
    }

    editStuatus(itemId, status) {
        console.warn('editStuatus data =)=>', itemId);

        var arrayData = {
            status: status
        }
        console.warn('arrayData ==>', arrayData);
        let url = `${this.omtUrlSdOnly}restaurant/verified/${itemId}`;
        let array = JSON.stringify(arrayData);
        return this.httpc.put<restaurant>(url, array);
    }

    getDetailRestaurant(id: number, userLang: string = null) {
        return this.httpc.get(this.detailsUrl + id);
    }

    /**
     * Metodo encargado de solicitar al web service el reporte general de estadisticas
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     */
    getGeneralStatics(userLang: string) {
        let urlDetail = this.utils.getUrlOmt() + 'sd/reports/generalStatistics';
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        })

        return this.http.get(urlDetail, options)
            .toPromise()
            .then(this.extractDataDetails)
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de solicitar al web service el reporte general de nuevos restaurantes
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     * @param from_level: string -> Variable para determinar los restaurantes de level o de directorio
     */
    getStaticsNewRestaurants(userLang: string, from_level: string) {
        let urlDetail = this.utils.getUrlOmt() + 'sd/reports/restaurantsLastMonths?from_level=' + from_level;
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        })

        return this.http.get(urlDetail, options)
            .toPromise()
            .then(this.extractDataDetails)
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de solicitar al web service el reporte general de nuevos restaurantes
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     */
    getStaticsNewUsers(userLang: string) {
        let urlDetail = this.utils.getUrlOmt() + 'sd/reports/clientsLastMonths';
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        })

        return this.http.get(urlDetail, options)
            .toPromise()
            .then(this.extractDataDetails)
            .catch(this.handleError);
    }

    cleanParametersSearch(fromSidebar: boolean) {
        this.city = "";
        this.state = "";
        this.country = "";
        this.freeText = "";
        this.level = "";
        this.page = 1;

        if (fromSidebar) {
            this.itemsPerPage = 10;
        }
    }

    /**
     * Metodo encargado de pedir al web service el listado completo de restaurantes
     * Este enviara la respuesta completa que recibe del web service para su posterior manejo 
     * en el listado
     * @param: items: number -> Numero de items que retornara por petición
     * @param: cleanSearch : boolean -> Variable para saber si se debe limpiar los parametros de busqueda
     */
    getAllRestaurants(items: number, cleanSearch: boolean) {

        //this.page = 1;
        /*if (items == 10) {
            this.itemsPerPage = items;
        }*/

        if (items != 10) {
            this.itemsPerPage = items;
        } else if (items == 10 && cleanSearch) {
            this.itemsPerPage = items;
        }

        if (cleanSearch) {
            this.page = 1;
        }

        let params = new HttpParams().set('page', this.page.toString())
            .set('items_per_page', this.itemsPerPage.toString())
            .set('city_name', this.city)
            .set('state_name', this.state)
            .set('free_text', this.freeText)
            .set('is_from_level', this.level);

        return this.httpc.get(this.allRestaurantsUrl, { params: params });
    }

    /**
     * Metodo encargado de pedir al web service el listado completo de restaurantes
     * Este enviara la respuesta completa que recibe del web service para su posterior manejo 
     * en el listado
     * @param: items: number -> Numero de items que retornara por petición
     * @param: cleanSearch : boolean -> Variable para saber si se debe limpiar los parametros de busqueda
     */
    getAllRestaurantsToEnable(items: number, cleanSearch: boolean, userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);
        //this.page = 1;
        /*if (items == 10) {
            this.itemsPerPage = items;
        }*/

        if (items != 10) {
            this.itemsPerPage = items;
        } else if (items == 10 && cleanSearch) {
            this.itemsPerPage = items;
        }

        if (cleanSearch) {
            this.page = 1;
        }

        let options = new RequestOptions({
            search: new URLSearchParams('page=' + this.page + '&items_per_page=' + this.itemsPerPage),
            headers: headers
        });


        return this.http.get(this.allRestaurantsToEnable, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    putEnableRestaurant(restaurant: any, userLang: string) {
        if (restaurant.phones[0].number != "") {
            var phone = restaurant.phones[0].number;
        }

        let headers = new Headers({});
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        var arrayData = {
            name: restaurant.name,
            main_phone: phone,
            street: restaurant.address.street,
            zip_code: restaurant.address.zipCode,
            city_name: restaurant.address.cityName,
            state_name: restaurant.address.stateName,
            country_name: restaurant.address.countryName,
            is_verified: true
        }

        let url = this.omtUrl + '/' + restaurant.id;
        return this.http
            .put(url, arrayData, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de realizar peticion de busqueda al web service de los restaurantes
     * recibe tres parametros para realizar la busqueda
     * @param: address : string -> Direccion de un restaurante
     * @param: searchData : string -> Nombre del restaurante, Si el restaurante pertenece o no a Level
     */
    getSearchRestaurants(searchData: any, address: string) {

        let fromSidebar: boolean = false;
        this.cleanParametersSearch(fromSidebar);

        let city: string = "";
        let state: string = "";
        let country: string = "";
        this.freeText = searchData.freeText;
        this.level = searchData.level;
        // this.license = searchData.license;
        this.page = 1;

        /**
         * Verificamos si la variable address contiene un guion 
         * para determinar el metodo para extraer la ciudad, estado y pais  
         *
        if (address) {
            if (address.indexOf("-") >= 0) {
                let addressBar = address.split("-");
                this.city = addressBar[0].trim();
                let stringAddres = addressBar[1].toString();
                let arrayAddress = stringAddres.split(",");
                this.state = arrayAddress[0].trim();
                this.country = arrayAddress[1].trim();
            } else {
                let arrayAddress = address.split(",");

                this.city = arrayAddress[0].trim();
                if (arrayAddress[1]) {
                    this.state = arrayAddress[1].trim();
                } else {
                    this.state = "";
                }
                if (arrayAddress[2]) {
                    this.country = arrayAddress[2].trim();
                }
            }
        }
        */
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Time-Zone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        let options = new RequestOptions({
            //search: new URLSearchParams("search_address="+searchData.searchAddress+'&city_name=' + this.city + '&state_name=' + this.state + '&free_text=' + this.freeText + '&is_from_level=' + this.level + '&items_per_page=' + this.itemsPerPage),
            search: new URLSearchParams("search_address=" + searchData.searchAddress + '&free_text=' + this.freeText + '&is_from_level=' + this.level + '&items_per_page=' + this.itemsPerPage),
            headers: headers
        });

        return this.http.get(this.allRestaurantsUrl, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    getPageRestaurants(page: number, items: number) {
        this.page = page;
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        let options = new RequestOptions({
            search: new URLSearchParams('page=' + this.page + '&items_per_page=' + items + '&city_name=' + this.city + '&state_name=' + this.state + '&free_text=' + this.freeText + '&is_from_level=' + this.level),
            headers: headers
        });

        return this.http.get(this.allRestaurantsUrl, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    getPageRestaurantsToEnable(page: number, items: number) {
        this.page = page;
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        let options = new RequestOptions({
            search: new URLSearchParams('page=' + this.page + '&items_per_page=' + items + '&city_name=' + this.city + '&state_name=' + this.state + '&free_text=' + this.freeText + '&is_from_level=' + this.level),
            headers: headers
        });

        return this.http.get(this.allRestaurantsToEnable, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    private extractDataDetails(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    private extractIndexRequest(res: Response) {
        let body = res.json();
        return body.pagination || {};
    }

    getImages(idRestaurant: number) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.omtUrl}/${idRestaurant}/images`;

        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataDetails)
            .catch(this.handleError);
    }

    public saveImage(files: any, imageForm: any, idRestaurant: number, categoryImage: number) {
        if (imageForm.id) {
            return this.putImage(files, imageForm, idRestaurant, categoryImage);
        }
        return this.postImage(files, imageForm, idRestaurant, categoryImage);
    }

    /**
     * Metodo encargado de agregar imagenes a los restaurantes
     * @param file any -> Imagen que se va a subir
     * @param imageForm any -> Formulario con la informacion de la Imagen (Title, Description)
     * @param idRestaurant any -> id del restaurante al que se le va a agregar la imagen
     * @param categoryImage number -> categoria de la imagen
     */
    private postImage(files: any, imageForm: any, idRestaurant: any, categoryImage: any) {
        // console.log('saveImage');
        // console.log('this.fileImage', files.imageCroppied);
        // console.log('imageForm', imageForm);
        // console.log('this.idRestaurant', idRestaurant);
        // console.log('categoryImage', categoryImage);
        let url = this.omtUrl + '/' + idRestaurant + '/image/';
        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        });
        if (!files) {
            console.log('failed');
            return;
        }
        const formData = new FormData();
        // if (categoryImage == 6) {
        //     console.log(files);
        //     formData.append('file', files);
        // } else {
        console.log(files.imageOriginal);
        console.log(files.imageCroppied);
        formData.append('file', files.imageOriginal);
        formData.append('cropedFile', files.imageCroppied);
        // }

        // if (files.imageCroppied) {
        //formData.append('cropedFile', files.imageCroppied);
        // }

        formData.append('title', imageForm.title);
        formData.append('description', imageForm.description);
        formData.append('category', categoryImage);

        console.log(imageForm.title);
        console.log(imageForm.description);
        console.log(categoryImage);
        console.log(formData);
        return this.httpc.post(url, formData, { headers: headers });
    }

    /**
     * Metodo encargado de editar los atributos de las imagenes a los restaurantes
     * @param: file: any -> Imagen que se va a subir
     * @param: imageForm : any -> Formulario con la informacion de la Imagen (Title, Description)
     * @param: idRestaurant: number -> id del restaurante al que se le va a agregar la imagen
     */
    private putImage(files: TreatedImage, imageForm: any, idRestaurant: number, categoryImage: number) {

        var arrayData = {
            id: imageForm.id,
            title: imageForm.title,
            description: imageForm.description,
            category: imageForm.category
        };

        let url = this.omtUrl + '/' + idRestaurant + '/image/' + imageForm.id;
        return this.httpc.put(url, arrayData);
    }

    /**
     * Metodo encargado de eliminar imagenes de los restaurantes
     * @param: idImage: number -> Id de la imagen que se va a borrar
     * @param: idRestaurant: number -> Id del restaurante al que se le va a borrar la imagen
     */
    deleteImage(idImage: any, idRestaurant: number) {
        let url = `${this.omtUrl}/${idRestaurant}/image/${idImage}`;
        return this.httpc.delete(url);
    }

    saveOpeningDate(openingDate: any, idRestaurant: number): Observable<any> {
        if (openingDate.id) {
            return this.putOpeningDate(openingDate, idRestaurant);
        }
        return this.postOpeningDate(openingDate, idRestaurant);
    }

    postOpeningDate(openingDate: any, idRestaurant: number) {

        var arrayData = {
            name: openingDate.name,
            start_date: openingDate.start_date,
            end_date: openingDate.end_date,
            is_default: openingDate.isDefault,
            comments: openingDate.description,
        }

        let array = JSON.stringify(arrayData);

        let url = `${this.omtUrl}/${idRestaurant}/opendate`;

        return this.httpc.post(url, array);
    }

    private putOpeningDate(openingDate: any, idRestaurant: number) {

        let isDefault: string = '';
        if (openingDate.is_default) {
            isDefault = 'true';
        } else {
            isDefault = 'false';
        }

        var arrayData = {
            id: openingDate.id,
            name: openingDate.name,
            start_date: openingDate.start_date,
            end_date: openingDate.end_date,
            is_default: isDefault,
            comments: (openingDate.description),
        }

        let array = JSON.stringify(arrayData);

        let url = `${this.omtUrl}/${idRestaurant}/opendate/${openingDate.id}`;

        return this.httpc.put(url, array)
    }

    getOpeningDate(idRestaurant: number) {

        let url = `${this.detailsUrl}${idRestaurant}`;
        return this.httpc.get(url);

    }

    /**
     * Metodo encargado de eliminar un horario de un restaurante
     * recibe como parametros el id de la horario y el id del restaurante
     */
    deleteOpenDate(idOpenDate: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/opendate/${idOpenDate}`;
        return this.httpc.delete(url);
    }

    /**
     * Metodo encargado de manejar que tipo de peticion se solicita
     * Agregar o Editar Horario
     */
    saveHour(hour: any, weekDays: string, idOpenDate: number, idRestaurant: number) {
        if (hour.id) {
            return this.putHour(hour, weekDays, idOpenDate, idRestaurant);
        }
        return this.postHour(hour, weekDays, idOpenDate, idRestaurant);
    }

    /**
     * Metodo encargado de agregar un horario dentro de una temporada
     * @param hour: any -> Información del horario
     * @param idOpenDate -> Id de la temporada
     * @param idRestaurant -> Id del restaurante
     */
    postHour(hour: any, weekDays: string, idOpenDate: number, idRestaurant: number) {

        var arrayData = {
            week_days: weekDays,
            is_open_all_time: hour.is_open_all_time,
            hour_from: hour.hour_from,
            hour_to: hour.hour_to,
            attention_type: hour.attention_type,
        }

        let array = JSON.stringify(arrayData);

        let url = this.omtUrl + '/' + idRestaurant + '/opendate/' + idOpenDate + '/hour';

        return this.httpc.post(url, array);
    }

    /**
     * Metodo encargado de editar un horario dentro de una temporada
     * @param hour: any -> Información del horario
     * @param idOpenDate -> Id de la temporada
     * @param idRestaurant -> Id del restaurante
     */
    private putHour(hour: any, weekDays: string, idOpenDate: number, idRestaurant: number) {

        var arrayData = {
            id: hour.id,
            week_days: weekDays,
            is_open_all_time: hour.is_open_all_time,
            hour_from: hour.hour_from,
            hour_to: hour.hour_to,
            attention_type: hour.attention_type,
        }

        let array = JSON.stringify(arrayData);

        let url = this.omtUrl + '/' + idRestaurant + '/opendate/' + idOpenDate + '/hour/' + hour.id;

        return this.httpc.put(url, array);
    }

    /**
     * Metodo encargado de capturar los horarios de una temporada
     * dentro de un determinado restaurante
     * @param= idRestaurant: number -> Id del restaurante
     * @param= idOpenDate: number -> Id de la temporada asociada al restaurante
     */
    getHour(idRestaurant: number, idOpenDate: number, userLang: string) {
        let url = `${this.detailsUrl}${idRestaurant}/opendates/${idOpenDate}/hours`;
        return this.httpc.get(url);
    }

    /**
     * Metodo encargado de eliminar un horario de una temporada
     * recibe como parametros el horario y el id del restaurante
     */
    deleteHour(hour: any, idOpenDate: number, idRestaurant: number) {
        let url = `${this.omtUrl}/${idRestaurant}/opendate/${idOpenDate}/hour/${hour}`;
        return this.httpc.delete(url);
    }

    /**
     * Metodo encargado de retornar el valor de las variables de paginación
     * para funcionamiento del mismo en el listado del restaurante 
     */
    valuesPagination() {
        return [this.page, this.itemsPerPage]
    }

    getGeolocationUser() {
        let arrayData = {};
        let url = "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyD8vBRVXi1qZgxSvwwR3wH8aStcKYazi_M";

        return this.http.post(url, arrayData)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);

    }



    public putAccount(data: any): Observable<any> {

        var arrayData = {
            account_name: data.account_name,
            contact_person: data.contact_person,
            main_phone: data.main_phone,
            main_email: data.main_email,
            street: data.street,
            suit_number: data.suit_number,
            zip_code: data.zip_code,
            city_name: data.city_name,
            state_name: data.state_name,
            country_name: data.country_name,
            nickname: data.nickname,
            restaurant_id: data.idRestaurant,
            account_id: data.idAccount

        }

        let array = JSON.stringify(arrayData);
        let url = `${this.accountUrl}/${data.idAccount}`;

        return this.httpc.put<any>(url, array);

    }

    public createLevel(data: any): Observable<any> {

        var arrayData = {
            account_name: data.account_name,
            contact_person: data.contact_person,
            main_phone: data.main_phone,
            main_email: data.main_email,
            street: data.street,
            suit_number: data.suit_number,
            zip_code: data.zip_code,
            city_name: data.city_name,
            state_name: data.state_name,
            country_name: data.country_name,
            nickname: data.nickname,
            restaurant_id: data.idRestaurant

        }

        let array = JSON.stringify(arrayData);
        let url = this.omtUrlSdOnly + 'account';

        return this.httpc.post<any>(url, array);

    }

    public createLevelTrue(data: any, isCreate: boolean): Observable<any> {

        var arrayData = {
            nickname: data,
            is_by_create: isCreate
        }

        let array = JSON.stringify(arrayData);
        let url = this.omtUrlSdOnly + 'queueSync';

        return this.httpc.post<any>(url, array);

    }
    /**
   * Permite obtener la información del listado de restaurantes
   */
    public getDataRestaurantList() {
        if (!this.restaurantsList) {
            return JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.RESTAURANT_LIST));
        }
        return this.restaurantsList;

    }

    /**
     * Método que permite guardar la información de los restaurantes existentes
     */
    public setDataRestaurantList(tmpRestaurants) {
        this.restaurantsList = tmpRestaurants;
        localStorage.setItem(Constants.LOCAL_STORAGE_DATA.RESTAURANT_LIST, JSON.stringify(this.restaurantsList));
    }

    /**
     * @description Se comunica con el ws enviando la informecionde cerrar o no el restaurante
     * @param idRestaurant id del restaurante
     * @param description descripcion del restaurante explicando por que se cerro
     * @param state estado del restaurante (0 abierto 1 cerrado)
     */
    public closeOrOpenRestaurant(idRestaurant: number, description: string, state: number) {

        const data = {
            rest_description_closed: description,
            rest_closed: state
        }
        let url = `${this.omtUrl}/${idRestaurant}`;
        return this.httpc.put(url, data)
    }
}
