import { Component } from '@angular/core';
import { UserLocationService } from './services/user-location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'app';
  constructor(private userLocation: UserLocationService ) {
    this.userLocation.findLocationByNetwork()
  } 
}
