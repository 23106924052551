import * as numeral from 'numeral';

import { Pipe, PipeTransform } from '@angular/core';
import { UserLocationService } from 'app/services/user-location.service';

@Pipe({
    name: 'currencySD'
})
export class CurrencyPipe implements PipeTransform {

    constructor(private userLocation: UserLocationService) {

    }

    transform(amount: string, country: string = null): any {
        if (this.userLocation.isColombia(country)) {
            return this.format(amount)
            //return numeral(amount).format('$0,0.00');
        } else {
            return numeral(amount).format('$0,0.00');
        }
    }

    /**
     * @author Anderson Barreto B. Jun - 16 - 2020
     * @version 1.0.0
     * @description Formatea en moneda en pesos 
     * @param input contiene el valor 
     */
    format(input: any) {
        
        let integerPart;
        let decimalPart;

        if (typeof input === 'undefined' || input === 'NaN' || input === 0) {
            return '$0';
        }
        
        if (!isNaN(input)) {
            input = String(input)
            
            const pointPosition = input.indexOf('.');

            // si trae una parte decimal la separa
            if (pointPosition >= 0) {

                decimalPart = input.substring(pointPosition);
                
                integerPart = input.substring(0, pointPosition);
              
                decimalPart = decimalPart.split('').reverse().join('').replace(/^0+/, '')
                
                decimalPart = decimalPart.split('').reverse().join('');
                
                integerPart = integerPart.replace(/\./g, '');
            } else {
                integerPart = input;
                decimalPart = 0;
            }
            
        }

        integerPart = String(integerPart);
        decimalPart = String(decimalPart);

        integerPart = integerPart.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');

        integerPart = integerPart.split('').reverse().join('').replace(/^[\.]/, '');
    

        if (decimalPart === '.' || decimalPart === 0 || decimalPart === '0') {

            input = integerPart;

        } else {
            
            input = `${integerPart}${decimalPart}`
        }

        return `$${input}`;


    }

}
