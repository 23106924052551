<div class="modal-header">
    <h4 class="modal-title pull-left">Level Form</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- componente que muestra el formulario para crear ò asignar  la cuenta -->
      <div *ngIf="typeModal == 'createAccount'">
        <level-form 
        [accountName]="accountName"
        [contactPerson]="contactPerson"
        [mainPhone]="mainPhone"
        [mainEmail]="mainEmail"
        [street]="street"
        [suitNumber]="suitNumber"
        [zipCode]="zipCode"
        [cityName]="cityName"
        [stateName]="stateName"
        [countryName]="countryName"
        [nickname]="nickname"
        [idRestaurant]="idRestaurant"
        [idAccount]="idAccount"
        [res]="res"
        (notify)="onNotify($event)"
        ></level-form>
      </div>

      <!-- componente que muestra la lista de cuentas -->
      <div *ngIf="typeModal == 'listAccount'">
        <level-list
        [list]="list"
        [res]="res"
        (notify)="onNotify($event)"
        ></level-list>
      </div>

  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-default" (click)="save(res)">Close</button>-->
  </div>

