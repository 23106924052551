/**
 * Order State class model
 */
export class OrderStateModel{

	/**
	 * identificador de estado de pedido
	 * RECEIVED
	 * PREPARED
	 * DELIVERY_ON_SITE
	 */
    stateConstant: number;
	
	
	/**
	 * Estado de pedido
	 */
	
	state: string;

	isActual: boolean;

	isEnabled: boolean;
	
	constructor (private inStateConstant: number, private value: string, private actual: boolean, private enabled: boolean){
        this.stateConstant = inStateConstant;
		this.state = value;
		this.isActual = actual;
		this.isEnabled = enabled;
	}

}
