<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section Class="widget">

            <div class="row">
                <div class="col-lg-12 col-xs-12">
                    <section widget class="widget">
                        <header>
                            <h4>
                                {{ 'Filters' | translate }}
                            </h4>
                        </header>
                        <div class="widget-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row filters">
                                        <div class="col-sm-3 col-xs-12">
                                            <div class="input-group">
                                                <input type="text" [(ngModel)]="filterNotifications.name" #searchInput
                                                    class="form-control" id="inputSearch"
                                                    placeholder="{{ 'NAME_NOTIFICATION' | translate }}">
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-xs-12">
                                            <select class="form-control select2" [(ngModel)]="filterNotifications.status">
                                                <option id="1" value="" disabled> {{'Status' | translate}} </option>
                                                <option value=""> {{ 'SelectedAll' | translate }} </option>
                                                <option *ngFor="let notification of notificationsStatus" [ngValue]="notification.number">
                                                {{notification.name | translate}}</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-4 col-xs-12">
                                            <div class="input-group">
                                                <input class="form-control" placeholder="{{ 'DATE_RANGE' | translate }}"
                                                    bsDaterangepicker #dpr="bsDaterangepicker"
                                                    [(ngModel)]="filterNotifications.dateRangePicker"
                                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-red' }">
                                            </div>
                                        </div>

                                        <div class="col-sm-2 col-xs-12">
                                            <button class="btn btn-info mb-xs" (click)="getNotificationsList()">{{
                                                'Search' | translate }}</button>
                                        </div>

                                    </div>
                                    <br>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        </section>
        <section Class="widget">
            <div class="table-responsive">
                <div class="form-group row">
                    <div class="col-md-12 col-xs-12">
                        <div class="text-xs-right pull-left">
                            <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                                <em class="fa fa-arrow-left"></em> {{ 'Back' | translate }}
                            </a>
                            <button class="btn btn-success mb-xs" (click)="refreshNotifications()">
                                <em class="fa fa-refresh"></em> {{ 'REFRESH' | translate }}
                            </button>
                        </div>
                        <div class="text-xs-left pull-right">
                            <button class="btn btn-success mb-xs" role="button"
                                title="{{ 'ADD_NOTIFICATIONS' | translate }}" (click)="showModalNotifications()">
                                <em class="fa fa-plus text-success"></em> {{ 'ADD_NOTIFICATIONS' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <table class="table journals">
                    <thead>
                        <tr>
                            <th>{{ 'CREATION_DATE' | translate }}</th>
                            <th>{{ 'EXECUTION_END_DATE' | translate }}</th>
                            <th>{{ 'Status' | translate }}</th>
                            <th>{{ 'EndedDate' | translate }}</th>
                            <th>{{ 'NAME_NOTIFICATION' | translate }}</th>
                            <th>{{ 'Actions' | translate }}</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notification of notificationsList">

                            <td>
                                {{notification.createdAt | dateTimeLocalize | async}}
                            </td>
                            <td>
                                {{notification.beginningDate | dateTimeLocalize | async}}
                            </td>
                            <td>
                                <div class="text-overflow" *ngFor="let notificationS of notificationsStatus">
                                    <ng-container *ngIf="notificationS.number === notification.status">{{notificationS.name | translate}}</ng-container>
                                </div>
                            </td>
                            <td>
                                <ng-container *ngIf="!notification.lastDate"> {{ 'DATE_NOT_AVAILABLE' | translate}} </ng-container>
                                <ng-container *ngIf="notification.lastDate">  {{notification.lastDate | dateTimeLocalize | async }}</ng-container>
                               
                            </td>
                            <!-- <td>
                                <div class="text-overflow">{{notification.message}}</div>
                            </td> -->
                            <td>
                                <div class="text-overflow">{{notification.name}}</div>
                            </td>
                            <td>

                                <button class="btn btn-secondary mb-xs" type="button" title="{{ 'EDIT_THE_NOTIFICATION' | translate }}" (click)="editNotification(notification, false, true)">
                                    <em class="fa fa-pencil-square-o text-warning"></em> {{ 'Edit' | translate }}</button>
                                <button *ngIf="notification.status === 0 || notification.status === 3" class="btn btn-secondary mb-xs" type="button" title="{{ 'START_THE_NOTIFICATION' | translate }}" (click)="editNotification(notification, true)">
                                    <em class="fa  fa-paper-plane text-danger"></em> {{ 'Confirm' | translate }}</button>
                                <button class="btn btn-secondary mb-xs" type="button" title="{{ 'STOP_THE_NOTIFICATION' | translate }}" (click)="stopMassiveNotification(notification.id, notification.context)">
                                    <em class="fa fa-hand-paper-o text-danger"></em> {{ 'STOP' | translate }}</button>
                                <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Delete' | translate }}" (click)="deleteMassiveNotification(notification.id)">
                                    <em class="fa fa-eraser text-danger"></em> {{ 'Delete' | translate }}</button>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div *ngIf="notificationsList && notificationsList.length === 0">
                    <alert type="danger">{{'NoDataToShow' | translate }}</alert>
                </div>
                <div bsModal #addNotification="bs-modal" class="modal fade" tabindex="-1" role="dialog"
                    aria-labelledby="mySmallModalLabel" aria-hidden="true" aria-labelledby="mySmallModalLabel"
                    aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true, keyboard: false}">

                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div>
                                    <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                                        {{ 'NOTIFICATIONS' | translate }}
                                    </h4>
                                </div>
                                <button type="button" class="close pull-right" aria-label="Close"
                                    (click)="hideAddNotificationsModal()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="form-horizontal" [formGroup]="notificationForm" novalidate>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-5 ">
                                                <label>{{ 'NAME_NOTIFICATION' | translate }}
                                                    <strong class="reqe">*</strong>
                                                </label>
                                                <input formControlName="name" type="text"
                                                    class="form-control form-control-sm pull-xs-right"
                                                    placeholder="{{ 'EXAMPLE_NAME_NOTIFICATION' | translate }}">
                                            </div>
                                            <div class="col-md-6 ">
                                                <label>{{ 'SUBJECT_NOTIFICATION' | translate }}
                                                    <strong class="reqe">*</strong>
                                                </label>
                                                <input formControlName="subject" type="text"
                                                    class="form-control form-control-sm pull-xs-right"
                                                    placeholder="{{ 'EXAMPLE_SUBJECT_NOTIFICATION' | translate }}">
                                            </div>

                                            <div class="col-md-12 col-xs-12 col-md-offset-2">
                                                <label>{{ 'MESSAGE_NOTIFICATION' | translate }}
                                                    <strong class="reqe">*</strong>
                                                </label>
                                                <textarea type="text" formControlName="message" class="form-control"
                                                    placeholder="{{ 'EXAMPLE_MESSAGE_NOTIFICATION' | translate }}"
                                                    rows="5"></textarea>
                                            </div>

                                            <br>

                                            <div class="col-md-12 col-xs-12 col-md-offset-2 form-check">
                                                <input type="checkbox" value="true" formControlName="confirm"
                                                    class="mr-top" id="confirm-notify" checked>
                                                <label class="form-check-label">{{'WISH_NOTIFY_NOW' | translate
                                                    }}</label>
                                            </div>
                                            <ng-container *ngIf="notificationForm.value.confirm === false">
                                                <label for="" class="col-md-offset-2"
                                                    *ngIf="!notificationForm.value.start_date && !notificationForm.value.end_date">
                                                    <div class="flx yellow alert alert-warning alert-dismissible">
                                                        {{'SELECT_DATE_NOTIFICATION' | translate}}
                                                    </div>
                                                </label>
                                                <div class="col-md-5 col-xs-4 col-md-offset-2">
                                                    <label>{{ 'StartDate' | translate }} <strong
                                                            class="reqe">*</strong></label>
                                                    <ngb-datepicker formControlName="start_date"></ngb-datepicker>
                                                </div>
                                                <div class="col-md-5 col-xs-4 col-md-offset-2">
                                                    <label>{{ 'EndDate' | translate }} <strong
                                                            class="reqe">*</strong></label>
                                                    <ngb-datepicker formControlName="end_date"></ngb-datepicker>
                                                </div>


                                                <div class="form-group row">
                                                    <div class="col-md-6 col-xs-12">
                                                        <label>
                                                            <strong>{{ 'HourFrom' | translate }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6 col-xs-12">
                                                        <label>
                                                            <strong>{{ 'HourTo' | translate }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6 col-xs-6">
                                                        <timepicker [(ngModel)]="start_time" [ngModelOptions]="{standalone:
                              true}">
                                                        </timepicker>
                                                    </div>
                                                    <div class="col-md-6 col-xs-6">
                                                        <timepicker [(ngModel)]="end_time" [ngModelOptions]="{standalone:
                              true}"></timepicker>
                                                    </div>
                                                </div>
                                            </ng-container>


                                            <div class="col-md-5 col-xs-4 col-md-offset-2 ">
                                                <label>{{ 'Select' | translate }} {{ 'Rules' | translate }} <strong
                                                        class="reqe">*</strong>
                                                </label>
                                                <div class="form-check" *ngFor="let rule of listOfRules">
                                                    <input formControlName="assimilated_rule" type="radio"
                                                        name="assimilated_rule" [value]="rule.id"
                                                        (click)="optionSelected(rule)">
                                                    <label class="form-check-label" for=""> {{rule.name}}</label>
                                                    <ng-container
                                                        *ngIf="TYPE_OF_NOTIFICATION.RULE_PTA == rule.type_rule">
                                                        <!-- <select formControlName="record_id" class="form-control"
                                                            *ngIf="TYPE_OF_NOTIFICATION.RULE_PTA == type_notify">
                                                            <option value="">{{ 'Select' | translate }}</option>
                                                            <option
                                                                *ngFor="let item of dataPTA | orderBy:'name'; let i = index"
                                                                value="{{item.id}}">
                                                                {{item.frpName}} ({{'TOTAL' | translate}}:
                                                                {{item.qtyClient}})
                                                            </option>
                                                        </select> -->
                                                        <div class="dropdown" *ngIf="TYPE_OF_NOTIFICATION.RULE_PTA == type_notify">
                                                            <button class="btn btn-info dropdown-toggle btn-selected-pta" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span *ngIf="!ptaSelected.length">{{ 'Select' | translate }}</span>
                                                                <span>{{getPtaSelected()}} </span>
                                                            </button>
                                                            <ul class="dropdown-menu pta-select-container" aria-labelledby="dropdownMenuButton">
                                                              <li *ngFor="let item of dataPTA | orderBy:'name'; let i = index" class="d-flex m-1"><input (change)="selectPta($event, item)" type="checkbox" />
                                                                <span class="ml-2">{{item.frpName}} ({{'TOTAL' | translate}}:
                                                                    {{item.qtyClient}})</span>
                                                                </li>
                                                            </ul>
                                                          </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-xs-4 col-md-offset-2 " [ngClass]="{'disable-content': type_notify === null}">
                                                <label>{{ 'Select' | translate }} {{ 'Users' | translate }} <strong
                                                        class="reqe">*</strong>
                                                </label>
                                                <div class="form-check">
                                                    <input class="form-check-input" value="0" type="radio" name="user_type"
                                                        [value]="0" checked formControlName="user_type" (change)="getCustomers($event)">
                                                    <label class="form-check-label" for="exampleRadios1">{{ 'ALL_USERS'
                                                        | translate }}<span *ngIf="quantityUsersToNotified && userTypeSelected === '0'">:</span></label>
                                                        <p class="quantity-user-notificated" *ngIf="quantityUsersToNotified >=0 && userTypeSelected === '0'">{{quantityUsersToNotified}} <span *ngIf="quantityUsersToNotified === null" class="lds-dual-ring"></span></p>

                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input" value="2" type="radio" name="user_type"
                                                        [value]="2" formControlName="user_type" (change)="getCustomers($event)">
                                                    <label class="form-check-label" for="exampleRadios1">{{ 'NEW_USERS'
                                                        | translate }}<span *ngIf="quantityUsersToNotified && userTypeSelected === '2'">:</span></label>
                                                        <p class="quantity-user-notificated" *ngIf="quantityUsersToNotified >=0 && userTypeSelected === '2'">{{quantityUsersToNotified}} <span *ngIf="quantityUsersToNotified === null" class="lds-dual-ring"></span></p>
                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input"value="1" type="radio" name="user_type"
                                                        [value]="1" formControlName="user_type" (change)="getCustomers($event)">
                                                    <label class="form-check-label" for="exampleRadios1">{{ 'OLD_USERS'
                                                        | translate }}<span *ngIf="quantityUsersToNotified && userTypeSelected === '1'">:</span></label>
                                                        <p class="quantity-user-notificated" *ngIf="quantityUsersToNotified >=0 && userTypeSelected === '1'">{{quantityUsersToNotified}} <span *ngIf="quantityUsersToNotified === null" class="lds-dual-ring"></span></p>

                                                </div>

                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        formControlName="notify_unregistered_devices">
                                                    <label class="form-check-label"
                                                        for="exampleCheck1">{{'UNREGISTERED_USERS' | translate}}</label>
                                                </div>
                                                <br>
                                                <!-- [(ngModel)]="selectedContact.status"  -->
                                                <label>{{ 'SECTION_TO_BE_CLICKED' | translate }} *</label>
                                                <div class="form-group">
                                                    <select class="form-control select2" formControlName="section">
                                                        <option id="1" value="1" selected>
                                                            {{ 'DETAIL_RESTAURANT' | translate }}
                                                        </option>
                                                        <option id="2" value="2"> {{ 'COUPONS_LIST' | translate }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <label>{{ 'CANT_USERS' | translate }}</label>
                                                <input formControlName="cant_clients" type="number"
                                                    class="form-control form-control-sm pull-xs-right" id="search"
                                                    placeholder="{{ 'EXAMPLE_QUANTITY' | translate }}">
                                            </div>

                                        </div>

                                    </div>

                                    <div class="flx">
                                        <button type="submit" [disabled]="!notificationForm.valid"
                                            (click)="onSubmitNotification(notificationForm.value)"
                                            class="btn btn-success mb-xs float-right" [hidden]="processingResponse">{{
                                            'Save' | translate }}</button>
                                        <button class="btn btn-gray flx float-right" [hidden]="!processingResponse"
                                            [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                                            <div class="lds-ellipsis">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- Botones Paginación -->
<div class="form-group row" *ngIf="pagination && pagination.totalItems > 0">
    <div class="col-lg-12 col-md-12 d-flex justify-content-center">
        <label>{{ 'Page' | translate }} {{pagination.currentPage}}</label>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
        <pagination class="pagination pagination-md justify-content-end active"
            [totalItems]="pagination.totalItems" [maxSize]="maxSize.maxSizePagination"
            [itemsPerPage]="pagination.itemsPerPage" [lastText]="lastPag" [firstText]="firstPag"
            [previousText]="prevPag" [nextText]="nextPag" (pageChanged)="getNotificationsList($event)"
            [boundaryLinks]="true" [rotate]="true">
        </pagination>
    </div>
</div>