/**
 * Esta clase es la que se utiliza para hace la peticion a un web services 
 */
export class Pagination {
    
    public maxSizePagination: number;

    // inicializamos variables
    constructor() {
        this.maxSizePagination = 10;
    }
}