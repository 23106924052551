import { OrdersService } from 'app/services/orders.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Component, OnInit } from '@angular/core';
import { RestaurantService } from 'app/services/restaurant.service';
import { detailRestaurant } from 'app/entity/detailRestaurant';
import { Observable, Subscriber } from 'rxjs';
import { UserLocationService } from 'app/services/user-location.service';
import { Constants } from 'app/app.constants';
import { OrderStateModel } from 'app/models/orderState/orderState';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'app/services/alerts.service';

@Component({
    selector: 'app-detail-order-modal',
    templateUrl: './detail-order-modal.component.html',
    styleUrls: ['./detail-order-modal.component.sass']
})
export class DetailOrderModalComponent implements OnInit {

    /**
     * Order's data
     */
    public order: any;

    /**
     * Store additional details form the order
     */
    public additionalDetailData: any;
    public restaurantDetails: detailRestaurant;
    public isLoadingDataRestaurant: boolean;
    public orderTypeConstants: any;
    public phoneOrderTypeConstants: { noPhone: number; pickup: number; delivery: number; table: number; turn: number; turnAutomatic: number; };
    public DINE_IN_CONSTANTS: { TABLE: number; BEACON: number; CONSECUTIVE_DAILY: number; };
    public isColombia: boolean;
    public shipping: number;
    public subTotal: number;
    public restaurantCountry: string;
    public orderComment: string;
    public couponsDiscount: any;
    public tip: number;
    public arrayTaxes: Array<any>;
    /**
     * Estados de la orden
     */
    private dataStates: Array<OrderStateModel>;
     /**
     * Ultimo estado de la orden
     */
    public lastOrderState: number;
    /**
     * Llaves de traducción
     */
    private translatedKeys: Array<string> = [];
    /**
     * Traducciones de los estados de una orden
     */
    private translationKeys: Array<string> = [
        'RECEIVED_STATUS',
        'COMPLETED',
        'PICKED_UP',
        'DELIVERED_STATUS',
        'CHANGE_STATE_MESSAGE',
        'READY_PICKUP',
        'OUR_DELIVERY',
        'TABLE_READY',
        'BEING_PREPARED'
    ];
    /**
     * Indica si se debe mostrar el texto de procesando
     */
    public orderStatusButtonProcessing: boolean;
    public orderAddressComment: string;

    constructor(
        private bsModalRef: BsModalRef,
        private restaurantService: RestaurantService,
        private orderService: OrdersService,
        private userLocation: UserLocationService,
        private translate: TranslateService,
        private alertService: AlertsService
    ) {
        this.restaurantDetails = new detailRestaurant();
        this.isLoadingDataRestaurant = false;
        this.orderTypeConstants = Constants.orderType;
        this.phoneOrderTypeConstants = Constants.PhoneOrderType;
        this.DINE_IN_CONSTANTS = Constants.DINE_IN_TYPE;
        this.isColombia = false;
        this.shipping = 0;
        this.arrayTaxes = [];
        /** Obtiene las traduciones disponibles */
        this.translate.get(this.translationKeys).subscribe(
            value => {
                this.translatedKeys = value;
            }
        );
        this.orderStatusButtonProcessing = false;
    }

    ngOnInit() {
        this.getOrderDetail();
        this.getRestaurantDetails();
        this.isColombia = this.userLocation.isColombia();
        this._loadDataStates();
        
    }
    /**
     * 
     * Carga los estados  que puede tener una orden
     * @author William Alarcon - Octu. 20 - 2020
     * @version 1.0.0
     * 
     */
    private _loadDataStates(): void{
        if (this.order.orderPhoneType === 1) {
            // Pickup
            this.dataStates = [
                new OrderStateModel(Constants.CONSTANTS_STATE.RECEIVED, this.translatedKeys['RECEIVED_STATUS'], false, false),
                new OrderStateModel(Constants.CONSTANTS_STATE.BEING_PREPARED, this.translatedKeys['BEING_PREPARED'], true, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.PREPARED, this.translatedKeys['READY_PICKUP'], false, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.DELIVERED, this.translatedKeys['PICKED_UP'], false, true)
            ];
        } else if (this.order.orderPhoneType === 2) {
            // Delivery
            this.dataStates = [
                new OrderStateModel(Constants.CONSTANTS_STATE.RECEIVED, this.translatedKeys['RECEIVED_STATUS'], true, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.BEING_PREPARED, this.translatedKeys['BEING_PREPARED'], false, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.PREPARED, this.translatedKeys['OUR_DELIVERY'], false, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.DELIVERED, this.translatedKeys['DELIVERED_STATUS'], false, true)
            ];
        } else if (this.order.orderPhoneType === 3 || this.order.orderPhoneType === 4 || this.order.orderPhoneType === 5) {
            // Dine In
            this.dataStates = [
                new OrderStateModel(Constants.CONSTANTS_STATE.RECEIVED, this.translatedKeys['RECEIVED_STATUS'], true, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.BEING_PREPARED, this.translatedKeys['BEING_PREPARED'], false, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.PREPARED, this.translatedKeys['TABLE_READY'], false, true),
                new OrderStateModel(Constants.CONSTANTS_STATE.DELIVERED, this.translatedKeys['COMPLETED'], false, true)
            ];
        }
    }

    /**
     * Cierra la modal
     * @author William Alarcon - Octu. 20 - 2020
     * @version 1.0.0
     */
    public closeModal() {
        this.bsModalRef.hide();
    }

    /**
     * Obtain additional details of the order
     * @author Alvaro Felipe Garcia Mendez - Sept. 6 - 2019
     * @version 1.1.0
     */
    private getOrderDetail(): void {
        // Indica cual es el estado de la orden
        if (this.order['orderAllStatus'].length === 0) {
            this.lastOrderState = Constants.CONSTANTS_STATE.PENDING;
        } else {
            this.lastOrderState = this.order['orderAllStatus'][this.order['orderAllStatus'].length - 1]['status'];
        }
        this.orderService.getOrderDetail(this.order['id'], this.order['restaurantId']).subscribe((x: any) => {
            if (!x['errors']) {
                console.log('x', x);
                this.additionalDetailData = x['data'];
                this.subTotal = x['data'].order.subTotal;
                this.tip = x['data'].order.invTip;
                this.taxesManagement(x['data']['order']['tax_detail']);
                this.orderComment = x.data.order.ordComment;
                this.orderAddressComment = x.data.order.deli_comment;
                if (x.data.order.discountCouponsByOrder.length) {
                    this.couponsDiscount = x.data.order.discountCouponsByOrder;
                } else if (x.data.order.discountCouponsByProduct.length) {
                    this.couponsDiscount = x.data.order.discountCouponsByProduct;
                }
                if (x['data'].order.shipping) {
                    console.log('conShipping');
                    this.shipping = x['data'].order.shipping;
                }

                for (const product of this.additionalDetailData['orderDetail']) {
                    for (const modifiers of product.orderObservation.products) {
                        modifiers.isCollapsed = true;
                    }
                }
            } else {
                console.error(x['errors']);
            }
        });
    }

        /**
     * @author Anderson Barreto B. - Sept. 30-2020
     * @version 1.0.0
     * @description metodo encargado de agrupar y sumar los taxes discriminados por porcentaje
     * @param taxes Recibe array con todos los taxes de la orden
     */
    private taxesManagement(taxes: any[]): void {
        taxes.forEach((tax) => {

            if (!tax.odeTaxInclude) {
                if (!this.arrayTaxes.find(element => element.percent === tax.percent)) {
                    this.arrayTaxes.push(tax)
                } else if (this.arrayTaxes.find(element => element.percent === tax.percent)) {
                    const index = this.arrayTaxes.findIndex(element => element.percent === tax.percent);
                    this.arrayTaxes[index].value += tax.value;
                }
            } else if (tax.hasSmartTax) {
                if (taxes.find(element => element.orderDetail === tax.orderDetail)) {
                    const index2 = taxes.findIndex(element => element.orderDetail === tax.orderDetail);
                    if (this.arrayTaxes.find(element => element.percent === taxes[index2].percent)) {
                        const index3 = this.arrayTaxes.findIndex(element => element.percent === taxes[index2].percent)
                        this.arrayTaxes[index3].value += taxes[index2].value;
                    } else if (!this.arrayTaxes.find(element => element.percent === taxes[index2].percent)) {
                        this.arrayTaxes.push(taxes[index2]);
                    }
                }
            }


            /* if (!tax.odeTaxInclude || tax.hasSmartTax) {
                if (!this.arrayTaxes.find(element => element.percent === tax.percent)) {
                    this.arrayTaxes.push(tax)
                } else if (this.arrayTaxes.find(element => element.percent === tax.percent)) {
                    let index = this.arrayTaxes.findIndex(element => element.percent === tax.percent)
                    this.arrayTaxes[index].value += tax.value;
                }
            } */

        });
    }

    private getRestaurantDetails(): void {
        this.isLoadingDataRestaurant = false;
        this.restaurantService.getDetailRestaurant(this.order['restaurantId'])
            .subscribe((x: any) => {
                if (!x['errors']) {
                    this.restaurantDetails = x['data'];
                    this.restaurantCountry = x['data'].address.countryName
                    this.userLocation.findLocationByNetwork().then(() => {
                        this.getAddressRestaurant(this.order).subscribe(x => {
                            // reasigna la nueva dirección sin el país siempre
                            // y cuando sea el país el mismo en el que se encuentra
                            this.restaurantDetails['address']['naturalAddress'] = x;
                        });
                        this.isLoadingDataRestaurant = true;
                    });
                } else {
                    console.error(['errors']);
                }
            })

    }

    /**
     * permite calcular si el restaurante esta en el mismo pais del usuario
     */
    public getAddressRestaurant(restaurantAddress) {
        const that = this;
        const contryUser = this.userLocation.getCurrentCountry();
        // permite imprimir la direccion con o sin el nombre del pais
        const getTextAdressRestaurant = function (isSameCountry: boolean): string {
            let textAddress: string;
            if (isSameCountry) {
                textAddress = restaurantAddress.restaCityName + ' \n' +
                    restaurantAddress.restaStateName + ' \n' +
                    restaurantAddress.restaZipCode;
            } else {
                textAddress = restaurantAddress.restaCityName + ' ' +
                    restaurantAddress.restaStateName + ' ' +
                    restaurantAddress.restaZipCode + ' ' +
                    restaurantAddress.restaCountryName;
            }
            return textAddress;
        };

        return new Observable<string>((observer: Subscriber<string>) => {
            observer.next(getTextAdressRestaurant(true));

            // permite verificar si son el mismo pais para mostrar en el pais en la direccion del restaurante
            const checkIfIsTheSameCountry = function (countryRest: string, countryCustomer: string) {
                if (that.userLocation.isUSA(countryCustomer)) {
                    const isUsa = that.userLocation.isUSA(countryRest);
                    if (!isUsa) {
                        observer.next(getTextAdressRestaurant(false));
                    }
                } else {
                    if (countryRest.toLowerCase().trim() !== countryCustomer.toLowerCase().trim()) {
                        observer.next(getTextAdressRestaurant(false));
                    }
                }
            };
            if (contryUser !== null) {
                checkIfIsTheSameCountry(restaurantAddress.restaCountryName, contryUser);
            }
        });
    }

     /**
     * Envia y recibe lista de modelos para el componente de estados de pedido
     * @author William Alarcon - Oct. 20 - 2020
     * @version 1.1.0
     * @param dataModel recibe lista de modelos del compoente
     */
    public chargeData(dataModel?: Array<OrderStateModel>) {

        if (dataModel !== undefined && dataModel != null) {
            this.dataStates = dataModel;
        }
        return this.dataStates;
    }

    /**
     * Envia al server la informacion recibida por emit (sendData)
     * @author William Alarcon - Oct. 20 - 2020
     * @version 1.0.0
     * @param status valor del estado a cambiar
     */
    public sendDataOrderState(status: number) {
        this.orderStatusButtonProcessing = true;
        const statusInfo = {
            'order_id': this.order['id'],
            'restaurant_id': this.order['restaurantId'],
            'status': status,
            'client_id': this.order['clientId'],
            'level_lite_upgrade': true
        };

        this.orderService.setOrderStatus(statusInfo).then((result) => {
            this.orderStatusButtonProcessing = false;
            this.alertService.successAdd("", "CHANGE_STATE_MESSAGE");
            if (status === Constants.CONSTANTS_STATE.DELIVERED) {
                this.closeModal();
            }
        }).catch((error) => {
            console.log(error);
        });
    }
}
