import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';

import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UtilsComponent } from '../../utils/utils';
import { Constants } from '../../app.constants';
import { restaurant } from '../../entity/restaurant';
import { ConfigRestaurantService } from '../../services/configRestaurant.service';
import { RestaurantService } from '../../services/restaurant.service';
import { GlobalEventsService } from '../../utils/globalEvents.service';
import { globalRegex } from '../../utils/globalRegex';
import { SweetAlertService } from '../../utils/sweetAlert.service';
import { EditRestaurantComponent } from '../editRestaurant/editRestaurant';
import { UserLocationService } from '../../services/user-location.service';

@Component({
    selector: 'config-component',
    templateUrl: './configComponent.html',
    styleUrls: ['./configComponent.scss'],
})

export class ConfigComponent {

    dataUponDelivery: any;
    uponDeliveryCreated: any;
    statusUponDelivery: boolean;

    public statusCreditCardPayment: boolean;
    /**
     * indica si se esta usando el menu extern
     * true se esta usando
     */
    private idRestaurant: any;
    data: EditRestaurantComponent;
    colorCodeScript: any;
    colorCodeBackground: any;

    //Variable que permite activar o desactivar el checkbox del menu online
    public statusCheck: boolean;

    //Variable que permite activar o desactivar el checkbox del whiteLabel
    public statusCheckWhiteLabel: boolean;
    url: string;

    //Variable para manejo de idioma
    userLang: any;

    email: string;

    //variable que me ayuda verificar si exixte un correo en el detalle del restaurante
    isMailActi: boolean;

    // variable para desabilitar el boton de guardar mientra se esta enviando el formulario del restaurante
    buttonStatus = false;

    //Variables para creación de formularios
    public emailForm: FormGroup;

    /**
     * Variable para almacenar el arreglo de tipos de dine in que se puede activar
     */
    public dineInTypes: [any];

    /**
     * Variable para almacenar el tipo de dine elegido por el restaurante
     */
    public dineInTypeId: string;

    /**
     * Variable para almacer el array de marcas q tiene el restaurante para el menu externo
     */
    public brandsArray: Array<any>;
    /**
     * Variable para almacer la marca elegido por el restaurante para el menu externo
     */
    public brandSelect: string;

    /**
     * Variable para almacer el id del brand en donde se va hacer la copia 
     * ejemplo copiar de level a MENU ONLINE se guardaria el id del MENU ONLINE
     */
    public brandTo: string;

    /**
     * Variable utilizada para mostrar el loading cuando se está haciendo la copia de precios
     * de un menú a otro
     */
    public processingResponse: boolean;

    /**
     * Contiene la opción que el usuario va a activar o desacativar las opciones puede ser 
     * Menu externo o marca blanca  (Constants.MENU_ONLINE | Constants.WHITE_LABEL)
     * 
     */
    public selectedBrandConfig: string;

    /**
     * Objeto que contiene el tipo de brands con sus determinadas constantes.
     */
    public typesBrands = {
        'BRAND_MENU_ONLINE': Constants.BRAND_ALIAS_ONLINE.name,
        'BRAND_WHITE_LABEL': Constants.BRAND_ALIAS_WHITE_LABEL.name
    }

    @Input() restaurant: any;
    @Output() close = new EventEmitter();
    public modalRef: BsModalRef;

    /**
     * Mensaje para mostrar en el UponDelivery
     */
    public messageUponDelivery: string;

    /**
     * Aloja el valor del API Key secreto de pushy para notificaciones en white label
     */
    public APIKeyPushy: string;

    /**
     * Almacena la clave secreta de facebook con la que se hace login en omt, sirve para que el usuario pueda solicitar el borrado de datos.
     */
    public facebookSecretKey: string;

    public storeLinks = {
        appStore: '',
        playStore: ''
    }

    public minimumSeviceValueForDelivery: any;

    /**
     * Indica si el restaurante es de colombia.
     */
    public isFromColombia: boolean;
    public uponDeliveryPaymentTypeDebitCard: boolean;
    public uponDeliveryPaymentTypeCash: boolean;
    public uponDeliveryPaymentTypeCreditCard: boolean;
    
    /**
     * Almacena el dominio propio del restaurante
     */
    public domainUrl: string;

    constructor(
        private configRestaurantService: ConfigRestaurantService,
        public utils: UtilsComponent,
        public route: ActivatedRoute,
        private toastr: ToastrService,
        private translate: TranslateService,
        private restaurantService: RestaurantService,
        private modalService: BsModalService,
        private globalEventsService: GlobalEventsService,
        private sweetAlert: SweetAlertService,
        private userLocation: UserLocationService
    ) {
        this.colorCodeScript = "#000000";
        this.colorCodeBackground = "#FFFFFF";
        this.statusUponDelivery = false;
        this.statusCreditCardPayment = true;
        this.messageUponDelivery = null;
        this.dataUponDelivery = null;
        this.brandsArray = [];
        this.APIKeyPushy = '';
        this.facebookSecretKey = '';
    }

    ngOnInit() {
        this.createForm();
        this.idRestaurant = this.restaurant.id;
        this.configRestaurantService.getAllDineInTypes().subscribe(response => {
            this.dineInTypes = response.data;
        });
        this.route.params.subscribe(params => {
            const that = this;
            this.configRestaurantService.get(params.id).subscribe(response => {
                that.colorCodeScript = response['data'].fontColor;
                that.colorCodeBackground = response['data'].bgColor;
                that.statusCheck = response['data'].useExternalMenu;
                that.statusCheckWhiteLabel = response['data'].useWhiteLabel;
                that.storeLinks.appStore = response['data'].urlAppStore;
                that.storeLinks.playStore = response['data'].urlPlayStore;
                that.domainUrl = response['data'].urlRestaurant;
            });
            // SE CONSULTA LA CONFIGURACION QUE TIENE UN RESTAURANTE
            this.configRestaurantService.getConfiguration(params.id).subscribe(response => {
                if (!response['errors']) {
                    console.log('response', response);
                    const data = response['data'];
                    this.dineInTypeId = data.dineInType.id;
                    this.uponDeliveryPaymentTypeCash = data.uponDeliveryPaymentTypeCash;
                    this.uponDeliveryPaymentTypeCreditCard = data.uponDeliveryPaymentTypeCreditCard;
                    this.uponDeliveryPaymentTypeDebitCard = data.uponDeliveryPaymentTypeDebitCard;
                    this.dineInTypeId = response['data'].dineInType.id
                    //se almacena la url apuntada al entorno deseado dependiendo del enviroment
                    this.url = this.utils.getUrlOmtWeb().toString();
                    this.url = this.url.concat('/app/detail-restaurant/' + data.restaurant + '?otherpage=true');
                }
            });
            //Obtenemos el estado de Upon delivery (contra entrega)
            // this.configView.getUponDelivery(params.id).subscribe(resp => {
            //   if (resp['errors']) {
            //     this.uponDeliveryCreated = null;
            //     console.error('Failed at ', resp['errors'], this.uponDeliveryCreated);
            //   }
            //   else {
            //     this.dataUponDelivery = resp['data'];
            //     this.statusUponDelivery = this.dataUponDelivery.use_upon_delivery;
            //   }
            // });
        });
        this.verifyEmailInitial();

        this.isFromColombia = this.userLocation.isColombia(this.restaurant.address.countryName);
        this.setOrGetMinimunPurchaseForDelivery();
    }

    /**
     * 
     */
    public privateEditCoupons() {

        this.setOrGetMinimunPurchaseForDelivery(true);
        this.restaurantService
            .save(this.restaurant, this.userLang, true)
            .subscribe(response => {

                if (!response['data']) {
                    if(response['errors']){
                        this.toastr.warning(response['errors']['message']);
                    } else {
                        this.translate.get("failedEdition").subscribe(translations => {
                            this.toastr.warning(translations);
                        });
                    }
                    this.restaurant.availableCoupons = false;
                } else {
                    this.translate.get("SuccessfullyEdited").subscribe(translations => {
                        this.toastr.success(translations);
                    });
                }
            },
                error => {
                    console.error(error);
                });

    }

    /**
     * Consume el ws que trae todos los datos del restaurante y setea dos variables.
     * this.statusUponDelivery:boolean. Se obtiene el estado de Upon delivery (contra entrega).
     * this.isMailActi:boolean. Se setea el estado de la variable dependiendo si el restaurante tiene o no email.
     */
    private verifyEmailInitial() {
        this.restaurantService.getDetailRestaurant(this.idRestaurant, this.userLang).subscribe(restaurant => {
            if (restaurant['errors']) {
                this.uponDeliveryCreated = null;
                console.error('Failed at ', restaurant['errors'], this.uponDeliveryCreated);
            } else {
                this.dataUponDelivery = restaurant['data'];
                if (this.dataUponDelivery.configuration[0]) {
                console.log('this.dataUponDelivery', this.dataUponDelivery.configuration[0]);

                    this.statusUponDelivery = this.dataUponDelivery.configuration[0].useUponDelivery;
                    this.messageUponDelivery = this.dataUponDelivery.configuration[0].messageUponDelivery;
                 
                    this.statusCreditCardPayment = this.dataUponDelivery.configuration[0].allowRealCreditCardsPayments;
                }
                if (restaurant['data'].emails["0"].email == '' || restaurant['data'].emails["0"].email == null) {
                    this.isMailActi = false;
                } else {
                    this.isMailActi = true;
                }
            }
        });
    }


    /**
     * Método que hace la copia de precios para el menú de brand a brand (Level - menu online | Level - white label).
     * @param typeOfExternalMenu -> Tipo de menu externo. Puede ser Menu Online o White Label.
     */
    public activateExternalMenu(typeOfExternalMenu: string) {
        // Esta variable es para mostrar el boton de Procesando... cuando empieza la copia de precios
        this.processingResponse = true;
        // Se llama a la función que consume el servicio que permite realizar la copia 
        this.insertDataFromExternalMenuToBrandSelected(typeOfExternalMenu, false, this.brandSelect);
    }

    /**
     * Indica si esta activo o inactivo el brand enviado por parametro (typeOfExternalMenu) 
     * @param typeOfExternalMenu 
     */
    private checkTypeBrand(typeOfExternalMenu: string): boolean {
        if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
            return this.statusCheck;
        }
        if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
            return this.statusCheckWhiteLabel;
        }
    }

    /**
     * Método para traer el array de brands, según el tipo de de menu externo que haya seleccionado
     * @param template =>string. Parametro que recibe el id del modal. En este caso ExternalMenu que es el modal que contiene el select con las brands
     * @param typeOfExternalMenu =>string. Tipo de menu externo. (MENU ONLINE :  Constants.BRAND_MENU_ONLINE | MARCA BLANCA: Constants.BRAND_WHITE_LABEL)
     * @param templateProcessing=>string. Parametro que recibe el id del modal. En este caso ProcessingModal que es el modal que contiene el spinner + procesando
     * @param deactivedTypeOfExternalMenu=>booleab. Parametro que determina la funcionalidad de una parte de la funcion. Indica si estamos activando o desactivando un tipo de
     * menu externo. 
     */
    private getBrands(template: TemplateRef<any>, typeOfExternalMenu: string, templateProcessing: TemplateRef<any>, deactivedTypeOfExternalMenu?: boolean): void {
        let constantBrand: string;
        this.configRestaurantService.getServicesAndBrands(this.idRestaurant).subscribe(res => {
            if (!res['errors']) {
                // Se limpia el arreglo a mostrar para que no se dupliquen los brands
                this.brandTo = '';
                this.brandSelect = '';
                this.brandsArray = [];
                if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
                    constantBrand = this.typesBrands.BRAND_MENU_ONLINE

                }
                if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
                    constantBrand = this.typesBrands.BRAND_WHITE_LABEL;
                }

                // Se hace el for para no mostrar entre las opciones del select,
                // la opción del menu que apenas va a ser activado
                for (let brand of res['data']['brand']) {
                    if (brand.alias == constantBrand) {
                        this.brandTo = brand.id;
                    }
                    if (brand && brand.status === 1 && brand.id != this.brandTo) {
                        this.brandsArray.push(brand);
                    }
                }
                // Si le mandan el parametro significa que se va a desactivar algun tipo de menu externo
                if (deactivedTypeOfExternalMenu) {
                    this.insertDataFromExternalMenuToBrandSelected(typeOfExternalMenu, true);
                } else {
                    let array = res['data']['brand'];
                    // Llamo a la función que me trae la posicion en el array el tipo de menu externo hayamos escogido activar 
                    const index = this.getIndexBrandSelected(array, typeOfExternalMenu);
                    // Aquí se lee si hay o no una copia previa en la activacion del menu externo que puede ser (1 => activo | 0 => inactivo, pero con datos anteriores)
                    if (res['data']['brand'][index]['lastStatus'] != null) {
                        // Si existe copia le pregunta que si desea utilizar los precios anteriores o hacer una copia nueva
                        this.askForPreviousData(template, typeOfExternalMenu, templateProcessing);
                    } else {
                        // Si no hay ninguna copia hecha previamente muestra el modal
                        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
                    }
                }
            } else {
                //desactivar 
            }
        });
    }
    /**
     * Método creado para obtener la posición de la brand que hayamos seleccionado, (Menu Online| White Label) en el array 
     * que nos devuelve el ws getServicesAndBrands
     * @param array 
     * @param typeOfExternalMenu 
     */
    private getIndexBrandSelected(array: Array<any>, typeOfExternalMenu: string): number {
        // Se hace este for para tomar la posición que tiene la brand seleccionada en el array 
        for (let i = 0; i < array.length; i++) {
            if (array[i].alias == this.typesBrands.BRAND_MENU_ONLINE && typeOfExternalMenu == this.typesBrands.BRAND_MENU_ONLINE) {
                return i;
            }
            if (array[i].alias == this.typesBrands.BRAND_WHITE_LABEL && typeOfExternalMenu == this.typesBrands.BRAND_WHITE_LABEL) {
                return i;
            }
        }
    }
    /**
     * Metodo para activar o desactivar algún tipo de menu externo, ya sea White Label o Menu Online
     * @param template -> A este parametro le seteamos el id del modal que queremos que abra
     * @param typeOfExternalMenu -> Este parametro diferencia para que tipo de menu externo va a ser la funcionalidad, si para el menu online, o para white label
     *  WHITE LABEL = Constants.BRAND_WHITE_LABEL
     *  MENU ONLINE = Constants.BRAND_MENU_ONLINE
     */
    public changeStatusForBrands(template: TemplateRef<any>, typeOfExternalMenu: string, templateProcessing: TemplateRef<any>) {
        this.selectedBrandConfig = typeOfExternalMenu;
        //Se hace una verificación si no se tiene email, no lo deja continuar
        if (!this.isMailActi) {
            this.globalEventsService.addEmail.emit();
            return;
        }
        this.processingResponse = false;

        if (!this.checkTypeBrand(typeOfExternalMenu)) {
            //Se llama a la función que consume el servicio para traernos las brands
            this.getBrands(template, typeOfExternalMenu, templateProcessing);
        } else {
            this.getBrands(template, typeOfExternalMenu, templateProcessing, true);
        }
    }

    /**
     * Metodo para consultar si ya existian datos o no en el registro del menu externo
     * @param template => Parametro que recibe el id del modal. En este caso este recibe el con el select de brands.
     * @param typeOfExternalMenu => Este parametro diferencia para que tipo de menu externo va a ser la funcionalidad, si para el menu online, 
     * o para la white label
     *  WHITE LABEL = Constants.BRAND_WHITE_LABEL
     *  MENU ONLINE = Constants.BRAND_MENU_ONLINE
     * @param templateProcessing => Parametro que recibe el id del modal. En este caso este recibe el modal que muestra el spinner + procesando
     */
    private askForPreviousData(template: TemplateRef<any>, typeOfExternalMenu: string, templateProcessing: TemplateRef<any>) {
        this.translate.get(["REPLACE_FOR_BEFORE_DATA", "Yes", "No"]).subscribe(translations => {
            this.sweetAlert.confirm(null, translations.REPLACE_FOR_BEFORE_DATA, null, [translations.No, translations.Yes])
                .then((result: boolean) => {
                    if (result) {
                        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
                    } else {
                        this.modalRef = this.modalService.show(templateProcessing, { backdrop: 'static', keyboard: false });
                        //Se llama al servicio que permite realizar la copia 
                        this.insertDataFromExternalMenuToBrandSelected(typeOfExternalMenu);
                    }
                });
        });
    }
    /**
     * Método que llama al ws que permite hacer la copia desde la brand que seleccione el usuario(LEVEL, OPENMYTAB)
     *  para el tipo de menu(WHITELABEL, MENUONLINE) que también ya haya seleccionado.
     * @param typeOfExternalMenu => Hay dos tipos de menu externo: Menu Online y White Label.
     * @param deactivedExternalMenu => Este parametro indica la desactivacion de alguno de los dos tipos de menu externo. Es opcional 
     * porque no siempre vamos a desactivarlo.
     * @param brandSelect => Este parametro es la brand que selecciona para saber de dónde hará la copia de precios.
     *  puede ser opcional porque una vez que ya se haya activado por ejemplo Menu Online, utilizando los precios de OPENMYTAB 
     *  y se desactive en algún momento puede sobreescribir los precios que tenía anteriormente, por lo cual no necesitaria este parametro. 
     */
    private insertDataFromExternalMenuToBrandSelected(typeOfExternalMenu: string, deactivedExternalMenu?: boolean, brandSelect?: string) {
        this.configRestaurantService.insertLevelBrandData(this.restaurant.levelDatabaseNickname, this.brandTo, brandSelect).subscribe(res => {
            if (res['errors']) {
                // Esconde el modal y muestra la alerta que hubo algún tipo de error en la copia 
                this.modalRef.hide();
                this.translate.get("syncError").subscribe(translations => {
                    this.toastr.error(translations);
                });
                //Dejamos el checkbox vacio si ocurre algún error
                if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
                    this.statusCheck = false;
                } else if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
                    this.statusCheckWhiteLabel = false;
                }
            } else {
                if (deactivedExternalMenu) {
                    // Si recibe el parametro deberá  Esconder la parte de html que permite cambiar el color de la pagina
                    if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
                        this.statusCheck = false;
                        this.translate.get("MENUONLINEDISABLE").subscribe(translations => {
                            this.toastr.warning(translations);
                        });
                    } else if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
                        this.translate.get("WHITELABELDISABLE").subscribe(translations => {
                            this.toastr.warning(translations);
                        });
                    }
                    // Llamamos a la funcion q permite cambiar el status dependiendo del tipo de menu. (Activado : true | Desactivado: false)
                    this.changeColor(false, false);


                } else {
                    this.modalRef.hide();
                    if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
                        // Se llama a la función que permite cambiar el color del menu online 
                        // para que así actualize la variable use_external_menu:boolean
                        this.statusCheck = true;
                        this.changeColor(false, false);
                        this.translate.get("MENUONLINEACTIVE").subscribe(translations => {
                            this.toastr.success(translations);
                        });
                    } else if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
                        this.statusCheckWhiteLabel = true;
                        this.changeColor(false, true);
                        this.translate.get("WHITELABELACTIVE").subscribe(translations => {
                            this.toastr.success(translations);
                        });
                    }
                }
            }
        }, catchError => {
            this.modalRef.hide();
            this.translate.get("syncError").subscribe(translations => {
                this.toastr.error(translations);
            });
            if (typeOfExternalMenu === this.typesBrands.BRAND_MENU_ONLINE) {
                this.statusCheck = false;
            }
            if (typeOfExternalMenu === this.typesBrands.BRAND_WHITE_LABEL) {
                this.statusCheckWhiteLabel = false;
            }
        });
    }

    /**
     * Función que cambia los colores para el menu online, al igual que cambia el status de este y el de la white label
     * @param whiteLabel => Recibe si se va a actualizar el status para la white label
     * @param showAlert => Parametro que viene desde la vista, para que solo muestre la alerta q los colores han sido actualizados correctamente
     */
    public changeColor(showAlert: boolean, whiteLabel?: boolean) {
        let colors = {
            font_color: this.colorCodeScript,
            bg_color: this.colorCodeBackground,
            use_external_menu: this.statusCheck,
            use_white_label: whiteLabel,
            urlRestaurant: this.domainUrl
        };
        if (whiteLabel) {
            colors.use_external_menu = this.statusCheck;
            // colors.use_white_label = whiteLabel;
        } else {
            colors.use_external_menu = this.statusCheck;
            colors.use_white_label = this.statusCheckWhiteLabel;
        }
        // Metodo para enviar/mostrar el código de color elegido por el user
        this.changeMenuExternalStatus(colors, showAlert);
    }


    /**
     * Función que consume el ws para actualizar los datos del menu online.
     * @param colors -> Es un objeto que trae todos los datos que serán actualizados, que son:
     * font_color:string = el color de la fuente que va a tener la página,
     * bg_color:string = el color del background que va a tener la página,
     * use_external_menu:boolean = determina si el menu online esta activado o desactivado.
     */

    private changeMenuExternalStatus(colors, showAlert) {
        this.route.params.subscribe(params => {
            const that = this;
            this.configRestaurantService.update(colors, params.id)
                .subscribe(response => {
                    if (showAlert) {
                        that.translate.get("COLOR_EXTERNAL_MENU").subscribe(translations => {
                            that.toastr.success(translations);
                        });
                    } else {
                        // that.translate.get("ERROR_COLORS").subscribe(translations => {
                        //     that.toastr.warning(translations);
                        // });
                    }
                });
        });
    }

    /**
     * Método para generar la vista previa en el cambio de color mediante ngStyle
     */
    sample() {
        let myStyle;
        myStyle = {
            'color': this.colorCodeScript,
            'background-color': this.colorCodeBackground,
            'text-align': "center",
            'display': "inline-block",
            'font-weight': "bold",
            'padding': "10px"
        };

        var head = document.head || document.getElementsByTagName('head')[0];
        var style = document.createElement('style');
        style.type = 'text/css';

        var css = 'body{ --color: ' + this.colorCodeBackground + ' }';
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);

        return myStyle;
    }

    public goBack(restaurant: restaurant = null) {
        this.close.emit(restaurant);
        window.history.back();
    }

    /**
     * Metodo encargado de crear el formulario que manejara los datos basicos del restaurante
     */
    private createForm() {
        let emailRegex = `([a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*)((@[a-zA-Z]{2}[a-zA-Z]*)[\\\.]([a-zA-Z]{2}|[a-zA-Z]{3}))`;
        this.emailForm = new FormGroup({
            email: new FormControl('', [<any>Validators.pattern(globalRegex.emailRegex)]),

        });
    }

    public uponDelivery(event: any, sendMessage = false) {
        if (!sendMessage && this.statusUponDelivery && !this.statusCreditCardPayment) {
            event.target.checked = this.statusUponDelivery;
            this.translate.get('YOU_MUST_A_PAYMENT_METHOD').subscribe(translation => {
                this.toastr.warning(translation);
            })
            return;
        }
        if (!sendMessage) {
            this.statusUponDelivery = event.target.checked;
        }
        if (!this.dataUponDelivery.configuration[0]) {
            this.configRestaurantService.createUponDelivery(this.idRestaurant).subscribe(r => {
                if (r['errors']) {
                    console.error("Failed at", r['errors']);
                }
                else {
                    this.uponDeliveryCreated = true;
                    this.dataUponDelivery.configuration.push({ useUponDelivery: null, id: null });
                    this.dataUponDelivery.configuration[0].useUponDelivery = r['data'].use_upon_delivery;
                    this.dataUponDelivery.configuration[0].id = r['data'].id;
                    this.dataUponDelivery.configuration[0].messageUponDelivery = r['data'].message_upon_delivery;
                }
            });
        }
        else {
            if (this.dataUponDelivery.configuration[0].useUponDelivery == true) {
                this.dataUponDelivery.configuration[0].useUponDelivery = false;
            } else {
                this.dataUponDelivery.configuration[0].useUponDelivery = true;
                this.dataUponDelivery.configuration[0].messageUponDelivery = this.messageUponDelivery;
            }
            if (sendMessage) {
                const data = {
                    'message_upon_delivery': this.messageUponDelivery,
                    cash_delivery_payment: this.uponDeliveryPaymentTypeCash,
                    creditcard_delivery_payment: this.uponDeliveryPaymentTypeCreditCard,
                    debitcard_delivery_payment: this.uponDeliveryPaymentTypeDebitCard
        
                };
                this.saveDataUponDelivery(data, true);
            } else {
                const data = {
                    use_upon_delivery: this.statusUponDelivery,
                    allow_credit_cards_payments: this.statusCreditCardPayment,
                    cash_delivery_payment: this.uponDeliveryPaymentTypeCash,
                    creditcard_delivery_payment: this.uponDeliveryPaymentTypeCreditCard,
                    debitcard_delivery_payment: this.uponDeliveryPaymentTypeDebitCard

                };

                this.saveDataUponDelivery(data);
            }

        }


    }

    /**
     * Actualiza la informacion de la contraentrega. Su estado y el mensaje.
     * @param data 
     * @param showToast 
     */
    private saveDataUponDelivery(data, showToast = false) {
        console.log('saveDataUponDelivery', data);
        this.configRestaurantService.updateUponDelivery(data, this.idRestaurant, this.dataUponDelivery.configuration[0].id)
            .subscribe(r => {
                if (r['errors']) {
                    console.error("Fail at: ", r['errors']);
                }
                else {
                    if (showToast) {
                        this.translate.get("MESSAGESAVEDCORRECTLY").subscribe(translations => {
                            this.toastr.success(translations);
                        });
                    }
                }
            });
    }
    /**
     * Permite seleccionar un tipo de dine in para el restaurante
     */
    public selectDineInType() {
        const data = {
            'dineIntypeOrder': this.dineInTypeId
        };
        this.configRestaurantService.updateUponDelivery(data, this.idRestaurant, this.dataUponDelivery.configuration[0].id)
            .subscribe(response => {
                if (!response['errors']) {
                    this.translate.get("TYPE_DINE_IN_UPDATE").subscribe(translations => {
                        this.toastr.success(translations);
                    });
                } else {
                    this.translate.get("TYPE_DINE_IN_ERROR").subscribe(translations => {
                        this.toastr.warning(translations);
                    });
                }
            });
    }
    /**
     * Funcion para cerrar el modal, dando en la x o en cancelar
     * @param typeOfExternalMenu 
     */
    public cancelExternalMenu(typeOfExternalMenu) {
        if (typeOfExternalMenu == this.typesBrands.BRAND_MENU_ONLINE) {
            this.statusCheck = false;
        } else if (typeOfExternalMenu == this.typesBrands.BRAND_WHITE_LABEL) {
            this.statusCheckWhiteLabel = false;
        }
        this.modalRef.hide();
    }

    /**
     * Guarda el SECRET API KEY de pushy para las notificaciones de white label
     */
    public saveKeyPushy() {
        let objectToSend = {
            "APIKey": this.APIKeyPushy,
            // "restaurantId": this.restaurant.id,
            "brandNumber": Constants.BRAND_ALIAS_WHITE_LABEL.originNumber
        };
        this.configRestaurantService
            .insertLevelBrandData(this.restaurant.levelDatabaseNickname, this.brandTo, null, objectToSend)
            .subscribe((resp: any) => {
                console.log(resp);
                if (!resp.errors) {
                    this.APIKeyPushy = '';
                    this.translate.get("SECRET_API_KEY_SUCCESS").subscribe(translations => {
                        this.toastr.success(translations);
                    });
                } else {
                    this.translate.get("errorSaving").subscribe(translations => {
                        this.toastr.warning(translations);
                    });
                }
            });
    }

    /**
     * @author Anderson Barreto B. Ene - 18 - 2021
     * @version 1.0.0
     * @description Metodo encargado de guardad las secretKeys requeridas (pushy, facebook)
     */
    public saveSecretKeys(): void {

        if (!this.APIKeyPushy.length && !this.facebookSecretKey.length) {
            this.translate.get("YOU_MUST_A_SECRET_KEY").subscribe(translations => {
                this.toastr.warning(translations);
            });
            return;
        }

        let dataSend = {
            configuration_id: this.dataUponDelivery.configuration[0].id,
            restaurant_id: this.idRestaurant,
            brandFrom: this.brandTo,
            nickname: this.restaurant.levelDatabaseNickname,
            overwrite: 1,
            whiteLabelPushyKey: null,
            facebook_secret: null

        }

        if (this.facebookSecretKey.length) {
            dataSend.facebook_secret = this.facebookSecretKey
        }

        if (this.APIKeyPushy.length) {
            dataSend.whiteLabelPushyKey = {
                APIKey: this.APIKeyPushy,
                brandNumber: Constants.BRAND_ALIAS_WHITE_LABEL.originNumber
            }
        }

        this.configRestaurantService.insertSecretKeys(dataSend).subscribe((resp: any) => {
            if (!resp.errors) {
                this.APIKeyPushy = '';
                this.facebookSecretKey = '';
                this.translate.get("SECRET_API_KEY_SUCCESS").subscribe(translations => {
                    this.toastr.success(translations);
                });
            } else {
                this.translate.get("errorSaving").subscribe(translations => {
                    this.toastr.warning(translations);
                });
            }
        })
    }

    public creditCardPayment(event) {
        if (!this.statusUponDelivery && this.statusCreditCardPayment) {
            event.target.checked = this.statusCreditCardPayment;
            this.translate.get('YOU_MUST_A_PAYMENT_METHOD').subscribe(translation => {
                this.toastr.warning(translation);
            })
            return;
        }
        console.log('Completar Operacion', this.statusCreditCardPayment);
        this.statusCreditCardPayment = event.target.checked;

        const data = {
            use_upon_delivery: this.statusUponDelivery,
            allow_credit_cards_payments: this.statusCreditCardPayment,
            cash_delivery_payment: this.uponDeliveryPaymentTypeCash,
            creditcard_delivery_payment: this.uponDeliveryPaymentTypeCreditCard,
            debitcard_delivery_payment: this.uponDeliveryPaymentTypeDebitCard
        };

        this.saveDataUponDelivery(data);

    }

    public saveUrlStores() {
        console.log('saveUrlStores');
        this.configRestaurantService.updateStoreLinks(this.idRestaurant, this.storeLinks)
            .subscribe((res: any) => {

                if (!res.errors) {
                    this.translate.get("URL_STORES_UPDATE").subscribe(translations => {
                        this.toastr.success(translations);
                    });
                } else {
                    this.translate.get("errorSaving").subscribe(translations => {
                        this.toastr.warning(translations);
                    });
                }
            });
    }

    /**
     * @author Anderson Barreto B. - Ago 6-2020
     * @version 1.0.0
     * @description Setea o obtiene el valor minimo de compra para realizar el delivery
     * @param setValue induca si se debe setiar la propiedad o leeer en caso de ser false
     */
    private setOrGetMinimunPurchaseForDelivery(setValue: boolean = false): void {
        if(this.restaurant.multiplePrice.servicesType){
            this.restaurant.multiplePrice.servicesType.forEach((servicesType, index) => {
                if (servicesType.ostType === 2) {
                    if (setValue) {
                        console.log('seteo', this.restaurant.multiplePrice.servicesType[index]);
                        this.restaurant.multiplePrice.servicesType[index].minimumSeviceValue = this.minimumSeviceValueForDelivery;
                    } else {
                        this.minimumSeviceValueForDelivery = this.restaurant.multiplePrice.servicesType[index].minimumSeviceValue 
                    }
                }
            });
        }
    }
}
