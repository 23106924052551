import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';

import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class SettingService {
    constructor(private http: Http,
        private utils: UtilsComponent
    ) { }

    private omtUrl = this.utils.getUrlOmtSd();
    private getSettingUrl = this.utils.getUrlOmt() + 'sd';

    getSetings(userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getSettingUrl}/generalSettings`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataSettings)
            .catch(this.handleError);
    }

    private extractDataSettings(res: Response) {
        let body = res.json();
        return body.data || {};
    }


    /**
     * Metodo encargado de actualizar las configuraciones generales
     */
    put(settingsData: any, userLang: string, emails_contact_messages: any) {
        let headers = new Headers({
            'Content-Type': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        let jsonparse = JSON.parse(emails_contact_messages)
        let jsonstringify = JSON.stringify(jsonparse)

        var arrayData = {
            default_search_scale: 'mi',
            default_search_range: settingsData.distance,
            days_to_new_restaurants: settingsData.timeNewRestaurant,
            emails_contact_messages: encodeURIComponent(jsonstringify)
        }

        let url = `${this.getSettingUrl}/generalSettings`;
        return this.http
            .put(url, arrayData, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}
