import { LocalizeRouterService } from 'localize-router';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//import { JqSparkline } from '../../components/sparkline/sparkline';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { detailRestaurant } from '../../entity/detailRestaurant';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
//import { TimerWrapper } from '@angular/core/src/facade/async';
//import { GoogleMapsAPIWrapper, GOOGLE_MAPS_DIRECTIVES } from 'angular2-google-maps/core';
import { restaurant } from '../../entity/restaurant';
import { PermissionsUserService } from '../../services/permissionsUser.service';
import { RestaurantService } from '../../services/restaurant.service';
//import { DatePipe } from './../pipes/date-pipe';
import { UtilsComponent } from '../../utils/utils';
import { Constants } from 'app/app.constants';

//Variable para manejo de funciones Jquery
declare var jQuery: any;

@Component({
    selector: 'showRestaurant',
    templateUrl: './showRestaurant.html',
    // directives: [Widget, ROUTER_DIRECTIVES,
    //     REACTIVE_FORM_DIRECTIVES, GOOGLE_MAPS_DIRECTIVES, InspectPermitsComponent],
    // providers: [GoogleMapsAPIWrapper],
    // pipes: [TranslatePipe, HoursPipe, DatePipe],
    styles: [`
    .sebm-google-map-container {
       height: 300px;
     }
  `],
})
// @CanActivate((next, prev) => {
//     let injector: Injector = appInjector();
//     let router: Router = injector.get(Router);

//     if (!isLoggedIn()) {
//         router.navigate(['/LoginPage']);
//         return false;
//     }

//     return true;
// })

export class ShowRestaurantComponent implements OnInit {
    navigated = false;
    //detRestaurante: detailRestaurant[];
    @Input() detRestaurante: detailRestaurant;
    response: any;

    activeMap: boolean = false;
    showMap: boolean = false;
    lat: number = 4.5323061;
    lng: number = -75.6735617;
    zoom: number = 18;

    @Output() close = new EventEmitter();

    /**
     * Variable para manejo de errores al realizar la petición al web service
     */
    error: any;

    //Variable para manejo de lenguaje
    userLang: string;

    /**
    * Variable que controla si se muestra o no la seccion
    */
    canShowSection: boolean = false;
    canViewLevelRestaurant: boolean = false;
    canViewDirectoryRestaurant: boolean = false;


    /**
 * variable encargada de traer todos los modulos
 */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));

    /**
    * Variable con identificador de la seccion menu
    */
    viewLevelRestaurant: any = this.allModules['module_view_restaurants_from_level'];
    viewDirectoryRestaurant: any = this.allModules['module_view_restaurants_from_directory'];
    /**
     * Variable que contendra el id del restaurant
     */
    idRestaurant: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        // private routeParams: RouteParams,
        private restaurantService: RestaurantService,
        private utils: UtilsComponent,
        //private _wrapper: GoogleMapsAPIWrapper,
        private permissionsUserService: PermissionsUserService,
        private localize: LocalizeRouterService,
        private translate: TranslateService
    ) { }

    putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }

    /**
     * Metodo encargado de enviar peticion al usuario cuando se llega a esta paginas por primera vez,
     * esto una vez se captura el id de la url
     */
    getFirstLoadRestaurant() {
        this.restaurantService.getDetailRestaurant(this.idRestaurant, this.userLang)
            .subscribe(restaurant => {
                this.detRestaurante = restaurant['data'];

                setTimeout(() => {
                    this.detRestaurante.createdAt = this.detRestaurante.createdAt.substr(0, 10);
                    let restaurant: any = this.detRestaurante;
                    // let openingDates = this.detRestaurante.openingDates;
                    for (var i = 0; i < this.detRestaurante.openingDates.length; i++) {
                        this.detRestaurante.openingDates[i]['startDate'] = this.utils.extractDateHour(this.detRestaurante.openingDates[i]['startDate'], true);
                        this.detRestaurante.openingDates[i]['endDate'] = this.utils.extractDateHour(this.detRestaurante.openingDates[i]['endDate'], true);
                        for (var j = 0; j < this.detRestaurante.openingDates[i]['hours'].length; j++) {
                            if (this.detRestaurante.openingDates[i]['hours'][j].hourFrom != null && this.detRestaurante.openingDates[i]['hours'][j].hourTo != null) {
                                this.detRestaurante.openingDates[i]['hours'][j].hourFrom = this.utils.captureHour(this.detRestaurante.openingDates[i]['hours'][j].hourFrom, true);
                                this.detRestaurante.openingDates[i]['hours'][j].hourTo = this.utils.captureHour(this.detRestaurante.openingDates[i]['hours'][j].hourTo, true);
                            }
                        }
                    }
                    this.lat = parseFloat(restaurant.address.latitude);
                    this.lng = parseFloat(restaurant.address.longitude);
                }, 1000);

                setTimeout(() => {
                    this.activeMap = true;
                }, 1100);
            }, error => {
                this.error = error;
                this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
            });
    }

    ngOnInit() {
        this.putClassActive();
        this.permissionsUserService.showHideButtonsSidebarByComponent();
        this.userLang = this.translate.currentLang;
        if (this.userLang == "") {
            this.userLang = navigator.language.split('-')[0];
        }
        this.route.params.subscribe(params => this.idRestaurant = params.id);
        // if (this.routeParams.get('id') !== null) {
        //     this.idRestaurant = +this.routeParams.get('id');
        //     this.navigated = true;
        //     this.getFirstLoadRestaurant();
        // } else {
        //     this.navigated = false;
        //     this.detRestaurante = new detailRestaurant();
        // }
        // this.idRestaurant = +this.routeParams.get('id');
        this.getFirstLoadRestaurant();

        this.canViewLevelRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewLevelRestaurant);
        this.canViewDirectoryRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewDirectoryRestaurant);

        // setTimeout(() => {
        //     if (this.detRestaurante.isFromLevel == true && this.canViewLevelRestaurant) {
        //         this.canShowSection = true;
        //     } else if (!this.detRestaurante.isFromLevel && this.canViewDirectoryRestaurant) {
        //         this.canShowSection = true;
        //     } else {
        //         this.canShowSection = false;
        //     }

        //     if (!this.canShowSection) {
        //         jQuery('#permissionsDeniedModal').modal('show');
        //     }
        // }, 1500);

    }

    gotoRestaurant() {
        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }

    gotoEdit(restaurant: restaurant) {
        this.router.navigate([this.localize.translateRoute('/app/restaurant'), restaurant.id, 'edit']);
    }

    goBack(restaurant: restaurant = null) {
        this.close.emit(restaurant);
        window.history.back();
    }

    canShowMap() {
        if (this.showMap) {
            this.showMap = false;
        } else if (!this.showMap) {
            this.showMap = true;
        }
    }
}