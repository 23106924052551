export class detailRestaurant {
  createdAt: string;
  updatedAt: string;
  static status: any;
  deletedAt: string;
  id: number;
  name: string;
  description: string;
  ratingAvg: number;
  isFromLevel: any;
  levelDatabaseNickname: string;
  superDashboardUser: string;
  rest_sd_user_id: number;
  status: number;

  address: any;
  phones: Array<Object>;
  emails: Array<Object>;
  cuisines: Array<Object>;
  openingDates: Array<Object>;
  images: Array<Object>;
  features: Array<Object>;
  meals: Array<Object>;
}

export class imageRestaurant {
  id: number;
  title: string;
  description: string;
  file: any;
  category: number;
  cropedPath: string;
  path:string;
  isUploadedOnAWS:boolean;
}

