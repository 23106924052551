<div class="table-responsive">
  <div class="pull-left">
    <button class="btn btn-success mb-xs" (click)="refreshRestaurants()">
      <em class="fa fa-refresh"></em> {{ 'REFRESH' | translate }}
    </button>
  </div>
  <table class="table table-striped" id="table-restaurant">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col" id="restaurant" class="text-center">
          {{ 'Restaurant' | translate }}
        </th>
        <th scope="col" id="client" class="text-center">
          {{ 'Client' | translate }}
        </th>
        <th scope="col" id="coupon" class="text-center">
          {{ 'COUPON_NAME' | translate }}
        </th>
        <th scope="col" id="buttonsAction" class="text-center">
          {{ 'Actions' | translate }}
        </th>
      </tr>
    </thead>
    <ng-container *ngFor="let restaurant of restaurants; let i = index">
      <tbody>
        <tr>
          <th id="restaurantName">{{restaurant.restaurantName}}</th>
          <td>{{restaurant.coupons[0]['clients'][0]['clientName']}}</td>
          <td>{{restaurant.coupons[0]['nameCoupons']}}</td>
          <td class="width-150 actions" id="actions">
            <div class="">
              <!-- <button class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}">
                <em class="fa fa-eye text-info"></em>
              </button> -->
              <button class="btn btn-info  mb-xs" role="button" title="Retry" (click)="redeemCoupon(restaurant)">
                <em class="fa fa-tags"></em>
                <!-- <app-loading-image *ngIf="order.resendingOrderSyncStatus" [classCSS]="'order-list'">
                </app-loading-image> -->
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>
<div>
  <div *ngIf="restaurants && restaurants.length <=0">
    <div class="alert alert-info alert-dismissable">
      <strong>{{'NoDataToShow' | translate }}</strong>
    </div>
  </div>
</div>
<!-- Botones Paginación -->
<div class="form-group row" *ngIf="pagination && pagination.totalItems > 0">
  <div class="col-lg-12 col-md-12 d-flex justify-content-center">
    <label>{{ 'Page' | translate }} {{pagination.currentPage}}</label>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
    <pagination class="pagination pagination-md justify-content-end active" [totalItems]="pagination.totalItems"
      [maxSize]="maxSize.maxSizePagination" [itemsPerPage]="pagination.itemsPerPage" [lastText]="lastPag"
      [firstText]="firstPag" [previousText]="prevPag" [nextText]="nextPag" (pageChanged)="getCouponsList($event)"
      [boundaryLinks]="true" [rotate]="true">
    </pagination>
  </div>
</div>
