export class user {
    id: string;
    name: string;
    lastname: string;
    username: string;
    email: string;
    type: number;
    typeDesciption: string;
    status: number;
    statusDescription: string;
    createdAt: string;
    updatedAt: string;
    password: string = "";
    password_confirm: string = "";
    phone: string;
}