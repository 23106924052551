import { NgModule } from '@angular/core';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from 'localize-router';
import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
import { BsDropdownModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { ToastrModule } from 'ngx-toastr';

//import {LocalizeRouterService} from 'localize-router';
//import {LocalizeRouterHttpLoader} from 'localize-router-http-loader';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { CommonModule, DecimalPipe, Location } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
//import { CHART_DIRECTIVES } from 'ng2-charts/ng2-charts';
import { routes, routing } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { LockedScreenComponent } from './auth/lockedScreen';
import { ClientComponent } from './clients/client';
import { LoadingImageComponent } from './components/loading-image/loading-image';
import { ChangeOrderStateComponent } from './components/change-order-state/change-order-state.component';
import { SelectorImagesComponent } from './components/selectorImagenes/selectorImages.component';
import { ConfigService } from './core/config';
import { Core } from './core/core';
import { Navbar } from './core/navbar/navbar';
import { DateTimeLocalizePipe } from './core/pipes/date-time-localize.pipe';
import { SearchPipeCore } from './core/pipes/pipe';
import { Sidebar } from './core/sidebar/sidebar';
import { AnimateNumber } from './core/utils/animate-number';
import { CheckAll } from './core/utils/check-all';
import { ProgressAnimate } from './core/utils/progress-animate';
import { Widget } from './core/widget/widget';
import { Dashboard } from './dashboard/dashboard';
import { ErrorPageComponent } from './error/error.component';
import { InspectPermitsComponent } from './inspectPermits/inspectPermits';
import { LegalComponent } from './legal/legal';
import { LoginPageComponent } from './login/loginPage.component';
import { MailComponent } from './mails/mail';
import { CurrencyPipe } from './pipes/currency.pipe';
import { DatePipe } from './pipes/date-pipe';
import { DateFullPipe } from './pipes/dateFull-pipe';
import { DistancePipe } from './pipes/distance-pipe';
import { HoursPipe } from './pipes/hours-pipe';
//import { TitleCasePipe } from '@angular/common';
import { OrderBy } from './pipes/orderBy-pipe';
import { SearchPipe } from './pipes/search-pipe';
import { SearchMailPipe } from './pipes/searchMail-pipe';
import { SearchUserPipe } from './pipes/searchUser-pipe';
import { TitleCaseCorePipe } from './pipes/titleCase-pipe';
import { TruncateCharactersPipe } from './pipes/truncate-pipe';
import {
    ActivateRestaurantLevel
} from './restaurant/activateRestaurantLevel/activateRestaurantLevel.component';
import { ConfigComponent } from './restaurant/configComponent/configComponent';
import { EditRestaurantComponent } from './restaurant/editRestaurant/editRestaurant';
import {
    DetailOrderModalComponent
} from './restaurant/orders-restaurant/components/detail-order-modal/detail-order-modal.component';
import {
    OrdersRestaurantComponent
} from './restaurant/orders-restaurant/orders-restaurant.component';
import {
    EnableRestaurantComponent
} from './restaurant/enableRestaurant/enableRestaurant.component';
import { LevelForm } from './restaurant/levelForm/levelForm.component';
import { LevelList } from './restaurant/listAccount/listaAccountLevel.component';
import { CategoryModalComponent } from './restaurant/menuItem/categoryModal/categoryModal';
import { MenuItemComponent } from './restaurant/menuItem/menuItem';
import { MenuProductsComponent } from './restaurant/menuItem/menuProducts/menuProducts';
import { NewMenuItemModalComponent } from './restaurant/menuItem/newMenuItemModal';
import { MenuItemMenuComponent } from './restaurant/menuItemMenu/menuItemMenu.component';
import { NewRestaurantComponent } from './restaurant/newRestaurant/newRestaurant';
import { RestaurantComponent } from './restaurant/restaurant';
import {
    RestaurantReviewComponent
} from './restaurant/restaurant-review-component/restaurant-review.component';
import { ShowRestaurantComponent } from './restaurant/showRestaurant/showRestaurant';
import { AlertsService } from './services/alerts.service';
import { ConfigRestaurantService } from './services/configRestaurant.service';
import { DetailsRestaurantService } from './services/detailsRestaurants.service';
import { LevelSyncroLevel } from './services/levelSync.service';
import { ListAccountService } from './services/listAccount.service';
import { OrdersService } from './services/orders.service';
import { UserLocationService } from './services/user-location.service';
import { RestaurantReviewService } from './services/restaurant-review.service';
import { RestaurantService } from './services/restaurant.service';
import { UserLoginService } from './services/userLogin.service';
import { SettingComponent } from './setting/setting';
import { UserComponent } from './user/user';
import { UserProfileComponent } from './userProfile/userProfile';
import { ControlMessagesComponent } from './utils/control-messages';
import { EqualValidator } from './utils/equalValidator.directive';
import { GlobalEventsService } from './utils/globalEvents.service';
import { ImagesUtilsComponent } from './utils/imagesUtils.component';
import { SweetAlertService } from './utils/sweetAlert.service';
import { UtilsComponent } from './utils/utils';
// import { CachingInterceptorService } from './services/caching-interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ListOrdersComponent } from './restaurant/orders-restaurant/components/list-orders/list-orders.component';
import { UserService } from './services/user.service';
import { CouponsRestaurantComponent } from './restaurant/coupons-restaurant/coupons-restaurant.component';
import { ListCouponsComponent } from './restaurant/coupons-restaurant/components/list-coupons/list-coupons.component';
import { ReportUserClicksComponent } from './restaurant/report-user-clicks/report-user-clicks.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ReviewsInfoComponent } from './components/modal-info/reviews-info/reviews-info.component';
import { OrdersInfoComponent } from './components/modal-info/orders-info/orders-info.component'
import { NotificationsComponent } from './restaurant/notifications/notifications.component';
import { CurrencyMaskModule } from "ng2-currency-mask";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function LocalizeLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
    return new LocalizeRouterHttpLoader(translate, location, settings, http, './assets/locales.json');
}

@NgModule({
    declarations: [
        DistancePipe,
        AppComponent,
        LoginPageComponent,
        ErrorPageComponent,
        Dashboard,
        Core,
        Sidebar,
        Navbar,
        UserProfileComponent,
        MailComponent,
        ClientComponent,
        LegalComponent,
        UserComponent,
        SettingComponent,
        RestaurantComponent,
        MenuItemComponent,
        MenuProductsComponent,
        ShowRestaurantComponent,
        EditRestaurantComponent,
        NotificationsComponent,
        NewRestaurantComponent,
        SelectorImagesComponent,
        LoadingImageComponent,
        ChangeOrderStateComponent,
        ConfigComponent,
        // CHART_DIRECTIVES,
        SearchPipe,
        SearchPipeCore,
        Widget,
        EqualValidator,
        InspectPermitsComponent,
        ControlMessagesComponent,
        SearchUserPipe,
        //TitleCasePipe,
        TitleCaseCorePipe,
        OrderBy,
        CategoryModalComponent,
        NewMenuItemModalComponent,
        CheckAll,
        ProgressAnimate,
        HoursPipe,
        DatePipe,
        AnimateNumber,
        DateFullPipe,
        SearchMailPipe,
        TruncateCharactersPipe,
        LockedScreenComponent,
        EnableRestaurantComponent,
        MenuItemMenuComponent,
        ActivateRestaurantLevel,
        LevelForm,
        LevelList,
        RestaurantReviewComponent,
        DateTimeLocalizePipe,
        OrdersRestaurantComponent,
        CurrencyPipe,
        DetailOrderModalComponent,
        ListOrdersComponent,
        CouponsRestaurantComponent,
        ListCouponsComponent,
        ReportUserClicksComponent,
        ModalInfoComponent,
        ReviewsInfoComponent,
        OrdersInfoComponent,
    ],
    imports: [
        ImageCropperModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgSlimScrollModule,
        HttpModule,
        routing,
        CommonModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginationModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD8vBRVXi1qZgxSvwwR3wH8aStcKYazi_M',
            libraries: ["places"]
        }),
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(routes, { enableTracing: false, useHash: true }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: LocalizeLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            }
        }),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
        CurrencyMaskModule
    ],

    exports: [RouterModule, LocalizeRouterModule, ActivateRestaurantLevel],
    entryComponents: [
        ActivateRestaurantLevel,
        DetailOrderModalComponent,
        ListOrdersComponent,
        ModalInfoComponent
    ],
    providers: [
        GlobalEventsService,
        ConfigRestaurantService,
        DecimalPipe,
        AlertsService,
        SweetAlertService,
        LevelSyncroLevel,
        ActivateRestaurantLevel,
        UtilsComponent,
        UserLoginService,
        RestaurantService,
        ListAccountService,
        ConfigService,
        ImagesUtilsComponent,
        GoogleMapsAPIWrapper,
        AuthService,
        RestaurantReviewService,
        DetailsRestaurantService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CachingInterceptorService,
        //     multi: true
        // },
        UserLocationService,
        OrdersService,
        UserService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private translate: TranslateService) {
        translate.setDefaultLang('en');
        translate.use(navigator['languages']['1']);
    }
}
