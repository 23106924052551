export class menuItem {
    statusDescription: string;
    id: number;
    name: string;
    description: string;
    ingredients: string;
    status: number;
    regularPrice: number;
    isModifier: boolean;
    sizes: Array<Object>;
    category_id: number;
    images: any;
    videoPath: string
}

export class imageProduct {
    id: number;
    title: string;
    description: string;
    isMain: boolean;
    file: any;
    product_size_id: any;
}