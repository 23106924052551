import * as FileSaver from 'file-saver';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { Constants } from 'app/app.constants';
import { DetailOrderModalComponent } from '../detail-order-modal/detail-order-modal.component';
import { LocalizeRouterService } from 'localize-router';
import { ModalInfoComponent } from 'app/components/modal-info/modal-info.component';
import { OrdersService } from 'app/services/orders.service';
import { Pagination } from 'app/entity/Pagination';
import { RestaurantService } from 'app/services/restaurant.service';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserLocationService } from 'app/services/user-location.service';
import { UtilsComponent } from 'app/utils/utils';
import { finalize } from 'rxjs/operators';

//Variable para manejo de funciones Jquery
declare var jQuery: any;
/**
 * Componente para listar las ordenes en los tabs de la pagina Ordenes
 * @author William  Alarcon
 * @version 
 */
@Component({
    selector: 'app-list-orders',
    templateUrl: './list-orders.component.html',
    styleUrls: ['./list-orders.component.sass']
})
export class ListOrdersComponent implements OnInit {

    /**
       * Indica cuales son las ordenes que se deben buscar 
       * NULL = todas las ordeNes , PENDING = CONSTANTS_STATE.PENDING (3)  solo las ordenes pendientes
       */
    @Input() status: number;
    /**
     * Indica si se esta haciendo desde la sección general o por restaurante
     */
    @Input() allOrders: boolean;

    public orderIconObject: string;
    /**
     * Load all the orders of the restaurant
     */
    public orders: [any];

    /**
     * It have all the pagination data
     * We need an object for this
     *  currentPage
     *   firstPage
     *   itemsInCurrentPage
     *   itemsPerPage
     *   lastPage
     *   nextPage
     *   prevPage
     *   totalItems
     */
    public pagination: any;

    /**
     * maximun size of pagination
     */
    public maxSize: Pagination;

    /**
     * Constants order type
     */
    public orderTypeConstants: { dineIn: number; counter: number; phoneOrder: number; barTab: number; driveThru: number; };

    /**
     * Constants status order
     */
    public statusConstants: { CANCELED: number; PENDING_ALSO: number; PENDING: number; RECEIVED: number; BEING_PREPARED: number; PREPARED: number; DELIVERY_ON_SITE: number; SEND_TO_DELIVERY: number; SEND_TO_CUSTOMER: number; ARRIVES_AT_DESTINATION: number; DELIVERED: number; };

    /**
     * Constants phone type
     */
    public phoneOrderTypeConstants: { noPhone: number; pickup: number; delivery: number; table: number; turn: number; turnAutomatic: number; };

    /**
    * Variable que almcena los tipos de brand desde donde se hizo la compra. Web, App, WhiteLabel, etc.
    */
    public types_brand: { TYPE_KIND_OMT_WEB: number; TYPE_KIND_OMT_APP: number; TYPE_KIND_MENU_ONLINE: number; TYPE_KIND_WHITE_LABEL: number; };

    /**
     * captura lo que ingresa el usuario para buscar ordenes
     */
    public searchFields: any;
    /**
     * Valeria que almacena la informacion de los restaurantes
     */
    public restaurants: any;
    /**
     * Variable que almacena los estados de la orden con su respectiva traduccion
     */
    public ordersStatus: { number: number; name: string; }[];

    public DINE_IN_CONSTANTS: { TABLE: number; BEACON: number; CONSECUTIVE_DAILY: number; };


    /**
     * Estado del boton de carga
     */
    public buttonStatus: boolean;
    /**
     * Variable que maneja si se muestra o no el loading del boton del push a android
     */
    public loadingResponse: boolean;

    public paginationArray: Array<any>;
    public paginationSelected: number;
    private itemsPerPageSelected: number = 25;

    @ViewChild('pushErrorModal') public pushErrorModal: ModalDirective;
    public order: any;
    public isColombia: boolean;
    /**
    * Variable que indica que se esta descargando el archivo de excel
    */
    public downloadExcel: boolean;
    /**
     * indica si ya esta cerrada la modal del detalle de una orden
     */
    private isHideModalDetailOrder: boolean;

    constructor(
        private router: Router,
        private localize: LocalizeRouterService,
        private ordersService: OrdersService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private restaurantService: RestaurantService,
        private toastr: ToastrService,
        private utils: UtilsComponent,
        private userLocation: UserLocationService,
        private sweet: SweetAlertService,
        private translate: TranslateService
    ) {
        // this.orderIconObject = 'fa fa-chevron-up';
        this.downloadExcel = false;
        this.buttonStatus = false;
        this.orderIconObject = '';
        this.maxSize = new Pagination();
        this.orderTypeConstants = Constants.orderType;
        this.phoneOrderTypeConstants = Constants.PhoneOrderType;
        this.statusConstants = Constants.CONSTANTS_STATE;
        this.ordersStatus = this.utils.clone(Constants.CONSTANTS_OMT_STATUS);
        // this.ordersStatus.splice(0, 1);
        this.searchFields = {
            orderCodeParam: null,
            restaurantId: '',
            statusOrder: '',
            country: '',
            dateRangePicker: ''
        };
        this.types_brand = Constants.TYPES_BRAND;
        this.isColombia = false;
        this.DINE_IN_CONSTANTS = Constants.DINE_IN_TYPE;
        this.paginationArray = [25, 50, 100];
        this.isHideModalDetailOrder = true;
    }

    ngOnInit() {
        this.isColombia = this.userLocation.isColombia();
        // Llamo a la funcion que carga en el select el listado de restaurantes existentes
        if (this.status) {
            this.searchFields.statusOrder = this.status;
        }
        this.getAllRestaurants();
        if (this.allOrders) {
            this.getOrders();
        } else {
            this.route.params.subscribe(params => {
                this.searchFields.restaurantId = params.id;
                this.refreshOrders();
            });
        }
    }

    /**
     * Refresh the orders list
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.0.0
     */
    public refreshOrders() {
        this.getOrders();
    }

    /**
     * Obtain orders from ws
     * @param eventPage if there's pagination
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.0.0
     */
    public getOrders(eventPage: any = null, itemsPerPage: number = 25) {
        this.paginationSelected = itemsPerPage;
        this.itemsPerPageSelected = itemsPerPage;
        this.buttonStatus = true;
        const params = this.__setParamsQueryOrders(eventPage);
        this.ordersService.getOrdersFromRestaurant(params, itemsPerPage)
            .pipe(finalize(() => {
                this.buttonStatus = false;
                this.paginationSelected = null;
            }))
            .subscribe((x: object) => {
                if (!x['errors']) {
                    this.orders = x['data'];
                    this.pagination = x['pagination'];
                    this.getArrayAdditionalName(this.orders);
                }
            });
    }
    /**
     * Se definen los parametros para hacer la consulta de ordenes
     * @param eventPage 
     */
    private __setParamsQueryOrders(eventPage: any): string {
        let params = '';
        if (eventPage) {
            params += '&page=' + eventPage.page;
        } else {
            params += '&page=1';
        }

        if (this.searchFields.restaurantId) {
            params += '&idRestaurant=' + this.searchFields.restaurantId;
        }
        if (this.searchFields.statusOrder && this.searchFields.statusOrder != "" || Number(this.searchFields.statusOrder) === 0) {
            if (Number(this.searchFields.statusOrder) === 3 || this.searchFields.statusOrder === '1,3') {
                this.searchFields.statusOrder = '1,3';
            } //else {
            // params += '&notOmtStatus=1,3';
            // params += '&omtStatus=' + this.searchFields.statusOrder;
            // }
            params += '&omtStatus=' + this.searchFields.statusOrder;
            if (String(this.searchFields.statusOrder) === '0') {
                params += '&status=' + String(this.searchFields.statusOrder);
            }
        } else {
            params += '&notOmtStatus=1,3';
        }
        //captura y setear el parametro para hacer la busqueda
        if (this.searchFields.orderCodeParam && this.searchFields.orderCodeParam !== '') {
            params += '&orderCode=' + this.searchFields.orderCodeParam;
        }
        if (this.searchFields.country && this.searchFields.country !== '') {
            params += '&country=' + this.searchFields.country;
        }
        if (this.searchFields.dateRangePicker && this.searchFields.dateRangePicker.length > 0) {
            params += '&startDate=' + moment(this.searchFields.dateRangePicker[0]).format('YYYY/MM/DD');
            params += '&endDate=' + moment(this.searchFields.dateRangePicker[1]).format('YYYY/MM/DD');
          }
        return params;
    }

    /**
     * It let you go to the restaurants list
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.0.0
     */
    public goToRestaurantList() {
        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }

    /**
     * Opens detail order modal
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.1.0
     */
    public goToDetailOrder(order: object = null) {
        if (!order) {
            order = this.order;
            this.closeModal();
        }
        const initialState = {
            order: order
        };
        this.isHideModalDetailOrder = false;
        this.modalService.show(DetailOrderModalComponent, { initialState, class: 'overflow-in-modal' });
        this.modalService.onHide.subscribe((reason: string | any) => {
            if(!this.isHideModalDetailOrder){
                this.isHideModalDetailOrder = true;
                this.refreshOrders();
            }
           
        });
    }
    /**
     * Metodo encargado de realizar la petición del listado completo de restaurantes
     * y de los items para realizar la paginación
     */
    private getAllRestaurants() {
        const params = this.__setParamsQueryRestaurant();
        this.ordersService.getRestaurant(params).subscribe(x => {
            if (!x['errors']) {
                this.restaurants = x['data'];
            }
        });
    }

    /**
     * Se definen los parametros para hacer la consulta de restaurantes
     * @param eventPage 
     */
    private __setParamsQueryRestaurant(): string {
        let params = '';
        if (this.status) {
            this.searchFields.statusOrder = this.status;
        }
        if (this.searchFields.statusOrder && this.searchFields.statusOrder != "") {
            if (Number(this.searchFields.statusOrder) === 3 || this.searchFields.statusOrder === '1,3') {
                this.searchFields.statusOrder = '1,3';
                params += '&omtStatus=' + this.searchFields.statusOrder;
            } else {
                params += '&omtStatus=' + this.searchFields.statusOrder;
            }
        }
        if (this.searchFields.country && this.searchFields.country !== '') {
            params += '&country=' + this.searchFields.country;
        }
        return params;
    }
    /**
     * Funcion que llena el campo del nombre del cliente cuando no viene desde la base de datos
     * porque compro sin loguearse
     * @param orders 
     */
    getArrayAdditionalName(orders) {
        for (const order of orders) {
            if (!order.clientName && !order.clientLastName) {
                const arrayNameCustomer = order.additionalName.split('-SPLIT-');
                if (arrayNameCustomer.length > 1) {
                    order.clientName = arrayNameCustomer[0];
                    order.clientPhone = arrayNameCustomer[1];
                }
            }
            order['resendingOrderAndroidSyncStatus'] = false;
            order['resendingOrderSyncStatus'] = false;
        }

    }
    /**
     * Send push to level lite / android to restaurant see it has pending orders
     * @param order 
     * @param notifyAndroid 
     */
    public getDataFromOrderToSync(order, notifyAndroid: boolean = false) {
        if (notifyAndroid){
            order.resendingOrderAndroidSyncStatus = true;
        } else {
            order.resendingOrderSyncStatus = true;
        }
        this.ordersService.getOrderRegisterNotSync(order.orderCode).subscribe(resp => {
            if (resp['data']) {
                this.resendOrder(order, resp['data'][0]['msgId'], resp['data'][0]['restaurantNick'], notifyAndroid);
            }
        }, error => {
            console.log('error', error);
        });
    }

    public resendOrder(order, messageId, nickname, notifyAndroid) {
        this.ordersService.resendOrderPending(messageId, nickname, order.orderCode, notifyAndroid).subscribe(resp => {
            if (resp['data']) {
                this.toastr.success(resp['data']);
                this.refreshOrders();
            } else {
                // this.toastr.warning(resp['errors']['message']);
                this.openModalOrdenStillPending();
            }
            if(notifyAndroid){
                order.resendingOrderAndroidSyncStatus = false;
            } else {
                order.resendingOrderSyncStatus = false;
            }
            this.order = order;
        }, error => {
            console.log('error', error);
        })
    }

    /**
     * Opens modal-info
     * @author Anderson Barreto - May. 15 - 2020
     * @version 1.0.0
     */
    public openModalInfo() {

        this.modalService.show(ModalInfoComponent, { initialState: { openModal: 'order-info' }, class: 'overflow-in-modal' });
    }
    /** 
     * @description Abre el modal del error al reencolar
     */
    private openModalOrdenStillPending() {
        this.pushErrorModal.show();
    }

    /**
     * Cierra el modal del error al reencolar
     */
    public closeModal() {
        this.pushErrorModal.hide();
    }

    /**
     * @author Anderson Barreto B. Jun 3 - 2020
     * @version 1.0.0
     * @description Metodo encargado de comunicarse con el servicio para cancelar la orden
     * @param order contiene la data de la orden
     */
    public cancelOrder(order: any) {
        this._cancelOrderAlert().then(res => {
            if (res) {
                this.translate.get(['EXPLAINED_CANCELED', 'EXPLAINED_CANCELED_EXAMPLE', 'EMPTY_MESSAGE', 'Confirm', 'Cancel']).subscribe(translation => {
                    this.sweet.modalWithPrompt(translation.EXPLAINED_CANCELED, translation.EXPLAINED_CANCELED_EXAMPLE, translation.EMPTY_MESSAGE)
                        .then(resp => {
                            console.log('resp', resp);
                            order.cancellation_message = resp;
                            this.ordersService.cancelOrder(order).subscribe(resp => {
                                if (resp['errors']) {
                                    this.toastr.error(resp['errors']['message']);
                                } else {
                                    const orderIndex = this.orders.findIndex(orders => orders.id == order.id);
                                    if (orderIndex != -1) {
                                        this.orders.splice(orderIndex, 1)
                                    }
                                    this.toastr.success(resp['data']);
                                }
                                // console.log('resp', resp); this.toastr.success(resp['data']);
                            })
                        });
                });
            }
        })
    }

    /**
     * @author Anderson Barreto B. Jun 3 - 2020
     * @version 1.0.0
     * @description Lanza un alerta de confirmacion para cancelar una orden
     */
    private _cancelOrderAlert(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.translate.get(['DROP_ORDER', 'CONFIRM_DROP_ORDER_MESSAGE', 'Confirm', 'Cancel']).subscribe(translation => {
                const buttons = [translation.Cancel, translation.Confirm]
                this.sweet.confirm(translation.DROP_ORDER, translation.CONFIRM_DROP_ORDER_MESSAGE, null, buttons)
                    .then(res => {
                        resolve(res);
                    });

            });
        });
    }

    /**
     * Exporta el reporte en excel
     * @param eventPage 
     */
    public exportToExcel() {
        this.downloadExcel = true;
        this.pagination.page = this.pagination.currentPage;
        const params = this.__setParamsQueryOrders(this.pagination);
        this.ordersService.getExcelReport(params, this.itemsPerPageSelected).subscribe((fileData: any) => {
            this.downloadExcel = false;
            let blob = new Blob([fileData._body], { type: fileData.type });

            const file = new File([blob], `orders-reports.xlsx`, { type: fileData.type });
            FileSaver.saveAs(file);
        }, err => console.log(err));
    }
}
