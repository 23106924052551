export class Constants {
    static RANGE_MIN_VALUE = 100;
    static RANGE_MAX_VALUE = 32250;

    static STATUS_REVIEWS_PENDING = '0';

    public static readonly CLICK_TYPES = {
        TYPE_RESTAURANT_CLICKED: 1,
        TYPE_REVIEWS_CLICKED: 2,
        TYPE_PHONE_CLICKED: 3,
        TYPE_PHONECALLED_CLICKED: 4,
        TYPE_ADDRESS_CLICKED: 5,
        TYPE_COUPONS_CLICKED: 6,
        TYPE_RESTAURANTHOME_CLICKED: 7,
        TYPE_COUPONSPECIFIC_CLICKED: 8,
        TYPE_COUPONREEDEM_CLICKED: 9,
        TYPE_OFFERSINPLACE_CLICKED: 10,
        TYPE_OFFERSOUTPLACE_CLICKED: 11,
        TYPE_ASK_FOR_COUPONS: 12,
        TYPE_COUPON_NOT_REEDEMED: 13,
        TYPE_COUPON_ALREADY_CLAIMED: 14,
        TYPE_REDEEM_REGISTRATION: 15,
        TYPE_REVIEW_REGISTRATION: 16,
        TYPE_REVIEW_POSTED: 17,
        TYPE_REVIEW_NOT_POSTED: 18
    }

    public static readonly CLICK_TYPES_ITIRABLES = [
        { number: 1, name: 'RESTAURANT_CLICKED' },
        { number: 2, name: 'REVIEWS_CLICKED' },
        { number: 3, name: 'PHONE_CLICKED' },
        { number: 4, name: 'PHONECALLED_CLICKED' },
        { number: 5, name: 'ADDRESS_CLICKED' },
        { number: 6, name: 'COUPONS_CLICKED' },
        { number: 7, name: 'RESTAURANTHOME' },
        { number: 8, name: 'COUPONSPECIFIC_CLICKED' },
        { number: 9, name: 'COUPONREEDEM_CLICKED' },
        { number: 10, name: 'OFFERSINPLACE_CLICKED' },
        { number: 11, name: 'OFFERSOUTPLACE_CLICKED' },
        { number: 12, name: 'ASK_FOR_COUPONS' },
        { number: 13, name: 'COUPON_NOT_REEDEMED' },
        { number: 14, name: 'COUPON_ALREADY_CLAIMED' },
        { number: 15, name: 'REDEEM_REGISTRATION' },
        { number: 16, name: 'REVIEW_REGISTRATION' },
        { number: 17, name: 'REVIEW_POSTED' },
        { number: 18, name: 'REVIEW_NOT_POSTED' },
        { number: 19, name: 'HOME_SPECIAL_OFFERS_AND_COUPONS' },
        { number: 20, name: 'MY_PTA_PTSA_COUPON_CLICKED' },
        { number: 21, name: 'PTA_PLAN_CLICKED' },
        { number: 22, name: 'PTA_COUPON_CLICKED' },
        { number: 23, name: 'SPECIAL_OFFERS_CLICKED' },
        { number: 24, name: 'NOTIFICATION_COUPON_RECIEVED' },
        { number: 25, name: 'NOTIFICATION_ORDER_RECIEVED' },
        { number: 26, name: 'NOTIFICATION_MASSIVE_RECIEVED' },
        { number: 27, name: 'NOTIFICATION_COUPON_PUSH_CLICKED' },
        { number: 28, name: 'NOTIFICATION_ORDER_PUSH_CLICKED' },
        { number: 29, name: 'NOTIFICATION_MASSIVE_PUSH_CLICKED' },
        { number: 30, name: 'NOTIFICATION_COUPON_ALERT_CLICKED' },
        { number: 31, name: 'NOTIFICATION_ORDER_ALERT_CLICKED' },
        { number: 32, name: 'NOTIFICATION_MASSIVE_ALERT_CLICKED' },
        { number: 33, name: 'PTA_OFFERSOUTPLACE_CLICKED' }
    ];

    /**
     * Almacena nombres de las variables del local storage
     */
    public static readonly LOCAL_STORAGE_DATA = {
        AUTH_TOKEN: 'sd_auth_token',
        USER: 'sd_user',
        TYPE: 'sd_type',
        MODULES: 'sd_modules',
        ID_USER: 'sd_idUser',
        ENABLED_MODULES: 'sd_enabled_modules',
        FILTER_LEVEL: 'sd_filterLevel',
        NAV_STATIC: 'sd_nav-static',
        DATA_CUSINES: 'sd__DATA_CUISINES__',
        DATA_MEALS: 'sd__DATA_MEALS__',
        DATA_FEATURES: 'sd__DATA_FEATURES__',
        RESTAURANT_LIST: 'sd_restaurantsList'
    };

    /**
     * Almacena nombres de las variables del sessions storage
     */
    public static readonly SESSIONS_STORAGE_DATA = {
        USER_COUNTRY: 'sd_userCountry'
    };

    /**
     * Tipos de estados para saber si el usuario es anonimo
     */
    public static readonly TYPES_USER = {
        TYPE_NOT_WAS_ANONYMUS: 0,
        TYPE_IS_ANONIMUS: 1
    }

    /**
     * Tipos para saber desde donde se llamo la funcion
     */
    public static readonly TYPES_BRAND = {
        TYPE_KIND_OMT_WEB: 2,
        TYPE_KIND_OMT_APP: 3,
        TYPE_KIND_MENU_ONLINE: 4,
        TYPE_KIND_WHITE_LABEL: 5,
    }
    /**

    /**
     * Nota: El originNumber es el número establecido para obtener de que plataforma se realizó la compra.
     * 1 = level
     * 2 = Open My Tab Web
     * 3 = Open My Tab App
     * 4 = Menu Online 
     * 5 = White Label
     */
    /**
     * Variable identificadora de la página (White label)
     */
    public static readonly BRAND_ALIAS_WHITE_LABEL = {
        name: "WHITE_LABEL",
        originNumber: 5
    }

    /**
    * Variable identificadora de la página (MENU_ONLINE);
    */
    public static readonly BRAND_ALIAS_ONLINE = {
        name: "MENU_ONLINE",
        originNumber: 4
    }

    /**
     * orders type
     */
    public static readonly orderType = {
        dineIn: 1,
        counter: 2,
        phoneOrder: 3,
        barTab: 4,
        driveThru: 5
    };

    /**
     * phone orders type
     */
    public static readonly PhoneOrderType = {
        /**
         * Esto es para cuando el order type no es 3(phone order)
         */
        noPhone: 0,
        /**
         * Cuando el tipo de phone order es pickup
         */
        pickup: 1,
        /**
         * Cuando el tipo de phone order es delivery
         */
        delivery: 2,
        /**
         * Se utiliza para indicar que el order type es 1(dine in) y que es una mesa
         */
        table: 3,
        /**
         * Se utiliza para indicar que el order type es 1(dine in) y que es un palito u otro objeto donde se tiene el número de 
         */
        turn: 4,
        /**
         * Se utiliza para indicar que el order type es 1(dine in) y que el sistema le genera el turno automaticamente
         */
        turnAutomatic: 5
    }

    /**
     * Constantes de estado de pedido
     */
    public static readonly CONSTANTS_STATE = {
        CANCELED: 0,
        PENDING_ALSO: 1,
        PENDING: 3,
        RECEIVED: 4,
        BEING_PREPARED: 5,
        PREPARED: 6,
        DELIVERY_ON_SITE: 7,
        SEND_TO_DELIVERY: 8,
        SEND_TO_CUSTOMER: 9,
        ARRIVES_AT_DESTINATION: 10,
        DELIVERED: 11
    };
    /**
    * Constantes de estado de pedido con su traduccion.
    * NOTA: Se hace en un array para recorrerlo y mostrarlo en el select del filtrado de ordenes. 
    * Se comentan los estados que no se utilizan actualmente
    */
    public static readonly CONSTANTS_OMT_STATUS = [
        {
            number: 0,
            name: 'CANCELED'
        },
        {
            number: 3,
            name: 'PENDING'
        },
        {
            number: 4,
            name: 'RECEIVED'
        },
        {
            number: 5,
            name: 'BEING_PREPARED'
        },
        {
            number: 6,
            name: 'PREPARED'
        },
        // {
        //     number: 7,
        //     name: 'DELIVERY_ON_SITE'
        // },
        // {
        //     number: 8,
        //     name: 'SEND_TO_DELIVERY'
        // },
        // {
        //     number: 9,
        //     name: 'SEND_TO_CUSTOMER'
        // },
        // {
        //     number: 10,
        //     name: 'ARRIVES_AT_DESTINATION'
        // },
        {
            number: 11,
            name: 'DELIVERED'
        }
    ];
    /* Tipo de estados de actividades detectadas en el acompañamiento de un cliente en OMT
    */

    public static readonly DINE_IN_TYPE = {
        TABLE: 3,
        BEACON: 4,
        CONSECUTIVE_DAILY: 5
    };
    /**
     * Permite identificar el tipo de regla sobre el cual trabajara la interpretation de notificaciones masivas
     */
    public static readonly TYPE_OF_NOTIFICATION = {
        RULE_GENERIC: 0,
        RULE_RESTAURANT: 1,
        RULE_PTA: 2,
        RULE_CATEGORY: 3,
        RULE_PRODUCT: 4,
        RULE_COUPONS: 5
    }
    /**
     * Constantes para el estado de la notificación
     */
    public static readonly STATUS_NOTIFICATIONS = [
        { name: 'INITIATED', number: 0 },//Inicializado pero aun no listo para notificar
        { name: 'SCHEDULED', number: 1 },// Listo para notiticar
        { name: 'IN_PROGRESS', number: 2 },// Se esta procesando (Ya inicio pero aun no ha notificado todos los clientes)
        { name: 'STOPPED', number: 3 },  // Una pausa  por si paso algo con la configuraion de la notificaciones masivas
        { name: 'ENDED', number: 4 }// Ya termino de notificar o no encontro mas clientes para notificar
    ]



    /**
         * Cuando las notificaciones son por clientes en el campo tipos de clientes a notificar 
         */
    // const ALL_CLIENTS = 0;
    // const OLD_CLIENTS = 1;
    // const NEW_CLIENTS = 2;

}