import { LocalizeRouterService } from 'localize-router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Component, OnInit } from '@angular/core';
//import { Widget } from './../core/widget/widget';
/*import { ROUTER_DIRECTIVES, Router, CanActivate } from '@angular/router';*/
import { Router } from '@angular/router';

import { detailRestaurant } from '../entity/detailRestaurant';
/*import { UserService } from './../services/user.service';

import { UtilsComponent } from './../utils/utils';
import { InspectPermitsComponent } from './../inspectPermits/inspectPermits';
import { MailService } from './../services/mail.service';*/
import { ClientService } from '../services/client.service';
import { PermissionsUserService } from '../services/permissionsUser.service';
//import { TimerWrapper } from '@angular/common';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
// import { CHART_DIRECTIVES } from 'ng2-charts/ng2-charts';
import { RestaurantService } from '../services/restaurant.service';
import { Constants } from 'app/app.constants';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.html',
    // directives: [CHART_DIRECTIVES],
    //pipes: [TranslatePipe],
    styles: [`
    .chart {display: block}
  `],
})
/*@CanActivate((next, prev) => {
  let injector: Injector = appInjector();
  let router: Router = injector.get(Router);

  if (!isLoggedIn()) {
    router.navigate(['/LoginPage']);
    return false;
  }

  return true;
})*/

export class Dashboard implements OnInit {

    router: Router;
    public modalRef: BsModalRef;

    constructor(
        private restaurantService: RestaurantService,
        private permissionsUserService: PermissionsUserService,
        router: Router,
        // private utils: UtilsComponent,
        // private mailService: MailService,
        private clientService: ClientService,
        // private userService: UserService
        private localize: LocalizeRouterService
    ) { this.router = router; }

    /**
     * Variable que controla si se muestra o no la seccion
     */
    canShowSection: boolean = false;
    canViewClient: boolean = false;
    canViewLevelRestaurant: boolean = false;
    canViewDirectoryRstaurant: boolean = false;

    /**
    * Variable que captura el listado de modulos habilitados en OMT
    */
    permissions: any;

    /**
     * Variable para capturar los restaurantes obtenidos en la consulta al web service
     */
    allRestaurants: detailRestaurant[];
    /**
     * Variable para contar la cantidad de restaurantes de level y que no son de level
     */
    levelRestaurants: number = 0;
    omtRestaurants: number = 0;
    /**
     * Variable para contar la cantidad de usuarios registrados en la app movil
     */
    usersApp: number = 30;
    /**
     * Variable para contabilizar los restaurantes que no han establecido horarios
     */
    withoutHours: number = 0;
    /**
     * Variable para contabilizar los restaurantes que no tienen foto
     */
    withoutPhoto: number = 0;
    /**
     * Variable con la cantidad de items que va a traer por pagina en la consulta
     */
    items_per_page: number = 50;
    /**
     * Variable que recibe respuesta completa de la consulta al web service
     */
    fullResponse: any;
    /**
     * Variable encargada del manejo de errores en las consultas a los web-services
     */
    error: any;
    /**
     * Variable para almacenar lenguaje de la aplicacion
     */
    userLang: string;
    /**
     * Variable que contendra los meses que se mostraran en las gráficas de nuevos items por mes
     */
    labels: any;
    /**
     * Variable que almacenara las estadistica generales de Open My Tab
     */
    generalStatics: any;
    /**
     * Variable que contendra las estadisticas de los nuevos restaurantes creados de level
     */
    newUsers: any;
    /**
     * Variable que contendra las estadisticas de los nuevos restaurantes creados de level
     */
    newRestaurantsLevel: any;
    /**
     * Variable que contendra las estadisticas de los nuevos restaurantes creados de directorio
     */
    newRestaurantsDirectory: any;
    /**
     * Variable que almacena las estadisticas de los nuevos restaurantes de level y se usa para pintar las graficas
     */
    datasetsRestaurantLevel: any;
    /**
     * Variable que almacena las estadisticas de los nuevos restaurantes de directorio y se usa para pintar las graficas
     */
    datasetsRestaurantDirectory: any;
    /**
     * Variable que almacena las estadisticas de los nuevos usuarios y se usa para pintar las graficas
     */
    datasetsNewUsers: any;
    /**
     * Variable con identificador de la seccion dashboard
     */
    idSectionReports: number = 31;
    viewClient: number = 14;
    viewLevelRestaurant: number = 3;
    viewDirectoryRestaurant: number = 4;

    before1month: string;
    before2month: string;
    before3month: string;
    currentMonth: string;

    level: string = 'true';
    directory: string = 'false';

    newPermissions: Array<Object> = [];

    /**
     * Metodo encargado de dirigr hacia el listado de restaurantes con el filtro
     * de restaurantes de Level o Directorio
     */

    goToRestaurants(filterlevel: string) {
        if (filterlevel == this.level && this.canViewLevelRestaurant) {
            localStorage.setItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL, this.level);
        } else if (filterlevel == this.directory && this.canViewDirectoryRstaurant) {
            localStorage.setItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL, this.directory);
        }

        setTimeout(() => {
            if (filterlevel == this.level && this.canViewLevelRestaurant) {
                this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
            } else if (filterlevel == this.directory && this.canViewDirectoryRstaurant) {
                this.router.navigate([this.localize.translateRoute('/app/restaurant')]);

            }
        }, 200);
    }

    /**
     * Metodo encargado de dirigir al listado de clientes de OMT
     */
    goToClients() {
        let fromSidebar: boolean = true;
        this.clientService.cleanParametersSearch(fromSidebar);

        setTimeout(() => {
            if (this.canViewClient) {
                this.router.navigate([this.localize.translateRoute('/app/clients')]);
            }
        }, 10);
    }


    /**
     * Metodo encargado de realizar la petición del listado completo de restaurantes
     * y de los items para realizar la paginación
     */
    getAllRestaurants() {
        this.restaurantService
            .getAllRestaurants(this.items_per_page, false)
            .subscribe(allRestaurants => {
                this.fullResponse = allRestaurants;
                this.getListRestaurants(this.fullResponse);
                setTimeout(() => {
                    for (var i = 0; i < this.allRestaurants.length; i++) {
                        if (this.allRestaurants[i].isFromLevel) {
                            this.levelRestaurants = this.levelRestaurants + 1;
                        } else if (!this.allRestaurants[i].isFromLevel) {
                            this.omtRestaurants = this.omtRestaurants + 1;
                        }

                        if (this.allRestaurants[i].images.length == 0) {
                            this.withoutPhoto = this.withoutPhoto + 1;
                        }
                    }
                }, 200);
            }, error => {
                this.error = error;
                console.info("ERROR: ", error);
            });
    }

    /**
     * Metodo encargado de capturar el listado completo de restaurantes
     */
    getListRestaurants(res: any) {
        this.allRestaurants = res.data || {};
        this.restaurantService.setDataRestaurantList(this.allRestaurants);
    }

    ngOnInit() {
        this.userLang = navigator.language.split('-')[0];

        let idUser = localStorage.getItem(Constants.LOCAL_STORAGE_DATA.ID_USER,);
        setTimeout(() => {
            if (!idUser) {
                this.router.navigate([this.localize.translateRoute('/login')]);
            } else {
                //this.permissionsUserService.getPermissionsUser(idUser, this.userLang);
            }
        }, 10);

        setTimeout(() => {
            this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionReports);
            this.canViewClient = this.permissionsUserService.verifyPermissionsUser(this.viewClient);
            this.canViewLevelRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewLevelRestaurant);
            this.canViewDirectoryRstaurant = this.permissionsUserService.verifyPermissionsUser(this.viewDirectoryRestaurant);
        }, 600);

        setTimeout(() => {
            this.getAllRestaurants();
            this.getGeneralStatics();
            this.getStaticsNewRestaurantsLevel();
            this.getStaticsNewRestaurantsDirectory();
            this.getStaticsNewUsers();

            this.setDataForGraphics();

            let date = new Date(), y = date.getFullYear(), m = date.getMonth();
            this.currentMonth = this.setMonth(date.getMonth());
            this.before1month = this.setMonth(date.getMonth() - 1);
            this.before2month = this.setMonth(date.getMonth() - 2);
            this.before3month = this.setMonth(date.getMonth() - 3);

            //this.labels = [this.before3month, this.before2month, this.before1month, this.currentMonth];
            this.labels = ["", "", "", ""];
        }, 700);

    }

    // getGeneralStatics() {
    //   console.log('getGeneralStatics');
    //   this.restaurantService
    //     .getGeneralStatics(this.userLang)
    //     .then(generalStatics => {
    //       this.generalStatics = generalStatics;
    //       console.warn('generalStatics' ,  this.generalStatics);
    //     })
    //     .catch(error => console.log('generalStatics' ,  this.generalStatics));
    // }

    /**
     * Metodo encargado de setear los datos que se mostraran en las graficas
     */

    setDataForGraphics() {

        let labelRestaurant: string = ""
        if (this.userLang == 'es') {
            labelRestaurant = 'Nuevos Restaurantes';
        } else {
            labelRestaurant = 'New Restaurants';
        }

        setTimeout(() => {
            //Seteo de label
            // this.labels = [this.newRestaurantsLevel[3].monthName,
            // this.newRestaurantsLevel[2].monthName,
            // this.newRestaurantsLevel[1].monthName,
            // this.newRestaurantsLevel[0].monthName
            // ];

            //Seteo de grafica de nuevos restaurantes de level
            this.datasetsRestaurantLevel = [
                {
                    label: labelRestaurant,
                    data: /*[this.newRestaurantsLevel[3].countRestaurants,
          this.newRestaurantsLevel[2].countRestaurants,
          this.newRestaurantsLevel[1].countRestaurants,
          this.newRestaurantsLevel[0].countRestaurants
          ]*/
                        [7, 4, 5, 2]
                }
            ];

            //Seteo de grafica de nuevos restaurantes de directorio
            this.datasetsRestaurantDirectory = [
                {
                    label: labelRestaurant,
                    data: /*[this.newRestaurantsDirectory[3].countRestaurants,
          this.newRestaurantsDirectory[2].countRestaurants,
          this.newRestaurantsDirectory[1].countRestaurants,
          this.newRestaurantsDirectory[0].countRestaurants
          ]*/
                        [8, 14, 8, 3]
                }
            ];

            // this.datasetsNewUsers = [
            //   {
            //     label: "",
            //     data: [this.newUsers[3].countClients,
            //     this.newUsers[2].countClients,
            //     this.newUsers[1].countClients,
            //     this.newUsers[0].countClients
            //     ]
            //   }
            /*{
              label: "",
              data: [3,
                2,
                10,
                1
              ]
            }*/
            // ];
        }
            , 900);

    }

    public lineChartColors: Array<any> = [
        { // grey
            backgroundColor: 'rgba(102,255,102,0.5)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }]


    /**
     * Metodo encargado de capturar el reporte con las estadisticas generales
     * que maneja Open My Tab
     */
    getGeneralStatics() {
        this.restaurantService
            .getGeneralStatics(this.userLang)
            .then(generalStatics => {
                this.generalStatics = generalStatics;
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de realizar peticion a web service y capturar las estadisticas acerca
     * de los nuevos usuarios que fueron registrados en OMT
     */
    getStaticsNewUsers() {
        this.restaurantService
            .getStaticsNewUsers(this.userLang)
            .then(newUsers => {
                this.newUsers = newUsers;
            })
    }

    /**
     * Metodo encargado de realizar peticion a web service y capturar las estadisticas acerca
     * de los nuevos restaurantes que fueron creados en OMT de Level
     */
    getStaticsNewRestaurantsLevel() {
        this.restaurantService
            .getStaticsNewRestaurants(this.userLang, 'true')
            .then(newRestaurantsLevel => {
                if (!newRestaurantsLevel.error) {
                    this.newRestaurantsLevel = newRestaurantsLevel;
                } else {
                    console.error(newRestaurantsLevel.error);
                }
            }, error => {
                console.error(error.url, error.status, error.statusText);
            });
    }

    /**
     * Metodo encargado de realizar peticion a web service y capturar las estadisticas acerca
     * de los nuevos restaurantes que fueron creados en OMT de Directorio
     */
    getStaticsNewRestaurantsDirectory() {
        this.restaurantService
            .getStaticsNewRestaurants(this.userLang, 'false')
            .then(newRestaurantsDirectory => {
                if (!newRestaurantsDirectory.error) {
                    this.newRestaurantsDirectory = newRestaurantsDirectory;
                } else {
                    console.error(newRestaurantsDirectory.error);
                }
            }, error => {
                console.error(error.url, error.status, error.statusText);
            });
    }

    setMonth(date: any) {
        let month: string = "";
        if (date == 0) {
            if (this.userLang == 'es') {
                month = "Enero";
            } else {
                month = "January";
            }
        } else if (date == 1) {
            if (this.userLang == 'es') {
                month = "Febero";
            } else {
                month = "February";
            }
        } else if (date == 2) {
            if (this.userLang == 'es') {
                month = "Marzo";
            } else {
                month = "March";
            }
        } else if (date == 3) {
            if (this.userLang == 'es') {
                month = "Abril";
            } else {
                month = "April";
            }
        } else if (date == 4) {
            if (this.userLang == 'es') {
                month = "Mayo";
            } else {
                month = "May";
            }
        } else if (date == 5) {
            if (this.userLang == 'es') {
                month = "Junio";
            } else {
                month = "June";
            }
        } else if (date == 6) {
            if (this.userLang == 'es') {
                month = "Julio";
            } else {
                month = "July";
            }
        } else if (date == 7) {
            if (this.userLang == 'es') {
                month = "Agosto";
            } else {
                month = "August";
            }
        } else if (date == 8) {
            if (this.userLang == 'es') {
                month = "Septiembre";
            } else {
                month = "September";
            }
        } else if (date == 9) {
            if (this.userLang == 'es') {
                month = "Octubre";
            } else {
                month = "October";
            }
        } else if (date == 10) {
            if (this.userLang == 'es') {
                month = "Noviembre";
            } else {
                month = "November";
            }
        } else if (date == 11) {
            if (this.userLang == 'es') {
                month = "Diciembre";
            } else {
                month = "December";
            }
        }

        return month;
    }

    //---------------------- Manejo Gráficas ---------------------------------

    private options = {
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };

    //-----------------------------------------------------------------------
    //}
}
