import * as moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsComponent {

  constructor(private http: HttpClient) { }

  /**
   * Variable que almacenara los modulos que estan habilitados para el usuario logueado
   */
  private enabledModules;

  categoryImage: number;

  /*Opciones para mostrar la notificacion*/
  public options = {
    timeOut: 2300,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: true,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: "visible",
    rtl: false,
    animate: "scale",
    position: ["right", "bottom"]
  };

  /*Opciones para mostrar la notificacion de detalles restaurante*/
  public optionsDetails = {
    timeOut: 800,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: true,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: "visible",
    rtl: true,
    animate: "scale",
    position: ["right", "bottom"]
  };

  /**
   * Variable url para utilizar en el envio de peticiones al webserive
   * ip Pruebas local 172.16.31.18:3000
   */

  // SERVIDOR LOCAL
  //public urlOmtSd: string = 'http://omt.loc/app_dev.php/sd/restaurant';
  //public urlOmt: string =  'http://omt.loc/app_dev.php/';

  // SERVIDOR LOCAL
  // public urlOmtSd: string = 'http://127.0.0.1/WebServiceSymfony/web/app_dev.php/sd/restaurant';
  // public urlOmt: string =  'http://127.0.0.1/WebServiceSymfony/web/app_dev.php/';

  // SERVIDOR LOCAL alejo
  // public urlOmtSd: string = 'http://192.168.0.160/OmtWsSymfony/web/app_dev.php/sd/restaurant';
  // public urlOmt: string =  'http://192.168.0.160/OmtWsSymfony/web/app_dev.php/';

  // SERVIDOR LOCAL LUIS FER
  // public urlOmtSd: string = 'http://192.168.0.127/sd/restaurant';
  // public urlOmt: string =  'http://192.168.0.127/';

  // SERVIDOR PRUEBAS VIEJO (NODEJS)
  // urlOmtSd: string = 'http://openws.kijho.com/sd/restaurant';
  // urlOmt: string = 'http://openws.kijho.com/';

  // SERVIDOR LOCAL DOCKER
  // public urlOmtSd: string = 'http://omt.localhost/sd/restaurant';
  // public urlOmt: string =  'http://omt.localhost/';

  urlOmtSd: string = environment.urlOmtSd;
  urlOmt: string = environment.urlOmt;
  urlOmtWeb: string = environment.urlOmtWeb;
  urlWebDetailKijho: string = environment.urlWebKijhoDetail;
  urlWebDetailKijhoList: string = environment.urlWebKijhoDetailList;

    /**
     * Metod encargado de devolver la Ip de los webservice
     * Nota: Sin sd en la URL
     */
    getUrlOmt() {
      return this.urlOmt;
    }
  
    getUrlOmtWeb() {
      return this.urlOmtWeb;
    }
  
    getUrlWebDetailKijho() {
      return this.urlWebDetailKijho;
    }
  
    getUrlWebDetailKijhoList() {
      return this.urlWebDetailKijhoList;
    }
  
    /**
     * Metod encargado de devolver la Ip de los webservice
     * Nota: Con sd en la URL
     */
    getUrlOmtSd() {
      return this.urlOmtSd;
    }

  /**
   * Metodo encargado de devolver la variable con las configuraciones necesarias
   * para mostrar notificaciones
   */
  setOptions() {
    return this.options;
  }

  setOptionsDetails() {
    return this.optionsDetails;
  }

  setEnabledModules(enabledModules: any) {
    this.enabledModules = enabledModules;
  }

  getEnabledModules() {
    return this.enabledModules;
  }

  /**
   * Metodo encargado de limpiar cadenas, quitando tildes
   * @param value: string -> Cadena que se va a limpiar
   */
  cleanString(value: string) {
    value = value.replace(/á/gi, "a");
    value = value.replace(/é/gi, "e");
    value = value.replace(/í/gi, "i");
    value = value.replace(/ó/gi, "o");
    value = value.replace(/ú/gi, "u");

    return value;
  }

  /**
   * permite obtener el mes de una fecha
   *
   * @param date
   * @returns {string}
   */
  extractMonthDate(date: any) {
    const month = moment(date).month() + 1;
    console.info("MONTH: ", month);
    return String(month);
    /*
      let startDate = date.substr(0, 10);
      let partDate = startDate.split('-');
      //startDate = (partDate[2] + '-' + partDate[0] + '-' + partDate[1]);
      startDate = (partDate[2]);
      return startDate.replace(/^0+/, '');
    */
  }

  extractDate(date: any) {
    console.info("EXTRACT DATE: ", date);
    let startDate = date.substr(0, 10);
    let partDate = startDate.split('/');
    startDate = (partDate[2] + '-' + partDate[0] + '-' + partDate[1]);
    return startDate.replace(/^0+/, '');
  }

  /**
   * permite obtener el dia del mes
   *
   * @param date
   * @returns {number}
   */
  extractDayDate(date: any) {
    const day = moment(date).date();
    console.info("DAY: ", day);
    return String(day);
    /*
      let startDate = date.substr(0, 10);
      let partDate = startDate.split('/');
      startDate = (partDate[1]);
      return startDate.replace(/^0+/, '');
    */
  }

  extractYearDate(date: any){
    const year = moment(date).year();
    console.info("YEAR: ", year);
    return String(year);
  }
  /**
   * Metodo encargado de manejar los mensajes que apareceran en las notificaciones
   * cuando se agregue, edite o elimine un tipo de servicio
   * @param formData: any -> Formulario con la informacion del servicio
   * @param userLang: string -> Idioma en que se mostrarán los mensajes
   * @param type: string -> Tipo de solicitud de notificación (Agregar/Editar/Eliminar)
   */
  defineContent(formData: any, userLang: string, type: string) {
    let content = "";

    if (type == 'Deleted') {
      if (userLang == "es") {
        content = " eliminado exitosamente"
      } else {
        content = " deleted successfully"
      }
    } else {
      if (formData.id) {
        if (userLang == 'es') {
          content = ' editado exitosamente';
        } else {
          content = ' edited successfully';
        }
      } else {
        if (userLang == 'es') {
          content = ' agregado exitosamente';
        } else {
          content = ' added successfully';
        }
      }
    }
    return content;
  }

  /**
   * Metodo encargado de setear las horas para mostrar en los horarios
   * @param hour: any -> Hora que se va a setear
   * @param formatHour: boolean -> Variable que indica el formato de las horas a manejar (12/24 Horas)
   */
  captureHour(hour: any, formatHour: boolean) {
    let hour_from: string;
    let verifyHour = hour.substr(0, 2);
    let minutes = hour.substr(3, 2);
    let amPm: string = "";
    /*Validacion si el horario tiene hora de apertura*/
    if (hour) {
      if (formatHour && verifyHour == 0) {
        verifyHour = 12;
        amPm = 'AM';
      } else if (formatHour && verifyHour == 12) {
        verifyHour = 12;
        amPm = 'PM';
      } else if (formatHour && verifyHour > 12) {
        verifyHour = verifyHour - 12;
        amPm = 'PM';
        if (verifyHour < 10) {
          verifyHour = '0' + verifyHour;
        }
      } else if (formatHour && verifyHour < 12) {
        amPm = 'AM';
      } else {
        /*Si existe horario de apertura se asigna*/
        hour_from = hour.substr(0, 5);
      }
    } else {
      /*Se asigna valor por defecto*/
      hour_from = hour;
    }

    /*Validacion para saber si se retorna con formate de 12 horas*/
    if (formatHour) {
      return verifyHour + ':' + minutes + ' ' + amPm;
    } else {
      return hour_from;
    }

  }

  /**
   * Metodo encargado de setear la forma en que se mostraran las horas de las temporadas
   * @param dateHour: any -> Horario de la temporada a setear
   * @param format12Hours: boolean -> Variable que indica el formato de las horas a manejar (12/24 Horas)
   */
  extractDateHour(dateHour: any, format12Hours: boolean) {
    let formatDateHour = dateHour.substr(0, 16);
    let date = dateHour.substr(0, 10);
    let hour = dateHour.substr(11, 2);
    let minutes = dateHour.substr(13, 3);
    let typeHour = dateHour.substr(20, 2);
    if (format12Hours) {
      return formatDateHour + ' ' + typeHour;
    } else {
      if (hour == 12 && typeHour == 'AM') {
        hour = '00';
      } else if (hour < 12 && typeHour == 'PM') {
        hour = hour++ + 12;
      }
      return date + ' ' + hour + minutes;
    }
  }

  /**
   * Metodo encargado de comparar las fechas para saber si el termino se puede editar
   */
  compareDates(dateTimeA, dateTimeB) {
    let dateA = new Date(dateTimeA);
    let dateB = new Date(dateTimeB);
    if (dateA > dateB || dateA == dateB) {
      return false;
    } else if (dateA < dateB) {
      return true;
    }
  }

  /**
   * Metodo encargado de generar array para iterar en la vista para realizar la paginacion
   */
  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  // metodo encargado de desabilitar enter en un formulario
  methodkey(event) {
    if (event.key === "Enter") {
      console.warn('utils.methodkey event.key ', event.key);
      return true;
    }
  }

  /**
     * Permite clonar variables
     * @param value
     */
  public clone(value: any): any {
    if (value instanceof Array) {
        return this.cloneArray(value);
    } else if (value instanceof Object) {
        return this.cloneObject(value);
    } else {
        return value;
    }
  }

  /**
   * Permite clonar objetos
   * @param anyObject
   */
  public cloneObject(anyObject: Object): any {
    const cloneObj = {};
    // tslint:disable-next-line:forin
    for (const attribut in anyObject) {
        cloneObj[attribut] = this.clone(anyObject[attribut]);
    }
    return cloneObj;
  }

  /**
   * Permite clonar arreglos
   * @param anyArray
   */
  public cloneArray(anyArray: Array<any>) {
    const tmpArray = [];
    for (const element of anyArray) {
        tmpArray.push(this.clone(element));
    }
    return tmpArray;
  }

}
