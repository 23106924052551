//import { BUTTON_DIRECTIVES } from 'ng2-bootstrap/ng2-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { LocalizeRouterService } from 'localize-router';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { MapsAPILoader } from '@agm/core';
import {
    Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
//import { MapsAPILoader } from 'angular2-google-maps/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
//import { JqSparkline } from '../components/sparkline/sparkline';
import { Router } from '@angular/router';
//import { TimerWrapper } from '@angular/core/src/facade/async';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
import { TranslateService } from '@ngx-translate/core';

import { categoryFood } from '../entity/categoryFood';
import { restaurant } from '../entity/restaurant';
// import { ToastrService } from 'ngx-toastr';
// import { ListAccountService } from '../services/listAccount.service';
// import { SweetAlertService } from '../utils/sweetAlert.service';
import { AlertsService } from '../services/alerts.service';
import { autocompletarGoogleService } from '../services/autocompletarGoogle.service';
import { LevelSyncroLevel } from '../services/levelSync.service';
import { MenuesService } from '../services/menues.service';
// import { UserService } from './../services/user.service';
import { PermissionsUserService } from '../services/permissionsUser.service';
// import { CategoryService } from './../services/category.service';
import { RestaurantService } from '../services/restaurant.service';
import { isAdministrator } from '../services/userLogin.service';
import { UtilsComponent } from '../utils/utils';
import {
    ActivateRestaurantLevel
} from './activateRestaurantLevel/activateRestaurantLevel.component';
import { Constants } from 'app/app.constants';

//Manejo de modal para eliminar un restaurante
declare var jQuery: any;

//Variable para manejo autocompletar location
// declare var google: any;

@Component({
    selector: 'restaurant',
    templateUrl: './restaurant.html',
    styleUrls: ['./restaurant.sass'],
    // directives: [CheckAll,
    //   JqSparkline,
    //   ProgressAnimate,
    //   Widget,
    //   ROUTER_DIRECTIVES,
    //   BUTTON_DIRECTIVES,
    //   SimpleNotificationsComponent,
    //   REACTIVE_FORM_DIRECTIVES,
    //   InspectPermitsComponent],
    providers: [autocompletarGoogleService],
    entryComponents: [ActivateRestaurantLevel],
    // pipes: [TranslatePipe, SearchPipe]
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }

//   return true;
// })
export class RestaurantComponent implements OnInit {

    public modalRef: BsModalRef;
    processingResponse: boolean;

    @ViewChild('canDeleteModal') public canDeleteModal: ElementRef;
    @ViewChild('lgModal') public lgModal: ElementRef;
    @ViewChild('confirmActivateLev') public confirmActivateLev: ModalDirective;

    //Dato en reemplazo
    isLevel: any = 0;


    maxSize: number;
    bigTotalItems: number;
    bigCurrentPage: number = 1;
    numPages: number;
    itemsPerPageBs: number = 25;

    nextPag: string;
    prevPag: string;

    lastPag: string;
    firstPag: string;

    //Order Column Icon
    orderAscOrDesc: number = 1;
    orderIconObject: string = 'fa fa-chevron-up';
    orderIcon2Object: string = 'fa fa-chevron-up';
    orderIconObjectNumber: string = 'fa fa-chevron-up';
    orderIconObjectBoolean: string = 'fa fa-chevron-up';





    /**
     * Variable que controla si se muestra o no la seccion
     */
    canShowSection: boolean = false;
    canCreateRestaurant: boolean = false;
    canViewLevelRestaurant: boolean = false;
    canViewDirectoryRestaurant: boolean = false;
    canEditLevelRestaurant: boolean = false;
    canEditDirectoryRestaurant: boolean = false;
    canDeleteRestaurant: boolean = false;
    canViewMenuRestaurant: boolean = false;

    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));

    /**
     * Variable con identificador de la seccion restaurante
     */
    idSectionRestaurant: any = this.allModules['module_restaurant'];
    createRestaurant: any = this.allModules['module_create_restaurants'];
    viewLevelRestaurant: any = this.allModules['module_view_restaurants_from_level'];
    viewDirectoryRestaurant: any = this.allModules['module_view_restaurants_from_directory'];
    editLevelRestaurant: any = this.allModules['module_edit_level_restaurants'];
    editDirectoryRestaurant: any = this.allModules['module_edit_directory_restaurants'];
    deleteDirectoryRestaurant: any = this.allModules['module_delete_directory_restaurants'];
    viewMenuRestaurant: any = this.allModules['module_view_restaurant_menu'];

    /**
     * Variable que captura el listado de modulos habilitados en OMT
     */
    permissions: any;

    /**
     * Variable para creacion de formulario para manejo de busqueda
     */
    public searchForm: FormGroup;

    category: categoryFood[];
    restaurant: restaurant[];
    menu: any;
    allRestaurants: restaurant[];
    selectedRestaurant: restaurant;
    error: any;
    indexRequest: any;
    @Output() close = new EventEmitter();

    //Input para realizar la busqueda
    inputSearch: any;

    //Variable para paginación por defecto
    private items_per_page: number = 25;
    private page: number = 1;


    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Mensaje de Restaurante creado exitosamente
    public title: string = 'Restaurant';
    public content: string = "Restaurant deleted successfully";

    //Variable para manejo de idioma
    userLang: any;

    //Variable que recibe respuesta del listado completo de restaurantes
    fullResponse: any;

    //Variables para manejo de paginación
    currentPage: number;
    firstPage: number;
    lastPage: number;
    totalItems: number;
    itemsInCurrentPage: number;
    itemsPerPage: number;
    prevPage: number;
    nextPage: number;
    cleanSearch: boolean = false;
    actualPage: number;

    filterLevel: string = "";

    actiClase: string;

    /**
     * Variable para saber si el usuario es tipo Administrativo o Gestor de Contenidos
     */
    isAdmin: boolean = true;

    // variable para saber que componente se va a usar centro el componente ActivateRestaurantLevel
    actualComp: string;

    constructor(
        // private modalService: NgbModal,
        private router: Router,
        // private categoryService: CategoryService,
        private restaurantService: RestaurantService,
        // private listAccountService: ListAccountService,
        private menuesService: MenuesService,
        // private userService: UserService,
        private permissionsUserService: PermissionsUserService,
        private utils: UtilsComponent,
        private _service: NotificationsService,
        // private _loader: MapsAPILoader,
        // private _fb: FormBuilder,
        private modalService: BsModalService,
        private localize: LocalizeRouterService,
        private translate: TranslateService,
        private gMapLoader: MapsAPILoader,
        private _autocompletarGoogleService: autocompletarGoogleService,
        // private toastr: ToastrService,
        // private sweet: SweetAlertService,
        public alertService: AlertsService,
        private levelsyncroLevel: LevelSyncroLevel

    ) { }

    ngOnInit() {
        // console.log(this.allModules);

        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();
        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionRestaurant);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canCreateRestaurant = this.permissionsUserService.verifyPermissionsUser(this.createRestaurant);
                this.canViewLevelRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewLevelRestaurant);
                this.canViewDirectoryRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewDirectoryRestaurant);
                this.canEditLevelRestaurant = this.permissionsUserService.verifyPermissionsUser(this.editLevelRestaurant);
                this.canEditDirectoryRestaurant = this.permissionsUserService.verifyPermissionsUser(this.editDirectoryRestaurant);
                this.canDeleteRestaurant = this.permissionsUserService.verifyPermissionsUser(this.deleteDirectoryRestaurant);
                this.canViewMenuRestaurant = this.permissionsUserService.verifyPermissionsUser(this.viewMenuRestaurant);

                this.putClassActive();

                this.getAllRestaurants();

                this.userLang = navigator.language.split('-')[0];

                var inputAddress = document.getElementById("address");

                this.gMapLoader.load().then(() => {
                    // se consume el service _mapsService para traer la direccion
                    var complete;
                    this._autocompletarGoogleService.inAutocomplete(inputAddress).then(place => {
                        complete = place;
                    });
                });


                //Manejo botones paginación
                jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
                    if (e.relatedTarget) {
                        jQuery(e.relatedTarget).removeClass('active');
                    }
                });

                this.getValuesPagination();

                if (!isAdministrator()) {
                    this.isAdmin = false;
                }

                if (localStorage.getItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL)) {
                    this.filterLevel = localStorage.getItem(Constants.LOCAL_STORAGE_DATA.FILTER_LEVEL);
                    setTimeout(() => {
                        jQuery('#searchFilter').click();
                    }, 200);
                }
            } else {

                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 600);
    }

    /**
     * Metodo encargado de realizar la petición del listado completo de restaurantes
     * y de los items para realizar la paginación
     */
    getAllRestaurants() {
        return new Promise(resolve => {
            this.restaurantService
            .getAllRestaurants(this.items_per_page, this.cleanSearch)
            .subscribe(fullResponse => {
                console.log(fullResponse);
                if (fullResponse['data']) {
                    this.fullResponse = fullResponse;
                    this.getListRestaurants(this.fullResponse);
                    this.getPagination(this.fullResponse);
                    this.getPaginationBs(this.fullResponse);
                    resolve(this.fullResponse);
                }
            }, error => {
                this.error = error;
                console.info("ERROR: ", error);
            });
        })
        
            

    }

    /**
     * Metodo encargado de capturar el listado completo de restaurantes
     */
    getListRestaurants(res: any) {
        this.allRestaurants = res.data || {};
    }

    /**
     * Metodo encargado de capturar los items necesarios para realizar
     * la paginación
     */
    getPagination(res: any) {
        this.currentPage = res.pagination.currentPage;
        this.totalItems = res.pagination.totalItems;
        this.itemsInCurrentPage = res.pagination.itemsInCurrentPage;
        this.firstPage = res.pagination.firstPage;
        this.lastPage = res.pagination.lastPage;
        this.itemsPerPage = res.pagination.itemsPerPage;
        this.nextPage = res.pagination.nextPage;
        this.prevPage = res.pagination.prevPage;
        this.actualPage = res.pagination.currentPage;

        if (res.pagination.currentPage > 1) {
            this.currentPage = this.currentPage + (res.pagination.itemsPerPage * (this.currentPage - 1)) - (this.currentPage - 1);
            this.itemsPerPage = this.currentPage + (res.pagination.itemsPerPage - 1);
        }

        if (res.pagination.itemsPerPage >= res.pagination.totalItems) {
            this.itemsPerPage = this.totalItems;
        }

        if (res.pagination.currentPage == res.pagination.lastPage) {
            this.itemsPerPage = this.totalItems;
        }
    }

    getPaginationBs(res: any) {

        this.translate.get(["Next", "Previous", "lastPage", "firstPage"]).subscribe(translations => {
            this.nextPag = translations.Next;
            this.prevPag = translations.Previous;
            this.lastPag = translations.lastPage;
            this.firstPag = translations.firstPage;
        });

        let itemsPerPageInitial = this.itemsPerPageBs;
        this.maxSize = 5;
        this.bigTotalItems = res.pagination.totalItems;
        this.bigCurrentPage = res.pagination.currentPage;
        this.numPages = 0;
        this.itemsPerPageBs = res.pagination.itemsPerPage;
        if (itemsPerPageInitial != res.pagination.itemsPerPage) {
            this.getPage(1);
        }

    }

    /**
     * Metodo encargado de generar array para iterar en la vista para realizar la paginacion
     */
    createRange(numberBtn) {
        return this.utils.createRange(numberBtn);
    }

    /**
     * Metodo encargado de pedir al web service el menu principal de cada restaurante
     * @param restaurant: restaurant -> Restaurante que se va a consultar el menu principal
     */
    getMainMenu(restaurant: restaurant, callback) {
        this.menuesService
            .getMainMenu(restaurant.id)
            .then(menu => {
                this.menu = menu;
                if (typeof callback !== "undefined") {
                    callback();
                }
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de consultar el menu principal del restaurante seleccionado y posteriormente
     * redirigir al usuario a la sección de Menu
     * @param restaurant: restaurant -> Restaurante al cual se modificara el menu
     */
    gotoMenu(restaurant: restaurant) {
        this.getMainMenu(restaurant, () => {
            this.router.navigate([this.localize.translateRoute('/app/restaurant'), restaurant.id, 'menu', this.menu.id, 'categories']);
        });
    }

    gotoMenuProducts(restaurant: restaurant) {
        this.router.navigate(['MenuProductsComponent', { id: restaurant.id }]);
    }

    gotoEdit(restaurant: restaurant) {
        this.router.navigate([this.localize.translateRoute('/app/restaurant'), restaurant.id, 'edit']);
    }

    /**
     * Metodo encargado de la eliminacion de un restaurante, después que se haya confirmado
     * que se quiere eliminar
     */
    deleteRestaurant(restaurant: restaurant, event: any, template: TemplateRef<any>) {
        this.processingResponse = true;
        this.content = this.utils.defineContent(restaurant, this.userLang, 'Deleted');

        // jQuery('#deleteBtn').prop('disabled', true);
        event.stopPropagation();
        this.restaurantService
            .delete(restaurant, this.userLang)
            .subscribe(res => {
                if (!res['data']) {
                    this.title = 'Restaurant';
                    this.alertService.WarningAdd("Restaurant", "eliminationFailed");
                } else {
                    this.title = 'Restaurante';
                    this.alertService.successAdd("Restaurant", "deletedSuccessfully");
                    this.processingResponse = false;
                }
                //this.restaurant = this.restaurant.filter(h => h !== restaurant);
                this._service.success(this.title, this.content, { id: 123 });
                setTimeout(() => {
                    this.goBack(null, true);
                    jQuery('#deleteBtn').prop('disabled', false);
                }, 500);
            }, error => { this.error = error });
    }

    showDetails(idRestaurant: string) {
        this.router.navigate([this.localize.translateRoute('/app/restaurant'), idRestaurant, 'show']);
    }

    showReviews(idRestaurant: string) {
        console.warn('idRestaurant ==> ', idRestaurant);
        this.router.navigate([this.localize.translateRoute('/app/restaurant'), idRestaurant, 'reviews']);
    }

    /**
     * Metodo encargado de hacer paginación manejando el numero de items que se quieren ver en
     * el listado
     */
    doPagination(items: number) {

        /* this.items_per_page = items;
         this.cleanSearch = true;
         this.restaurantService
           .getAllRestaurants(this.items_per_page, this.cleanSearch)
           .then(fullResponse => {
             this.fullResponse = fullResponse;
             this.getListRestaurants(this.fullResponse);
             this.getPagination(this.fullResponse);
             this.getPaginationBs(this.fullResponse);
           })
           .catch(error => this.error = error);*/
        this.items_per_page = items;
        this.cleanSearch = true;
        this.restaurantService
            .getAllRestaurants(this.items_per_page, this.cleanSearch)
            .subscribe(fullResponse => {
                if (!fullResponse['errors']) {
                    this.fullResponse = fullResponse;
                    this.getListRestaurants(this.fullResponse);
                    this.getPagination(this.fullResponse);
                    this.getPaginationBs(this.fullResponse);
                }
            }, error => {
                this.error = error;
                console.info("ERROR: ", error);
            });

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage1').addClass('active');
    }

    /**
     * Metodo encargado de manejar la navegacion entre paginas del listado de restaurantes
     */
    getPage(page: number) {

        /*jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage' + page).addClass('active');*/

        this.restaurantService
            .getPageRestaurants(page, this.items_per_page)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getListRestaurants(this.fullResponse);
                this.getPagination(this.fullResponse);
                this.getPaginationBs(this.fullResponse);
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de abrir el modal para confirmar que se quiere eliminar el restaurante
     * seleccionado
     * @param restaurant: restaurant -> Restaurante que se quiere eliminar
     */

    public confirmDelete(restaurant: restaurant, modal) {
        this.modalRef = this.modalService.show(modal);
        this.selectedRestaurant = restaurant;
    }

    // confirmDelete(restaurant: restaurant, modal) {
    //   this.selectedRestaurant = restaurant;
    //   // this.modalService.open(modal);
    //   //jQuery('#canDeleteModal').modal('show');
    // }

    async goBack(restaurant: restaurant = null, refreshDataStorage: boolean = false) {
        this.close.emit(restaurant);
        if (refreshDataStorage) {
            const restaurants = await this.getAllRestaurants();
            this.restaurantService.setDataRestaurantList(restaurants);
        }
        this.modalRef.hide();
        // jQuery('#canDeleteModal').modal('hide');
    }

    putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }

    /**
     * Metodo encargada de definir si se puede mostrar el boton de ver detalles de un restaurante
     * @param isFromLevel: boolean -> Variable que define si el restaurante es de level o no
     */
    defineViewRestaurant(isFromLevel: boolean) {
        if (this.canViewLevelRestaurant) {
            return true;
        } else if (this.canViewDirectoryRestaurant) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Metodo encargada de definir si se puede mostrar el boton de ver detalles de un restaurante
     * @param isFromLevel: boolean -> Variable que define si el restaurante es de level o no
     */
    defineEditRestaurant(isFromLevel: boolean) {
        if (this.canEditLevelRestaurant) {
            return true;
        } else if (this.canEditDirectoryRestaurant) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Metodo encargado de crear el formulario para manejo de la busqueda
     */
    createForm() {
        this.searchForm = new FormGroup({
            level: new FormControl(''),
            freeText: new FormControl(''),
            license: new FormControl('')
        })
    }

    /**
     * Metodo encargado de realizar la busqueda de los restaurantes
     */
    doSearch(searchData: any, isValid: boolean, address: string = "") {
        if (address) {
            address = this.utils.cleanString(address);
        } else {
            address = "";
        }

        if (!searchData.freeText) {
            searchData.freeText = "";
        }

        if (!searchData.level) {
            searchData.level = "";
        }
        // if (!searchData.license) {
        //   searchData.license = "";
        // }

        searchData.searchAddress = address;

        this.restaurantService
            .getSearchRestaurants(searchData, address)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                setTimeout(() => {
                    this.getListRestaurants(this.fullResponse);
                    this.getPagination(this.fullResponse);
                    this.getPaginationBs(this.fullResponse);
                }, 500);
            })
            .catch(error => this.error = error);

    }

    getValuesPagination() {
        let values = this.restaurantService.valuesPagination();
        let page = values[0];
        let itemsPerPage = values[1];

        jQuery('.nav-pagination').children('li').children('a').removeClass('active');
        jQuery('#items' + itemsPerPage).addClass('active');

        setTimeout(() => {
            jQuery('.btnPage' + page).addClass('active');
        }, 2000);
    }

    pageChanged(event: any): void {
        //   console.log('Page changed to: ' + event.page);
        //   console.log('Number items per page: ' + event.itemsPerPage);
    }



    //Inicio ordenadores de columnas

    /**
     * @description Funcion encargada de pasar los parametros para ordenar un array simple de objetos
     * @param order -> (number) Tipo de ordenamiento 1: asc, 2: desc
     * @param key -> (string) Parametro del objeto a ordenar ej: 'name'
     */
    orderColumn1Object(order: number, key: string): void {



        if (this.orderAscOrDesc == order) {
            this.allRestaurants.sort(this.compareValues1Object(key, 'desc'));
            this.orderAscOrDesc = 2;
            this.orderIconObject = 'fa fa-chevron-down';
        }
        else {
            this.allRestaurants.sort(this.compareValues1Object(key, 'asc'));
            this.orderAscOrDesc = 1;
            this.orderIconObject = 'fa fa-chevron-up'
        }

    }

    /**
     * @description Funcion encargada de ordenar un array simple de objetos object->key
     * @param key -> (string) Parametro a tener en cuenta para ordenar
     * @param order -> (string) Ordenamiento asc o desc
     */
    compareValues1Object(key: string, order: string = "asc") {

        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };

    }

    /**
     * @description Funcion encargada de pasar los parametros para ordenar un array object->object->key
     * @param order -> (number) Tipo de ordenamiento 1: asc, 2: desc
     * @param key -> (string) Parametro del objeto a ordenar ej: 'name'
     * @param obj -> (string) Nombre del objeto a referir
     */
    orderColumn2Object(order: number, key: string, obj: string): void {

        if (this.orderAscOrDesc == order) {
            this.allRestaurants.sort(this.compareValues2Object(key, 'desc', obj));
            this.orderAscOrDesc = 2;
            this.orderIcon2Object = 'fa fa-chevron-down';
        }
        else {
            this.allRestaurants.sort(this.compareValues2Object(key, 'asc', obj));
            this.orderAscOrDesc = 1;
            this.orderIcon2Object = 'fa fa-chevron-up'
        }

    }

    /**
     * @description Funcion encargada de ordenar un array simple de objetos object->object->key
     * @param key -> (string) Parametro a tener en cuenta para ordenar
     * @param order -> (string) Ordenamiento asc o desc
     * @param obj -> (string) Nombre del objeto a referir
     */
    compareValues2Object(key: string, order: string = "asc", obj: string) {

        return function (a, b) {

            if (!a[obj].hasOwnProperty(key) || !b[obj].hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[obj][key] === 'string') ?
                a[obj][key].toUpperCase() : a[obj][key];
            const varB = (typeof b[obj][key] === 'string') ?
                b[obj][key].toUpperCase() : b[obj][key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };

    }


    /**
     * @description Funcion encargada de pasar los parametros para ordenar un array object->object->index->key
     * @param order -> (number) Tipo de ordenamiento 1: asc, 2: desc
     * @param key -> (string) Parametro del objeto a ordenar ej: 'name'
     * @param obj -> (string) Nombre del objeto a referir
     * @param index -> (string) Index donde se encuentra el parametro key
     */
    orderColumnObjectNumber(order: number, obj: string, index: number, key: string): void {

        if (this.orderAscOrDesc == order) {
            this.allRestaurants.sort(this.compareValuesObjectNumber(key, 'desc', obj, index));
            this.orderAscOrDesc = 2;
            this.orderIconObjectNumber = 'fa fa-chevron-down';
        }
        else {
            this.allRestaurants.sort(this.compareValuesObjectNumber(key, 'asc', obj, index));
            this.orderAscOrDesc = 1;
            this.orderIconObjectNumber = 'fa fa-chevron-up'
        }

    }

    /**
     * @description Funcion encargada de ordenar un array simple de objetos object->object->index->key
     * @param key -> (string) Parametro a tener en cuenta para ordenar
     * @param order -> (string) Ordenamiento asc o desc
     * @param obj -> (string) Nombre del objeto a referir
     * @param index -> (string) Index donde se encuentra el parametro key
     */
    compareValuesObjectNumber(key: string, order: string = "asc", obj: string, index: number) {

        return function (a, b) {
            if (!a[obj][index].hasOwnProperty(key) || !b[obj][index].hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[obj][index][key] === 'string') ?
                a[obj][index][key].toUpperCase() : a[obj][index][key];
            const varB = (typeof b[obj][index][key] === 'string') ?
                b[obj][index][key].toUpperCase() : b[obj][index][key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };

    }

    /**
   * @description Funcion encargada de pasar los parametros para ordenar un array simple de objetos boolean
   * @param order -> (number) Tipo de ordenamiento 1: asc, 2: desc
   * @param key -> (string) Parametro del objeto a ordenar ej: 'name'
   */
    orderColumn1ObjectBoolean(order: number, key: string): void {



        if (this.orderAscOrDesc == order) {
            this.allRestaurants.sort(this.compareValues1ObjectBoolean(key, 'desc'));
            this.orderAscOrDesc = 2;
            this.orderIconObjectBoolean = 'fa fa-chevron-down';
        }
        else {
            this.allRestaurants.sort(this.compareValues1ObjectBoolean(key, 'asc'));
            this.orderAscOrDesc = 1;
            this.orderIconObjectBoolean = 'fa fa-chevron-up'
        }

    }

    /**
     * @description Funcion encargada de ordenar un array simple de objetos object->key boolean
     * @param key -> (string) Parametro a tener en cuenta para ordenar
     * @param order -> (string) Ordenamiento asc o desc
     */
    compareValues1ObjectBoolean(key: string, order: string = "asc") {

        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            /*const varA = (typeof a[key] === 'boolean') ? 
              a[key].toString().toUpperCase() : a[key].toString().toUpperCase();
            const varB = (typeof b[key] === 'boolean') ? 
              b[key].toString().toUpperCase() : b[key].toString().toUpperCase();*/

            let comparison = 0;
            if (a[key]) {
                comparison = 1;
            } else {
                comparison = -1;
            }
            return (
                (order == 'desc') ? (comparison * -1) : comparison
            );
        };

    }

    //Fin ordenadores de columnas

    /**
     * Funcion encargada de activar restaurante en Level
     * @param item -> Informacion del restaurante
     */
    activateLevel(item: any) {
        this.levelsyncroLevel.levelActive(item, ActivateRestaurantLevel);
    }

    syncLevel(levelDatabaseNickname) {
        this.levelsyncroLevel.levelSync(levelDatabaseNickname);
    }

    /**
     * Metodo encargado de abrir el modal para preguntar  se desea agregar un acuenta
     *  @param item -> Informacion del restaurante
     */
    public confirmActivateLevel(item: any) {
        this.confirmActivateLev.show();
    }

    /**
     * Metodo encargado de cerar el modal que preguntar si se desea agregar un acuenta
     */
    public hideActivateLevel() {
        this.confirmActivateLev.hide();
    }

    /**
     * Funcion encargada de setear la variable de activar, desactivar o poner no disponible
     * un restaurante
     * @param item.status 0 para desactivado, 1 activado y 2 no disponible
     * @param isCheckbox flag para usar o no esta funcion en un checkbox, por defecto no
     * @version 1.0.1
     */
    public actiRest(item: any, isCheckbox: boolean = false) {
        const errorAux = item.status;
        if (isCheckbox) {
            if (item.status == 1) {
                item.status = 0;
            } else {
                item.status = 1;
            }
        }
        this.restaurantService
            .editStuatus(item.id, item.status)
            .subscribe(response => {
                if (response['errors']) {
                    console.error(response['errors']);
                    item.status = errorAux;
                } else {
                    // Succesfull
                }
            }, catchError => {
                console.error(catchError);
                item.status = errorAux;
            });
    }
}


