<div *ngIf="detRestaurante">
    <h1 class="page-title">{{detRestaurante.name}}<small> {{ 'DetailsRestaurant' | translate }}</small></h1>
</div>

<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
            </header>
            <div class="widget-body">
                <p class="text-xs-right">
                    <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                        <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                    </a>
                    <a *ngIf="detRestaurante" class="btn btn-secondary mb-xs" (click)="gotoEdit(detRestaurante)">
                        <i class="fa fa-pencil text-warning"></i> {{ 'Edit' | translate }}
                    </a>
                </p>

                <div *ngIf="detRestaurante">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table table-striped table-hover tablesorter">
                                    <thead>
                                        <th>
                                            {{ 'Created' | translate }}
                                        </th>
                                        <th>
                                            {{ 'Name' | translate }}
                                        </th>
                                        <th>
                                            {{ 'Description' | translate }}
                                        </th>
                                        <th>
                                            {{ 'Email' | translate }}
                                        </th>
                                        <th class="txtcntr">
                                            {{ 'Phone' | translate }}
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ detRestaurante.createdAt }}</td>

                                            <td><strong>{{ detRestaurante.name }}</strong></td>

                                            <td>{{ detRestaurante.description }}</td>

                                            <td *ngFor="let email of detRestaurante.emails">
                                                <div *ngIf="email.isMainEmail">
                                                    {{ email.email}}
                                                </div>
                                            </td>
                                            <td *ngIf="!detRestaurante.emails.length">
                                            </td>

                                            <td width="10%" *ngFor="let phone of detRestaurante.phones ">
                                                <div *ngIf="phone.isMainPhone">
                                                    {{ phone.number}}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <thead>
                                        <th>{{ 'Address' | translate }}</th>
                                        <th>{{ 'City' | translate }}</th>
                                        <th>{{ 'State' | translate }}</th>
                                        <th>{{ 'Country' | translate }}</th>
                                        <th class="txtcntr">{{ 'Zip Code' | translate }}</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ detRestaurante.address.street }}</td>
                                            <td>{{ detRestaurante.address.cityName }}</td>
                                            <td>{{ detRestaurante.address.stateName }}</td>
                                            <td>{{ detRestaurante.address.countryName }}</td>
                                            <td class="txtcntr">{{ detRestaurante.address.zipCode }}</td>
                                        </tr>

                                    </tbody>

                                    <thead>
                                        <th style="text-align:center" colspan="2">{{ 'Features' | translate }}</th>
                                        <th style="text-align:center" colspan="1">{{ 'Cuisines' | translate }}</th>
                                        <th style="text-align:center" colspan="2">{{ 'MealsServed' | translate }}</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="2" class="grandit">
                                                <div class="dadit" *ngFor="let features of detRestaurante.features">
                                                    <div class="childit" *ngFor="let feature of features.Feature.langs">
                                                        {{feature.name}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="1" class="grandit">
                                                <div class="dadit" *ngFor="let cuisines of detRestaurante.cuisines">
                                                    <div class="childit" *ngFor="let cuisine of cuisines.Cuisine.langs">
                                                        {{cuisine.name}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="2" class="grandit">
                                                <div class="dadit" *ngFor="let meals of detRestaurante.meals">
                                                    <div class="childit" *ngFor="let meal of meals.Meal.langs">
                                                        {{meal.name}}
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>

                                </table>

                                <table class="table table-striped table-hover tablesorter">
                                    <thead>
                                        <th colspan="2" style="text-align:center">{{ 'OpeningDates' | translate }}</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style="text-align:center" width="30%">
                                                {{ 'Season' | translate }}
                                            </th>
                                            <td class="cntrH">
                                                <strong>{{ 'Hours' | translate }}</strong>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of detRestaurante.openingDates">
                                            <th>
                                                <div class="cntrH">
                                                    {{ item.name }}
                                                </div>
                                                <div class="cntrH row">
                                                    <div class="col-md-6">
                                                        <small>{{ 'StartDate' | translate }} {{item.startDate | date:'MMM d, y' }}</small>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <small>{{ 'EndDate' | translate }} {{item.endDate | date:'MMM d, y' }}</small>
                                                    </div>
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <small><strong>{{ 'Restaurant' | translate }} : </strong></small>
                                                    <label *ngFor="let hour of item.hours">
                                                    <small *ngIf="hour.attentionType == 1">                                                        
                                                        <label>{{ hour.weekDayDescription }} : </label>
                                                        <label><strong *ngIf="hour.hourFrom"> ( {{ hour.hourFrom }} - </strong></label>
                                                        <label><strong *ngIf="hour.hourTo"> {{ hour.hourTo  }} )  </strong></label>
                                                        <label><strong *ngIf="hour.isOpenAllTime == true">( {{ 'IsOpenAllTime' | translate }} )  </strong></label>
                                                    </small>
                                                    </label>
                                                </div>
                                                <div>
                                                    <small><strong>{{ 'Delivery' | translate }} : </strong></small>
                                                    <label *ngFor="let hour of item.hours">
                                                    <small *ngIf="hour.attentionType == 2">                                                        
                                                        <label>{{ hour.weekDayDescription }} : </label>
                                                        <label><strong *ngIf="hour.hourFrom"> ( {{ hour.hourFrom }} - </strong></label>
                                                        <label><strong *ngIf="hour.hourTo"> {{ hour.hourTo }} ) </strong></label>
                                                        <label><strong *ngIf="hour.isOpenAllTime == true">( {{ 'IsOpenAllTime' | translate }} )   </strong></label>
                                                    </small>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-xs-right">
                    <button class="btn btn-secondary mb-xs" type="button" (click)="canShowMap()">
                        <i class="fa fa-map-marker text-info"></i> {{ 'ShowMap' | translate }}
                    </button>
                </p>
                <!-- Google Maps -->
                <div *ngIf="activeMap && showMap">
                    <table class="table table-striped table-hover tablesorter">
                        <thead>
                            <th colspan="2" style="text-align:center">{{ 'Localization' | translate }}</th>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                    <!-- <sebm-google-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                        <sebm-google-map-marker [latitude]="lat" [longitude]="lng"></sebm-google-map-marker>
                    </sebm-google-map> -->
                    <agm-map [latitude]="lat" [longitude]="lng">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map>
                </div>
            </div>
        </section>
    </div>
</div>
<!-- <inspectPermits></inspectPermits> -->