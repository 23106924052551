import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-image',
    templateUrl: 'loading-image.html',
    styleUrls: ['./loading-image.css']
})
export class LoadingImageComponent {

    /**
     * Mensaje para mostrar mientras se ve el cargador
     */
    @Input()
    public message: string;

    /**
     * Clase a aplicar al cargador
     */
    @Input()
    public classCSS: string;

    constructor() { }
}
