import { Component, OnInit } from '@angular/core';
/**
 * Variable para manejo de funciones Jquery
 */
declare var jQuery: any;

@Component({
  selector: 'app-coupons-restaurant',
  templateUrl: './coupons-restaurant.component.html',
  styleUrls: ['./coupons-restaurant.component.sass']
})


export class CouponsRestaurantComponent implements OnInit {
  /**
   * Indica si se va a mostrar todas las ordenes de todos los restaurantes
   */
  public allCoupons: boolean;
  constructor() { }

  ngOnInit() {
    // Verifica si la url existe /all
    this.allCoupons = document.location.href.includes("all");
    if (this.allCoupons) {
      this.__putClassActive();
    }
  }

  /**
   * Metodo encargado de poner clase activa en el sidebar para identificar
   * que se esta trabajando dentro de la sección de ordenes generales
   */
  private __putClassActive() {
    setTimeout(() => {
      jQuery('.coupons-sidebar').addClass('active');
    }, 100);
  }

}
