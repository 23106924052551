import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsComponent } from '../utils/utils';

@Injectable()
export class RestaurantReviewService {
     
    constructor( public httpClient: HttpClient,
        private utils: UtilsComponent
    ) { }

    // Variable que almacena la url de los webservices
    private urlOmt = this.utils.getUrlOmt();

    /**
     * Permite listar los reviews por restaurante
     * @param idRestaurant 
     */
    getAll(idRestaurant: string,allReviews:boolean,paramsQuery:string) {
        let url;
        if (allReviews) {
            url = this.urlOmt + 'sd/reviews'+ paramsQuery;
          } else {
            url = this.urlOmt + 'sd/reviews-restaurant/' + idRestaurant + paramsQuery;
        }
        return this.httpClient.get(url);
    }
    /**
     * Permite eliminar un comentario
     * @param idReview  id del comentario
     * @param idRestaurant id del restaurante
     */
    delete(idReview:string, idRestaurant:string){
        const url = this.urlOmt + 'sd/restaurant/' + idRestaurant+'/review/'+idReview;
        return this.httpClient.delete(url);
    }

    /**
     * Cambia el estatus del comentario
     * @param requestData 
     */
    changeStatus(requestData){
        const url = this.urlOmt + 'sd/review-restaurant';
        return this.httpClient.put(url,requestData);
    }
}
