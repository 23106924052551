<div *ngIf="restaurants">
    <h1 class="page-title"> {{restaurants.name}} <small>{{ 'MenuCategories' | translate }}</small>
    </h1>
</div>

<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    {{ 'ListItems' | translate }}
                </h4>
            </header>
            <div class="widget-body">

                <div class="form-group row">
                    <div class="col-md-4 col-xs-12">
                        <div class="input-group">
                            <span class="info input-group-addon"><i class="fa fa-search"></i></span>
                            <input type="text" class="form-control form-control-sm pull-xs-right" id="search" placeholder="{{ 'Search' | translate }}"
                                [(ngModel)]="searchText">
                        </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                        <p *ngIf="restaurants" class="text-xs-right">
                            <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                                <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                            </a>
                            <a class="btn btn-success mb-xs" (click)="addEditCategory(null)">
                                <i class="fa fa-plus"></i> {{ 'Add' | translate }}
                            </a>
                        </p>
                    </div>
                </div>


                <div class="form-group">
                    <div class="table-responsive">
                        <table *ngIf="restaurants" class="table">
                            <thead>
                                <tr>
                                    <th>{{ 'Category' | translate }}</th>
                                    <th class="txtcntr">{{ 'Products' | translate }}</th>
                                    <th>{{ 'Actions' | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of categories | SearchPipe : searchText">
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td class="txtcntr">
                                        <div *ngFor="let category of menuCategories">
                                            <strong *ngIf="category.id == item.id">{{category.products.length}}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <p class="text-xs-left">
                                            <button *ngIf="canViewMenu" class="btn btn-secondary mb-xs" type="button" title="{{ 'ShowDetails' | translate }}" (click)="showCategory(item, showCategoryModal)">                                                    
                                                <i class="fa fa-eye text-info"></i>{{ 'ShowDetails' | translate }}</button>
                                            <button *ngIf="canEditMenu" (click)="gotoMenuProducts(item)" class="btn btn-secondary mb-xs" role="button" title="{{ 'Products' | translate }}">
                                                <i class="fa fa-coffee text-success"></i> {{ 'Products' | translate }}
                                            </button>
                                            <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Edit' | translate }}" (click)="addEditCategory(item)">
                                                    <i class="fa fa-pencil-square-o text-info"></i> {{ 'Edit' | translate }}
                                                </button>
                                            <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                                (click)="confirmDelete(item, canDeleteModal)">
                                                    <i class="fa fa-eraser text-danger"></i> {{ 'Delete' | translate }}
                                                </button>
                                        </p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Modal para agregar/editar categoria -->

            <div bsModal #addEditCategoryModal="bs-modal" [config]="{ignoreBackdropClick: true, keyboard: false}"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-mm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 *ngIf="!canEdit" class="modal-title text-xs-center fw-bold mt">{{ 'AddNewCategory' | translate }}</h4>
                            <h4 *ngIf="canEdit" class="modal-title text-xs-center fw-bold mt">{{ 'EditCategory' | translate }}</h4>
                            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div class="modal-body">

                            <form *ngIf="!isResetting" class="form-horizontal" [formGroup]="categoryForm" novalidate>

                                <div [formGroup]="categoryForm" *ngIf="selectedCategory">
                                    <div class="col-md-12">
                                        <input type="hidden" id="id" [(ngModel)]="selectedCategory.id" formControlName="id">

                                        <div class="form-group row">
                                            <label class="col-sm-3 control-label">{{ 'Name' | translate }}</label>
                                            <div class="col-sm-9">
                                                <input formControlName="name" type="text" class="form-control" placeholder="{{ 'Name' | translate }}" required="required"
                                                    [(ngModel)]="selectedCategory.name">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-4 control-label">{{ 'Sizes' | translate }}</label>
                                            <button type="button" class="btn btn-success pull-xs-right mb-xs" (click)="addArrayLiSize(arrayLiSizes)"><i class="fa fa-plus"></i></button>
                                        </div>

                                        <div class="row">
                                            <div *ngIf="errorSize">                                                
                                                <alert type="danger" [dismissible]="dismissible">{{ errorMessage}}</alert>
                                            </div>
                                            <ul id="divSizesNew">
                                                <li *ngFor="let item of arrayLiSizes; let i = index" id="liSizeNew{{i+1}}">

                                                    <div class="row" formGroupName="size">
                                                        <label class="col-sm-3">{{ 'Size' | translate }}:</label>
                                                        <div class="col-md-7">
                                                            <input value="" id="inputSizeNew{{i+1}}" class="form-control" placeholder="{{ 'Size' | translate }}">
                                                        </div>
                                                        <div class="col-md-2 pull-right">
                                                            <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}" (click)="removeItemSizeNew(item)"><i class="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div *ngIf="canEdit && selectedCategory.sizes" class="row">
                                            <ul id="divSizes" formGroupName="size">
                                                <li *ngFor="let ctrl of keysCategory(selectedCategory.sizes); let i = index" formGroupName="size" id="liSize{{i+1}}" #liSize>

                                                    <div class="row">
                                                        <label class="col-sm-3">{{ 'Size' | translate }}:</label>
                                                        <div class="col-md-7">
                                                            <input #inputSize id="inputSize{{i+1}}" class="form-control" placeholder="{{ctrl}}" value="{{ctrl}}">
                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}" (click)="removeItemSize(inputSize.id, liSize.id)"><i class="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>


                                <!-- <div *ngIf="selectedCategory">
                                    <div class="col-md-12">

                                        <input type="hidden" id="id" [(ngModel)]="selectedCategory.id" formControlName="id">

                                        <div class="form-group row">
                                            <label class="col-sm-3 control-label">{{ 'Name' | translate }}</label>
                                            <div class="col-sm-9">
                                                <input #name formControlName="name" type="text" class="form-control" placeholder="{{ 'Name' | translate }}" required="required"
                                                    [(ngModel)]="selectedCategory.name">
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-4 control-label">{{ 'Sizes' | translate }}</label>
                                            <button type="button" class="btn btn-success pull-xs-right mb-xs" (click)="addArrayLiSize(arrayLiSizes)"><i class="fa fa-plus"></i></button>
                                        </div>

                                        <div class="row">
                                            <ul id="divSizesNew" ngModelGroup="size">
                                                <li *ngFor="let item of arrayLiSizes; let i = index" ngModelGroup="size" id="liSizeNew{{i+1}}" #liSizeNew>

                                                    <div class="row">
                                                        <label class="col-sm-3">{{ 'Size' | translate }}:</label>
                                                        <div class="col-md-7"><input value="" #inputSizeNew id="inputSizeNew{{i+1}}" class="form-control"
                                                                formControlName="nameSize" placeholder="{{ 'Size' | translate }}"></div>
                                                        <div class="col-md-2 pull-right"><button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                                                (click)="removeItemSizeNew(item)"><i class="fa fa-minus"></i></button></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div *ngIf="canEdit && selectedCategory.sizes" class="row">
                                            <ul id="divSizes" ngModelGroup="size">
                                                <li *ngFor="let ctrl of keysCategory(selectedCategory.sizes); let i = index" ngModelGroup="size" id="liSize{{i+1}}" #liSize>

                                                    <div class="row">
                                                        <label class="col-sm-3">{{ 'Size' | translate }}:</label>
                                                        <div class="col-md-7">
                                                            <input #inputSize id="inputSize{{i+1}}" class="form-control" formControlName="nameSize" placeholder="{{ctrl}}" value="{{ctrl}}">
                                                        </div>
                                                        <div class="col-md-2">
                                                            <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}" (click)="removeItemSize(inputSize.id, liSize.id)"><i class="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->

                            </form>
                        </div>
                        <div class="modal-footer">
                            <p class="text-xs-right">
                                <button id="saveCategoryBtn" type="submit" [disabled]="!categoryForm.valid" class="btn btn-success md-xs" (click)="onSubmitCategory(categoryForm.value, categoryForm.valid)">{{ 'Save' | translate }}</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal para mostrar los detalles de una categoria -->
            <ng-template #showCategoryModal>
                <div class="modal-header">
                    <h4 class="modal-title text-xs-center fw-bold mt">{{ 'Category' | translate }}</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="selectedCategory">
                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table table-striped table-hover tablesorter">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th colspan="2">{{ 'Name' | translate }}</th>
                                            <td colspan="2">{{ selectedCategory.name }}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="2">{{ 'Sizes' | translate }}</th>
                                            <td colspan="2" *ngIf="selectedCategory.sizes">
                                                <div *ngFor="let item of objToArray(selectedCategory.sizes)">
                                                    {{ item }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- Modal para confirmar acción eliminar -->
            <ng-template #canDeleteModal>
                <div class="modal-header">
                    <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18"><i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                <div class="modal-body">
                    <p class="text-xs-center">
                        {{ 'WantDelete' | translate }}
                        <strong>{{ 'Category' | translate }}</strong>?
                    </p>
                </div>
                <div class="modal-footer">
                    <div *ngIf="selectedCategory">
                        <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">{{ 'Cancel' | translate }}</button>
                        <button id="deleteCategoryBtn" type="button" class="btn btn-success" (click)="deleteCategory(selectedCategory, $event)">{{ 'Confirm' | translate }}</button>
                    </div>
                </div>
            </ng-template>

            <!-- Notificaciones-->
            <!-- <simple-notifications [options]="options"></simple-notifications> -->
        </section>
    </div>
</div>
<!-- <inspectPermits></inspectPermits> -->