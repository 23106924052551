import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchPipe'
})

export class SearchPipe implements PipeTransform {

    transform(value, args?): Array<any> {
        let searchText = new RegExp(args, 'ig');
        if (value) {
            return value.filter(item => {
                if (item.name) {
                    return item.name.search(searchText) !== -1;
                }
                if (item.phone) {
                    return item.phone.search(searchText) !== -1;
                }
            });
        }
    }
}
