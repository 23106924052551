import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'distances'
})

/*
 * Pasar metros a kilometros(km) o millas(mi)
 * Toma el valor enviado y devuelve la distancia transformada.
 * Example:
 *   {{ 1000 | distance:'km' }}
 *   distance to: 1
*/
@Pipe({ name: 'distances' })
export class DistancePipe implements PipeTransform {


    constructor(private datePipe: DecimalPipe) { }  

    abbrNum(numbers, decPlaces) {
        var orig = numbers;
        var dec = decPlaces;
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);

        // Enumerate number abbreviations
        var abbrev = ["k", "m", "b", "t"];

        // Go through the array backwards, so we do the largest first
        for (var i = abbrev.length - 1; i >= 0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10, (i + 1) * 3);

            // If the number is bigger or equal do the abbreviation
            if (size <= numbers) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                numbers = Math.round(numbers * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((numbers == 1000) && (i < abbrev.length - 1)) {
                    numbers = 1;
                    i++;
                }

                // console.log(number);
                // Add the letter for the abbreviation
                numbers += abbrev[i];

                // We are done... stop
                break;
            }
        }
        return numbers;
    }

    ceilDecimal(value: number) {
        var format = +this.datePipe.transform(value, '1.0-1');
        var normal = value;
        if (format < 0.1) {
            return +this.datePipe.transform(normal, '1.0-2');
        }
        return format;
    }


    transform(value: number, ret: string): string {


        let result = value * 0.000621371;
        // console.warn('transforms result', result);
        // console.warn('transforms', ret);
        

        if (ret === 'mi') {
            result = ((result / 1000) / 1.609);
        } else if (ret === 'km') {
            result = (result / 1000);
            // console.warn('result KM ==>', result);
        }

        if (result >= 1000) {
            // console.warn('result MI ==>', result);
            result = this.abbrNum(result, 1);
        } else {
            result = this.ceilDecimal(result);
        }

        return String(result);
    }
}

