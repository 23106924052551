<div class="bglog container-login">
  <div class="container">
    <main id="content" class="widget-login-container" role="main">
      <div class="row justify-content-md-center bkgLogin">
        <div class="col-xl-4 col-md-6 col-xs-10 col-xl-offset-4 col-md-offset-3 col-xs-offset-1">
          <h5 class="widget-login-logo animated fadeInUp">
            <span class="thumb-xlg">
          <img src="assets/images/logo/openmytabWhite.svg" class="img-responsive" alt="logo">
          </span>
          </h5>
          <section class="widget widget-login animated fadeInUp">
            <header>
              <h3>{{ 'SignUp' | translate }}</h3>
            </header>
            <div class="widget-body">
              <form class="login-form mt-lg" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit(loginForm.value, loginForm.valid)">
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-user"></i></span>
                    <input (keypress)="loaderFalse()" type="text" class="form-control" id="email" placeholder="Email or Username" formControlName="username"
                      [(ngModel)]="username">
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                    <input (keypress)="loaderFalse()" class="form-control" id="pswd" type="password" placeholder="Password" formControlName="password"
                      [(ngModel)]="password">
                  </div>
                </div>
                <div class="clearfix">
                  <div class="btn-toolbar justify-content-md-end m-t-1">
                    <!--<a class="btn btn-inverse btn-sm" [routerLink]="['/App', 'RestaurantComponent'] ">Login</a>-->
                    <button type="submit" [disabled]="!loginForm.valid" class="btn btn-inverse btn-sm">Login</button>
                  </div>
                </div>
                <div *ngIf="isError" class="clearfix">
                  <div class="alert alert-danger alert-dismissable nlblk">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>{{ messageError}}
                  </div>
                </div>
               
                <div class="row m-t-1">
                  <div class="col-md-12 flex">
                    <a href="#">{{ 'ForgotYourPassword' | translate }}</a>
                     <div class="flex-check widget-login-info pull-xs-right">
                        <input type="checkbox" id="checkbox1" value="1">
                        <label for="checkbox1">{{ 'KeepMeSignedIn' | translate }} </label>
                      </div>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="isActive">
              <div class="loader animated fadeIn handle">
                <span class="spinner">
                            <i class="fa fa-spinner fa-spin"></i>
                        </span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
    <footer class="page-footer">
      2016 &copy; {{ 'DevelopedBy' | translate }} <a href="https://uva3.com" target="_blank" style="color: #f90"> STUDIO UVA 3</a>
    </footer>
  </div>
</div>