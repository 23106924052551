<div class="modal-header">
    <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
        {{ 'INFORMATION' | translate }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-xs-12">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ 'Status' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row" class="pending">{{ "Pending" | translate }}</th>
                        <td>{{'DESCRIPTION_STATUS_PENDING' | translate }} <em class="fa fa-paper-plane"></em></td>
                    </tr>
                    <tr>
                        <th scope="row" class="being-prepared">{{ "RECEIVED" | translate }}</th>
                        <td>{{'DESCRIPTION_STATUS_RECEIVED' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="prepared">{{ "BEING_PREPARED" | translate }}</th>
                        <td>{{ 'DESCRIPTION_STATUS_PREPARED' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="delivered">{{ "DELIVERED" | translate }}</th>
                        <td>{{ 'DESCRIPTION_STATUS_DELIVERED' | translate }}</td>
                    </tr>
                </tbody>
            </table>

            <hr>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ 'Type' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "PICKUP" | translate }}</th>
                        <td>{{ 'DESCRIPTION_ORDER_TYPE_PICKUP' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "DELIVERY" | translate }}</th>
                        <td>{{ 'DESCRIPTION_ORDER_TYPE_DELIVERY' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "DINE_IN" | translate }}</th>
                        <td>{{ 'DESCRIPTION_ORDER_TYPE_DINE_IN' | translate }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ 'Actions' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            <button class="btn btn-secondary  mb-xs">
                <em class="fa fa-eye text-info"></em>
              </button>
                        </th>
                        <td>{{ 'DESCRIPTION_ACTION_DETAIL' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <button class="btn btn-info  mb-xs">
                <em class="fa fa-paper-plane"></em>
              </button>
                        </th>
                        <td>{{ 'DESCRIPTION_ACTION_RETRY' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>