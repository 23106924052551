import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { UtilsComponent } from '../utils/utils';

@Injectable()
export class DetailsRestaurantService {

    constructor(
        private httpc: HttpClient,
        private utils: UtilsComponent
    ) { }

    private cuisinesUrl = this.utils.getUrlOmt() + 'cuisines';
    private mealsUrl = this.utils.getUrlOmt() + 'meals';
    private featuresUrl = this.utils.getUrlOmt() + 'features';
    private omtUrl = this.utils.getUrlOmtSd();


    /**
     * Metodo encargado de capturar todas las cocinas registradas
     * en el webservice OMT
     */
    getAllCuisines(userLang: string) {
        const params = new HttpParams().set('All', '1');
        const url = `${this.cuisinesUrl}`;
        return this.httpc.get(url, { params });
    }

    /**
     * Metodo encargado de capturar todas las comidas registradas
     * en el webservice OMT
     */
    getAllMeals(userLang: string) {
        const params = new HttpParams().set('All', '1');
        return this.httpc.get(this.mealsUrl, { params });
    }

    /**
     * Metodo encargado de capturar todas las características registradas
     * en el webservice OMT
     */
    getAllFeatures(userLang: string) {
        const params = new HttpParams().set('All', '1');
        return this.httpc.get(this.featuresUrl, { params });
    }

    /**
     * Metodo encargado de extraer el contenido de las consultas
     * realizadas a los diferentes webservices
     */
    private extractData(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    /**
     * Metodo encargado del manejo de errores que se pueden presentar
     * en las consultas a los webservices
     */
    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    /**
     * Metodo encargado de redireccionar la petición para realizar
     * la adición de una cocina al restaurante
     */
    saveCuisine(idCousine: number, idRestaurant: number): any {
        return this.postCuisine(idCousine, idRestaurant);
    }

    /**
     * Metodo para agregar una nueva cocina a un restaurante
     */
    private postCuisine(idCousine: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/cuisine`;
        let arrayData = {
            cuisine_id: idCousine
        }
        let array = JSON.stringify(arrayData);
        return this.httpc.post(url, array);
    }

    /**
     * Metodo encargado de eliminar una cocina de un restaurante
     * recibe como parametros el id de la cocina y el id del restaurante
     */
    deleteCuisine(idCuisine: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/cuisine/${idCuisine}`;
        return this.httpc.delete(url);
    }

    /**
     * Metodo encargado de redireccionar la petición para realizar
     * la adición de una comida al restaurante
     */
    saveMeals(idMeals: number, idRestaurant: number): any {
        return this.postMeals(idMeals, idRestaurant);
    }

    /**
     * Metodo para agregar una nueva cocina a un restaurante
     */
    private postMeals(idMeals: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/meal`;
        let arrayData = {
            meal_id: idMeals
        }
        let array = JSON.stringify(arrayData);
        return this.httpc.post(url, array);
    }

    /**
     * Metodo encargado de eliminar una comida de un restaurante
     * recibe como parametros el id de la comida y el id del restaurante
     */
    deleteMeals(idMeal: any, idRestaurant: any) {

        let url = `${this.omtUrl}/${idRestaurant}/meal/${idMeal}`;
        return this.httpc.delete(url);
    }

    /**
     * Metodo encargado de redireccionar la petición para realizar
     * la adición de una característica al restaurante
     */
    saveFeature(idFeature: number, idRestaurant: number): any {
        return this.postFeature(idFeature, idRestaurant);
    }

    /**
     * Metodo para agregar una nueva característica a un restaurante
     */
    private postFeature(idFeature: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/feature`;
        let arrayData = {
            feature_id: idFeature
        }
        let array = JSON.stringify(arrayData);
        return this.httpc.post(url, array);
    }

    /**
     * Metodo encargado de eliminar una característica de un restaurante
     * recibe como parametros el id de la característica y el id del restaurante
     */
    deleteFeature(idFeature: number, idRestaurant: number) {

        let url = `${this.omtUrl}/${idRestaurant}/feature/${idFeature}`;
        return this.httpc.delete(url);
    }

}
