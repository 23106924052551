import { Constants } from 'app/app.constants';
import { Component, OnInit } from '@angular/core';

/**
 * Variable para manejo de funciones Jquery
 */
declare var jQuery: any;
@Component({
    selector: 'app-orders-restaurant',
    templateUrl: './orders-restaurant.component.html',
    styleUrls: ['./orders-restaurant.component.sass']
})
export class OrdersRestaurantComponent implements OnInit {
    /**
     * Constants status order
     */
    public statusConstants: { CANCELED: number; PENDING_ALSO: number; PENDING: number; RECEIVED: number; BEING_PREPARED: number; PREPARED: number; DELIVERY_ON_SITE: number; SEND_TO_DELIVERY: number; SEND_TO_CUSTOMER: number; ARRIVES_AT_DESTINATION: number; DELIVERED: number; };
    /**
     * Indica si se va a mostrar todas las ordenes de todos los restaurantes
     */
    public allOrders: boolean;

    constructor() {
        this.statusConstants = Constants.CONSTANTS_STATE;
    }

    ngOnInit() {
        // Verifica si la url existe /all
        this.allOrders = document.location.href.includes("all");
        if (this.allOrders) {
            this.__putClassActive();
        }
    }

    /**
     * Metodo encargado de poner clase activa en el sidebar para identificar
     * que se esta trabajando dentro de la sección de ordenes generales
     */
    private __putClassActive() {
        setTimeout(() => {
            jQuery('.orders-sidebar').addClass('active');
        }, 100);
    }
}
