import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { UtilsComponent } from '../utils/utils';

@Injectable()
export class ListAccountService {
    constructor(
        private httpc: HttpClient,
        private utils: UtilsComponent
    ) { }


    private accountUrl = this.utils.getUrlOmt() + 'sd/account';

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    /**
     * Metodo encargado de pedir al web service el listado completo de cuentas
     * Este enviara la respuesta completa que recibe del web service para su posterior manejo 
     * en el listado     
     */
    getAllAccount() {
        let url = `${this.accountUrl}`;
        return this.httpc.get(url);
    }


    /**
     * Metodo encargado de pedir al web service el listado completo de cuentas por parametros enviados por el usuario
     * Este enviara la respuesta completa que recibe del web service para su posterior manejo 
     * en el listado     
     */
    getAllAccountParam(parant) {

        let params = new HttpParams().set('param', parant);

        // let url = `${this.accountUrl}?param=`, {params:params};
        return this.httpc.get(this.accountUrl, { params: params });
        // return this.httpc.get(url);
    }


}
