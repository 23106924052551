<h1 class="page-title">{{ 'Restaurants' | translate }} <small></small></h1>

<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    {{ 'Filters' | translate }}
                </h4>
            </header>
            <div class="widget-body">
                <div class="row">
                    <div class="col-md-12">

                        <form [formGroup]="searchForm" novalidate (ngSubmit)="doSearch(searchForm.value, searchForm.valid, addressInput.value)">
                            <div class="form-group row">
                                <div class="col-sm-3 col-xs-12">
                                    <select [(ngModel)]="filterLevel" id="selectLevel" name="fromLevel" formControlName="level" class="form-control select2">
                    <option id="1" value="" disabled> {{ 'FromLevel' | translate }} </option>
                    <option id="2" value="true"> {{ 'Yes' | translate }} </option>
                    <option id="3" value="false"> {{ 'No' | translate }} </option>
                  </select>
                                </div>
                                <div class="col-sm-3 col-xs-12">
                                    <div class="input-group">
                                        <input class="form-control" #addressInput id="address" type="text" placeholder="{{ 'CityState' | translate }}">
                                    </div>
                                </div>
                                <!-- <div class="col-sm-3 col-xs-12">
                  <div class="input-group">
                    <input formControlName="license" type="text" #licenseInput class="form-control" id="licenseSearch"
                      placeholder="{{ 'LICENSE' | translate }}">
                  </div>
                </div> -->
                                <div class="col-sm-3 col-xs-12">
                                    <div class="input-group">
                                        <input formControlName="freeText" type="text" #searchInput class="form-control" id="inputSearch" placeholder="{{ 'RestaurantToSearch' | translate }}">
                                    </div>
                                </div>
                                <div class="col-sm-3 col-xs-12">
                                    <button type="submit" id="searchFilter" class="btn btn-info">{{ 'Search' | translate }}</button>
                                </div>

                            </div>
                        </form>
                        <br>
                    </div>
                </div>

            </div>
        </section>
    </div>
</div>


<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    {{ 'ListRestaurants' | translate }}
                </h4>
            </header>
            <div class="widget-body">
                <p class="text-xs-right">
                    <a *ngIf="canCreateRestaurant" class="btn btn-success" [routerLink]="['new']">
                        <i class="fa fa-plus"></i> {{ 'AddRestaurant' | translate }}
                    </a>
                </p>

                <div class="form-group row">
                    <div class="col-md-8 col-xs-12">
                        <p>{{ 'Showing' | translate }} {{currentPage}} {{ 'To' | translate }} {{itemsPerPage}} {{ 'Of' | translate }} {{totalItems}}
                        </p>
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a (click)="doPagination(25)" class="nav-link active" id="items10" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">25</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="doPagination(50)" class="nav-link" id="items20" data-toggle="tab" role="tab" aria-controls="details" aria-expanded="false">50</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="doPagination(100)" class="nav-link" id="items50" data-toggle="tab" role="tab" aria-controls="image" aria-expanded="false">100</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="form-group">
                    <div *ngIf="allRestaurants">
                        <div *ngIf="allRestaurants.length <=0">
                            <div class="alert alert-info alert-dismissable">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                <strong>{{'NoDataToShow' | translate }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped {{actiClase}}" id="table-restaurant">
                            <thead>
                                <tr>
                                    <th><a style="cursor: pointer;" (click)="orderColumn1Object(1, 'name')">{{ 'ESTATUS' | translate }}
                      <i class="{{orderIconObject}}"></i></a></th>
                                    <th><a style="cursor: pointer;" (click)="orderColumn1Object(1, 'name')">{{ 'Name' | translate }} <i
                        class="{{orderIconObject}}"></i></a></th>
                                    <th><a style="cursor: pointer;" (click)="orderColumn2Object(1, 'naturalAddress', 'address')">{{
                      'Address' | translate }} <i class="{{orderIcon2Object}}"></i></a></th>
                                    <th class="hidden-xs-down txtcntr"><a style="cursor: pointer;" (click)="orderColumnObjectNumber(1, 'phones', 0,'number')">{{
                      'Phone' | translate }} <i class="{{orderIconObjectNumber}}"></i></a></th>
                                    <th class="hidden-xs-down txtcntr"><a style="cursor: pointer;" (click)="orderColumn1ObjectBoolean(1, 'isFromLevel')">Level
                      <i class="{{orderIconObjectBoolean}}"></i></a></th>
                                    <th class="hidden-xs-down txtcntr"><a style="cursor: pointer;" (click)="orderColumn1Object(1, 'levelDatabaseNickname')">
                      {{'LICENSE' | translate}}
                      <i class="{{orderIconObjectBoolean}}"></i></a></th>
                                    <th class="txtcntr">{{ 'Actions' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allRestaurants; let i = index">
                                    <td>
                                        <div class="slideThree">
                                            <input class="inputStatus" type="checkbox" value="{{item.status}}" id="slideThree_{{item.id}}" name="check" [checked]="item.status" (click)="actiRest(item, true)" />
                                            <label for="slideThree_{{item.id}}"></label>
                                        </div>
                                        <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                      <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 0}" (click)="actiRest(0)">
                        <input type="radio" name="radiogroup" id="option1" [value]="0" [(ngModel)]="detailRestaurant.status">
                        {{ 'Inactive' | translate }}
                      </label>
                      <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 2}" (click)="actiRest(2)">
                        <input type="radio" name="radiogroup" id="option2" [value]="2" [(ngModel)]="detailRestaurant.status">
                        {{ 'NOT_AVAILABLE' | translate }}
                      </label>
                      <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 1}" (click)="actiRest(1)">
                        <input type="radio" name="radiogroup" id="option3" [value]="1" [(ngModel)]="detailRestaurant.status">
                        {{ 'Active' | translate }}
                      </label>
                    </div> -->
                                    </td>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td class="address">
                                        <label>
                                        <span *ngIf="item.address.street">{{ item.address.street }}</span>
                                        <span *ngIf="item.address.suitNumber">{{ item.address.suitNumber }}</span>
                                        <span *ngIf="item.address.cityName">{{ item.address.cityName }}</span>
                                        <span *ngIf="item.address.stateName">{{ item.address.stateName }}</span>
                                        <span *ngIf="item.address.postal_code">{{ item.address.postal_code }}</span>
                                        <span *ngIf="item.address.countryName">{{ item.address.countryName }}</span>
                                        </label>
                                    </td>
                                    <td class="hidden-xs-down txtcntr">
                                        <ng-container *ngFor="let phone of item.phones">
                                            {{ phone.number }}
                                        </ng-container>
                                    </td>
                                    <td class="hidden-xs-down text-semi-muted txtcntr" id="rsinc-{{item.id}}">
                                        <label *ngIf="item.isFromLevel == null || item.isFromLevel == 0 || item.isFromLevel == 'false'">
                      {{ 'notSynchronized' | translate }}</label>
                                        <label *ngIf="item.isFromLevel == 1">{{ 'processing' | translate }}</label>
                                        <label *ngIf="item.isFromLevel == 2">{{ 'synchronized' | translate }}</label>
                                    </td>
                                    <td class="hidden-xs-down txtcntr">
                                        <ng-container *ngIf="item.isFromLevel == 2">{{ item.levelDatabaseNickname }}</ng-container>
                                    </td>
                                    <td class="hidden-xs-down txtcntr">
                                        <ng-container *ngIf="item.isFromLevel != 2">
                                            {{ "NOT_CREATED" | translate }}
                                        </ng-container>
                                    </td>
                                    <td class="width-150 actions" id="ractions-{{item.id}}">
                                        <div class="btn-group">
                                            <button *ngIf="defineViewRestaurant(item.isFromLevel)" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="showDetails(item.id)">
                        <i class="fa fa-eye text-info ktl-{{ i + 1 }}"></i>
                      </button>
                                            <button *ngIf="defineEditRestaurant(item.isFromLevel)" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Edit' | translate }}" (click)="gotoEdit(item)">
                        <i class="fa fa-pencil text-warning ktl-{{ i + 1 }}"></i>
                      </button>
                                            <button *ngIf="defineEditRestaurant(item.isFromLevel)" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Reviews' | translate }}" (click)="showReviews(item.id)">
                        <i class="fa fa-comment text-warning ktl-{{ i + 1 }}"></i>
                      </button>
                                            <button *ngIf="canDeleteRestaurant" class="btn btn-secondary mb-xs" role="button" title="{{ 'Delete' | translate }}" (click)="confirmDelete(item,canDeleteModal)">
                        <i class="fa fa-eraser text-danger"></i>
                      </button>
                                            <!-- Boton encargado de abrir modal con formulario para crear cuenta y restaurante en level -->
                                            <button *ngIf="item.isFromLevel == null || item.isFromLevel == 0 || item.isFromLevel == 'false' " class="btn btn-secondary mb-xs" role="button" title="{{ 'activateLevel' | translate }}" (click)="activateLevel(item)">
                        <i class="fa fa-refresh text-danger ktl-{{ i + 1 }}"></i>
                      </button>
                                            <button *ngIf="item.isFromLevel == 1" class="btn btn-secondary mb-xs" role="button" title="{{ 'process_sync' | translate }}" disabled="disabled">
                        <i class="fa fa-refresh fa-spin text-danger ktl-{{ i + 1 }}"></i>
                      </button>
                                            <button *ngIf="item.isFromLevel == 2" class="btn btn-secondary btn-success mb-xs" role="button" title="{{ 'sync_to_level' | translate }}" (click)="syncLevel(item.levelDatabaseNickname)">
                        <i class="fa fa-cloud-upload text-danger ktl-{{ i + 1 }}"></i>
                      </button>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Botones Paginación -->
                <div class="form-group row">
                    <!-- <div class="col-lg-6">
                        <label class="pull-right">{{ 'Page' | translate }} {{actualPage}}</label>
                    </div>
                    <div class="col-lg-6">
                        <div class="btn-group btnGroup-pagination pull-right">
                            <button *ngIf="prevPage != 0" class="btn btn-default" type="button" (click)="getPage(prevPage)"><i class="fa fa-angle-left"></i></button>
                            <button #btnPage id="btnPage{{ i+1}}" *ngFor="let item of createRange(lastPage); let i = index" class="btn btn-default btnPage{{i+1}}"
                                type="button" (click)="getPage(item)">{{item}}</button>
                            <button *ngIf="nextPage <= lastPage" class="btn btn-default" type="button" (click)="getPage(nextPage)"><i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>-->
                    <div class="col-lg-12 col-md-12 d-flex justify-content-center">
                        <label>{{ 'Page' | translate }} {{bigCurrentPage}}</label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
                        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" [maxSize]="maxSize" class="pagination pagination-md justify-content-end active" [lastText]="lastPag" [firstText]="firstPag" [previousText]="prevPag" [nextText]="nextPag" [itemsPerPage]="itemsPerPageBs"
                            (click)="getPage(bigCurrentPage)" [boundaryLinks]="true" [rotate]="false" (numPages)="numPages = $event">
                        </pagination>


                    </div>

                </div>
                <ng-template #canDeleteModal>
                    <div class="modal-header">
                        <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
                            <i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                        </h4>
                        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body bg-gray-lighter">
                            <p class="text-xs-center">
                                {{ 'WantDeleteRestaurant' | translate }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <div *ngIf="selectedRestaurant">
                                <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">{{
                  'Cancel' | translate }}</button>
                                <button id="deleteBtn" type="button" class="btn btn-success" [hidden]="processingResponse" (click)="deleteRestaurant(selectedRestaurant, $event, canDeleteModal)">{{
                  'Confirm' | translate }}</button>
                                <button class="btn btn-gray flx" [hidden]="!processingResponse" [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                  <div class="lds-ellipsis proccess">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <!-- <ng-template class="modal fade" #canDeleteModal>
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
                                    <i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                                </h4>
                            </div>
                            <div class="modal-body bg-gray-lighter">
                                <p class="text-xs-center">
                                    {{ 'WantDeleteRestaurant' | translate }}
                                </p>
                            </div>
                            <div class="modal-footer">
                                <div *ngIf="selectedRestaurant">
                                    <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true">{{ 'Cancel' | translate }}</button>
                                    <button id="deleteBtn" type="button" class="btn btn-success" (click)="deleteRestaurant(selectedRestaurant, $event)">{{ 'Confirm' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template> -->
                <!-- <simple-notifications [options]="options"></simple-notifications> -->
            </div>
        </section>
    </div>
</div>
<!-- <inspectPermits></inspectPermits> -->