import { Component, OnInit } from '@angular/core';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
//import { TimerWrapper } from '@angular/core/src/facade/async';
import { FormControl, FormGroup } from '@angular/forms';

import { Constants } from 'app/app.constants';
import { MailService } from '../services/mail.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
//import { SearchMailPipe } from './../restaurant/pipes/searchMail-pipe';
//import { TitleCasePipe } from './../restaurant/pipes/titleCase-pipe';
//import { TruncateCharactersPipe } from './../restaurant/pipes/truncate-pipe';
//mport { OrderBy } from './../restaurant/pipes/orderBy-pipe';
import { PermissionsUserService } from '../services/permissionsUser.service';
import { UtilsComponent } from '../utils/utils';

//Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: 'mail',
    templateUrl: './mail.html',
    //directives: [Widget, REACTIVE_FORM_DIRECTIVES,
    //   SimpleNotificationsComponent, InspectPermitsComponent],
    // pipes: [TranslatePipe, SearchMailPipe, TitleCasePipe, TruncateCharactersPipe, OrderBy],
    providers: [NotificationsService]
})

export class MailComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private mailService: MailService,
        private permissionsUserService: PermissionsUserService,
        private utils: UtilsComponent,
        private _service: NotificationsService
    ) { 
        this.mails = [];
    }


    /**
   * Variable que controla si se muestra o no la seccion
   */
    canShowSection: boolean = false;
    canViewMails: boolean = false;
    canAddNotes: boolean = false;


    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));



    /**
     * Variable con identificador de la seccion correos de contacto
     */

    idSectionMails: any = this.allModules['module_contact_messages'];
    viewMails: any = this.allModules['module_view_contact_messages'];
    addNotes: any = this.allModules['module_add_notes_contact_messages'];

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Variables para manejo de mensajes de notificacion
    public title: string = "Contact Message";
    public content: string = "succesfully updated";

    //Variable para buscador
    searchText: string = "";

    /**
   * Variable para creacion de formulario 
   */
    public searchForm: FormGroup
    /**
   * Variable para creacion de formulario para edicion de correo
   */
    public editForm: FormGroup;
    /**
     * Variable encargada del manejo de errores al realizar peticiones a los web services
     */
    error: any;
    /**
     * Variable encargada de recibir el listado completo de mails enviados a OMT
     */
    mails: any[]
    /**
   * Variable encargada de recibir la respuesta completa que envia el web service de Clientes
   */
    fullResponse: any;
    /**
     * Variable encargado de manejo de lenguaje en la aplicacion
     */
    userLang: string;
    /**
     * Variable que almacena los correos recibidos en el web service
     */
    contacts: any;
    /**
     * Variable que contiene las notas
     */
    notes: any;
    /**
     * Variable que almacena el correo seleccionado
     */
    selectedContact: any;
    /**
     * Variable que muestra u oculta el contenido completo de una nota
     */
    showFullNote: boolean = false;
    /**
     * Variable para manejo de listado
     */
    items_per_page: number = 25;
    /**
     * Variable que recibe la respuesta despues de cambiar el estado de un correo
     */
    mailChanged: any;
    /**
     * Variable para manejo de sorting
     */
    sort: any = {
        column: 'Name',
        descending: false
    };

    sorter: number = 0;
    order_by: string = 'creation_date';

    //Variables para manejo de paginación
    currentPage: number;
    firstPage: number;
    lastPage: number;
    totalItems: number;
    itemsInCurrentPage: number;
    itemsPerPage: number;
    prevPage: number;
    nextPage: number;
    cleanSearch: boolean = false;
    actualPage: number;

    dayNote: any;
    note1: string = "I called the user and he asked about how much he should pay for publishing his restaurant on OMT";
    note2: string = "The user want to do a claim because he desagree with the service received";

    putClassActive() {
        setTimeout(() => {
            jQuery('.mailsList').addClass('active');
        }, 500);
    }

    ngOnInit() {

        this.createFormEdit();
        this.createFormSearch();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionMails);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canViewMails = this.permissionsUserService.verifyPermissionsUser(this.viewMails);
                this.canAddNotes = this.permissionsUserService.verifyPermissionsUser(this.addNotes);
                this.putClassActive();
                this.userLang = navigator.language.split('-')[0];

                this.getMails(this.sorter, this.order_by);

                this.getValuesPagination();

                setTimeout(() => {
                    this.generateContacts();
                    this.generateNotes();
                }, 10);

                this.dayNote = new Date(Date()).toLocaleString('en').substr(0, 10);

                //Manejo botones paginación
                jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
                    if (e.relatedTarget) {
                        jQuery(e.relatedTarget).removeClass('active');
                    }
                });
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);
    }

    generateContacts() {
        this.contacts = [{
            code: 'GF34G9',
            date: new Date(Date()).toLocaleString('en').substr(0, 10),
            status: 2,
            name: 'Pedro Perez',
            subject: 1,
            message: 'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            email: 'pperez@yahoo.com',
            phone: '(315) 123-4567'
        },
        {
            code: 'UTB32U',
            date: new Date(Date()).toLocaleString('en').substr(0, 10),
            status: 1,
            name: 'Juan Gomez',
            subject: 2,
            message: 'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure',
            email: 'jgomez@yahoo.com',
            phone: '(314) 123-9876'
        }
        ];

    }

    generateNotes() {
        this.notes = [
            {
                status: 1,
                dateNote: this.dayNote,
                note: "I called the user and he asked about how much he should pay for publishing his restaurant on OMT",
                user: 'Cesar Giraldo'
            },
            {
                status: 2,
                dateNote: this.dayNote,
                note: "The user want to do a claim because he desagree with the service received",
                user: 'Mauricio Kiglies'
            },
            {
                status: 1,
                dateNote: this.dayNote,
                note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                user: 'Brad Page'
            },
            {
                status: 2,
                dateNote: this.dayNote,
                note: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                user: 'Cesar Giraldo'
            },
            {
                status: 1,
                dateNote: this.dayNote,
                note: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                user: 'Cesar Giraldo'
            }
        ]
    }

    /**
     * Metodo encargado de realizar el filtro de los correos dependiendo el valor seleccionado en los 
     * diferentes selects
     * @param statusSelect: string -> Estado que puede tener un correos
     * @param subjectSelect: string -> Asunto que puede poseer un correo
     */
    searchSelectsMail(statusSelect: string, subjectSelect: string) {
        this.generateContacts();

        setTimeout(() => {
            if (statusSelect && subjectSelect) {
                this.contacts = this.contacts.filter(it => it['status'] == parseInt(statusSelect) && it['subject'] == parseInt(subjectSelect));
            } else if (statusSelect) {
                this.contacts = this.contacts.filter(it => it['status'] == parseInt(statusSelect));
            } else if (subjectSelect) {
                this.contacts = this.contacts.filter(it => it['subject'] == parseInt(subjectSelect));
            }
        }, 300);

    }

    /**
     * Metodo encargado de abrir el modal para mostrar los detalles de un correo seleccionado
     * @param selectedContact: any -> Informacion del correo seleccionado
     */
    openDetailMailModal(selectedContact: any, modal) {
        this.selectedContact = selectedContact;

        // setTimeout(() => {
        //     this.hideDivDFullNotes();
        //     if (this.selectedContact['contactByEmail']) {
        //         jQuery('#contactByEmail').prop('disabled', true);
        //     }
        //     if (this.selectedContact['contactByPhone']) {
        //         jQuery('#contactByPhone').prop('disabled', true);
        //     }
        // }, 10);
        this.modalService.open(modal);
        //jQuery('#showMailModal').modal('show');

    }

    /**
     * Metodo encargado de ocultar los div's que contienen las notas de cada correo
     */
    hideDivDFullNotes() {
        for (var i = 0; i < this.notes.length; i++) {
            jQuery('.fullNote' + i).hide();
        }
    }

    /**
     * Metodo encargado de habilitar el div para mostrar el contenido completo de una nota
     * @param note: string -> Nota completa que se va a mostrar
     * @param item: string -> Identificador del div a mostrar
     */
    showFullNoteModal(note: string, item: string) {
        this.note2 = note;
        this.hideDivDFullNotes();
        setTimeout(() => {
            jQuery('.fullNote' + item).show();
        }, 20);
    }

    /**
     * Metodo encargado de deshabilitar el div para mostrar el contenido completo de una nota
     * @param item: string -> Identificador del div a ocultar
     */
    closeFullNote(item: string) {
        this.note2 = "";
        jQuery('.fullNote' + item).hide();
    }

    /**
    * Metodo encargado de establecer la clase que indica si el ordenamiento es
    * descendente o ascendente (Icono en el th)
    */
    selectedClass(columnName) {
        return columnName == this.sort.column ? 'sort-' + this.sort.descending : false;
    }

    /**
     * Metodo encargado de cambiar la forma de ordenamiento 
     * (Ascendente o Descendente)
     */
    changeSorting(columnName): void {
        var sort = this.sort;
        if (sort.column == columnName) {
            sort.descending = !sort.descending;
        } else {
            sort.column = columnName;
            sort.descending = false;
        }
    }

    convertSorting(): string {
        return this.sort.descending ? '-' + this.sort.column : this.sort.column;
    }

    /**
     * Metodo encargado de capturar la respuesta completa que envia el web service 
     * de listado de mails
     */
    getMails(sort: number, order_by: string) {
        this.mailService
            .getMail(this.items_per_page, this.cleanSearch, this.userLang, sort, order_by)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getMailsList(this.fullResponse);
                this.getPagination(this.fullResponse);
            }).catch(error => this.error = error);
    }

    /**
     * Metodo encargado de crear el formulario para el manejo de las busquedas
     */
    createFormSearch() {
        this.searchForm = new FormGroup({
            status: new FormControl(''),
            freeText: new FormControl(''),
            subject: new FormControl('')
        })
    }

    /**
     * Metodo encargado de crear el formulario para el manejo de las busquedas
     */
    createFormEdit() {
        this.editForm = new FormGroup({
            statusMail: new FormControl('')
        })
    }

    /**
   * Metodo encargado del manejo del buscador para filtrar el listado de los mails de OMT
   * @param searchData: any -> Parametros para realizar la busqueda
   * @param isValid: boolean -> Variable que determina si el formulario se lleno correctamente
   */
    doSearch(searchData: any, isValid: boolean) {
        if (!isValid) {
            return false;
        }

        this.mailService
            .getSearchMails(searchData, this.userLang)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                setTimeout(() => {
                    this.getMailsList(this.fullResponse);
                    this.getPagination(this.fullResponse);
                }, 500);
            })
            .catch(error => this.error = error);

    }

    /**
   * Metodo encargado de capturar el listado completo de clientes
   */
    getMailsList(res: any) {
        this.mails = res.data || {};
    }

    /**
   * Metodo encargado de capturar los items necesarios para realizar
   * la paginación
   */
    getPagination(res: any) {
        this.currentPage = res.pagination.currentPage;
        this.totalItems = res.pagination.totalItems;
        this.itemsInCurrentPage = res.pagination.itemsInCurrentPage;
        this.firstPage = res.pagination.firstPage;
        this.lastPage = res.pagination.lastPage;
        this.itemsPerPage = res.pagination.itemsPerPage;
        this.nextPage = res.pagination.nextPage;
        this.prevPage = res.pagination.prevPage;
        this.actualPage = res.pagination.currentPage;

        if (res.pagination.currentPage > 1) {
            this.currentPage = this.currentPage + (res.pagination.itemsPerPage * (this.currentPage - 1)) - (this.currentPage - 1);
            this.itemsPerPage = this.currentPage + (res.pagination.itemsPerPage - 1);
        }

        if (res.pagination.itemsPerPage >= res.pagination.totalItems) {
            this.itemsPerPage = this.totalItems;
        }

        if (res.pagination.currentPage == res.pagination.lastPage) {
            this.itemsPerPage = this.totalItems;
        }
    }

    /**
  * Metodo encargado de generar array para iterar en la vista para realizar la paginacion
  */
    createRange(numberBtn) {
        let items: any;
        return items = this.utils.createRange(numberBtn);
    }

    /**
   * Metodo encargado de hacer paginación manejando el numero de items que se quieren ver en
   * el listado
   * @param items: number -> Numero de items que se mostrarán en la paginacion
   */
    doPagination(items: number) {

        this.items_per_page = items;
        this.cleanSearch = true;
        this.mailService
            .getMail(this.items_per_page, this.cleanSearch, this.userLang, this.sorter, this.order_by)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getMailsList(this.fullResponse);
                this.getPagination(this.fullResponse);
            })
            .catch(error => this.error = error);

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage1').addClass('active');
    }

    /**
   * Metodo encargado de manejar la navegacion entre paginas del listado de clientes
   * @param page:number -> Página donde se va a navegar
   */
    getPage(page: number) {

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage' + page).addClass('active');

        this.mailService
            .getPageMailsList(page, this.items_per_page, this.userLang)
            .then(fullResponse => {
                this.fullResponse = fullResponse;
                this.getMailsList(this.fullResponse);
                this.getPagination(this.fullResponse);
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de retornar el valor de las variables de paginación
     * para funcionamiento del mismo en el listado de correos 
     */
    getValuesPagination() {
        let values = this.mailService.valuesPagination();
        let page = values[0];
        let itemsPerPage = values[1];

        jQuery('.nav-pagination').children('li').children('a').removeClass('active');
        jQuery('#items' + itemsPerPage).addClass('active');

        setTimeout(() => {
            jQuery('.btnPage' + page).addClass('active');
        }, 2000);
    }

    /**
     * Metodo encargado del manejo del ordenamiento del listado de correos
     */
    doSorting(order_by: string) {
        if (this.sorter == 1) {
            this.sorter = 0;
        } else if (this.sorter == 0) {
            this.sorter = 1;
        }

        this.order_by = order_by;

        setTimeout(() => {
            this.getMails(this.sorter, this.order_by)
        }, 700);

    }

    /**
     * Metodo encargado de cambiar el estado de un correo
     * @param editData: any -> Variable con la informacion a editar del correo
     * @param isValid: boolean -> Variable que determina la validez del formulario llenado
     */
    editMail(editData: any, isValid: boolean, idMail: string) {

        if (this.userLang == 'es') {
            this.title = 'Mensaje de Contacto';
            this.content = 'actualizado exitosamente';
        } else {
            this.title = 'Contact Message';
            this.content = 'succesfully updated';
        }

        this.mailService
            .putChangeStatusMail(parseInt(idMail), parseInt(editData.statusMail), this.userLang)
            .then(mailChanged => {
                this.mailChanged = mailChanged;
                this._service.success(this.title, this.content, { id: 123 });
                this.getMails(this.sorter, this.order_by);
                setTimeout(() => {
                    for (var i = 0; i < this.mails.length; i++) {
                        if (idMail == this.mails[i].id) {
                            this.selectedContact = this.mails[i];
                        }
                    }
                }, 600);
            })
            .catch(error => this.error = error);
    }

    /**
   * Metodo encargado de establecer la clase si el usuario puede agregar notas (Tamaño del div)
   */
    selectedClassDiv() {
        let classDiv = '';
        if (this.canAddNotes) {
            classDiv = 'col-md-6 col-xs-12'
        } else {
            classDiv = 'col-lg-12 col-xs-12'
        }
        return classDiv;
    }

    closeModalFullNote() {
        //Pendiente
    }
}