<h1 class="page-title">{{ 'Contacts' | translate }}</h1>

<div class="row">
	<div class="col-md-12 col-xs-12">
		<section widget class="widget">
			<header>
				<h4>
				</h4>
			</header>
			<div class="widget-body">
				<form [formGroup]="searchForm" novalidate (ngSubmit)="doSearch(searchForm.value, searchForm.valid)">
					<div class="form-group row">
						<div class="col-md-3 col-xs-12">
							<select name="statusSelect" formControlName="status" class="form-control select2">
                            <option id="0" value=""> {{ 'Status' | translate }} </option>
                            <option id="1" value="1"> {{ 'Pending' | translate }} </option>
                            <option id="2" value="2"> {{ 'InProgress' | translate }} </option>
							<option id="3" value="3"> {{ 'Closed' | translate }} </option>
                        </select>
						</div>
						<div class="col-md-3 col-xs-12">
							<select name="subjectSelect" formControlName="subject" class="form-control select2">
                            <option id="0" value=""> {{ 'Subject' | translate }} </option>
                            <option id="1" value="1"> {{ 'IWantPublishRestaurant' | translate }} </option>
                            <option id="2" value="2"> {{ 'IGotSugerencyDoubt' | translate }} </option>
                            <option id="3" value="3"> {{ 'IWantMakeClaim' | translate }} </option>
                        </select>
						</div>
						<div class="col-md-3 col-xs-12">
							<div class="input-group">
								<span class="info input-group-addon"><i class="fa fa-search"></i></span>
								<input formControlName="freeText" type="text" class="form-control form-control-sm pull-xs-right" id="search" placeholder="{{ 'Search' | translate }}">
							</div>
						</div>
						<div class="col-md-3 col-xs-12">
							<button type="submit" id="searchFilter" class="btn btn-info">{{ 'Search' | translate }}</button>
						</div>
					</div>
				</form>

				<div class="form-group row">
					<div class="col-md-8 col-xs-12">
						<p>{{ 'Showing' | translate }} {{currentPage}} {{ 'To' | translate }} {{itemsPerPage}} {{ 'Of' | translate }} {{totalItems}}
						</p>
					</div>
					<div class="col-md-4 col-xs-12">
						<ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
							<li class="nav-item">
								<a (click)="doPagination(25)" class="nav-link active" id="items25" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">25</a>
							</li>
							<li class="nav-item">
								<a (click)="doPagination(50)" class="nav-link" id="items50" data-toggle="tab" role="tab" aria-controls="details" aria-expanded="false">50</a>
							</li>
							<li class="nav-item">
								<a (click)="doPagination(100)" class="nav-link" id="items100" data-toggle="tab" role="tab" aria-controls="image" aria-expanded="false">100</a>
							</li>
						</ul>
					</div>
				</div>

				<div class="form-group">
					<div *ngIf="mails">
						<div *ngIf="mails.length <= 0">
							<div class="alert alert-info alert-dismissable">
								<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
								<strong>{{'NoDataToShow' | translate }}</strong>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table id="tableUser" class="table table-striped table-hover table-sortable">
							<thead>
								<tr>
									<th class="cursor-pointerP" (click)="doSorting('code')">{{ 'Code' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('creation_date')">{{ 'Date' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('status')">{{ 'Status' | translate }}</th>
									<th>{{ 'Name' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('subject')">{{ 'Subject' | translate }}</th>
									<th class="txtcntr">{{ 'Actions' | translate }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of mails">
									<td>{{item.code}}</td>
									<td>{{item.createdat}}</td>
									<td>{{item.status}}</td>
									<td>{{item.client?.name}} {{item.client?.lastname}}</td>
									<td>{{item.client?.subjectDescription}}</td>
									<td>
										<p class="text-xs-center">
											<button *ngIf="canViewMails" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="openDetailMailModal(item, showMailModal)">
                                                <i class="fa fa-eye text-info"></i>
                                            </button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<!-- Botones Paginación -->
				<div class="form-group row">
					<div class="col-lg-12">
						<div class="btn-group btnGroup-pagination pull-right">
							<button *ngIf="prevPage != 0" class="btn btn-default" type="button" (click)="getPage(prevPage)"><i class="fa fa-angle-left"></i></button>
							<button #btnPage id="btnPage{{ i+1}}" *ngFor="let item of createRange(lastPage); let i = index" class="btn btn-default btnPage{{i+1}}"
								type="button" (click)="getPage(item)">{{item}}</button>
							<button *ngIf="nextPage <= lastPage" class="btn btn-default" type="button" (click)="getPage(nextPage)"><i class="fa fa-angle-right"></i></button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Modal para mostrar un Correo -->
		<ng-template class="modal fade" #showMailModal role="dialog" style="z-index: 1400;">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
						<h4 class="modal-title text-xs-center fw-bold mt">{{ 'Details' | translate }}</h4>
					</div>
					<div class="modal-body bg-body-gray">
						<div *ngIf="selectedContact">
							<div class="row">
								<div class="col-lg-12">
									<table class="table table-striped table-hover tablesorter">
										<tbody>
											<tr>
												<td width="50%"><strong>{{ 'Code' | translate }}:</strong> {{ selectedContact.code}}</td>
												<td><strong>{{ 'Name' | translate }}:</strong> {{ selectedContact.Client.name  }} {{ selectedContact.Client.lastname
													 }}</td>
											</tr>
											<tr>
												<td><strong>{{ 'Date' | translate }}:</strong> {{ selectedContact.created_at }}</td>
												<td><strong>{{ 'Email' | translate }}:</strong> {{ selectedContact.Client.email }}
												<input class="pull-xs-right" *ngIf="selectedContact.contactByEmail" type="checkbox" checked="true" id="contactByEmail">
												</td>
											</tr>
											<tr>
												<td><strong>{{ 'Status' | translate }}:</strong> {{ selectedContact.statusDescription }}</td>
												<td><strong>{{ 'Phone' | translate }}:</strong> {{ selectedContact.Client.phone }}
												<input class="pull-xs-right" *ngIf="selectedContact.contactByPhone" type="checkbox" checked="true" id="contactByPhone">
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div [class]="selectedClassDiv()">
									<table class="table table-striped table-hover tablesorter">
										<tbody>
											<tr>
												<td>
													<strong>{{ 'Subject' | translate }}:</strong> {{ selectedContact.subjectDescription }}
												</td>
											</tr>
											<tr>
												<td align="justify">
													<strong>{{ 'Message' | translate }}:</strong> {{ selectedContact.message }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div *ngIf="canAddNotes" class="col-md-6 col-xs-12">
									<label>
                                            <strong>{{ 'NewNote' | translate }}</strong>
                                        </label>
									<form [formGroup]="editForm" novalidate (ngSubmit)="editMail(editForm.value, editForm.valid, selectedContact.id)">
										<div class="row">
											<div class="col-md-8 col-xs-12">
												<textarea class="form-control" rows="7"></textarea>
											</div>
											<div class="col-md-4 col-xs-12">
												<div class="form-group">
													<select [(ngModel)]="selectedContact.status" formControlName="statusMail" class="form-control select2">
                                                        <option id="1" value="1"> {{ 'Pending' | translate }} </option>
														<option id="2" value="2"> {{ 'InProgress' | translate }} </option>
														<option id="3" value="3"> {{ 'Closed' | translate }} </option>
                                                    </select>
												</div>
												<p>
													<button class="btn btn-success widthBtn-100 mb-xs" type="submit" title="{{ 'Add' | translate }}">
                                                        <i class="fa fa-floppy-o"></i> {{ 'Add' | translate }}
                                                    </button>
												</p>
											</div>
										</div>
									</form>
								</div>
								<div class="col-lg-12">
									<label class="uppercase">
                                            <strong>{{ 'Notes' | translate }}</strong>
                                        </label>
									<table class="table table-striped table-hover tablesorter">
										<tbody>
											<tr *ngFor="let item of notes; let i = index">
												<td>
													({{dayNote}})
												</td>
												<td>
													<strong *ngIf="item.status == 1">{{ 'Closed' | translate }}</strong>
													<strong *ngIf="item.status == 2">{{ 'Pending' | translate }}</strong>
												</td>												
												<td>
													<label *ngIf="item.note.length > 55"> {{ item.note  }} <a class="cursor-pointerP" (click)="showFullNoteModal(item.note, i)">{{ 'SeeMore' | translate }}</a></label>
													<label *ngIf="item.note.length <= 55"> {{ item.note }}</label>
													<div class="fullNote{{i}}">
														<div class="alert alert-info alert-dismissable nlblk">
															<button type="button" class="close" (click)="closeFullNote(i)" aria-hidden="true">&times;</button><strong>{{note2}}</strong></div>
													</div>
												</td>
												<td>
													<label> {{ item.user }}</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

					</div>
					<div class="modal-footer">
					</div>
				</div>
			</div>
		</ng-template>

		<div id="noteFullModal" class="modal fade" role="dialog" style="z-index: 2500;" data-backdrop="static">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						{{note2}}
					</div>
					<div class="modal-footer">
						<button class="btn btn-gray mb-xs" (click)="closeModalFullNote()">Close</button>
					</div>
				</div>
			</div>
		</div>

	</div>
	<!-- <simple-notifications [options]="options"></simple-notifications> -->
</div>
<!-- <inspectPermits></inspectPermits> -->