import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsComponent } from '../utils/utils';

/*
@Autor: Alvaro Felipe García Méndez
*/
@Injectable()
export class ConfigRestaurantService {
    constructor(private httpc: HttpClient, private utils: UtilsComponent) {

    }
    private omtUrl = this.utils.getUrlOmtSd();
    private omtUrlOmt = this.utils.getUrlOmt();

    // Método para comunicar el cambio de color
    public update(colorsConfig: Object, idRestaurant: number) {
        let url = `${this.omtUrl}/${idRestaurant}/config-view`;
        return this.httpc.put(url, colorsConfig);
    }

    // Método para obtener el color que tiene el sitio
    public get(idRestaurant: string) {
        let url = `${this.omtUrlOmt}restaurant/${idRestaurant}/config-view`;
        return this.httpc.get(url);
    }

    /**
     * Método para obtener el estado de Upon delivery (contra entrega)
     * @param status
     */
    public getConfiguration(idRestaurant: string) {
        let url = `${this.omtUrl}/${idRestaurant}/configuration`;
        return this.httpc.get(url);
    }

    /**
     * Método para comunicar el cambio de estado de Upon Delivery
     * @param data 
     */
    public updateUponDelivery(data: any, idRestaurant: string, idConfig: string) {
        let url = `${this.omtUrl}/${idRestaurant}/configuration/${idConfig}`;
        return this.httpc.put(url, data);
    }

    public createUponDelivery(idRestaurant: string) {
        let url = `${this.omtUrl}/${idRestaurant}/configuration`;
        return this.httpc.post(url, { "use_upon_delivery": true, "allow_credit_cards_payments": true });
    }

    /**
     * Permite obtener todos los tipos de dine in que se pueden utilizar
     */
    public getAllDineInTypes(): any {
        let url = `${this.omtUrlOmt}sd/dine-in-types`;
        return this.httpc.get(url);
    }

    /**
     * Método para obtener las marcas con las que se pueden asociar y traer la informacion 
     * en el uso del menú externo  
     * @param id id del restaurante
     */
    public getServicesAndBrands(id: string): any {
        let url = `${this.omtUrlOmt}restaurant/verifyservicesandbrand/${id}`;
        return this.httpc.get(url);
    }

    /**
     * Metodo para copiar los valores a un restaurante segun el brand seleccionado
     * @param nickname nickname de Level del restaurante
     * @param brandFrom brand desde la cual se va a copiar
     * @param brandTo bran de destino de la copia
     */
    public insertLevelBrandData(nickname: string, brandTo: string, brandFrom: string = "0", whiteLabelPushyKey: object = null): any {
        let url = `${this.omtUrlOmt}sd/insertLevelBrandData`;
        let objectToSend = {
            "nickname": nickname,
            "brandFrom": brandFrom,
            "brandTo": brandTo,
            "overwrite": 1,
            "whiteLabelPushyKey": whiteLabelPushyKey
        };
        let json = JSON.stringify(objectToSend);
        return this.httpc.post(url, json);
    }

    /**
     * @author Anderson Barreto B. Ene - 18 - 2021
     * @version 1.0.0
     * @description Encardo de enviar las secretKeys al ws.
     * @param data contiene las secretKey y parametros requeridos por el ws.
     */
    public insertSecretKeys(data: any) {
        let url = `${this.omtUrlOmt}sd/insertSecretKeys`;

        return this.httpc.post(url, data);
    }
 
    /**
     * @author Anderson Barreto B. May - 26 - 2020
     * @version 1.0.0
     * @description Metodo en cargado de actualizar enlaces de las tiendas (white label)
     * @param id id del restaurante
     * @param storeUrls objeto con las url de las tiendas
     */
    public updateStoreLinks(id: string, storeUrls: any) {
        let url = `${this.omtUrlOmt}sd/restaurant/links/${id}`;
  
        return this.httpc.put(url, {
            url_appstore: storeUrls.appStore,
            url_playstore: storeUrls.playStore
        });
    }

}
