export class menuCategory {
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    id: number;
    name: string;
    description: string;
    sorting: number;
    sizes: Object;
}
