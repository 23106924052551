import { Injectable } from '@angular/core';

//import decode from 'jwt-decode';
import { Constants } from 'app/app.constants';


@Injectable()
export class AuthService {

    public getToken(): string {
        return localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
    }

    /**
     * Permite setear el valor del token generado
     *
     * @param token Es el nuevo token de la sesión del usuario
     */
    public setToken(token: string) {
        localStorage.setItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN, token);
    }
}