import { NotificationsService } from 'angular2-notifications';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ClientService } from '../services/client.service';
import { PermissionsUserService } from '../services/permissionsUser.service';
import { UtilsComponent } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'app/app.constants';

//Variable para manejo de funciones jquery
declare var jQuery: any;
import { saveAs } from 'file-saver';
@Component({
    selector: 'client',
    templateUrl: './client.html',
    styleUrls: ['./client.css'],
    providers: [NotificationsService]
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }
//   /*if (!isAdministrator()) {
//     router.navigate(['/App']);
//     return false;
//   }*/

//   return true;
// })
export class ClientComponent implements OnInit {

    /**
    * Variable que controla si se muestra o no la seccion
    */
    private canShowSection: boolean = false;
    public canViewClient: boolean = false;
    public canChangeStatus: boolean = false;
    public canLogoutClientSession: boolean = false;


    /**
     * variable encargada de traer todos los modulos
     */
    private allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));


    /**
     * Variable con identificador de la seccion clientes
     */
    private idSectionClient: any = this.allModules['module_clients'];
    public viewClient: any = this.allModules['module_view_clients'];
    private changeClientStatus: any = this.allModules['module_change_client_status'];
    public logoutClientSession: any = this.allModules['module_logout_client_mobile_session'];

    /**
     * Variable para manejo de lenguaje en la aplicacion
     */
    private userLang: string;
    /**
     * Variable que contendra el listado completo de clientes
     */
    public clients: any;
    /**
     * Variable para creacion de formulario 
     */
    public searchForm: FormGroup;
    /**
     * Variable para creacion de formulario para editar el cliente
     */
    public editForm: FormGroup;
    /**
     * Variable encargada de recibir la respuesta completa que envia el web service de Clientes
     */
    private fullResponse: any;
    /**
     * Variable encargada del manejo de errores al hacer peticiones a los web services
     */
    private error: any;
    /**
     * Variable encargada de almacenar la informacion detallada de un cliente
     */
    public selectedClient: any;
    /**
     * Variable que almacena los restaurantes favoritos de un cliente
     */
    public favouriteRestaurants: Array<Object> = [];
    /**
     * Variable que almacena el resultado del cambio de estado del cliente
     */
    private clientUpdated: any;
    /**
     * Variable encargada de almacenar la sesion activa de un cliente
     */
    public activesession: number = 0;
    /**
     * Variable que almacena el resultado al desloguear un cliente
     */
    private clientLogout: any;

    //Variables para manejo de paginación
    public currentPage: number;
    private firstPage: number;
    public lastPage: number;
    public totalItems: number;
    private itemsInCurrentPage: number;
    public itemsPerPage: number;
    public prevPage: number;
    public nextPage: number;
    private cleanSearch: boolean = false;
    private actualPage: number;
    public sorter: number = 1;
    private order_by: string = 'creation_date';

    public paginationArray: Array<any>;
    public paginationSelected: number;
    private itemsPerPageSelected: number = 25;


    //Variable para paginación por defecto
    // private itemsPerPageBs: number = 10;
    // private page: number = 1;
    /**
     * Variables para el manejo de la paginacion
     */
    public maxSize: number;
    public bigTotalItems: number;
    public bigCurrentPage: number = 1;
    public numPages: number;
    public itemsPerPageBs: number = 25;
    public nextPag: string;
    public prevPag: string;
    public lastPag: string;
    public firstPag: string;

    /**
   * Variable que indica que se esta descargando el archivo de excel
   */
  public downloadExcel: boolean;
    constructor(
        private modalService: NgbModal,
        private clientService: ClientService,
        private permissionsUserService: PermissionsUserService,
        private utils: UtilsComponent,
        private _service: NotificationsService,
        private translate: TranslateService
    ) {
        this.paginationArray = [25, 50, 100];
     }

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Variables para manejo de mensajes de notificacion
    public title: string = "Client Status";
    public content: string = "edited succesfully";

    private putClassActive() {
        setTimeout(() => {
            jQuery('.clientsList').addClass('active');
        }, 500);   
    }

    ngOnInit() {
        this.downloadExcel = false;
        this.createFormSearch();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionClient);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canViewClient = this.permissionsUserService.verifyPermissionsUser(this.viewClient);
                this.canChangeStatus = this.permissionsUserService.verifyPermissionsUser(this.changeClientStatus);
                this.canLogoutClientSession = this.permissionsUserService.verifyPermissionsUser(this.logoutClientSession);
                this.putClassActive();
                this.userLang = navigator.language.split('-')[0];

                this.getAllClients(this.sorter, this.order_by);
                this.createFormEditUser();
                // this.getValuesPagination();

                //Manejo botones paginación
                jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
                    if (e.relatedTarget) {
                        jQuery(e.relatedTarget).removeClass('active');
                    }
                });
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);
    }

    /**
     * Metodo encargado de solicitar al web service el listado completo de los clientes que estan registrados
     * en OMT
     */
    private getAllClients(sort: number, order_by: string) {
        this.paginationSelected = this.itemsPerPageBs;
        this.clientService
            .getClients(this.itemsPerPageBs, this.cleanSearch, this.userLang, sort, order_by)
            .subscribe(fullResponse => {
                // this.fullResponse = fullResponse;
                // this.getClientsList(this.fullResponse);
                // this.getPagination(this.fullResponse);
                // this.getPaginationBs(this.fullResponse);
                this.paginationSelected = null;
                this.handlerResponse(fullResponse);
            });
        // .catch(error => this.error = error);
    }
    /**
     * Funcion que maneja la respuesta el ws de clientes
     * @param fullResponse respuesta del ws
     */
    handlerResponse(fullResponse) {
        this.fullResponse = fullResponse;
        this.getClientsList(this.fullResponse);
        this.getPagination(this.fullResponse);
        this.getPaginationBs(this.fullResponse);
    }
    /**
     * Metodo encargado de pedir al web service la informacion detallada de un cliente
     * @param idClient: number -> Id del cliente al que se va a consultar la informacion  detallada
     */
    private getClientDetail(idClient: number) {
        this.clientService
            .getClientDetails(idClient, this.userLang)
            .subscribe(client => {
                this.selectedClient = client;
                this.selectedClient.createdAt = this.selectedClient.createdAt.substr(0, 10);
                for (var i = 0; i < this.selectedClient['favorite_restaurants'].length; i++) {
                    if (this.selectedClient['favorite_restaurants'][i]['Restaurant'] != null) {
                        this.favouriteRestaurants.push(this.selectedClient['favorite_restaurants'][i]['Restaurant']);
                    }
                }

                for (var j = 0; j < this.selectedClient['mobile_sessions'].length; j++) {
                    if (this.selectedClient['mobile_sessions'][j].isActive) {
                        this.activesession = this.selectedClient['mobile_sessions'][j].id;
                    }
                }


            });
        // .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de capturar el listado completo de clientes
     */
    private getClientsList(res: any) {
        this.clients = res.data || {};
    }

    /**
     * Metodo encargado de capturar los items necesarios para realizar
     * la paginación
     */
    private getPagination(res: any) {
        this.currentPage = res.pagination.currentPage;
        this.totalItems = res.pagination.totalItems;
        this.itemsInCurrentPage = res.pagination.itemsInCurrentPage;
        this.firstPage = res.pagination.firstPage;
        this.lastPage = res.pagination.lastPage;
        this.itemsPerPage = res.pagination.itemsPerPage;
        this.nextPage = res.pagination.nextPage;
        this.prevPage = res.pagination.prevPage;
        this.actualPage = res.pagination.currentPage;

        if (res.pagination.currentPage > 1) {
            this.currentPage = this.currentPage + (res.pagination.itemsPerPage * (this.currentPage - 1)) - (this.currentPage - 1);
            this.itemsPerPage = this.currentPage + (res.pagination.itemsPerPage - 1);
        }

        if (res.pagination.itemsPerPage >= res.pagination.totalItems) {
            this.itemsPerPage = this.totalItems;
        }

        if (res.pagination.currentPage == res.pagination.lastPage) {
            this.itemsPerPage = this.totalItems;
        }
    }

    /**
     *  Metodo encargado de capturar los items necesarios para realizar
     * la paginación de los botones
     * @param res 
     */
    private getPaginationBs(res: any) {
        this.translate.get(["Next", "Previous", "lastPage", "firstPage"]).subscribe(translations => {
            this.nextPag = translations.Next;
            this.prevPag = translations.Previous;
            this.lastPag = translations.lastPage;
            this.firstPag = translations.firstPage;
        });

        let itemsPerPageInitial = this.itemsPerPageBs;
        this.maxSize = 5;
        this.bigTotalItems = res.pagination.totalItems;
        this.bigCurrentPage = res.pagination.currentPage;
        this.numPages = 0;
        this.itemsPerPageBs = res.pagination.itemsPerPage;
        if (itemsPerPageInitial != res.pagination.itemsPerPage) {
            this.getPage(1);
        }
    }

    /**
     * Metodo encargado de manejar la navegacion entre paginas del listado de clientes
     * @param page:number -> Página donde se va a navegar
     */
    public getPage(page: number) {

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage' + page).addClass('active');

        this.clientService
            .getPageClientsList(page, this.itemsPerPageBs, this.userLang)
            .subscribe(fullResponse => {
                this.fullResponse = fullResponse;
                this.getClientsList(this.fullResponse);
                this.getPagination(this.fullResponse);
            });
        // .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de generar array para iterar en la vista para realizar la paginacion
     */
    public createRange(numberBtn) {
        let items: any;
        return items = this.utils.createRange(numberBtn);
    }

    /**
     * Metodo encargado de hacer paginación manejando el numero de items que se quieren ver en
     * el listado
     * @param items: number -> Numero de items que se mostrarán en la paginacion
     */
    public doPagination(items: number) {
        this.paginationSelected = items;
        this.itemsPerPageSelected = items;
        this.itemsPerPageBs = items;
        this.cleanSearch = true;
        this.clientService
            .getClients(this.itemsPerPageBs, this.cleanSearch, this.userLang, this.sorter, this.order_by)
            .subscribe(fullResponse => {
                // this.fullResponse = fullResponse;
                // this.getClientsList(this.fullResponse);
                // this.getPagination(this.fullResponse);
                // this.getPaginationBs(this.fullResponse);
                this.paginationSelected = null;
                this.handlerResponse(fullResponse);
            })
        // .catch(error => this.error = error);

        jQuery('.btn').siblings().removeClass('active');
        jQuery('#btnPage1').addClass('active');
    }

    /**
   * Metodo encargado de crear el formulario para manejo de la busqueda
   */
    private createFormSearch() {
        this.searchForm = new FormGroup({
            status: new FormControl(''),
            freeText: new FormControl(''),
            active_sessions: new FormControl(''),
            orders_made: new FormControl(''),
            dateRangePicker: new FormControl('')
        })
    }

    /**
   * Metodo encargado de crear el formulario cambiar el estado de un usuario
   */
    private createFormEditUser() {
        this.editForm = new FormGroup({
            statusClient: new FormControl(''),
            active_sessionsClient: new FormControl('')
        })
    }

    /**
     * Metodo encargado del manejo del buscador para filtrar el listado de los clientes de OMT
     * @param searchData: any -> Parametros para realizar la busqueda
     * @param isValid: boolean -> Variable que determina si el formulario se lleno correctamente
     */
    public doSearch(searchData: any, isValid: boolean) {
        console.log('doSearch', searchData);
        if (!isValid) {
            return false;
        }
        this.clientService
            .getSearchClients(searchData, this.userLang)
            .subscribe(fullResponse => {
                this.fullResponse = fullResponse;
                setTimeout(() => {
                    this.getClientsList(this.fullResponse);
                    this.getPagination(this.fullResponse);
                    this.getPaginationBs(this.fullResponse);
                }, 500);
            })
        // .catch(error => this.error = error);

    }

    /**
       * Metodo encargado de retornar el valor de las variables de paginación
       * para funcionamiento del mismo en el listado de clientes 
       */
    private getValuesPagination() {
        let values = this.clientService.valuesPagination();
        let page = values[0];
        let itemsPerPage = values[1];

        jQuery('.nav-pagination').children('li').children('a').removeClass('active');
        jQuery('#items' + itemsPerPage).addClass('active');

        setTimeout(() => {
            jQuery('.btnPage' + page).addClass('active');
        }, 2000);
    }

    /**
     * Metodo encargado de abrir el modal de detalles de un Cliente
     * @param idClient: number -> Id del cliente al que se le mostraran los detalles
     */
    public openClientDetailsModal(idClient: number, modal) {
        this.getClientDetail(idClient);
        this.modalService.open(modal);
        setTimeout(() => {


        }, 800);
    }

    /**
     * Metodo encargado de cerrar el modal que muestra los detalles de un cliente
     */
    public closeClientDetailModal() {
        jQuery('#showClientModal').modal('hide');
        this.favouriteRestaurants = [];
        this.activesession = 0;
    }

    /**
     * Metodo encargado de editar el estado y la sesion de un cliente
     * @param editData: any -> Variable con la informacion a editar del usuario
     */
    public onSubmit(editData: any, isValid: boolean) {
    }

    /**
     * Metodo encargado de cambiar el estado de un cliente
     * @param idClient: number -> Id del cliente al cual se le cambiara el estado
     * @param status: string -> Estado que se definira al cliente
     */
    public changeStatusClient(idClient: number, status: string) {

        if (this.userLang == 'es') {
            this.title = "Estado de Cliente";
            this.content = "editado exitosamente";
        } else {
            this.title = "Client Status";
            this.content = "edited succesfully";
        }

        this.clientService
            .putChangeStatus(idClient, parseInt(status), this.userLang)
            .subscribe(clientUpdated => {
                this.clientUpdated = clientUpdated;
                this._service.success(this.title, this.content, { id: 123 });
                this.getAllClients(this.sorter, this.order_by);
                this.getClientDetail(idClient);
                this.favouriteRestaurants = [];
            })
        // .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de cerrar la sesion activa de un cliente registrado en OMT
     * @param sessionId: string -> Id de la sesion activa del cliente
     * @param idClient: string -> Id del cliente al que se le va a cerrar la sesion
     */
    public logoutClient(sessionId: number, idClient: string) {

        if (this.userLang == 'es') {
            this.title = "Sesion del Cliente";
            this.content = "cerrada exitosamente";
        } else {
            this.title = "Client Session";
            this.content = "closed succesfully";
        }

        this.clientService
            .putLogoutClient(parseInt(idClient), sessionId, this.userLang)
            .subscribe(clientLogout => {
                this.clientLogout = clientLogout;
                this._service.success(this.title, this.content, { id: 123 });
                this.getAllClients(this.sorter, this.order_by);
                this.getClientDetail(parseInt(idClient));
                this.favouriteRestaurants = [];
                this.activesession = 0;
            })
        // .catch(error => this.error = error);

    }

    /**
     * Metodo encargado de realizar ordenamiento 
     */
    public doSorting(order_by: string) {
        if (this.sorter == 1) {
            this.sorter = 0;
        } else if (this.sorter == 0) {
            this.sorter = 1;
        }

        this.order_by = order_by;

        setTimeout(() => {
            this.getAllClients(this.sorter, this.order_by)
        }, 700);

    }

    /**
     * Exporta el reporte en excel
     * @param eventPage 
     */
    public exportToExcel() {
        this.downloadExcel = true;
        this.clientService.getExcelReport(this.itemsPerPageSelected).subscribe((fileData: any) => {
            this.downloadExcel = false;
            console.log('fileData', fileData);
            let blob = new Blob([fileData._body], { type: fileData.type });

            const file = new File([blob], `client-reports.xlsx`, { type: fileData.type });
            saveAs(file);
        }, err => console.log(err));
    }
}