<h1 class="page-title">{{ 'Clients' | translate }}</h1>

<div class="row">
	<div class="col-md-12 col-xs-12">
		<section widget class="widget">
			<header>
				<h4>
				</h4>
			</header>
			<div class="widget-body">
				<form [formGroup]="searchForm" novalidate (ngSubmit)="doSearch(searchForm.value, searchForm.valid)">
					<div class="form-group row">
						<div class="col-md-2 col-xs-12">
							<select id="statusSelect" name="statusClient" formControlName="status" class="form-control select2">
                                    <option id="1" value=""> {{ 'Status' | translate }} </option>
                                    <option id="2" value="1"> {{ 'Active' | translate }} </option>
                                    <option id="3" value="2"> {{ 'Inactive' | translate }} </option>
									<option id="4" value="3"> {{ 'Locked' | translate }} </option>
                            </select>
						</div>
						<div class="col-md-2 col-xs-12">
							<select id="activeSessionSelect" name="activeSession" formControlName="active_sessions" class="form-control select2">
                                    <option id="1" value=""> {{ 'ActiveSessions' | translate }} </option>
                                    <option id="2" value="true"> {{ 'Yes' | translate }} </option>
                                    <option id="3" value="false"> {{ 'No' | translate }} </option>
                            </select>
						</div>
						<div class="col-md-2 col-xs-12">
							<select id="activeSessionSelect" name="activeSession" formControlName="orders_made" class="form-control select2">
                                    <option id="1" value=""> {{ 'MADE_ORDERS' | translate }} </option>
                                    <option id="2" value="1"> {{ 'Yes' | translate }} </option>
									<option id="3" value="0"> {{ 'No' | translate }} </option>
                            </select>
						</div>
						<div class="col-md-2 col-xs-12">
							<div class="input-group">
								<span class="info input-group-addon"><i class="fa fa-search"></i></span>
								<input formControlName="freeText" type="text" class="form-control form-control-sm pull-xs-right" id="search" placeholder="{{ 'Search' | translate }}">
							</div>
						</div>
						<div class="col-md-2 col-xs-12">
							<div class="input-group">
							  <input class="form-control" placeholder="{{ 'DATE_RANGE' | translate }}" bsDaterangepicker
								#dpr="bsDaterangepicker" [bsConfig]="{
								dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-green' }" formControlName="dateRangePicker" name="dateRangePicker">
							</div>
						  </div>
						<div class="col-md-2 col-xs-12">
							<button type="submit" id="searchFilter" class="btn btn-info">{{ 'Search' | translate }}</button>
						</div>
					</div>	
				</form>

				<div class="form-group row">
					<div class="col-md-8 col-xs-12">
						<p>{{ 'Showing' | translate }} {{currentPage}} {{ 'To' | translate }} {{itemsPerPage}} {{ 'Of' | translate }} {{totalItems}}
						</p>
					</div>
					<div class="col-md-4 col-xs-12">
						<ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
							<li class="nav-item" *ngFor="let page of paginationArray">
								<a (click)="doPagination(page)" [ngClass]="{'active': page === itemsPerPageSelected}" class="nav-link" id="items10" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">{{page}}</a>
								<div class="lds-dual-ring" *ngIf="paginationSelected == page"></div>
							</li>
						</ul>
					</div>
				</div>
			
				<div class="text-xs-left pull-right">
					<button  class="btn btn-success mb-xs"
					(click)="exportToExcel()" [disabled]="downloadExcel" [hidden]="downloadExcel">
					<i class="fa fa-file"></i> {{ 'EXPORT_TO_EXCEL' | translate }}
					</button>
					<button class="btn btn-gray flx" [hidden]="!downloadExcel"
					[disabled]="!downloadExcel"
					>{{ 'processing' | translate }}&nbsp;
					<div class="lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					</button>
				</div>
				<div class="form-group">
					<div *ngIf="clients">
                        <div *ngIf="clients.length <= 0">
                            <div class="alert alert-info alert-dismissable">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                <strong>{{'NoDataToShow' | translate }}</strong>
                            </div>
                        </div>
                    </div>
					<div class="table-responsive">
						<table id="tableUser" class="table table-striped table-hover table-sortable">
							<thead>
								<tr>
									<th class="cursor-pointerP" (click)="doSorting('name')">{{ 'Name' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('lastname')">{{ 'LastName' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('username')">{{ 'Username' | translate}}</th>
									<th>{{ 'Status' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('email')">{{ 'Email' | translate }}</th>
									<th>{{ 'MADE_ORDERS' | translate }}</th>
									<th>{{ 'Phone' | translate }}</th>
									<th>{{ 'TYPE_REGISTER' | translate }}</th>
									<th class="cursor-pointerP" (click)="doSorting('creation_date')">{{ 'CREATED' | translate }}</th>
									<!-- <th class="txtcntr">{{ 'Actions' | translate }}</th> -->
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of clients">
									<td>
										{{item.name}}
									</td>
									<td>
										{{item.lastname}}
									</td>
									<td>
										{{item.username}}
									</td>
									<td>
										{{item.statusDescription}}
									</td>
									<td>
										{{item.email}}
									</td>
									<td>
									  <ng-container *ngIf="item.qty_order > 0"> 
											{{'Yes' | translate}}
											<br>
											{{'QTY' | translate}}: {{item.qty_order}}
									  </ng-container>
									  <ng-container *ngIf="item.qty_order === 0"> {{'No' | translate}}</ng-container>
									</td>
									<td >
										{{item.phone}}
									</td>
									<td class="capitalize-letter">
										{{item.socialNetwork}}
									</td>
									<td>
										{{item.createdat | dateTimeLocalize | async}}
									</td>
									<!-- <td>
										<p class="text-xs-center">
											<button *ngIf="canViewClient" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="openClientDetailsModal(item.id, showClientModal)">
                                                <i class="fa fa-eye text-info"></i>                                            
                                            </button>
										</p>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="form-group row">
					<div class="col-lg-12 col-md-12 d-flex justify-content-center">
                        <label>{{ 'Page' | translate }} {{bigCurrentPage}}</label>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
                        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" [maxSize]="maxSize" class="pagination pagination-md justify-content-end active" [lastText]="lastPag" [firstText]="firstPag" [previousText]="prevPag" [nextText]="nextPag" [itemsPerPage]="itemsPerPageBs"
                            (click)="getPage(bigCurrentPage)" [boundaryLinks]="true" [rotate]="false" (numPages)="numPages = $event">
                        </pagination>
                    </div>
					<!-- <div class="col-lg-12">
						<div class="btn-group btnGroup-pagination pull-right">
							<button *ngIf="prevPage != 0" class="btn btn-default" type="button" (click)="getPage(prevPage)"><i class="fa fa-angle-left"></i></button>
							<button #btnPage id="btnPage{{ i+1}}" *ngFor="let item of createRange(lastPage); let i = index" class="btn btn-default btnPage{{i+1}}"
								type="button" (click)="getPage(item)">{{item}}</button>
							<button *ngIf="nextPage <= lastPage" class="btn btn-default" type="button" (click)="getPage(nextPage)"><i class="fa fa-angle-right"></i></button>
						</div>
					</div> -->
				</div>
			</div>

			<!-- Modal para mostrar los detalles de un Cliente -->
			<ng-template class="modal fade" #showClientModal data-backdrop="static">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<button type="button" class="close" (click)="closeClientDetailModal()" aria-hidden="true">&times;</button>
							<h4 *ngIf="selectedClient" class="modal-title text-xs-center fw-bold mt">
								{{ 'Client' | translate }} {{ selectedClient.name }} {{ selectedClient.lastname }}
							</h4>
						</div>
						<div class="modal-body bg-body-gray">
							<div *ngIf="selectedClient">
								<div class="row">
									<div class="col-sm-12">
										<table class="table table-striped table-hover tablesorter">
											<thead>
											</thead>
											<tbody>
												<tr>
													<td width="50%"><strong>{{ 'Name' | translate }}:</strong> {{ selectedClient.name }} {{ selectedClient.lastname
														 }}</td>
													<td><strong>{{ 'Status' | translate }}:</strong> {{ selectedClient.statusDescription}}</td>
												</tr>
												<tr>
													<td><strong>{{ 'Email' | translate }}:</strong> {{ selectedClient.email }}</td>
													<td><strong>{{ 'Phone' | translate }}:</strong> {{ selectedClient.phone }}</td>
												</tr>
												<tr>
													<td><strong>{{ 'Username' | translate }}:</strong> {{ selectedClient.username }}</td>
													<td><strong>{{ 'Created' | translate }}:</strong> {{ selectedClient.createdAt }}</td>
												</tr>												
											</tbody>
										</table>

										<table *ngIf="selectedClient.addresses.length > 0" class="table table-striped">
											<thead>
												<th class="txtcntr">
													{{ 'Address' | translate }}
												</th>
											</thead>
											<tbody>
												<tr *ngIf="selectedClient.addresses.length > 0">
													<td colspan="2"><div *ngFor="let item of selectedClient.addresses"><strong>{{ item.name }}: </strong>{{ item.naturalAddress }}</div></td>
												</tr>
											</tbody>
										</table>

										<table *ngIf="favouriteRestaurants.length > 0" class="table table-striped">
											<thead>
												<th class="txtcntr">
													{{ 'FavouriteRestaurants' | translate }}
												</th>
											</thead>
										</table>

										<table *ngIf="favouriteRestaurants.length > 0" class="table table-striped">
											<tbody>
												<tr>
													<td colspan="1" class="grandit txtcntr">
														<div class="dadit">
															<div class="childit" *ngFor="let item of favouriteRestaurants">
																{{item.name}}
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>

										simple						<h5 *ngIf="canChangeStatus || canLogoutClientSession" class="txtcntr"><strong>{{ 'Edit' | translate }}</strong></h5>

										<div *ngIf="canChangeStatus" class="form-group row">
											<div class="col-md-6 col-xs-12">
												<p><strong> {{ 'Status' | translate }} </strong></p>
											</div>
										
											<div class="col-md-6 col-xs-12">
												<select [(ngModel)]="selectedClient.status" id="statusClient" name="statusClient" class="form-control select2" (change)="changeStatusClient(selectedClient.id, $event.target.value)">
													<option id="2" value="1"> {{ 'Active' | translate }} </option>
													<option id="3" value="2"> {{ 'Inactive' | translate }} </option>
													<option id="4" value="3"> {{ 'Locked' | translate }} </option>
												</select>
											</div>
										</div>

										<div *ngIf="activesession != 0 && canLogoutClientSession" class="form-group row">
											<div class="col-md-6 col-xs-12">
												<p><strong>{{ 'LogoutClient' | translate }}</strong></p>
											</div>

											<div *ngIf="activesession != 0" class="col-md-6 col-xs-12">
												<button type="button" class="btn btn-danger mb-xs widthBtn-100" id="logoutClient" (click)="logoutClient(activesession, selectedClient.id)"><i class="fa fa-sign-out"></i></button>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
						<div class="modal-footer">
							<!--<p class="text-xs-right">
								<button id="saveBtn" type="submit" [disabled]="!editForm.valid" class="btn btn-success md-xs" (click)="onSubmit(editForm.value, editForm.valid)">{{ 'Save' | translate }}</button>
							</p>-->
						</div>
					</div>
				</div>
			</ng-template>
		</section>
	</div>
	<!-- <simple-notifications [options]="options"></simple-notifications> -->
</div>
<!-- <inspectPermits></inspectPermits> -->