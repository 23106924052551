import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class GlobalEventsService {

    public category: EventEmitter<number>;
    public addEmail: EventEmitter<void>;


    constructor() {
        this.category = new EventEmitter<number>();
        this.addEmail = new EventEmitter<void>();
    }

}
