import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsComponent } from '../utils/utils';

@Injectable({
    providedIn: 'root'
})
export class CouponsService {

    constructor(
        private http: HttpClient,
        private utils: UtilsComponent
    ) { }

    /**
     * WS to get the list of coupons by clients
     * @param paramsQuery params idRestaurant, like page, status, items_per_page,
     * nickname, startDate y endDate
     * @author Valeria Medina Ramirez- Oct. 11 - 2019
     * @version 1.0.0
     */
    public getListCouponsByClients(paramsQuery): Observable<any> {
        const url = this.utils.getUrlOmt() + 'sd/listCouponByClient?orderBy=restaurant&items_per_page=100' + paramsQuery;
        return this.http.get(url);
    }
    /**
    * WS to redeem a coupon
    * @param data all the information about the coupon
    * @author Valeria Medina Ramirez- Oct. 16 - 2019
    * @version 1.0.0
    */
    public redeemCoupon(data): Observable<any> {
        const dataToSend = {
            idCoupon: data.idCoupon,
            idClient: data.idClient,
            idRestaurant: data.idRestaurant
        }
        const url = this.utils.getUrlOmt() + 'sd/coupon/redeem';
        return this.http.post(url, dataToSend);
    }

}
