import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { Constants } from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class UserLocationService {

    // permite almacenar el nombre del pais en el que el usuario esta
    private currentCountry;
    // es el pais por defecto indicado
    private defaultCountry: any;

    constructor(public userService: UserService) {
        // inicializamos el pais
        this.currentCountry = sessionStorage.getItem(Constants.SESSIONS_STORAGE_DATA.USER_COUNTRY);
        // indicamos que el pais por defecto es usa
        this.defaultCountry = 'USA';
    }

    /**
     * Permite obtener el ultimo nombre del ultimos pais encontrado
     */
    public getCurrentCountry(): string {
        return this.currentCountry;
    }
    /**
     * Permite verificar si el pais es estados unidos
     */
    public isUSA(country: string = null): boolean {

        // en caso de que no hayamos recibido el argumento
        if (country === null) {
            // en caso de que no hayamos cargado el pais
            if (this.currentCountry === null) {
                return true; // por defecto tomamos como si el usuario estuviera en USA
            }

            // clonamos la varible para que no vaya a ser afectada
            country = this.currentCountry;
        }

        if (country.trim() === 'United States' ||
            country.trim() === 'Estados Unidos' ||
            country.trim() === 'United States of America' ||
            country.trim() === 'EE. UU.' ||
            country.trim() === 'USA' ||
            country.trim() === 'UnitedStates') {
            return true;
        }

        return false;
    }

    /**
    * Permite verificar si el usuario esta ubicado en colombia
    */
    public isColombia(country: string = null): boolean {

        // en caso de que no hayamos recibido el argumento
        if (country === null) {
            const userCountry = sessionStorage.getItem(Constants.SESSIONS_STORAGE_DATA.USER_COUNTRY);

            // en caso de que no hayamos cargado el pais
            if (this.currentCountry === null && !userCountry) {
                return false;
            }

            // console.info("PAIS ACTUAL 1: ", this.currentCountry);
            return userCountry.trim().toLowerCase() === 'colombia';
        }

        // console.info("PAIS ACTUAL 2: ", country);
        return country.trim().toLowerCase() === 'colombia';
    }


    /**
     * permite obtener el pais y la ubicacion lo mas cernao posible a traves de su conexion a internet
     */
    public findLocationByNetwork(callback: Function = null) {
        return new Promise(resolve => {
            this.userService.getCountryFromIp().subscribe(response => {
                if (response['data']) {
                    // asignamos el nombre del pais para tenerlo en memoria
                    this.currentCountry = response['data']['country'];
                    // guardamos el valor en session
                    sessionStorage.setItem(Constants.SESSIONS_STORAGE_DATA.USER_COUNTRY, this.currentCountry);
                } else {
                    // asignamos los valores por defecto
                    this.currentCountry = this.defaultCountry;
                }

                // en caso de que se haya enviado un callback lo ejecutamos y pasamos el valor
                if (callback !== null) {
                    callback(this.currentCountry);
                }
                resolve(true);

            });
        });
    }
}
