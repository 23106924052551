import { Promise } from 'es6-shim';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { menuCategory } from '../entity/menuCategory';
import { UtilsComponent } from '../utils/utils';

@Injectable()
export class CategoryService {

    constructor(
        private http: HttpClient,
        private utils: UtilsComponent
    ) { }

    private categoryUrl = 'https://www.mocky.io/v2/578d32c50f0000ae07aebb09';
    private mockyUrl = 'https://www.mocky.io/v2/57a3a8a13b0000fd1a9034fc';
    private omtUrl = this.utils.getUrlOmtSd();
    private getCatUrl = this.utils.getUrlOmt() + 'restaurant';


    /**
     * permite consultar todos los registros de categorias
     */
    getAllCategories(idRestaurant: number, idMenu: number) {
        const url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/categories`;
        return this.http.get(url);
    }


    /**
     * permite almacenar los datos de una categoria
     * 
     * @param category 
     * @param size 
     * @param idRestaurant 
     * @param idMenu 
     */
    save(category: menuCategory, size: any, idRestaurant: number, idMenu: number): Observable<menuCategory> {
        if (category.id) {
            return this.put(category, size, idRestaurant, idMenu);
        } else {
            return this.post(category, size, idRestaurant, idMenu);
        }
    }

    /**
     * permite registrar una categoria nueva
     * @param category 
     * @param size 
     * @param idRestaurant 
     * @param idMenu 
     */
    private post(category: menuCategory, size: any, idRestaurant: number, idMenu: number) {
        var arrayData = {
            name: category.name,
            description: category.description,
            sizes: size
        }
        let array = JSON.stringify(arrayData);
        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category`;
        return this.http.post<menuCategory>(url, array);
    }


    /**
     * Permite guardar los cambios de una categoria
     * @param category 
     * @param size 
     * @param idRestaurant 
     * @param idMenu 
     */
    private put(category: menuCategory, size: string, idRestaurant: number, idMenu: number) {

        var arrayData = {
            name: category.name,
            description: category.description,
            sizes: size
        }

        let array = JSON.stringify(arrayData);
        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${category.id}`;

        return this.http.put<menuCategory>(url, array);
    }


    /**
     * permite eliminar una categoria
     * @param category 
     * @param idRestaurant 
     * @param idMenu 
     */
    delete(category: menuCategory, idRestaurant: number, idMenu: number) {
        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${category.id}`;
        return this.http.delete(url);
    }




    /**
     * permite consultar categorias de mocky
     */
    /*getCategories(): Observable<any> {
        return this.http.get(this.mockyUrl);
    }*/

    /**
     * permite consultar
     * @param id 
     */
    /*getCategory(id: number) {
        const tmpPromise = new Promise((resolve, reject) => {
            // the resolve / reject functions control the fate of the promise

            this.getCategories()
                .subscribe(data => {
                    let encontro = false;
                    for(let tmpCategory of data){
                        if(tmpCategory.id == id){
                            encontro = true;
                            resolve(tmpCategory);
                            break;
                        }
                    }

                    if(!encontro){
                        resolve(null);
                    }

                },error => {
                    reject(Promise.reject(error.message || error));
                });

            //.then(categories => categories.filter(category => category.id === id)[0]);
        });

        return tmpPromise;
    }
    

    addCategory(name: string): Promise<categoryFood> {
        let body = JSON.stringify({ name });
        return this.http.post(this.categoryUrl, body)
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    */





    private handleError(error: any) {
        return Promise.reject(error.message || error);
    }

    private extractDataCategory(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    private extractData(res: Response) {
        let body = res.json();
        console.info("RESPUESTA CATEGORIES: ", body);
        return body || {};
    }



}
