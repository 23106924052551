<button class="status color-status-order-{{inputState}}" (click)="changeOrderStatusOmt()" [hidden]="buttonProcessing">
    {{ currentState }}
</button>

<button class="status" [hidden]="!buttonProcessing" [disabled]="buttonProcessing">{{ 'processing' | translate }}&nbsp;
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</button>