// import { InspectPermitsComponent } from './../../inspectPermits/inspectPermits';
import { LocalizeRouterService } from 'localize-router';

import { MapsAPILoader } from '@agm/core';
import {
    ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import { TimerWrapper } from '@angular/core/src/facade/async';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
//import { MapsAPILoader } from 'angular2-google-maps/core';
//import { ACCORDION_DIRECTIVES } from 'ng2-bootstrap/ng2-bootstrap';
import { Response } from '@angular/http';
// import { Widget } from '../../core/widget/widget';
// import { CheckAll } from '../../core/utils/check-all';
//import { JqSparkline } from '../../components/sparkline/sparkline';
// import { ProgressAnimate } from '../../core/utils/progress-animate';
import { CanActivate, Router } from '@angular/router';

import { Constants } from '../../app.constants';
// import { categoryFood } from './../../entity/categoryFood';
import { restaurant } from '../../entity/restaurant';
import { AlertsService } from '../../services/alerts.service';
// import { isLoggedIn } from './../../userLogin.service';
// import { appInjector } from './../../app-injector';
import { PermissionsUserService } from '../../services/permissionsUser.service';
// import { CategoryService } from './../../services/category.service';
import { RestaurantService } from '../../services/restaurant.service';
import { globalRegex } from '../../utils/globalRegex';
import { UtilsComponent } from '../../utils/utils';

//Variable para manejo autocompletar location
declare var google: any;
//Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: 'newRestaurant',
    templateUrl: './newRestaurant.html',
    styleUrls: ['./newRestaurant.scss'],
    // directives: [Widget,
    //     ROUTER_DIRECTIVES,
    //     REACTIVE_FORM_DIRECTIVES,
    //     SimpleNotificationsComponent,
    //     ACCORDION_DIRECTIVES,
    //     InspectPermitsComponent],
    // providers: [NotificationsService],
    // pipes: [TranslatePipe]
})
// @CanActivate((next, prev) => {
//     let injector: Injector = appInjector();
//     let router: Router = injector.get(Router);

//     if (!isLoggedIn()) {
//         router.navigate(['/LoginPage']);
//         return false;
//     }

//     return true;
// })

export class NewRestaurantComponent implements OnInit {

    @ViewChild('addressInput') public addressInput: ElementRef;
    @ViewChild('map') public map: ElementRef;


    //Radius Range
    delivery_range: number;
    delivery_range_default: number = 5000;
    // Variable para cargar el rango mínimo del radio
    range_min: number;

    // Variable para cargar el rango máximo del radio
    range_max: number;

    // guarda el ,estado del restaurante si activo o inactivo
    statusRestaurant;

    constructor(
        // private _fb: FormBuilder,
        private restaurantService: RestaurantService,
        // private _service: NotificationsService,
        private utils: UtilsComponent,
        //private _loader: MapsAPILoader,
        private router: Router,
        private permissionsUserService: PermissionsUserService,
        // private toastr: ToastrService,
        private localize: LocalizeRouterService,
        // private translate: TranslateService,
        private chRef: ChangeDetectorRef,
        private gMapLoader: MapsAPILoader,
        // private gMapInstance: GoogleMapsAPIWrapper,
        private alertService: AlertsService
    ) {
        this.range_min = Constants.RANGE_MIN_VALUE;
        this.range_max = Constants.RANGE_MAX_VALUE;

        this.statusRestaurant = 1;
    }

    /**
    * Variable que controla si se muestra o no la seccion
    */
    canShowSection: boolean = false;
    renderer: Renderer2;
    /**
    * variable encargada de traer todos los modulos
    */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));


    /**
    * Variable con identificador de la seccion menu
    */
    createRestaurant: any = this.allModules['module_create_restaurants'];

    //Variable para manejo de idioma
    userLang: any;


    invalid: boolean = false;

    restaurant: restaurant;
    public restaurantForm: FormGroup;
    public submitted: boolean;
    public events: any[] = [];
    @Input() saveRestaurant: restaurant;
    @Input() restaurantSaved;
    @Output() close = new EventEmitter();
    error: any;
    navigated = false;

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //varuable Mensaje de Restaurante
    message: string;

    placeSearch: any;
    autocomplete: any;

    city: string;
    state: string;
    country: string;
    postal_code: string;
    route: string;
    street_number: string;
    address: any;
    routet: string;
    street: any;
    naturalAddress: any;
    suitNumber: string;
    formatted_address: any;
    nameComercial: any;

    //variables para el mapa
    lat: number;
    lng: number;
    zoom: number;
    latitude: string;
    longitude: string;

    userFindingCoverage: any;
    userHasCoverage: any;
    // variable para contener el objeto poligono del restaurante,    
    // en este caso un circuo que representa la cobertura del restaurante    
    circleRestaurantCoverage: any;
    // variable que contiene la instancia del mapa    
    gMap: any = null;
    // variable para identificar la posicion del restaurante en formato lanlat de google    
    positionRestaurant: any;

    markerAddressRestaurant: any;

    // variable para desabilitar el boton de guardar mientra se esta enviando el formulario del restaurante
    buttonStatus = false;
    putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }
    //funcion encargada de validar errores al momento de crear un restaurante

    validaRestaurant(errorf): boolean {
        if (errorf.errors) {
            this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
            // this.mensajeErrorAddress();
            this.buttonStatus = false;
        } else {
            this.alertService.successAdd("Restaurant", "SuccessfullyCreated");
            return true;
        }
        return false;
    }

    methodkeyp(event) {
        if (this.utils.methodkey(event)) {
            return false;
        }
    }

    /**
     * funcion encargada de setear la variable de activar o desactivar un restaurante
     */
    actiStatus() {
        if (this.statusRestaurant == 1) {
            this.statusRestaurant = 0;
        } else {
            this.statusRestaurant = 1;
        }
    }

    onSubmit(restaurant: restaurant, isValid: boolean, address: any, phone: string) {

        var phone = phone.replace("(", "");
        var phone = phone.replace(")", "");
        var phone = phone.replace("-", "");

        //Se valida que el campo nombre este lleno
        if (!restaurant.name) {
            this.alertService.AlertGlobalInput("messageErrorName");
            return false;
        }

        //Se valida que el campo address este lleno
        if (!address) {
            this.alertService.AlertGlobalInput("messageErrorAddress");
            return false;
        }

        //Se valida que el campo TELEFONO este lleno
        if (!phone) {
            this.alertService.AlertGlobalInput("messageErrorPhone");
            return false;
        }

        // //Se valida que el campo zipCode
        // if (!restaurant.zipCode) { 
        // this.translate.get(["messageZipcode","messageCityStateCountrySu"]).subscribe(translations => {
        // this.toastr.info(translations.messageCityStateCountrySu, translations.messageZipcode);
        //     return false;          
        //   }); 
        // }

        this.saveRestaurant = restaurant;

        this.saveRestaurant.phone = phone;

        this.saveRestaurant.status = this.statusRestaurant;

        // Modificamos el recargo, por ahora cero hasta que se defina como se va a calcular
        this.saveRestaurant.delivery_charge = 0;

        address = this.utils.cleanString(address);
        if (!this.saveRestaurant.contactPerson) {
            this.saveRestaurant.contactPerson = "";
        }

        if (!this.saveRestaurant.website) {
            this.saveRestaurant.website = "";
        }

        if (!this.saveRestaurant.description) {
            this.saveRestaurant.description = "";
        }

        if (!this.saveRestaurant.suitNumber) {
            this.saveRestaurant.suitNumber = "";
        }

        if (!this.saveRestaurant.urlMenu) {
            this.saveRestaurant.urlMenu = "";
        }
        this.saveRestaurant.is_verified = true;

        this.buttonStatus = true;

        this.restaurantService
            .save(this.saveRestaurant, this.userLang)
            .subscribe(restaurant => {
                if (this.validaRestaurant(restaurant)) {
                    this.buttonStatus = false;
                    this.restaurantSaved = restaurant;
                    setTimeout(() => {
                        this.router.navigate([this.localize.translateRoute('/app/restaurant'), this.restaurantSaved.data.id, 'edit', 'isNew']);
                    }, 500);
                }
            },
                error => {
                    this.buttonStatus = false;
                    this.error = this.extractError(error);
                    this.invalid = true;
                    this.message = this.error.message;
                    setTimeout(() => {
                        this.invalid = false;
                    }, 3000);
                });
    }

    updateCircleRadius = function (val) {
        // Modifica el radio del circulo del rango en el mapa        
        this.circleRestaurantCoverage.setRadius(Number(val));

        // Reajusta el mapa para que se logre ver todo el rango seleccionado por el usuario
        this.gMap.fitBounds(this.circleRestaurantCoverage.getBounds());
    }

    /**
    * Metodo encargado de capturar el error que puede llegar a generarse al crear un usuario
    */
    extractError(res: Response) {
        let body = res.json();
        return body.errors || {};
    }

    /**
     * Metodo encargado de crear el formulario para manejo de la informacion necesaria para crear un
     * nuevo restaurante
     */
    createForm() {

        // let emailRegex = `([a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*)((@[a-zA-Z]{2}[a-zA-Z]*)[\\\.]([a-zA-Z]{2}|[a-zA-Z]{3}))`;
        this.restaurantForm = new FormGroup({
            name: new FormControl('', [<any>Validators.required]),
            phone: new FormControl(''),
            email: new FormControl('', [<any>Validators.pattern(globalRegex.emailRegex)]),
            description: new FormControl(''),
            address: new FormControl(''),
            naturalAddress: new FormControl('', [<any>Validators.required]),
            suitNumber: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            country: new FormControl(''),
            zipCode: new FormControl('', [<any>Validators.required]),
            contactPerson: new FormControl(''),
            website: new FormControl('', [<any>Validators.pattern(globalRegex.urlRegex)]),
            urlMenu: new FormControl('', [<any>Validators.pattern(globalRegex.urlRegex)]),
            street: new FormControl(''),
            route: new FormControl(''),
            is_verified: new FormControl(''),
            delivery_range: new FormControl(100),
            latitude: new FormControl(),
            longitude: new FormControl()

        });

    }

    /*** metodo que se ejecuta cuando el mapa ya esta listo     
     * *     
     * * @param {google.maps.Map} map     
     * */
    onMapReady(map: any) {
        var that = this;

        navigator.geolocation.getCurrentPosition(function (position) {
            that.lat = position.coords.latitude;
            that.lng = position.coords.longitude;
        });

        that.gMap = map;
        that.gMap.setOptions({
            // the user must swipe with one finger to scroll the page and two fingers to pan the map. 
            gestureHandling: 'cooperative'
        });
        that.gMap.setZoom(2);

        // dibujamos la cobertura del usuario                
        that.circleRestaurantCoverage = new google.maps.Circle({
            strokeColor: '#b71e37',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#b71e37',
            fillOpacity: 0.35,
            map: this.gMap,
            //center: this.positionRestaurant,                    
            radius: this.range_min,
            editable: false // con esta linea indicamos que se pueda editar el radio                
        });

        that.markerAddressRestaurant = new google.maps.Marker({
            //center: this.positionRestaurant,
            anchorPoint: new google.maps.Point(0, -29),
            draggable: true,
        });

        /*
        this.markerAddressRestaurant.setIcon(({                    
            size: new google.maps.Size(71, 71),                    
            origin: new google.maps.Point(0, 0),                    
            anchor: new google.maps.Point(17, 34),                    
            scaledSize: new google.maps.Size(35, 35)                
        }));  */

        // indicamos al marcador que sea visible en el mapa                
        that.markerAddressRestaurant.setVisible(true);
        that.markerAddressRestaurant.setMap(that.gMap);
        var that = that;
        that.markerAddressRestaurant.addListener('dragend', function () {
            const position = that.markerAddressRestaurant.getPosition();
            that.circleRestaurantCoverage.setCenter(position);
            that.latitude = position.lat();
            that.longitude = position.lng()

            // funcionalidad que captura la dirección a partir de las coordenadas que se obtienen cuando se mueve el marcador
            var geocoder = new google.maps.Geocoder;
            var latlng = { lat: that.latitude, lng: that.longitude };
            geocoder.geocode({ 'location': latlng }, function (results, status) {
                //   console.log('status', status)
                if (status === 'OK') {
                    if (results[0]) {
                        that.naturalAddress = results[0].formatted_address;
                        var place = results[0];

                        // console.log('hasta aca')
                        that.nameComercial = place.name
                        that.formatted_address = place.formatted_address.split(',');
                        that.street = that.formatted_address[0];
                        that.country = that.formatted_address[3];

                        // this.getPlaceMaps(place);
                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i];
                            //   console.log('addressType', addressType)

                            if (addressType.types[0] == 'locality' || addressType.types[0] == 'administrative_area_level_2') {
                                that.city = addressType.long_name;
                            }
                            if (addressType.types[0] == 'administrative_area_level_1') {
                                that.state = addressType.long_name;
                            }
                            //   if (addressType.types[0] == 'country') {
                            //     that.country = addressType.long_name;
                            //   }
                            if (addressType.types[0] == 'route') {
                                that.routet = addressType.long_name;
                            }
                            if (addressType.types[0] == 'street_number') {
                                that.street_number = addressType.long_name;
                            }
                            if (addressType.types[0] == 'postal_code') {
                                that.postal_code = addressType.long_name
                            }
                            //   if (that.routet != undefined || that.routet != ' ') {
                            //     that.street = that.routet;
                            //   } else if (that.street_number != undefined) {
                            //     that.street = that.street_number;
                            //   }
                            //   if (that.routet != undefined && that.street_number != undefined) {
                            //     that.routet = ' ' +that.routet;
                            //     that.street =  that.street_number + that.routet;
                            //   }              
                        }
                        that.chRef.detectChanges();
                    }
                }
            });
        });

        setTimeout(function () {
            //that.gMap.setZoom(15);
            //that.markerAddressRestaurant.setPosition(that.positionRestaurant);
            // that.gMap.panTo(that.positionRestaurant);
            //that.circleRestaurantCoverage.setCenter(that.positionRestaurant);   
        }, 1000);

        var geoLocationUser: any;

        this.restaurantService
            .getGeolocationUser()
            .then(geoLocation => {
                setTimeout(function () {
                    geoLocationUser = geoLocation;
                    //that.gMap.setZoom(15);
                    that.markerAddressRestaurant.setPosition(geoLocationUser.location);
                    that.circleRestaurantCoverage.setCenter(geoLocationUser.location);
                    //that.gMap.setCenter(geoLocationUser.location);
                    that.circleRestaurantCoverage.setRadius(Number(that.delivery_range));
                    that.gMap.fitBounds(that.circleRestaurantCoverage.getBounds());

                }, 5000);

            })
            .catch(error => this.error = error);

    }

    ngOnInit() {
        var that = this;
        this.userLang = navigator.language.split('-')[0];

        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.createRestaurant);

        setTimeout(() => {
            if (this.canShowSection) {
                this.delivery_range = this.delivery_range_default;
                // autocompletador de google
                const inputAddress = this.addressInput.nativeElement;
                that.gMapLoader.load().then(() => {

                    const options = {
                        types: ['address']
                    };

                    const autocomplete = new google.maps.places.Autocomplete(inputAddress, options);

                    //variable que obtiene el array con datos del autocomplete google
                    google.maps.event.addListener(autocomplete, 'place_changed', function () {

                        var place = autocomplete.getPlace();

                        that.nameComercial = place.name
                        that.formatted_address = place.formatted_address.split(',');

                        google.maps.event.trigger(this.gMap, 'resize');
                        that.city = '';
                        that.state = '';
                        that.country = '';
                        that.postal_code = '';

                        that.routet = '';
                        that.street = that.formatted_address[0];
                        // that.country = that.formatted_address[3];
                        that.street_number = '';

                        // console.log('tamaño', place.address_components.length)
                        // Recibe cada componente de la dirección de los lugares más detalles
                        // y llena el campo correspondiente en el formulario.
                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i];

                            if (addressType.types[0] == 'locality' && addressType.types[1] == 'political') {
                                that.city = addressType.long_name;
                            }
                            if (addressType.types[0] == 'administrative_area_level_1') {
                                that.state = addressType.long_name;
                            }
                            if (addressType.types[0] == 'country') {
                                that.country = addressType.long_name;
                            }
                            if (addressType.types[0] == 'route') {
                                that.routet = addressType.short_name;
                            }
                            if (addressType.types[0] == 'street_number') {
                                that.street_number = addressType.short_name;
                            }
                            if (addressType.types[0] == 'postal_code') {
                                that.postal_code = addressType.long_name;
                            }

                            // if (that.routet != undefined) {
                            //     that.street = that.routet;
                            // } else if (that.street_number != undefined) {
                            //     that.street = that.street_number;
                            // }
                            // if (that.routet != undefined && that.street_number != undefined) {
                            //     that.routet = ' ' + that.routet;
                            //     that.street =  that.street_number + that.routet ;
                            // }                         
                        }

                        // if(that.city == '' || that.state == '' || 
                        // that.country == '' || that.routet == '' || 
                        // that.street_number == '' || that.postal_code == ''){

                        //     that.mensajeErrorAddress(); 
                        //     that.newRestaurantError();  

                        // var body = $("html, body");
                        // body.stop().animate({scrollTop:200}, 200, 'swing');

                        // }


                        /* if(this.gMap != null){                    
                            autocomplete.bindTo('bounds', this.gMap);                
                        }*/
                        // escuchamos cuando el usuario haya seleccionado una dirección  
                        // validamos que el usuario haya digitado una direccion                
                        if (!place.geometry) {
                            // reseteamos mapa                    
                            this.circleRestaurantCoverage.setMap(null);
                            this.markerAddressRestaurant.setMap(null);
                            this.gMap.setCenter(this.positionRestaurant);


                            // User entered the name of a Place that was not suggested and                    
                            // pressed the Enter key, or the Place Details request failed.                    
                            //window.alert("No details available for input: '" + place.name + "'");return;  
                        }
                        // this.gMap.setZoom(12);  // Why 17? Because it looks good.                

                        /* @type {google.maps.Icon} */

                        // colocamos el marcador en la posicion de la direccion que indico el usuario               

                        //that.gMap.setZoom(15);  
                        that.markerAddressRestaurant.setPosition(place.geometry.location);

                        const position = that.markerAddressRestaurant.getPosition();
                        // that.circleRestaurantCoverage.setCenter(position);
                        that.latitude = position.lat();
                        that.longitude = position.lng();

                        that.gMap.setCenter(place.geometry.location);
                        that.circleRestaurantCoverage.setCenter(place.geometry.location);
                        that.gMap.fitBounds(that.circleRestaurantCoverage.getBounds());

                    });
                    that.chRef.detectChanges();
                });
            }
        }, 100);
    }

    goBack(restaurant: restaurant = null) {
        this.close.emit(restaurant);
        window.history.back();
    }

    gotoRestaurant() {
        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }

}