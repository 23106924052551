<div *ngIf="detailRestaurant">
  <h1 class="page-title">{{detailRestaurant.name}}
    <small> {{ 'EditRestaurant' | translate }}</small>
  </h1>
</div>

<div class="row">
  <div class="col-lg-12 col-xs-12">
    <section widget class="widget">
      <header class="flx">
        <div class="cien text-right pull-right">
          <!-- <div class="slideThree">
            <input class="inputStatus" type="checkbox" value="1" id="slideThree" name="status" [checked]="detailRestaurant.status"
                (click)="actiRest(null, true)" />
              <label for="slideThree"></label> -->
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 0}" (click)="actiRest(0)">
              <input type="radio" name="radiogroup" id="option1" [value]="0" [(ngModel)]="detailRestaurant.status">
              {{ 'Inactive' | translate }}
            </label>
            <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 2}" (click)="actiRest(2)">
              <input type="radio" name="radiogroup" id="option2" [value]="2" [(ngModel)]="detailRestaurant.status">
              {{ 'NOT_AVAILABLE' | translate }}
            </label>
            <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.status === 1}" (click)="actiRest(1)">
              <input type="radio" name="radiogroup" id="option3" [value]="1" [(ngModel)]="detailRestaurant.status">
              {{ 'Active' | translate }}
            </label>
          </div>
          <!-- </div> -->
          <div class="col-md-1 text-right pull-right">
            <button *ngIf="detailRestaurant.isFromLevel == 2  " class="btn btn-secondary" role="button"
              title="{{ 'SYNC_UP' | translate }}" (click)="syncLevel(detailRestaurant.levelDatabaseNickname)">
              <i class="fa fa-cloud-upload text-danger"></i>
            </button>
          </div>
        </div>

      </header>

      <div class="widget-body">
        <div class="row mb-lg">
          <div *ngIf="canEditSection" class="col-lg-12 col-xs-12">

            <ngb-tabset #ngbtabset (tabChange)="onTabChange($event)">
              <ngb-tab title="{{ 'DetailsRestaurant' | translate }}" id="tab-selectbyid1">
                <ng-template ngbTabContent>
                  <form class="form-horizontal" [formGroup]="restaurantForm" novalidate (ngSubmit)="onSubmit(restaurantForm.value, restaurantForm.valid, addressInput.value, phone.value,
                    email.value )" (keypress)="methodkeyp($event)">
                    <div class="flx">
                      <a class="btn btn-gray mb-xs nomar" (click)="goBack()">
                        <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                      </a>
                      <button id="saveBtn" type="submit" class="btn btn-success mb-xs nomar" [hidden]="buttonStatus">{{
                        'Save' | translate }}
                      </button>
                      <button class="btn btn-gray flx" [hidden]="!buttonStatus"
                        [disabled]="buttonStatus">{{ 'processing' | translate }}&nbsp;
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </button>
                    </div>



                    <label>{{ 'RequiredFields' | translate }} (
                      <b class="reqe">*</b>)</label>
                    <div *ngIf="invalid">
                    </div>
                    <div class="row">
                      <input type="hidden" id="id" [(ngModel)]="detailRestaurant.id" formControlName="id">
                      <div class="col-md-4 col-sm-12 col-xs-12">
                        <fieldset class="scheduler-border">
                          <legend class="scheduler-border">{{ 'RestaurantInformation' | translate }}</legend>
                          <div class="col-md-12 col-sm-12 col-xs-12">

                            <div class="form-group">
                              <label>{{ 'Name' | translate }}
                                <b class="reqe">*</b>
                              </label>
                              <input [(ngModel)]="detailRestaurant.name" type="text" class="form-control"
                                placeholder="{{ 'Name' | translate }}" required="required" formControlName="name">
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">

                            <div class="form-group">
                              <label>{{ 'Phone' | translate }}
                                <b class="reqe">*</b>
                              </label>
                              <input #phone data-mask="(999)999-9999" [(ngModel)]="phoneRestaurant" type="text"
                                class="form-control" placeholder="{{ 'Phone' | translate }}" formControlName="phone">
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <label>{{ 'Website' | translate }} </label>
                              <input [(ngModel)]="detailRestaurant.website" type="text" class="form-control"
                                placeholder="http://www.example.com/" formControlName="website">
                              <div
                                *ngIf="!restaurantForm.controls['website'].valid && !restaurantForm.controls['website'].pristine"
                                class="alert alert-sm alert-danger">{{ 'Invalid URL' | translate }}</div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <label>{{ 'UrlMenu' | translate }} </label>
                              <input [(ngModel)]="detailRestaurant.urlMenu" type="text" class="form-control"
                                placeholder="http://www.example.com/menu" formControlName="urlMenu">
                              <div
                                *ngIf="!restaurantForm.controls['urlMenu'].valid && !restaurantForm.controls['urlMenu'].pristine"
                                class="alert alert-sm alert-danger">{{ 'Invalid URL' | translate }}</div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <label>{{ 'Description' | translate }}</label>
                              <textarea [(ngModel)]="detailRestaurant.description" class="form-control"
                                placeholder="{{ 'Description' | translate }}" formControlName="description"
                                rows="10"></textarea>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset class="scheduler-border">
                          <legend class="scheduler-border">{{ 'Contact' | translate }}</legend>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <label (click)="loadGeo()"> {{ 'ContactPerson' | translate }}</label>
                              <input [(ngModel)]="detailRestaurant.contactPerson" type="text" class="form-control"
                                placeholder="{{ 'ContactPerson' | translate }}" formControlName="contactPerson">
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <label>{{ 'Email' | translate }}</label>
                              <input #email [(ngModel)]="emailRestaurant" type="email" class="form-control"
                                placeholder="{{ 'Email' | translate }}" formControlName="email">
                              <div
                                *ngIf="!restaurantForm.controls['email'].valid && !restaurantForm.controls['email'].pristine"
                                class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div class="col-md-8 col-sm-12 col-xs-12">
                        <fieldset class="scheduler-border">
                          <legend class="scheduler-border"> {{ 'Location' | translate }} </legend>
                          <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              <div class="form-group">
                                <label>{{ 'PUBLIC_ADDRESS' | translate }}</label><br/> 
                                <span> {{ 'PUBLIC_ADDRESS_DESCRIPTION' | translate }} </span><br/> 
                                <input [(ngModel)]="publicAddress" type="text" class="form-control"
                                  placeholder="{{ 'PUBLIC_ADDRESS_TO_SHOW' | translate }}" formControlName="publicAddress">
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <div class="form-group">
                                <label>{{ 'CityStateCountry' | translate }}
                                  <b class="reqe">*</b>
                                </label>
                                <input id="naturalAddress" #addressInput [(ngModel)]="naturalAddress" type="text"
                                  class="form-control" placeholder="{{ 'CityStateCountry' | translate }}"
                                  required=required formControlName="naturalAddress">

                                <input type="hidden" [(ngModel)]="city" formControlName="city" placeholder="city">
                                <input type="hidden" [(ngModel)]="state" formControlName="state" placeholder="state">
                                <input type="hidden" [(ngModel)]="country" formControlName="country"
                                  placeholder="country">
                                <input type="hidden" [(ngModel)]="street" formControlName="street" placeholder="street">
                                <input type="hidden" [(ngModel)]="postal_code" formControlName="zipCode"
                                  placeholder="postal_code">
                                <input type="hidden" [(ngModel)]="latitud" formControlName="latitude"
                                  placeholder="latitude">
                                <input type="hidden" [(ngModel)]="longitud" formControlName="longitude"
                                  placeholder="longitude">
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <div class="form-group">
                                <label>{{ 'SuitNumber' | translate }}</label>
                                <input [(ngModel)]="suitNumber" type="text" class="form-control"
                                  placeholder="{{ 'SuitNumber' | translate }}" formControlName="suitNumber">
                              </div>
                            </div>
                          </div>
                          <label *ngIf="naturalAddress">
                            <span>{{ street }}
                              <br> </span>
                            <span *ngIf="suitNumber">{{ suitNumber}}
                              <br> </span>
                            <span>{{ city }} {{ state }} {{ postal_code }}
                              <br>
                            </span>
                            <span>{{ country }} </span>
                          </label>
                        </fieldset>
                        <div class="row">

                          <!--<input [(ngModel)]="distance" id="distance" #range formControlName="distance" type="range" name="range" min="1" max="50"
                                                                  onchange="distance.value=value">-->
                          <div class=" col-md-12 col-sm-12 col-xs-12">
                            <fieldset class="scheduler-border">
                              <legend class="scheduler-border">{{ 'Map' | translate }}</legend>
                              <div class="form-group">
                                <label>{{ 'distanceMap' | translate }}
                                  <strong>{{ delivery_range | distances }}</strong> {{ 'MILES' | translate}}</label>
                                <input class="form-control slider" [(ngModel)]="delivery_range" type="range"
                                  formControlName="delivery_range" min="{{range_min}}" max="{{range_max}}"
                                  (change)="updateCircleRadius(delivery_range)" [value]="delivery_range"/>
                              </div>

                              <label>{{ 'DragAndDropArea' | translate }} </label>
                              <i style="color:red;" class="fa fa-map-marker"></i>
                              <agm-map *ngIf="positionRestaurant" (mapReady)="onMapReady($event)">
                              </agm-map>
                            </fieldset>
                          </div>
                        </div>

                      </div>

                    </div>

                  </form>
                </ng-template>
              </ngb-tab>
              <!-- services -->
              <ngb-tab title="{{ 'Services' | translate }}" id="tab-selectbyid2">
                <ng-template ngbTabContent>
                  <p class="text-xs-right">
                    <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                      <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                    </a>
                  </p>
                  <!-- <alert type="warning">{{infoServices}}</alert>                                            -->

                  <div *ngIf="errorService">
                    <div class="alert alert-danger alert-dismissable nlblk">
                      <button type="button" class="close" data-dismiss="alert"
                        aria-hidden="true">&times;</button>{{msgErrorService}}</div>
                  </div>
                  <!-- Cuisines -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <h4>{{ 'Cuisines' | translate }}
                        <b class="reqe">*</b>
                      </h4>
                    </div>

                    <div class="col-md-3 col-xs-12">
                      <select name="cuisines" class="form-control" (change)="addCuisine($event.target.value)">
                        <option>{{ 'Select' | translate }}</option>
                        <option *ngFor="let item of cuisines | orderBy:'name'" [value]="item.id">
                          <div>
                            {{item.name | translate}}
                          </div>
                        </option>
                      </select>
                    </div>
                    <div class="col-md-9 col-xs-12 row">
                      <div class="cntrV" *ngFor="let cuisines of servicesSelected.Cuisine">
                        <div>
                          <label>{{cuisines.name | translate}}</label>
                          <input type="checkbox" [checked]="true" value="{{cuisines.id}}"
                            (change)="deleteCuisine($event.target.value)">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Meals -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <h4>{{ 'MealsServed' | translate }}
                        <b class="reqe">*</b>
                      </h4>
                    </div>

                    <div class="col-md-3 col-xs-12">
                      <select name="meals" class="form-control" (change)="addMeal($event.target.value)">
                        <option>{{ 'Select' | translate }}</option>
                        <option *ngFor="let item of meals | orderBy:'order' " value="{{item.id}}">
                          <div>
                            {{item.name | translate}}
                          </div>
                        </option>
                      </select>
                    </div>
                    <div class="col-md-9 col-xs-12 row">
                      <div class="cntrV" *ngFor="let meals of servicesSelected.Meal">
                        <div>
                          <label>{{meals.name | translate}}</label>
                          <input type="checkbox" [checked]="true" value="{{meals.id}}"
                            (change)="deleteMeal($event.target.value)">
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- Features -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <h4>{{ 'Features' | translate }}
                        <b class="reqe">*</b>
                      </h4>
                    </div>
                    <div class="col-md-3 col-xs-12">
                      <label style="color: #9098ab">{{ 'MAX_FEATURES' | translate }}</label>
                      <select name="features" class="form-control" (change)="addFeature($event.target.value)">
                        <option>{{ 'Select' | translate }}</option>
                        <option *ngFor="let item of features| orderBy:'name'; let i = index" value="{{item.id}}">
                          <div>
                            {{item.name | translate}}
                          </div>
                        </option>
                      </select>
                    </div>
                    <div class="col-md-9 col-xs-12 row">
                      <div class="cntrV" *ngFor="let features of servicesSelected.Feature">
                        <div>
                          <label>{{features.name | translate}}</label>
                          <input type="checkbox" [checked]="true" value="{{features.id}}"
                            (change)="deleteFeature($event.target.value)">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>




              <!-- images -->
              <ngb-tab title="{{ 'Images' | translate }}">
                <ng-template ngbTabContent>
                  <p class="text-xs-right">
                    <!-- <a class="btn btn-gray mb-xs" [routerLink]="['app/restaurant']"> -->
                    <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                      <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                    </a>
                  </p>

                  <!-- imagen logo -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <label class="labelOpen">{{ 'Logo' | translate }}
                        <b class="reqe">*</b>
                      </label>
                      <button *ngIf="imagesRestaurant[logo].length > 0" (click)="openWindow();"
                        class="btn btn-success mb-xs " role="button" title="{{ 'webPreview' | translate }}">
                        <i class="fa fa-external-link"></i> {{ 'webPreview' | translate }}
                      </button>
                      <br>
                      <label style="color: #9098ab">{{ 'LOGO_MINIMUN_DIMENSION' | translate: {'height':
                        IMAGE_LOGO_MIN_HEIGHT, 'width': IMAGE_LOGO_MIN_WIDTH} }} </label>
                      <div class="form-group" *ngIf="!isCategoryLogo">

                        <button *ngIf="imagesRestaurant[logo].length == 0" class="btn btn-success mb-xs pull-right"
                          role="button" title="{{ 'Add' | translate }}" (click)="addImageModal(modalAddImage,logo)">
                          <i class="fa fa-plus"></i> {{ 'Add' | translate }}
                        </button>

                      </div>
                    </div>
                  </div>

                  <div *ngIf="imagesRestaurant[logo].length > 0">
                    <ul class="thmb row">
                      <span *ngFor="let image of imagesRestaurant[logo]">
                        <li class="col-md-12">
                          <span class="thumb-xlg thumbnail thumbnail-logo">
                            <h4 *ngIf="!image.title">OpenMyTab</h4>
                            <h4 *ngIf="image.title">{{image.title}}</h4>

                            <img class="cursorPoint" [src]="getImagePathThumbnail(image)" alt="{{image.title}}"
                              (click)="showImagesRestaurant(image, showImagesResaturant)">
                          </span>
                          <button role="button" class="btn btn-danger" title="{{ 'Delete' | translate }}"
                            (click)="confirmDelete(image, 'image')">
                            <i class="fa fa-eraser"></i>
                          </button>
                          <!-- <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editImageModal(modalAddImage, image, logo)"><i class="fa fa-edit"></i></button> -->
                        </li>
                      </span>
                    </ul>
                  </div>

                  <!-- main image of the restaurant -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <label class="labelOpen">{{ 'MainImage' | translate }}
                        <b class="reqe">*</b>
                      </label>
                      <button *ngIf="imagesRestaurant[main].length > 0" (click)="openWindowName();"
                        class="btn btn-success mb-xs" role="button" title="{{ 'webPreview' | translate }}">
                        <i class="fa fa-external-link"></i> {{ 'webPreview' | translate }}
                      </button>
                      <br>
                      <label style="color: #9098ab">{{ 'MainImage_req' | translate }} </label>
                      <div class="form-group" *ngIf="!isCategoryLogo">
                        <button *ngIf="imagesRestaurant[main].length == 0" class="btn btn-success mb-xs pull-right"
                          role="button" title="{{ 'Add' | translate }}" (click)="addImageModal(modalAddImage,main)">
                          <i class="fa fa-plus"></i> {{ 'Add' | translate }}
                        </button>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="imagesRestaurant[main].length > 0">
                    <ul class="thmb row">
                      <span *ngFor="let image of imagesRestaurant[main]">
                        <li class="col-md-12 mainThmb">
                          <span class="thumb-xlg thumbnail">
                            <h4 *ngIf="!image.title">OpenMyTab</h4>
                            <h4 *ngIf="image.title">{{image.title}}</h4>
                            <img class="cursorPoint" [src]="getImagePathThumbnail(image)" alt="{{image.title}}"
                              (click)="showImagesRestaurant(image, showImagesResaturant)">
                          </span>
                          <button role="button" class="btn btn-danger" title="{{ 'Delete' | translate }}"
                            (click)="confirmDelete(image, 'image')">
                            <i class="fa fa-eraser"></i>
                          </button>
                          <!-- <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editImageModal(modalAddImage, image, main)"><i class="fa fa-edit"></i></button> -->
                        </li>
                      </span>
                    </ul>
                  </div>

                  <!-- slider images -->
                  <div class="form-group row alert alert-default">
                    <div class="col-lg-12 col-xs-12">
                      <label class="labelOpen">{{ 'Slider' | translate }}
                        <b class="reqe">*</b>
                      </label>
                      <button *ngIf="imagesRestaurant[slider].length > 0" (click)="openWindow();"
                        class="btn btn-success mb-xs" role="button" title="{{ 'webPreview' | translate }}">
                        <i class="fa fa-external-link"></i> {{ 'webPreview' | translate }}
                      </button>
                      <!-- <div class="form-group" *ngIf="!isFromLevel"> -->
                      <button class="btn btn-success mb-xs pull-right" role="button" title="{{ 'Add' | translate }}"
                        (click)="addImageModal(modalAddImage, slider)">
                        <i class="fa fa-plus"></i> {{ 'Add' | translate }}
                      </button>
                      <br>
                      <label style="color: #9098ab">{{ 'Slider_req' | translate }} </label>
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="form-group row alert alert-default">
                    <div class="cien">
                      <ul class="thmb">
                        <li class="col-md-12" *ngFor="let image of imagesRestaurant[slider]">
                          <span class="thumb-xlg thumbnail">
                            <h4 *ngIf="!image.title">OpenMyTab</h4>
                            <h4 *ngIf="image.title">{{image.title}}</h4>

                            <img class="cursorPoint" [src]="getImagePathThumbnail(image)" alt="{{image.title}}"
                              (click)="showImagesRestaurant(image, showImagesResaturant)">
                          </span>
                          <button role="button" class="btn btn-danger" title="{{ 'Delete' | translate }}"
                            (click)="confirmDelete(image, 'image')">
                            <i class="fa fa-eraser"></i>
                          </button>
                          <!-- <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editImageModal(modalAddImage, image, tablesBar)"><i class="fa fa-edit"></i></button> -->
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
              <!-- START TAB HOURS -->
              <ngb-tab title="{{ 'OpeningDates' | translate }}">
                <ng-template ngbTabContent>
                  <div class="row">
                    
                    <div class="text-xs-right col-md-8">
                      <!-- <a class="btn btn-gray mb-xs" [routerLink]="['app/restaurant']"> -->
                      <a class="btn btn-gray mb-xs" (click)="gotoRestaurant()">
                        <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                      </a>
                      <button class="btn btn-success mb-xs" role="button" title="{{ 'AddSeason' | translate }}"
                        (click)="addOpenDateModal()">
                        <i class="fa fa-plus text-success"></i> {{ 'AddSeason' | translate }}
                      </button>
                      
                    </div>
                    <div class="text-right col-md-4">
                        

                       <!-- Toggle abrir cerrar restaurante -->
                      <div class="btn-group btn-group-toggle">
                        <label class="btn btn-secondary" [ngClass]="{'active':detailRestaurant.restClosed  === 0}">
                          <input type="radio" name="radiogroup" (click)="closeOrOpenRestaurant(0)">
                          {{ 'OPEN' | translate }}
                        </label>
                        <label class="btn btn-secondary" [ngClass]="{'active': detailRestaurant.restClosed  === 1}">
                          <input type="radio" name="radiogroup"  (click)="openModalRestaurantClose()">
                          {{ 'Close' | translate }}
                        </label>
                      </div>
                      <!-- Toggle abrir cerrar restaurante -->

                    
                    </div>
                  </div>
                  <header>
                    <h4>
                      {{ 'OpeningDates' | translate }}
                    </h4>
                  </header>

                  <div class="form-group">
                    <div *ngIf="openingDate">
                      <div *ngIf="openingDate.length <=0">
                        <alert type="danger">{{'NoDataToShow' | translate }}</alert>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table journals">
                        <thead>
                          <tr>
                            <th>{{ 'Name' | translate }}</th>
                            <th>{{ 'Actions' | translate }}</th>
                            <th>{{ 'Restaurant' | translate }}</th>
                            <th>{{ 'Delivery' | translate }}</th>
                            <th>{{ 'Hours' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="openingDate1">
                          <tr *ngFor="let item of openingDate1.openingDates">

                            <td>{{item.name}} ({{item.startDate | date:'MMM d, y'}} -
                              {{item.endDate | date:'MMM d, y'}})</td>
                            <td>
                              <button class="btn btn-secondary mb-xs" role="button"
                                title="{{ 'ShowDetails' | translate }}" (click)="showOpeningDate(item)">
                                <i class="fa fa-eye text-info"></i> {{ 'Details' | translate }}</button>
                              <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Edit' | translate }}"
                                (click)="addOpenDateModal(item, true)">
                                <i class="fa fa-pencil-square-o text-warning"></i> {{ 'Edit' | translate }}</button>
                              <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                (click)="confirmDelete(item, 'season')">
                                <i class="fa fa-eraser text-danger"></i> {{ 'Delete' | translate }}</button>
                            </td>
                            <td class="txtcntr">
                              {{item.dineIn}}
                            </td>
                            <td class="txtcntr">
                              {{item.delivery}}
                            </td>
                            <td>
                              <button class="btn btn-secondary mb-xs" role="button" title="{{ 'AddHours' | translate }}"
                                (click)="addHoursModal(item)">
                                <i class="fa fa-calendar text-success"></i> {{ 'AddHour' | translate }}</button>
                              <button class="btn btn-secondary mb-xs" role="button"
                                title="{{ 'ShowHours' | translate }}" (click)="showDetailsHour(item)">
                                <i class="fa fa-eye text-info"></i> {{ 'ShowHours' | translate }}</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
              <!-- END TAB HOURS -->
              <!-- Menu  -->
              <ngb-tab title="{{ 'Menu' | translate }}">
                <ng-template ngbTabContent>
                  <category-menu [idMenuByComponent]="idMenuR" [idRestaurantByComponent]="idRestaurant"></category-menu>
                </ng-template>
              </ngb-tab>
              <!-- Conguration tab -->
              <ngb-tab title="{{ 'Configuration' | translate }}">
                <ng-template ngbTabContent>
                  <config-component [restaurant]="detailRestaurant"></config-component>
                </ng-template>
              </ngb-tab>

              <!-- Reviews tab -->
              <ngb-tab title="{{ 'Reviews' | translate }}">
                <ng-template ngbTabContent>
                  <app-restaurant-review-component [idRestaurant]="idRestaurant"></app-restaurant-review-component>
                </ng-template>
              </ngb-tab>
              <!-- Orders tab -->
              <ngb-tab title="{{ 'Orders' | translate }}">
                <ng-template ngbTabContent>
                  <app-orders-restaurant>
                  </app-orders-restaurant>
                </ng-template>
              </ngb-tab>
              <ngb-tab title="{{ 'REPORTS' | translate }}">
                <ng-template ngbTabContent>
                  <app-report-user-clicks>
                  </app-report-user-clicks>
                </ng-template>
              </ngb-tab>
              <ngb-tab title="{{ 'NOTIFICATIONS' | translate }}">
                <ng-template ngbTabContent>
                  <app-notifications [detailRestaurant]="detailRestaurant">
                  </app-notifications>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>
        </div>

        <!-- Google Maps -->
        <div *ngIf="activeMap && canEditMap">
          <table class="table table-striped table-hover tablesorter">
            <thead>
              <th colspan="2" style="text-align:center">{{ 'Localization' | translate }}</th>
            </thead>
            <tbody>
            </tbody>
          </table>

        </div>

        <!-- Modal Add/Edit Opening Dates -->

        <div bsModal #addOpenDate="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                  {{ 'Season' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="errorSeason">
                  <div class="alert alert-danger alert-dismissable nlblk">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <strong>{{msgErrorSeason}}</strong>
                  </div>
                </div>

                <form class="form-horizontal" [formGroup]="openingDateForm" novalidate>
                  <div *ngIf="selectedOpenDate">

                    <input type="hidden" [(ngModel)]="selectedOpenDate.id" formControlName="id">

                    <div class="form-group">
                      <label>{{ 'Name' | translate }}</label>
                      <input [(ngModel)]="selectedOpenDate.name" formControlName="name" type="text" class="form-control"
                        placeholder="{{ 'Name' | translate }}">
                    </div>

                    <div class="form-group row">
                      <div class="col-md-6 col-xs-12">
                        <label>{{ 'StartDate' | translate }}</label>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <label>{{ 'EndDate' | translate }}</label>
                      </div>

                      <!-- StartDate 
                                                <div class="col-md-3 col-xs-6">
                                                    <label>{{ 'Month' | translate }}</label>
                                                    <div *ngIf="userLang == 'es'">
                                                        <select name="monthsStartDate" id="monthsSd" class="form-control" (change)="monthSelected($event.target.value, 'sd')">
                                                            <option value="">{{ 'Select' | translate }}</option>
                                                            <option *ngFor="let item of monthsEs; let i = index" value="{{i + 1}}">
                                                                {{item}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="userLang != 'es'">
                                                        <select name="monthsStartDate" id="monthsSd" class="form-control" (change)="monthSelected($event.target.value, 'sd')">
                                                            <option value="">{{ 'Select' | translate }}</option>
                                                            <option  *ngFor="let item of monthsEn; let i = index" value="{{i + 1}}">
                                                                {{item}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
    
                                                <div class="col-md-3 col-xs-6">
                                                    <label>{{ 'Day' | translate }}</label>
                                                    <select name="daysStartDate" id="daysSd" class="form-control" (change)="daySelected($event.target.value, 'sd')">
                                                        <option value="">{{ 'Select' | translate }}</option>
                                                        <option *ngFor="let item of daysInMonthSd; let i = index" value="{{item}}">
                                                            {{item}}
                                                        </option>
                                                    </select>
                                                </div>-->
                      <div style="display: flex;" class="col-xs-6 col-6 col-md-6 form-group">
                        <input type="text" class="form-control" id="bsStartDate" placeholder="Datepicker"
                          [minDate]="minDate" [maxDate]="maxDate" #dp1="bsDatepicker" bsDatepicker [(bsValue)]="bsValue"
                          disabled="disabled" [bsConfig]="bsConfig">
                        <i style="cursor:pointer;color: #28a745" (click)="dp1.toggle()"
                          class="fa fa-calendar fa-2x ktl-1"></i>

                      </div>


                      <!-- EndDate 
                                                <div class="col-md-3 col-xs-6">
                                                    <label>{{ 'Month' | translate }}</label>
                                                    <div *ngIf="userLang == 'es'">
                                                        <select name="monthsEndDate" id="monthsEd" class="form-control" (change)="monthSelected($event.target.value, 'ed')">
                                                            <option value="">{{ 'Select' | translate }}</option>
                                                            <option  *ngFor="let item of monthsEs; let i = index" value="{{i + 1}}">
                                                                {{item}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="userLang != 'es'">
                                                        <select name="monthsEndDate" id="monthsEd" class="form-control" (change)="monthSelected($event.target.value, 'ed')">
                                                        <option value="">{{ 'Select' | translate }}</option>
                                                        <option  *ngFor="let item of monthsEn; let i = index" value="{{i + 1}}">
                                                            {{item}}
                                                        </option>
                                                    </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-xs-6">
                                                    <label>{{ 'Day' | translate }}</label>
                                                    <select name="daysEndDate" id="daysEd" class="form-control" (change)="daySelected($event.target.value, 'ed')">
                                                        <option value="">{{ 'Select' | translate }}</option>
                                                        <option *ngFor="let item of daysInMonthEd; let i = index" value="{{item}}">
                                                            {{item}}
                                                        </option>
                                                    </select>
                                                </div>-->
                      <div style="display: flex;" class="col-xs-6 col-6 col-md-6 form-group">
                        <input type="text" class="form-control" placeholder="Datepicker" id="bsEndDate"
                          [minDate]="minDate" [maxDate]="maxDate" #dp2="bsDatepicker" disabled="disabled" bsDatepicker
                          [(bsValue)]="bsValue2" [bsConfig]="bsConfig">
                        <i style="cursor:pointer; color: #28a745" (click)="dp2.toggle()"
                          class="fa fa-calendar fa-2x ktl-2"></i>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>{{ 'Description' | translate }}</label>
                      <textarea [(ngModel)]="selectedOpenDate.description" formControlName="description"
                        class="form-control" placeholder="{{ 'Description' | translate }}"></textarea>
                    </div>

                    <div class="flx">
                      <button id="saveSeasonBtn" type="submit" [disabled]="!openingDateForm.valid"
                        (click)="onSubmitOpeningDate(openingDateForm.value, openingDateForm.valid)"
                        class="btn btn-success mb-xs" [hidden]="processingResponse">{{ 'Save' | translate }} </button>
                      <div>
                        <button class="btn btn-gray flx" [hidden]="!processingResponse"
                          [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                          <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Show Opening Dates -->

        <div bsModal #showOpenDate="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                  {{ 'Season' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideshowOpenDate()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="displayOpenDate">
                  <div class="row">
                    <div class="col-lg-12 col-xs-12">
                      <table class="table table-striped table-hover tablesorter">
                        <thead>
                        </thead>
                        <tbody>
                          <tr>
                            <th colspan="2">{{ 'Name' | translate }}</th>
                            <td colspan="2">{{ displayOpenDate.name }}</td>
                          </tr>
                          <tr>
                            <th colspan="2">{{ 'StartDate' | translate }}</th>
                            <td colspan="2">{{ displayOpenDate.startDate | date:'MMM d, y' }}</td>
                          </tr>
                          <tr>
                            <th colspan="2">{{ 'EndDate' | translate }}</th>
                            <td colspan="2">{{ displayOpenDate.endDate | date:'MMM d, y' }}</td>
                          </tr>
                          <tr>
                            <th colspan="2">{{ 'Description' | translate }}</th>
                            <td colspan="2">{{ displayOpenDate.description }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add/Edit Hours-->

        <div bsModal #addHours="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <div *ngIf="displayOpenDate">
                  <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                    {{ 'Hours' | translate }} - {{displayOpenDate.name}}
                  </h4>
                  <p class="text-xs-center">
                    ( {{displayOpenDate.startDate | date:'MMM d, y' }} - {{displayOpenDate.endDate | date:'MMM d, y' }}
                    )
                  </p>
                </div>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideAddHoursModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <form class="form-horizontal" [formGroup]="hoursForm" novalidate>
                  <div *ngIf="selectedHour">
                    <input [(ngModel)]="selectedHour.id" type="hidden" formControlName="id">

                    <div class="form-group row">
                      <div class="col-md-8 col-xs-12 col-md-offset-2">
                        <label>{{ 'AttentionType' | translate }}
                          <b class="reqe">*</b>
                        </label>
                        <select id="attentionType" [(ngModel)]="selectedHour.attention_type"
                          formControlName="attention_type" #attentionType name="attentionType" class="form-control">
                          <option value="" disabled>{{ 'Select' | translate }}</option>
                          <option id="1" value="1"> {{ 'DineIn' | translate }} </option>
                          <option id="2" value="2"> {{ 'Delivery' | translate }} </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <label>{{ 'Select' | translate }} {{ 'Day' | translate }}</label>
                    </div>
                    <div class="form-group dadjournal">
                      <div class="journal cntrV">
                        <label>{{ 'Sun' | translate }}</label>
                        <input #sunday type="checkbox" id="day0" value="0"
                          (change)="updateChecked(sunday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Mon' | translate }}</label>
                        <input #monday type="checkbox" id="day1" value="1"
                          (change)="updateChecked(monday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Tue' | translate }}</label>
                        <input #tuesday type="checkbox" id="day2" value="2"
                          (change)="updateChecked(tuesday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Wed' | translate }}</label>
                        <input #wednesday type="checkbox" id="day3" value="3"
                          (change)="updateChecked(wednesday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Thu' | translate }}</label>
                        <input #thursday type="checkbox" id="day4" value="4"
                          (change)="updateChecked(thursday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Fri' | translate }}</label>
                        <input #friday type="checkbox" id="day5" value="5"
                          (change)="updateChecked(friday.value, $event)">
                      </div>
                      <div class="journal cntrV">
                        <label>{{ 'Sat' | translate }}</label>
                        <input #saturday type="checkbox" id="day6" value="6"
                          (change)="updateChecked(saturday.value, $event)">
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-6 col-xs-12">
                        <label>
                          <strong>{{ 'HourFrom' | translate }}</strong>
                        </label>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <label>
                          <strong>{{ 'HourTo' | translate }}</strong>
                        </label>
                      </div>
                      <div class="col-md-6 col-xs-6">
                        <timepicker formControlName="myTimeStart" [(ngModel)]="myTimeStart2"></timepicker>
                      </div>
                      <div class="col-md-6 col-xs-6">
                        <timepicker formControlName="myTimeEnd" [(ngModel)]="myTimeEnd2"></timepicker>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="checkbox">
                        <input [(ngModel)]="selectedHour.is_open_all_time" type="checkbox"
                          formControlName="is_open_all_time" class="form-control" (change)="allDayChecked($event.target.checked)">
                        <label>{{ 'IsOpenAllTime' | translate }}</label>
                      </div>
                    </div>


                    <div class="flx">
                      <button id="saveHourBtn" type="submit" [disabled]="!hoursForm.valid"
                        (click)="onSubmitHour(hoursForm.value, displayOpenDate.id)" class="btn btn-success mb-xs"
                        [hidden]="processingResponse">{{ 'Save' | translate }}</button>
                      <button class="btn btn-gray flx" [hidden]="!processingResponse"
                        [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal details Hour -->

        <div bsModal #detailsHou="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <div *ngIf="displayOpenDate">
                  <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                    {{ 'Hours' | translate }} - {{displayOpenDate.name}}
                  </h4>
                  <p class="text-xs-center">
                    ( {{displayOpenDate.startDate | date:'MMM d, y' }} - {{displayOpenDate.endDate | date:'MMM d, y' }}
                    )
                  </p>
                </div>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideDetailsHour()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="hour" class="row">
                  <div class="col-lg-12">
                    <div *ngIf="hour.length <= 0">
                      <alert type="danger">{{'NoDataToShow' | translate }}</alert>
                    </div>
                    <ngb-tabset>
                      <ngb-tab title="{{ 'DineIn' | translate }}">

                        <ng-template ngbTabContent>
                          <div class="form-group">
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th class="txtcntr">{{ 'WeekDay' | translate }}</th>
                                    <th class="txtcntr">{{ 'HourFrom' | translate }}</th>
                                    <th class="txtcntr">{{ 'HourTo' | translate }}</th>
                                    <th class="txtcntr">{{ 'Actions' | translate }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of hour; let i = index">
                                    <td *ngIf="item.attentionType == 1">
                                      {{ item.weekDayDescription }}
                                    </td>
                                    <td *ngIf="item.attentionType == 1" class="txtcntr">
                                      <!-- <div *ngIf="item.isOpenAllTime == true && format12Hours">
                                                                                    12:00 AM
                                                                                </div>
                                                                                <div *ngIf="item.isOpenAllTime == true && !format12Hours">
                                                                                    00:00
                                                                                </div> -->
                                      {{ item.hourFrom }}
                                    </td>
                                    <td *ngIf="item.attentionType == 1" class="txtcntr">
                                      <!-- <div *ngIf="item.isOpenAllTime == true && format12Hours">
                                                                                    11:59 PM
                                                                                </div>
                                                                                <div *ngIf="item.isOpenAllTime == true && !format12Hours">
                                                                                    23:59
                                                                                </div> -->
                                      {{ item.hourTo }}
                                    </td>
                                    <td *ngIf="item.attentionType == 1" class="actions txtcntr triact">
                                      <button class="btn btn-dark mb-xs" id="btnEditHour{{i+1}}" type="button"
                                        title="{{ 'Edit' | translate }}" (click)="editHour(displayOpenDate, item)">
                                        <i class="fa fa-pencil-square-o"></i>
                                      </button>
                                      <button class="btn btn-danger mb-xs" id="btnDelete{{i+1}}" type="button"
                                        title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, 'hour')">
                                        <i class="fa fa-eraser"></i>
                                      </button>
                                      <!-- <input class="btn" type="checkbox" id="{{i+1}}"
                                        (change)="checkToDeleteVarious($event, $event.target.id, item.id )"> -->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </ng-template>
                      </ngb-tab>

                      <ngb-tab title="{{ 'Delivery' | translate }}">

                        <ng-template ngbTabContent>
                          <div class="form-group">
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th class="txtcntr">{{ 'WeekDay' | translate }}</th>
                                    <th class="txtcntr">{{ 'HourFrom' | translate }}</th>
                                    <th class="txtcntr">{{ 'HourTo' | translate }}</th>
                                    <th class="txtcntr">{{ 'Actions' | translate }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of hour; let i = index">
                                    <td *ngIf="item.attentionType == 2">
                                      {{ item.weekDayDescription }}
                                    </td>
                                    <td *ngIf="item.attentionType == 2" class="txtcntr">
                                      <!-- <div *ngIf="item.isOpenAllTime == true && format12Hours">
                                                                                    12:00 AM
                                                                                </div>
                                                                                <div *ngIf="item.isOpenAllTime == true && !format12Hours">
                                                                                    00:00
                                                                                </div> -->
                                      {{ item.hourFrom }}
                                    </td>
                                    <td *ngIf="item.attentionType == 2" class="txtcntr">
                                      <!-- <div *ngIf="item.isOpenAllTime == true && format12Hours">
                                                                                    11:59 PM
                                                                                </div>
                                                                                <div *ngIf="item.isOpenAllTime == true && !format12Hours">
                                                                                    23:59
                                                                                </div> -->
                                      {{ item.hourTo }}
                                    </td>
                                    <td *ngIf="item.attentionType == 2" class="actions txtcntr triact">
                                      <button class="btn btn-dark mb-xs" id="btnEditHour{{i+1}}" type="button"
                                        title="{{ 'Edit' | translate }}" (click)="editHour(displayOpenDate, item)">
                                        <i class="fa fa-pencil-square-o"></i>
                                      </button>
                                      <button class="btn btn-danger mb-xs" id="btnDelete{{i+1}}" type="button"
                                        title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, 'hour')">
                                        <i class="fa fa-eraser"></i>
                                      </button>
                                      <input class="btn" type="checkbox" id="{{i+1}}"
                                        (change)="checkToDeleteVarious($event, $event.target.id, item.id )">
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </ng-template>
                      </ngb-tab>
                    </ngb-tabset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add Images Restaurant -->

        <ng-template #modalAddImage>
          <div class="modal-header">
            <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
              {{ 'UploadImage' | translate }}
            </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label *ngIf="!canEditImage">{{ 'SelectPicture' | translate }}</label>

            <div *ngIf="errorHour">
              <div class="alert alert-danger alert-dismissable nlblk">
                <button type="button" class="close" data-dismiss="alert"
                  aria-hidden="true">&times;</button>{{msgErrorHour}}</div>
            </div>

            <div *ngIf="selectedImage">
              <form class="form-horizontal" [formGroup]="imageForm" novalidate>

                <input type="hidden" formControlName="id" [(ngModel)]="selectedImage.id">
                <input type="hidden" formControlName="category" [(ngModel)]="selectedImage.category">

                <!-- <div class="form-group">
                                        <label>{{ 'Title' | translate }}</label>
                                        <input formControlName="title" type="text" id="titleImage" class="form-control" [(ngModel)]="selectedImage.title">
                                    </div> -->

                <!-- <div class="form-group">
                                        <label>{{ 'Description' | translate }}</label>
                                        <textarea formControlName="description" type="text" id="descriptionImage" class="form-control" [(ngModel)]="selectedImage.description"></textarea>
                                    </div> -->
                <div *ngIf="!canEditImage" class="form-group">
                  <div *ngIf="catImage">
                    <input type="file" class="form-control" id="fileImageRestaurant" (change)="captureImage($event)"
                      accept="image/jpeg,image/png">
                    <hr>
                    <div *ngIf="errorExist" class="alert alert-danger cien">{{textWarn}}</div>
                  </div>
                  <div *ngIf="!catImage">
                    <selector-images (onChange)="captureImage($event)"></selector-images>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="fileImage" class="pull-right">
              <button id="btnSaveImage" type="button" class="btn btn-success mb-xs"
                (click)="uploadImage(imageForm.value, categoryImage,modalAddImage)" [hidden]="processingResponse">
                <span class="glyphicon glyphicon-upload"></span> {{ 'Save' | translate}}</button>
            </div>
            <div *ngIf="canEditImage" class="pull-right">
              <button id="btnSaveImage" type="button" class="btn btn-success mb-xs"
                (click)="uploadImage(imageForm.value, categoryImage, modalAddImage)" [hidden]="processingResponse">
                <span class="glyphicon glyphicon-floppy-disk"></span> {{ 'Save' | translate}}
              </button>
            </div>
            <button class="btn btn-gray flx" [hidden]="!processingResponse"
              [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </div>
        </ng-template>

        <!-- Modal para confirmar acción eliminar -->
        <div bsModal #canDeleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
                  <i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="confirmDeletCerar()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-xs-center">
                  {{ 'WantDelete' | translate }}
                  <strong *ngIf="system == 'season'">{{ 'Season' | translate }}?</strong>
                  <strong *ngIf="system == 'image'">{{ 'Image' | translate }}?</strong>
                  <strong *ngIf="system == 'hour'">{{ 'Hour' | translate }}?</strong>
                  <strong *ngIf="system == 'variousHours'">{{ 'Hour' | translate }}?</strong>
                </p>
              </div>
              <div class="modal-footer">
                <div *ngIf="itemToDelete" class="flx">
                  <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true"
                    (click)="confirmDeletCerar()">{{
                    'Cancel' | translate }}</button>
                  <button id="deleteBtn" type="button" class="btn btn-success"
                    (click)="deleteSystem(itemToDelete, $event, system)" [hidden]="processingResponse">{{
                    'Confirm' | translate }}</button>
                  <button class="btn btn-gray flx" [hidden]="!processingResponse"
                    [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal para mostrar las imagenes de un restaurante de acuerdo a la categoria-->
        <ng-template #showImagesResaturant>
          <div class="modal-header">
            <h4 class="modal-title text-xs-center fw-bold mt">{{ 'Image' | translate }}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <div class="row" style="    min-height: 200px;">
                <div class="col-sm-12">
                  <ul class="thmb">
                    <li class="col-md-12">
                      <button *ngIf="selectedImage.cropedPath" (click)="showImageFull();" class="btn btn-success mb-xs"
                        role="button">
                        <span *ngIf="!crop">
                          {{ 'Image_crop' | translate }}
                        </span>
                        <span *ngIf="crop">
                          {{ 'Image_ori' | translate }}
                        </span>
                      </button>
                      <span class="thumb-xlg thumbnail thmb-lg thumbnail-large">
                        <img [src]="selectedImage.currentPath" alt="{{selectedImage.title}}" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-template>


        <!-- modal close restaurant -->
        <div bsModal #restaurantModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                  {{'CLOSE_RESTAURANT' | translate}}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalRestaurantClose()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>{{ 'Description' | translate }}</label>
                  <span class="counter-char ">{{ 'CHARACTERS' | translate}} {{range_min - restaurantCloseDescription.length}}</span>
                  <textarea maxlength="100" type="text" class="form-control" [(ngModel)]="restaurantCloseDescription" placeholder="{{ 'CLOSED_RESTAURANT_DESCRIPTION' | translate }}"></textarea>
                </div>
                <button id="saveSeasonBtn" class="btn btn-success mb-xs" [hidden]="buttonStatus"
                  [hidden]="processingResponse" (click)="closeOrOpenRestaurant(1)">{{ 'Save' | translate }} </button>
                  <button class="btn btn-gray flx" [hidden]="!buttonStatus"
                        [disabled]="buttonStatus">{{ 'processing' | translate }}&nbsp;
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal close restaurant -->

        <!-- <simple-notifications [options]="options"></simple-notifications> -->
      </div>
    </section>
  </div>
</div>
<!-- <inspectPermits></inspectPermits> -->