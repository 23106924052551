<h1 class="page-title">{{ 'RestaurantsToEnable' | translate }}
    <small></small>
</h1>

<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    {{ 'ListRestaurants' | translate }}
                </h4>
            </header>
            <div class="widget-body">
                <p class="text-xs-right">
                    <!-- <a class="btn btn-success" (click)="(enableRestaurant())">
                        <i class="fa fa-check-square-o"></i> {{ 'EnableRestaurant' | translate }}
                    </a> -->
                </p>

                <div class="form-group row">
                    <div class="col-md-8 col-xs-12">
                        <p>{{ 'Showing' | translate }} {{currentPage}} {{ 'To' | translate }} {{itemsPerPage}} {{ 'Of' | translate
                            }} {{totalItems}}
                        </p>
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a (click)="doPagination(10)" class="nav-link active" id="items10" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">10</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="doPagination(20)" class="nav-link" id="items20" data-toggle="tab" role="tab" aria-controls="details" aria-expanded="false">20</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="doPagination(50)" class="nav-link" id="items50" data-toggle="tab" role="tab" aria-controls="image" aria-expanded="false">50</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="form-group">
                    <div *ngIf="allRestaurants">
                        <div *ngIf="allRestaurants.length <=0">
                            <div class="alert alert-info alert-dismissable">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                <strong>{{'NoDataToShow' | translate }}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <!-- Tabla con los restaurantes que están pendientes para habilitar -->
                        <table class="table table-striped">
                            <tbody>
                                <ng-container *ngFor="let item of allRestaurants; index as i">
                                    <tr>
                                        <!-- <td width="2%">
                                            <input type='checkbox' [(ngModel)]='item.checked'>
                                        </td> -->
                                        <td width="98%" data-toggle="collapse" [attr.data-target]="acordeon[i]" class="clickable">
                                            {{ item.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div id="{{acordeon1[i]}}" class="collapse">
                                                <div class="row info-restaurant">
                                                    <div class="col">
                                                        <ul>
                                                            <li class="title">
                                                                <strong>{{ 'Website' | translate }}: </strong>
                                                            </li>
                                                            <li class="info">
                                                                <a href="{{ item.website }}" target="_blank">{{ item.website }}</a>
                                                            </li>
                                                            <li class="title">
                                                                <strong>{{ 'Description' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">{{ item.description }}</li>
                                                            <li class="title">
                                                                <strong>{{ 'UrlMenu' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">
                                                                <a href="{{ item.urlMenu }}" target="_blank">{{ item.urlMenu }}</a>
                                                            </li>
                                                            <li class="title">
                                                                <strong>{{ 'Address' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">{{ item.address.naturalAddress }}</li>
                                                            <li class="title">
                                                                <strong>{{ 'Phone' | translate }}:</strong>
                                                            </li>
                                                            <li *ngFor="let resPhone of item.phones">{{ resPhone.number }}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col">
                                                        <ul>
                                                            <li class="title">
                                                                <strong>{{ 'Cuisines' | translate }}:</strong>
                                                            </li>
                                                            <li class="info" *ngFor="let resCuisine of item.cuisines">
                                                                <span *ngIf="resCuisine != ''">{{ resCuisine.Cuisine[0].langs[0].name }}</span>
                                                            </li>
                                                            <li class="title">
                                                                <strong>{{ 'Features' | translate }}:</strong>
                                                                <br/>
                                                                <span *ngFor="let resFeature of item.features;let i = index">
                                                                    {{ resFeature.Feature[0].langs[0].name }}
                                                                    <span *ngIf="i < item.features.length - 1 ">,</span>
                                                                </span>
                                                            </li>
                                                            <br/>
                                                            <!--<li *ngFor="let resFeature of item.features">
                                                                <span *ngIf="resFeature != ''">{{ resFeature.Feature[0].langs[0].name }}</span>
                                                            </li>-->
                                                            <li class="title">
                                                                <strong>{{ 'Meals' | translate }}:</strong>
                                                            </li>
                                                            <li *ngFor="let resMeals of item.meals">
                                                                <span *ngIf="resMeals != ''">{{ resMeals.Meal[0].langs[0].name }}</span>
                                                            </li>
                                                            <br/>
                                                            <li class="title">
                                                                <strong>{{ 'Neighborhood' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">
                                                                <span *ngIf="item.third_party_information != ''">{{ item.third_party_information[0].neighborhood }}</span>
                                                            </li>
                                                            <li class="title">
                                                                <strong>{{ 'Rating' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">
                                                                <span *ngIf="item.third_party_information != ''">{{ item.third_party_information[0].rating }}</span>
                                                            </li>
                                                            <li class="title">
                                                                <strong>{{ 'Reviews' | translate }}:</strong>
                                                            </li>
                                                            <li class="info">
                                                                <span *ngIf="item.third_party_information != ''">{{ item.third_party_information[0].reviews }}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <a class="btn btn-success" (click)="(enableRestaurant(item, i))">
                                                    {{ 'EnableRestaurant' | translate }}
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Botones Paginación -->
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="pull-right">{{ 'Page' | translate }} {{actualPage}}</label>
                    </div>
                    <div class="col-lg-6">
                        <div class="btn-group btnGroup-pagination pull-right">
                            <button *ngIf="prevPage != 0" class="btn btn-default" type="button" (click)="getPage(firstPage)">
                                <i class="fa fa-angle-double-left"></i>
                            </button>
                            <button *ngIf="prevPage != 0" class="btn btn-default" type="button" (click)="getPage(prevPage)">
                                <i class="fa fa-angle-left"></i>
                            </button>
                            <button #btnPage id="btnPage{{ i+1}}" *ngFor="let item of createRange(10); let i = index" class="btn btn-default btnPage{{i+1}}"
                                type="button" (click)="getPage(item)">{{item}}</button>
                            <button *ngIf="nextPage <= lastPage" class="btn btn-default" type="button" (click)="getPage(nextPage)">
                                <i class="fa fa-angle-right"></i>
                            </button>
                            <button *ngIf="nextPage <= lastPage" class="btn btn-default" type="button" (click)="getPage(lastPage)">
                                <i class="fa fa-angle-double-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <ng-template #canDeleteModal>
                    <div class="modal-header">
                        <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18">
                            <i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                        </h4>
                        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body bg-gray-lighter">
                            <p class="text-xs-center">
                                {{ 'WantDeleteRestaurant' | translate }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <div *ngIf="selectedRestaurant">
                                <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true" (click)="modalRef.hide()">{{ 'Cancel' | translate }}</button>
                                <button id="deleteBtn" type="button" class="btn btn-success" (click)="deleteRestaurant(selectedRestaurant, $event, canDeleteModal)">{{ 'Confirm' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </section>
    </div>
</div>