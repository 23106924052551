export class openingDate {
    id: number;
    name: string;
    start_date: string;
    end_date: string;
    description: string;
    isDefault: boolean;
    hours: Array<Object>;
    dineIn: number;
    delivery: number;
}

export class hour {
    id: number;
    week_day: number;
    is_open_all_time: boolean;
    hour_from: string;
    hour_to: string;
    attention_type: number;
}