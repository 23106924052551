<div class="row">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    {{ 'Filters' | translate }}
                </h4>
            </header>
            <div class="widget-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row filters filter-form">
                            <div class="col-lg-2 col-xs-12" *ngIf="allOrders">
                                <select class="form-control select2" [(ngModel)]="searchFields.country"
                                    placeholder="works" (ngModelChange)="getAllRestaurants()">
                                    <option id="1" value="" disabled> {{ 'Country' | translate }} </option>
                                    <option value=""> {{ 'SelectedAll' | translate }} </option>
                                    <option value="CO">
                                        Colombia
                                    </option>
                                    <option value="US">
                                        {{'USA' | translate}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-xs-12" *ngIf="status !== statusConstants.PENDING">
                                <select class="form-control select2" [(ngModel)]="searchFields.statusOrder"
                                    (ngModelChange)="getAllRestaurants()">
                                    <option id="1" value="" disabled> {{ 'Status' | translate }} </option>
                                    <option value=""> {{ 'SelectedAll' | translate }} </option>
                                    <option *ngFor="let orderStatus of ordersStatus" [ngValue]="orderStatus.number">
                                        {{orderStatus.name | translate}}</option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-xs-12" *ngIf="allOrders">
                                <select class="form-control select2" [(ngModel)]="searchFields.restaurantId"
                                    placeholder="works">
                                    <option id="1" value="" disabled> {{ 'Restaurant' | translate }} </option>
                                    <option value=""> {{ 'SelectedAll' | translate }} </option>
                                    <option *ngFor="let restaurant of restaurants" [ngValue]="restaurant.restaurantId">
                                        {{ restaurant.restaurantName}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-lg-3 col-xs-12">
                                <div class="input-group">
                                    <input type="text" [(ngModel)]="searchFields.orderCodeParam" #searchInput
                                        class="form-control" id="inputSearch"
                                        placeholder="{{ 'FIELDS_TO_FIND_ORDERS' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-12">
                                <div class="input-group">
                                  <input class="form-control" placeholder="{{ 'DATE_RANGE' | translate }}" bsDaterangepicker
                                    #dpr="bsDaterangepicker" [(ngModel)]="searchFields.dateRangePicker" [bsConfig]="{
                                    dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-green' }">
                                </div>
                              </div>
                            <div class="col-lg-1 col-xs-12">
                                <button [disabled]="buttonStatus" class="btn btn-info mb-xs"
                                    (click)="getOrders()">{{ 'Search' | translate }}</button>
                            </div>
                            <div class="text-right col-lg-2 col-xs-12">
                                <button (click)="openModalInfo()" [disabled]="buttonStatus"
                                    class="btn btn-info mb-xs"><em class="fa fa-info-circle"></em></button>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>

            </div>
            <div bsModal #pushErrorModal="bs-modal" class="modal fade modal-push" tabindex="-1" role="dialog"
                aria-labelledby="mySmallModalLabel" aria-hidden="true"
                [config]="{ backdrop: false, ignoreBackdropClick: true, keyboard: false}">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
                                {{ 'WE_ARE_SORRY' | translate}}
                            </h4>
                            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <span> {{'PUSH_ERROR' | translate}}</span>
                            <div class="col-md-12 flex">
                                <button id="saveSeasonBtn" class="btn btn-success width-50"
                                    (click)="goToDetailOrder()">{{ 'SEE_ORDER' | translate }} </button>
                                <button id="saveSeasonBtn" class="btn btn-danger width-50"
                                    (click)="closeModal()">{{ 'Close' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="table-responsive">





                <div class="form-group row justify-content-end">
					<div class="col-md-12">
						<ul class="nav nav-tabs nav-pagination pull-xs-right" id="myTab" role="tablist">
							<li class="nav-item" *ngFor="let page of paginationArray">
								<a (click)="getOrders(null, page)"  [ngClass]="{'active': page === itemsPerPageSelected}" class="nav-link" id="items10" data-toggle="tab" role="tab" aria-controls="basic" aria-expanded="true">{{page}}</a>
								<div class="lds-dual-ring" *ngIf="paginationSelected == page"></div>
							</li>
						</ul>
					</div>
				</div>
                <div class="pull-left">
                    <button class="btn btn-success mb-xs" (click)="refreshOrders()">
                        <em class="fa fa-refresh"></em> {{ 'REFRESH' | translate }}
                    </button>
                </div>
                <div class="text-xs-left pull-right">
                    <button class="btn btn-success mb-xs" (click)="exportToExcel()" [disabled]="downloadExcel" [hidden]="downloadExcel">
                      <i class="fa fa-file"></i> {{ 'EXPORT_TO_EXCEL' | translate }}
                    </button>
                    <button class="btn btn-gray flx" [hidden]="!downloadExcel"
                    [disabled]="!downloadExcel"
                      >{{ 'processing' | translate }}&nbsp;
                      <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </button>
                  </div>
                <table class="table table-striped" id="table-restaurant">
                    <caption></caption>
                    <thead>
                        <tr>
                            <th id="number" *ngIf="!status">
                                <a>#
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <th id="number">
                                <a>Codigo
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>
                            <th id="date">
                                <a>{{ 'Date' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <th id="type">
                                <a>{{ 'Type' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>
                            <th id="customer">
                                <a>{{ 'CUSTOMER' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <th id="status" class="text-center" *ngIf="!status">
                                <a>{{ 'Status' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <th id="brand">
                                <a>{{'BRAND' | translate}}
                                </a>
                            </th>

                            <th id="restaurant" class="text-center" *ngIf="allOrders">
                                <a>{{ 'Restaurant' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <!-- <th id="table">
          <a>{{ 'TABLE' | translate }}
            <em class="{{orderIconObject}}"></em>
          </a>
        </th> -->
                            <!-- <th id="server">
          <a>{{ 'SERVER' | translate }}
            <em class="{{orderIconObject}}"></em>
          </a>
        </th> -->

                            <th>
                                <a>Pago</a>
                            </th>
                            <th id="total">
                                <a>{{ 'TOTAL' | translate }}
                                    <em class="{{orderIconObject}}"></em>
                                </a>
                            </th>

                            <th id="actions" class="txtcntr">{{ 'Actions' | translate }}</th>
                        </tr>
                    </thead>
                    <ng-container *ngFor="let order of orders">
                        <tbody class="load-background-order-{{order.omtStatus}}">
                            <tr>
                                <td *ngIf="!status">
                                    {{order.orderConsecutive}}
                                </td>
                                <td>
                                    {{order.orderCode}}
                                </td>
                                <td>
                                    {{order.orderCreation | dateTimeLocalize | async}}
                                </td>
                                <td>
                                    <ng-container *ngIf="order.ordOrderType === orderTypeConstants.dineIn">
                                        {{ "DINE_IN" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.ordOrderType === orderTypeConstants.counter">
                                        {{ "COUNTER" | translate }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder && order.orderPhoneType == 1">
                                        {{ "PICKUP" | translate }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder&& order.orderPhoneType == 2">
                                        {{ "DELIVERY" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder && (order.orderPhoneType ===
                                          phoneOrderTypeConstants.table || order.orderPhoneType === phoneOrderTypeConstants.turn ||
                                          order.orderPhoneType === phoneOrderTypeConstants.turnAutomatic)">
                                        {{ "DINE_IN" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.ordOrderType === orderTypeConstants.barTab">
                                        {{ "BAR_TAB" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.ordOrderType === orderTypeConstants.driveThru">
                                        {{ "DRIVE_THRU" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.dineInTurn">
                                        <span
                                            *ngIf="order.ordDineInType === DINE_IN_CONSTANTS.TABLE">{{'TABLE' | translate }}:</span>
                                        <span
                                            *ngIf="order.ordDineInType === DINE_IN_CONSTANTS.BEACON">{{'BEACON' | translate }}:</span>
                                        <span>{{order.dineInTurn}}</span>
                                    </ng-container>
                                </td>

                                <td>
                                    {{order.clientName}} {{order.clientLastName}}
                                </td>

                                <td *ngIf="!status">
                                    <ng-container
                                        *ngIf="order.omtStatus === statusConstants.PENDING || order.omtStatus == 1">
                                        {{ "Pending" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.omtStatus === statusConstants.RECEIVED">
                                        {{ "RECEIVED" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.omtStatus === statusConstants.BEING_PREPARED">
                                        {{ "BEING_PREPARED" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.omtStatus === statusConstants.PREPARED">
                                        {{ "PREPARED" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.omtStatus === statusConstants.DELIVERED">
                                        {{ "DELIVERED" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="order.omtStatus === statusConstants.CANCELED">
                                        {{ "CANCELED" | translate }}
                                    </ng-container>
                                </td>

                                <td>
                                    <ng-container *ngIf="order.orderBrandFrom === types_brand.TYPE_KIND_OMT_WEB">
                                        Online OMT
                                    </ng-container>
                                    <ng-container *ngIf="order.orderBrandFrom === types_brand.TYPE_KIND_OMT_APP">
                                        APP OMT
                                    </ng-container>
                                    <ng-container *ngIf="order.orderBrandFrom === types_brand.TYPE_KIND_MENU_ONLINE">
                                        ONLINE
                                    </ng-container>
                                    <ng-container *ngIf="order.orderBrandFrom === types_brand.TYPE_KIND_WHITE_LABEL">
                                        APP
                                    </ng-container>
                                </td>

                                <td *ngIf="allOrders">
                                    {{ order.restaurantName }}
                                </td>

                                <!-- <td>
            {{order.dineInTurn}}
          </td>
          <td>
            N.A.
          </td> -->


                                <td>
                                    <span *ngIf="order.uponDelivery == 0">{{ "credit_card" | translate}}</span>
                                    <span *ngIf="order.uponDelivery == 1">{{ "UPON_DELIVERY" | translate}} <span
                                            *ngIf="isColombia">-
                                            {{order.ordPayMethod | translate}}</span></span>
                                </td>
                                <td class="text-align-right">
                                    {{ order.invTotal | currency:'USD':true:'1.2-2' }}
                                </td>
                                <td class="width-250 actions" id="ractions-{{order.id}}">
                                    <div class="">
                                        <button class="btn btn-secondary  mb-xs" role="button"
                                            title="{{ 'ShowDetails' | translate }}" (click)="goToDetailOrder(order)">
                                            <em class="fa fa-eye text-info"></em>
                                        </button>
                                        <button
                                            *ngIf="order.omtStatus === statusConstants.PENDING || order.omtStatus === statusConstants.PENDING_ALSO"
                                            class="btn btn-info" role="button" title="Retry"
                                            (click)="getDataFromOrderToSync(order)"
                                            [disabled]="order.resendingOrderSyncStatus">
                                            <em class="fa fa-paper-plane" *ngIf="!order.resendingOrderSyncStatus"></em>
                                            <app-loading-image *ngIf="order.resendingOrderSyncStatus"
                                                [classCSS]="'order-list'">
                                            </app-loading-image>
                                        </button>
                                        <button
                                        *ngIf="order.omtStatus === statusConstants.PENDING || order.omtStatus === statusConstants.PENDING_ALSO"
                                        class="btn btn-primary" role="button" title="RetryAndroid"
                                        (click)="getDataFromOrderToSync(order, true)"
                                        [disabled]="order.resendingOrderAndroidSyncStatus">
                                        <em class="fa fa-mobile" *ngIf="!order.resendingOrderAndroidSyncStatus"></em>
                                        <app-loading-image *ngIf="order.resendingOrderAndroidSyncStatus"
                                            [classCSS]="'order-list'">
                                        </app-loading-image>
                                        </button>
                                        <button
                                            *ngIf="order.omtStatus !== statusConstants.DELIVERED && order.omtStatus !== statusConstants.CANCELED"
                                            class="btn btn-danger" role="button" title="Delete"
                                            (click)="cancelOrder(order)">
                                            <em class="fa fa-ban" ></em>
                                        </button>
        
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </ng-container>
                </table>
            </div>
            <div *ngIf="orders">
                <div *ngIf="orders.length <=0">
                    <div class="alert alert-info alert-dismissable">
                        <strong>{{'NoDataToShow' | translate }}</strong>
                    </div>
                </div>
            </div>
            <!-- Botones Paginación -->
            <div class="form-group row" *ngIf="pagination && pagination.totalItems > 0">
                <div class="col-lg-12 col-md-12 d-flex justify-content-center">
                    <label>{{ 'Page' | translate }} {{pagination.currentPage}}</label>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-12 pagNew d-flex justify-content-center">
                    <pagination class="pagination pagination-md justify-content-end active"
                        [totalItems]="pagination.totalItems" [maxSize]="maxSize.maxSizePagination"
                        [itemsPerPage]="pagination.itemsPerPage" [lastText]="lastPag" [firstText]="firstPag"
                        [previousText]="prevPag" [nextText]="nextPag" (pageChanged)="getOrders($event)"
                        [boundaryLinks]="true" [rotate]="true">
                    </pagination>
                </div>
            </div>
