import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response, URLSearchParams } from '@angular/http';

import { user } from '../entity/user';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class UserService {
    constructor(
        private http: Http,
        private utils: UtilsComponent,
        private httpClient: HttpClient
    ) { }

    private omtUrl = this.utils.getUrlOmtSd();
    private getUserUrl = this.utils.getUrlOmt() + 'sd';

    getAllUsers(userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getUserUrl}/administrators`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataUsers)
            .catch(this.handleError);
    }

    getMainMenu(idRestaurant: number) {
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');

        let url = `${this.getUserUrl}/${idRestaurant}/menu`;
        return this.http.get(url)
            .toPromise()
            .then(this.extractDataUsers)
            .catch(this.handleError);

    }

    getMenu(idRestaurant: number, userLang: string) {
        let headers = new Headers();
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getUserUrl}/${idRestaurant}/menu`;

        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataUsers)
            .catch(this.handleError);

    }

    /**
     * Metodo que se encarga de determinar que tipo de web services debe ser llamado
     * para crear o editar un usuario
     * @param userData: user -> Informacion del usuario a crear
     */
    save(userData: user, userLang: string) {
        if (userData.id) {
            return this.put(userData);
        }
        return this.post(userData, userLang);
    }

    /**
     * Metodo encargado de agregar un nuevo usuario al sistema
     * @param userData: any -> Informacion del usuario a crear
     */
    private post(userData: any, userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = `${this.getUserUrl}/administrator`;

        var arrayData = {
            name: userData.name,
            lastname: userData.lastname,
            email: userData.email,
            username: userData.username,
            password: userData.password,
            password_confirm: userData.password_confirm,
            type: userData.type
        }

        let array = JSON.stringify(arrayData);

        // return this.http
        //     .post(url, array, { headers: headers })
        //     .toPromise();

        return this.httpClient.post(url, array);
    }

    /**
     * Metodo encargado de editar un usuario existente en el sistema
     * @param userData: any -> Informacion del usuario a editar
     */
    private put(userData: any) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        let url = `${this.getUserUrl}/administrator/${userData.id}`;

        var arrayData = {
            id: userData.id,
            name: userData.name,
            lastname: userData.lastname,
            email: userData.email,
            username: userData.username,
            password: userData.password,
            password_confirm: userData.password_confirm,
            type: userData.type,
            status: userData.status
        }

        let array = JSON.stringify(arrayData);

        // return this.http
        //     .put(url, array, { headers: headers })
        //     .toPromise()
        //     .then(res => res.json())
        //     .catch(this.handleError);

        return this.httpClient.put(url, array);

    }

    private extractDataUsers(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    delete(user: user, userLang: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = `${this.getUserUrl}/administrator/${user.id}`;

        return this.http
            .delete(url, { headers })
            .toPromise()
            .catch(this.handleError);
    }

    /**
    * Metodo encargado de pedir el perfil del usuario logueado al web service
    */
    getPermissionsUser(userLang: string, idUser: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);
        // console.log("idUser ",idUser);
        let options = new RequestOptions({
            search: new URLSearchParams('user_id=' + idUser),
            headers: headers
        });

        let url = `${this.getUserUrl}/modules`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataUsers)
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de editar los permisos de un usuario sobre los modulos de OMT
     * @param idUser: number -> Id del usuario a editar
     * @param idModule: number -> Id del modulo a editar
     * @param userLang: string -> Idioma que esta utilizando la aplicacion
     */
    postPermissionsUser(idUser: string, idModule: number, userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = `${this.getUserUrl}/administrator/${idUser}/assignModule`;

        var arrayData = {
            module_id: idModule
        }

        let array = JSON.stringify(arrayData);

        return this.http
            .post(url, array, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de enviar peticion al web service para eliminar permisos de un usuario sobre
     * los modulos existentes en OMT
     * @param idUser: number -> Id del usuario que se esta editando
     * @param idModule: number -> Id del modulo a editar permiso
     * @param userLang: string -> Idioma que esta manejando la aplicacion
     */
    deletePermissionsUser(idUser: string, idModule: number, userLang: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = `${this.getUserUrl}/administrator/${idUser}/deleteModule`;

        var arrayData = {
            module_id: idModule
        }

        return this.http
            .delete(url, { headers: headers, body: arrayData })
            .toPromise()
            .catch(this.handleError);
    }



    /**
     * Metodo encargado de pedir el perfil del usuario logueado al web service
     */
    getProfileUser(userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getUserUrl}/profile`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataUsers)
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de editar un usuario existente en el sistema
     * @param userData: any -> Informacion del usuario a editar
     */
    putProfileUser(userData: any, userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = `${this.getUserUrl}/profile`;

        var arrayData = {
            name: userData.name,
            lastname: userData.lastname,
            email: userData.email,
            password: userData.password,
            password_confirm: userData.password_confirm
        }

        let array = JSON.stringify(arrayData);

        return this.http
            .put(url, array, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }
    /**
     * funcion para ubicar el usuario a traves de wifi
     */
    public getCountryFromIp() {
        const url = `${this.utils.getUrlOmt()}search-ip`;
        return this.httpClient.get(url);
    }

}
