import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Response } from '@angular/http';

import { menuItem } from '../entity/menuItem';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class MenuProductsService {

    constructor(
        private http: HttpClient,
        private utils: UtilsComponent
    ) { }

    private menuItemUrl = 'https://www.mocky.io/v2/5790d326260000e1187ee59b';
    private getProductsUrl = this.utils.getUrlOmt() + 'restaurant';
    private omtUrl = this.utils.getUrlOmtSd();

    /**
     * permite consultar todos los registros de los productos
     */
    getMenuItems() {
        return this.http.get(this.menuItemUrl);
    }

    getAllMenuProducts(idRestaurant: number, idMenu: number, idCategory: number) {
        let url = `${this.getProductsUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/products` + '?items_per_page=600';
        return this.http.get(url);
    }

    getDetailsProduct(idRestaurant: number, idMenu: number, idCategory: number, idProduct: number, userLang: string) {
        let url = this.utils.getUrlOmt() + 'product/' + idProduct;
        return this.http.get(url);
    }



    private extractDataProducts(res: Response) {
        let body = res.json();
        // console.log("body ",body);
        return body.data || [];
    }

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    // getMenuItem(id: number) {
    //     return this.getMenuItems()
    //         .then(menuItem => menuItem.filter(menuItem => menuItem.id === id)[0]);
    // }

    /**
     * permite almacenar los datos de un producto
     * 
     * @param menuItem 
     * @param idRestaurant 
     * @param idMenu 
     * @param idCategory
     * @param strSize 
     */
    save(menuItem: menuItem, idRestaurant: number, idMenu: number, idCategory: number, strSize: string): Observable<menuItem> {
        if (menuItem.id) {
            return this.put(menuItem, idRestaurant, idMenu, idCategory, strSize);
        } else {
            return this.post(menuItem, idRestaurant, idMenu, idCategory, strSize);
        }
    }

    deleteProduct(idProduct: number, idRestaurant: number, idMenu: number, idCategory: number) {
        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/product/${idProduct}`;
        return this.http.delete(url);
    }


    private post(menuItem: menuItem, idRestaurant: number, idMenu: number, idCategory: number, strSize: string) {

        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/product`;

        // let isModifier: boolean = false;

        // if (menuItem.isModifier) {
        //     isModifier = true;
        // }

        var arrayData = {
            name: menuItem.name,
            description: menuItem.description,
            ingredients: menuItem.ingredients,
            prices: JSON.parse(strSize),
            isModifier: menuItem.isModifier
        }

        let array = JSON.stringify(arrayData);

        return this.http.post<menuItem>(url, array);

    }

    // Update existing menuItem
    private put(menuItem: menuItem, idRestaurant: number, idMenu: number, idCategory: number, strSize: string) {

        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/product/${menuItem.id}`;

        var arrayData = {
            id: menuItem.id,
            name: menuItem.name,
            description: menuItem.description,
            ingredients: menuItem.ingredients,
            prices: strSize,
            isModifier: false,
            category_id: menuItem.category_id,
            status: menuItem.status,
            video_path: menuItem.videoPath
        }

        let array = JSON.stringify(arrayData);

        return this.http.put<menuItem>(url, array);

    }

    /**
     * Permite guardar la imagen de un producto
     * @param formImage 
     * @param idRestaurant 
     * @param idMenu 
     * @param idCategory 
     * @param idProduct 
     */
    saveImage(formImage: any, idRestaurant: number, idMenu: number, idCategory: number, idProduct: number) {
        // if (formImage.id) {
        //     return this.putImageProduct(formImage, idRestaurant, idMenu, idCategory, idProduct);
        // }
        console.warn('saveImage ==> formImage', formImage);
        // console.warn('saveImage ==> idRestaurant', idRestaurant);
        // console.warn('saveImage ==> idMenu', idMenu);
        // console.warn('saveImage ==> idCategory', idCategory);
        // console.warn('saveImage ==> idProduct', idProduct);
        return this.uploadImageProduct(formImage, idRestaurant, idMenu, idCategory, idProduct);
    }

    /**
     * Permite registrar una imagen de producto
     * 
     * @param formImage 
     * @param idRestaurant 
     * @param idMenu 
     * @param idCategory 
     * @param idProduct 
     */
    public uploadImageProduct(formImage: any, idRestaurant: number, idMenu: number, idCategory: number, idProduct: number) {

        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });

        let url = this.omtUrl + '/' + idRestaurant + '/menu/' + idMenu + '/category/' + idCategory + '/product/' + idProduct + '/image';

        let formData = new FormData();
        // formData.append('title', formImage.title);
        // formData.append('description', formImage.description);
        formData.append('file', formImage.imageOriginal);
        formData.append('cropedFile', formImage.imageCroppied);

        return this.http.post(url, formData, { 'headers': headers })
    }

    /**
     * Permite actualizar la imagen de un producto
     * 
     * @param formImage 
     * @param idRestaurant 
     * @param idMenu 
     * @param idCategory 
     * @param idProduct 
     */
    private putImageProduct(formImage: any, idRestaurant: number, idMenu: number, idCategory: number, idProduct: number) {

        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });

        let url = this.omtUrl + '/' + idRestaurant + '/menu/' + idMenu + '/category/' + idCategory + '/product/' + idProduct + '/image/' + formImage.id;

        var arrayData = {
            id: formImage.id,
            title: formImage.title,
            description: formImage.description,
            is_main: formImage.isMain
        };

        let array = JSON.stringify(arrayData);
        return this.http.put(url, array, { 'headers': headers });
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    getImagesInProduct(idRestaurant: number, idMenu: number, idCategory: number, idProduct: number) {
        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/product/${idProduct}/image`;
        return this.http.get(url);
    }

    deleteImageProduct(idRestaurant: number, idMenu: number, idCategory: number, idProduct: number, idImage: number) {

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        let url = `${this.omtUrl}/${idRestaurant}/menu/${idMenu}/category/${idCategory}/product/${idProduct}/image/${idImage}`;
        return this.http
            .delete(url);
        // .toPromise()
        // .catch(this.handleError);
    }

}
