import { ToastrService } from 'ngx-toastr';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AlertsService } from '../../services/alerts.service';
import { RestaurantService } from '../../services/restaurant.service';
import { globalRegex } from '../../utils/globalRegex';

//Variable para manejo de funciones Jquery
declare var jQuery: any;
@Component({
    selector: 'level-form',
    templateUrl: './levelForm.component.html',

})

export class LevelForm implements OnInit {

    public levelForm: FormGroup;
    submitted = true;
    dis: boolean = true;
    error: any;
    @Input() title: string;
    @Input() accountName: string;
    @Input() contactPerson: string;
    @Input() mainPhone: string;
    @Input() mainEmail: string;
    @Input() street: string;
    @Input() suitNumber: string;
    @Input() zipCode: string;
    @Input() cityName: string;
    @Input() stateName: string;
    @Input() countryName: string;
    @Input() nickname: string;
    @Input() idRestaurant: string;
    @Input() idAccount: string;
    @Input() res: any;

    @Output() notify: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private translate: TranslateService,
        private restaurantService: RestaurantService,
        private toastr: ToastrService,
        public alertService: AlertsService

    ) { }

    ngOnInit() {
        this.createForm();
    }
    /** 
     * Cambiar iconos del restaurante
     * 
    */
    updateStatusLevel(idRestaurant) {
        var element = document.getElementById('ractions-' + idRestaurant);
        jQuery(element).find('.fa-refresh').addClass('fa-spin');
        jQuery(element).find('.fa-refresh').parent().attr('disabled', true)
        var elementSic = document.getElementById('rsinc-' + idRestaurant);
        //traduccir procesando
        this.translate.get(["processing"]).subscribe(translations => {
            jQuery(elementSic).find('label').text(translations.processing);
        });
    }

    onSubmit(valid, accountName, contactPerson, mainPhone, mainEmail, street, suitNumber, zipCode, cityName, stateName, countryName, nickname, idRestaurant, idAccount) {
        this.submitted = false;

        //Armamos array para enviar al service
        var arrayData = {
            account_name: accountName,
            contact_person: contactPerson,
            main_phone: mainPhone,
            main_email: mainEmail,
            street: street,
            suit_number: suitNumber,
            zip_code: zipCode,
            city_name: cityName,
            state_name: stateName,
            country_name: countryName,
            nickname: nickname,
            idRestaurant: idRestaurant,
            idAccount: idAccount

        }

        if (this.idAccount) {
            this.restaurantService
                .putAccount(arrayData)
                .subscribe(data => {
                    //Nickname devuelto por el WS
                    this.restaurantService
                        .createLevelTrue(arrayData.nickname, true)
                        .subscribe(res => {
                            //this.newRestaurantSuccess();
                            this.alertService.alertGestionSuccess("SuccessfullyEdited");
                            this.submitted = true;
                            //this.globalEventsService.clase.emit();
                            this.updateStatusLevel(idRestaurant);
                            this.notify.emit('Click from nested component');
                        },
                            error => {
                                error => this.error = error
                                this.submitted = true;
                                //this.newRestaurantError();
                                this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
                                console.warn('ERROR', error);
                            });
                },
                    error => {
                        error => this.error = error
                        //this.newRestaurantError();
                        this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
                        console.warn('ERROR', error);
                    });
        } else {
            this.restaurantService
                .createLevel(arrayData)
                .subscribe(data => {
                    console.warn('createLevel 2 data ==>', data);

                    if (data.data) {
                        //Nickname devuelto por el WS
                        this.restaurantService
                            .createLevelTrue(arrayData.nickname, true)
                            .subscribe(res => {
                                this.alertService.alertGestionSuccess("SuccessfullyEdited");
                                this.submitted = true;
                                this.updateStatusLevel(idRestaurant);
                                this.notify.emit('Click from nested component');
                            },
                                error => {
                                    error => this.error = error
                                    this.submitted = true;
                                    //this.newRestaurantError();
                                    this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
                                });
                    } else {
                        //this.newRestaurantError();
                        this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
                        this.submitted = true;
                        console.warn('ERROR', data.errors.message);
                    }

                },
                    error => {
                        error => this.error = error
                        this.alertService.WarningAdd("Restaurant", "ErrorCreatingRestaurant");
                        this.submitted = true;
                    });
        }

    }

    /**
   * Metodo encargado de crear el formulario que manejara los datos basicos del restaurante
   */
    createForm() {

        this.levelForm = new FormGroup({

            accountName: new FormControl('', [<any>Validators.required]),
            contactPerson: new FormControl('', [<any>Validators.required]),
            mainPhone: new FormControl('', [<any>Validators.required, Validators.max(9999999999)]),
            mainEmail: new FormControl('', [<any>Validators.pattern(globalRegex.emailRegex)]),
            street: new FormControl(''),
            suitNumber: new FormControl(''),
            zipCode: new FormControl('', [<any>Validators.required, Validators.max(9999999999)]),
            cityName: new FormControl(''),
            stateName: new FormControl(''),
            countryName: new FormControl('', [<any>Validators.required]),
            nickname: new FormControl(''),
            idRestaurant: new FormControl(''),
            idAccount: new FormControl('')

        });
    }

    errorToast(title: string, text: string) {
        this.toastr.warning(text, title)
    }



}

