// import { TimerWrapper } from '@angular/core/src/facade/async';
import { NotificationsService } from 'angular2-notifications';

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
// import { HolderJs } from '../components/holderjs/holderjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { UserService } from './../services/user.service';
import { legalTerm } from '../entity/legalTerm';
// import { NKDatetime } from 'ng2-datetime/ng2-datetime';
import { LegalService } from '../services/legal.service';
// import { DateFullPipe } from './../restaurant/pipes/dateFull-pipe';
import { PermissionsUserService } from '../services/permissionsUser.service';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

//Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: 'legal',
    templateUrl: './legal.html',
    // directives: [Widget, HolderJs, REACTIVE_FORM_DIRECTIVES,
    //   SimpleNotificationsComponent, NKDatetime, InspectPermitsComponent],
    // providers: [NotificationsService],
    // encapsulation: ViewEncapsulation.None,
    // pipes: [TranslatePipe, DateFullPipe],
    styles: [('./legal.scss')]
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }
//   /*if (!isAdministrator()) {
//     router.navigate(['/App']);
//     return false;
//   }*/

//   return true;
// })
export class LegalComponent implements OnInit {
    router: Router;
    constructor(
        private modalService: NgbModal,
        private legalService: LegalService,
        // private userService: UserService,
        private permissionsUserService: PermissionsUserService,
        private _fb: FormBuilder,
        private _service: NotificationsService,
        private utils: UtilsComponent
    ) { }

    /**
     * Variable que controla si se muestra o no la seccion
     */
    canShowSection: boolean = false;
    canViewLegalTerm: boolean = false;
    canEditLegalTerm: boolean = false;
    canDeleteLegalTerm: boolean = false;


    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));


    /**
     * Variable con identificador de la seccion términos legales y políticas de privacidad
     */
    idSectionLegalTerms: any = this.allModules['module_terms_of_service_privacy_policies'];
    viewLegalTerm: any = this.allModules['module_view_terms_of_service_privacy_policies'];
    editLegalTerm: any = this.allModules['module_edit_terms_of_service_privacy_policies'];
    deleteTermPolicy: any = this.allModules['module_delete_terms_of_service_privacy_policies'];


    //Variable para creacion de formulario
    public legalForm: FormGroup;

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Variables para manejo de mensajes de notificacion
    public title: string = 'Terms of Service';
    public content: string = "created succesfully";

    //Variable para manejo de lenguaje
    userLang: string = "";

    //Variable para control de agregar/editar una regla
    canEdit: boolean = true;

    //Variable para control de errores en llenado de informacion
    errorLegal: boolean = false;
    errorMessage: string = "";

    //Variables para manejo de información de términos de servicio con entidad legal
    legalTerms: legalTerm[];
    @Input() legalTerm: legalTerm;
    selectedLegalTerm: legalTerm;
    displayLegalTerm: legalTerm;
    legalTermUpdated: any;
    legalTermDelete: any;

    //Variables para manejo de información de políticas de privacidad con entidad legal
    privacyPolicies: legalTerm[];
    @Input() privacyPolicy: legalTerm;

    //Variable para manejo de error al enviar peticiones a los diferentes web services
    error: any;

    //Variable para establecer la fecha minima de creación de Término o Política
    minDate: any;
    today: any;

    //Variables que identifican los tipos de servicios
    typeLegalTerm: number = 1;
    typePrivacyPolicy: number = 2;

    /**
     * Variable con opciones del datepicker
     */
    datepickerOpts: any = {
        startDate: new Date(),
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: true,
        assumeNearbyYear: true,
        format: 'm/d/yyyy'
    };

    date = new Date(2017, 5, 20);

    /**
     * Variable que captura el listado de modulos habilitados en OMT
     */
    permissions: any;

    ngOnInit() {

        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionLegalTerms);

        setTimeout(() => {
            if (this.canShowSection) {
                this.canViewLegalTerm = this.permissionsUserService.verifyPermissionsUser(this.viewLegalTerm);
                this.canEditLegalTerm = this.permissionsUserService.verifyPermissionsUser(this.editLegalTerm);
                this.canDeleteLegalTerm = this.permissionsUserService.verifyPermissionsUser(this.deleteTermPolicy);
                this.userLang = navigator.language.split('-')[0];

                this.getLegalTerms();
                this.getPrivacyPolicies();

                this.minDate = this.utils.extractDate(new Date(Date()).toLocaleString('en').substr(0, 10));

                this.today = new Date(Date()).toLocaleString('en').substr(0, 10);

                //Manejo de los taps
                jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
                    if (e.relatedTarget) {
                        jQuery(e.relatedTarget).removeClass('active');
                    }
                });
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);
    }

    /**
     * Metodo encargado de solicitar el listado de las términos de servicio al web service
     */
    getLegalTerms() {
        this.legalService
            .getLegalTerms(this.userLang)
            .then(legalTerms => {
                this.legalTerms = legalTerms;
                setTimeout(() => {
                    let validity = "";
                    let existValidity: boolean = false;
                    //Array para guardar las fechas de los términos
                    let arrayDates = [];
                    for (var i = 0; i < this.legalTerms.length; i++) {
                        //Seteo del array con las fechas de los términos
                        arrayDates.push(new Date(this.utils.extractDate(this.legalTerms[i].startDate.substr(0, 10))));
                        //Validación de las fechas para ocultar o mostrar los botones de editar y eliminar término
                        this.legalTerms[i].canShow = this.utils.compareDates(this.today, this.legalTerms[i].startDate.substr(0, 10));
                        //Validacion del estado del término según la fecha (Obsoleto, Vigencia, Proxima)
                        validity = this.defineValidityLegalTerm(this.today, this.legalTerms[i].startDate.substr(0, 10), this.utils.extractDate(this.legalTerms[i].endDate.substr(0, 10)));

                        //Se valida que si existe un término o política en vigencia
                        if (validity == 'Validity') {
                            existValidity = true;
                        }

                        this.legalTerms[i].validity = validity;
                    }
                    //Si no existe un término o política en vigencia se consulta cual es la fecha mas reciente de las
                    //mismas para establecer esta como vigente
                    if (!existValidity) {
                        let position = this.nearDateToCurrent(new Date(this.today), arrayDates);
                        this.legalTerms[position].validity = 'Validity';
                    }
                }, 600);
            })
            .catch(error => this.error = error);
    }

    /**
     * Metodo encargado de solicitar el listado de las políticas de privacidad al web service
     */
    getPrivacyPolicies() {
        this.legalService
            .getPrivacyPolicies(this.userLang)
            .then(privacyPolicies => {
                this.privacyPolicies = privacyPolicies;
                setTimeout(() => {
                    let validity = "";
                    let existValidity: boolean = false;
                    let arrayDates = [];
                    for (var i = 0; i < this.privacyPolicies.length; i++) {
                        //Seteo del array con las fechas de los términos
                        arrayDates.push(new Date(this.utils.extractDate(this.privacyPolicies[i].startDate.substr(0, 10))));
                        //Validación de las fechas para ocultar o mostrar los botones de editar y eliminar políticas
                        this.privacyPolicies[i].canShow = this.utils.compareDates(this.today, this.privacyPolicies[i].startDate.substr(0, 10));

                        //Validacion del estado del término según la fecha (Obsoleto, Vigencia, Proxima)
                        validity = this.defineValidityLegalTerm(this.today, this.utils.extractDate(this.privacyPolicies[i].startDate.substr(0, 10)), this.utils.extractDate(this.privacyPolicies[i].endDate.substr(0, 10)));
                        //Seteo de los estados de las políticas
                        this.privacyPolicies[i].validity = validity;

                        //Se valida que si existe un término o política en vigencia
                        if (validity == 'Validity') {
                            existValidity = true;
                        }
                    }

                    //Si no existe un término o política en vigencia se consulta cual es la fecha mas reciente de las
                    //mismas para establecer esta como vigente
                    if (!existValidity) {
                        let position = this.nearDateToCurrent(new Date(this.today), arrayDates);
                        this.privacyPolicies[position].validity = 'Validity';
                    }

                }, 600);
            })
            .catch(error => this.error = error);
    }

    /**
    * Metodo encargado de crear la estructura del formulario que se 
    * utilizará para manejo de los datos
    */
    createForm() {
        let id: FormControl = new FormControl('');
        let startDate: FormControl = new FormControl('');
        let name: FormControl = new FormControl('', [<any>Validators.required]);
        let rulesEn: FormControl = new FormControl('', [<any>Validators.required]);
        let rulesSp: FormControl = new FormControl('', [<any>Validators.required]);

        this.legalForm = this._fb.group({
            id: id,
            name: name,
            startDate: startDate,
            rulesEn: rulesEn,
            rulesSp: rulesSp
        })
    }

    /**
     * Metodo encargado de setear las opciones del datePicker
     */
    setOptionsDatePicker() {

        let datepickerOpts: any = {
            startDate: new Date(),
            autoclose: true,
            todayBtn: 'linked',
            todayHighlight: true,
            assumeNearbyYear: true,
            format: 'm/d/yyyy',
            keyboardNavigation: false,
            language: this.userLang
        };

        return datepickerOpts;
    }

    /**
     * Metodo encargado de remover la capacidad de escribir en el campo de fecha del modal
     * de crear o editar un término o una política
     */
    notKeyPress() {
        return false;
    }

    /**
    * Metodo encargado de abrir el modal para agregar una nueva regla
    */
    addLegalModal(modal) {



        this.modalService.open(modal);
        setTimeout(() => {
            jQuery('#datePickerEdit').datepicker(this.setOptionsDatePicker());
            // jQuery('#addEditRulerModal').modal('show');
            jQuery("#nameLegalTerm").prop('selectedIndex', 0);
        }, 300);
    }

    /**
     * Metodo encargado de cerrar el modal de agregar/editar reglas
     * Debe limpiar los datos que se hayan ingresado en el formulario en caso que no se
     * hayan enviado
     */
    closeModal() {
        this.canEdit = false;
        jQuery('#addEditRulerModal').modal('hide');
        this.getLegalTerms();
        this.getPrivacyPolicies();
        jQuery('#datePickerEdit').datepicker('update', '');

        if (this.legalForm.valid) {
            for (let name in this.legalForm.controls) {
                this.legalForm.controls[name]['updateValue']('');
            }
        }

        this.canEdit = true;
        this.enabledFillLegalTerm();
    }

    /**
     * Metodo encargado de validar que el formulario haya sido llenado correctamente
     */
    validateForm(ruleData: any, formValid: boolean) {
        let startDate = jQuery('#datePickerEdit').val();
        if (!formValid) {
            this.errorLegal = true;
            if (this.userLang = 'es') {
                this.errorMessage = 'Por favor complete los campos obligatorios';
            } else {
                this.errorMessage = 'Please complete the required fields';
            }
            return false;
        }

        if (startDate == '') {
            this.errorLegal = true;
            if (this.userLang = 'es') {
                this.errorMessage = 'Debe seleccionar una fecha para crear o editar';
            } else {
                this.errorMessage = 'You must select a date to create or edit';
            }
            jQuery('#datePickerEdit').focus();
            return false;
        }

        return true;

    }

    /**
     * Metodo encargado de mostrar el datepicker cuando se de click en el icono del input
     */
    showDatePicker() {
        jQuery('#datePickerEdit').datepicker('show');
    }

    /**
     * Metodo encargado de enviar los datos para crear/editar una nueva regla en el sistema
     * @param ruleData: any -> Información de la regla a crear/editar
     * @param formValid: boolean -> Validacion que el formulario se haya llenado correctamente
     */
    onSubmit(ruleData: any, formValid: boolean) {
        if (!this.validateForm(ruleData, formValid)) {
            setTimeout(() => {
                this.errorLegal = false;
            }, 3000);
            return false;
        }
        let idRule: number;

        ruleData.startDate = this.utils.extractDate(jQuery('#datePickerEdit').val());

        if (!ruleData.id) {
            //Creación del término de servicio
            this.legalService
                .postCreateLegalTerm(ruleData, this.userLang)
                .then(legalTerm => {
                    this.legalTerm = legalTerm;

                    setTimeout(() => {
                        idRule = this.legalTerm['data'].id;
                        this.editContentLegalTerm(this.legalTerm['data'].id, ruleData, 'Add')
                    }, 800);
                })
                .catch(error => this.error = error);
        } else if (ruleData.id) {
            setTimeout(() => {
                this.editContentLegalTerm(ruleData.id, ruleData, 'Edit')
            }, 700);
        }
    }

    /**
     * Metodo encargado de modificar el contenido de un termino de servicio
     * @param idLegalTerm: number -> Id del termino a modificar
     * @param legalTermData: legalTerm -> Informacion del termino de condición
     */
    editContentLegalTerm(idLegalTerm: number, legalTermData: legalTerm, typePetition: string) {
        let language: string = 'en';

        if (this.userLang == 'es') {

            //Definimos el titulo de la notificación dependiendo del tipo de regla (Término o Política)
            if (parseInt(legalTermData.name) == 1) {
                this.title = "Término de Servicio";
            } else {
                this.title = "Política de Privacidad";
            }

            if (typePetition == 'Edit') {
                this.content = 'editado correctamente';
            } else {
                this.content = 'creado correctamente';
            }
        } else {

            //Definimos el titulo de la notificación dependiendo del tipo de regla (Término o Política)
            if (parseInt(legalTermData.name) == 1) {
                this.title = "Term of Service";
            } else {
                this.title = "Privacy Policy";
            }

            this.title = "Terms of Service";
            if (typePetition == 'Edit') {
                this.content = 'edited succesfully';
            } else {
                this.content = "created succesfully";
            }
        }

        for (var index = 0; index < 2; index++) {
            if (index > 0) {
                language = 'es';
                this._service.success(this.title, this.content, { id: 123 });
            }
            //setTimeout(() => {
            this.legalService
                .putContentLegalTerm(legalTermData, idLegalTerm, this.userLang, language)
                .then(legalTermUpdated => {
                    this.legalTermUpdated = legalTermUpdated;
                    this.getLegalTerms();
                    this.getPrivacyPolicies();
                    jQuery('#addEditRulerModal').modal('hide');
                    this.closeModal();
                })
                .catch(error => this.error = error);
            //}, 800);
        }

    }

    /**
     * Metodo encargado de abrir el modal para editar un término de servicio
     * Se setean los datos necesarios para el correcto funcionamiento del modal
     * @param legalTermData : legalTerm -> Término o Política que se va a editar
     * @param ruleType : any -> Variable que determina si es Término o Política que se va a editar
     */
    editLegalTermModal(legalTermData: any, ruleType: any, modal) {
        this.selectedLegalTerm = new legalTerm;

        setTimeout(() => {
            this.selectedLegalTerm = legalTermData;

            var startDate = this.selectedLegalTerm.startDate.substr(0, 10);

            this.canEdit = this.utils.compareDates(this.today, startDate);
            if (!this.canEdit) {
                this.disabledFillLegalTerm();
            } else {
                this.enabledFillLegalTerm();
            }

            //Seteo de Horas para mostrar en el calendario
            this.selectedLegalTerm.startDate = this.utils.extractDate(this.selectedLegalTerm.startDate);
            this.selectedLegalTerm.endDate = this.utils.extractDate(this.selectedLegalTerm.endDate);
            //jQuery('.q-inputtimepicker').val(startDate);
            jQuery('#datePickerEdit').datepicker(this.setOptionsDatePicker());
            jQuery('#datePickerEdit').datepicker('update', new Date(startDate));

            //Seteo de Nombre (Temporal Mientras se define la forma en el web service)
            this.selectedLegalTerm.typeRule = ruleType;

            //Seteo de las descripciones (Ingles y Español)
            for (var i = 0; i < legalTermData.langs.length; i++) {
                if (legalTermData.langs[i]['language'] === 'en') {
                    if (ruleType == 1) {
                        this.selectedLegalTerm.rulesEn = legalTermData.langs[i]['termsDescription'];
                    } else {
                        this.selectedLegalTerm.rulesEn = legalTermData.langs[i]['privacyDescription'];
                    }
                } else {
                    if (ruleType == 1) {
                        this.selectedLegalTerm.rulesSp = legalTermData.langs[i]['termsDescription'];
                    } else {
                        this.selectedLegalTerm.rulesSp = legalTermData.langs[i]['privacyDescription'];
                    }
                }
            }
        }, 400);
        this.modalService.open(modal);

        setTimeout(() => {

            //jQuery('#addEditRulerModal').modal('show');
        }, 600);

        this.canEdit = false;
    }

    /**
     * Metodo encargado de deshabilitar campos para edición de términos o políticas 
     * cuando la fecha de inicio superior o igual a la actual
     */
    disabledFillLegalTerm() {
        jQuery('#nameLegalTerm').prop('disabled', true);
        jQuery('#startDateLegal').prop('disabled', true);
        jQuery('#endDateLegal').prop('disabled', true);
        jQuery('#rulesEn').prop('disabled', true);
        jQuery('#rulesSp').prop('disabled', true);
        jQuery('#btnSaveLegalTerms').prop('hide', true);
        jQuery('#nameLegalTerm').addClass('disabled');
        jQuery('#startDateLegal').addClass('disabled');
        jQuery('#endDateLegal').addClass('disabled');
        jQuery('#rulesEn').addClass('disabled');
        jQuery('#rulesSp').addClass('disabled');
    }

    /**
     * Metodo encargado de habilitar campos para edición de términos o políticas 
     */
    enabledFillLegalTerm() {
        jQuery('#nameLegalTerm').prop('disabled', false);
        jQuery('#startDateLegal').prop('disabled', false);
        jQuery('#endDateLegal').prop('disabled', false);
        jQuery('#rulesEn').prop('disabled', false);
        jQuery('#rulesSp').prop('disabled', false);
        jQuery('#btnSaveLegalTerms').prop('hide', false);
        jQuery('#nameLegalTerm').removeClass('disabled');
        jQuery('#startDateLegal').removeClass('disabled');
        jQuery('#endDateLegal').removeClass('disabled');
        jQuery('#rulesEn').removeClass('disabled');
        jQuery('#rulesSp').removeClass('disabled');
    }

    /**
     * Metodo encargado de abrir el modal para mostrar los detalles de un término de servicio
     * o de una política de servicio
     */
    detailsLegalTermModal(legalTermData: any, typeRule: any, modal) {
        this.displayLegalTerm = legalTermData;
        this.displayLegalTerm.endDate = this.utils.extractDateHour(this.displayLegalTerm.endDate, true);
        this.displayLegalTerm.typeRule = typeRule;
        for (var i = 0; i < legalTermData.langs.length; i++) {
            if (legalTermData.langs[i]['language'] == 'en') {
                if (typeRule == 1) {
                    this.displayLegalTerm.rulesEn = legalTermData.langs[i]['termsDescription'];
                } else {
                    this.displayLegalTerm.rulesEn = legalTermData.langs[i]['privacyDescription'];
                }
            } else if (legalTermData.langs[i]['language'] == 'es') {
                if (typeRule == 1) {
                    this.displayLegalTerm.rulesSp = legalTermData.langs[i]['termsDescription'];
                } else {
                    this.displayLegalTerm.rulesSp = legalTermData.langs[i]['privacyDescription'];
                }
            }
        }

        this.modalService.open(modal);
        // setTimeout(() => {
        //   jQuery('#showLegalTermModal').modal('show');
        // }, 300);
        this.canEdit = false;
    }

    /**
     * Metodo encargado de cerrar el modal que muestra los detalles de un término de servicio 
     * o de una política de privacidad
     */
    closeDetailtLegalTermModal() {
        this.selectedLegalTerm = new legalTerm;
        jQuery('#showLegalTermModal').modal('hide');
    }

    /**
    * Metodo encargado de mostrar mensaje de confirmacion para eliminar un termino de servicio
    * o política de privacidad
    */
    confirmDelete(legalTermData: any, typeRule: string) {
        legalTermData.name = typeRule;
        this.selectedLegalTerm = legalTermData;
        jQuery('#canDeleteModal').modal('show');
    }

    /**
     * Metodo encargado de eliminar un usuario
     * @param legalTermData: any -> Registro que se quiere eliminar 
     */
    deleteLegalTerm(legalTermData: any, event: any) {
        this.selectedLegalTerm = legalTermData;
        this.content = this.utils.defineContent(this.selectedLegalTerm, this.userLang, 'Deleted');
        if (this.userLang === 'es') {
            this.title = "Términos de servicio";
        } else {
            this.title = "Terms of Service";
        }

        event.stopPropagation();
        this.legalService
            .delete(legalTermData, this.userLang)
            .then(legalTerm => {
                this.legalTermDelete = legalTerm;
                this._service.success(this.title, this.content, { id: 123 });
                setTimeout(() => {
                    jQuery('#canDeleteModal').modal('hide');
                    this.getLegalTerms();
                    this.getPrivacyPolicies();
                }, 1000)
            })
            .catch(error => {
                this.error = this.extractError(error);
                this.errorLegal = true;
                this.errorMessage = this.error.message;
                setTimeout(() => {
                    this.errorLegal = false;
                }, 3000);
            });
    }

    /**
     * Metodo encargado de capturar el error que puede llegar a generarse al crear un usuario
     */
    extractError(res: Response) {
        let body = res.json();
        return body.errors || {};
    }

    /**
     * Metodo encargado de definir el estado de vigencia de un término o política
     */
    defineValidityLegalTerm(currenDate: any, startDate: any, endDate: any) {
        let crrntDate = new Date(currenDate);
        let dateStart = new Date(startDate);
        let dateEnd = new Date(endDate);
        let validity: string = "";

        if (crrntDate > dateStart) {
            validity = 'Obsolete';
        } else if (crrntDate < dateStart) {
            validity = 'Next';
        } else {
            validity = 'Validity';
        }

        return validity;
    }

    /**
     * Metodo encargado de comparar si la fecha obsoleta puede estar en vigencia en comparacion con
     * las demas fechas
     */
    defineValidityInArray(date: any, arrayDates: any, currenDate: any) {
        let compareDate: any;
        let validity;

        for (var i = 0; i < arrayDates.length; i++) {
            compareDate = this.utils.extractDate(arrayDates[i].startDate.substr(0, 10));
            if (date != compareDate && date > compareDate && date < currenDate) {
                validity = 'Validity';
            } else {
                validity = 'Obsolete';
            }
        }

        return validity;
    }

    /**
     * Metodo encargado encontrar y devolver la fecha mas proxima a la fecha actual 
     * para establecer esta como vigencia de un término o política
     */
    nearDateToCurrent(startDate: any, dates: any) {
        var startTime = +startDate;
        var nearestDate, nearestDiff = -Infinity;
        var position = 0;
        for (var i = 0, n = dates.length; i < n; ++i) {
            var diff = +dates[i] - startTime;
            if (diff < 0 && diff > nearestDiff) {
                nearestDiff = diff;
                nearestDate = dates[i];
                position = i;
            }
        }
        return position;
    }
}