import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Constants } from '../../app.constants';
import { Pagination } from '../../entity/Pagination';
import { AlertsService } from '../../services/alerts.service';
import { RestaurantReviewService } from '../../services/restaurant-review.service';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';

//Variable para manejo de funciones Jquery
declare var jQuery: any;
@Component({
    selector: 'app-restaurant-review-component',
    providers: [Pagination],
    templateUrl: './restaurant-review.component.html',
    styleUrls: ['./restaurant-review.component.css']
})
export class RestaurantReviewComponent implements OnInit {

    // variables para el calendario del rango de fechas en el filtro
    dateRangePicker: Date[];
    currentDate = new Date();

    // variables para filtrar las ordenes
    public startDate: string;
    public endDate: string;

    /**
     * Contiene el id que se envia por parametro
     */
    @Input() idRestaurant: string;
    /**
     * Indicador si es para mostrar todos los reviews
     */
    @Input() allReviews: boolean;
    /**
     * Id de restaurnte cuando se pasa por url /restaurant/{idRestaurantParam}/reviews
     */
    public idRestaurantParam: string = null;
    /**
     * se debe crear un objecto tipo lista de la respuesta.
     * COntiene los comentarios por cada restaurante
     */
    public reviews: any;
    /**
     * Manejador de modales
     */
    public modalRef: BsModalRef;
    /**
     * Esto se debe pasar  a un objecto tipo restaurante
     * contiene el restaurante seleccionado para eliminar
     */
    public selectedEntity: any;
    /**
     * es el index del object reviews
     */
    private indexSelected: number;
    /**
     * inactiva los botones
     */
    public buttonStatus = false;
    /**
     * parametros de la consulta de ordenes
     */
    private paramsQuery = '?items_per_page=25';
    /**
       * Tiene todos los datos de paginación
       * es necesario crear un objecto para esto
       *  currentPage
       *   firstPage
       *   itemsInCurrentPage
       *   itemsPerPage
       *   lastPage
       *   nextPage
       *   prevPage
       *   totalItems
       */
    public pagination;

    /**
      * Variable para el manejo de paginacion
      */
    actiClase: string;


    //Order Column Icon
    orderIconObject: string = 'fa fa-chevron-up';
    orderIcon2Object: string = 'fa fa-chevron-up';

    /**
     * Textos de los botones de paginación
     */
    public nextPag: string;
    public prevPag: string;
    public lastPag: string;
    public firstPag: string;

    /**
    * Para mostrar por defecto el Select Status en el
    * filtro de selecionar el criterio 
    * -1 para Select Status
    * 0 para PENDING
    * 1 para PENDING
    */
    public filterStatus: any;

    /**
     * Guarda el revie para mostrarlo en el modal del detalle
     */
    public detailReview: any;

    /**
     * Para enviar pericion al WS para que busque los reviews con wl parametro de resturante
     */
    public restaurantToSearch: any;

    /**
     * Usuario con el cual se quiere filtrar
     */
    public username: any;

    /**
     * para saber si se muestra ono el input de buscar restaurante
     */
    public sohwInputRestaurantToSearch: boolean;
    processingResponse: boolean;

    constructor(
        private reviewsService: RestaurantReviewService,
        private modalService: BsModalService,
        private translate: TranslateService,
        public alertService: AlertsService,
        private route: ActivatedRoute,
        public maxSize: Pagination
    ) {
        this.filterStatus = Constants.STATUS_REVIEWS_PENDING;
        this.restaurantToSearch = '';
        this.username = '';
        this.sohwInputRestaurantToSearch = false;
    }

    ngOnInit() {
        const url = document.location.href;
        this.allReviews = url.includes("allReviews");
        if (this.allReviews){
            this.putClassActive();
        }
        // Falta consultar el restaurante para obtener el nombre 
        if (!this.idRestaurant) {
            this.route.params.subscribe(params => this.idRestaurantParam = params.id);
            this.idRestaurant = this.idRestaurantParam;
        }

        this.textButtonsPagination();
        this.getReviews(Constants.STATUS_REVIEWS_PENDING);
    }

    /**
     * Metodo encargado de poner clase activa en el sidebar para identificar
     * que se esta trabajando dentro de la sección restaurante
     */
    putClassActive() {
        setTimeout(() => {
            jQuery('.reviewsSidebar').addClass('active');
        }, 100);
    }

    /**
     * Hace las traducciones necesarias para los botones de paginación
     */
    textButtonsPagination() {
        this.translate.get(["Next", "Previous", "lastPage", "firstPage"]).subscribe(translations => {
            this.nextPag = translations.Next;
            this.prevPag = translations.Previous;
            this.lastPag = translations.lastPage;
            this.firstPag = translations.firstPage;
        });
    }

    /**
     * Se encarga de refrescar el listado de reviews y la variable del filtro
     * 
     * @author Angel Andres Diaz Calle 9-Jul-2018
     * @version 1.1.2
     */
    getReviewsRefresh() {
        this.restaurantToSearch = '';
        this.username = '';
        this.dateRangePicker = [];
        this.startDate = '';
        this.endDate = '';
        this.getReviews(this.filterStatus = '');

    }

    /**
     * Trae los reviews swgun criterio de busqueda
     * 
     * @author Angel Andres Diaz Calle - 9-Jul-2018
     * @version 1.1.2s
     * @param status estado del review
     * @param eventPage 
     */
    getReviews(status: string = '', eventPage: any = null): void {
        let params = this.paramsQuery;

        if (this.dateRangePicker && this.dateRangePicker.length > 0) {
            this.startDate = moment(this.dateRangePicker[0]).format('DD-MM-YYYY');
            this.endDate = moment(this.dateRangePicker[1]).format('DD-MM-YYYY');
        }

        if ((this.startDate && this.endDate) && (this.startDate !== '' && this.endDate !== '')) {
            params += '&startDate=' + this.startDate + '&endDate=' + this.endDate;
        }

        if (status !== '' && status !== null) {
            params += '&status=' + status;
        } else {
            params += '&status=';
        }
        if (eventPage) {
            params += '&page=' + eventPage.page;
        } else {
            params += '&page=1';
        }

        if (this.restaurantToSearch !== '' && this.restaurantToSearch !== null) {
            params += '&freeTextRestaurant=' + this.restaurantToSearch;
        } else {
            params += '&freeTextRestaurant='
        }

        if (this.username !== '' && this.username !== null) {
            params += '&username=' + this.username;
        } else {
            params += '&username='
        }

        this.buttonStatus = true;
        this.reviewsService.getAll(this.idRestaurant, this.allReviews, params)
            .subscribe(response => {
                if (!response['errors']) {
                    this.reviews = response['data'];
                    this.pagination = response['pagination'];
                }
                this.buttonStatus = false;
            }, error => {
                console.log(error);
                this.buttonStatus = false;
            });
    }
    /**
     * Mustra una modal para confirmar la eliminación del comentario
     * @param restaurant 
     * @param modalConfirmation 
     */
    confirmDelete(indexRevew: number, review: any, modalConfirmation: string): void {
        this.modalRef = this.modalService.show(modalConfirmation);
        this.selectedEntity = review;
        this.indexSelected = indexRevew;

    }
    /**
     * Función que elimina el comentario
     */
    deleteReview(): void {
        this.processingResponse = true;
        if (!this.idRestaurant) {
            this.idRestaurant = this.selectedEntity.restaurantId;
        }
        this.reviewsService.delete(this.selectedEntity.id, this.idRestaurant)
            .subscribe(response => {
                this.processingResponse = false;
                if (!response['errors']) {
                    this.alertService.successAdd("REVIEW", "deletedSuccessfully");
                    this.reviews.splice(this.indexSelected, 1);
                    setTimeout(() => {
                        this.modalRef.hide();
                    }, 500);
                }
            }, error => { console.log(error); });
    }
    /**
     * Cambia el status de pendiende a abrobado o viceversa
     */
    changeStatus(item, status) {
        item.status = status;
        this.reviewsService.changeStatus(
            {
                'review_id': item.id,
                'status': item.status
            }).subscribe(response => {
                this.getReviews(this.filterStatus);
            }, error => {
                console.log(error);
            });
    }

    goToRestaurant() {
        window.history.back();
    }

    /**
     * permite abrir el modal que muestra el review completo
     * 
     * @author Angel Andres Diaz Calle  09-Jul-2018
     * @version 1.1.0
     * @param idReview id del review
     */
    showDetails(review: any, modal) {
        this.detailReview = review;
        this.modalRef = this.modalService.show(modal);
        // this.selectedCategory = category;
    }

    public openModalInfo() {
        this.modalService.show(ModalInfoComponent, { initialState:{openModal: 'reviews-info'}, class: 'overflow-in-modal' });
    }

}
