<h1 class="page-title">{{ 'TermsServicePrivacyPolicy' | translate }} </h1>

<div class="row">
    <div class="col-md-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                </h4>
            </header>
            <div class="widget-body">
                <div class="form-group row">
                    <div class="col-md-12 col-xs-12">
                        <p class="text-xs-right">
                            <a class="btn btn-success" (click)="addLegalModal(addEditRulerModal)">
                                <i class="fa fa-plus"></i> {{ 'Add' | translate }}
                            </a>
                        </p>
                    </div>
                </div>

               
                <ngb-tabset>
                    <ngb-tab title="Terms of Service">
                      <ng-template ngbTabContent>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ 'StartDate' | translate }}</th>
                                        <th>{{ 'Type' | translate }}</th>
                                        <th class="txtcntr">{{ 'Users' | translate }}</th>
                                        <th class="txtcntr">{{ 'Status' | translate }}</th>
                                        <th class="txtcntr">{{ 'Actions' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of legalTerms">
                                        <td>{{ item.startDate }}</td>
                                        <td>{{ 'TermsService' | translate }}</td>
                                        <td class="txtcntr">0</td>
                                        <td class="txtcntr">
                                            <label *ngIf="item.validity == 'Obsolete'">{{ 'Obsolete' | translate }}</label>
                                            <strong *ngIf="item.validity == 'Validity'">{{ 'Validity' | translate }}</strong>
                                            <label *ngIf="item.validity == 'Next'">{{ 'Next' | translate }}</label>
                                        </td>
                                        <td>
                                            <p class="text-xs-center">
                                                <button *ngIf="canViewLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="detailsLegalTermModal(item, typeLegalTerm , showLegalTermModal)">
                                            <i class="fa fa-eye text-info"></i>                                            
                                        </button>
                                                <button *ngIf="item.canShow && canEditLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Edit' | translate }}" (click)="editLegalTermModal(item, typeLegalTerm, addEditRulerModal)">
                                            <i class="fa fa-pencil text-warning"></i>                                            
                                        </button>
                                                <button *ngIf="item.canShow && canDeleteLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, typeLegalTerm)">
                                            <i class="fa fa-eraser text-danger"></i>                                            
                                        </button>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                      <ng-template ngbTabTitle>{{ 'PRIVACY_POLICY' | translate }}</ng-template>
                      <ng-template ngbTabContent>
                            <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>{{ 'StartDate' | translate }}</th>
                                                <th>{{ 'Type' | translate }}</th>
                                                <th class="txtcntr">{{ 'Users' | translate }}</th>
                                                <th class="txtcntr">{{ 'Status' | translate }}</th>
                                                <th class="txtcntr">{{ 'Actions' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of privacyPolicies">
                                                <td>{{ item.startDate }}</td>
                                                <td>{{ 'PrivacyPolicy' | translate }}</td>
                                                <td class="txtcntr">0</td>
                                                <td class="txtcntr">
                                                    <label *ngIf="item.validity == 'Obsolete'">{{ 'Obsolete' | translate }}</label>
                                                    <strong *ngIf="item.validity == 'Validity'">{{ 'Validity' | translate }}</strong>
                                                    <label *ngIf="item.validity == 'Next'">{{ 'Next' | translate }}</label>
                                                </td>
                                                <td>
                                                    <p class="text-xs-center">
                                                        <button *ngIf="canViewLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="detailsLegalTermModal(item, typeLegalTerm , showLegalTermModal)">
                                                    <i class="fa fa-eye text-info"></i>                                            
                                                </button>
                                                        <button *ngIf="item.canShow && canEditLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Edit' | translate }}" (click)="editLegalTermModal(item, typePrivacyPolicy, addEditRulerModal)">
                                                    <i class="fa fa-pencil text-warning"></i>                                            
                                                </button>
                                                        <button *ngIf="item.canShow && canDeleteLegalTerm" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, typePrivacyPolicy)">
                                                    <i class="fa fa-eraser text-danger"></i>                                            
                                                </button>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                      </ng-template>
                    </ngb-tab>
                    
                  </ngb-tabset>
                <!-- <simple-notifications [options]="options"></simple-notifications> -->
            </div>

            <!-- Modal para agregar/editar una regla -->
            <ng-template class="modal fade" #addEditRulerModal data-backdrop="static">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" (click)="closeModal()" aria-hidden="true">&times;</button>
                            <h4 class="modal-title text-xs-center fw-bold mt"> {{ 'Legal' | translate }} </h4>
                        </div>
                        <div class="modal-body bg-body-light">

                            <div *ngIf="errorLegal">
                                <div class="alert alert-danger alert-dismissable nlblk">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>{{errorMessage}}</div>
                            </div>

                            <div *ngIf="!canEdit">
                                <div class="alert alert-warning alert-dismissable nlblk">
                                    <strong>{{ 'CanNotEditLegalTerm' | translate }}</strong>
                                </div>
                            </div>

                            <form class="form-horizontal" [formGroup]="legalForm" novalidate>
                                <div class="col-md-12" >

                                    <input type="hidden" formControlName="id">

                                    <div class="form-group row">
                                        <label class="col-md-4">{{ 'Name' | translate }} *</label>
                                        <div class="col-md-8">
                                            <select id="nameLegalTerm" formControlName="name" name="typeName" class="form-control select2">
                                                <option id="0" value=""> {{ 'Select' | translate }} </option>
                                                <option id="1" value="1"> {{ 'TermsService' | translate }} </option>
                                                <option id="2" value="2"> {{ 'PrivacyPolicy' | translate }} </option>
                                            </select>
                                        </div>
                                    </div>                                    

                                    <div class="form-group row">
                                        <label class="col-md-4">{{ 'StartDate' | translate }} *</label>
                                        <div class="col-md-8 input-group">
                                            <input (keypress)="notKeyPress()" formControlName="startDate" type="text" id="datePickerEdit" class="form-control">
                                            <div class="input-group-addon cursor-pointerP">
                                                <span (click)="showDatePicker()" class="fa fa-calendar"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-md-4">{{ 'Description' | translate }} ({{ 'En' | translate }}) *</label>
                                        <div class="col-md-8">
                                            <textarea  type="text" formControlName="rulesEn" class="form-control" id="rulesEn"
                                                placeholder="{{ 'Description' | translate }} ({{ 'En' | translate }})" rows="5"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-md-4">{{ 'Description' | translate }} ({{ 'Sp' | translate }}) *</label>
                                        <div class="col-md-8">
                                            <textarea type="text" formControlName="rulesSp" class="form-control" id="rulesSp"
                                                placeholder="{{ 'Description' | translate }} ({{ 'Sp' | translate }})" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button *ngIf='canEdit' id="btnSaveLegalTerms" type="submit" [disabled]="!legalForm.valid" (click)="onSubmit(legalForm.value, legalForm.valid)"
                                class="btn btn-success mb-xs">{{ 'Save' | translate }}</button>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- Modal para mostrar los detalles de un Término -->
            <ng-template  class="modal fade" #showLegalTermModal data-backdrop="static">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" (click)="closeDetailtLegalTermModal()" aria-hidden="true">&times;</button>
                            <h4 *ngIf="displayLegalTerm" class="modal-title text-xs-center fw-bold mt">
                                <strong *ngIf="displayLegalTerm.typeRule == 1">{{ 'TermsService' | translate }}</strong>
                                <strong *ngIf="displayLegalTerm.typeRule == 2">{{ 'PrivacyPolicy' | translate }}</strong>
                            </h4>
                        </div>
                        <div class="modal-body bg-body-gray">
                            <div *ngIf="displayLegalTerm">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table class="table table-striped table-hover tablesorter">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th width="30%">{{ 'Status' | translate }}</th>
                                                    <td>
                                                        <strong *ngIf="displayLegalTerm.validity == 'Obsolete'">{{ 'Obsolete' | translate }}</strong>
                                                        <strong *ngIf="displayLegalTerm.validity == 'Validity'">{{ 'Validity' | translate }}</strong>
                                                        <strong *ngIf="displayLegalTerm.validity == 'Next'">{{ 'Next' | translate }}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">{{ 'StartDate' | translate }}</th>
                                                    <td>{{displayLegalTerm.startDate }}</td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">{{ 'Description' | translate }} ({{ 'En' | translate }})</th>
                                                    <td>{{displayLegalTerm.rulesEn}}</td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">{{ 'Description' | translate }} ({{ 'Sp' | translate }})</th>
                                                    <td>{{displayLegalTerm.rulesSp}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </ng-template >

            <!-- Modal para confirmar acción eliminar -->
            <div class="modal fade" id="canDeleteModal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18"><i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
                            </h4>
                        </div>
                        <div class="modal-body bg-gray-lighter">
                            <p class="text-xs-center">
                                {{ 'WantDelete' | translate }}
                                <strong>{{ 'TermsService' | translate }}</strong>?
                            </p>
                        </div>
                        <div class="modal-footer">
                            <div *ngIf="selectedLegalTerm">
                                <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true">{{ 'Cancel' | translate }}</button>
                                <button type="button" class="btn btn-success" (click)="deleteLegalTerm(selectedLegalTerm, $event)">{{ 'Confirm' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</div>
<!-- <inspectPermits></inspectPermits> -->