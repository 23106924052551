import { of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';

import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class UserLoginService {
    constructor(
        private http: Http,
        private utils: UtilsComponent,
        public _http: HttpClient
    ) {
        this.loggedIn = !!localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
    }

    private loggedIn = false;
    private omtUrl = this.utils.getUrlOmt() + 'sd/';


    /**
     * Metodo encargado de extraer el contenido de las consultas
     * realizadas a los diferentes webservices
     */
    private extractData(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    /**
     * Metodo encargado del manejo de errores que se pueden presentar
     * en las consultas a los webservices
     */
    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    allModules(userLang: string, key: string) {
        let headers = new Headers({
            'Api-Key': key,
            'Accept': 'application/json'
        });
        headers.append('Accept-Language', userLang);


        let url = this.omtUrl + 'modules'

        return this.http
            .get(url, { headers: headers })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    postLogin(email: string, password: string, userLang: string) {
        let headers = new Headers({
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        });
        headers.append('Accept-Language', userLang);

        // let body = new URLSearchParams();
        // body.set('username', email);
        // body.set('password', password);
        let arrayData = {
            'username': email,
            'password': password
        }
        let array = JSON.stringify(arrayData);
        let url = this.omtUrl + 'login'

        return this.http
            .post(url, array, { headers: headers })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    postLogout(userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Accept-Language', userLang);
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));

        let url = this.omtUrl + 'logout'

        return this._http
            .post(url, { headers: headers })
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    logout() {
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.USER);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.TYPE);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.ID_USER,);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.DATA_CUSINES);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.DATA_FEATURES);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.DATA_MEALS);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.ENABLED_MODULES);
        localStorage.removeItem(Constants.LOCAL_STORAGE_DATA.MODULES);
        this.loggedIn = false;
    }

    isLoggedIn() {
        return this.loggedIn;
    }

    setLoggedIn() {
        this.loggedIn = true;
    }

    check() {
        return of(this.loggedIn);
    }
}

export function isLoggedIn() {
    return !!localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
}

export function isAdministrator() {
    if (parseInt(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.TYPE)) == 1) {
        return true;
    }
    return false;
}
