import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.sass']
})
export class ModalInfoComponent implements OnInit {

  /**
   * Propiedad que recibe el nombre del cotenido 
   * que se debe mostrar en el html
   */
  public openModal: string;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  /**
   * Cierra el modal
   */
  public closeModal() {
    this.bsModalRef.hide()
  }

}
