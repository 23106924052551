import { ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AlertsService {
    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
    ) { }

    cosas: string;
    //ALERTS 

    alertGestionSuccess(valueSuccess) {
        this.translate.get([valueSuccess]).subscribe(translations => {
            this.toastr.success(translations[valueSuccess]);
        });
    }


    alertGestionError(valueError) {
        this.translate.get([valueError]).subscribe(translations => {
            this.toastr.warning(translations[valueError]);
        });
    }


    alertGestioErrorSyn(valueError) {
        this.translate.get([valueError]).subscribe(translations => {
            this.toastr.error(translations[valueError]);
        });
    }


    // alertSaveSuccess() {
    //   this.translate.get(["SuccessfullyEdited"]).subscribe(translations => {
    //     this.toastr.success(translations.SuccessfullyEdited);
    //   });
    // }

    // alertDeletedSuccess() {
    //   this.translate.get(["deletedSuccessfully"]).subscribe(translations => {
    //     this.toastr.success(translations.deletedSuccessfully);
    //   });
    // }

    // alertEditedSuccess() {
    //   this.translate.get(["editedSuccessfully"]).subscribe(translations => {
    //     this.toastr.success(translations.editedSuccessfully);
    //   });
    // }


    // alertSaveError() {
    //   this.translate.get(["errorSaving"]).subscribe(translations => {
    //     this.toastr.warning(translations.errorSaving);
    //   });
    // }

    // alertDeletedError() {
    //   this.translate.get(["eliminationFailed"]).subscribe(translations => {
    //     this.toastr.warning(translations.eliminationFailed);
    //   });
    // }


    // alertEditedError() {
    //   this.translate.get(["failedEdition"]).subscribe(translations => {
    //     this.toastr.warning(translations.failedEdition);
    //   });
    // }


    // funcion global para el llamado de alertas success en funciones del sistema
    successAdd(moddule, message) {
        this.translate.get([moddule, message]).subscribe(translations => {
            this.toastr.success(translations[message], translations[moddule]);
        });
    }

    // funcion global para el llamado de las alertas de error de funciones del sistema 
    WarningAdd(moddule, message) {
        this.translate.get([moddule, message]).subscribe(translations => {
            this.toastr.warning(translations[message], translations[moddule]);
        });
    }

    // funcion de alerta para validacion de campos vacios del formulario created y editar restaurantes
    AlertGlobalInput(globalInut) {
        this.translate.get([globalInut]).subscribe(translations => {
            this.toastr.warning(translations[globalInut]);
        });
    }


}
