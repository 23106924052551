import { Component, OnInit } from '@angular/core';
import { CouponsService } from 'app/services/coupons.service';
import { finalize } from 'rxjs/operators';
import { Pagination } from 'app/entity/Pagination';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-list-coupons',
  templateUrl: './list-coupons.component.html',
  styleUrls: ['./list-coupons.component.sass']
})
export class ListCouponsComponent implements OnInit {
  buttonStatus: boolean;
  searchFields: any;
  restaurants: any;
  /**
   * It have all the pagination data
   * We need an object for this
   *  currentPage
   *   firstPage
   *   itemsInCurrentPage
   *   itemsPerPage
   *   lastPage
   *   nextPage
   *   prevPage
   *   totalItems
   */
  public pagination: any;
  /**
    * maximun size of pagination
    */
  public maxSize: Pagination;
  constructor(
    private couponsService: CouponsService,
    private toastr: ToastrService
  ) {
    this.maxSize = new Pagination();
  }

  ngOnInit() {
    this.getCouponsList();
  }
  /**
   * Obtain orders from ws
   * @param eventPage if there's pagination
   * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
   * @version 1.0.0
   */
  public getCouponsList(eventPage: any = null) {
    this.buttonStatus = true;
    const params = this.__setParamsQueryOrders(eventPage);
    this.couponsService.getListCouponsByClients(params)
      .pipe(finalize(() => {
        this.buttonStatus = false;
      }))
      .subscribe((x: object) => {
        if (!x['errors']) {
          this.restaurants = x['data'];
          console.log('coupons', this.restaurants);
          this.pagination = x['pagination'];
        }
      });
  }
  /**
   * Redeem the coupon
   * @param restaurant
   * @author Valeria Medina Ramirez - Oct. 16 - 2019
   * @version 1.0.0
   */
  public redeemCoupon(restaurant = null) {
    const dataToSend = {
      idCoupon: restaurant.coupons[0]['idCoupon'],
      idClient: restaurant.coupons[0]['clients'][0]['idClient'],
      idRestaurant: restaurant.idRestaurant
    }
    this.couponsService.redeemCoupon(dataToSend).subscribe(resp => {
      console.log('resp', resp);
      if (!resp['errors']) {
        this.toastr.success(resp['data']['message']);
      } else {
        this.toastr.warning(resp['errors']['message']);
      }
    }, error => {

    });
  }
  /**
  * Refresh the orders list
  * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
  * @version 1.0.0
  */
  public refreshRestaurants() {
    this.getCouponsList();
  }
  /**
   * Se definen los parametros para hacer la consulta de cupones
   * @param eventPage 
   */å
  private __setParamsQueryOrders(eventPage: any): string {
    let params = '';
    if (eventPage) {
      params += '&page=' + eventPage.page;
    } else {
      params += '&page=1';
    }
    return params;
  }
}
