<h1 class="page-title">Dashboard</h1>
<!-- <div *ngIf="generalStatics" class="row"> -->
<div  class="row">
    
  <div class="col-lg-4 col-md-6 col-xs-12 cursor-pointerP" (click)="goToRestaurants(level)" title="{{ 'GoToLevelRestaurantList' | translate }}">
    <div class="widget bg-primary text-white">
      <div class="panel-heading">
        <div class="row flex-nowrap">
          <div class="col-md-2">
            <i class="fa fa-cutlery fa-5x"></i>
          </div>
          <div class="col-md-10 text-right">
           <!-- <div class="huge">{{generalStatics.numberLevelRestaurants}}</div>  -->
           <div class="huge">30</div> 
           
            <div class="titleH2">{{ 'RestaurantLevel' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-xs-12 cursor-pointerP" (click)="goToRestaurants(directory)" title="{{ 'GoToDirectoryRestaurantList' | translate }}">
    <div class="widget bg-info text-white">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-cutlery fa-5x"></i>
          </div>
          <div class="col-md-10 text-right">
           <!-- <div class="huge">{{generalStatics.numberDirectoryRestaurants}}</div> -->
           <div class="huge">20</div>
           
            <div class="titleH2">{{ 'RestaurantDirectory' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-xs-12 cursor-pointerP" (click)="goToClients()" title="{{ 'GoToClientList' | translate }}">
    <div class="widget bg-gray text-white">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-user fa-5x"></i>
          </div>
          <div class="col-md-10 text-right">
            <!-- <div class="huge">{{generalStatics.numberClients}}</div> -->
            <div class="huge">10</div>
            
            <div class="titleH2">{{ 'Clients' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-xs-12">
    <div class="widget bg-warning text-white">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-calendar fa-5x"></i>
          </div>
          <div class="col-md-10 text-right">
            <!-- <div class="huge">iii{{generalStatics.numberRestaurantsWithoutSchedule}}</div> -->
            <div class="huge">15</div>
            
            <div class="titleH2">{{ 'RestarantWithoutHours' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-xs-12">
    <div class="widget bg-danger text-white">
      <div class="panel-heading">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-picture-o fa-5x"></i>
          </div>
          <div class="col-md-10 text-right">
            <!-- <div class="huge">{{generalStatics.numberRestaurantsWithoutPhotos}}</div> -->
            <div class="huge">12</div>
            
            <div class="titleH2">{{ 'RestarantWithoutPhoto' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Nuevos Restaurantes por Mes Level -->
  <div class="col-lg-6 col-md-6 col-xs-12">
    <section class="widget">
      <div class="widget-body">
        <h2>{{ 'NewRestaurantsLevel' | translate }}</h2>
        <div>
          <!-- <base-chart
            class="chart"
            [datasets]="datasetsRestaurantLevel"
           [labels]="labels"
            [options]="options"
            [chartType]="'bar'"
            [colors]="lineChartColors">
          </base-chart> -->
        </div>
      </div>
    </section>
  </div>

  <!-- Nuevos Restaurantes por Mes Directorio  -->
  <div class="col-lg-6 col-md-6 col-xs-12">
    <section class="widget">
      <div class="widget-body">
        <h2>{{ 'NewResaurantsDirectory' | translate }}</h2>
        <div>
          <!-- <base-chart
            class="chart"
            [datasets]="datasetsRestaurantDirectory"
            [labels]="labels"
            [options]="options"
            [chartType]="'bar'"
            [colors]="lineChartColors">
          </base-chart> -->
        </div>
      </div>
    </section>
  </div>

  <!-- Nuevos Usuarios App Movil por Mes  -->
  <div class="col-lg-6 col-md-6 col-xs-12">
    <section class="widget">
      <div class="widget-body">
        <h2>{{ 'NewUsersPerMonth' | translate }}</h2>
        <div>
          <!-- <base-chart
            class="chart"
            [datasets]="datasetsNewUsers"
            [labels]="labels"
            [options]="options"
            [chartType]="'pie'">
          </base-chart> -->
        </div>
      </div>
    </section>
  </div>

  <div class="col-lg-6 col-md-6 col-xs-12">
    <section widget class="widget">
      <header>
        <h4>
          List of <span class="fw-semi-bold">{{ 'Restaurants' | translate }}</span>
        </h4>
      </header>
      <div class="widget-body">
        <img [routerLink]="['RestaurantComponent']" class="pull-left mr-sm" src="assets/images/logo/logo128x128.png" alt="Open My Tab"
          width="100">
        <p class="lead">{{ 'LIST_RESTAURANT_OF' | translate }}<strong>Open My <em>Tab</em></strong></p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
      </div>      
    </section>
  </div>    
</div>
<!-- <inspectPermits></inspectPermits> -->
