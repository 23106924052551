import { Observable, Subscription } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsComponent } from '../utils/utils';
// import { Constants } from 'app/app.constants';
@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(
        private http: HttpClient,
        private utils: UtilsComponent
    ) { }

    /**
     * Consume el ws que obtiene el listado de reglas existentes para crear una notificacion
     */
    public getListOfRules(restaurantId:string): Observable<any> {
        const url = this.utils.getUrlOmt() + 'sd/provideListOfRules?restaurant_id='+restaurantId;
        return this.http.get(url);
    }

    /**
     *  Consume el ws que obtiene el listado de notificaciones
     * @param paramsQuery 
     */
    public getNotificationsList(paramsQuery, itemsPerPage: number): Observable<any> {
        const url = this.utils.getUrlOmt() + 'sd/listMassNotifications?items_per_page='+ itemsPerPage +'&order_by=created_at&sort=0' + paramsQuery;
        return this.http.get(url);
    }

    /**
     * Consume el ws que crea o edita la notificacion masiva
     * @param data 
     */
    public createMassiveNotification(data) {
        if (data.id) {
            return this.editMassiveNotification(data);
        }
        const url = this.utils.getUrlOmt() + 'sd/massiveNotification';
        return this.http.post(url, data);
    }

    /**
     * Edita la notificacion
     * @param notification datos de la notificacion
     */
    public editMassiveNotification(notificacion: any) {
        let array = JSON.stringify(notificacion);
        const url = this.utils.getUrlOmt() + 'sd/editMassiveNotification/' + notificacion.id;
        return this.http.put(url, array);
    }

    /**
     * Para la notificacion de seguir siendo notificada
     * @param idNotification 
     */
    public stopMassiveNotification(idNotification: any, contextNotify) {
        const data = {
            id: idNotification,
            context_notify: contextNotify
        };
        let array = JSON.stringify(data);
        const url = this.utils.getUrlOmt() + 'sd/stopMassiveNotification/' + idNotification;
        return this.http.put(url, array);
    }


    /**
     * Elimina la notificacion 
     * @param idNotification 
     */
    public deleteMassiveNotification(idNotification: any) {
        // confirmar si es este ws
        const url = this.utils.getUrlOmt() + 'sd/deleteMassiveNotification/' + idNotification;
        return this.http.put(url, idNotification);
    }

    /**
     * Obtiene los FRP activos
     * @param restaurantId 
     */
    public getFRP(restaurantId): Observable<any> {
        const url = this.utils.getUrlOmt() + 'sd/restaurant/list/frp?frp_status=1&restaurantId=' + restaurantId;
        return this.http.get(url);
    }

    public getCustomersToMassNotify(params) {

        const url = this.utils.getUrlOmt() + 'getCustomersToMassNotify';

        return this.http.get(url, {params});

    }

}
