export class cuisines {
    id: number;
    lang: Object;
}

export class meals {
    id: number;
    lang: Object;    
}

export class features {
    id: number;
    lang: Object;    
}