import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsComponent } from 'app/utils/utils';
import { Observable } from 'rxjs';
import { RequestOptions, ResponseContentType, Http } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class UserTrackingService {

  constructor(
    private http: HttpClient,
    private utils: UtilsComponent,
    private https: Http
  ) { }

  /**
   * WS to get the reports from specific restaurant
   * @param paramsQuery params restaurant_id, type, status, startDate y endDate
   * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
   * @version 1.0.0
   */
  public getReportsFromUser(paramsQuery: string, groupData = false): Observable<any> {
    if (groupData) {
      const url = this.utils.getUrlOmt() + 'accompanimentClientReport?' + 'is_group=1&' + 'no_info_track=1';
      return this.http.get(url);
    } else {
      const url = this.utils.getUrlOmt() + 'accompanimentClientReport?' + paramsQuery
      return this.http.get(url);
    }
  }

  public getExcelReport(paramsQuery: string) {

    console.log('paramsQuery', paramsQuery);
    

    const url = `${this.utils.getUrlOmt()}accompanimentClientExcelReport?${paramsQuery}`;

    const options = new RequestOptions({
      responseType: ResponseContentType.Blob
  });

    return this.https.get(url, options);
  }

}
