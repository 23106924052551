import { OrderStateModel } from 'app/models/orderState/orderState';
import { Constants } from 'app/app.constants';

import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-change-order-state',
    templateUrl: './change-order-state.component.html',
    styleUrls: ['./change-order-state.component.scss']
})
export class ChangeOrderStateComponent implements OnChanges {
    /**
   * Modelo inicial recibido
   */
    @Input() orderStateModel: Array<OrderStateModel>;

    @Input() inputState: string;

    @Input() buttonProcessing: boolean;

    /**
     * Modelo actualizado por cambio de parametros del cliente
     */
    // @Output() updateModel = new EventEmitter<Array<OrderStateModel>>();

    /**
     * Modelo actualizado por cambio de parametros del cliente
     */
    @Output() sendData = new EventEmitter<number>();

    /**
     * Estado actual del evento de la vista
     */
    public currentState: string;

    /**
     * Alert con lista de estados disponibles
     */
    alertChangeState: any;

    /**
     * Alert que confirma el cambio de estado
     */
    alertConfirmChangeState: any;

    translationKeys: Array<string> = [
        'CONFIRM_CHANGE_STATE_MESSAGE',
        'SELECT_AN_OPTION',
        'TITLE_CHANGE_STATE',
        'CANCEL',
        'YES',
        'NO',

    ];

    /**
       * Llaves de traducción
       */
    translatedKeys: Array<string> = [];

    /**
     * Variable que indica si se debe mostrar el texto "Processando"
     */
    public processingResponse: boolean;


    /**
     * Constructor de la clase
     */
    constructor(public sweet: SweetAlertService, public translate: TranslateService) {
        this.processingResponse = false;
        this.translate.get(this.translationKeys).subscribe(
            value => {
                this.translatedKeys = value;
            }
        );
    }

    /**
     * Indica en que estado esta la orden
     * @author William Alarcón - Oct 20 - 2020
     * @version 1.0.0
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {
        if (this.orderStateModel) {
            // Verifica los estados del modelo y actualiza los estados
            for (let i = 0; i < this.orderStateModel.length; i++) {
                this.orderStateModel[i].isActual = false;
                this.orderStateModel[i].isEnabled = false;
                // Cuando la orden es nueva o no tiene estados
                if (parseInt(this.inputState, 10) === 3) {
                    this.currentState = "Pending";
                    this.orderStateModel[0].isActual = false;
                    this.orderStateModel[0].isEnabled = false;
                }

                if (this.orderStateModel[i].stateConstant === parseInt(this.inputState, 10)) {
                    if (this.orderStateModel[i] !== undefined) {
                        this.orderStateModel[i].isActual = true;
                        this.orderStateModel[i].isEnabled = true;
                        this.currentState = this.orderStateModel[i].state;

                        for (let l = i + 2; l < this.orderStateModel.length; l++) {
                            this.orderStateModel[l].isActual = false;
                            this.orderStateModel[l].isEnabled = true;
                            break;
                        }
                    }
                    this.currentState = this.orderStateModel[i].state;

                }
            }
            // Actualiza el valor de estado inicial en el componente
            const that = this;
            this.orderStateModel.forEach(element => {
                if (element.isActual) {
                    that.currentState = element.state;
                }
            });
        }
    }

    /**
     * Envia modelo al componente que lo usa
     * @author William Alarcón - Oct. 20 - 2020
     * @version 1.0.0
     * @param model
     */
    sendDataModel(status: number) {
        this.sendData.emit(status);
    }

    /**
     * Consume el ws que cambia el estado de la orden
     * @author William Alarcón - Oct. 20 - 2020
     * @version 1.0.0
     */
    public changeOrderStatusOmt() {
        if (Number(this.inputState) === Constants.CONSTANTS_STATE.PREPARED) {
            this.inputState = String(Constants.CONSTANTS_STATE.DELIVERED);
        } else if (Number(this.inputState) >= Constants.CONSTANTS_STATE.DELIVERED) {
            this.inputState = String(Constants.CONSTANTS_STATE.DELIVERED);
            return;
        } else {
            this.inputState = this.inputState + 1;
        }
        this.sendDataModel(parseInt(String(this.inputState), 10));
        for (let i = 0; i < this.orderStateModel.length; i++) {

            if (Number(this.inputState) === this.orderStateModel[i].stateConstant) {

                if (this.orderStateModel[i] !== undefined) {
                    this.orderStateModel[i].isActual = true;
                    this.currentState = this.orderStateModel[i].state;
                    this.orderStateModel[i].isActual = true;
                    this.orderStateModel[i].isEnabled = true;
                    for (let l = i + 2; l < this.orderStateModel.length; l++) {
                        this.orderStateModel[l].isActual = false;
                        this.orderStateModel[l].isEnabled = true;
                    }
                } else if (this.orderStateModel[i + 1] === undefined) {
                    this.currentState = this.orderStateModel[i].state;
                }
                break;
            }
        }
    }
}
