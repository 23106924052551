import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'localize-router/src/localize-router.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { AlertsService } from 'app/services/alerts.service';
import { NotificationsService } from 'app/services/notifications.service';
import * as moment from 'moment';
import { Constants } from 'app/app.constants';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { Pagination } from 'app/entity/Pagination';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit {

  /**
   * Variable que permite acceder al modal de agregar notificacion
   */
  @ViewChild('addNotification') public addNotification: ModalDirective;
  /**
   * Variable que obtiene toda la informacion del detalle del restaurante
   */
  @Input() detailRestaurant;
  /**
   * Variable que contiene todo el formulario para agregar una notificacion masiva
   */
  public notificationForm: FormGroup;
  /**
   * Variable que almacena la lista de reglas existentes para la creacion de una notificacion
   */
  public listOfRules;

  public type_notify: number;
  /**
   * Variable que almacena el listado de notificaciones
   */
  public notificationsList: any;
  /**
   * Variable que maneja como se filtra el listado de notificaciones
   */
  public filterNotifications: { name: string, status: string, dateRangePicker: Array<any> };
  /**
   * Variable que almacena los estados posibles en los que se encuentra una notificacion
   */
  public notificationsStatus: { name: string; number: number; }[];

  /**
   * Indica si se esta procesando la respuesta del ws
   */
  public processingResponse: boolean;

  /**
   * Indica si estan editando la notificacion 
   */
  private isEditing: boolean;

  /**
   * Variable que almacena la hora de inicio de la notificacion
   */
  public start_time: string;
  /**
   * Variable que almacena la hora final de la notificacion
   */
  public end_time: string;

  /**
   * Indica cuales son las tipos de notificaciones
   */
  public TYPE_OF_NOTIFICATION;
  /**
   * Contiene todas las pta
   */
  public dataPTA: any;

  /**
    * It have all the pagination data
    * We need an object for this
    *  currentPage
    *   firstPage
    *   itemsInCurrentPage
    *   itemsPerPage
    *   lastPage
    *   nextPage
    *   prevPage
    *   totalItems
    */
  public pagination: any;

  public ptaSelected: Array<{name: string, id: string}> = [];

  public quantityUsersToNotified: number;

  public userTypeSelected: any;

  /**
   * maximun size of pagination
   */
  public maxSize: Pagination;
  public paginationArray: Array<any>;
  public paginationSelected: number;
  private itemsPerPageSelected: number = 25;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localize: LocalizeRouterService,
    private _fb: FormBuilder,
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private sweet: SweetAlertService,
    private alertService: AlertsService,
  ) {
    this.notificationsStatus = Constants.STATUS_NOTIFICATIONS;
    this.filterNotifications = {
      name: '',
      status: '',
      dateRangePicker: []
    };

    this.TYPE_OF_NOTIFICATION = Constants.TYPE_OF_NOTIFICATION;
    this.maxSize = new Pagination();
    this.paginationArray = [25, 50, 100];
    this.quantityUsersToNotified = null;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.detailRestaurant = params.detailRestaurant ? params.detailRestaurant : this.detailRestaurant
      this._getListOfRules(this.detailRestaurant.id);
    });
    this.createFormNotifications();

    this.getNotificationsList();
    this._getPTA();
  }

  /**
   * Funcion que consume el ws q obtiene el listado de notificaciones
   */
  public getNotificationsList(eventPage: any = null, itemsPerPage: number = 25) {
    const params = this.__setParamsQueryNotifications(eventPage);
    this.paginationSelected = itemsPerPage;
    this.itemsPerPageSelected = itemsPerPage;
    this.notificationsService.getNotificationsList(params, itemsPerPage).pipe(finalize(() => {
      this.paginationSelected = null;
    })).subscribe(resp => {
      if (!resp['errors']) {
        this.notificationsList = resp['data'];
        console.log('this.notificationsList', this.notificationsList)
        this.pagination = resp['pagination'];
      }
    })
  }

  /**
   * Metodo encargado de abrir el modal para enviar una notificacion masiva
   */
  public showModalNotifications(): void {
    this.type_notify = null;
    this.createFormNotifications();
    setTimeout(() => {
      this.addNotification.show();
    }, 500);
  }

  /**
   * Funcion que obtiene la informacion de la notificacion para editarla
   * @param notification 
   */
  public editNotification(notification, startNotify, isEditing = false) {
    // seteamos los datos
    let isConfirmed: boolean = false;
    if (notification.status > 0) {
      isConfirmed = true;
    }
    this.type_notify = Number(notification.constTypeRule);
    this.notificationForm.setValue({
      id: notification.id,
      name: notification.name,
      subject: notification.subject,
      message: notification.message,
      confirm: isConfirmed,
      assimilated_rule: notification.entityRule,
      type_notify: Number(notification.constTypeRule),
      context_notify: notification.context,
      record_id: notification.especificationText,
      start_date: this.separateDate(notification.beginningDate),
      end_date: this.separateDate(notification.endingDate),
      cant_clients: notification.cantCli,
      user_type: notification.clientSinceType,
      notify_unregistered_devices: false,
      json_restaurants: notification.jsonRestaurant,
      section: notification.section
    });
    // manda el parametro en true para que se notifique al ws que debera a notificar la
    // notificacion de inmediato
    if (startNotify) {
      this.onSubmitNotification(this.notificationForm.value, startNotify);
    } else {
      setTimeout(() => {
        this.isEditing = isEditing;
        this.addNotification.show();
      }, 500);
    }
  }


  /**
   * Metodo encargado de crear el formulario para adicion y edicion de notificaciones masivas
   */
  private createFormNotifications() {
    this.notificationForm = this._fb.group({
      id: undefined,
      name: new FormControl('', [<any>Validators.required]),
      subject: new FormControl('', [<any>Validators.required]),
      message: new FormControl('', [<any>Validators.required]),
      confirm: true,
      assimilated_rule: new FormControl('', [<any>Validators.required]),
      type_notify: null,
      record_id: new FormControl(''),
      context_notify: null,
      start_date: new FormControl(''),
      end_date: new FormControl(''),
      cant_clients: new FormControl(''),
      user_type: new FormControl(null, [<any>Validators.required]),
      json_restaurants: undefined,
      section: new FormControl('1', [<any>Validators.required]),
      notify_unregistered_devices: new FormControl(false)
    });
  }

  /**
   * Maneja el click cuando se guarda o se edita una notificacion
   * @param form 
   * @param startNotify
   */
  public onSubmitNotification(form, startNotify = false) {
    form.type_notify = this.type_notify;
    if (form.type_notify == this.TYPE_OF_NOTIFICATION.RULE_PTA && form.record_id == '') {
      this.alertService.WarningAdd("Select", "MUST_SELECT_PTA");
      return;
    }

    this.processingResponse = true;
    if (form.confirm) {
      form.start_date = moment().format('YYYY/MM/DD HH:mm:ss');
      form.end_date = moment().add(8, 'hours').format('YYYY/MM/DD HH:mm:ss');
    } else {
      // convertimos la fecha al formato que el ws recibe 
      form.start_date = this.setFormatDate(form.start_date) + ' ' + moment(this.start_time, 'HH:mm:ss').format('HH:mm:ss');
      form.end_date = this.setFormatDate(form.end_date) + ' ' + moment(this.end_time, 'HH:mm:ss').format('HH:mm:ss');
    }

    // asignamos el tipo de notificacion (rule)

    form.context_notify = 3;
    form.json_restaurants = { [this.detailRestaurant.id]: this.detailRestaurant.name };
    if (startNotify || !form.confirm) {
      this.startNotify(startNotify).then(resp => {
        if (resp) {
          form.confirm = true;
          this.createMassiveNotification(form);
        } else {
          this.processingResponse = false;
          if (startNotify) {
            this.hideAddNotificationsModal();
          } else {
            this.createMassiveNotification(form);
          }
        }
      });
    } else {
      this.createMassiveNotification(form);
    }
  }

  /**
   * Consume el ws que peermite crear o editar una noticacion
   * @param form datos de la creacion/edicion de la notificacion
   */
  private createMassiveNotification(form) {
    this.notificationsService.createMassiveNotification(form).subscribe(resp => {
      this.processingResponse = false;
      if (!resp['errors']) {
        this.hideAddNotificationsModal();
        this.refreshNotifications();
        this.translate.get('CONFIG_CORRECTLY').subscribe(response => {
          this.toastr.success(response);
        });
      } else {
        this.toastr.error(resp['errors']['message']);
      }
    });
  }
  /**
   * Devuelve al detalle principal del restaurante
   */
  public gotoRestaurant() {
    this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
  }

  /**
   * Obtiene el listado de reglas de las notificaciones
   * @version 1.0.2
   * @param restaurantId
   */
  private _getListOfRules(restaurantId: string) {
    this.notificationsService.getListOfRules(restaurantId).subscribe(resp => {
      if (!resp['errors']) {
        this.listOfRules = resp.data;
      }
    });
  }

  /**
   * Setea la opcion seleccionada de las reglas existentes para la creacion de la notificacion
   * @param rule regla seleccionada
   */
  public optionSelected(rule) {
    this.type_notify = rule.type_rule;

    if (this.type_notify != this.TYPE_OF_NOTIFICATION.RULE_PTA) {
      this.notificationForm.patchValue({
        record_id: ""
      });

      this.ptaSelected = [];
      
      if (this.notificationForm.get('user_type').value != null) {
      this.getCustomers();
        
      }

    }
  }

  /**
   * Se definen los parametros para hacer la consulta de notificaciones
   */
  private __setParamsQueryNotifications(eventPage): string {

    let params = '';
    if (eventPage) {
      params += '&page=' + eventPage.page;
    } else {
      params += '&page=1';
    }
    params += '&restaurant_id=' + this.detailRestaurant.id;
    if (this.filterNotifications.name) {
      params += '&name=' + this.filterNotifications.name;
    }
    if (this.filterNotifications.status) {
      params += '&status=' + this.filterNotifications.status;
    }
    if (this.filterNotifications.dateRangePicker && this.filterNotifications.dateRangePicker.length > 0) {
      params += '&dateStart=' + moment(this.filterNotifications.dateRangePicker[0]).format('DD/MM/YYYY');
      params += '&dateEnd=' + moment(this.filterNotifications.dateRangePicker[1]).format('DD/MM/YYYY');
    }
    // console.log('params', params);
    return params;
  }

  /**
   * Funcion que obtiene las fechas de inicio y fin de la notificacion y las devuelve al formato del datepicker
   * para que se muestre correctamente al editar
   * @param notificacion 
   * @author Valeria Medina Agosto 27, 2020
   * @version 1.0.0
   */
  private separateDate(notificacionDates: string) {
    let split = (moment((notificacionDates).split(' ')[0]).format('YYYY/MM/DD')).split('/');
    return {
      year: +split[0],
      month: +split[1],
      day: +split[2]
    };
  }

  private setFormatDate(notificacionDates) {
    if (notificacionDates.day <= 9) {
      notificacionDates.day = '0' + notificacionDates.day;
    }
    if (notificacionDates.month <= 9) {
      notificacionDates.month = '0' + notificacionDates.month;
    }
    return notificacionDates.year + '/' + notificacionDates.month + '/' + notificacionDates.day;
  }

  /**
   *
   * Metodo encargado de cerrar el modal para agregar una notificacion masivas
   * @author Valeria Medina Agosto 27, 2020
   * @version 1.0.0
   */
  public hideAddNotificationsModal(): void {
    this.addNotification.hide();
    this.notificationForm.reset();
    this.ptaSelected = [];
    this.isEditing = false;
  }

  /**
    * Refresh the massive notifications list
    * @author Valeria Medina Agosto 27, 2020
    * @version 1.0.0
    */
  public refreshNotifications() {
    this.getNotificationsList();
  }

  /**
   * Para la notificacion se seguir siendo masivamente notificada
   * @param idNotification id de la notificacion
   * @param contextNotify contexto, el dato siempre esta quemado es 3
   */
  public stopMassiveNotification(idNotification, contextNotify) {
    this.notificationsService.stopMassiveNotification(idNotification, contextNotify).subscribe(resp => {
      if (!resp['errors']) {
        this.translate.get('NOTIFICATION_STOPPED').subscribe(response => {
          this.toastr.success(response);
        });
      } else {
        this.toastr.error(resp['errors']['message']);
      }
    });
  }

  /**
  * Para la notificacion se seguir siendo masivamente notificada
  * @param idNotification id de la notificacion
  * @param contextNotify contexto, el dato siempre esta quemado es 3
  */
  public deleteMassiveNotification(idNotification) {
    this.notificationsService.deleteMassiveNotification(idNotification).subscribe(resp => {
      if (!resp['errors']) {
        this.translate.get('deletedSuccessfully').subscribe(response => {
          this.toastr.success(response);
        });
        this.refreshNotifications();
      } else {
        this.toastr.error(resp['errors']['message']);
      }
    });
  }

  /**
   * @author Valeria Medina 31 Agosto, 2020
   * @version 1.0.0
   * @description Lanza un alerta de confirmacion para empezar a emitir la notificacion masiva 
   */
  private startNotify(startNotify): Promise<boolean> {
    return new Promise((resolve) => {
      this.translate.get(['DROP_NOTIFICATION', 'CONFIRM_DROP_NOTIFICATION_MESSAGE', 'START_THE_NOTIFICATION', 'CREATE_BUT_NO_CONFIRM', 'Cancel', 'EDIT_BUT_NO_CONFIRM']).subscribe(translation => {
        let buttons = [];
        buttons = [translation.CREATE_BUT_NO_CONFIRM, translation.START_THE_NOTIFICATION];
        if (startNotify) {
          buttons = [translation.Cancel, translation.START_THE_NOTIFICATION];
        }
        if (this.isEditing) {
          this.isEditing = false;
          buttons = [translation.EDIT_BUT_NO_CONFIRM, translation.START_THE_NOTIFICATION,];
        }
        this.sweet.confirm(translation.DROP_NOTIFICATION, translation.CONFIRM_DROP_NOTIFICATION_MESSAGE, null, buttons)
          .then(res => {
            resolve(res);
          });
      });
    });
  }

  /**
   * Obtiene los frp Activos
   * @author WIlliam Alarcón 24 Nov 2020
   */
  private _getPTA() {
    this.notificationsService.getFRP(this.detailRestaurant.id).subscribe(resp => {
      if (!resp['errors']) {
        console.log("resp ", resp);
        this.dataPTA = resp.data;
      }
    });
  }

  public selectPta(event, ptaInfo) {

    console.log('ptaInfo', ptaInfo);

    const data = {
      name: ptaInfo.frpName,
      id: ptaInfo.id
    }

    if (event.target.checked) {
      if (!this.ptaSelected.includes(data)) {
        this.ptaSelected.push(data)
      }
    } else {
      const index = this.ptaSelected.findIndex(x => x.id === data.id);
      console.log('index', index);
      if (index > -1) {
        this.ptaSelected.splice(index, 1)

      }
    }

    console.log('this.ptaSelected', this.ptaSelected);

    this.notificationForm.patchValue({
      record_id: this.ptaSelected.map(x => x.id).join(',')
    });

    this.getCustomers();
  }

  public getPtaSelected() {
     return this.ptaSelected.map(x => x.name).join(', ');
  }

  public getCustomers(event = null) {
    this.quantityUsersToNotified = null;
    if (event) {
      this.userTypeSelected = event.target.value;
    }

    const data = {
      assimilated_rule: this.notificationForm.get('assimilated_rule').value,
      cant_clients: this.notificationForm.get('cant_clients').value,
      type_notify: this.type_notify,
      restaurants: this.detailRestaurant.id,
      user_type: this.notificationForm.get('user_type').value,
      record_id: this.notificationForm.get('record_id').value,
      idNotificationMassive: '',
      daysToNewClient: ''
    } 


    this.notificationsService.getCustomersToMassNotify(data)
      .subscribe((resp: any) => {

        if (!resp.errors) {

          this.quantityUsersToNotified = resp.data.qty
        } else {
          this.quantityUsersToNotified = null;
        }
      })

  }
}
