<div class="container">
    
    <form [formGroup]="levelForm" validate (submit)="onSubmit(levelForm.valid, accountName, contactPerson, mainPhone, mainEmail, street, suitNumber, zipCode, cityName, stateName, countryName, nickname, idRestaurant,idAccount)">
        <div class="col-sm-12 col-md-12 row">
        <div class="col-sm-6 col-md-6">
            <div class="form-group">
                <label for="accountName">{{ 'accountName' | translate }}</label>
                <input type="text" [(ngModel)]="accountName"  class="form-control" id="accountName" formControlName="accountName" required>
                <!--<div [hidden]="accountName"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="contactPerson">{{ 'ContactPerson' | translate }}</label>
                <input type="text" [(ngModel)]="contactPerson" class="form-control" id="contactPerson" formControlName="contactPerson" required>
                <!--<div [hidden]="contactPerson"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="mainPhone">{{ 'Phone' | translate }}</label>
                <input type="text"  [(ngModel)]="mainPhone" class="form-control" id="mainPhone" data-mask="(999) 999-9999" formControlName="mainPhone" placeholder="(999) 999-9999"  required>
                <!--<div [hidden]="mainPhone"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="mainEmail">{{ 'Email' | translate }}</label>
                <input type="text" [(ngModel)]="mainEmail" class="form-control" id="mainEmail" formControlName="mainEmail" required>
                <!--<div *ngIf="!levelForm.controls['mainEmail'].valid" class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>-->
            </div>
    
            <div class="form-group">
                <label for="street">{{ 'StreetAddress' | translate }}</label>
                <input type="text" [(ngModel)]="street" class="form-control" id="street" formControlName="street" required>
                <!--<div [hidden]="street"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
        </div>

        <div class="col-sm-6 col-md-6">
            <div class="form-group">
                <label for="suitNumber">{{ 'SuitNumber' | translate }}</label>
                <input type="text" [(ngModel)]="suitNumber" class="form-control" id="suitNumber" formControlName="suitNumber">
                <!--<div [hidden]="suitNumber"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="zipCode">{{ 'Zip Code' | translate }}</label>
                <input type="number" [(ngModel)]="zipCode" class="form-control" id="zipCode" formControlName="zipCode" required>
                <!--<div [hidden]="zipCode"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="cityName">{{ 'City' | translate }}</label>
                <input type="text" [(ngModel)]="cityName" class="form-control" id="cityName" formControlName="cityName" required>
                <!--<div [hidden]="cityName"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="stateName">{{ 'State' | translate }}</label>
                <input type="text" [(ngModel)]="stateName" class="form-control" id="stateName" formControlName="stateName" required>
                <!--<div [hidden]="stateName"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
    
            <div class="form-group">
                <label for="countryName">{{ 'Country' | translate }}</label>
                <input type="text" [(ngModel)]="countryName" class="form-control" id="countryName" formControlName="countryName" required>
                <!--<div [hidden]="countryName"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
        </div>
    </div>
        

    <div [hidden]="true" class="col-sm-12 col-md-12 row">
        <div class="col-sm-12 col-md-12">
        <div class="form-group">
            <label for="nickname">{{ 'nickname' | translate }}</label>
            <input disabled="disabled" type="text" [(ngModel)]="nickname" class="form-control" id="nickname" formControlName="nickname" required>
            <!--<div [hidden]="nickname"
                class="alert alert-sm alert-danger">
                {{ 'isRequired' | translate }}
            </div>-->
        </div>
        </div>
    </div>
    <div [hidden]="true" class="col-sm-12 col-md-12 row">
        <div class="col-sm-12 col-md-12">
        <div class="form-group">
            <label for="idRestaurant">ID Restaurant</label>
            <input disabled="disabled" type="text" [(ngModel)]="idRestaurant" class="form-control" id="idRestaurant" formControlName="idRestaurant" required>
            <!--<div [hidden]="nickname"
                class="alert alert-sm alert-danger">
                {{ 'isRequired' | translate }}
            </div>-->
        </div>
        </div>
    </div>
    <div [hidden]="true" class="col-sm-12 col-md-12 row">
            <div class="col-sm-12 col-md-12">
            <div class="form-group">
                <label for="idRestaurant">ID Account</label>
                <input disabled="disabled" type="text" [(ngModel)]="idAccount" class="form-control" id="idAccount" formControlName="idAccount" required>
                <!--<div [hidden]="nickname"
                    class="alert alert-sm alert-danger">
                    {{ 'isRequired' | translate }}
                </div>-->
            </div>
            </div>
        </div>

    <div class="col-sm-12 col-md-12 row">
        <div class="col-sm-12 col-md-12">

        <button type="submit" 
                [disabled]="!accountName || 
                            !contactPerson || 
                            !levelForm.controls['mainPhone'].valid || 
                            !levelForm.controls['mainEmail'].valid || 
                            !street || 
                            !levelForm.controls['zipCode'].valid || 
                            !cityName || 
                            !stateName || 
                            !countryName || 
                            !nickname ||
                            !idRestaurant ||
                            !submitted
                            " 
                class="btn btn-success"
        >{{ 'Save' | translate }}</button>

        </div>
        </div>

    </form>
</div>