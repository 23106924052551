<div class="js-sidebar-content">
  <header class="logo hidden-sm-down">
    <a class="cursor-pointerP" (click)="gotoRestaurants()">
      <img class="brand" src="assets/images/logo/Logoflat.png" alt="logo">
    </a>
  </header>
  <!-- seems like lots of recent admin template have this feature of user info in the sidebar.
       looks good, so adding it and enhancing with notifications -->
  <div class="sidebar-status hidden-md-up">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
      <span class="thumb-sm avatar pull-xs-right">
        <img class="img-circle" src="assets/images/avatar.png" alt="...">
      </span>
      <!-- .circle is a pretty cool way to add a bit of beauty to raw data.
           should be used with bg-* and text-* classes for colors -->
      <span class="circle bg-warning fw-bold text-gray-dark">
        &
      </span> &nbsp; Philip <strong>Smith</strong>
      <strong class="caret"></strong>
    </a>
    <!-- #notifications-dropdown-menu goes here when screen collapsed to xs or sm -->
  </div>
  <!-- main notification links are placed inside of .sidebar-nav -->
  <ul class="sidebar-nav">
    <li>
      <a [routerLink]=" ['dashboard'] ">
        <span class="icon">
          <em class="fa fa-desktop"></em>
        </span> Dashboard
      </a>
    </li>
    <!--
    <li>
      <a [routerLink]=" ['AnotherPage'] ">
      <span class="icon">
          <em class="fa fa-plus"></em>
      </span>
        Another Page
      <span class="label label-danger">
          &
      </span>
      </a>
    </li>
    -->
    <li class="restaurantList ">
      <a class="cursor-pointerP" (click)="gotoRestaurants()">
        <span class="icon">
          <em class="fa fa-cutlery"></em>
        </span> {{ 'Restaurants' | translate }}
      </a>
    </li>
    <li class="reviewsSidebar ">
      <!-- <a [routerLink]=" ['reviews'] "> -->
      <a class="cursor-pointerP" (click)='showReviews()'>
        <span class="icon">
          <em class="fa fa-comment"></em>
        </span> {{ 'Reviews' | translate }}
      </a>
    </li>
    <li class="orders-sidebar">
      <a class="cursor-pointerP" (click)='showOrders()'>
        <span class="icon">
          <em class="fa fa-list-ul"></em>
        </span> {{ 'Orders' | translate }}
      </a>
    </li>
    <li class="reports-sidebar">
      <a class="cursor-pointerP" (click)='showReports()'>
        <span class="icon">
          <em class="fa fa-file-text"></em>
        </span> {{ 'REPORTS' | translate }}
      </a>
    </li>
    <li class="coupons-sidebar">
      <a class="cursor-pointerP" (click)='showCoupons()'>
        <span class="icon">
          <em class="fa fa-gift"></em>
        </span> {{ 'COUPONS' | translate }}
      </a>
    </li>
    <li class="userList ">
      <a [routerLink]=" ['user'] ">
        <span class="icon">
          <em class="fa fa-user"></em>
        </span> {{ 'Users' | translate }}
      </a>
    </li>
    <li class="legalList ">
      <a [routerLink]=" ['legal'] ">
        <span class="icon">
          <em class="fa fa-gavel"></em>
        </span> {{ 'Legal' | translate }}
      </a>
    </li>
    <li class="mailsList ">
      <a class="cursor-pointerP" (click)="gotoMails()">
        <span class="icon">
          <em class="fa fa-envelope"></em>
        </span> {{ 'Contacts' | translate }}
      </a>
    </li>
    <li class="clientsList ">
      <a class="cursor-pointerP" (click)="gotoClients()">
        <span class="icon">
          <em class="fa fa-users"></em>
        </span> {{ 'Clients' | translate }}
      </a>
    </li>
    <li class="settingList ">
      <a [routerLink]=" ['setting'] ">
        <span class="icon">
          <em class="fa fa-cogs"></em>
        </span> {{ 'Settings' | translate }}
      </a>
    </li>
    <li class="enableRestaurant ">
      <a class="cursor-pointerP" (click)="gotoEnableRestaurant()">
        <span class="icon">
          <em class="fa fa-check-square-o"></em>
        </span> {{ 'EnableRestaurant' | translate }}
      </a>
    </li>
  </ul>
</div>
