import { NotificationsService } from 'angular2-notifications';

import { Component, OnInit } from '@angular/core';
// import { TranslatePipe } from 'ng2-translate/ng2-translate';
// import { HolderJs } from '../components/holderjs/holderjs';
// import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/common';
// import { TimerWrapper } from '@angular/core/src/facade/async';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PermissionsUserService } from '../services/permissionsUser.service';
import { SettingService } from '../services/settings.service';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

// Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: 'setting',
    templateUrl: './setting.html',
    // directives: [Widget, HolderJs, REACTIVE_FORM_DIRECTIVES,
    //   SimpleNotificationsComponent, InspectPermitsComponent],
    // pipes: [TranslatePipe],
    providers: [NotificationsService],
    styles: ['./setting.scss']
})
// @CanActivate((next, prev) => {
//   let injector: Injector = appInjector();
//   let router: Router = injector.get(Router);

//   if (!isLoggedIn()) {
//     router.navigate(['/LoginPage']);
//     return false;
//   }
//   /*if (!isAdministrator()) {
//     router.navigate(['/App']);
//     return false;
//   }*/

//   return true;
// })
export class SettingComponent implements OnInit {

    /**
    * Variable que controla si se muestra o no la seccion
    */
    canShowSection: boolean = false;
    canEditGeneralSetting: boolean;

    /**
    * variable encargada de traer todos los modulos
    */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));


    /**
     * Variable con identificador de la seccion configuraciones generales
     */
    idSectionGeneralSettings: any = this.allModules['module_general_settings'];
    editGeneralSetting: any = this.allModules['module_edit_general_settings'];

    distance = 20;
    min: number = 1;
    max: number = 50;
    step: number = 1;

    // Variable para manejo de errores al realizar peticiones a los webservices
    error: any;

    // Variable para llenar el select de dias y meses
    days: Array<Object> = [];

    // Variable para manejo de idioma
    userLang: string = "";

    // Variable para capturar las configuraciones generales
    settings: any;
    settingsUpdated: any;

    // Variable para creacion de formulario
    public settingsForm: FormGroup;

    // Variable que almacena los emails de las personas que recibiran los correos enviados a OMT
    strEmails: string = "";

    // Variable para controlar nuevos registros de personas que recibiran los mails
    arrayLiPerson: Array<Object> = ['person'];
    numberSize: number = 1;

    // Variable para manejo de errores
    errorMail: boolean = false;
    msgErrorSize: string = '';

    validateEmails: boolean = false;
    processingResponse: boolean;

    constructor(
        private settingService: SettingService,
        private permissionsUserService: PermissionsUserService,
        private _fb: FormBuilder,
        private utils: UtilsComponent,
        private _service: NotificationsService
    ) { }

    // Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    // Variables para manejo de mensajes de notificacion
    public title: string = "General Settings";
    public content: string = "succesfully updated";

    ngOnInit() {
        this.createForm();
        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionGeneralSettings);

        setTimeout(() => {
            if (this.canShowSection) {
                for (var i = 5; i <= 50; i = i + 5) {
                    this.days.push(i);
                }
                this.userLang = navigator.language.split('-')[0];

                this.getSettings();
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);

        setTimeout(() => {
            this.canEditGeneralSetting = this.permissionsUserService.verifyPermissionsUser(this.editGeneralSetting);
        }, 1500);
    }

    /**
     * Metodo encargado de capturar las configuraciones generales que se hayan establecido previamente
     * para el sistema
     */
    getSettings() {
        this.settingService
            .getSetings(this.userLang)
            .then(settings => {
                this.settings = settings;
                if (this.settings.emailsToContactMessages) {
                    if (this.settings.emailsToContactMessages.emails.length > 0) {
                        this.validateEmails = true;
                        // let emails = this.settings.emailsToContactMessages.emails;
                        for (var i = 0; i < this.settings.emailsToContactMessages.emails.length; i++) {
                            this.settings.emailsToContactMessages.emails[i].publish = false;
                            this.settings.emailsToContactMessages.emails[i].sugerency = false;
                            this.settings.emailsToContactMessages.emails[i].claim = false;
                            for (var j = 0; j < this.settings.emailsToContactMessages.emails[i].notifications.length; j++) {
                                if (this.settings.emailsToContactMessages.emails[i].notifications[j] == 1) {
                                    this.settings.emailsToContactMessages.emails[i].publish = true;
                                } else if (this.settings.emailsToContactMessages.emails[i].notifications[j] == 2) {
                                    this.settings.emailsToContactMessages.emails[i].sugerency = true;
                                } else if (this.settings.emailsToContactMessages.emails[i].notifications[j] == 3) {
                                    this.settings.emailsToContactMessages.emails[i].claim = true;
                                }
                            }
                        }
                    }
                }

                setTimeout(() => {
                    this.distance = this.settings.defaultSearchRange;
                }, 500)
            })
            .catch(error => this.error = error);
    }



    /**
     * Metodo encargado de crear la estructura del formulario que se 
     * utilizará para manejo de los datos
     */
    createForm() {
        let emailRegex = `([a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*)((@[a-zA-Z]{2}[a-zA-Z]*)[\\\.]([a-zA-Z]{2}|[a-zA-Z]{3}))`;
        let timeNewRestaurant: FormControl = new FormControl('', [<any>Validators.required]);
        // let default_search_scale: FormControl = new FormControl('', [<any>Validators.required]);
        let distance: FormControl = new FormControl('', [<any>Validators.required]);
        let email: FormControl = new FormControl('', [<any>Validators.pattern(emailRegex)]);
        let email2: FormControl = new FormControl('', [<any>Validators.pattern(emailRegex)]);

        this.settingsForm = this._fb.group({
            timeNewRestaurant: timeNewRestaurant,
            distance: distance,
            email: email,
            email2: email2
        })
    }

    onSubmit(settingsData: any, isValid: boolean) {
        this.processingResponse = true;
        this.strEmails = '';
        settingsData.timeNewRestaurant = parseInt(settingsData.timeNewRestaurant);
        settingsData.distance = parseInt(settingsData.distance);

        if (this.userLang == 'es') {
            this.content = "actualizada exitosamente";
            this.title = "Configuracion General";
        }

        let response: any;

        // Captura de informacion de nuevos administradores de emails
        response = this.captureInfoPersonMail('newAdminMails');
        if (response[0]) {
            setTimeout(() => {
                this.errorMail = false;
                // this.processingResponse = false;
            }, 3500);
            return false;
        } else {
            // this.processingResponse = false;
            this.strEmails = response[1];
        }
        if (this.settings.emailsToContactMessages) {
            if (this.settings.emailsToContactMessages.emails.length > 0) {
                // Captura de informacion editada proveniente de web service
                response = this.captureInfoPersonMail('adminWebServices');
                if (response[0]) {
                    setTimeout(() => {
                        this.errorMail = false;
                        // this.processingResponse = false;
                    }, 5000);
                    return false;
                } else {
                    // this.processingResponse = false;
                    this.strEmails = response[1];
                }
            }

        }


        if (this.strEmails.length <= 3) {
            this.strEmails = '{"emails":[]}';
            // this.processingResponse = false;
        } else {
            this.strEmails = this.strEmails + ']}';
            // this.processingResponse = false;
        }

        this.settingService
            .put(settingsData, this.userLang, this.strEmails)
            .then(settings => {
                // this.processingResponse = false;
                this.settingsUpdated = settings;
                this._service.success(this.title, this.content, { id: 123 });
                this.strEmails = "";
                this.arrayLiPerson = ['person'];
                setTimeout(() => {
                    this.processingResponse = false;
                    this.getSettings();
                    jQuery('#personNameNew1').val('');
                    jQuery('#personEmailNew1').val('');
                    jQuery("#publishNew1").prop('checked', false);
                    jQuery("#sugerencyNew1").prop('checked', false);
                    jQuery("#claimNew1").prop('checked', false);
                }, 700);
            })
        // this.processingResponse = false;
    }

    /**
    * Metodo que se encarga de capturar la informacion que esta en los inputs de correos 
    * al momento de crear o editar una persona que se encargara de recibir los correos
    */
    captureInfoPersonMail(typeAction: string) {
        // Array que almacena los nombres de las personas 
        let personName = [];
        // Array que almacena los emails de las personas 
        let personEmail = [];
        // Array que almacena los tipos de mensajes que manejara cada persona
        let notifications = [];
        // Array que almacena los tipos de mensajes que manejaran todas las persona
        let allNotifications = [];
        // Captura de elemento ul
        let divSizes = document.getElementById(typeAction);
        // Captura de cada elemento li que contiene los inputs
        let itemsSizes = divSizes.getElementsByTagName('li');
        // Variable para determinar si existe error en el llenado de la informacion
        let existError: boolean = false;

        // Se recorre los elementos li
        for (var i = 0; i < itemsSizes.length; i++) {
            //Captura de elementos input de cada li
            let inputSize = itemsSizes[i].getElementsByTagName('input');

            // Validacion de campos vacios en caso que el ul sea para agregar nuevas personas
            if (typeAction == 'newAdminMails' && jQuery('#' + inputSize[0].id).val() == '' &&
                jQuery('#' + inputSize[1].id).val() == '' && !jQuery('#' + inputSize[2].id).is(':checked') &&
                !jQuery('#' + inputSize[3].id).is(':checked') && !jQuery('#' + inputSize[4].id).is(':checked')) {
                this.strEmails = "";
            } else {
                // Captura de informacion después de validaciones
                if (jQuery('#' + inputSize[0].id).val() != '') {
                    personName.push(jQuery('#' + inputSize[0].id).val());

                    // Validacion campo vacio para el email
                    if (jQuery('#' + inputSize[1].id).val() != '') {
                        personEmail.push(jQuery('#' + inputSize[1].id).val());
                    } else {
                        jQuery('#' + inputSize[1].id).focus()
                        this.errorMail = true;
                        this.controlMessagesError('email');
                        existError = true;
                    }

                    // Validaciones para saber cuales son los tipos de mensajes que manejara la persona
                    if (jQuery('#' + inputSize[2].id).is(':checked')) {
                        notifications.push(1);
                    }

                    if (jQuery('#' + inputSize[3].id).is(':checked')) {
                        notifications.push(2);
                    }

                    if (jQuery('#' + inputSize[4].id).is(':checked')) {
                        notifications.push(3);
                    }
                } else {
                    jQuery('#' + inputSize[0].id).focus();
                    this.errorMail = true;
                    this.controlMessagesError('name');
                    existError = true;
                }

                // Validacion cuando se ingrese email y nombre y no se establezca el tipo de mensaje que recibira
                if (jQuery('#' + inputSize[0].id).val() != '' && jQuery('#' + inputSize[1].id).val() != '' && notifications.length <= 0) {
                    this.errorMail = true;
                    this.controlMessagesError('checkbox');
                    existError = true;
                    jQuery('#' + inputSize[1].id).focus();
                }
            }

            allNotifications.push(notifications);
            notifications = [];
        }

        // Seteo de variable strEmails que representa el Json para enviar al web service
        for (var j = 0; j < personName.length; j++) {
            if (this.strEmails == "") {
                this.strEmails = '{"emails":[{"name":"' + personName[j] + '","email":"' + personEmail[j] + '","notifications":[' + allNotifications[j] + ']}';
            } else {
                this.strEmails = this.strEmails + ',{"name":"' + personName[j] + '","email":"' + personEmail[j] + '","notifications":[' + allNotifications[j] + ']}';
            }
        }

        return [existError, this.strEmails];
    }

    /**
     * Metodo encargado de establecer el tipo de mensaje que se mostrara al usuario
     * dependiendo el campo que presente error
     * @param typeError: string -> Campo que tiene el error
     */
    controlMessagesError(typeError: string) {
        if (typeError == 'name') {
            if (this.userLang == 'es') {
                this.msgErrorSize = 'Debe proporcionar el nombre de la persona que recibirá los correos';
            } else {
                this.msgErrorSize = 'You must provide the name of the person who will receive the mails'
            }
        } else if (typeError == 'email') {
            if (this.userLang == 'es') {
                this.msgErrorSize = 'Debe proporcionar el email de la persona que recibirá los correos';
            } else {
                this.msgErrorSize = 'You must provide the email of the person who will receive the mails'
            }
        } else if (typeError == 'checkbox') {
            if (this.userLang == 'es') {
                this.msgErrorSize = 'Debe seleccionar por lo menos un tipo de mensaje que recibirá la persona';
            } else {
                this.msgErrorSize = 'You must select at least one type of message that the person will receive';
            }
        }
    }

    /**
     * Metodo encargado de adicionar un nuevo input size cuando se esta creando una categoria
     */
    addArrayLiPerson(arrayLiSize: any) {

        let divSizes = document.getElementById('newAdminMails');
        let itemsSizes = divSizes.getElementsByTagName('li');
        let lastItem = itemsSizes.length;
        let inputSize = itemsSizes[lastItem - 1].getElementsByTagName('input');
        let lastItemVal = jQuery('#' + inputSize[0].id).val();
        let canAddInput: boolean = false;

        setTimeout(() => {
            if (lastItemVal) {
                this.numberSize++;
                this.arrayLiPerson.push('size' + this.numberSize);
                canAddInput = true;
            } else {
                jQuery('#' + inputSize[0].id).focus();
                this.errorMail = true;
                if (this.userLang == 'es') {
                    this.msgErrorSize = 'Por favor llene todos los campos para adicionar otro administrador de correos';
                } else {
                    this.msgErrorSize = 'Please fill in all fields to add another mail administrator';
                }
                setTimeout(() => {
                    this.errorMail = false;
                }, 5000);
            }
        }, 300);

        setTimeout(() => {
            if (canAddInput) {
                inputSize = itemsSizes[lastItem].getElementsByTagName('input');
                jQuery('#' + inputSize[0].id).val('');
                jQuery('#' + inputSize[1].id).val('');
            }
        }, 400);

    }

    /**
  * Metodo encargado de eliminar inputs de size cuando se esta creando un producto
  */
    removeItemPerson(personName: any, personEmail: any, liPerson: any) {
        jQuery('#' + personName).val('');
        jQuery('#' + personEmail).val('');
        jQuery('#' + liPerson).remove();
    }

    /**
  * Metodo encargado de remover un input cuando se esta creando un producto
  */
    removeItemPersonNew(key: any) {
        if (this.arrayLiPerson.length > 1) {
            for (var i = 0; i < this.arrayLiPerson.length; i++) {
                if (this.arrayLiPerson[i] == key) {
                    this.arrayLiPerson.splice(i, 1);
                }
            }
        }
    }

}
