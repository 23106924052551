//import { TitleCasePipe } from './../../restaurant/pipes/titleCase-pipe';
import { LocalizeRouterService } from 'localize-router';

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
// import {TOOLTIP_DIRECTIVES} from 'ng2-bootstrap/ng2-bootstrap';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
// import {Notifications} from '../notifications/notifications';
// import {TranslatePipe} from 'ng2-translate/ng2-translate';
// import {TimerWrapper} from '@angular/core/src/facade/async';
import { UserLoginService } from '../../services/userLogin.service';
import { ConfigService } from '../config';
import { Constants } from 'app/app.constants';

declare var jQuery: any;

@Component({
    selector: '[navbar]',
    //  events: ['toggleSidebarEvent', 'toggleChatEvent'],
    // directives: [Notifications, TOOLTIP_DIRECTIVES, ROUTER_DIRECTIVES, Widget, UserProfileComponent],
    templateUrl: './navbar.html',
    // pipes: [TranslatePipe, TitleCasePipe],
    // styles: [require('./../../restaurant/newRestaurant/newRestaurant.scss')],
})
export class Navbar implements OnInit {

    username: string;

    /**
     * Variable para manejo de lenguaje
     */
    userLang: string = "";

    /**Open
     * Varible que recibe respuesta del logout
     */
    logout: any;

    /**
     * Variable para manejo de errores
     */
    error: any;

    /**
     * Variable para manejar la información del perfil del usuario
     */
    userProfile: any;

    @Output() toggleSidebarEvent: EventEmitter<any> = new EventEmitter();
    @Output() toggleChatEvent: EventEmitter<any> = new EventEmitter();
    $el: any;
    config: any;

    constructor(el: ElementRef,
        config: ConfigService,
        private userLoginService: UserLoginService,
        private userService: UserService,
        private router: Router,
        private localize: LocalizeRouterService
    ) {
        this.$el = jQuery(el.nativeElement);
        this.config = config.getConfig();
        this.username = localStorage.getItem(Constants.LOCAL_STORAGE_DATA.USER);
    }

    /**
     * Metodo encargado de enviar petición al web service para realizar el logout en el web service
     */
    logOut() {

        this.userLoginService
            .postLogout(this.userLang)
            .then(logout => {
                this.logout = logout;
            })
            .catch(error => this.error = error);

        this.userLoginService.logout();
        this.router.navigate([this.localize.translateRoute('/login')]);
    }

    myProfile() {
        jQuery('#userProfile').modal('show');
        this.userService
            .getProfileUser(this.userLang)
            .then(userProfile => {
                this.userProfile = userProfile;
            })
            .catch(error => this.error = error);
    }

    toggleSidebar(state): void {
        this.toggleSidebarEvent.emit(state);
    }

    toggleChat(): void {
        this.toggleChatEvent.emit(null);
    }

    ngOnInit(): void {
        this.userLang = navigator.language.split('-')[0];

        setTimeout(() => {
            let $chatNotification = jQuery('#chat-notification');
            $chatNotification.removeClass('hide').addClass('animated fadeIn')
                .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
                    $chatNotification.removeClass('animated fadeIn');
                    setTimeout(() => {
                        $chatNotification.addClass('animated fadeOut')
                            .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd' +
                                ' oanimationend animationend', () => {
                                    $chatNotification.addClass('hide');
                                });
                    }, 8000);
                });
            $chatNotification.siblings('#toggle-chat')
                .append('<i class="chat-notification-sing animated bounceIn"></i>');
        }, 4000);

        this.$el.find('.input-group-addon + .form-control').on('blur focus', function (e): void {
            jQuery(this).parents('.input-group')
            [e.type === 'focus' ? 'addClass' : 'removeClass']('focus');
        });
    }
}
