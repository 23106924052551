<div class="modal-header">
    <h4 class="modal-title pull-left">{{'ORDER_DETAIL' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="user-data">
        <p *ngIf="order.orderConsecutive">
            <em class="fa fa-hashtag"></em>&nbsp;
            
            <span>{{order.orderConsecutive}}</span>
        </p>


        <p>
            <em class="fa fa-calendar"></em>&nbsp;
            <span>
        {{order.orderCreation | dateTimeLocalize : false : 'YYYY-MM-DD HH:mm:ss' | async  }}
      </span>
        </p>

        <p>
            <em class="fa fa-cutlery"></em>&nbsp;
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.dineIn">
                <span>{{ "DINE_IN" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.counter">
                <span>{{ "COUNTER" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder && order.orderPhoneType == 1">
                <span>{{ "PICKUP" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder&& order.orderPhoneType == 2">
                <span>{{ "DELIVERY" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.phoneOrder && (order.orderPhoneType === phoneOrderTypeConstants.table || order.orderPhoneType === phoneOrderTypeConstants.turn || order.orderPhoneType === phoneOrderTypeConstants.turnAutomatic)">
                <span>{{ "DINE_IN" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.barTab">
                <span>{{ "BAR_TAB" | translate }}</span>
            </ng-container>
            <ng-container *ngIf="order.ordOrderType === orderTypeConstants.driveThru">
                <span> {{ "DRIVE_THRU" | translate }}</span>
            </ng-container>
        </p>


        <p *ngIf="order.dineInTurn">
            <em class="fa fa-ticket"></em>&nbsp;
            <span *ngIf="order.ordDineInType === DINE_IN_CONSTANTS.TABLE">{{'TABLE' | translate }}:</span>
            <span *ngIf="order.ordDineInType === DINE_IN_CONSTANTS.BEACON">{{'BEACON' | translate }}:</span>

            <span>{{order.dineInTurn}}</span>
        </p>

        <p>
            <em class="fa fa-user"></em>&nbsp;
            <span>
        {{order.clientName}} {{order.clientLastName}}
      </span>
        </p>

        <p *ngIf="order.uponDelivery == 0">
            <em *ngIf="order.uponDelivery == 0" class="fa fa-credit-card"></em>&nbsp;
            <span>{{ "credit_card" | translate}}</span>
        </p>
        <p *ngIf="isColombia && order.uponDelivery == 1">
            <em *ngIf="order.ordPayMethod == 'credit_card' || order.ordPayMethod  == 'cardDeb' || order.ordPayMethod == 'credit_debit'" class="fa fa-credit-card"></em>
            <em *ngIf="order.ordPayMethod == 'cash'" class="fa fa-money"></em>
            <span class="ml-1">{{ "UPON_DELIVERY" | translate}} - {{order.ordPayMethod | translate}}</span> <span *ngIf="order.ordPayMethod == 'cash'"> {{order.ordPayMount | currencySD: restaurantCountry }}</span>

        </p>
        <p *ngIf="order.orderAddress">
            <em class="fa fa-map-marker"></em>&nbsp;
            <span>{{order.orderAddress}}</span>
        </p>
        <p *ngIf="orderAddressComment">
            <em class="fa fa-sticky-note-o" aria-hidden="true"></em>&nbsp;
            <span>{{'NOTE' | translate }} {{'Delivery' | translate}}: {{orderAddressComment}}</span>
        </p>
        <p>
            <em class="fa fa-phone"></em>&nbsp;
            <span *ngIf="order.clientPhone">
        {{order.clientPhone}}
      </span>
        </p>
        <!-- <p>
      <ng-container *ngIf="order.orderAddress">
        <em class="fa fa-home"></em>&nbsp;
        <span>
          Direccion cliente
        </span>
      </ng-container>
    </p> -->
        <hr>
        <app-loading-image *ngIf="!isLoadingDataRestaurant" [classCSS]="'margin-processing'" [message]="'processing' | translate"></app-loading-image>
        <ng-container *ngIf="isLoadingDataRestaurant">
            <p>
                <span>
          {{restaurantDetails.name}}
        </span>
            </p>

            <p>
                <em class="fa fa-map-marker"></em>&nbsp;
                <span *ngIf="restaurantDetails.address">
          {{restaurantDetails.address.naturalAddress}}
        </span>
            </p>
            <p>
                <em class="fa fa-phone"></em>&nbsp;
                <span *ngIf="restaurantDetails.phones">
          {{restaurantDetails.phones[0].number}}
        </span>
            </p>
        </ng-container>
    </div>
    <div class="table-responsive">
        <table style="width:100%">
            <caption></caption>
            <ng-container *ngIf="additionalDetailData && isLoadingDataRestaurant">
                <tr>
                    <th id="product">{{ 'PRODUCT' | translate }}</th>
                    <th class="text-center product-size">{{ 'Size' | translate }}</th>
                    <th id="value" class="text-right">{{ 'VALUE' | translate }}</th>
                </tr>
                <ng-container *ngFor="let product of additionalDetailData.orderDetail">
                    <tr>
                        <td>{{product.qty | number: '1.0-0'}} {{product.name}}</td>
                        <td class="text-center">
                            <span *ngIf="product.nameSize">{{product.nameSize}}</span>
                            <span *ngIf="!product.nameSize">{{ 'Regular' | translate}}</span>
                            
                        </td>
                        <td class="text-right">{{product.subTotal | currencySD: restaurantCountry }}</td>
                        
                    </tr>
                    <ng-container *ngIf="product.orderObservation">
                        <ng-container *ngFor="let modifiers of product.orderObservation.products">
                            <tr class="modifiers">
                                <td class="pad-left">{{modifiers.qty | number: '1.0-0'}} {{modifiers.name}}</td>
                                <td></td>
                                <td *ngIf="modifiers.value > 0" class="text-right">{{modifiers.value | currencySD: restaurantCountry }}</td>
                            </tr>
                            <tr class="submods" *ngFor="let subMods of modifiers.subModifiers">
                                <td class="pad-left">{{subMods.qty | number: '1.0-0'}} {{subMods.name}}</td>
                                <td></td>
                                <td *ngIf="subMods.value > 0" class="text-right">{{subMods.value | currencySD: restaurantCountry }}</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td *ngIf="product.orderObservation.text" class="note"><span class="bold-600">{{'NOTE' | translate}}:</span> {{product.orderObservation.text}}</td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </table>
    </div>
    <hr>
    <div class="table-responsive">
        <table style="width:100%" class="total-detail">
            <tr>
                <td>Subtotal</td>
                <td>{{ subTotal | currencySD: restaurantCountry }}</td>
            </tr>
            <tr *ngIf="shipping">
                <td>{{'DELIVERY' | translate}}</td>
                <td>{{ shipping | currencySD: restaurantCountry}}</td>
            </tr>
            <tr *ngIf="tip">
                <td>{{'TIP' | translate}}</td>
                <td>{{ tip | currencySD: restaurantCountry}}</td>
            </tr>
            <ng-container *ngIf="additionalDetailData">
                <tr *ngFor="let tax of arrayTaxes">
                    <ng-container *ngIf="tax.value">
                        <td>{{'CART_TAX' | translate}} {{tax.percent | percent:'1.1-2'}}</td>
                        <td>{{tax.value | currencySD: restaurantCountry}}</td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-container *ngIf="couponsDiscount">
                <tr>
                    <td>{{'DISCOUNTS' | translate}}</td>
                </tr>
                <tr class="submods" *ngFor="let discount of couponsDiscount">
                    <td class="pad-left">{{discount.couponName}}</td>
                    <td *ngIf="discount.discount">- {{discount.discount | currencySD: restaurantCountry}}</td>
                </tr>
            </ng-container>
            <tr>
                <td *ngIf="orderComment" class="w-100 note">
                    <p><span class="bold-600">{{'ORDER_COMENTARY' | translate}}:</span> {{orderComment}}</p>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer put-left">
    <span>{{ "ORDER_CODE" | translate }}: {{ order.orderCode }}</span>
    <span class="put-right"> Total: {{order.invTotal | currencySD: restaurantCountry }}</span>
</div>
<app-change-order-state [orderStateModel]='chargeData()' [buttonProcessing]='orderStatusButtonProcessing' [inputState]='lastOrderState' (sendData)='sendDataOrderState($event)'>
</app-change-order-state>