import { LocalizeRouterService } from 'localize-router';
//import { TimerWrapper } from '@angular/core/src/facade/async';
import { FileUploader } from 'ng2-file-upload';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';

import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
//import { AlertComponent } from 'ng2-bootstrap/components/alert';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import {LocalizeRouterService} from 'localize-router';
import { TranslateService } from '@ngx-translate/core';

import { categoryFood } from '../../../entity/categoryFood';
import { imageProduct, menuItem } from '../../../entity/menuItem';
import { restaurant } from '../../../entity/restaurant';
import { TreatedImage } from '../../../entity/treatedImage';
import { AlertsService } from '../../../services/alerts.service';
import { CategoryService } from '../../../services/category.service';
import { MenuProductsService } from '../../../services/menuProducts.service';
import { PermissionsUserService } from '../../../services/permissionsUser.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { ImagesUtilsComponent } from '../../../utils/imagesUtils.component';
import { UtilsComponent } from '../../../utils/utils';
import { Constants } from 'app/app.constants';

//Manejo de modal para agregar/editar categoría
declare var jQuery: any;

@Component({
    selector: 'products',
    templateUrl: './menuProducts.html',
    styleUrls: ['./menuProducts.scss'],
    // directives: [Widget,
    //     ROUTER_DIRECTIVES,
    //     CategoryModalComponent,
    //     NewMenuItemModalComponent,
    //     AlertComponent,
    //     REACTIVE_FORM_DIRECTIVES,
    //     SimpleNotificationsComponent,
    //     FILE_UPLOAD_DIRECTIVES,
    //     InspectPermitsComponent
    // ],
    // providers: [NotificationsService],
    // pipes: [TranslatePipe, SearchPipe]
})

// @CanActivate((next, prev) => {
//     let injector: Injector = appInjector();
//     let router: Router = injector.get(Router);
//     if (!isLoggedIn()) {
//         router.navigate(['/LoginPage']);
//         return false;
//     }
//     return true;
// })

export class MenuProductsComponent implements OnInit {

    @ViewChild('canDeleteModal') public canDeleteModal: ModalDirective
    @ViewChild('showImagesProductModal') public showImagesProductModal: ModalDirective
    @ViewChild('addImagesProductModal') public addImagesProductModal: ModalDirective;
    @ViewChild('addEditProductModal') public addEditProductModal: ModalDirective;
    @ViewChild('showProductModal') public showProductModal: ModalDirective;
    @ViewChild('priceSizeCreate') public priceSizeCreate: ElementRef;
    @ViewChild('priceSizeEdit') public priceSizeEdit: ElementRef;

    //Peso máximo de la imagen
    sizeImg = 1953130;

    public modalRef: BsModalRef;
    textWarn: any;

    /**
     * Dimensiones de las imagenes de producto
     */
    readonly IMAGE_PRODUCT_MIN_HEIGHT = 350;
    readonly IMAGE_PRODUCT_MIN_WIDTH = 942;
    errorExist: boolean;
    processingResponse: boolean;

    //Variable para capturar las categorias
    category: categoryFood[];
    error: any;
    categorySelected: categoryFood;

    //Variables para capturar restaurante
    navigated: boolean = false;
    restaurants: restaurant[];
    @Input() restaurant: restaurant;

    //Variable para manejo de lenguaje
    userLang: string;

    //Variables para manejo de datos productos
    menuItem: menuItem[];
    selectedProduct: menuItem;
    public productForm: FormGroup;
    @Input() product: menuItem;
    newProduct: any;
    submitted: boolean = false;

    //Variables para manejo de navegacion
    idRestaurant: number;
    idMenu: number;
    idCategory: number;

    //Variable para manejo de informacion en size de producto
    strSize: string = "";
    cantSize: Array<Object> = [];
    sizesInImages: Array<Object>;
    arrayLiSizes: Array<Object> = ['size'];
    numberSize: number = 1;
    msgErrorSize: string = '';
    errorSize: boolean = false;
    isRegularCheck: boolean = false;
    searchText: any = '';
    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Mensajes de Edicion de Restaurante
    public title: string = 'Product';
    public content: string = "";

    //Variable para manejo de imagen
    public uploader: FileUploader;
    urlUpload: string = this.utils.getUrlOmtSd();
    @Input() newImage: any;
    @Input() image: any;
    selectedImage: imageProduct;
    fileImage: any;
    canAddImage: boolean = true;

    //Reset formulario
    @Input() isResetting = false;

    //Variable para capturar imagen
    file: any;
    public imageForm: FormGroup;
    imageSizeId: number;

    //Variables para manejo de modal
    canCreate: boolean = false;
    canEdit: boolean = false;
    // statesEs = ['Activo', 'Inactivo', 'Agotado'];
    statesEs = ['Activo', 'Inactivo', 'Agotado'];

    statesEn = ['Enable', 'Disable', 'Drained'];

    //Variable para obtener registro a eliminar
    itemToDelete: any;
    section: string = "";

    //Variable para manejo de video
    videoUrl: any;

    /**
     * Variable que controla si se muestra o no la seccion
     */
    canShowSection: boolean = false;

    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));


    /**
     * Variable con identificador de la seccion menu
     */
    idSectionMenu: any = this.allModules['module_products'];

    // variable para asegurarse que es para cancelar el eliminado de la imagen del producto
    cancel: boolean = true;

    /**
     * Variable para descativar botón de guardado de productos
     */
    public buttonStatus = false;

    /**
     * Variable para descativar botón de guardado de productos
     */
    public buttonStatusImagen = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        // private routeParams: RouteParams,
        private categoryService: CategoryService,
        private menuProductsService: MenuProductsService,
        private restaurantService: RestaurantService,
        private alertService: AlertsService,
        private utils: UtilsComponent,
        private _fb: FormBuilder,
        //private sanitizer: DomSanitizationService,
        private permissionsUserService: PermissionsUserService,
        private modalService: BsModalService,
        private localize: LocalizeRouterService,
        private imagesUtils: ImagesUtilsComponent,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {
    }

    putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }

    ngAfterViewInit() {
        var THIS = this;
        jQuery('#content').on('keyup', 'input[id^="priceSizeNew"]', function () {
            THIS.validarPunto(jQuery(this).attr("id"), jQuery(this).val(), null);
        });
    }

    ngOnInit() {

        for (let modulo of this.allModules) {
            if (modulo.slug == 'module_view_restaurant_menu') {
                this.idSectionMenu = modulo.id;
            }
        }
        this.userLang = navigator.language.split('-')[0];
        this.createForm();
        this.createFormImages();

        this.permissionsUserService.showHideButtonsSidebarByComponent();

        this.canShowSection = this.permissionsUserService.verifyPermissionsUser(this.idSectionMenu);

        setTimeout(() => {
            if (this.canShowSection) {

                this.putClassActive();
                this.route.params.subscribe(params => this.idRestaurant = params.id);
                this.route.params.subscribe(params => this.idMenu = params.idMenu);
                this.route.params.subscribe(params => this.idCategory = params.idCategory);

                this.navigated = true;
                this.restaurantService.getDetailRestaurant(this.idRestaurant, this.userLang)
                    .subscribe(restaurant => {
                        this.restaurants = restaurant['data'];
                    }, error => {
                        this.error = error;
                        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
                    });


                this.getCategory();
                this.getMenuItems();

                this.urlUpload = this.urlUpload + '/' + this.idRestaurant + '/image/';
                this.uploader = new FileUploader({ url: this.urlUpload });
            } else {
                jQuery('#permissionsDeniedModal').modal('show');
            }
        }, 100);
    }

    /*
    Metodo encargado de hacer peticion a web service y
    capturar de las categoriasanActivate((next, prev) => {
  //     let injector: Injector = appInjector();
  //     let router: Router = injector.get(Router);
  
  //     if (!isLoggedIn()) {
  //         router.navigate(['/LoginPage']);
  //         return false;
  //     }
  
  //     return true;
  // })
  */
    getCategory() {
        this.categoryService
            .getAllCategories(this.idRestaurant, this.idMenu)
            .subscribe(response => {
                this.category = response['data'];
                setTimeout(() => {
                    this.captureCorrectCategory(this.category);
                }, 600);
            }, error => {
                this.error = error;
            });

        /*
          .then(category => {
            this.category = category;
            setTimeout(() => {
              this.captureCorrectCategory(this.category);
            }, 600);
          })
          .catch(error => this.error = error);
        */
    }

    captureCorrectCategory(category: any) {
        for (var i = 0; i < category.length; i++) {
            if (category[i].id == this.idCategory) {
                this.categorySelected = category[i];
            }
        }
    }

    /**
     * Metodo encargado de hacer peticion a web service y
     * capturar de las productos
     */
    getMenuItems() {
        this.menuProductsService
            .getAllMenuProducts(this.idRestaurant, this.idMenu, this.idCategory)
            .subscribe(response => {
                if (!response['errors']) {
                    this.menuItem = response['data'];
                    // setTimeout(() => {
                    //   let menuProducts: any = this.menuItem;
                    // }, 600);
                } else {
                    console.error('ERROR: ', response['errors']);
                }
            },
                error => {
                    console.error('ERROR:', error);
                }
            );
    }

    /**
     * Metodo encargado de volver al usuario a la página de donde viene
     */
    goBack() {
        window.history.back();
    }

    /**
     * Metodo encargado de abrir el modal para
     * agregar o editar un producto
     */

    public openAddEditProductModal(product: menuItem, template: TemplateRef<any>): void {

        if (this.newImage == undefined) {
            this.newImage = ''
        }
        this.getCategory();
        if (this.categorySelected.sizes) {
            this.cantSize = this.objToArray(this.categorySelected.sizes);
        }
        if (product != null) {
            this.selectedProduct = product;
        } else {
            this.canCreate = true;
            this.selectedProduct = new menuItem;
        }
        this.addEditProductModal.show();
    }

    /**
     * Metodo encargado de crear la estructura del formulario que se
     * utilizará para manejo de los datos
     */
    createForm() {
        this.productForm = new FormGroup({
            id: new FormControl(''),
            name: new FormControl('', [<any>Validators.required]),
            ingredients: new FormControl(''),
            description: new FormControl(''),
            videoPath: new FormControl(''),
            category: new FormControl(''),
            status: new FormControl(''),
            size: this._fb.group({
                size: this._fb.group({
                    nameSize: this._fb.control(''),
                    price: this._fb.control(''),
                    isDefault: this._fb.control(false)
                })
            }),
            isModifier: new FormControl(''),
            image: new FormControl('')
        });
    }

    /**
     * Metodo encargado de crear el formulario para ser utilizado en
     * la creación o edición de imagenes
     */
    createFormImages() {
        this.imageForm = new FormGroup({
            id: new FormControl(''),
            title: new FormControl(''),
            description: new FormControl(''),
            isMain: new FormControl('')
        })
    }

    /**
     * Metodo encargado de devolver los keys del objeto form.size
     * para generar nuevos input de Size
     */
    objToArray(object: any) {
        if (typeof object === 'string') {
            return Object.keys(JSON.parse(object));
        }
        return Object.keys(object);
    }

    /**
     * Metodo encargado de añadir nuevos input tipo size
     * segun la estructura del formulario
     */
    // addInputSize() {
    //     let sizeField = this._fb.group({
    //         nameSize: this._fb.control(''),
    //         price: this._fb.control(''),
    //         isDefault: this._fb.control(false)
    //     });

    //     (<FormGroup>this.productForm.find('size')).addControl(
    //         'Size' + (Object.keys((<FormGroup>this.productForm.find('size')).controls).length + 1), sizeField);
    // }


    /**
     * Metodo encargado de validar que por lo menos se haya seleccionado un tamaño como regular
     */
    validateRegularSize() {
        if (!this.isRegularCheck) {
            this.errorSize = true;
            this.msgErrorSizeError()
            setTimeout(() => {
                this.errorSize = false;
            }, 2500);

            return false;
        }
        return true;
    }

    /**
     * Metodo encargado de validar que se cree los productos con al menos un tamaño y
     * que este tamaño este seleccionado como regular
     */
    validateDivSize() {
        if (document.getElementById('divSizesNew')) {
            let divSizesNew = document.getElementById('divSizesNew');
            let itemsSizes = divSizesNew.getElementsByTagName('li');
            let divSizes = document.getElementById('divSizes');
            let items = divSizes.getElementsByTagName('li');

            if (itemsSizes.length == 1 && items.length == 0) {
                let inputSize = itemsSizes[0].getElementsByTagName('input');
                if (jQuery('#' + inputSize[0].id).val() == '') {
                    this.errorSize = true;
                    this.msgErrorSizeError()
                    setTimeout(() => {
                        this.errorSize = false;
                    }, 2500);

                    return false;
                }

                if (!jQuery('#' + inputSize[2].id).is(':checked')) {
                    this.errorSize = true;
                    this.msgErrorSizeError();
                    setTimeout(() => {
                        this.errorSize = false;
                    }, 2500);

                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Metodo que se encarga de capturar la informacion que esta en los inputs size
     * al momento de crear o editar un producto
     */
    captureInfoSize(typeAction: string) {
        let sizeName = [];
        let sizePrice = [];
        let sizeDefault = [];
        let divSizes = document.getElementById(typeAction);
        let itemsSizes = divSizes.getElementsByTagName('li');
        for (var i = 0; i < itemsSizes.length; i++) {
            let inputSize = itemsSizes[i].getElementsByTagName('input');
            //Captura de informacion después de validaciones
            if (jQuery('#' + inputSize[0].id).val() != '') {
                sizeName.push(jQuery('#' + inputSize[0].id).val());
                //Validacion campo vacio para el precio del tamaño
                if (jQuery('#' + inputSize[1].id).val() == '') {
                    sizePrice.push(0);
                } else {
                    sizePrice.push(jQuery('#' + inputSize[1].id).val());
                }
                //Validacion para saber cual es el size por defecto
                if (jQuery('#' + inputSize[2].id).is(':checked')) {
                    this.isRegularCheck = true;
                    sizeDefault.push('true');
                } else {
                    sizeDefault.push('false');
                }
            }
        }
        for (var j = 0; j < sizeName.length; j++) {
            if (this.strSize == "") {
                this.strSize = '{"' + sizeName[j] + '": {"price":' + sizePrice[j] + ',"default":' + sizeDefault[j] + '}';
            } else {
                this.strSize = this.strSize + ', "' + sizeName[j] + '": {"price":' + sizePrice[j] + ',"default":' + sizeDefault[j] + '}';
            }
        }
        return this.strSize;
    }

    /**
     * Metodo encargado de eliminar inputs de size cuando se esta creando un producto
     */
    removeItemSize(inputSize: any, priceSize: any, defaultSize: any, liSize: any) {
        jQuery('#' + inputSize).val('');
        jQuery('#' + priceSize).val('');
        jQuery('#' + liSize).remove();
    }

    /**
     * Metodo encargado de cerrar el modal y limpiar los datos que contiene
     */
    closeAddEditModal() {
        // jQuery('#saveProductBtn').prop('disabled', false);
        this.resetForm();
        this.getCategory();
        this.cantSize = [];
        this.uploader.clearQueue();
        this.addEditProductModal.hide();
        this.addImagesProductModal.hide();
        this.getMenuItems();
        this.newImage = '';
        this.buttonStatusImagen = false;
        this.fileImage = null;
        this.errorExist = false;
        // Limpiamos el input
        jQuery('#fileImageProduct').val('')
    }

    /**
     * Metodo encargado de validar que en el campo precio solo se ingresen numeros y que maneje solo
     * dos cifras despues del punto
     */
    onlyNumbers(e: any, id: any, nameClass: string) {

        let inputValue = jQuery('.' + nameClass + id);
        if ((e.which != 46 || jQuery('.' + nameClass + id).val().indexOf('.') != -1) &&
            ((e.which < 48 || e.which > 57) &&
                (e.which != 0 && e.which != 8))) {
            e.preventDefault();
        }


        //Prevenir que ingrese mas de dos numeros despues del punto
        var text = inputValue.val();
        if ((e.which == 46) && (text.indexOf('.') == -1)) {
            setTimeout(function () {
                if (inputValue.val().substring(inputValue.val().indexOf('.')).length > 3) {
                    inputValue.val(inputValue.val().substring(0, inputValue.val().indexOf('.') + 3));
                }
            }, 1);
        }

        if ((text.indexOf('.') != -1) &&
            (text.substring(text.indexOf('.')).length > 2) &&
            (e.which != 0 && e.which != 8) &&
            (jQuery('.' + nameClass + id)[0].selectionStart >= text.length - 2)) {
            e.preventDefault();
        }
    }

    /**
     * Metodo encargado de adicionar un nuevo input size cuando se esta creando una categoria
     */
    addArrayLiSize(arrayLiSize: any) {
        this.numberSize++;
        this.arrayLiSizes.push('size' + this.numberSize);
        setTimeout(() => {
            jQuery("#inputSizeNew" + this.numberSize).val("");
            jQuery("#priceSizeNew" + this.numberSize).val("");
        }, 100);
    }

    /**
     * Metodo encargado de remover un input cuando se esta creando un producto
     */
    removeItemSizeNew(key: any) {
        if (this.arrayLiSizes.length > 1) {
            for (var i = 0; i < this.arrayLiSizes.length; i++) {
                if (this.arrayLiSizes[i] == key) {
                    this.arrayLiSizes.splice(i, 1);
                }
            }
        }
    }

    /**
     * Metodo encargado de limpiar las variables que se utilizaron para
     * agregar o editar un nuevo producto
     */
    resetForm() {
        this.createForm();
        this.fileImage = "";
        this.arrayLiSizes = ['size'];
        this.numberSize = 1;
        this.strSize = '';
        this.isRegularCheck = false;
        this.canEdit = false;
        this.canCreate = false;
        this.isResetting = true;
        setTimeout(() => {
            this.isResetting = false;
        }, 1000);
        return false;
    }

    /**
     * Metodo encargado de eliminar un producto
     * @param product: any -> Registro que se quiere eliminar
     */
    deleteProduct(product: any, event: any) {
        this.content = this.utils.defineContent(this.selectedImage, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Producto';
        } else {
            this.title = 'Product';
        }

        event.stopPropagation();
        this.menuProductsService
            .deleteProduct(product.id, this.idRestaurant, this.idMenu, this.idCategory)
            .subscribe(product => {
                this.processingResponse = false;

                this.newProduct = product;
                setTimeout(() => {
                    this.getMenuItems();
                }, 1000)
            },
                error => {
                    this.processingResponse = false;
                    console.error('ERROR: ', error);
                }
            );
    }

    /**
     * Metodo encargado de realizar peticion al web service para traer la informacion detallada
     * de un producto
     */
    getDetailsProduct(product: any, isEdit = false) {
        this.menuProductsService
            .getDetailsProduct(this.idRestaurant, this.idMenu, this.idCategory, product.id, this.userLang)
            .subscribe(response => {
                if (!response['errors']) {
                    this.newProduct = response['data'];
                    this.selectedProduct = this.newProduct;
                    if (isEdit) {
                        this.cantSize = jQuery.map(this.newProduct.sizes, function (value) {
                            return [value];
                        });
                        this.addEditProductModal.show();
                    }
                } else {
                    console.error('ERROR: ', response['errors']);
                }
            },
                error => {
                    console.error('ERROR: ', error);
                }
            );
    }

    /**
     * Metodo encargado de enviar peticion al webservice para traer detalles de un producto
     */
    public openEditProductModal(product: any): void {
        this.getDetailsProduct(product, true);
        this.newImage = product.images;
        //this.getImagesInProduct(product);
        this.canEdit = true;
        // let restaurant: any = this.restaurants;


        /*setTimeout(() => {
          if (restaurant.isFromLevel) {
            jQuery('#selectStatus').prop('disabled', 'disabled');
            jQuery('#selectStatus').addClass('disabled');
            jQuery('#selectStatus2').prop('disabled', 'disabled');
            jQuery('#selectStatus2').addClass('disabled');
            jQuery('#selectCategory').prop('disabled', 'disabled');
            jQuery('#selectCategory').addClass('disabled');
            jQuery('#nameProduct').prop('readonly', true);
            jQuery('#nameProduct').addClass('disabled');
            jQuery("#divSizes li input").prop('readonly', true);
            jQuery("#divSizes li input").addClass('disabled');
            jQuery("#divSizes li input:radio").prop('disabled', 'disabled');
            jQuery("#divSizes li button").prop('disabled', true);
            jQuery("#divSizes li button").addClass('hide');
            jQuery('#isModifier').prop('disabled', 'disabled');
            if (this.newProduct.videoPath) {
              this.selectedProduct.videoPath = 'https://youtu.be/' + this.newProduct.videoPath;
            }
          }
        }, 1000);*/
    }


    /**
     * Metodo encargado de capturar la imagen que se va a enviar al web service en
     * crear o editar imagenes del producto del menu
     */

    public captureImage(files: any = null) {
        this.fileImage = null;
        if (files !== null) {
            if (this.fileValidation(files)) {
                let filesToUpload = files;
                let lastFile = filesToUpload.length;
                if (filesToUpload['imageOriginal'].size > this.sizeImg) {
                    this.translate.get("IMAGE_IS_BIG").subscribe(translations => {
                        this.errorExist = true;
                        this.textWarn = translations;
                        this.buttonStatusImagen = true;
                    });
                } else {
                    // if (lastFile > 0) {
                    // this.fileImage = filesToUpload['imageOriginal'];
                    const that = this;
                    this.checkDimensions(filesToUpload['imageOriginal'], function (allow: boolean) {
                        if (allow == false) {
                            that.translate.get("IMAGE_DIMENSION_LOGO").subscribe(translations => {
                                that.errorExist = true;
                                that.textWarn = translations;
                                that.fileImage = null;
                                that.buttonStatusImagen = true;
                            });
                        } else {
                            that.errorExist = false;
                            that.buttonStatusImagen = false;
                            that.fileImage = filesToUpload;
                        }
                    });
                    // } else {
                    //     this.fileImage = null;
                    // }
                }

            }
        }
    }

    /**  
     * Método encargado de validar extencion correcta para la imagen
     */
    fileValidation(files: TreatedImage) {
        let fileInput: any;
        let filePath: any;
        if (document.getElementById('fileImageProduct')) {
            fileInput = document.getElementById('fileImageProduct');
            filePath = fileInput.value;
        } else {
            filePath = files.imageOriginal.name;
        }


        const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
        if (!allowedExtensions.exec(filePath)) {
            this.translate.get(["uploadImageError"]).subscribe(translations => {
                this.toastr.warning(translations.uploadImageError);
            });
            this.fileImage = null;
            jQuery('#btnUploadImage').prop('disabled', false);
            return false;
        }
        return true;
    }
    /**
       * Método para verificar las dimensiones permitidas de la imagen
       * @param tmpImage 
       * @param callback 
       */
    private checkDimensions(tmpImage: File, callback: Function) {
        var reader = new FileReader();
        const that = this;
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {

                const url = e.target.result;
                const image = new Image();

                image.onload = function () {
                    // aqui validamos si las proporciones son correctas
                    if (that.IMAGE_PRODUCT_MIN_HEIGHT > image.height) {
                        callback(false);
                    } else if (that.IMAGE_PRODUCT_MIN_WIDTH < 274) {
                        callback(false);
                    }
                    else {
                        callback(true);
                    }
                };

                image.src = url;

            };
        })(tmpImage);

        // Read in the image file as a data URL.
        reader.readAsDataURL(tmpImage);

    }

    /**
     * Metodo encargado de traer la ruta de la imagen del producto
     *
     * @param images
     */
    getImagePath(images: any) {
        const defaultImagePath = './assets/extra-images/listing-detail-1.jpg';
        if (typeof images === 'undefined') {
            return defaultImagePath;
        }
        const image: any = this.imagesUtils.getOneImage(images);
        if (image == null) {
            return defaultImagePath;
        }
        this.imagesUtils.setContentFolder('prod/images/product/');
        this.imagesUtils.setImageDefault(defaultImagePath);
        this.imagesUtils.setSizeFormat('');
        return this.imagesUtils.getPath(image);
    }

    /**
      * Metodo encargado de abrir el modal para mostrar los detalles de cada producto
      */
    public showProduct(product: menuItem) {
        this.getDetailsProduct(product);
        this.newImage = product.images;
        this.showProductModal.show();
        setTimeout(() => {
            this.selectedProduct = this.newProduct;
        }, 600);
    }

    //Método encargado de mostras las imagenes de un producto
    public showImagesProduct(product: any, modal) {
        this.selectedProduct = product;
        this.getImagesInProduct(this.selectedProduct, () => {
            this.modalRef = this.modalService.show(modal);
        });
    }

    addImagesProduct(product: any) {
        this.selectedProduct = product;
        this.canCreate = true;
        this.selectedImage = new imageProduct;

        setTimeout(() => {
            if (this.selectedProduct.sizes) {
                this.sizesInImages = jQuery.map(this.selectedProduct.sizes, function (value, index) {
                    return [value];
                });
            }
            this.addImagesProductModal.show();
        }, 700);
    }

    /**
     * Metodo encargado de enviar los datos ingresados en el formulario
     * al web service para la creacion de un producto
     */
    onSubmitProduct(product: any, isValid: boolean) {
        this.buttonStatus = true;
        this.submitted = true;
        this.product = product;

        if (!this.product.name) {
            this.requiredFieldsError();
            this.submitted = false;
            this.buttonStatus = false;
        }

        //Valida que se cree productos con al menos un tamaño
        if (!this.validateDivSize()) {
            this.buttonStatus = false;
            return false;
        }
        this.strSize = "";
        //Valida que exista informacion de tamaño nuevos
        if (document.getElementById('divSizes')) {
            this.strSize = this.captureInfoSize('divSizes');
        }

        //Valida que exista informacion de tamaño por defecto (Cargados por la categoría)
        if (document.getElementById('divSizesNew')) {
            this.strSize = this.captureInfoSize('divSizesNew');
        }

        //Valida que al menos se haya seleccionado un tamaño como regular
        // if (!this.validateRegularSize()) {
        //     this.buttonStatus = false;
        //     return false;
        // }


        if (!this.product.ingredients) {
            this.product.ingredients = "";
        }

        if (!this.product.description) {
            this.product.description = "";
        }

        if (this.product.videoPath) {
            let videoUrl = this.product.videoPath.split('/');
            this.product.videoPath = videoUrl[3];
        } else if (!this.product.videoPath) {
            this.product.videoPath = "";
        }

        if (this.strSize.length <= 3) {
            this.strSize = "";
        } else {
            this.strSize = this.strSize + '}';
        }

        this.content = this.utils.defineContent(this.product, this.userLang, 'addEdit');
        if (this.userLang == 'es') {
            this.title = 'Producto';
        } else {
            this.title = 'Product';
        }

        if (this.product.id) {
            this.product.category_id = product.category;
            this.product.status = parseInt(product.status);
        }


        if (this.fileImage) {
            this.product.images = this.fileImage;
        } else {
            this.product.images = "";
        }

        if (this.submitted) {
            this.product.id = this.selectedProduct.id;
            this.menuProductsService
                .save(this.product, this.idRestaurant, this.idMenu, this.idCategory, this.strSize)
                .subscribe(response => {
                    if (!response['errors']) {
                        this.buttonStatus = false;
                        this.product = response;
                        if (this.fileImage) {
                            this.onSubmitImageProduct(this.fileImage, isValid, this.product['data'].id);
                        } else {
                            setTimeout(() => {
                                this.getMenuItems();
                                this.closeAddEditModal();
                                if (product.id) {
                                    //this.alertService.alertEditedSuccess();
                                    this.alertService.alertGestionSuccess("editedSuccessfully");
                                } else {
                                    //this.alertService.alertSaveSuccess();
                                    this.alertService.alertGestionSuccess("SuccessfullyCreated");
                                }
                            }, 600);
                        }
                    } else {
                        this.buttonStatus = false;
                        if (product.id) {
                            //this.alertService.alertEditedError();
                            this.alertService.alertGestionError("failedEdition");
                        } else {
                            //this.alertService.alertSaveError();
                            this.alertService.alertGestionError("errorSaving");
                        }
                    }
                }, error => {
                    this.buttonStatus = false;
                    if (product.id) {
                        //this.alertService.alertEditedError();
                        this.alertService.alertGestionError("failedEdition");
                    } else {
                        //this.alertService.alertSaveError();
                        this.alertService.alertGestionError("errorSaving");
                    }
                });
        }

    }

    /**
     * Metodo encargado de enviar guardar la imagen en el ws
     * @param formImage 
     * @param isValid 
     * @param product 
     */
    public onSubmitImageProduct(formImage: any, isValid: boolean, product: any) {
        console.log('formImage', formImage);
        this.menuProductsService
            .saveImage(formImage, this.idRestaurant, this.idMenu, this.idCategory, product)
            .subscribe(response => {
                this.image = response;
                setTimeout(() => {
                    this.buttonStatusImagen = false;
                    this.getMenuItems();
                    this.closeModalImages();
                }, 600);
            }, errorResponse => {
                this.error = errorResponse
            });
    }

    /**
     * Valida si hay imagen para subir
     * @param formImage 
     * @param isValid 
     * @param product 
     */
    public onSubmitImageProductEdit(formImage: any, isValid: boolean, product: any) {
        this.buttonStatusImagen = true;
        if (!this.fileImage) {
            this.buttonStatusImagen = false;
            this.requiredFieldsError();
        } else {
            // Se borra primero la imagen
            if (product.image) {
                this.deleteImageProduct(product.images, product, isValid);
            }
            // luego la guardamos
            console.log(this.fileImage);
            this.onSubmitImageProduct(this.fileImage, isValid, product.id);
        }

    }

    closeModalImages() {
        this.uploader.clearQueue();
        this.canCreate = false;
        this.canEdit = false;
        this.imageSizeId = 0;

        jQuery('#fileImageProduct').val('').clone(true);
        this.closeAddEditModal();
        this.editRestaurantSuccess();
        if (this.imageForm.valid) {
            for (let name in this.imageForm.controls) {
                this.imageForm.controls[name].setValue('');
                //this.imageForm.controls[name]['updateValue']('');
            }
        }

        setTimeout(() => {
            jQuery('#btnUploadImage').prop('disabled', false);
            jQuery('#btnUploadImage').prop('editImageBtn', false);
            // this.addImagesProductModal.hide();
            // jQuery('#addImagesProductModal').modal('hide');
        }, 200);
    }

    getImagesInProduct(product: any, callback?) {
        this.menuProductsService
            .getImagesInProduct(this.idRestaurant, this.idMenu, this.idCategory, product.id)
            .subscribe(image => {
                if (!image['errors']) {
                    this.newImage = image['data'];
                    if (typeof callback !== "undefined") {
                        callback();
                    }
                } else {
                    console.error('ERROR: ', image['errors']);
                }
            },
                error => {
                    console.error('ERROR: ', error);
                }
            );
    }

    /**
     * Metodo encargado de eliminar la imagen del producto
     * @param image 
     * @param product 
     * @param formImage 
     */
    deleteImageProduct(image: any, product: any, formImage) {
        this.content = this.utils.defineContent(this.selectedImage, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Imagen';
        } else {
            this.title = 'Image';
        }
        this.menuProductsService
            .deleteImageProduct(this.idRestaurant, this.idMenu, this.idCategory, product.id, image[0].id)
            .subscribe(image => {
                this.image = image;
                setTimeout(() => {
                    this.addImagesProductModal.hide();
                }, 300);
            },
                // Errors will call this callback instead:
                error => {
                    console.error('ERROR: ', error);
                }
            );
    }

    /**
     * 
     * @param image Mètodo encargado de abrir el modal para editar la imagen del producto
     * @param product 
     */
    public editAttrImage(image: any, product: any) {
        this.canEdit = true;
        this.selectedImage = new imageProduct;
        this.addEditProductModal.hide();
        setTimeout(() => {
            this.addImagesProductModal.show();
        }, 500);
    }

    /**
     * Metodo encargado de cerrar el de agregar imagen
     */
    hideAddImagesProductModal(): void {
        this.addImagesProductModal.hide();
        this.buttonStatusImagen = false;
        this.fileImage = null;
        this.errorExist = false;

        jQuery('#fileImageProduct').val('')

        setTimeout(() => {
            this.addEditProductModal.show();
        }, 1200);
    }

    /**
     * Mètodo encargado de cerrar el modal de ver producto
     */
    hideShowProductModal(): void {
        this.showProductModal.hide();
        this.newImage = '';
    }

    /**
     * Metodo encargado de mostrar mensaje de confirmacion para eliminar un producto
     * @param itemToDelete: any -> Registro que se quiere eliminar
     * @param section: string -> Seccion a la cual se va a eliminar el registro (Producto, ImagenProducto)
     */
    public confirmDelete(itemToDelete: any, section: string): void {

        this.itemToDelete = itemToDelete;
        this.section = section;
        if (section == 'product') {
            setTimeout(() => {
                this.canDeleteModal.show();
            }, 400);
        } else if (section == 'image') {
            this.modalRef.hide();

            setTimeout(() => {
                this.canDeleteModal.show();
            }, 800);
        }
    }

    public hideConfirmDelete(cancel, modal): void {
        this.canDeleteModal.hide();
    }

    /**
     * Metodo encargado de llamar la funcion necesaria para eliminar un registro
     * dependiendo desde que seccion se haya solicitado la eliminacion
     * @param itemToDelete: any -> Registro que se quiere eliminar
     * @param event: any -> Evento que se ha solicitado
     * @param section: string -> Seccion de donde se va a eliminar el registro (Producto, Imagen)
     */
    deleteSection(itemToDelete: any, event: any, section: string) {
        this.processingResponse = true;
        jQuery('#deleteBtn').prop('disabled', true);
        event.stopPropagation();
        if (section == 'product') {
            this.deleteProduct(itemToDelete, event);
            this.processingResponse = true;

            this.alertService.successAdd("ProductMenu", "deletedSuccessfully");
        } else if (section == 'image') {
            this.deleteImageProduct(itemToDelete, this.selectedProduct, event);
        } else {
            this.alertService.WarningAdd("ProductMenu", "eliminationFailed");
        }

        setTimeout(() => {
            jQuery('#deleteBtn').prop('disabled', false);
            this.canDeleteModal.hide();

            // jQuery('#canDeleteModal').modal('hide');
            this.itemToDelete = null;
            this.section = "";
        }, 1500);
    }

    // metodo encargado de detectar si en el campo de precio se ecribio un punto 
    validarPunto(idp, value, them) {
        // console.log('tengo este id', them);

        var pricetex;


        if (value) {
            pricetex = value;
        } else {
            if (them == 'priceSizeCreate') {
                pricetex = this.priceSizeCreate.nativeElement;
                // console.log('priceSizeCreate', this.priceSizeCreate);
            } else {
                pricetex = this.priceSizeEdit.nativeElement;
                // console.log('priceSizeEdit', this.priceSizeEdit);
            }
            pricetex = pricetex.value;
            pricetex = pricetex.trim();
        }

        var pricetexS = pricetex.split('.');

        if (!pricetexS["0"] && pricetexS["1"]) {
            // console.warn('pricetexS ==>', pricetexS);
            var tvalos = '0.' + pricetexS["1"];
            if (!value) {
                if (them == 'priceSizeCreate') {
                    this.priceSizeCreate.nativeElement.value = tvalos;
                } else {
                    this.priceSizeEdit.nativeElement.value = tvalos;
                }
            } else {
                jQuery('#' + idp).val(tvalos);
            }
        }
    }

    /**
     * Metodo encargado de abrirl el modal para mostrar el video del producto
     */
    // showVideoModal(product: menuItem) {
    //     this.selectedProduct = product;
    //     if (this.selectedProduct.videoPath) {
    //         this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.selectedProduct.videoPath + '?rel=0');
    //     }
    //     setTimeout(() => {
    //         jQuery('#videoModal').modal('show');
    //     }, 300);

    // }

    /**
     * Metodo encargado de cerrar el modal que muestra el video del producto, tambien el video si se
     * esta reproduciendo
     */
    closeVideoModal() {
        jQuery('iframe').attr('src', jQuery('iframe').attr('src'));
        jQuery('#videoModal').modal('hide');
    }

    /**
     * Toast para cuando falta algun campo requerido
     */
    requiredFieldsError() {
        this.translate.get(["RequiredFields"]).subscribe(translations => {
            this.toastr.warning(translations.RequiredFields);
        });
    }

    msgErrorSizeError() {
        this.translate.get(["msgErrorSize"]).subscribe(translations => {
            this.toastr.warning(translations.msgErrorSize);
        });
    }

    editRestaurantSuccess() {
        this.translate.get(["SuccessfullyEdited"]).subscribe(translations => {
            this.toastr.success(translations.SuccessfullyEdited);
        });
    }

}
