import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserTrackingService } from '../../services/user-tracking.service';
import { Constants } from 'app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { IFiltersTrackReports } from 'app/models/filter-tracks/filter-tracks.interface';
import { Pagination } from 'app/entity/Pagination';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
// Variable para manejo de funciones jquery
declare var jQuery: any;
@Component({
  selector: 'app-report-user-clicks',
  templateUrl: './report-user-clicks.component.html',
  styleUrls: ['./report-user-clicks.component.sass']
})
export class ReportUserClicksComponent implements OnInit {
  /**
     * It have all the pagination data
     * We need an object for this
     *  currentPage
     *   firstPage
     *   itemsInCurrentPage
     *   itemsPerPage
     *   lastPage
     *   nextPage
     *   prevPage
     *   totalItems
     */
  public pagination: any;
  /**
    * maximun size of pagination
    */
  public maxSize: Pagination;
  /**
   * Variable que almacena la informacion de los reportes
   */
  public reports: any;
  /**
   * Variable que maneja la informacion de los filtros
   */
  public filterReport: IFiltersTrackReports;
  /**
   * Variable que almacena el tipo de click dependiendo la seccion
   */
  public types: any;
  /**
   * Variable que almacena el tipo de usuario que hace los clicks. Logueado: 0 o sin loguear: 1
   */
  public types_user: { TYPE_NOT_WAS_ANONYMUS: number; TYPE_IS_ANONIMUS: number; };
  /**
   * Variable que almcena los tipos de brand desde donde se hizo la compra. Web, App, WhiteLabel, etc.
   */
  public types_brand: { TYPE_KIND_OMT_WEB: number; TYPE_KIND_OMT_APP: number; TYPE_KIND_MENU_ONLINE: number; TYPE_KIND_WHITE_LABEL: number; };
  /**
   * Variable que almacena los restaurantes que han empezado a registrar clicks
   */
  public restaurants: any;
  /**
   * Variable que almacena el tipo de click en un array que contiene en nombre y el numero para iterarlo en un array
   */
  public typesItirables: any;
  /**
   * Variable que indica que se deben 
   */
  public allReports: boolean;
  /**
   * Variable que maneja el boton de buscar
   */
  public buttonStatus: boolean;
  /**
   * Variable que indica que se esta descargando el archivo de excel
   */
  public downloadExcel: boolean;

  public excelFile;

  public paginationArray: Array<any>;
  public paginationSelected: number;
  private itemsPerPageSelected: number = 25;

  constructor(
    private userTrackService: UserTrackingService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.filterReport = {
      userType: '',
      restaurantId: '',
      brand: '',
      type: '',
      dateRangePicker: []
    };
    this.maxSize = new Pagination();
    this.types = Constants.CLICK_TYPES;
    this.types_user = Constants.TYPES_USER;
    this.types_brand = Constants.TYPES_BRAND;
    this.typesItirables = Constants.CLICK_TYPES_ITIRABLES;
    console.log("ite ", this.typesItirables);
    this.allReports = document.location.href.includes("all");
    this.buttonStatus = false;
    this.downloadExcel = false;
    this.paginationArray = [25, 50, 100];
  }

  ngOnInit() {
    if (this.allReports) {
      this.getReports();
      this.__putClassActive();
    } else {
      this.route.params.subscribe(params => {
        this.filterReport.restaurantId = params.id;
        this.refreshReports();
      });
    }
    this.getNamesRestaurant();

  }
  /**
   * Devuelve la navegación hacia el listado de restaurantes
   */
  public goToRestaurantList() {
    window.history.back();
  }

  /**
   * Metodo encargado de poner clase activa en el sidebar para identificar
   * que se esta trabajando dentro de la sección de reports de clicks generales
   */
  private __putClassActive() {
    setTimeout(() => {
      jQuery('.reports-sidebar').addClass('active');
    }, 100);
  }

  /**
   * Obtain reports of clicks from ws
   * @param eventPage if there's pagination
   * @author Valeria Medina Ramirez Febrero 16-2020
   * @version 1.0.0
   */
  public getReports(eventPage: any = null, itemsPerPage?: number) {
    console.log('getReports', eventPage);
    this.paginationSelected = itemsPerPage;
    if(itemsPerPage != undefined){
      this.itemsPerPageSelected = itemsPerPage;
    }
    this.buttonStatus = true;
    const params = this.__setParamsQueryOrders(eventPage);
    console.log('paramsejes', params);
    this.userTrackService.getReportsFromUser(params)
      .pipe(finalize(() => {
        console.log('suuuppp');
        this.buttonStatus = false;
        this.paginationSelected = null;
      })).subscribe((x: object) => {
        if (!x['errors']) {
          this.reports = x['data'];
          this.pagination = x['pagination'];

        }
      });
  }

  /**
   * Obtain reports from ws
   * @param eventPage if there's pagination
   * @author Valeria Medina Ramirez Febrero 16-2020
   * @version 1.0.0
   */
  private getNamesRestaurant() {
    this.userTrackService.getReportsFromUser(null, true)
      .subscribe((x: object) => {
        console.log('getReportsFromUser', x);
        if (!x['errors']) {
          this.restaurants = x['data'];
        }
      });
  }

  /**
   * Se definen los parametros para hacer la consulta de ordenes
   * @param eventPage 
   */
  private __setParamsQueryOrders(eventPage: object | number): string {
    let params = '';

    if (eventPage) {
      if(eventPage['page']){
        params += 'page=' + eventPage['page'];
      } else {
        params += 'page=' + eventPage;
      }
     
    } else if (this.pagination && this.pagination.currentPage) {
      params += 'page=' + this.pagination.currentPage;
    } else {
      params += 'page=1';
    }
    if (this.filterReport.restaurantId) {
      params += '&restaurant_id=' + this.filterReport.restaurantId;
    }
    if (this.filterReport.type) {
      params += '&type=' + this.filterReport.type;
    }
    if (this.filterReport.userType) {
      params += '&wasAnonymous=' + this.filterReport.userType;
    }
    if (this.filterReport.dateRangePicker && this.filterReport.dateRangePicker.length > 0) {
      params += '&start_date=' + moment(this.filterReport.dateRangePicker[0]).format('YYYY/MM/DD');
      params += '&end_date=' + moment(this.filterReport.dateRangePicker[1]).format('YYYY/MM/DD');
    }

    if(this.itemsPerPageSelected){
      params += '&items_per_page=' + this.itemsPerPageSelected
    }
    console.log("params ", params);
    return params;
  }

  /**
  * Refresh the click reports list
  * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
  * @version 1.0.0
  */
  public refreshReports() {
    this.getReports();
  }
  /**
   * Exporta el reporte en excel
   */
  public exportToExcel(): void {

    this.downloadExcel = true;

    // Es necesario consumir el servicio de excel
    console.log("CONSUMIR WS DE EXCEL");
    const params = this.__setParamsQueryOrders(null);

    this.userTrackService.getExcelReport(params)
      .subscribe((res: any) => {
        if (res) {
          this.translate.get('REPORTS').subscribe(translation => {
            const fileName = translation;
            const blob = new Blob([res._body], { type: res.type });
  
            const file = new File([blob], `${fileName}.xlsx`, { type: res.type });
  
            saveAs(file);
            this.downloadExcel = false;
          });
        }
      });
   
  }
}
