import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ListAccountService } from '../../services/listAccount.service';
import {
    ActivateRestaurantLevel
} from '../activateRestaurantLevel/activateRestaurantLevel.component';
import { RestaurantComponent } from '../restaurant';

@Component({
    selector: 'level-list',
    templateUrl: './listaAccountLevel.component.html',
    // entryComponents: [ActivateRestaurantLevel],
})

export class LevelList implements OnInit {

    public levelForm: FormGroup;
    public restaurant: RestaurantComponent;

    /**
     * Variable para creacion de formulario para manejo de busqueda
     */
    public searchLic: FormGroup;

    fomrSearchLic = {
        licsearch: ''
    }


    listAccount: any;
    @Input() list: Array<any>;
    @Input() res: any;

    @Output() notify: EventEmitter<string> = new EventEmitter<string>();

    public modalRef: BsModalRef;
    //Variable que recibe respuesta del listado completo de restaurantes
    fullResponse: any;
    error: any;

    constructor(
        private modalService: BsModalService,
        private listAccountService: ListAccountService
    ) { }

    ngOnInit() {
        this.listAccount = this.list['data'];
        this.createForm();
    }

    assignAccount(account) {
        this.modalRef = this.modalService.show(ActivateRestaurantLevel, { class: 'modal-lg' });
        this.modalRef.content.typeModal = 'createAccount';
        this.modalRef.content.list = account;


        this.modalRef.content.res = this.res;
        this.modalRef.content.title = 'Modal with component';
        this.modalRef.content.accountName = account.acName;
        this.modalRef.content.contactPerson = account.acContactName;
        this.modalRef.content.mainPhone = account.acPhoneNumber;
        this.modalRef.content.mainEmail = account.acEmail;
        this.modalRef.content.street = this.res.address.street;
        this.modalRef.content.suitNumber = this.res.address.suitNumber;
        this.modalRef.content.zipCode = this.res.address.zipCode;
        this.modalRef.content.cityName = this.res.address.cityName;
        this.modalRef.content.stateName = this.res.address.stateName;
        this.modalRef.content.countryName = this.res.address.countryName;
        this.modalRef.content.nickname = this.res.levelDatabaseNickname;
        this.modalRef.content.idRestaurant = this.res.id;
        this.modalRef.content.idAccount = account.id;
        this.notify.emit('Click from nested component');

    }



    createForm() {
        this.searchLic = new FormGroup({
            licsearch: new FormControl('')
        });
    }


    resetList() {
        this.listAccount = this.list['data'];
        this.fomrSearchLic.licsearch = '';

    }

    filterlic() {
        this.fomrSearchLic.licsearch;
        this.listAccountService
            .getAllAccountParam(this.fomrSearchLic.licsearch)
            .subscribe(fullResponse => {
                this.fullResponse = fullResponse;

                if (this.fullResponse.errors) {
                    console.warn('error', this.fullResponse.errors);
                } else {
                    this.listAccount = this.fullResponse.data;
                }
            }
                , error => {
                    this.error = error;
                    console.info("ERROR: ", error);
                });
    }


}

