<div class="container">
    <div class="form-group">
        <div *ngIf="list">
            <div *ngIf="list.length <=0">
                <div class="alert alert-info alert-dismissable">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <strong>{{'NoDataToShow' | translate }}</strong>
                </div>
            </div>
        </div>
        <div class="table-responsive">
               <div class="form-inline justify-content-end" style="margin-bottom:10px">
                    <form [formGroup]="searchLic" #fromSearch="ngForm" (ngSubmit)="filterlic()">
                        <input class="form-control" [(ngModel)]="fomrSearchLic.licsearch" formControlName="licsearch" type="text" placeholder="{{ 'SEARCHLIC' | translate }}">
                        <button class="btn btn-success" type="submit" style="margin-left:5px" [disabled]="fomrSearchLic.licsearch ==''">{{ 'Search' | translate }}</button>
                    </form>
                    <button class="btn btn-danger reset" (click)="resetList()" style="margin-left:5px">{{ 'CLEAN' | translate }}</button>
               </div>
            <table class="table table-striped" id="table-restaurant">
                <thead>
                    <tr>
                        <th>
                            <a style="cursor: pointer;" (click)="restaurant.orderColumn1Object(1, 'name')">{{ 'Name' | translate }} 
                                <i class="{{restaurant.orderIconObject}}"></i>
                            </a>
                        </th>
                        <th>
                            <a style="cursor: pointer;" (click)="restaurant.orderColumn2Object(1, 'naturalAddress', 'address')">{{ 'email' | translate }}
                                <i class="{{restaurant.orderIcon2Object}}"></i>
                            </a>
                        </th>
                        <th class="hidden-xs-down txtcntr">
                            <a style="cursor: pointer;" (click)="restaurant.orderColumnObjectNumber(1, 'phones', 0,'number')">{{ 'Phone' | translate }}
                                <i class="{{restaurant.orderIconObjectNumber}}"></i>
                            </a>
                        </th>
                        <!-- <th class="hidden-xs-down txtcntr">
                            <a style="cursor: pointer;" (click)="orderColumn1ObjectBoolean(1, 'isFromLevel')">Level
                                <i class="{{orderIconObjectBoolean}}"></i>
                            </a>
                        </th> -->
                        <th class="txtcntr">{{ 'Actions' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listAccount">
                        <td>{{ item.acName}}</td>
                        <td>{{ item.acEmail}}</td>
                        <td>{{ item.acPhoneNumber}}</td>
                        <td class="width-150 actions">
                            <div class="btn-group">
                                <button (click)="assignAccount(item)">
                                    <i class="fa fa-refresh text-success"></i> 
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>