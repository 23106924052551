import { NotificationsService } from 'angular2-notifications';
import { LocalizeRouterService } from 'localize-router';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import {
    ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Constants } from '../../app.constants';
import { cuisines, features, meals } from '../../entity/cuisines';
import { detailRestaurant, imageRestaurant } from '../../entity/detailRestaurant';
import { hour, openingDate } from '../../entity/openingDate';
import { restaurant } from '../../entity/restaurant';
import { TreatedImage } from '../../entity/treatedImage';
import { AlertsService } from '../../services/alerts.service';
import { ConfigRestaurantService } from '../../services/configRestaurant.service';
import { DetailsRestaurantService } from '../../services/detailsRestaurants.service';
import { LevelSyncroLevel } from '../../services/levelSync.service';
import { MenuesService } from '../../services/menues.service';
import { PermissionsUserService } from '../../services/permissionsUser.service';
import { RestaurantService } from '../../services/restaurant.service';
import { UserLoginService } from '../../services/userLogin.service';
import { GlobalEventsService } from '../../utils/globalEvents.service';
import { globalRegex } from '../../utils/globalRegex';
import { ImagesUtilsComponent } from '../../utils/imagesUtils.component';
import { UtilsComponent } from '../../utils/utils';
import {
    ActivateRestaurantLevel
} from '../activateRestaurantLevel/activateRestaurantLevel.component';

//Variable para manejo autocompletar location
declare var google: any;

//Variable para manejo de funciones Jquery
declare var jQuery: any;
import * as moment from 'moment';
@Component({
    selector: 'editRestaurant',
    templateUrl: './editRestaurant.html',
    styleUrls: ['./editRestaurant.scss'],
    providers: [DatePipe],

    entryComponents: [ActivateRestaurantLevel],
})
// @CanActivate((next, prev) => {
//     let injector: Injector = appInjector();
//     let router: Router = injector.get(Router);

//     if (!isLoggedIn()) {
//         router.navigate(['/LoginPage']);
//         return false;
//     }

//     return true;
// })

export class EditRestaurantComponent implements OnInit {
    //Peso máximo de la imagen
    sizeImg = 1953130;

    /**
     * Constante que define el valor minimo de alto del logo 
     */
    readonly IMAGE_LOGO_MIN_HEIGHT = 300;

    /**
     * Constante que define el valor minimo de ancho del logo 
     */
    readonly IMAGE_LOGO_MIN_WIDTH = 300;

    errorExist: boolean;
    textWarn: any;
    catImage: boolean;
    crop: boolean = false;
    @ViewChild('addOpenDate') public addOpenDate: ModalDirective;
    @ViewChild('childModal') public childModal: ModalDirective;
    @ViewChild('addHours') public addHours: ModalDirective;
    @ViewChild('detailsHou') public detailsHou: ModalDirective;
    @ViewChild('ngbtabset') public ngbtabset: NgbTabset;
    @ViewChild('xyz') public addressInputTest: ElementRef;
    @ViewChild('canDeleteModal') public canDeleteModal: ModalDirective;
    @ViewChild('restaurantModal') public restaurantModal: ModalDirective;

    //Radius Range
    //rangeRadius: number = 100;

    // Start Datapicker
    minDate = new Date(1950, 0, 1);
    maxDate = new Date(2050, 11, 31);
    bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, { locale: this.translate.currentLang, containerClass: 'theme-green', showWeekNumbers: false });
    resetBsValue: Date;
    bsValue: Date;
    bsValue2: Date;
    //End Datapicker

    //TimePicker
    myTimeStart2: Date;
    myTimeEnd2: Date;

    public modalRef: BsModalRef;
    idMenuR: any;
    idRestaurant: number;
    addressIn = '';
    /**Variables para manejo de google maps */
    activeMap: boolean = false;
    canEditMap: boolean = false;
    lat: number = 4.5323061;
    lng: number = -75.6735617;
    zoom: number = 18;
    latitud: number;
    longitud: number;

    userFindingCoverage: any;
    userHasCoverage: any;
    // variable para contener el objeto poligono del restaurante,    
    // en este caso un circuo que representa la cobertura del restaurante    
    circleRestaurantCoverage: any;
    // variable que contiene la instancia del mapa    
    gMap: any = null;
    // variable para identificar la posicion del restaurante en formato lanlat de google    
    positionRestaurant: any;

    markerAddressRestaurant: any;

    // variable para desabilitar el boton de guardar mientra se esta enviando el formulario del restaurante
    buttonStatus = false;

    navigated = false;
    @Input() detailRestaurant: detailRestaurant;
    @Input() restaurant: restaurant;

    /**
     * Variable para manejo de menu y redireccion
     */
    menu: any;

    //Variables para creación de formularios
    public restaurantForm: FormGroup;
    public openingDateForm: FormGroup;
    public hoursForm: FormGroup;
    public imageForm: FormGroup;
    submitted: boolean;
    @Output() close = new EventEmitter();
    error: any;

    autocomplete: any;

    /**
     * Variable encargada de almacenar el contenido obtenido del web service de cocinas
     */
    cuisines: cuisines[];
    /**
     * Variable encargada de almacenar las cocinas seleccionadas
     */
    servicesSelected: any = { 'Cuisine': [], 'Meal': [], 'Feature': [] };
    @Input() newCuisine: cuisines;
    serviceCuisine: string = 'Cuisine';
    currentServiceSelected: any;
    /**
     * Variable encargada de almacenar el contenido obtenido del web service de comidas
     */
    meals: meals[];
    @Input() newMeal: meals;
    serviceMeal: string = 'Meal';
    /**
     * Variable encargada de almacenar el contenido obtenido del web service de características
     */
    features: features[];
    @Input() newFeature: features;
    serviceFeature: string = 'Feature';

    //Variable para error al eliminar servicios
    errorService: boolean = false;
    msgErrorService: string = '';

    //Variable para mostar info en los servicios
    infoServices: string = '';

    //Variable para manejo de horarios de atencion
    @Input() newOpenDate: any;
    @Input() openingDate: openingDate;
    openDate: openingDate[];
    selectedOpenDate: openingDate;
    public displayOpenDate: any;
    errorSeason: boolean = false;
    msgErrorSeason: string = '';

    //Variable para manejo de horas de atencion
    @Input() newHour: any;
    @Input() hour: any;
    selectedHour: hour;
    errorHour: boolean = false;
    msgErrorHour: string = '';
    openingDate1: any;
    /**
     * Mensaje campos requeridos
     */
    message: string = "Please complete the required fields";
    invalid: boolean = false;

    // Variable para cargar el rango mínimo del radio
    range_min: number;

    // Variable para cargar el rango máximo del radio
    range_max: number;


    /**
     * Variable manejo telefono
     */
    phoneRestaurant: any;
    emailRestaurant: any;
    /**
     * Variable para mostrar el processing cuando se hace una petición 
     */
    processingResponse: boolean;


    // guarda el estado del restaurante si activo o inactivo
    // statusRestaurant;


    public urlWebKijho = '';
    public urlWebKijhoList = '';
    //Variable para manejo de idioma
    userLang: any;

    //Opciones para mostrar la notificacion
    public options = this.utils.setOptions();

    //Mensajes de Edicion de Restaurante
    public title: string = 'Restaurant';
    public content: string = "Restaurant edited successfully";


    /**
     * Variable para manejo de imagenes
     */
    images: any = [];
    urlImage: string = this.utils.getUrlOmt();
    // urlUpload: string = this.utils.getUrlOmtSd();
    // private uploader: FileUploader;
    @Input() imageLoad: any;

    categoryImage: number;
    isCategoryLogo: boolean = false;
    isCategoryMaian: boolean = false;

    isFromLevel: boolean = false;
    fileImage: any;
    selectedImage: imageRestaurant;
    canEditImage: boolean = false;

    //Categorias de imagenes
    frontage: number = 1;
    tablesBar: number = 2;
    kitchenChefs: number = 3;
    moments: number = 4;
    others: number = 5;
    logo: number = 6;
    main: number = 7;
    slider: number = 8;



    // variable para manipular las imagenes del restaurante por categoria
    imagesRestaurant: any = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: []
    };



    //Control de dias
    days = [];
    totalDays: number = 6;

    //Variables para manejo de fechas al momento de agregar temporadas
    monthsEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    monthsEs = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
    daysInMonthSd = [];
    daysInMonthEd = [];

    //Variables para select de hora en adicionar horario
    //mSelect = ['00','05','10','15','20','25','30','35','40','45','50','55'];
    minToSelect = [];
    hrToSelect = [];
    minSelectedFrom: number = null;
    minSelectedTo: number = null;
    hrSelectedFrom: number = null;
    hrSelectedTo: number = null;
    formatSelectedFrom: string = '';
    formatSelectedTo: string = '';

    //Variables para identificar los dias y los meses seleccionados para crear una temporada
    mSelectedSd: string = '';
    mSelectedEd: string = '';
    dSelectedSd: string = '';
    dSelectedEd: string = '';
    ySelectedSd: string = '';
    ySelectedEd: string = '';

    /**
     * Varible global que servira para definir el registro que se va a eliminar
     * (Temporada, Horario, Imagen)
     */
    itemToDelete: any;
    system: string;

    /**
     * Variable para definir el tipo de horario que va a manejar la aplicacion
     * Por defecto utilizara 12 Horas se podrá cambiar a 24 Horas
     */
    format12Hours: boolean = true;

    /**
     * Variable para identificar los items que han sido checkeados en la vista de Horarios
     * sirve de control para eliminar varios items a la vez
     */
    itemsCheckedHour = [];

    /**
     * Variable que controla si se muestra o no la seccion
     */
    canEditSection: boolean = false;
    canEditLevelRestaurant: boolean = false;
    canEditDirectoryRestaurant: boolean = false;

    /**
     * variable encargada de traer todos los modulos
     */
    allModules = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.MODULES));
    /**   
     * Variable con identificador de la seccion menu   
     * */
    editLevelRestaurant: any = this.allModules['module_edit_level_restaurants'];
    editDirectoryRestaurant: any = this.allModules['module_edit_directory_restaurants'];
    city: string;
    state: string;
    country: string;
    postal_code: string;
    suitNumber: string;
    routet: string;
    street: string;
    street_number: string;
    address: any;
    publicAddress: any;
    naturalAddress: any;
    dataRestaurant: any;
    formatted_address: any;
    nameComercial: any;
    nameRestaurant: string;
    delivery_range: number;
    delivery_charge: number;
    complete: any;
    isNewRestaurant: boolean = false;
    filePath: any;
    fileInput: any;

    /**
     * Almacena la descripcion del restaurante cuando se cierra
     */
    public restaurantCloseDescription: string;

    private _openSeasonEdit: boolean = false;
    constructor(
        // private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        //private routeParams: RouteParams,
        private restaurantService: RestaurantService,
        private alertService: AlertsService,
        private menuesService: MenuesService,
        private detailsRestaurantsService: DetailsRestaurantService,
        private _fb: FormBuilder,
        private _service: NotificationsService,
        private utils: UtilsComponent,
        //private _wrapper: GoogleMapsAPIWrapper,
        private permissionsUserService: PermissionsUserService,
        private modalService: BsModalService,
        // private renderer2: Renderer2,
        private toastr: ToastrService,
        private localize: LocalizeRouterService,
        private translate: TranslateService,
        private chRef: ChangeDetectorRef,
        private imagesUtils: ImagesUtilsComponent,
        private gMapLoader: MapsAPILoader,
        // private gMapInstance: GoogleMapsAPIWrapper,
        // private _localeService: BsLocaleService,
        // private datePipe: DatePipe,
        private levelsyncroLevel: LevelSyncroLevel,
        private globalEventsService: GlobalEventsService,
        private configView: ConfigRestaurantService,
        private userLoginService: UserLoginService,
    ) {
        defineLocale('es', esLocale);
        this.range_min = Constants.RANGE_MIN_VALUE;
        this.range_max = Constants.RANGE_MAX_VALUE;
        this.hour = [];
        this.fileImage = null;
        // this.statusRestaurant = 1;
        this.restaurantCloseDescription = '';
    }

    ngOnInit() {

        // Se obtiene los valores de cocinas, comidas y caracteristicas
        this.cuisines = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.DATA_CUSINES));
        this.meals = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.DATA_MEALS));
        this.features = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_DATA.DATA_FEATURES));

        // Se verifca que si existan valores en cocinas, comidas y caracteristicas.
        if (!this.cuisines || !this.meals || !this.features) {
            this.userLoginService.logout();
            this.translate.get(["SYSTEM_ERROR"]).subscribe(translations => {
                this.toastr.warning(translations.SYSTEM_ERROR);
            });
            var element = document.getElementById('app');
            jQuery(element).css('display', 'none');
            this.router.navigate([this.localize.translateRoute('/login')]);
        }

        this.globalEventsService.addEmail.subscribe(response => {
            this.translate.get(["EMAILREQUIREDBYEXTMENU"]).subscribe(translations => {
                this.toastr.warning(translations.EMAILREQUIREDBYEXTMENU);
            });

            setTimeout(() => {
                this.ngbtabset.select('tab-selectbyid1');
            }, 1000);

        });

        // this.permissionsUserService.showHideButtonsSidebarByComponent();
        this.userLang = this.translate.currentLang;
        if (this.userLang == "") {
            this.userLang = navigator.language.split('-')[0];
        }

        //Creacion de formularios para edición de restaurante y sus características
        this.createForm();
        this.createFormDate();
        this.createFormHours();
        this.createImageForm();

        this.putClassActive();

        const url = document.location.href;
        this.isNewRestaurant = url.includes("isNew");

        //Seteo Variable address y phone para mostrar en formulario
        this.detailRestaurant = new detailRestaurant();
        this.detailRestaurant.address = { "street": "" };
        this.detailRestaurant.phones = [];
        this.route.params.subscribe(params => this.idRestaurant = params.id);

        this.gMapLoader.load().then(() => {
            // Se precargan las funciones de google a usar en los metodos a usar a continuación
            this.getFirstLoadRestaurant();
        });

        this.canEditLevelRestaurant = this.permissionsUserService.verifyPermissionsUser(this.editLevelRestaurant);
        this.canEditDirectoryRestaurant = this.permissionsUserService.verifyPermissionsUser(this.editDirectoryRestaurant);
        if (this.detailRestaurant.isFromLevel && this.canEditLevelRestaurant) {
            this.canEditSection = true;
        } else if (!this.detailRestaurant.isFromLevel && this.canEditDirectoryRestaurant) {
            this.canEditSection = true;
        } else {
            this.canEditSection = false;
        }

        //Manejo de los taps
        jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
            this.handlerEventTabs(e);
        });

        //Manejo de los taps
        jQuery('.nav-hoursTab').on('shown.bs.tab', 'a', (e) => {
            this.handlerEventTabs(e);
        });

        //Seteo de variables para manejo de las imagenes
        // this.urlUpload = this.urlUpload + '/' + this.idRestaurant + '/image/';
        // this.uploader = new FileUploader({ url: this.urlUpload });

        //Mensaje que se mostrará en el tab de servicios
        this.translate.get(["infoServices"]).subscribe(translations => {
            this.infoServices = (translations.infoServices);
        });

        this.fillSelectHours();

        this.urlWebKijho = this.utils.getUrlWebDetailKijho() + this.idRestaurant;
        this.urlWebKijhoList = this.utils.getUrlWebDetailKijhoList();
    }

    /**
     * Si existe el objetivo se remueve la clase activa
     */
    private handlerEventTabs(e) {
        if (e.relatedTarget) {
            jQuery(e.relatedTarget).removeClass('active');
        }
    }

    /**
     * Limpia datos del calendario
     */
    private clearSelectDays() {
        jQuery("#monthsSd").prop('selectedIndex', "");
        jQuery("#monthsEd").prop('selectedIndex', "");
        jQuery("#daysSd").prop('selectedIndex', "");
        jQuery("#daysEd").prop('selectedIndex', "");
    }


    newRestaurantError() {
        this.translate.get(["Restaurant", "ErrorCreatingRestaurant"]).subscribe(translations => {
            this.toastr.warning(translations.ErrorCreatingRestaurant, translations.Restaurant);
        });
    }
    mensajeErrorAddress() {
        this.translate.get(["messageZipcode", "messageCityStateCountrySu"]).subscribe(translations => {
            this.toastr.info(translations.messageCityStateCountrySu, translations.messageZipcode);
        });
    }

    /**
     * Metodo encargado de poner clase activa en el sidebar para identificar
     * que se esta trabajando dentro de la sección restaurante
     */
    private putClassActive() {
        setTimeout(() => {
            jQuery('.restaurantList').addClass('active');
        }, 500);
    }

    /**
     * Metodo encargado de enviar peticion al web service para capturar la informacion detallada del mismo
     * despues de haber capturado el id en la url
     */
    private getFirstLoadRestaurant() {
        this.restaurantService.getDetailRestaurant(this.idRestaurant, this.userLang)
            .subscribe(restaurant => {
                this.detailRestaurant = restaurant['data'];
                if (this.detailRestaurant.address && this.detailRestaurant.address['latitude'] && this.detailRestaurant.address['longitude']) {
                    this.latitud = this.detailRestaurant.address['latitude'];
                    this.longitud = this.detailRestaurant.address['longitude'];
                }
                
                this.setImagesRestaurant(this.detailRestaurant.images);

                this.dataRestaurant = restaurant['data'].address;
                this.publicAddress = restaurant['data'].address.publicAddress
                this.naturalAddress = this.dataRestaurant.naturalAddress;
                this.city = this.dataRestaurant.cityName;
                this.state = this.dataRestaurant.stateName;
                this.country = this.dataRestaurant.countryName;
                this.postal_code = this.dataRestaurant.zipCode;
                this.street = this.dataRestaurant.street;
                this.suitNumber = this.dataRestaurant.suitNumber;
                this.idMenuR = restaurant['data'].menus[0].id;
                this.nameRestaurant = restaurant['data'].name;
                this.restaurantCloseDescription = restaurant['data'].descriptionOfClosedRestaurant

                if (restaurant['data'].descriptionOfClosedRestaurant) {
                    this.restaurantCloseDescription = restaurant['data'].descriptionOfClosedRestaurant
                } else {
                    this.restaurantCloseDescription  = '';
                }

                //Set radius restaurant
                if (restaurant['data'].deliveryRanges.length > 0) {
                    this.delivery_range = parseInt(restaurant['data'].deliveryRanges[0].rangeMax);
                    if (this.delivery_range == 100) {
                        this.delivery_range = 5000;
                    }
                    this.delivery_charge = restaurant['data'].deliveryRanges[0].rangeCharge;
                } else {
                    this.delivery_range = 5000;
                    this.delivery_charge = 0;
                }

                this.lat = parseFloat(restaurant['data'].address.latitude);
                this.lng = parseFloat(restaurant['data'].address.longitude);
                this.positionRestaurant = new google.maps.LatLng(this.lat, this.lng);

                this.phoneRestaurant = this.detailRestaurant.phones[0]['number'];

                if (!this.detailRestaurant.emails.length) {
                    this.emailRestaurant = "";
                } else {
                    for (var i = 0; i < this.detailRestaurant.emails.length; i++) {
                        if (this.detailRestaurant.emails[i]['isMainEmail']) {
                            this.emailRestaurant = this.detailRestaurant.emails[i]['email'];
                        }
                    }
                }

                this.features = this.compareServices(this.detailRestaurant.features, this.features, this.serviceFeature);
                this.cuisines = this.compareServices(this.detailRestaurant.cuisines, this.cuisines, this.serviceCuisine);

                this.meals = this.compareServices(this.detailRestaurant.meals, this.meals, this.serviceMeal);
                // verificamos si el restaurante es nuevo
                if (this.isNewRestaurant) {
                    // dejamos activo la seccion de los servicios
                    this.ngbtabset.select('tab-selectbyid2');
                }

                // ejecutamos el metodo encargado de terminar de cargar la vista
                this.postInit();
            },
                error => {
                    this.error = this.extractError(error);
                    if (this.error.status == 404) {
                        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
                    }
                });
    }

    updateCircleRadius = function (val) {
        // Modifica el radio del circulo del rango en el mapa        
        this.circleRestaurantCoverage.setRadius(Number(val));

        // Reajusta el mapa para que se logre ver todo el rango seleccionado por el usuario
        this.gMap.fitBounds(this.circleRestaurantCoverage.getBounds());
    }

    /** 
     * metodo que se ejecuta cuando el mapa ya esta listo   
     * @param {google.maps.Map} map     
     */
    onMapReady(map: any) {
        const that = this;

        this.gMap = map;
        this.gMap.setOptions({
            // the user must swipe with one finger to scroll the page and two fingers to pan the map. 
            gestureHandling: 'cooperative'
        });
        this.gMap.setZoom(2);

        // dibujamos la cobertura del usuario                
        this.circleRestaurantCoverage = new google.maps.Circle({
            strokeColor: '#b71e37',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#b71e37',
            fillOpacity: 0.35,
            map: this.gMap,
            //center: this.positionRestaurant,                    
            radius: 100,
            editable: false // con esta linea indicamos que se pueda editar el radio                
        });

        this.markerAddressRestaurant = new google.maps.Marker({
            //center: this.positionRestaurant,
            anchorPoint: new google.maps.Point(0, -29),
            draggable: true,
        });

        /*
        this.markerAddressRestaurant.setIcon(({                    
            size: new google.maps.Size(71, 71),                    
            origin: new google.maps.Point(0, 0),                    
            anchor: new google.maps.Point(17, 34),                    
            scaledSize: new google.maps.Size(35, 35)                
        }));  */

        // indicamos al marcador que sea visible en el mapa                
        this.markerAddressRestaurant.setVisible(true);
        this.markerAddressRestaurant.setMap(this.gMap);
        // var that = that;
        that.markerAddressRestaurant.addListener('dragend', function () {
            const position = that.markerAddressRestaurant.getPosition();
            that.circleRestaurantCoverage.setCenter(position);
            that.latitud = position.lat();
            that.longitud = position.lng()

            // funcionalidad que captura la dirección a partir de las coordenadas que se obtienen cuando se mueve el marcador
            var geocoder = new google.maps.Geocoder;
            var latlng = { lat: that.latitud, lng: that.longitud };
            geocoder.geocode({ 'location': latlng }, function (results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        that.naturalAddress = results[0].formatted_address;
                        var place = results[0];

                        that.nameComercial = place.name
                        that.formatted_address = place.formatted_address.split(',');
                        that.street = that.formatted_address[0];
                        that.country = that.formatted_address[3];

                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i];

                            if (addressType.types[0] == 'locality') {
                                that.city = addressType.short_name;
                            }
                            if (addressType.types[0] == 'administrative_area_level_1') {
                                that.state = addressType.short_name;
                            }
                            // if (addressType.types[0] == 'country') {
                            //   that.country = addressType.long_name;
                            // }
                            if (addressType.types[0] == 'route') {
                                that.routet = addressType.short_name;
                            }
                            if (addressType.types[0] == 'street_number') {
                                that.street_number = addressType.short_name;
                            }
                            if (addressType.types[0] == 'postal_code') {
                                that.postal_code = addressType.long_name
                            }
                            // if (that.routet != undefined) {
                            //   that.street = that.routet;
                            // } else if (that.street_number != undefined) {
                            //   that.street = that.street_number;
                            // }
                            // if (that.routet != undefined && that.street_number != undefined) {
                            //   that.routet = that.routet;
                            //   that.street =  that.street_number + that.routet;
                            // }              
                        }
                        that.chRef.detectChanges();
                    }
                }
            });
        });
        // setTimeout(function () {
        //that.gMap.setZoom(15);
        this.markerAddressRestaurant.setPosition(this.positionRestaurant);
        this.gMap.panTo(this.positionRestaurant);
        this.circleRestaurantCoverage.setCenter(this.positionRestaurant);
        this.circleRestaurantCoverage.setRadius(Number(this.delivery_range));
        this.gMap.fitBounds(this.circleRestaurantCoverage.getBounds());
        // }, 1000);
    }

    /**
     * Listen para el cambio de tab
     * @param index
     */
    onTabChange(index) {
        if (index.nextId === 'ngb-tab-0') {
            this.startAddress();
        }
    }

    startAddress() {
        var that = this;
        // var autocomplete: any;
        setTimeout(function () {
            //Manejo de autocompletar localización con libreria de google
            var inputAddress = document.getElementById("naturalAddress");

            that.gMapLoader.load().then(() => {

                const options = {
                    types: ['address']
                };

                const autocomplete = new google.maps.places.Autocomplete(inputAddress, options);

                //variable que obtiene el array con datos del autocomplete google
                google.maps.event.addListener(autocomplete, 'place_changed', function () {
                    that.complete = autocomplete.getPlace();

                    that.nameComercial = that.complete.name
                    that.formatted_address = that.complete.formatted_address.split(',');

                    google.maps.event.trigger(that.gMap, 'resize');

                    // Recibe cada componente de la dirección de los lugares más detalles
                    // y llena el campo correspondiente en el formulario.
                    that.city = '';
                    that.state = '';
                    that.country = '';
                    that.postal_code = '';

                    that.routet = '';
                    that.street_number = '';
                    that.street = that.formatted_address[0];
                    // that.country = that.formatted_address[3];

                    for (var i = 0; i < that.complete.address_components.length; i++) {
                        var addressType = that.complete.address_components[i];

                        if (addressType.types[0] == 'locality' && addressType.types[1] == 'political') {
                            that.city = addressType.short_name;
                        }
                        if (addressType.types[0] == 'administrative_area_level_1') {
                            that.state = addressType.short_name;
                        }
                        if (addressType.types[0] == 'country') {
                            that.country = addressType.long_name;
                        }
                        if (addressType.types[0] == 'route') {
                            that.routet = addressType.short_name;
                        }
                        if (addressType.types[0] == 'street_number') {
                            that.street_number = addressType.short_name;
                        }
                        if (addressType.types[0] == 'postal_code') {
                            that.postal_code = addressType.long_name
                        }
                        // if (that.routet != undefined) {
                        //   that.street = that.routet;
                        // } else if (that.street_number != undefined) {
                        //   that.street = that.street_number;
                        // }
                        // if (that.routet != undefined && that.street_number != undefined) {
                        //   that.routet = that.routet;
                        //   that.street =  that.street_number + that.routet;
                        // }    

                    }

                    // if(that.city == '' || that.state == '' || 
                    // that.country == '' || that.routet == '' || 
                    // that.street_number == '' || that.postal_code == ''){

                    //     that.mensajeErrorAddress(); 
                    //     that.newRestaurantError();   

                    //     var body = jQuery("html, body");
                    //     body.stop().animate({scrollTop:200}, 200, 'swing');

                    // }

                    /* if(this.gMap != null){                    
                        autocomplete.bindTo('bounds', this.gMap);                
                    }*/
                    // escuchamos cuando el usuario haya seleccionado una dirección            

                    // obtenemos las cordenas de cobertura del circulo 

                    // validamos que el usuario haya digitado una direccion                
                    if (!that.complete.geometry) {
                        // reseteamos mapa                    
                        this.circleRestaurantCoverage.setMap(null);
                        this.markerAddressRestaurant.setMap(null);
                        this.gMap.setCenter(this.positionRestaurant);

                        // User entered the name of a Place that was not suggested and                    
                        // pressed the Enter key, or the Place Details request failed.                    
                        //window.alert("No details available for input: '" + place.name + "'");return;  
                    }
                    // this.gMap.setZoom(12);  // Why 17? Because it looks good.                

                    /* @type {google.maps.Icon} */

                    // colocamos el marcador en la posicion de la direccion que indico el usuario               

                    //that.gMap.setZoom(15);  
                    that.markerAddressRestaurant.setPosition(that.complete.geometry.location);
                    that.gMap.setCenter(that.complete.geometry.location);
                    that.circleRestaurantCoverage.setCenter(that.complete.geometry.location);
                    that.gMap.fitBounds(that.circleRestaurantCoverage.getBounds());
                });
                that.chRef.detectChanges();
            });
        }, 1500);
    }



    /**
     * metodo que se ejecuta cuando los datos del restaurante ya se hayan cargado
     */
    private postInit() {
        //Captura de Imagenes y Temporadas de Atención
        this.getOpeningDates();
        this.startAddress();

        setTimeout(() => {
            jQuery('#latitude').prop('readonly', true);
            jQuery('#latitude').addClass('no-cursor');
            jQuery('#longitude').prop('readonly', true);
            jQuery('#longitude').addClass('no-cursor');

            //this.validImages();
            //this.existService();
        }, 1500);


    }
    /**
     * Metodo encargado de devolver un array con cada tipo de servicio, dicho array
     * no debe contener los items que ya esten seleccionados para el servicio requeridos
     * @param: itemsSelected: any -> Items que ya estan registrados en el servicio correspondiente
     * @param: allItems: any -> Todos los items del servicio requerido
     * @param: service: string -> Tipo de servicio al que se le hara la validacion
     */
    compareServices(itemsSelected: any, allItems: any, service: string) {
        var newArray = [];
        if (service == this.serviceCuisine) {
            this.servicesSelected[service] = [];
        }

        var a = allItems;
        var b = itemsSelected;
        for (var i = 0; i < a.length; i++) {
            // Comparamos para saber si existe algun registro en comun
            var match = false;
            for (var j = 0; j < b.length; j++) {
                if (a[i]['id'] == b[j][service]['id']) {
                    // Registro en comun, se detiene la busqueda
                    match = true;
                    break;
                }
            }
            // Adicionamos al nuevo array los registros encontrados que no son comunes
            if (!match) {
                newArray.push(a[i]);
            } else {
                this.servicesSelected[service].push(a[i]);
            }
        }
        return newArray;
    }

    serviceActions(action: string, allItems: any, service: string, serviceId: string) {
        let arrayIterator = allItems;
        if (action == "remove") {
            arrayIterator = this.servicesSelected[service];
        }
        for (var i = 0; i < arrayIterator.length; i++) {
            if (serviceId && arrayIterator[i]['id'] == serviceId) {
                if (action == "remove") {
                    allItems.push(arrayIterator[i]);
                    this.servicesSelected[service].splice(i, 1);
                } else {
                    this.servicesSelected[service].push(arrayIterator[i]);
                    this.currentServiceSelected = allItems[i];
                    allItems.splice(i, 1);
                }
            }
        }
        return allItems;
    }
    //funcion encargada de validar errores al momento de crear un restaurante
    validaRestaurant(errorf) {
        if (errorf.errors) {
            //this.editRestaurantError();
            this.alertService.WarningAdd("Restaurant", "ErrorEditingRestaurant");
            // this.mensajeErrorAddress();
            this.buttonStatus = false;
        } else {
            //this.editRestaurantSuccess();
            this.alertService.successAdd("Restaurant", "SuccessfullyEdited");
            return true;
        }
        return false;
    }
    //  Metodo para validar que se ingrese de forma correcta la ciudad, estado y pais
    private cityStateCountry() {
        if (this.userLang != 'es') {
            this.message = 'To send the location must fill City, State and Country';
        } else {
            this.message = 'Para enviar la localización debe llenar Ciudad, Estado y Pais';
        }
        jQuery('#address').focus();
        setTimeout(() => {
            this.invalid = false;
        }, 3500);
        this.invalid = true;
    }

    /**
     * Metodo encargado de devolver al listado de restaurante una vez se edite el restaurante
     */
    public goBack(restaurant: restaurant = null) {
        this.close.emit(restaurant);
        window.history.back();
    }

    /**
     * Metodo encargado de llenar los select para agregar/editar horario
     * dependiendo del formato de horas que se vaya a utilizar
     */
    fillSelectHours() {
        let iteration: number;
        if (this.format12Hours) {
            iteration = 12;
        } else {
            iteration = 23;
        }
        for (var x = 1; x <= iteration; x++) {
            this.hrToSelect.push(x);
        }
        for (var y = 0; y <= 55; y = y + 5) {
            this.minToSelect.push(y);
        }
        this.minToSelect.push(59);
    }

    /**
     * Metodo encargado de crear el formulario que manejara los datos basicos del restaurante
     */
    private createForm() {
        let emailRegex = `^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+.[a-zA-Z0-9-.]*)[\\\.]([a-zA-Z_.]{2,6})`;

        this.restaurantForm = new FormGroup({
            id: new FormControl('', [<any>Validators.required]),
            name: new FormControl('', [<any>Validators.required]),
            phone: new FormControl(''),
            email: new FormControl('', [<any>Validators.pattern(emailRegex)]),
            description: new FormControl(''),
            address: new FormControl(''),
            publicAddress: new FormControl(''),
            naturalAddress: new FormControl('', [<any>Validators.required]),
            suitNumber: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl('', [<any>Validators.required]),
            country: new FormControl(''),
            zipCode: new FormControl('', [<any>Validators.required]),
            contactPerson: new FormControl(''),
            website: new FormControl('', [<any>Validators.pattern(globalRegex.urlRegex)]),
            urlMenu: new FormControl('', [<any>Validators.pattern(globalRegex.urlRegex)]),
            latitude: new FormControl(''),
            longitude: new FormControl(''),
            street: new FormControl(''),
            delivery_range: new FormControl(100)
        });
    }
    /**
     * Metodo encargado de capturar el error que puede llegar a generarse al crear un usuario
     */
    extractError(res: Response) {
        let body = res.json();
        return body.errors || {};
    }

    /**
     * Metodo encargado de comprobar que exista por lo menos un registro de cada servicio,
     * si este no existe se debe deshabilitar la navegacion por tabs
     */
    private existService() {
        setTimeout(() => {
            if (this.detailRestaurant.cuisines.length < 1 || this.detailRestaurant.meals.length < 1 || this.detailRestaurant.features.length < 1) {
                jQuery('#new-tab').removeAttr('data-toggle');
                jQuery('#new-tab').removeAttr('href');
                jQuery('#new-tab').addClass('no-cursor');
                jQuery('#image-tab').removeAttr('data-toggle');
                jQuery('#image-tab').removeAttr('href');
                jQuery('#image-tab').addClass('no-cursor');
                jQuery('#hours-tab').removeAttr('data-toggle');
                jQuery('#hours-tab').removeAttr('href');
                jQuery('#hours-tab').addClass('no-cursor');
                //Deshabilitando navegacion a menu
                for (var i = 0; i < 4; i++) {
                    jQuery('#gotoCategories' + (i + 1)).prop('disabled', true);
                }
            }
        }, 1500);

        //Desactivando edicion de mapa
        this.deactiveEditMap();
    }

    methodkeyp(event) {
        if (this.utils.methodkey(event)) {
            return false;
        }
    }

    /**
     * Funcion encargada de setear la variable de activar, desactivar o poner no disponible
     * un restaurante
     * @argument status 0 para desactivado, 1 activado y 2 no disponible
     * @argument toUseCheckbox flag para usar o no esta funcion en un checkbox, por defecto no
     * @version 1.0.1
     */
    public actiRest(status: any, toUseCheckbox: boolean = false) {
        const errorAux = this.detailRestaurant.status;
        if (toUseCheckbox) {
            if (this.detailRestaurant.status == 1) {
                this.detailRestaurant.status = 0;
            } else {
                this.detailRestaurant.status = 1;
            }
        } else {
            this.detailRestaurant.status = status;
        }
        // console.log('this.detailRestaurant.status  ===>', this.detailRestaurant.status);

        this.restaurantService
            .editStuatus(this.detailRestaurant.id, this.detailRestaurant.status)
            .subscribe(response => {
                // this.getFirstLoadRestaurant();
                if (response['errors']) {
                    console.error(response['errors']);
                    this.detailRestaurant.status = errorAux;
                } else {
                    // Succesfull
                }
            }, catchError => {
                console.error(catchError);
                this.detailRestaurant.status = errorAux;
            });
    }

    /**
     * Metodo encargado de enviar el formulario de edicion del restaurante
     * @param restaurant: restaurant -> Formulario con los datos del restaurante a editar
     * @param isValid: boolean -> Variable para validar que los campos requeridos estan correctos dentro del formulario
     * @param address: any -> Variable que almacena la localización del restaurante
     * @param phone: string -> Variable que almacena el telefono del restaurante
     * @param email: string -> Variable que almacena el email del restaurante
     */
    onSubmit(restaurant: restaurant, isValid: boolean, address: any, phone: string, email: string) {
        let phoneTemp = phone.replace("(", "").replace(")", "").replace("-", "");
        // phoneTemp = phone.replace(")", "");
        // phoneTemp = phone.replace("-", "");

        //Se valida que el campo nombre este lleno
        if (!restaurant.name) {
            this.translate.get(["messageErrorName"]).subscribe(translations => {
                this.toastr.warning(translations.messageErrorName);
            });
            return false;
        }

        //Se valida que el campo address este lleno
        if (!address) {
            this.translate.get(["messageErrorAddress"]).subscribe(translations => {
                this.toastr.warning(translations.messageErrorAddress);
            });
            return false;
        }

        //Se valida que el campo TELEFONO este lleno
        if (!restaurant.phone) {
            this.translate.get(["messageErrorPhone"]).subscribe(translations => {
                this.toastr.warning(translations.messageErrorPhone);
            });
            return false;
        }
        //     //Se valida que los campos ciudad, stado y pais esten llenos
        // if (!restaurant.zipCode) {
        //   this.translate.get(["messageZipcode"]).subscribe(translations => {
        //   this.toastr.warning(translations.messageZipcode);
        //   }); 
        //   return false;
        // }

        this.content = this.utils.defineContent(restaurant, this.userLang, 'addEdit');

        this.submitted = true;
        this.restaurant = restaurant;

        // address = this.utils.cleanString(address);



        if (!email) {
            // si el restaurante nio tiene un correo se envia esto por defecto, para que el menu externo quede desactivado
            var colors = {
                font_color: '#FFFFFF',
                bg_color: '#b71f37',
                use_external_menu: false
            };

            this.restaurant.email = "";
            this.configView.update(colors, restaurant.id)
                .subscribe(response => {
                });
        } else {
            this.restaurant.email = email;
        }

        //Seteo campo phone con mascara
        this.restaurant.phone = phoneTemp;

        // Modificamos el recargo, por ahora cero hasta que se defina como se va a calcular
        this.restaurant.delivery_charge = 0;

        if (!this.restaurant.contactPerson) {
            this.restaurant.contactPerson = "";
        }

        if (!this.restaurant.website) {
            this.restaurant.website = "";
        }

        if (!this.restaurant.description) {
            this.restaurant.description = "";
        }

        if (!this.restaurant.urlMenu) {
            this.restaurant.urlMenu = "";
        }

        this.buttonStatus = true;
        if (isValid) {
            this.restaurantService
                .save(this.restaurant, this.userLang)
                .subscribe(restaurant => {
                    this.restaurant = restaurant;
                    this._service.success(this.title, this.content, { id: 123 });
                    if (this.validaRestaurant(restaurant)) {
                        this.buttonStatus = false;
                        setTimeout(() => {
                            jQuery('#saveBtn').prop('disabled', false);
                        }, 1500);
                    }
                },
                    error => {
                        this.error = error;
                        console.error(error);
                    });
        }
        else {
            this.buttonStatus = false;
            this.alertService.alertGestionError("errorSaving");
        }
    }

    /**
     * Metodo para validar que se ingrese de forma correcta la ciudad, estado y pais
     */
    validCityState(fieldCity: any, items: number) {
        if (fieldCity.length <= items) {
            this.invalid = true;
            if (this.userLang == 'es') {
                this.message = 'Para enviar la localización debe llenar Ciudad, Estado y Pais';
            } else {
                this.message = 'To send the location must fill City, State and Country';
            }
            jQuery('#address').focus();
            setTimeout(() => {
                this.invalid = false;
            }, 3500);
            return false;
        }
        return true;
    }



    /**
     * Metodo encargado de crear el formulario para adicion y edicion de temporadas
     */
    private createFormDate() {
        let id: FormControl = new FormControl(null);
        let name: FormControl = new FormControl('', [<any>Validators.required]);
        let start_date: FormControl = new FormControl('');
        let end_date: FormControl = new FormControl('');
        let description: FormControl = new FormControl('');

        this.openingDateForm = this._fb.group({
            id: id,
            name: name,
            start_date: start_date,
            end_date: end_date,
            description: description
        })
    }

    /**
     * Metodo encargado de crear el formulario para adicion y edicion de imagenes
     */
    createImageForm() {
        let id: FormControl = new FormControl(null);
        let title: FormControl = new FormControl('');
        let description: FormControl = new FormControl('');
        let category: FormControl = new FormControl('');

        this.imageForm = this._fb.group({
            id: id,
            title: title,
            description: description,
            category: category
        })

    }

    /**
     * Metodo encargado de crear el formulario para adicion y edicion de horarios
     */
    createFormHours() {
        let id: FormControl = new FormControl('');
        //let week_day: FormControl = new FormControl('', [<any>Validators.required]);
        let is_open_all_time: FormControl = new FormControl('');
        let hour_from: FormControl = new FormControl('');
        let hour_to: FormControl = new FormControl('');
        let minutesSelectFrom: FormControl = new FormControl('');
        let minutesSelectTo: FormControl = new FormControl('');
        let attention_type: FormControl = new FormControl('', [<any>Validators.required]);
        let myTimeStart: FormControl = new FormControl('');
        let myTimeEnd: FormControl = new FormControl('');

        this.hoursForm = this._fb.group({
            id: id,
            //week_day: week_day,
            is_open_all_time: is_open_all_time,
            hour_from: hour_from,
            hour_to: hour_to,
            minutesSelectFrom: minutesSelectFrom,
            minutesSelectTo: minutesSelectTo,
            attention_type: attention_type,
            myTimeStart: myTimeStart,
            myTimeEnd: myTimeEnd
        });
    }

    resetFormHours() {
        this.createFormHours();
    }
    /**
     * Metodo encargado de enviar peticion para agregar una cocina
     * al restaurante que se esta editando
     */
    addCuisine(idCuisine: any) {
        this.cuisines = this.serviceActions("add", this.cuisines, this.serviceCuisine, idCuisine);
        this.detailsRestaurantsService
            .saveCuisine(idCuisine, this.detailRestaurant.id)
            .subscribe(cuisines => {
                if (cuisines.errors) {
                    this.cuisines.push(this.currentServiceSelected);
                    this.cuisines = this.serviceActions("add", this.cuisines, this.serviceCuisine, idCuisine);
                }
            }, error => {
                this.error = error
            });
    }

    /**
     * Metodo encargado de enviar peticion para eliminar una cocina
     * al restaurante que se esta editando
     */
    deleteCuisine(idCuisine: any) {
        this.detailsRestaurantsService
            .deleteCuisine(idCuisine, this.detailRestaurant.id)
            .subscribe(res => {
                this.cuisines = this.serviceActions("remove", this.cuisines, this.serviceCuisine, idCuisine);
            },
                error => {
                    this.error = error
                });
    }

    /**
     * Metodo encargado de enviar peticion para agregar una comida
     * al restaurante que se esta editando
     */
    addMeal(idMeal: any) {
        this.meals = this.serviceActions("add", this.meals, this.serviceMeal, idMeal);
        this.detailsRestaurantsService
            .saveMeals(idMeal, this.detailRestaurant.id)
            .subscribe(meal => {
                if (meal.errors) {
                    this.meals.push(this.currentServiceSelected);
                    this.meals = this.serviceActions("add", this.meals, this.serviceMeal, idMeal);
                }
            }, error => {
                this.error = error
            });
    }

    /**
     * Metodo encargado de enviar peticion para eliminar una comida
     * al restaurante que se esta editando
     */
    deleteMeal(idMeal: any) {
        this.detailsRestaurantsService
            .deleteMeals(idMeal, this.detailRestaurant.id)
            .subscribe(res => {
                this.meals = this.serviceActions("remove", this.meals, this.serviceMeal, idMeal);
            },
                error => {
                    this.error = error
                });
    }

    /**
     * Metodo encargado de enviar peticion para agregar una característica
     * al restaurante que se esta editando
     * @param idFeature id de la caracteristica
     * 
     * @author Alvaro Felipe Garcia Mendez
     * @version 1.0.1
     */
    addFeature(idFeature: any) {
        if (this.servicesSelected.Feature.length < 8) {
            this.features = this.serviceActions("add", this.features, this.serviceFeature, idFeature);
            this.detailsRestaurantsService.saveFeature(idFeature, this.detailRestaurant.id).subscribe(feature => {
                if (feature.errors) {
                    this.toastr.warning(feature.errors.message);
                    this.features.push(this.currentServiceSelected);
                    this.features = this.serviceActions("add", this.features, this.serviceFeature, idFeature);
                }
            }, error => {
                this.error = error;
                this.translate.get(["MAX_FEATURES_REACHED"]).subscribe(translations => {
                    this.toastr.warning(translations.MAX_FEATURES_REACHED);
                });
            });
        } else {
            this.translate.get(["MAX_FEATURES_REACHED"]).subscribe(translations => {
                this.toastr.warning(translations.MAX_FEATURES_REACHED);
            });
        }
    }

    /**
     * Metodo encargado de enviar peticion para eliminar una característica
     * al restaurante que se esta editando
     */
    deleteFeature(idFeature: any) {
        this.detailsRestaurantsService
            .deleteFeature(idFeature, this.detailRestaurant.id)
            .subscribe(res => {
                this.features = this.serviceActions("remove", this.features, this.serviceFeature, idFeature);
            },
                error => {
                    this.error = error;
                });
    }
    /**
     * Metodo para cambiar de imagen recortada a imagen original en modal imagen
     */
    public showImageFull() {
        let urlImageToShow = '';
        if (this.crop) {
            urlImageToShow = this.selectedImage.path;
            this.crop = false;
        } else {
            urlImageToShow = this.selectedImage.cropedPath;
            this.crop = true;
        }

        if (!this.selectedImage.isUploadedOnAWS) {
            urlImageToShow = this.imagesUtils.getUrlHostApi() + urlImageToShow;
        }

        this.selectedImage['currentPath'] = urlImageToShow;

    }

    //Método encargado de mostras la imagene selecionada de un restaurante
    public showImagesRestaurant(imagen: any, modal) {
        this.modalRef = this.modalService.show(modal);
        this.crop = true;
        this.selectedImage = imagen;
        let urlImageToShow = '';
        if (imagen.cropedPath) {
            urlImageToShow = imagen.cropedPath;
        } else {
            urlImageToShow = imagen.path;
        }
        if (!imagen.isUploadedOnAWS) {
            urlImageToShow = this.imagesUtils.getUrlHostApi() + urlImageToShow;
        }

        this.selectedImage['currentPath'] = urlImageToShow;
    }

    /**
     * permite agregar images al arreglo principal donde se organizan por categoria
     *
     * @param image
     */
    private addImagesRestaurant(image: any) {
        if (typeof this.imagesRestaurant[image.category] === "undefined") {
            this.imagesRestaurant[image.category] = [];
        }
        this.imagesRestaurant[image.category].push(image);
    }

    /**
     * permite remover una imagen de la lista de imagenes
     * @param image
     */
    private removeImageRestaurant(image) {
        for (let i in this.imagesRestaurant[image.category]) {
            if (this.imagesRestaurant[image.category][i].id === image.id) {
                // eliminamos el registro
                this.imagesRestaurant[image.category].splice(i, 1);
                break;
            }
        }
    }

    /**
     * permite resetear la lista de imagenes con otra
     *
     * @param images
     */
    private setImagesRestaurant(images: any) {

        // reseteamos
        this.imagesRestaurant = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: []

        };

        // recorremos las imagenes
        for (let image of images) {
            //
            this.addImagesRestaurant(image);
        }
    }
    /**  
     * Método encargado de validar extencion correcta para la imagen
     */
    private fileValidation(files: TreatedImage) {
        if (this.catImage) {
            this.fileInput = document.getElementById('fileImageRestaurant');
            this.filePath = this.fileInput.value;
        } else {
            this.filePath = files.imageOriginal.name;
        }
        const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
        if (!allowedExtensions.exec(this.filePath)) {
            this.translate.get(["uploadImageError"]).subscribe(translations => {
                this.toastr.warning(translations.uploadImageError);
            });
            this.fileImage = null;
            jQuery('#btnSaveImage').prop('disabled', false);
            return false;
        }
        return true;
    }

    /** revisando poder enviar un eventos de un componente a otro */
    activeLevel(detailRes: any) {
        this.levelsyncroLevel.levelActive(detailRes, ActivateRestaurantLevel);
    }


    syncLevel(nickLevel) {
        this.levelsyncroLevel.levelSync(nickLevel);
    }


    /**
     * Metodo encargado de manejar el formulario para agregar una imagen a un restaurante
     * Envia solicitud de peticion y maneja el resultado de la peticion
     */
    public uploadImage(imageForm: any, categoryImage: number, template: TemplateRef<any>) {
        this.processingResponse = true;
        if (this.fileImage === null && !this.canEditImage) {
            console.log('entré');
            this.errorHour = true;
            if (this.userLang == 'es') {
                this.msgErrorHour = "Debe seleccionar una imagen para subir";
            } else {
                this.msgErrorHour = "You must select an image to upload";
            }
            // setTimeout(() => {
            this.errorHour = false;
            // }, 2500);
            return false;
        }

        this.content = this.utils.defineContent(imageForm, this.userLang, 'addEdit');
        if (this.userLang == 'es') {
            this.title = 'Imagen';
        } else {
            this.title = "Image"
        }

        if (!imageForm.title) {
            imageForm.title = "";
        }

        if (!imageForm.description) {
            imageForm.description = "";
        }

        this.restaurantService
            .saveImage(this.fileImage, imageForm, this.idRestaurant, categoryImage)
            .subscribe(response => {
                this.processingResponse = false;
                console.log('in petition', response['data']);
                if (response['errors']) {
                    this.alertService.alertGestionError("errorSaving");
                    jQuery('#btnSaveImage').prop('disabled', false);
                    return;
                }
                if (imageForm.id) {
                    //this.alertService.alertEditedSuccess();
                    this.alertService.alertGestionSuccess("editedSuccessfully");
                    this.fileImage = null;
                } else {
                    //this.alertService.alertSaveSuccess();
                    this.alertService.alertGestionSuccess("UPLOAD_SUCCESFUL");
                    this.fileImage = null;
                }

                // agregamos la imagen a la lista
                this.addImagesRestaurant(response['data']);

                this.imageLoad = response;
                this._service.success(this.title, this.content, { id: 123 });
                this.modalRef.hide();
                this.closeModalAddImages();

            }, responseError => {
                this.error = responseError;
                if (imageForm.id) {
                    //this.alertService.alertEditedError();
                    this.alertService.alertGestionError("failedEdition");
                    this.closeModalAddImages();
                } else {
                    //this.alertService.alertSaveError();
                    this.alertService.alertGestionError("errorSaving");
                    this.closeModalAddImages();
                }
            })

    }


    /**
     * Metodo encargado de capturar la imagen que se va a enviar al web service en
     * crear o editar imagenes de un restaurante
     */
    public captureImage(files: any = null) {
        console.log(files);
        if (files !== null) {
            if (this.fileValidation(files)) {
                this.fileImage = files;
                // if (this.categoryImage == 6) {

                //     let filesToUpload = <Array<File>>files.target.files;
                //     let lastFile = filesToUpload.length;
                //     if (filesToUpload[0].size > this.sizeImg) {
                //         const that = this;
                //         this.translate.get("IMAGE_IS_BIG").subscribe(translations => {
                //             that.errorExist = true;
                //             that.textWarn = translations;
                //         });
                //     } else {
                //         if (lastFile > 0) {
                //             this.fileImage = filesToUpload[lastFile - 1];
                //             const that = this;
                //             this.checkDimensions(this.fileImage, function (allow) {
                //                 if (allow == false) {
                //                     that.translate.get("IMAGE_DIMENSION_LOGO").subscribe(translations => {
                //                         that.errorExist = true;
                //                         that.textWarn = translations;
                //                         that.fileImage = null;
                //                     });
                //                 } else {
                //                     that.errorExist = false;
                //                 }
                //             });
                //         } else {
                //             this.fileImage = null;
                //         }
                //     }
                // } else {
                //     this.fileImage = files;
                // }

            }
        }
    }

    /**
       * Método para verificar las dimensiones permitidas de la imagen
       * @param tmpImage 
       * @param callback 
       */
    // private checkDimensions(tmpImage: File, callback: Function) {
    //     // Dimensiones mínimas de la imagen
    //     var dim;
    //     var catImage
    //     if (this.categoryImage == 6) {
    //         catImage = this.categoryImage;
    //     }
    //     var reader = new FileReader();

    //     const that = this;

    //     // Closure to capture the file information.
    //     reader.onload = (function (theFile) {
    //         return function (e) {

    //             const url = e.target.result;
    //             const image = new Image();

    //             image.onload = function () {
    //                 // aqui validamos si las proporciones son correctas
    //                 if (that.IMAGE_LOGO_MIN_HEIGHT > image.height) {
    //                     //console.log(image.width);
    //                     callback(false);
    //                 } else if (that.IMAGE_LOGO_MIN_WIDTH < 120) {
    //                     callback(false);
    //                 }
    //                 else {
    //                     callback(true);
    //                 }
    //             };

    //             image.src = url;

    //         };
    //     })(tmpImage);

    //     // Read in the image file as a data URL.
    //     reader.readAsDataURL(tmpImage);

    // }

    /**
     * Metodo encargado de abrir el modal para añadir una nueva imagen a un restaurante
     */
    public addImageModal(modal: any, category: number) {
        this.categoryImage = category;
        this.catImage = false;
        this.globalEventsService.category.emit(this.categoryImage);
        this.utils.categoryImage = category;
        this.canEditImage = false;
        this.selectedImage = new imageRestaurant;
        this.fileImage = null;
        this.modalRef = this.modalService.show(modal);
    }

    /**
     * Metodo encargado de borrar las imagenes de los restaurantes
     * @param image: any -> Registro que se quiere borrar
     */
    private deleteImage(image: any) {

        this.content = this.utils.defineContent(image, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Imagen';
        } else {
            this.title = "Image";
        }
        this.restaurantService
            .deleteImage(image.id, this.idRestaurant)
            .subscribe(response => {
                this.processingResponse = false;
                this.imageLoad = image;
                this._service.success(this.title, this.content, { id: 123 });
                this.removeImageRestaurant(image);
                //this.alertService.alertDeletedSuccess();
                //this.alertService.alertGestionSuccess("deletedSuccessfully");
            }, responseError => {
                this.error = responseError;
                //this.alertService.alertDeletedError();
                this.alertService.alertGestionError("eliminationFailed");
            });
    }


    /**
     * permite obtener la ruta de las imagenes de tamaño miniaturas
     * @param images
     * @returns {any}
     */
    getImagePathThumbnail(images: any) {
        const defaultImagePath = './assets/extra-images/listing-detail-1.jpg';

        if (typeof images === 'undefined') {
            return defaultImagePath;
        }

        const image: any = this.imagesUtils.getOneImage(images);

        if (image == null) {
            return defaultImagePath;
        }

        this.imagesUtils.setContentFolder('prod/images/restaurant/');
        this.imagesUtils.setImageDefault(defaultImagePath);
        this.imagesUtils.setSizeFormat('-xxxhdpi-400x266');

        return this.imagesUtils.getPath(image);
    }



    /**
     * Metodo que se encarga de realizar las validaciones iniciales para mostrar las imagenes
     * activar y desactivar boton de subida de archivos
     */
    private validImages() {
        let imageOther: number = 0;
        let imageLogo: number = 0;
        setTimeout(() => {

            //Validacion que no exista ninguna imagen del restaurante
            if (this.images.length <= 0) {
                this.isCategoryLogo = false;
            }

            //Se recorre la cantidad de imagenes para determinar a que categoria pertenecen
            for (var i = 0; i < this.images.length; i++) {
                if (this.images[i].category == 6) {
                    imageLogo++;
                }

                if (this.images[i].category == 5) {
                    imageOther++;
                }
            }

            if (imageLogo > 0) {
                this.isCategoryLogo = true;
            } else {
                this.isCategoryLogo = false;
            }

            if (imageOther >= 1 && !this.detailRestaurant.isFromLevel) {
                this.isFromLevel = true;
            } else {
                this.isFromLevel = false;
            }
        }, 500);

        //Desactivando edicion de mapa
        //this.deactiveEditMap();
    }



    /**
     * Metodo encargado de abrir el modal para agregar un horario de atencion
     */

    public addOpenDateModal(openDate?: any, isEdit: boolean = false): void {
        this.processingResponse = false;
        this._openSeasonEdit = isEdit;
        if (openDate != null) {

            this.selectedOpenDate = new openingDate;
            setTimeout(() => {
                this.selectedOpenDate = openDate;
                this.optionInSelectedToEdit(openDate);

                this.selectedOpenDate.name = openDate.name;
                //this.selectedOpenDate.start_date = startDate;
                //this.selectedOpenDate.end_date = endDate;
                this.selectedOpenDate.isDefault = openDate.isDefault;
                this.selectedOpenDate.description = openDate.comments;
            }, 400);

        } else {
            // this.clearSelectDays();
            this.selectedOpenDate = new openingDate;
        }
        setTimeout(() => {
            this.addOpenDate.show();
        }, 500);

    }

    public hideChildModal(): void {
        this.bsValue = this.resetBsValue;
        this.bsValue2 = this.resetBsValue;
        this.addOpenDate.hide();
    }


    /**
     * Metodo encargado de setear los campos necesarios para hacer la edicion de una temporada
     * Se setean los select de fecha del modal editar temporada
     */
    optionInSelectedToEdit(openDate: any) {

        let startMonthDate = this.utils.extractMonthDate(openDate.startDate);
        let startDayDate = this.utils.extractDayDate(openDate.startDate);
        let endMonthDate = this.utils.extractMonthDate(openDate.endDate);
        let endDayDate = this.utils.extractDayDate(openDate.endDate);
        let startYearDate = this.utils.extractYearDate(openDate.startDate);
        let endYearDate = this.utils.extractYearDate(openDate.endDate);

        this.monthSelected(startMonthDate, 'sd');
        this.monthSelected(endMonthDate, 'ed');
        this.dSelectedSd = startDayDate;
        this.dSelectedEd = endDayDate;
        this.ySelectedSd = startYearDate;
        this.ySelectedEd = endYearDate;


        this.bsValue = new Date(openDate.startDate + ' 19:00:00 GMT+0');
        this.bsValue2 = new Date(openDate.endDate + ' 19:00:00 GMT+0');


        setTimeout(() => {
            jQuery("#monthsSd").prop('selectedIndex', startMonthDate);
            jQuery("#monthsEd").prop('selectedIndex', endMonthDate);
            jQuery("#daysSd").prop('selectedIndex', startDayDate);
            jQuery("#daysEd").prop('selectedIndex', endDayDate);
        }, 300);
    }

    /**
     * Metodo encargado de validar que se haya seleccionado una fecha para la creación de una
     * temporada, valida que el formulario se halla llenado correctamente
     */
    validateDateSelected(isValid: boolean) {
        if (!isValid) {
            this.errorSeason = true;
            if (this.userLang == 'es') {
                this.msgErrorSeason = "Debe llenar el campo nombre";
            } else {
                this.msgErrorSeason = "You must fill the name field";
            }
            return false;
        }

        if (!this.bsValue || !this.bsValue2) {
            this.errorSeason = true;
            if (this.userLang == 'es') {
                this.msgErrorSeason = "Debe llenar todos los campos de fecha";
            } else {
                this.msgErrorSeason = "You must fill all date fields";
            }
            return false;
        }
        return true;
    }

    /**
     * Metodo encargado de enviar al webservice un nuevo horario de atencion
     * @argument openingDate
     * @argument isvalid
     * 
     * @version 1.0.0
     * @author Alvaro Felipe Garcia Mendez - Oct. 10-2018
     */
    onSubmitOpeningDate(openingDate: any, isvalid: boolean) {
        this.processingResponse = true;

        let validForm = this.validateDateSelected(isvalid);
        let dateStartDate: any;
        let dateEndDate: any;
        if (!validForm) {
            setTimeout(() => {
                this.errorSeason = false;
                this.processingResponse = false;
            }, 3000);
            return false;
        }
        openingDate.start_date = (this.bsValue.getMonth() + 1) + '/' + this.bsValue.getDate() + '/' + (this.bsValue.getFullYear());
        openingDate.end_date = (this.bsValue2.getMonth() + 1) + '/' + this.bsValue2.getDate() + '/' + (this.bsValue2.getFullYear());

        dateStartDate = new Date(openingDate.start_date);
        dateEndDate = new Date(openingDate.end_date);
        if (dateStartDate < dateEndDate || dateStartDate > dateEndDate) {

            if (!openingDate.description) {
                openingDate.description = "";
            }
            const tempOpeningDateS = new Date(this.bsValue.getFullYear() + '-' + (this.bsValue.getMonth() + 1) + '/' + this.bsValue.getDate());
            const tempOpeningDateE = new Date(this.bsValue2.getFullYear() + '-' + (this.bsValue2.getMonth() + 1) + '/' + this.bsValue2.getDate());
            // Validamos si la temporada ya existe
            for (let i = 0; i < this.openingDate1.openingDates.length; i++) {
                let tempOpeningDate1S = new Date(this.openingDate1.openingDates[i].startDate);
                let tempOpeningDate1E = new Date(this.openingDate1.openingDates[i].endDate);

                if ( !this._openSeasonEdit && (tempOpeningDateS >= tempOpeningDate1S && tempOpeningDateS <= tempOpeningDate1E) ||
                    !this._openSeasonEdit && (tempOpeningDate1S >= tempOpeningDateS && tempOpeningDate1S <=  tempOpeningDateE )) {

                    //  organizamos la fecha para mostrarla en formato USA
                    const tmp1 = this.openingDate1.openingDates[i].startDate.split('-');
                    const tmp2 = tmp1[1];
                    tmp1[1] = tmp1[2];
                    tmp1[2] = tmp2;
                    //  organizamos la fecha para mostrarla en formato USA
                    const tmp3 = this.openingDate1.openingDates[i].endDate.split('-');
                    const tmp4 = tmp3[1];
                    tmp3[1] = tmp3[2];
                    tmp3[2] = tmp4;

                    this.translate.get(["SEASON_ALREADY_EXIST"],
                        {
                            startDate: tmp1.reverse().join('/'),
                            endDate: tmp3.reverse().join('/')
                        })
                        .subscribe(translations => {
                            this.toastr.warning(translations.SEASON_ALREADY_EXIST, '', {
                                timeOut: 1000,
                            });
                            this.processingResponse = false;
                        });
                    return 0;
                }
            }
            this.openDate = openingDate;

            this.content = this.utils.defineContent(this.openDate, this.userLang, 'addEdit');
            if (this.userLang == 'es') {
                this.title = 'Temporada';
            } else {
                this.title = "Season"
            }

            jQuery('#saveSeasonBtn').prop('disabled', true);
            this.restaurantService
                .saveOpeningDate(this.openDate, this.detailRestaurant.id)
                .subscribe(openDate => {
                    this.newOpenDate = openDate;
                    setTimeout(() => {
                        this.getOpeningDates();
                        this.processingResponse = false;
                        this.hideChildModal();
                        // console.warn("openingDate.id ==> ", openingDate.id);
                        if (openingDate.id) {
                            //this.alertService.alertEditedSuccess();
                            this.alertService.alertGestionSuccess("editedSuccessfully");
                        }
                        else {
                            //this.alertService.alertSaveSuccess();
                            this.alertService.alertGestionSuccess("SuccessfullyCreated");
                        }

                    }, 1000);
                },
                    error => {
                        this.error = error
                        this.processingResponse = false;
                        if (openingDate.id) {
                            //this.alertService.alertEditedError();
                            this.alertService.alertGestionError("failedEdition");
                        }
                        else {
                            //this.alertService.alertSaveError();
                            this.alertService.alertGestionError("errorSaving");
                        }
                    });
        } else {
            this.alertService.alertGestionError("DATE_ERROR");
            this.processingResponse = false;
        }
    }
    clearFormOpenDate() {
        if (this.openingDateForm.valid) {
            for (let name in this.openingDateForm.controls) {
                this.openingDateForm.controls[name]['updateValue']('');
            }
        }
    }

    /**
     * Metodo encargado de limpiar el formulario de horarios de atencion
     * y de cerrar el modal
     */
    closeModal() {
        this.clearFormOpenDate();
        jQuery("#monthsSd").prop('selectedIndex', 0);
        jQuery("#monthsEd").prop('selectedIndex', 0);
        jQuery("#daysSd").prop('selectedIndex', 0);
        jQuery("#daysEd").prop('selectedIndex', 0);
        this.mSelectedSd = '';
        this.mSelectedEd = '';
        this.dSelectedSd = '';
        this.dSelectedEd = '';
        this.ySelectedSd = '';
        this.ySelectedEd = '';
        jQuery('#addOpenDate').modal('hide');
    }

    /**
     * Metodo encargado de capturar los horarios de atencion del restaurante
     * que se esta editando
     */
    getOpeningDates() {
        this.restaurantService
            .getOpeningDate(this.idRestaurant)
            .subscribe(opendates => {
                this.openingDate1 = opendates['data'];
                // setTimeout(() => {
                //     // this.captureDineInDelivery();
                // }, 4000);

            },
                error => {
                    this.error = error
                });
    }

    /**
     * Metodo encargado de eliminar un horario de atencion del restaurante
     * que se esta editando
     */
    deleteOpenDate(opendate: openingDate) {

        this.content = this.utils.defineContent(opendate, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Temporada';
        } else {
            this.title = "Season";
        }

        this.restaurantService
            .deleteOpenDate(opendate.id, this.idRestaurant)
            .subscribe(openDate => {
                this.processingResponse = false;
                this.newOpenDate = openDate;
                this._service.success(this.title, this.content, { id: 123 });
                this.getOpeningDates();
                //this.alertService.alertDeletedSuccess();
                this.alertService.alertGestionSuccess("deletedSuccessfully");
            },
                error => {
                    this.deletingDatesError(error);
                });
    }

    /**
     * Metodo encargado de abrir el modal para mostrar los detalles
     * de los horarios de atencion de un restaurante
     */
    @ViewChild('showOpenDate') public showOpenDate: ModalDirective;

    public showOpeningDate(openDate: any): void {
        this.displayOpenDate = openDate;
        this.displayOpenDate.start_date = this.utils.extractDateHour(openDate.startDate, this.format12Hours);
        this.displayOpenDate.end_date = this.utils.extractDateHour(openDate.endDate, this.format12Hours);
        this.displayOpenDate.description = openDate.comments;
        this.showOpenDate.show();
    }

    public hideshowOpenDate(): void {
        this.showOpenDate.hide();
    }

    editOpenDate(openDate: openingDate) {
        this.selectedOpenDate = openDate;
        jQuery('#showOpenDate').modal('hide');
        setTimeout(() => {
            this.addOpenDateModal(this.selectedOpenDate, true);
        }, 500);
    }


    /**
     * Metodo encargado de abrir el modal para añadir un horario
     */

    public addHoursModal(openDate: openingDate): void {

        this.clearCheckedDays();
        this.displayOpenDate = openDate;
        this.selectedHour = new hour;
        // jQuery('#addHours').modal('show');
        this.addHours.show();

        setTimeout(() => {
            jQuery("#attentionType").prop('selectedIndex', 0);
        }, 300);
    }

    public hideAddHoursModal(): void {
        this.addHours.hide();
    }

    /**
     * Metodo encargado de limpiar el formulario de Horarios
     * Con el fin de prevenir errores al momento de crear/editar un horario
     * o cuando se cierre el modal sin haber hecho la peticion
     */
    clearFormHour() {
        jQuery('#saveHourBtn').prop('disabled', false);
        if (this.hoursForm.valid) {
            this.hoursForm.reset();
        }
        this.clearHourMinutesSelect();
        this.itemsCheckedHour = [];
    }

    /**
     * Metodo encargado de limpiar el select de las horas y minutos y las variables que se setean
     * al manipular dichos select
     */
    clearHourMinutesSelect() {
        jQuery("#hoursSelectFrom").prop('selectedIndex', 0);
        jQuery("#minutesSelectFrom").prop('selectedIndex', 0);
        jQuery("#hoursSelectTo").prop('selectedIndex', 0);
        jQuery("#minutesSelectTo").prop('selectedIndex', 0);
        jQuery("#formatSelectedFrom").prop('selectedIndex', 0);
        jQuery("#formatSelectedTo").prop('selectedIndex', 0);
        this.hrSelectedFrom = null;
        this.hrSelectedTo = null;
        this.minSelectedFrom = null;
        this.minSelectedTo = null;
        this.formatSelectedFrom = null;
        this.formatSelectedTo = null;
        this.myTimeStart2 = null;
        this.myTimeEnd2 = null;
    }

    /**
     * Metodo encargado de limpiar los checkbox de los dias cuando se abre el modal de agregar horario
     */
    clearCheckedDays() {
        for (var i = 0; i <= this.totalDays; i++) {
            jQuery('#day' + i).attr('checked', false);
        }
        for (var j = 0; j <= this.totalDays; j++) {
            jQuery('#day' + j).prop("disabled", false);
        }

        jQuery('#hourFrom').removeAttr('disabled');
        jQuery('#hourFrom').removeClass('disabled');
        jQuery('#hourTo').removeAttr('disabled');
        jQuery('#hourTo').removeClass('disabled');
        this.days = [];
    }

    /**
     * Metodo que se encarga de validar la informacion enviada en el formulario
     * de agregar horario
     */
    validateSendFormHour(hour: any, isvalid: boolean) {
        if (!isvalid) {
            this.errorHour = true;
            if (this.userLang == 'es') {
                this.msgErrorHour = 'Debe seleccionar un tipo de atención';
            } else {
                this.msgErrorHour = 'You must select a attention type';
            }
            return false;
        }
        //Verificamos que se haya seleccionado al menos un dia para agregar el horario
        if (this.days.length <= 0) {
            this.errorHour = true;
            if (this.userLang == 'es') {
                this.msgErrorHour = 'Debe seleccionar por lo menos un dia';
            } else {
                this.msgErrorHour = 'You must select at least one day';
            }
            return false;
        }

        //Verificamos que se haya seteado un horario de apertura y clausura o que este checkeado abierto todo el dia
        if (!hour.is_open_all_time && (this.hrSelectedFrom == null || this.hrSelectedTo == null || this.minSelectedFrom == null || this.minSelectedTo == null)) {
            this.errorHour = true;
            if (this.userLang == 'es') {
                this.msgErrorHour = 'Debe seleccionar un horario o establecer si tiene atención todo el día';
            } else {
                this.msgErrorHour = 'You must select a set hour or set if you have attention all day';
            }
            return false;
        }

        if (!hour.is_open_all_time && this.format12Hours && (this.formatSelectedFrom == '' || this.formatSelectedTo == '' || this.formatSelectedFrom == null || this.formatSelectedTo == null)) {
            this.errorHour = true;
            if (this.userLang == 'es') {
                this.msgErrorHour = 'Debe seleccionar un formato de hora';
            } else {
                this.msgErrorHour = 'You must select a hour format';
            }
            if (this.formatSelectedFrom == '' || this.formatSelectedFrom == null) {
                jQuery('#formatSelectedFrom').focus();
            } else if (this.formatSelectedTo == '' || this.formatSelectedTo == null) {
                jQuery('#formatSelectedTo').focus();
            }
            return false;
        }

        //Si el campo abierto todo el dia esta seleccionado seteamos a vacio los campos de apertura y clausura
        if (hour.is_open_all_time) {
            this.clearHourMinutesSelect();
        }

        return true;
    }

    /**
     * Metodo encargado de adicionar nuevos horarios dentro de las temporadas
     * @param hour = formulario con los datos para creacion de horario
     * @param isValid = boolean - Verifica si el formulario es valido
     * 
     * @version 1.0.1
     * @author Alvaro Felipe Garcia Mendez - Oct. 12-2018
     */
    public async onSubmitHour(hour: any, idOpeningDate: number) {
        this.processingResponse = true;
        let hourId = hour.id;
        if (!hour.is_open_all_time && hour.myTimeStart && hour.myTimeEnd) {
            hour.hour_from = new Date(this.myTimeStart2).toTimeString().substr(0, 8);
            hour.hour_to = new Date(this.myTimeEnd2).toTimeString().substr(0, 8);
            
            var duration = moment.duration(moment(this.myTimeEnd2).diff(moment(this.myTimeStart2)));
            var hours = duration.hours();
            console.log('duracion', hours);

            if (!hours) {
                await this.allDayChecked(true);
                this.selectedHour.is_open_all_time = true;
                hour.is_open_all_time = true;
                hour.hour_from = null;
                hour.hour_to = null;
                hour.minutesSelectFrom = null;
                hour.minutesSelectTo = null;
                hour.myTimeEnd = null;
                hour.myTimeStart = null;
                console.log('como situvieces', this.selectedHour.is_open_all_time);
            }
            
        }
        if (this.days.length == 0) {
            this.processingResponse = false;
            this.translate.get(['SELECT_DAY']).subscribe(translations => {
                this.toastr.warning(translations.SELECT_DAY);
            });
            return 0;
        }
        if (!hour.is_open_all_time && (!hour.myTimeStart || !hour.myTimeEnd)) {
            this.processingResponse = false;
            this.translate.get(['SELECT_SCHEDULE']).subscribe(translations => {
                this.toastr.warning(translations.SELECT_SCHEDULE);
            });
            return 0;
        }
        if (this.displayOpenDate.hours && this.displayOpenDate.hours.length > 0) {
            for (let j = 0; j < this.displayOpenDate.hours.length; j++) {
                for (let k = 0; k < this.days.length; k++) {
                    // if (this.selectedHour.attention_type == this.openingDate1.openingDates[i].hours[j].attentionType &&
                    //     this.openingDate1.openingDates[i].hours[j].weekDay == parseInt(this.days[k])) {
                    // Validamos que sea el horario para el mismo tipo de servicio y los mismos dias
                    if (Number(this.selectedHour.attention_type) === this.displayOpenDate.hours[j]['attentionType']
                        && this.displayOpenDate.hours[j]['weekDay'].indexOf(this.days[k]) > -1
                        && this.selectedHour.id !== this.displayOpenDate.hours[j]['id']) {
                        // Verificamos si seleccionarion abierto 24 horas
                        if (hour.is_open_all_time) {
                            console.warn('Already exist schedule');
                            this.errorForHours(
                                // this.openingDate1.openingDates[i].hours[j].hourFrom,
                                // this.openingDate1.openingDates[i].hours[j].hourTo,
                                // this.openingDate1.openingDates[i].hours[j].weekDayDescription
                                this.displayOpenDate.hours[j].hourFrom,
                                this.displayOpenDate.hours[j].hourTo,
                                this.displayOpenDate.hours[j].weekDayDescription);
                            return 0;
                        }
                        // manipulamos las horas para hacerlas numeros enteros comparables
                        let hourFrom1 = this.displayOpenDate.hours[j].hourFrom,
                            hourTo1 = this.displayOpenDate.hours[j].hourTo,
                            hourFrom2 = hour.hour_from,
                            hourTo2 = hour.hour_to;

                        // hourFrom1 = parseInt(hourFrom1.replace(regex, ''), 10);
                        // hourTo1 = parseInt(hourTo1.replace(regex, ''), 10);
                        // hourFrom2 = parseInt(hourFrom2.replace(regex, ''), 10);
                        // hourTo2 = parseInt(hourTo2.replace(regex, ''), 10);

                        hourFrom1 = moment("1999-12-16 " + hourFrom1, "YYYY-MM-DD HH:mm:ss");
                        hourTo1 = moment("1999-12-16 " + hourTo1, "YYYY-MM-DD HH:mm:ss");
                        if (hourFrom1 > hourTo1) {
                            hourTo1 = moment(hourTo1, "DD-MM-YYYY").add('days', 1);
                        }

                        hourFrom2 = moment("1999-12-16 " + hourFrom2, "YYYY-MM-DD HH:mm:ss");
                        hourTo2 = moment("1999-12-16 " + hourTo2, "YYYY-MM-DD HH:mm:ss");

                        if (hourFrom2 > hourTo2) {
                            hourTo2 = moment(hourTo2, "DD-MM-YYYY").add('days', 1);
                        }

                        // Comparamos si el rango de horas existe
                        // console.log(hourFrom1, hourFrom2, hourTo2, hourTo1)
                        if ((hourFrom1 >= hourFrom2 && hourFrom1 <= hourTo2) || (hourFrom2 >= hourFrom1 && hourFrom2 <= hourTo1)) {
                            console.warn('Already exist schedule');
                            this.errorForHours(
                                this.displayOpenDate.hours[j].hourFrom,
                                this.displayOpenDate.hours[j].hourTo,
                                this.displayOpenDate.hours[j].weekDayDescription);
                            return 0;
                        }
                    }
                }
            }
        }
        let weekDays: string = this.days.toString();
        jQuery('#saveHourBtn').prop('disabled', true);

        this.restaurantService
            .saveHour(hour, weekDays, idOpeningDate, this.idRestaurant)
            .subscribe(hour => {
                this.newHour = hour;
                this._service.success(this.title, this.content, { id: 123 });
                this.addHours.hide();
                this.processingResponse = false;
                this.getOpeningDates();

                setTimeout(() => {
                    //this.getOpeningDates();
                    this.clearFormHour();
                    this.addHours.hide();
                    // jQuery('#addHours').modal('hide');
                    this.clearCheckedDays();
                    if (hourId) {
                        //this.alertService.alertEditedSuccess();
                        this.alertService.alertGestionSuccess("editedSuccessfully");
                    }
                    else {
                        //this.alertService.alertSaveSuccess();
                        this.alertService.alertGestionSuccess("SuccessfullyCreated");
                    }

                }, 500);
            },
                error => {
                    this.error = error
                    this.processingResponse = false;
                    if (hourId) {
                        //this.alertService.alertEditedError();
                        this.alertService.alertGestionError("failedEdition");
                    }
                    else {
                        //this.alertService.alertSaveError();
                        this.alertService.alertGestionError("errorSaving");
                    }
                });
    }

    /**
     * Muestra mensaje de error para horarios ya existentes
     * @argument hourFrom Hora de apertura del servicio
     * @argument hourTo Hora de cierre del servicio
     * @argument weekDay Dia del servicio
     * 
     * @version 1.0.0
     * @author Alvaro Felipe Garcia Mendez - Oct. 12-2018
     */
    private errorForHours(hourFrom: any, hourTo: any, weekDay: any) {
        this.processingResponse = false;
        this.translate.get(["SCHEDULE_ALREADY_EXIST"],
            {
                startDate: hourFrom,
                endDate: hourTo,
                weekDay: weekDay
            })
            .subscribe(translations => {
                this.toastr.warning(translations.SCHEDULE_ALREADY_EXIST);
            });
    }

    closeModalHour() {
        this.clearFormHour();
        this.addHours.hide();
        // jQuery('#addHours').modal('hide');
    }

    closeModalDetailsHour() {
        this.clearFormHour();
        this.addHours.hide();
        // jQuery('#detailsHour').modal('hide');
    }

    /**
     * Metodo encargado de enviar la peticion para capturar
     * los horarios de una temporada
     * @argument idOpenDate
     * 
     * @version 1.0.1
     * @author Alvaro Felipe Garcia Mendez - Oct. 11-2018
     */
    private getHour(idOpenDate: number): Observable<any> {
        return this.restaurantService
            .getHour(this.idRestaurant, idOpenDate, this.userLang)
            .pipe(map(hour => {
                this.hour = hour['data'];
            },
                error => {
                    console.error(error);
                }
            ));
    }

    /**
     * Metodo encargado de abrir el modal para listar los
     * horarios pertenecientes a una temporada
     * @argument openDate
     * 
     * @version 1.0.1
     * @author Alvaro Felipe Garcia Mendez - Oct. 11-2018
     */

    public showDetailsHour(openDate: any): void {
        this.clearFormHour();
        this.displayOpenDate = openDate;
        this.getHour(openDate.id).subscribe(() => {
            for (var i = 0; i < this.hour.length; i++) {
                if (this.hour[i].hourFrom != null && this.hour[i].hourTo != null) {
                    this.hour[i].hourFrom = this.utils.captureHour(this.hour[i].hourFrom, this.format12Hours);
                    this.hour[i].hourTo = this.utils.captureHour(this.hour[i].hourTo, this.format12Hours);
                }
            }
            this.detailsHou.show();
        }, () => {
            console.error('Error in promise');
        });
    }

    /**
     * Esconde modal de horas.
     * @version 1.0.0
     */
    public hideDetailsHour(): void {
        this.detailsHou.hide();
    }

    /**
     * Metodo encargado de eliminar un horario de una temporada
     * Puede eliminar solo un item o varios
     * @param hour: any -> Item(s) que se va a eliminar
     * @param openDate: any -> Temporada a la cual pertenece el horario
     */
    deleteHour(hour: any, openDate: any) {
        let arrayHour = [];

        this.content = this.utils.defineContent(hour, this.userLang, 'Deleted');
        if (this.userLang == 'es') {
            this.title = 'Horario';
        } else {
            this.title = "Hour";
        }

        //Validación para saber si se va a eliminar solo un item o varios items
        if (hour.constructor === Array) {
            arrayHour = hour;
        } else {
            arrayHour.push(hour.id);
        }

        for (var index = 0; index < arrayHour.length; index++) {
            this.restaurantService
                .deleteHour(arrayHour[index], openDate.id, this.idRestaurant)
                .subscribe(hour => {
                    this.processingResponse = false;
                    this.newHour = hour;

                    setTimeout(() => {
                        this.getOpeningDates();
                        this.getHour(openDate.id).subscribe(() => {
                            this._service.success(this.title, this.content, { id: 123 });
                            this.cleanArrayHours();
                            this.clearFormHour();
                            //this.alertService.alertDeletedSuccess();
                            this.alertService.alertGestionSuccess("deletedSuccessfully");
                        }, () => {
                            console.error('Error in promise');
                        });
                    }, 600);

                    setTimeout(() => {
                        this.showDetailsHour(openDate);
                    }, 1800);
                },
                    error => {
                        this.deletingDatesError(error);
                    });
        }
    }

    private deletingDatesError(error) {
        this.error = error;
        //this.alertService.alertDeletedError();
        this.alertService.alertGestionError("eliminationFailed");
    }

    editImageModal(modal: any, image: any, category: number) {
        this.fileImage = null;
        this.canEditImage = true;
        this.selectedImage = new imageRestaurant;
        this.selectedImage = image;
        this.modalRef = this.modalService.show(modal);
    }


    /**
     * Metodo encargado de abrir el modal de editar los horarios y setear la informacion necesaria
     * para poder realizar la edicion
     * @param openDate: openingDate -> Temporada al que pertenece el horario
     * @param hourEdit: any -> Registro de horario que se quiere editar
     */

    editHour(openDate: openingDate, hourEdit: any) {

        this.clearFormHour();
        this.clearCheckedDays();
        this.clearHourMinutesSelect();
        this.displayOpenDate = openDate;
        this.selectedHour = new hour;

        setTimeout(() => {
            //Asignacion de informacion a editar del horario
            this.selectedHour = hourEdit;

            //Seteo de los campos que se mostraran en el formulario
            this.selectedHour.id = hourEdit.id;
            this.selectedHour.week_day = hourEdit.weekDay;
            this.selectedHour.attention_type = hourEdit.attentionType;
            this.selectedHour.is_open_all_time = hourEdit.isOpenAllTime;

            if (hourEdit.hourFrom != null && hourEdit.hourTo != null) {
                //Captura de los Horarios de Apertura y Clausura (Horas y Minutos)
                let hourFrom = parseInt(hourEdit.hourFrom.substr(0, 2));
                let minFrom = parseInt(hourEdit.hourFrom.substr(3, 2));
                let hourTo = parseInt(hourEdit.hourTo.substr(0, 2));
                let minTo = parseInt(hourEdit.hourTo.substr(3, 2));

                let timeFrom24 = this.convertTime12to24(hourEdit.hourFrom);
                let timeTo24 = this.convertTime12to24(hourEdit.hourTo);

                let hourFrom24 = parseInt(timeFrom24.substr(0, 2));
                let minFrom24 = parseInt(timeFrom24.substr(3, 2));
                let hourTo24 = parseInt(timeTo24.substr(0, 2));
                let minTo24 = parseInt(timeTo24.substr(3, 2));

                let hourInitial = new Date();
                hourInitial.setHours(hourFrom24, minFrom24);
                this.myTimeStart2 = new Date(hourInitial);
                let hourFinal = new Date();
                hourFinal.setHours(hourTo24, minTo24);
                this.myTimeEnd2 = new Date(hourFinal);




                //Captura de formato de hora (AM/PM) para Hora Apertura
                this.formatSelectedFrom = hourEdit.hourFrom.substr(6, 2);
                //Seteo del select de formato de hora
                if (this.formatSelectedFrom == 'AM') {
                    jQuery("#formatSelectedFrom").prop('selectedIndex', 1);
                } else if (this.formatSelectedFrom == 'PM') {
                    jQuery("#formatSelectedFrom").prop('selectedIndex', 2);
                }


                //Captura de formato de hora (AM/PM) para Hora Clausura
                this.formatSelectedTo = hourEdit.hourTo.substr(6, 2);
                //Seteo del select de formato de hora
                if (this.formatSelectedTo == 'AM') {
                    jQuery("#formatSelectedTo").prop('selectedIndex', 1);
                } else if (this.formatSelectedTo == 'PM') {
                    jQuery("#formatSelectedTo").prop('selectedIndex', 2);
                }

                //Seteo de los Horarios de Apertura y Clausura
                this.hrSelectedFrom = hourFrom;
                this.hrSelectedTo = hourTo;
                this.minSelectedFrom = minFrom;
                this.minSelectedTo = minTo;

                //Seteo de los select de hora apertura y hora clausura
                jQuery("#hoursSelectFrom").prop('selectedIndex', hourFrom);
                jQuery("#hoursSelectTo").prop('selectedIndex', hourTo);

            }

        }, 400);

        //Esconde el modal que muestra los detalles del horario
        this.detailsHou.hide();
        //Se esperar el tiempo suficiente entre el cerrado del modal de detalle y el modal de editar
        setTimeout(() => {
            //Se muestra el modal encargado de editar los datos
            this.addHours.show();

            //Checked dia seleccionado
            jQuery('#day' + hourEdit.week_day).prop('checked', 'checked');
            this.days = [hourEdit.week_day.toString()];
            for (var index = 0; index <= this.totalDays; index++) {
                if (hourEdit.week_day != index) {
                    jQuery('#day' + index).prop("disabled", true);
                }
            }
        }, 1000);
    }

    /**
     * Metodo que se encarga de manejar los checkbox de los dias para la adicion de
     * un horario
     */
    updateChecked(value: number, event: any) {
        if (event.target.checked) {
            this.days.push(value);
        }
        else if (!event.target.checked) {
            let indexx = this.days.indexOf(value);
            this.days.splice(indexx, 1);
        }
    }

    /**
     * Metodo que se encarga de manejar la validacion de las horas y jornada completa
     * cuando se esta adicionando o editando un horario
     */
    allDayChecked(checked: any) {

            this.clearHourMinutesSelect();
            //Si el campo "isOpenAllDate" es true se debe deshabilitar los inputs de hora de apertura y clausura
            if (checked) {
                jQuery('#hourFrom').attr('disabled', 'disabled');
                jQuery('#hourTo').attr('disabled', 'disabled');
                jQuery('#hourFrom').addClass('disabled');
                jQuery('#hourTo').addClass('disabled');
                jQuery('#hourFrom').val('');
                jQuery('#hourTo').val('');
            } else if (!checked) {
                console.log('aqui');
                jQuery('#hourFrom').removeAttr('disabled');
                jQuery('#hourTo').removeAttr('disabled');
                jQuery('#hourFrom').removeClass('disabled');
                jQuery('#hourTo').removeClass('disabled');
            }

    }

    /**
     * Metodo que se encarga de llenar los dias dependiendo del mes seleccionado
     * para agregar una temporada
     */
    monthSelected(month: any, typeDate: string) {

        //Verificamos si el mes debe contener 31 dias
        if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
            //Verificamos desde que select se esta haciendo la peticion para setear a 0 los dias
            if (typeDate == 'sd') {
                this.daysInMonthSd = [];
            } else if (typeDate == 'ed') {
                this.daysInMonthEd = [];
            }

            //Se llena los dias con la cantidad requerida
            for (let i = 1; i <= 31; i++) {
                if (typeDate == 'sd') {
                    this.daysInMonthSd.push(i);
                } else if (typeDate == 'ed') {
                    this.daysInMonthEd.push(i);
                }
            }
        }
        //Verificamos si el mes debe contener 30 dias
        if (month == 4 || month == 6 || month == 9 || month == 11) {
            if (typeDate == 'sd') {
                this.daysInMonthSd = [];
            } else if (typeDate == 'ed') {
                this.daysInMonthEd = [];
            }

            for (let i = 1; i <= 30; i++) {
                if (typeDate == 'sd') {
                    this.daysInMonthSd.push(i);
                } else if (typeDate == 'ed') {
                    this.daysInMonthEd.push(i);
                }
            }
        }
        //Validacion mes febrero
        if (month == 2) {
            if (typeDate == 'sd') {
                this.daysInMonthSd = [];
            } else if (typeDate == 'ed') {
                this.daysInMonthEd = [];
            }

            for (let i = 1; i <= 28; i++) {
                if (typeDate == 'sd') {
                    this.daysInMonthSd.push(i);
                } else if (typeDate == 'ed') {
                    this.daysInMonthEd.push(i);
                }

            }
        }

        if (typeDate == 'sd') {
            this.mSelectedSd = month;
        } else if (typeDate == 'ed') {
            this.mSelectedEd = month;
        }
    }

    /**
     * Metodo encargado de setear los dias seleccionados al agregar o editar una temporada
     * @param day : any -> Numero del dia seleccionado
     * @param typeDate: string -> Identificador si es fecha inicial o fecha final
     */
    daySelected(day: any, typeDate: string) {
        if (typeDate == 'sd') {
            this.dSelectedSd = day;
        } else if (typeDate == 'ed') {
            this.dSelectedEd = day;
        }
    }

    /**
     * Metodo encargado de cerrar el modal para agregar/editar imagenes
     * Limipia el formulario para prevenir posibles errores
     */
    private closeModalAddImages() {
        // this.uploader.clearQueue();
        jQuery('#titleImage').val('');
        jQuery('#descriptionImage').val('');
        jQuery('#fileImageRestaurant').val('').clone(true);
        jQuery('#btnSaveImage').prop('disabled', false);
        this.fileImage = null;
        this.errorHour = false;
        this.msgErrorHour = "";
    }

    public gotoRestaurant() {
        this.router.navigate([this.localize.translateRoute('/app/restaurant')]);
    }

    /**
     * Metodo encargado de abrirl el modal para confirmar la eliminacion de un elemento
     * Se encarga de confirmar la eliminacion de imagenes, temporadas y horarios
     * @param itemToDelete: any -> Registro que se quiere eliminar
     * @param system: string -> Seccion de la cual se va a eliminar (Temporada, Horario, Imagen)
     */
    public confirmDelete(itemToDelete: any, system: string) {
        this.itemToDelete = itemToDelete;
        this.system = system;

        if (system == 'hour' || system == 'variousHours') {
            this.canDeleteModal.hide();
        }
        this.canDeleteModal.show();
    }

    public confirmDeletCerar(): void {
        this.canDeleteModal.hide();
    }

    /**
     * Metodo encargado de llamar la funcion necesaria para eliminar un registro
     * dependiendo desde que seccion se haya solicitado la eliminacion
     * @param itemToDelete: any -> Registro que se quiere eliminar
     * @param event: any -> Evento que se ha solicitado
     * @param system: string -> Seccion de donde se va a eliminar el registro (Temporada, Horario, Imagen)
     */
    public deleteSystem(itemToDelete: any, event: any, system: string) {
        this.processingResponse = true;
        jQuery('#deleteBtn').prop('disabled', true);
        event.stopPropagation();
        if (system == 'season') {
            this.deleteOpenDate(itemToDelete);
        } else if (system == 'image') {
            this.deleteImage(itemToDelete);
        } else if (system == 'hour' || system == 'variousHours') {
            this.deleteHour(itemToDelete, this.displayOpenDate);
        }
        this.getOpeningDates();
        setTimeout(() => {
            jQuery('#deleteBtn').prop('disabled', false);
            this.canDeleteModal.hide();
            this.itemToDelete = null;
            this.system = "";
        }, 1500);
    }

    /**
     * Metodo encargado de dirigir al usuario a la seccion de menu por medio del boton
     * @param restaurant: restaurant -> Restaurante que contiene el menu
     */
    gotoMenu(restaurant: restaurant) {

        this.menuesService
            .getMainMenu(restaurant.id)
            .then(menu => {
                this.menu = menu;
            })
            .catch(error => this.error = error);

        setTimeout(() => {
            this.router.navigate(['/app/restaurant', restaurant.id, 'menu', this.menu.id, 'categories']);
        }, 800);
    }

    /**
     * Metodo encargado de reconocer cuando el punto a sido movido en el mapa y de esta forma
     * capturar las nuevas coordenadas que quiere setear el usuario
     */
    markerDragEnd(event: MouseEvent) {
        let coordenadas: any = event;
        this.detailRestaurant.address['latitude'] = coordenadas.coords.lat;
        this.detailRestaurant.address['longitude'] = coordenadas.coords.lng;
    }

    activeEditMap() {
        this.canEditMap = true;
    }

    deactiveEditMap() {
        this.canEditMap = false;
    }

    /**
     * Metodo encargado de setear las variables de Horas, Minutos y Formato de hora de los Select
     * destinados para este proposito
     */
    captureHourMinSelected(item: any, type: string, slctType: any) {

        if (item == 'null') {
            item = null;
        }

        setTimeout(() => {
            if (slctType == 'hoursSelectFrom' && type == 'from') {
                this.hrSelectedFrom = item;
            } else if (slctType == 'hoursSelectTo' && type == 'to') {
                this.hrSelectedTo = item;
            } else if (slctType == 'minutesSelectFrom' && type == 'from') {
                this.minSelectedFrom = item;
            } else if (slctType == 'minutesSelectTo' && type == 'to') {
                this.minSelectedTo = item;
            } else if (slctType == 'formatSelectedFrom' && type == 'from') {
                this.formatSelectedFrom = item;
            } else if (slctType == 'formatSelectedTo' && type == 'to') {
                this.formatSelectedTo = item;
            }
        }, 400);
    }

    /**
     * Metodo encargado del manejo del checkbox en la vista de los detalles de un horario
     * Captura el item seleccionado y lo adiciona a un array que servira para realizar la petición
     * de eliminar varios horarios a la vez
     * @param event: any -> Elemento html para capturar si el checkbox a sido chequeado
     * @param idCheck: any -> Id del checkbox que servira como referencia para habilitar o desahibilitar el boton de eliminar el elemento
     * @param idItem: number -> Id del item que se adicionara al array para ser borrado posteriormente
     */
    checkToDeleteVarious(event: any, idCheck: any, idItem: number) {
        if (event.target.checked) {
            jQuery('#btnDelete' + idCheck).prop('disabled', true);

            this.itemsCheckedHour.push(idItem);
        } else if (!event.target.checked) {
            jQuery('#btnDelete' + idCheck).prop('disabled', false);

            for (var i = 0; i < this.itemsCheckedHour.length; i++) {
                if (this.itemsCheckedHour[i] == idItem) {
                    this.itemsCheckedHour.splice(i, 1);
                }
            }
        }

    }

    /**
     * Metodo encargado de Limpiar el array que contiene los horarios que serían borrados
     * al mismo tiempo
     */
    cleanArrayHours() {
        this.itemsCheckedHour = [];
        for (var i = 0; i < this.hour.length; i++) {
            jQuery('#btnDelete' + (i + 1)).prop('disabled', false);
            jQuery('#' + (i + 1)).prop('checked', false);
        }
    }

    convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return hours + ':' + minutes;
    }

    openWindow() {
        window.open(this.urlWebKijho, '_blank');
    }

    openWindowName() {
        window.open(encodeURI(this.urlWebKijhoList + '?name=' + this.nameRestaurant), '_blank');
        //alert(this.urlWebKijhoList  +this.nameRestaurant.replace(/\s/g,''));
    }
    /**
       * Metodo encargado de traer la ruta de la imagen en tamaño grande
       *
       * @param images
       */
    getImagePathSlider(images: any) {

        const defaultImagePath = './assets/extra-images/listing-detail-1.jpg';

        if (typeof images === 'undefined') {
            return defaultImagePath;
        }

        const image: any = this.imagesUtils.getOneImage(images);

        if (image == null) {
            return defaultImagePath;
        }

        this.imagesUtils.setContentFolder('prod/images/restaurant/');
        this.imagesUtils.setImageDefault(defaultImagePath);
        this.imagesUtils.setSizeFormat('-xxhdpi-1200x460');

        return this.imagesUtils.getPath(images);
    }
    /**
     * Metodo encargado de traer la ruta de la imagen
     *
     * @param images
     */
    // getImagePathMainThumb(images: any) {
    //     for (let i = 0; i < this.imagesRestaurant.length; i++) {
    //         if (this.imagesRestaurant[i].category == 7) {

    //         }
    //         const element = this.imagesRestaurant[i];

    //     }
    //     const defaultImagePath = './assets/extra-images/recent-realestate-2.jpg';

    //     if (typeof images === 'undefined') {
    //         return defaultImagePath;
    //     }

    //     let image = null;

    //     image = this.imagesUtils.getOneImage(images);

    //     if (image == null) {
    //         return defaultImagePath;
    //     }

    //     this.imagesUtils.setContentFolder('prod/images/restaurant/');
    //     this.imagesUtils.setImageDefault(defaultImagePath);
    //     this.imagesUtils.setSizeFormat('-xxxhdpi-411x448');

    //     return this.imagesUtils.getPath(image);
    // }

    /**
     * @description Abre el modal que permite cerrar un restaurante
     */
    public openModalRestaurantClose() {
        console.log('openModal', this.restaurantCloseDescription, this.detailRestaurant, this.buttonStatus);
        this.restaurantModal.show();
    }

    /**
     * @description se encarga de recibir la descripcion y cerrar elrestaurante
     * @param restaurantState parametro con el estado del (0 abierto 1 cerrado)
     */
    public closeOrOpenRestaurant(restaurantState: number) {
        this.buttonStatus = true;
    
        this.restaurantService.closeOrOpenRestaurant(this.idRestaurant, this.restaurantCloseDescription, restaurantState)
            .subscribe(response => {
                if (!response['errors']) {
                    this.alertService.alertGestionSuccess('SuccessfullyEdited');
                    this.restaurantCloseDescription = '';
                    this.getFirstLoadRestaurant();
                    this.buttonStatus = false;
                    this.closeModalRestaurantClose();
                } else {
                    this.restaurantCloseDescription = '';
                    this.buttonStatus = false;
                    this.closeModalRestaurantClose();
                    this.alertService.alertGestionError("errorSaving");
                }
            });
    }

    public closeModalRestaurantClose() {
        this.restaurantModal.hide();
    }

}
