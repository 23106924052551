import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';

import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class LegalService {
    constructor(private http: Http,
        private utils: UtilsComponent
    ) { }

    private omtUrl = this.utils.getUrlOmtSd();
    private legalUrl = this.utils.getUrlOmt() + 'sd';

    getLegalTerms(userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.legalUrl}/termAndConditions`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractLegalData)
            .catch(this.handleError);
    }

    private extractLegalData(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    /**
     * Metodo encargado de crear un nuevo termino de servicio
     * @param legalTermData: any -> Formulario con la información del término de servicio a crear
     * @param userLang: string -> Variable con lenguaje del sistema
     */
    postCreateLegalTerm(legalTermData: any, userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url = "";

        if (legalTermData.name == 1) {
            url = `${this.legalUrl}/termAndCondition`;
        } else if (legalTermData.name == 2) {
            url = `${this.legalUrl}/privacyPolicy`;
        }

        var today = new Date();
        today.setTime(today.valueOf() + 10 * 365 * 24 * 60 * 60 * 1000);
        today = this.utils.extractDate(today.toLocaleString('en').substr(0, 10));

        var arrayData = {
            start_date: legalTermData.startDate,
            end_date: today
        }

        return this.http
            .post(url, arrayData, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de modificar termino de servicio agregando los terminos legales
     * correspondientes al mismo
     * @param legalTermData: any -> Formulario con la información del término de servicio a modificar
     * @param idLegalTerm: number -> Id del término de servicio a modificar
     * @param userLang: string -> Varible con el lenguaje del sistema
     */
    putContentLegalTerm(legalTermData: any, idLegalTerm: number, userLang: string, language: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url: string = "";
        let description: string = "";
        if (language == 'en') {
            description = legalTermData.rulesEn;
        } else {
            description = legalTermData.rulesSp;
        }

        var today = new Date();
        today.setTime(today.valueOf() + 10 * 365 * 24 * 60 * 60 * 1000);
        today = this.utils.extractDate(today.toLocaleString('en').substr(0, 10));

        var arrayData = new Object();
        arrayData['language'] = language;
        arrayData['start_date'] = legalTermData.startDate;
        arrayData['end_date'] = today;

        if (legalTermData.name == 1) {
            url = `${this.legalUrl}/termAndCondition/${idLegalTerm}`;
            arrayData['terms_description'] = description;
        } else if (legalTermData.name == 2) {
            url = `${this.legalUrl}/privacyPolicy/${idLegalTerm}`;
            arrayData['privacy_description'] = description;
        }

        return this.http
            .put(url, arrayData, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de enviar petición al servidor para eliminar un término o política de privacidad
     */
    delete(legalTermData: any, userLang: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let url: string = "";

        if (legalTermData.name == 1) {
            url = `${this.legalUrl}/termAndCondition/${legalTermData.id}`;
        } else if (legalTermData.name == 2) {
            url = `${this.legalUrl}/privacyPolicy/${legalTermData.id}`;
        }

        return this.http
            .delete(url, { headers })
            .toPromise()
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    //************ Web Service Privacy Policies ***************//

    /**
    * Metodo encargado de solicitar el listado de las políticas de privacidad al web service
    */
    getPrivacyPolicies(userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.legalUrl}/privacyPolicies`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractLegalData)
            .catch(this.handleError);
    }

}
