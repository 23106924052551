<h1 class="page-title">{{ 'NewRestaurant' | translate }} <small></small></h1>

<div class="row" *ngIf="canShowSection">
    <div class="col-lg-12 col-xs-12">
        <section widget class="widget">
            <header>
                <h4>
                    <div *ngIf="restaurant">{{restaurant.name}}</div>
                </h4>
            </header>
            <div class="widget-body">
                <div class="row mb-lg">
                    <!-- <div *ngIf="canShowSection" class="col-lg-12 col-xs-12"> -->
                    <div *ngIf="canShowSection" class="col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="clearfix">
                                    <ul class="nav nav-tabs pull-xs-left" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="new-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="basic" aria-expanded="true">{{ 'DetailsRestaurant' | translate }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link no-cursor" id="details-tab" role="tab" aria-controls="details" aria-expanded="false">{{ 'Services' | translate }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link no-cursor" id="image-tab" role="tab" aria-controls="image" aria-expanded="false">{{ 'Image' | translate }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link no-cursor" id="image-tab" role="tab" aria-controls="dates" aria-expanded="false">{{ 'OpeningDates' | translate }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link no-cursor" id="image-tab" role="tab" aria-controls="menu" aria-expanded="false">{{ 'Menu' | translate }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-2"> 
                                <div class="slideThree">                                    
                                        <input class="inputStatus"  type="checkbox" value="1" id="slideThree" name="status" [checked]="statusRestaurant" (click)="actiStatus()"/>
                                        <label for="slideThree"></label>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div class="tab-content mb-lg" id="myTabContent">
                            <div role="tabpanel" class="tab-pane active in clearfix" id="basic" aria-labelledby="basic-tab" aria-expanded="true">
                             <form class="form-horizontal" [formGroup]="restaurantForm" novalidate (submit)="onSubmit(restaurantForm.value, restaurantForm.valid, addressInput.value, phone.value)" (keypress)="methodkeyp($event)"  >
                                            
                                <!-- <p class="text-xs-right">
                                    <a class="btn btn-gray md-xs" (click)="goBack()">
                                        <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                                    </a>
                                    <button [disabled]="buttonStatus" id="saveBtn" type="submit" class="btn btn-success md-xs" >{{ 'Save' | translate }}</button>
                                </p>      -->
                                <div class="flx">
                                    <a class="btn btn-gray mb-xs nomar" (click)="goBack()">
                                      <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
                                    </a>
                                    <button id="saveBtn" type="submit" class="btn btn-success mb-xs nomar" [hidden]="buttonStatus">{{
                                      'Save' | translate }} 
                                    </button>
                                    <button class="btn btn-gray flx" [hidden]="!buttonStatus"
                                    [disabled]="buttonStatus">{{ 'processing' | translate }}
                                    <div class="lds-ellipsis">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  </button>
                                </div>                        
                                <p><label>{{ 'RequiredFields' | translate }} (<b class="reqe">*</b>)</label></p>
                                <div *ngIf="invalid">
                                </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12 col-xs-12">
                                            <fieldset class="scheduler-border">
                                                <legend class="scheduler-border">{{ 'RestaurantInformation' | translate }}</legend>
                                                <div class="col-md-12 col-sm-12 col-xs-12">

                                                    <div class="form-group">
                                                        <label>{{ 'Name' | translate }} <b class="reqe">*</b></label>
                                                        <input type="text" class="form-control" placeholder="{{ 'Name' | translate }}" required="required" formControlName="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-xs-12">

                                                    <div class="form-group">
                                                        <label>{{ 'Phone' | translate }} <b class="reqe">*</b></label>
                                                        <input #phone data-mask="(999)999-9999" type="text" class="form-control" required="required" placeholder="(999) 999-9999" formControlName="phone">
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{ 'Website' | translate }} </label>

                                                        <input type="text" class="form-control" placeholder="https://www.example.com/" formControlName="website">
                                                        <div *ngIf="!restaurantForm.controls['website'].valid && !restaurantForm.controls['website'].pristine" class="alert alert-sm alert-danger">{{ 'Invalid URL' | translate }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{ 'UrlMenu' | translate }} </label>

                                                        <input type="text" class="form-control" placeholder="https://www.example.com/menu" formControlName="urlMenu">
                                                        <div *ngIf="!restaurantForm.controls['urlMenu'].valid && !restaurantForm.controls['urlMenu'].pristine" class="alert alert-sm alert-danger">{{ 'Invalid URL' | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{ 'Description' | translate }}</label>
                                                        <textarea class="form-control" placeholder="{{ 'Description' | translate }}" formControlName="description" rows="10" ></textarea>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset class="scheduler-border">
                                                    <legend class="scheduler-border">{{ 'Contact' | translate }}</legend>
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <div class="form-group">
                                                            <label (click)="loadGeo()"> {{ 'ContactPerson' | translate }}</label>
                                                            <input type="text" class="form-control" placeholder="{{ 'ContactPerson' | translate }}" formControlName="contactPerson">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <div class="form-group">
                                                            <label>{{ 'Email' | translate }}</label>
                                                            <input type="email" class="form-control" placeholder="{{ 'Email' | translate }}" formControlName="email">
                                                            <div *ngIf="!restaurantForm.controls['email'].valid && !restaurantForm.controls['email'].pristine" class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                        </div>

                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            <fieldset class="scheduler-border">
                                                <legend class="scheduler-border"> {{ 'Location' | translate }} </legend>
                                                <div class="row">
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{ 'CityStateCountry' | translate }} <b class="reqe">*</b></label>
                                                        <input id="naturalAddress" #addressInput [(ngModel)]="naturalAddress" type="text" class="form-control" 
                                                        placeholder="{{ 'CityStateCountry' | translate }}" required=required formControlName="naturalAddress">
                                                        <input  type="hidden" [(ngModel)]="city" formControlName="city" placeholder="city">
                                                        <input  type="hidden" [(ngModel)]="state" formControlName="state" placeholder="state">
                                                        <input  type="hidden" [(ngModel)]="country"  formControlName="country" placeholder="country">
                                                        <input  type="hidden" [(ngModel)]="street" required=required formControlName="street" placeholder="street">
                                                        <input  type="hidden" [(ngModel)]="postal_code" formControlName="zipCode" placeholder="postal_code">
                                                        <input  type="hidden" [(ngModel)]="latitude" formControlName="latitude" placeholder="latitude">
                                                        <input  type="hidden" [(ngModel)]="longitude" formControlName="longitude" placeholder="longitude">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{ 'SuitNumber' | translate }}</label>
                                                        <input type="text" [(ngModel)]="suitNumber" class="form-control" placeholder="{{ 'SuitNumber' | translate }}" formControlName="suitNumber">
                                                    </div>
                                                </div>
                                            </div>   
                                                <label *ngIf="naturalAddress">
                                                    <span>{{ street }} <br> </span>                                                            
                                                    <span *ngIf="suitNumber">{{ suitNumber}}<br> </span>
                                                    <span>{{ city }} {{ state }} {{ postal_code }} <br></span>
                                                    <span>{{ country }} </span>
                                                </label>
                                            </fieldset>
                                            <div class="row" #map>
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <fieldset class="scheduler-border">
                                                            <legend class="scheduler-border">{{ 'Map' | translate }}</legend>
                                                            <div class="form-group">
                                                                <label>{{ 'distanceMap' | translate }} <strong>{{delivery_range | distances}}</strong>  {{ 'MILES' | translate}}</label>
                                                                <input
                                                                class="form-control slider"
                                                                [(ngModel)]="delivery_range"
                                                                type="range"
                                                                formControlName="delivery_range"
                                                                min="{{range_min}}"
                                                                max="{{range_max}}"
                                                                (change)="updateCircleRadius(delivery_range)"
                                                              />
                                                            </div>
                                                            <label>{{ 'DragAndDropArea' | translate }} </label>
                                                            <i style="color:red;" class="fa fa-map-marker"></i>
                                                            <agm-map (mapReady)="onMapReady($event)" >                                                     
                                                            </agm-map> 
                                                        </fieldset>
                                                    </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="col-md-4 col-sm-12 col-xs-12">
                                            <fieldset class="scheduler-border">
                                                <legend class="scheduler-border">Datos Restaurante</legend>
                                            </fieldset>
                                        </div> -->
                                    </div>
                                
                                    <p class="text-xs-right">
                                        <!--<button type="submit" [disabled]="!restaurantForm.valid" class="btn btn-success md-xs">{{ 'Save' | translate }}</button>-->
                                    </p>
                                </form>
                            </div>
                            <div class="tab-pane" id="details" role="tabpanel" aria-labelledby="details-tab" aria-expanded="false">
                                All Details
                            </div>
                            <div class="tab-pane" id="image" role="tabpanel" aria-labelledby="image-tab" aria-expanded="false">
                                All Images
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <simple-notifications [options]="options"></simple-notifications> -->
        </section>
    </div>
</div>
<!-- <inspectPermits></inspectPermits> -->