<div class="modal-header">
    <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">
        {{ 'INFORMATION' | translate }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-xs-12">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ 'Status' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ "POST" | translate }}</th>
                        <td>{{ 'DESCRIPTION_REVIEW_ACTION_POST' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "PENDING" | translate }}</th>
                        <td>{{ 'DESCRIPTION_REVIEW_ACTION_PENDING' | translate }}</td>
                    </tr>
                    <tr>
                        <th scope="row">{{ "REJECT" | translate }}</th>
                        <td>{{ 'DESCRIPTION_REVIEW_ACTION_REJECT' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>