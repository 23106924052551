import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TreatedImage } from '../../entity/treatedImage';
import { UtilsComponent } from '../../utils/utils';

@Component({
    selector: 'selector-images',
    templateUrl: './selectorImages.component.html',
    styleUrls: ['./selectorImages.component.css'],

})
export class SelectorImagesComponent {
    public imageCroped: any;
    typeImage: any;
    sizeImg = 1953130;
    attachmentService: any;
    title = 'app';
    imageChangedEvent: any = '';
    croppedImage: any = '';
    imageSource: any;
    textWarn: any;
    errorExist: any;
    /**
     * se tienen dimensiones por defecto con sus respectivos aspect ratio (relación de aspecto)
     * ratio image = x/y 
     * ex: 4/3 (ratio) 1200 (width) 900 (height)
     *  1200/900 = 4/3 
     * ex 2: 1200 (width) 460 (height) ? (ratio)
     *  1200/460 = 60/23
     */
    imagesDimensions = {
        logo: { width: 300, height: 300, x: 1, y: 1 },
        main: { width: 550, height: 366, x: 1.50, y: 1 },
        slider: { width: 1200, height: 460, x: 2.61, y: 1 },
        prod: { width: 414, height: 274, x: 1.51, y: 1 }
    };


    @Output()
    onChange: EventEmitter<TreatedImage>;


    categoryImage: number;
    constructor(
        private translate: TranslateService,
        private utils: UtilsComponent
    ) {
        this.onChange = new EventEmitter<TreatedImage>();
        this.imageSource = null;
        this.errorExist = false;

        this.categoryImage = this.utils.categoryImage;
        // var dim;
        if (this.categoryImage == 6) {
            this.imageCroped = this.imagesDimensions.logo;

        } else if (this.categoryImage == 7) {
            this.imageCroped = this.imagesDimensions.main;
        }
        else if (this.categoryImage == 8) {
            this.imageCroped = this.imagesDimensions.slider;
        } else {
            this.imageCroped = this.imagesDimensions.prod
        }

    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    public imageCropped(image: string) {

        // this.croppedImage = image;
        const result = new TreatedImage();
        result.imageOriginal = this.imageSource;
        result.imageCroppied = new File([this.dataURItoBlob(image)], 'image-cropped.jpg',{'type':'image/jpg'});

        this.onChange.emit(result);
    }
    public imageLoaded() {
        console.log("Imagen cargada");
    }
    public loadImageFailed() {
        console.log('Error cargando');
    }
    private dataURItoBlob(dataURI: any): Blob {
        const byteString = atob(dataURI.base64.split(',')[1]);
        const mimeString = dataURI.base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    uploadAttachmentToServer() {
        const fileToUpload: File = new File([this.dataURItoBlob(this.croppedImage)], 'subida.jpeg');
        //this.attachmentService.postAttachment(fileToUpload).subscribe(data => {
        // success, do something
        //}, error => {
        // failure, do something
        //});
    }
    checkPhoto(target) {
        // Tamaño maximo de la imagen de 2MB o 1953130 MiB
        if (target[0].size > this.sizeImg) {
            return false;
        }
        return true;
    }

    /**
     * Método para verificar las dimensiones permitidas de la imagen
     * @param tmpImage 
     * @param callback 
     */
    checkDimensions(tmpImage: File, callback: Function) {
        // Dimensiones mínimas de la imagen
        var dim;
        var catImage;
        if (this.categoryImage == 6) {
            dim = this.imagesDimensions.logo;
        } else if (this.categoryImage == 7) {
            dim = this.imagesDimensions.main;
        }
        else if (this.categoryImage == 8) {
            dim = this.imagesDimensions.slider;
        } else {
            dim = this.imagesDimensions.prod;
        }
        var reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {

                const url = e.target.result;
                const image = new Image();

                image.onload = function () {

                    // aqui validamos si las proporciones son correctas
                    if (image.width < dim.width || image.height < dim.height) {
                        callback(false);
                    }
                    else {
                        callback(true);
                    }
                    //Verificamos si es un logo
                    // if (catImage == 6) {
                    //     // aqui validamos si las proporciones son correctas
                    //     if (80 > image.height || image.height > 200) {
                    //         callback(false);
                    //     } 
                    //     else {
                    //         callback(true);
                    //     }
                    // } else {
                    // }
                    
                };

                image.src = url;

            };
        })(tmpImage);

        // Read in the image file as a data URL.
        reader.readAsDataURL(tmpImage);

    }


    innnerChangeImage(e: any) {
        let currentFiles = null;
        if (e.srcElement && e.srcElement.files.length > 0) {
            currentFiles = e.srcElement.files;
        } else if (e.target.files.length > 0) {
            currentFiles = e.target.files;
        }
        if (currentFiles !== null) {

            const that = this;
            // reseteamos valores
            this.onChange.emit(null);
            this.checkDimensions(currentFiles[0], function (allow) {

                if (allow) {
                    if (!that.checkPhoto(currentFiles)) {
                        that.translate.get("IMAGE_IS_BIG").subscribe(translations => {
                            // that.toastr.info(translations);
                            that.errorExist = true;
                            that.textWarn = translations;
                        });
                    } else {
                        that.errorExist = false;
                        that.imageSource = currentFiles[0];
                        that.fileChangeEvent(e);
                    }
                } else {
                    that.translate.get("IMAGE_DIMENSION").subscribe(translations => {
                        // that.toastr.info(translations);
                        that.errorExist = true;
                        that.textWarn = translations;
                    });
                }

            });
        }
    }

}
