import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-reviews-info',
  templateUrl: './reviews-info.component.html',
  styleUrls: ['./reviews-info.component.sass']
})
export class ReviewsInfoComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  
  /**
   * Cierra el modal
   */
  public closeModal() {
    this.bsModalRef.hide()
  }
}
