import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';

//Variable para manejo autocompletar location
declare var google: any;

@Injectable()
export class autocompletarGoogleService {

    constructor(
        private gMapLoader: MapsAPILoader,
    ) { }


    // autocompletador de google
    inAutocomplete(inputAddress: any) {
        var that = this;
        return new Promise(resolve => {
            that.gMapLoader.load().then(() => {
                const autocomplete = new google.maps.places.Autocomplete(inputAddress);

                //variable que obtiene el array con datos del autocomplete google
                google.maps.event.addListener(autocomplete, 'place_changed', function () {
                    var place = autocomplete.getPlace();
                    resolve(place);
                });
            });
        });
    }
}
