/*import { TranslatePipe} from '@ngx-translate/core';*/
// import { TranslatePipe } from 'ng2-translate';
import { LocalizeRouterService } from 'localize-router';

import { Component, OnInit } from '@angular/core';
/*import { FormGroup, FormControl, Validators, FormBuilder, REACTIVE_FORM_DIRECTIVES }*/
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { Router } from '@angular/router';

import { DetailsRestaurantService } from '../services/detailsRestaurants.service';
import { UserLoginService } from '../services/userLogin.service';
import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Component({
  selector: 'app-login',
  templateUrl: './loginPage.component.html',
  styleUrls: ['./loginPage.component.scss'],
})
export class LoginPageComponent implements OnInit {

  /**
   * @param username: string -> Variable para captura de username o correo para acceder al sistema
   * @param password: string -> Variable para captura de password para acceder al sistema
   */
  username: string = "";
  password: string = "";


  isActive: boolean = false;

  constructor(private _fb: FormBuilder,
    private userService: UserLoginService,
    private router: Router,
    private utils: UtilsComponent,
    private localize: LocalizeRouterService,
    private detailsRestaurantsService: DetailsRestaurantService
  ) { }
  /**
   * Variable para creacion de formulario
   */
  public loginForm: FormGroup;

  /**
   * Variable para control de lenguaje
   */
  userLang: string;

  error: any;
  isError: boolean = false;
  messageError: string;
  moduleEnable: any[];
  ngOnInit() {
    this.userLang = navigator.language.split('-')[0];

    this.loginForm = new FormGroup({
      username: new FormControl('', [<any>Validators.required]),
      password: new FormControl('', [<any>Validators.required]),
    })
  }


  addModule(resultados) {
    let module = {};;
    for (let resu of resultados) {

      module[resu.slug] = resu.id;

      //module.push({ slug: resu.slug, id: resu.id });
      for (let children of resu.children) {

        module[children.slug] = children.id;
        //module.push({ slug: children.slug, id: children.id });
      }
    }

    // console.info("JSON: ", JSON.stringify(module))

    localStorage.setItem(Constants.LOCAL_STORAGE_DATA.MODULES, JSON.stringify(module));
  }


  /**
   * @author William Alarcón Sep 18 - 2018
   * @version 1.0.0
   * @param dataForm 
   * @param isValid 
   */
  onSubmit(dataForm: any, isValid: boolean) {
    /*Variable para setear el usuario que se ha logueado*/
    let user: string;
    this.isActive = true;
    this.userService
      .postLogin(dataForm.username, dataForm.password, this.userLang)
      .then((result) => {
        if (result) {
          this.getOptionsRestaurant();
          this.userService.allModules(this.userLang, result.sessions[0].apiKey)
            .then((resultado) => {
              this.addModule(resultado);
          });
          
          this.userService.setLoggedIn();
          user = result.name + ' ' + result.lastname;
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN, result.sessions[0].apiKey);
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.USER, user);
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.TYPE, result.type);
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.ID_USER, result.id);
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.ENABLED_MODULES, JSON.stringify(result.enabled_modules));
          this.utils.setEnabledModules(result.enabled_modules);
          this.router.navigate([this.localize.translateRoute('/app/dashboard')]);
        }
      })
      .catch(error => {
        this.error = this.extractError(error);
        this.isError = true;
        this.isActive = false;
        this.messageError = this.error.message;
      });
  }

  extractError(res: Response) {
    let body = res.json();
    return body.errors || {};
  }

  loaderFalse() {
    this.isActive = false;
    this.isError = false;
  }

  /**
   * @author William Alarcón Sep 18 - 2018
   * @version 1.0.0
   * Llama las funciones que obtiene los registros por defecto de cosinas, comidas y caracteristicas
   */
  getOptionsRestaurant() {
    this.getCuisines();
    this.getMeals();
    this.getFeatures();
  }

  /**
    * @author William Alarcón Sep 18 - 2018
    * @version 1.0.0
    * Metodo encargado de capturar todos los registros que se encuentren en cocinas
   */
  getCuisines() {
    this.detailsRestaurantsService
      .getAllCuisines(this.userLang)
      .subscribe(cuisines => {
        if (cuisines['data']) {
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.DATA_CUSINES, JSON.stringify(cuisines['data']));
        }
      }, error => {
        //this.error = error
      });
  }


  /**
   * @author William Alarcón Sep 18 - 2018
   * @version 1.0.0
   * Metodo encargado de capturar todos los registros que se encuentren en comidas
   */
  getMeals() {
    this.detailsRestaurantsService
      .getAllMeals(this.userLang)
      .subscribe(meals => {
        if (meals['data']) {
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.DATA_MEALS, JSON.stringify(meals['data']));
        }
      },
        error => {
          this.error = error
        });
  }

  /**
   * @author William Alarcón Sep 18 - 2018
   * @version 1.0.0
   * Metodo encargado de capturar todos los registros que se encuentren en características
   */
  getFeatures() {
    this.detailsRestaurantsService
      .getAllFeatures(this.userLang)
      .subscribe(features => {
        if (features['data']) {
          localStorage.setItem(Constants.LOCAL_STORAGE_DATA.DATA_FEATURES, JSON.stringify(features['data']));
        }
      }, error => {
        this.error = error
      });
  }
}

