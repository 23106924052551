import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UtilsComponent } from '../utils/utils';
import { AuthService } from './auth.service';

//Variable para manejo de funciones Jquery
declare var jQuery: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, private utils: UtilsComponent, private injector: Injector) {
    }


    /**
     * Permite verificar si es una peticion para el servidor api
     * @param urlRequest 
     */
    public isRequestForApi(urlRequest: string): boolean {

        // verificamos si es una url con protocolo, en este caso eso nos dice que esta haciendo una peticion a un servidor
        if (urlRequest.includes("http")) {
            const urlObjRequest = new URL(urlRequest);
            const urlObjApi = new URL(this.utils.getUrlOmt());
            return (urlObjRequest.host == urlObjApi.host);
        }

        return false;
    }


    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // verificamos si estamos haciendo una peticion a la api
        if (!this.isRequestForApi(request.url)) {

            // en caso de no estar haciendo la peticion a la api, no filtramos la cabecera
            return next.handle(request);
        }

        // obtenemos el content type de la petición
        const ContentTypeRequest = request.headers.get('Content-Type');

        // cargamos el servicio que manipula el idioma
        // con el objetivo de saber que idioma tiene seleccionado el usuario
        const translator = this.injector.get(TranslateService);

        let objDataHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': translator.currentLang,
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        if(typeof objDataHeaders['Time-Zone'] === 'undefined'){
            delete objDataHeaders['Time-Zone'];
        }

        if (this.auth.getToken()) {
            objDataHeaders['Api-Key'] = this.auth.getToken();
        }

        // en caso de querer estar subiendo una imagen eliminamos el content type, ya que el propio angular
        // le agrega el content type correspondiente, seguido de un codigo de desencriptacion: 
        // ejemplo:  Content-Type:multipart/form-data; boundary=----WebKitFormBoundaryZoqUTnYP1CCa1tYs
        if (ContentTypeRequest === "multipart/form-data") {
            delete objDataHeaders['Content-Type'];
        }

        const tmpRequest = request.clone({
            responseType: 'json',
            headers: new HttpHeaders(objDataHeaders)
        });

        return next.handle(tmpRequest).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

                // Valido si el servidor devuelve un nuevo token para el usuario y lo seteo
                if (event.headers.get('Api-Key')) {
                    this.auth.setToken(event.headers.get('Api-Key'));
                }

                // verificamos si hay un error
                if (typeof event.body['errors'] !== 'undefined') {

                    // obtenemos el estado de respuesta
                    const responseHttpStatus = Number(event.body['errors']['status']);

                    // verificamos si hay error de sesion
                    if (event.body['errors']['message'] === 'Session not found' || responseHttpStatus === 401) {
                        //this.el.nativeElement.css('display','none');
                        var element = document.getElementById('sidebar');
                        jQuery(element).css('display', 'none');
                        window.location.href = "/#";

                    }
                }
            }
        }, (err: any) => {

            if (err instanceof HttpErrorResponse) {

                if (err.status === 401) {
                    // redirect to the login route
                    // or show a modal
                    window.location.href = "/#";
                }
            }
        }));

    }

}