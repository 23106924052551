import { Injectable } from '@angular/core';

import { UtilsComponent } from './utils';

// Variable para manejo de funciones jquery
declare var jQuery: any;

@Injectable()
export class ImagesUtilsComponent {

    constructor(private utils: UtilsComponent) {
    }

    // Variable que almacena direccion de s3
    urlS3: string = 'https://s3-us-west-1.amazonaws.com/kijho-open-my-tab/';
    // imageFormatPng: string = '-hdpi-364x247.png';
    // imageFormatJpg: string = '-mdpi-320x300.jpg';

    sizeFormat: string = null;
    contentFolder: string = "";
    imageDefault: string = null;


    /**
     * permite obtener el host de la url del servidor api
     * @returns {string}
     */
    public getUrlHostApi() {
        let url = new URL(this.utils.getUrlOmt());
        // console.log(url.protocol); // http:
        // console.log(url.host); // localhost:8080
        // console.log(url.port); //
        return url.protocol + "//" + url.host + "/";
    }


    /**
     * Permite asignar una url de imagen por defecto
     *
     * @param tmpImageDefault
     */
    setImageDefault(tmpImageDefault: string) {
        this.imageDefault = tmpImageDefault;
    }

    /**
     * Permite asignar la ruta de la carpeta contenedora de las imagenes
     *
     * @param tmpContentFolder
     */
    setContentFolder(tmpContentFolder: string) {
        this.contentFolder = tmpContentFolder;
    }

    /**
     * Permite asignar un tamaño y formato de la imagen
     *
     * @param tmpSizeFormat
     */
    setSizeFormat(tmpSizeFormat: string) {
        this.sizeFormat = tmpSizeFormat;
    }

    /**
     * Permite obtener la ruta de una imagen establecida en el ws
     *
     * @param image
     */
    getBuidPathAWS(image) {

        let extension = null;

        if (this.getIsJpg(image)) {
            extension = '.jpg';
        }

        if (this.getIsJpeg(image)) {
            extension = '.jpeg';
        }

        if (this.getIsPng(image)) {
            extension = '.png';
        }

        if (extension == null) {
            return this.imageDefault;
        }

        // Croped path es el path de las imagenes ya completo que devuelve el ws 
        if (image.cropedPath) {
            return image.cropedPath;
        }
        return this.urlS3 + this.contentFolder + image.fileName + this.sizeFormat + extension;
    }


    /**
     * Los siguinetes metodos verifican la extencion de la imagen (png, jpeg, jpg)
     *
     * @param image
     */
    getIsPng(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'png';
    }

    getIsJpeg(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'jpeg';
    }

    getIsJpg(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'jpg';
    }

    /**
     * Verifica si la imagen se encuentra en el ws y devuelve la ruta que la contiene,
     * si no está, devuelve una ruta de img por defecto
     *
     * @param image
     */
    getPath(image: any) {
        if (image !== null) {
            if (!jQuery.isEmptyObject(image)) {
                if (image.isUploadedOnAWS) {
                    return this.getBuidPathAWS(image);
                } else {
                    const urlApi = this.getUrlHostApi();
                    let path;
                    if (image.cropedPath) {
                        path = urlApi + image.cropedPath;
                    } else {
                        path = urlApi + image.path;
                    }
                    return path;
                }
            }
        }
        return this.imageDefault;
    }


    /**
     * Verifica si el parametro es un arreglo, si lo és, devuelve la posicion [0],
     * Si no verifica el id de la imagen y devuelve el objeto images
     *
     * @param images
     */
    getOneImage(images: any) {

        if (images instanceof Array) {

            if (images.length > 0) {
                return images[0];
            }

        } else {
            if (images.id !== null) {
                return images;
            }
        }

        return null;
    }


}
