import swal from 'sweetalert';

import { Injectable } from '@angular/core';

// import { SwalOptions } from "sweetalert/typings/modules/options";


@Injectable()
export class SweetAlertService {


    /**
     * Permite mostrar un alerta personalizado
     *
     * @param title
     * @param text
     * @param typeAlert
     * @param buttonText
     */
    alert(title: string, text: string = null, typeAlert: string = null, buttonText: string = null): Promise<void> {

        const opts = {
            title: title,
            text: text,
            icon: typeAlert,
            button: buttonText,
            className: 'omt-sweet-alert'
        };

        if (buttonText === null) {
            delete opts.button;
        }

        if (typeAlert === null) {
            delete opts.icon;
        }

        if (text === null) {
            delete opts.title;
            opts.text = title;
        }

        return swal(opts);
    }

    /**
     * Permite mostrar un dialogo de confirmacion personalizado
     * 
     * @param title
     * @param text
     * @param typeAlert
     * : Promise<boolean>
     */
    confirm(title: string, text: string = null, typeAlert: string = null, buttons = ['cancel', 'ok']): Promise<boolean> {

        return new Promise((resolve, reject) => {

            const opts = {
                title: title,
                text: text,
                icon: typeAlert,
                buttons: buttons,
                dangerMode: false,
                className: 'omt-sweet-confirm',
                closeOnClickOutside: false,
                closeOnEsc: false
            };

            if (typeAlert === null) {
                delete opts.icon;
            }

            if (text === null) {
                delete opts.title;
                opts.text = title;
            }

            swal(opts)
                .then(res => {
                    resolve(res === true);
                });
        });

    }
    /**
     * Permite mostrar un dialogo de confirmacion personalizado para crear la cuenta
     * 
     * @param title
     * @param text
     * @param typeAlert
     * : Promise<boolean>
     */
    confirmAccount(toAssign, create, title: string, text: string = null, typeAlert: string = null): Promise<boolean> {


        return new Promise((resolve, reject) => {

            const opts = {
                title: title,
                text: text,
                icon: 'success',
                buttons: [toAssign, create],
                className: 'omt-sweet-confirmAccount',
                closeOnClickOutside: false,
                closeOnEsc: false,
            };

            if (typeAlert === null) {
                delete opts.icon;
            }

            if (text === null) {
                delete opts.title;
                opts.text = title;
            }

            swal(opts)
                .then(res => {
                    resolve(res === true);
                });
        });

    }
    /**
     * Permite mostrar un dialogo de confirmacion con prompt
     * @param text: string 
     */
    public modalWithPrompt(text: string, placeholder: string, errorMessage: string = undefined) {
        return new Promise((resolve, reject) => {
            swal(text, {
                content: {
                    element: "input",
                    attributes: {
                        placeholder: placeholder,
                        type: 'text',
                        // value: ``
                    }
                }
            })
                .then((value) => {
                    if (value.length) {
                        // this.usuario.clave = value;
                        // this.cambiarClave();
                        resolve(value);
                    } else {
                        if (errorMessage) {
                            swal('Ups', errorMessage, 'error');
                        }
                    }

                });
        });

    }

}
