import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response, URLSearchParams } from '@angular/http';

import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';

@Injectable()
export class MailService {
    constructor(private http: Http,
        private utils: UtilsComponent
    ) { }

    //Variables para manejo de paginacion
    private page: number = 1;
    private itemsPerPage: number = 25;
    private sort: number = 0;
    private order_by: string = 'creation_date';

    //Variables para manejo de busqueda
    freeText: string = "";
    status: string = "";
    subject: string = "";


    private omtUrl = this.utils.getUrlOmtSd();
    private getMailUrl = this.utils.getUrlOmt() + 'sd';

    /**
     * Metodo encargado de solicitar al web service el listado completo de los 
     * correos que los clientes han enviado a OMT
     * @param item: number -> Numero de items que se mostraran por paginacion
     * @param cleanSearch: boolean -> Variable para validar cuando se debe poner la pagina en 1
     * @param userLang: string -> Variable para controlar el idioma en que se solicitaran los resultados
     */
    getMail(items: number, cleanSearch: boolean, userLang: string, sort: number, order_by: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        this.sort = sort;
        this.order_by = order_by;

        if (items != 10) {
            this.itemsPerPage = items;
        } else if (items == 10 && cleanSearch) {
            this.itemsPerPage = items;
        }

        if (cleanSearch) {
            this.page = 1;
        }

        let options = new RequestOptions({
            search: new URLSearchParams('page=' + this.page + '&items_per_page=' + items + '&free_text=' + this.freeText + '&status=' + this.status + '&subject=' + this.subject + '&sort=' + this.sort + '&order_by=' + this.order_by),
            headers: headers
        });

        let url = `${this.getMailUrl}/contactMessages`;
        return this.http.get(url, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de solicitar al web service la informacion detallada de un 
     * cliente registrado en la aplicacion movil de OMT
     * @param idClient: number -> Id del cliente a capturar los detalles
     * @param userLang: string -> Variable para controlar el idioma en que se solicitaran los resultados
     */
    getClientDetails(idClient: number, userLang: string) {
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            headers: headers
        });

        let url = `${this.getMailUrl}/client/${idClient}`;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractDataSettings)
            .catch(this.handleError);
    }

    private extractDataSettings(res: Response) {
        let body = res.json();
        return body.data || {};
    }

    /**
     * Metodo encargado de manejar las paginas del paginador
     */
    getPageMailsList(page: number, items: number, userLang: string) {
        this.page = page;
        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);
        let options = new RequestOptions({
            search: new URLSearchParams('page=' + this.page + '&items_per_page=' + items + '&free_text=' + this.freeText + '&status=' + this.status + '&subject=' + this.subject + '&sort=' + this.sort + '&order_by=' + this.order_by),
            headers: headers
        });

        let url = this.getMailUrl + '/contactMessages';

        return this.http.get(url, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    /**
     * Metodo encargado de realizar peticion de busqueda al web service de los restaurantes
     * recibe tres parametros para realizar la busqueda
     * @param: searchData : string -> Parametros para realizar la busqueda
     */
    getSearchMails(searchData: any, userLang: string) {

        this.page = 1;

        this.freeText = searchData.freeText;
        this.status = searchData.status;
        this.subject = searchData.subject

        let headers = new Headers();
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        let options = new RequestOptions({
            search: new URLSearchParams('free_text=' + this.freeText + '&status=' + this.status + '&subject=' + this.subject + '&items_per_page=' + this.itemsPerPage),
            headers: headers
        });

        let url = this.getMailUrl + '/contactMessages';

        return this.http.get(url, options)
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }


    /**
     * Metodo encargado de cambiar el estado a un cliente registrado en OMT
     * @param idMail: number -> ID del cliente que se cambiara el estado
     * @param status: number -> Estado que se seteara
     * @param userLang: string -> Lenguaje que maneja la aplicacion
     */
    putChangeStatusMail(idMail: number, status: number, userLang: string) {
        let headers = new Headers({
            'Accept': 'application/json'
        });
        headers.append('Api-Key', localStorage.getItem(Constants.LOCAL_STORAGE_DATA.AUTH_TOKEN));
        headers.append('Accept-Language', userLang);

        var arrayData = {
            status: status
        }

        let array = JSON.stringify(arrayData);

        let url = `${this.getMailUrl}/contactMessage/${idMail}/changeStatus`;
        return this.http
            .put(url, array, { headers: headers })
            .toPromise()
            .then(res => res.json())
            .catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    /**
     * Metodo encargado de retornar el valor de las variables de paginación
     * para funcionamiento del mismo en el listado de clientes 
     */
    valuesPagination() {
        return [this.page, this.itemsPerPage]
    }

    /**
     * Metodo encargado de limpiar las variables encargadas del manejo de filtro
     */
    cleanParametersSearch(fromSidebar: boolean) {
        this.freeText = "";
        this.status = "";
        this.subject = "";
        this.sort = 0;
        this.order_by = 'creation_date';

        if (fromSidebar) {
            this.itemsPerPage = 25;
        }
    }

}
