export class legalTerm {
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    id: number;
    langs: Array<Object>;
    rulesEn: string;
    rulesSp: string;
    typeRule: number;
    name: string;
    canShow: boolean;
    validity: string;
}