<h1 class="page-title">{{ 'Settings' | translate }}</h1>

<div class="row">
	<div class="col-md-12 col-xs-12">
		<section widget class="widget">
			<header>
				<h4>
				</h4>
			</header>
			<div class="widget-body">
				<div *ngIf="settings">
					<form class="form-horizontal" [formGroup]="settingsForm" novalidate>
						<div class="form-group">
							<div class="row">
								<div class="col-md-3 col-xs-12">
									<label>{{ 'TimeNew' | translate }} ({{ 'Days' | translate }})</label>
								</div>
								<div class="col-md-4 col-xs-12">
									<select [(ngModel)]="settings.daysToNewRestaurants" formControlName="timeNewRestaurant" name="TimeNew" class="form-control">
                                        <option value="">{{ 'Days' | translate }}</option>
                                        <option *ngFor="let item of days" value="{{item}}">{{item}}</option>                                       
                                    </select>
								</div>
							</div>
						</div>

						<div class="form-group">
							<div class="row">
								<div class="col-md-3 col-xs-12">
									<label>{{ 'Distance' | translate }}</label>
								</div>
								<div class="col-md-4 col-xs-12">
									<input formControlName="distance" type="range" min="{{min}}" max="{{max}}" step="{{step}}" [(ngModel)]="distance" id="distance">
									<label class="per4">{{distance}} {{ 'Miles' | translate }}</label>
								</div>
							</div>
						</div>

						<div class="form-group legend">
							<div class="row">
								<label class="col-md-10 col-xs-12"><strong> Administradores que recibiran los mensajes de contacto </strong></label>
								<div class="col-md-2">
									<button type="button" class="btn btn-success pull-right mb-xs" (click)="addArrayLiPerson(arrayLiSizes)"><i class="fa fa-plus"></i></button>
								</div>
							</div>
						</div>

						<div *ngIf="errorMail">
							<div class="alert alert-danger alert-dismissable nlblk">
								<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><strong>{{msgErrorSize}}</strong></div>
						</div>

						<div class="form-group">
							<div *ngIf="validateEmails">
							<div *ngIf="settings.emailsToContactMessages.emails.length > 0">
								<div #errorMail *ngIf="!settingsForm.controls['email'].valid && !settingsForm.controls['email'].pristine" id="{{i+1}}" class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>
								<ul id="adminWebServices">
									<li *ngFor="let item of settings.emailsToContactMessages.emails; let i = index" id="liAdminWebService{{i +1}}" #liAdminWebService>
										<div class="row">
											<div class="col-md-2 col-xs-12">
												<input id="personName{{i+1}}" #personName [ngModelOptions]="{standalone: true}" [(ngModel)]="item.name" type="text" class="form-control">
											</div>

											<div class="col-md-2 col-xs-12">
												<input [ngModelOptions]="{standalone: true}" id="personEmail{{i+1}}" #personEmail [(ngModel)]="item.email" type="text" class="form-control">
											</div>

											<div class="col-md-2 col-xs-12 cntrV">
												<label>{{ 'IWantPublishRestaurant' | translate }}</label>
												<input id="publish{{i+1}}" [checked]="item.publish==true" type="checkbox" class="form-control">
											</div>

											<div class="col-md-2 col-xs-12 cntrV">
												<label>{{ 'IGotSugerencyDoubt' | translate }}</label>
												<input id="sugerency{{i+1}}" [checked]="item.sugerency==true" type="checkbox" class="form-control">
											</div>

											<div class="col-md-2 col-xs-12 cntrV">
												<label>{{ 'IWantMakeClaim' | translate }}</label>
												<input id="claim{{i+1}}" [checked]="item.claim==true" type="checkbox" class="form-control">
											</div>

											<div class="col-md-2 col-xs-12">
												<button class="btn btn-danger mb" type="button" title="{{ 'Delete' | translate }}" (click)="removeItemPerson(personName.id, personEmail.id, liAdminWebService.id)"><i class="fa fa-minus"></i></button>
											</div>
										</div>

									</li>
								</ul>
							</div>
							</div>
						</div>

						<div class="form-group">
							<div #errorMail *ngIf="!settingsForm.controls['email2'].valid && !settingsForm.controls['email2'].pristine" id="{{i+1}}"
								class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>
							<ul id="newAdminMails">
								<li *ngFor="let item of arrayLiPerson; let i = index" id="liNewAdminMails{{i +1}}" #liNewAdminMails>
									<div class="row">
										<div class="col-md-2 col-xs-12">
											<input id="personNameNew{{i+1}}" #personNameNew type="text" class="form-control" placeholder="{{ 'Name' | translate }}">
										</div>

										<div class="col-md-2 col-xs-12">
											<input id="personEmailNew{{i+1}}" #personEmailNew type="text" class="form-control" placeholder="{{ 'Email' | translate }}">
										</div>

										<div class="col-md-2 col-xs-12 cntrV">
											<label>{{ 'IWantPublishRestaurant' | translate }}</label>
											<input id="publishNew{{i+1}}" type="checkbox" class="form-control">
										</div>

										<div class="col-md-2 col-xs-12 cntrV">
											<label>{{ 'IGotSugerencyDoubt' | translate }}</label>
											<input id="sugerencyNew{{i+1}}" type="checkbox" class="form-control">
										</div>

										<div class="col-md-2 col-xs-12 cntrV">
											<label>{{ 'IWantMakeClaim' | translate }}</label>
											<input id="claimNew{{i+1}}" type="checkbox" class="form-control">
										</div>

										<div class="col-md-2 col-xs-12">
											<button class="btn btn-danger mb" type="button" title="{{ 'Delete' | translate }}" (click)="removeItemPersonNew(item)"><i class="fa fa-minus"></i></button>
										</div>
									</div>

								</li>
							</ul>
						</div>

					</form>
				</div>
				<div *ngIf="canEditGeneralSetting" class="form-group">
					<div class="row">						
						<div class="col-md-12 col-xs-12">
							<button type="submit" [disabled]="!settingsForm.valid"  [hidden]="processingResponse"(click)="onSubmit(settingsForm.value, settingsForm.valid)" class="pull-right btn btn-success mb-xs">{{ 'Save' | translate }}</button>
							<button class="btn btn-gray pull-right mb-xs" [hidden]="!processingResponse" 
							[disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
								<div class="lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</button> 
						</div>
					</div>
				</div>
			</div>
		</section>

	</div>
	<!-- <simple-notifications [options]="options"></simple-notifications> -->
</div>
<!-- <inspectPermits></inspectPermits> -->