<h1 class="page-title">{{ 'Users' | translate }} </h1>

<div class="row">
	<div class="col-md-12 col-xs-12">
		<section widget class="widget">
			<header>
				<h4>
				</h4>
			</header>
			<div class="widget-body">
				<div class="form-group row">
					<div class="col-sm-3 col-xs-12">
						<select name="roleSelect" #roleSelect class="form-control select2">
                            <option id="0" value=""> {{ 'Role' | translate }} </option>
                            <option id="1" value="1"> {{ 'Administrator' | translate }} </option>
                            <option id="2" value="2"> {{ 'ContentGenerator' | translate }} </option>
                        </select>
					</div>
					<div class="col-sm-2 col-xs-12">
						<select name="statusSelect" #statusSelect class="form-control select2">
                            <option id="0" value=""> {{ 'Status' | translate }} </option>
                            <option id="1" value="1"> {{ 'Active' | translate }} </option>
                            <option id="2" value="2"> {{ 'Inactive' | translate }} </option>
                            <option id="3" value="3"> {{ 'Locked' | translate }} </option>
                        </select>
					</div>
					<div class="col-md-3 col-xs-12">
						<div class="input-group">
							<span class="info input-group-addon"><i class="fa fa-search"></i></span>
							<input type="text" class="form-control form-control-sm pull-xs-right" id="search" placeholder="{{ 'Search' | translate }}"
							 [(ngModel)]="searchText">
						</div>
					</div>
					<div class="col-md-2 col-xs-6">
						<p class="text-xs-left">
							<a class="btn btn-info" (click)="searchRoleUser(roleSelect.value, statusSelect.value)">
                                {{ 'Search' | translate }}
                            </a>
						</p>
					</div>
					<div class="col-md-2 col-xs-6">
						<a *ngIf="canCreateUser" class="btn btn-success pull-right" (click)="addUserModal(addEditUserModal)">
							<i class="fa fa-plus"></i> {{ 'Add' | translate }}
						</a>
					</div>
				</div>

				<div class="form-group">
					<div *ngIf="users">
						<div *ngIf="users.length <= 0">
							<div class="alert alert-info alert-dismissable">
								<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
								<strong>{{'NoDataToShow' | translate }}</strong>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table id="tableUser" class="table table-striped table-hover table-sortable">
							<thead>
								<tr>
									<th id="thName" [class]="selectedClass('name')" (click)="changeSorting('name')">{{ 'Name' | translate }}</th>
									<th id="thTypeDescription" [class]="selectedClass('typeDescription')" (click)="changeSorting('typeDescription')">{{ 'Role' | translate }}</th>
									<th id="thUsername" [class]="selectedClass('username')" (click)="changeSorting('username')">{{ 'Username' | translate }}</th>
									<th id="thStatus" [class]="selectedClass('status')" (click)="changeSorting('status')">{{ 'Status' | translate }}</th>
									<th class="txtcntr">{{ 'Actions' | translate }}</th>
								</tr>
							</thead>
							<tbody *ngIf="users">
								<!-- <tr *ngFor="let item of users | orderBy : convertSorting() | SearchUserPipe : searchText"> -->
								<tr *ngFor="let item of users">

									<td>{{item.name }} {{item.lastname }}</td>
									<td>{{item.typeDescription}}</td>
									<td>{{item.username}}</td>
									<td>{{item.statusDescription}}</td>
									<td>
										<p class="text-xs-center">
											<button *ngIf="canViewUser" class="btn btn-secondary  mb-xs" role="button" title="{{ 'ShowDetails' | translate }}" (click)="showDetailsModal(item, showUserModal)">			
                                                <i class="fa fa-eye text-info"></i>                                            
                                            </button>
											<button *ngIf="canEditUser" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Edit' | translate }}" (click)="editUserModal(item, addEditUserModal)">
                                                <i class="fa fa-pencil text-warning"></i>                                            
                                            </button>
											<button *ngIf="canDeleteUser" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, canDeleteModal)">
                                                <i class="fa fa-eraser text-danger"></i>                                            
                                            </button>
											<button *ngIf="canEditPermissions" class="btn btn-secondary  mb-xs" role="button" title="{{ 'Permissions' | translate }}"
											 (click)="setPermissions(item, permissionsUserModal)">
                                                <i class="fa fa-lock text-gray-darker"></i>                                            
                                            </button>
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!-- <simple-notifications [options]="options"></simple-notifications> -->
			</div>

			<!-- Modal para agregar/editar imagenes al producto -->

			<ng-template #addEditUserModal>
				<div class="modal-header">
					<h4 class="modal-title text-xs-center fw-bold mt">{{ 'User' | translate }} <strong *ngIf="selectedUser"></strong></h4>
					<p *ngIf="!canEdit" class="text-xs-center fs-mini text-muted mt-sm">
						{{ 'AddUser' | translate }}
					</p>
					<p *ngIf="canEdit" class="text-xs-center fs-mini text-muted mt-sm">
						{{ 'EditUser' | translate }}
					</p>
					<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
						<span aria-hidden="true">&times;</span>
					  </button>
				</div>
				<div class="modal-body">
					<!-- <div *ngIf="errorUser">
						<div class="alert alert-danger alert-dismissable nlblk">
							<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>{{errorMessage}}</div>
					</div> -->

					<form class="form-horizontal" [formGroup]="userForm" novalidate>
						<div *ngIf="selectedUser" class="col-md-12">

							<input type="hidden" [(ngModel)]="selectedUser.id" formControlName="id">

							<div class="form-group row">
								<label class="col-md-4">{{ 'Role' | translate }} *</label>
								<div class="col-md-8">
									<select formControlName="type" name="typeProduct" id="typeProduct" class="form-control" [(ngModel)]="selectedUser.type">
														<option>{{ 'Select' | translate }}</option>
														<option id="administrator" value="1">{{ 'Administrator' | translate }}</option>
														<option id="contentGenerator" value="2">{{ 'ContentGenerator' | translate }}</option>
													</select>
								</div>
							</div>

							<div *ngIf="canEdit && userLang == 'es'" class="form-group row">
								<label class="col-sm-4 control-label">{{ 'Status' | translate }}</label>
								<div class="col-sm-8">
									<select id="selectStatus" formControlName="status" name="status" class="form-control" [(ngModel)]="selectedUser.status">
														<option>{{ 'Select' | translate }}</option>
														<option *ngFor="let item of statesEs; let i = index" [value]="i+1">                                                
															{{item}}                                                
														</option>
													</select>
								</div>
							</div>

							<div *ngIf="canEdit && userLang != 'es'" class="form-group row">
								<label class="col-sm-4 control-label">{{ 'Status' | translate }}</label>
								<div class="col-sm-8">
									<select id="selectStatus2" formControlName="status" name="status" class="form-control" [(ngModel)]="selectedUser.status">
														<option>{{ 'Select' | translate }}</option>
														<option *ngFor="let item of statesEn; let i = index" [value]="i+1">                                                
															{{item}}                                                
														</option>
													</select>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-4">{{ 'Name' | translate }} *</label>
								<div class="col-md-8">
									<input [(ngModel)]="selectedUser.name" type="text" formControlName="name" class="form-control" id="nameUser" placeholder="{{ 'Name' | translate }}">
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-4">{{ 'LastName' | translate }} *</label>
								<div class="col-md-8">
									<input [(ngModel)]="selectedUser.lastname" type="text" formControlName="lastname" class="form-control" id="lastnameUser"
									 placeholder="{{ 'LastName' | translate }}">
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-4">{{ 'Email' | translate }} *</label>
								<div class="col-md-8">
									<input #emailUser (keypress)="withoutSpaces($event)" [(ngModel)]="selectedUser.email" type="text" formControlName="email"
									 class="form-control" id="emailUser" placeholder="example@example.com">
									<div *ngIf="!userForm.controls['email'].valid && !userForm.controls['email'].pristine" class="alert alert-sm alert-danger">{{ 'InvalidEmail' | translate }}</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-4">{{ 'Phone' | translate }}</label>
								<div class="col-md-8">
									<input #phone data-mask="(999) 999-9999" type="text" formControlName="phone" class="form-control" id="phone" placeholder="(999) 999-9999">
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-4">{{ 'Username' | translate }} *</label>
								<div class="col-md-8">
									<input (focus)="autoFocus($event)" (keypress)="withoutSpaces($event)" [(ngModel)]="selectedUser.username" type="text" formControlName="username"
									 class="form-control" id="usernameUser" placeholder="{{ 'Username' | translate }}">
								</div>
							</div>

							<div *ngIf="canEdit" class="form-group legend">
								<label class="control-label"><strong>{{ 'EditPassword' | translate }}</strong></label>
								<button type="button" class="btn btn-secondary pull-xs-right mb-xs" (click)="editPasswords(item)"><i class="fa fa-pencil text-warning"></i>
											</button>
							</div>

							<div *ngIf="!editPassword" class="form-group row">
								<label class="col-md-4">{{ 'Password' | translate }} *</label> 
								<div class="col-md-8">
									<input (keypress)="withoutSpaces($event)" [(ngModel)]="selectedUser.password" type="password" formControlName="password"
									 class="form-control" id="password" placeholder="{{ 'Password' | translate }}" name="password">
									<div *ngIf="!userForm.controls['password'].valid && !userForm.controls['password'].pristine" class="alert alert-sm alert-danger">{{ 'PasswordMinCharacters' | translate }}</div>
									
								</div>
							</div>

							<div *ngIf="!editPassword" class="form-group row">
								<label class="col-md-4">{{ 'ConfirmPassword' | translate }} *</label>
								<div class="col-md-8">
									<input validateEqual="password" (keypress)="withoutSpaces($event)" (keyup)="validatepass(userForm.value)" [(ngModel)]="selectedUser.password_confirm" type="password"
									 formControlName="password_confirm" class="form-control" id="password_confirm" placeholder="{{ 'ConfirmPassword' | translate }}"
									 name="password_confirm">
									<div *ngIf="validatePassR" class="alert alert-sm alert-danger">{{ 'PasswordDontMatch' | translate }}</div>
								</div>
							</div>

						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="submit" [hidden]="processingResponse" [disabled]="!userForm.valid" (click)="onSubmit(userForm.value, userForm.valid, validatePassR)" class="btn btn-success mb-xs">{{ 'Save' | translate }}</button>
					<button class="btn btn-gray flx" [hidden]="!processingResponse"
                      [disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
                      <div class="lds-ellipsis ">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </button> 
				</div>
			</ng-template>

			<!-- Modal para mostrar los detalles del usuario -->
			<ng-template #showUserModal>
				<div class="modal-header">
					<h4 class="modal-title text-xs-center fw-bold mt">{{ 'DetailsUser' | translate }}</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
				 <span aria-hidden="true">&times;</span>
			   </button>
				</div>
				<div class="modal-body">
					<div *ngIf="selectedUser">
						<div class="row">
							<div class="col-lg-12">
								<div class="table-responsive">
									<table class="table table-striped table-hover tablesorter">
										<thead>
											<tr>
												<th>{{ 'Name' | translate }}</th>
												<th class="txtcntr">{{ 'Username' | translate }}</th>
												<th class="txtcntr">{{ 'Status' | translate }}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{{ selectedUser.name }} {{ selectedUser.lastname }}
												</td>
												<td class="txtcntr">{{ selectedUser.username }}</td>
												<td class="txtcntr">{{ selectedUser.statusDescription }}</td>
											</tr>
										</tbody>
										<thead>
											<tr>
												<th colspan="2">{{ 'Email' | translate }}</th>
												<th class="txtcntr">{{ 'Role' | translate }}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td colspan="2">{{ selectedUser.email }}</td>
												<td class="txtcntr">{{ selectedUser.typeDescription }}</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>
						</div>
					</div>
				</div>
			</ng-template>

			<!-- Modal para confirmar acción eliminar -->
			<ng-template #canDeleteModal>
				<div class="modal-header">
					<h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18"><i class="fa fa-exclamation-triangle"></i> {{ 'Caution' | translate }}
					</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
						<span aria-hidden="true">&times;</span>
					  </button>
				</div>
				<div class="modal-body">
					<p class="text-xs-center">
						{{ 'WantDelete' | translate }}
						<strong>{{ 'User' | translate }}</strong>?
					</p>
				</div>
				<div class="modal-footer">
					<div *ngIf="selectedUser">
						<button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true">{{ 'Cancel' | translate }}</button>
						<button type="button" [hidden]="processingResponse" class="btn btn-success" (click)="deleteUser(selectedUser, $event)">{{ 'Confirm' | translate }}</button>
						<button class="btn btn-gray flx" [hidden]="!processingResponse"
							[disabled]="processingResponse">{{ 'processing' | translate }}&nbsp;
							<div class="lds-ellipsis">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</button> 
					</div>
				</div>
			</ng-template>
			<!-- Modal de accesos -->
			<ng-template #permissionsUserModal>
				<div class="modal-header">
					<h4 *ngIf="selectedUser" class="modal-title text-xs-center fw-bold mt">{{ 'Permissions' | translate }} {{ 'User' | translate }} <small>({{ selectedUser.name }} {{ selectedUser.lastname }})</small>						</h4>
					<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
						<span aria-hidden="true">&times;</span>
					  </button>
				</div>
				<div class="modal-body">
					<div *ngIf="idUser">
						<!--<div class="row">
										<div class="col-lg-12">
											<div class="table-responsive">
												<table class="table table-striped table-hover tablesorter">
													<thead>
														<tr>
															<th><strong>{{ 'SelectedAll' | translate }}</strong></th>
															<th class="txtcntr">
																<input type="checkbox" id="selectedAll" class="form-control pull-right" (change)="selectAllChckbx($event)">
															</th>
														</tr>
													</thead>
												</table>
											</div>
										</div>
									</div>-->
						<form class="form-horizontal" [formGroup]="userPermissionsForm" novalidate>
							<!-- Listado Permisos de Restaurante -->
							<div class="row">
								<div class="col-md-4 col-xs-12">
									<div class="table-responsive">
										<table class="table table-striped table-hover tablesorter">
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleRestaurant.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleRestaurant.permissions==true" type="checkbox" id="restaurantSelectedAll" class="form-control pull-right"
														 (change)="editPermissionsUser($event, moduleRestaurant.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleRestaurant.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_restaurant{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
											<!--<thead>
															<tr>
																<th class="capitalize"><strong>{{ 'RestaurantSection' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
																<th class="txtcntr">
																	<input type="checkbox" id="restaurantSelectedAll" class="form-control pull-right" (change)="restaurantSelectAllChckbx($event)">
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{{ 'CreateRestaurant' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="createRestaurant" type="checkbox" id="createRestaurant" class="form-control pull-right" (change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'ViewRestaurantLevel' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewRestaurantLevel" type="checkbox" id="viewRestaurantLevel" class="form-control pull-right" (change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'ViewRestaurantDirectory' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewRestaurantDirectory" type="checkbox" id="viewRestaurantDirectory" class="form-control pull-right"
																		(change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditRestauratLevel' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editRestaurantLevel" type="checkbox" id="editRestaurantLevel" class="form-control pull-right" (change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditRestauratDirectory' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editRestaurantDirectory" type="checkbox" id="editRestaurantDirectory" class="form-control pull-right"
																		(change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'DeleteRestaurantDirectory' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="deleteRestaurantDirectory" type="checkbox" id="deleteRestaurantDirectory" class="form-control pull-right"
																		(change)="changeValueselectedAll(restaurantSection)">
																</td>
															</tr>
														</tbody>-->
										</table>
										<table class="table table-striped table-hover tablesorter">
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleMenuRestaurant.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleMenuRestaurant.permissions==true" type="checkbox" id="menuSelectedAll" class="form-control pull-right"
														 (change)="editPermissionsUser($event, moduleMenuRestaurant.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleMenuRestaurant.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_restaurant_menu{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
											<!--<thead>
															<tr>
																<th class="capitalize"><strong>{{ 'RestaurantMenuSection' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
																<th class="txtcntr">
																	<input type="checkbox" id="menuSelectedAll" class="form-control pull-right" (change)="menuSelectAllChckbx($event)">
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{{ 'ViewMenu' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewMenu" type="checkbox" id="viewMenu" class="form-control pull-right" (change)="changeValueselectedAll(menuSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditMenu' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editMenu" type="checkbox" id="editMenu" class="form-control pull-right" (change)="changeValueselectedAll(menuSection)">
																</td>
															</tr>
														</tbody>-->
										</table>
										<table class="table table-striped table-hover tablesorter">
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleMails.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleMails.permissions==true" type="checkbox" id="userSelectedAll" class="form-control pull-right" (change)="editPermissionsUser($event, moduleMails.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleMails.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_contact_messages{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
											<!--<thead>
															<tr>
																<th class="capitalize"><strong>{{ 'MailSection' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
																<th class="txtcntr">
																	<input type="checkbox" id="mailSelectedAll" class="form-control pull-right" (change)="mailSelectAllChckbx($event)">
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{{ 'ViewMail' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewMail" type="checkbox" id="viewMail" class="form-control pull-right" (change)="changeValueselectedAll(mailSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditNoteMail' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editNoteMail" type="checkbox" id="editNoteMail" class="form-control pull-right" (change)="changeValueselectedAll(mailSection)">
																</td>
															</tr>
														</tbody>-->
										</table>

									</div>
								</div>
								<div class="col-md-4 col-xs-12">
									<!--<legend><span class="label label-info text-gray-dark mr-xs"></span></legend>-->
									<div class="table-responsive">
										<table class="table table-striped table-hover tablesorter">
											<!--<thead>
															<tr>
																<th class="capitalize"><strong>{{ 'SectionLegalTermPrivacyPolicies' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
																<th class="txtcntr">
																	<input type="checkbox" id="legalTermsSelectedAll" class="form-control pull-right" (change)="legalTermsSelectAllChckbx($event)">
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{{ 'ViewLegalTermPrivacyPolicies' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewLegalTerms" type="checkbox" id="viewLegalTerms" class="form-control pull-right" (change)="changeValueselectedAll(legalTermSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditLegalTermPrivacyPolicies' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editLegalTerms" type="checkbox" id="editLegalTerms" class="form-control pull-right" (change)="changeValueselectedAll(legalTermSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'DeleteLegalTermPrivacyPolicies' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="deleteLegalTerms" type="checkbox" id="deleteLegalTerms" class="form-control pull-right" (change)="changeValueselectedAll(legalTermSection)">
																</td>
															</tr>
														</tbody>-->
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleLegalTerms.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleLegalTerms.permissions==true" type="checkbox" id="userSelectedAll" class="form-control pull-right"
														 (change)="editPermissionsUser($event, moduleLegalTerms.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleLegalTerms.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_terms_of_service_privacy_policies{{i+1}}"
														 class="form-control pull-right" (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
										</table>

										<table class="table table-striped table-hover tablesorter">
											<!--<thead>
															<tr>
																<th class="capitalize"><strong>{{ 'UserSection' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
																<th class="txtcntr">
																	<input type="checkbox" id="userSelectedAll" class="form-control pull-right" (change)="userSelectAllChckbx($event)">
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>{{ 'ViewUser' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="viewUser" type="checkbox" id="viewUser" class="form-control pull-right" (change)="changeValueselectedAll(userSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'CreateUser' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="createUser" type="checkbox" id="createUser" class="form-control pull-right" (change)="changeValueselectedAll(userSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'EditUsers' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="editUser" type="checkbox" id="editUser" class="form-control pull-right" (change)="changeValueselectedAll(userSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'DeleteUser' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="deleteUser" type="checkbox" id="deleteUser" class="form-control pull-right" (change)="changeValueselectedAll(userSection)">
																</td>
															</tr>
															<tr>
																<td>{{ 'AssignPermissionsUser' | translate }}</td>
																<td class="txtcntr">
																	<input formControlName="assignPermissions" type="checkbox" id="assignPermissions" class="form-control pull-right" (change)="changeValueselectedAll(userSection)">
																</td>
															</tr>
														</tbody>-->
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleUsers.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleUsers.permissions==true" type="checkbox" id="userSelectedAll" class="form-control pull-right" (change)="editPermissionsUser($event, moduleUsers.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleUsers.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_users{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div class="col-md-4 col-xs-12">
									<div class="table-responsive">
										<table class="table table-striped table-hover tablesorter">
											<!--<thead>
														<tr>
															<th class="capitalize"><strong>{{ 'Others' | translate }}</strong><small> ({{ 'SelectedAll' | translate }})</small></th>
															<th class="txtcntr">
																<input type="checkbox" id="otherSelectedAll" class="form-control pull-right" (change)="otherSelectAllChckbx($event)">
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{{ 'AccessStatisticsModule' | translate }}</td>
															<td class="txtcntr">
																<input formControlName="accessModuleStatics" type="checkbox" id="accessModuleStatics" class="form-control pull-right" (change)="changeValueselectedAll(otherSection)">
															</td>
														</tr>
														<tr>
															<td>{{ 'AccessGeneralSettings' | translate }}</td>
															<td class="txtcntr">
																<input formControlName="generalSettings" type="checkbox" id="generalSettings" class="form-control pull-right" (change)="changeValueselectedAll(otherSection)">
															</td>
														</tr>
													</tbody>-->

											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleGeneralSettings.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleGeneralSettings.permissions==true" type="checkbox" id="userSelectedAll" class="form-control pull-right"
														 (change)="editPermissionsUser($event, moduleGeneralSettings.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleGeneralSettings.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_general_settings{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
										</table>

										<table class="table table-striped table-hover tablesorter">
											<thead>
												<tr>
													<th class="capitalize"><strong>{{moduleClient.name}}</strong></th>
													<th class="txtcntr">
														<input [checked]="moduleClient.permissions==true" type="checkbox" id="userSelectedAll" class="form-control pull-right" (change)="editPermissionsUser($event, moduleClient.id, idUser)">
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of moduleClient.children; let i = index">
													<td>{{ item.name }}</td>
													<td class="txtcntr">
														<input [checked]="item.permissions==true" value="{{item.id}}" type="checkbox" id="module_clients{{i+1}}" class="form-control pull-right"
														 (change)="editPermissionsUser($event, item.id, idUser)">
													</td>
												</tr>
											</tbody>
										</table>

									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</ng-template>

		</section>
	</div>
</div>
<!-- <inspectPermits></inspectPermits> -->