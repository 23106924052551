import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsComponent } from '../utils/utils';
import { Constants } from 'app/app.constants';
import { AuthService } from 'app/auth/auth.service';
import { Headers, RequestOptions, Response, URLSearchParams, Http, ResponseContentType } from '@angular/http';
@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    constructor(
        private http: HttpClient,
        private utils: UtilsComponent,
        private auth: AuthService,
        private https: Http
    ) { }

    /**
     * WS to get orders from specific restaurant
     * @param paramsQuery params idRestaurant, like page, status, items_per_page,
     * nickname, startDate y endDate
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.0.0
     */
    public getOrdersFromRestaurant(paramsQuery: string, itemsPerPage: number): Observable<any> {
        const url = `${this.utils.getUrlOmt()}sd/orderList?items_per_page=${itemsPerPage}${paramsQuery}`;
        return this.http.get(url);
    }

    /**
     * Obtain order detail
     * @param orderId order id
     * @author Alvaro Felipe Garcia Mendez - Sept. 5 - 2019
     * @version 1.0.0
     */
    public getOrderDetail(orderId: string, restaurantId): Observable<any> {
        const url = this.utils.getUrlOmt() + 'sd/order?order_id=' + orderId + '&id_restaurant='+restaurantId+'&is_last_order=false';
        return this.http.get(url);
    }
    /**
     * Obtain the register of the order which is not sync in android
     * @param orderCode order code
     */
    public getOrderRegisterNotSync(orderCode) {
        const url = this.utils.getUrlOmt() + 'sd/sync/ordersSyncHistory?status=' + Constants.CONSTANTS_STATE.PENDING + '&orderCode=' + orderCode;
        return this.http.get(url);
    }
    /**
     * Send the push newly for the order which is still pending
     * @param messageId 
     * @param nickname nickname of the license of the restaurant
     * @param orderCode ordercode
     */
    public resendOrderPending(messageId, nickname, orderCode, notifyAndroid) {
        const params = {
            'messageId': undefined,
            'nickname': nickname,
            'orderCode': orderCode,
            'notify_pending_orders': notifyAndroid
        };
        if (messageId && !notifyAndroid) {
            params.messageId = messageId;
        }

        const url = this.utils.getUrlOmt() + 'sd/sync/resendUnansweredPush';
        return this.http.post(url, params, { headers: new HttpHeaders({ timeout: `${10000}` })});
    }
   
    /**
     * Funcion que obtiene los restaurantes a los que ya se han realizado ordenes
     * @param status los estados de la orden que queremos obtener
     * @param country el pais donde se encuentran los restaurantes
     */
    public getRestaurant(paramsQuery){
        const url = this.utils.getUrlOmt() + 'sd/restaurantWithOrders?items_per_page=all&Unpaginated='+ true +paramsQuery;
        return this.http.get(url);
    }

    public cancelOrder(order: any) {
        const data = {
            order_id: order.id,
            restaurant_id: order.restaurantId,
            client_id: order.clientId,
            cancellation_message: order.cancellation_message
        };
        let array = JSON.stringify(data);
        const url = this.utils.getUrlOmt() + 'sd/cancelAnOrder';
        return this.http.post(url, array);
    }

     /**
     * Obtiene el reporte de ordenes
     */
    public getExcelReport(paramsQuery, itemsPerPage: number) {
        const url = `${this.utils.getUrlOmt()}sd/download/excel/orders?items_per_page=${itemsPerPage}${paramsQuery}`;

        const headers = {
            'Api-Key': this.auth.getToken()
        }
        const options = new RequestOptions({
            responseType: ResponseContentType.Blob,
            headers: new Headers(headers)
        });

        return this.https.get(url, options);
        
    }

    /**
     * Cambiar el estado de una orden [Recibido, Preparand0,...]
     * @author William Alarcon - Octu. 20 - 2020
     * @version 1.0.0
     */
    public setOrderStatus(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .put(
                    this.utils.getUrlOmt() + 'sd/changeOrderStatus',
                    JSON.stringify(data)
                )
                .subscribe(
                    x => {
                        resolve(x);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
}
