import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SweetAlertService } from '../utils/sweetAlert.service';
import { AlertsService } from './alerts.service';
import { ListAccountService } from './listAccount.service';
import { RestaurantService } from './restaurant.service';

@Injectable()

export class LevelSyncroLevel {

    error: any;
    public modalRef: BsModalRef;

    //Variable que recibe respuesta del listado completo de restaurantes
    fullResponse: any;

    constructor(
        private restaurantService: RestaurantService,
        private listAccountService: ListAccountService,
        public alertService: AlertsService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private sweet: SweetAlertService
    ) { }


    // funcion global para la peticion de activacion o sincronizacion de cuentas de usuarios con level
    levelActive(item: any, ActivateRestaurantLevel) {
        this.listAccountService
            .getAllAccount()
            .subscribe(fullResponse => {
                this.fullResponse = fullResponse;

                if (this.fullResponse.data.length > 0) {
                    var f = "";
                    var toAssign = "";
                    var create = "";
                    this.translate.get(["accountConfimr"]).subscribe(translations => {
                        f = translations.accountConfimr;
                    });
                    this.translate.get(["toAssign"]).subscribe(translations => {
                        toAssign = translations.toAssign;
                    });
                    this.translate.get(["create"]).subscribe(translations => {
                        create = translations.create;
                    });

                    this.sweet.confirmAccount(toAssign, create, f)
                        .then(response => {
                            if (response) {
                                if (item.levelDatabaseNickname != '' && item.levelDatabaseNickname != null) {
                                    this.modalRef = this.modalService.show(ActivateRestaurantLevel, { class: 'modal-lg' });
                                    this.modalRef.content.typeModal = 'createAccount';
                                    this.modalRef.content.title = 'Modal with component';
                                    this.modalRef.content.accountName = '';
                                    this.modalRef.content.contactPerson = item.contactPerson;
                                    this.modalRef.content.mainPhone = item.phones[0].number.replace(" ", "").replace(" ", "");
                                    this.modalRef.content.mainEmail = '';
                                    this.modalRef.content.street = item.address.street;
                                    this.modalRef.content.suitNumber = item.address.suitNumber;
                                    this.modalRef.content.zipCode = item.address.zipCode;
                                    this.modalRef.content.cityName = item.address.cityName;
                                    this.modalRef.content.stateName = item.address.stateName;
                                    this.modalRef.content.countryName = item.address.countryName;
                                    this.modalRef.content.nickname = item.levelDatabaseNickname;
                                    this.modalRef.content.idRestaurant = item.id;
                                    this.modalRef.content.res = item;

                                } else {
                                    this.alertService.WarningAdd("Restaurant", "errorNickname");
                                }
                            } else {
                                this.modalRef = this.modalService.show(ActivateRestaurantLevel, { class: 'modal-lg' });
                                this.modalRef.content.typeModal = 'listAccount';
                                this.modalRef.content.list = this.fullResponse;
                                this.modalRef.content.res = item;
                            }
                        })
                }
                // this.getListRestaurants(this.fullResponse);
            }, error => {
                this.error = error;
                console.info("ERROR: ", error);
            });
    }


    // funcion para la syncronizacion con level 
    levelSync(levelDatabaseNickname) {
        this.restaurantService
            .createLevelTrue(levelDatabaseNickname, false)
            .subscribe(res => {
                //this.syncRestaurantSuccess();
                this.alertService.alertGestionSuccess("syncOk");
            },
                error => {
                    error => this.error = error
                    this.alertService.alertGestioErrorSyn("syncError");
                });
    }
}