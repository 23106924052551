<div *ngIf="restaurants">
  <h1 class="page-title"> {{restaurants.name}} <small>{{ 'MenuProducts' | translate }}</small>
  </h1>
</div>

<div class="row">
  <div class="col-lg-12 col-xs-12">
    <section widget class="widget">
      <header>
        <h4>
          {{ 'Category' | translate }} <strong *ngIf="categorySelected">"{{categorySelected.name}}"</strong> -
          {{ 'ListProducts' | translate }}
        </h4>
      </header>
      <div class="widget-body">

        <div class="form-group row">
          <div class="col-md-4 col-xs-12">
            <div class="input-group">
              <span class="info input-group-addon"><i class="fa fa-search"></i></span>
              <input type="text" class="form-control form-control-sm pull-xs-right" id="search" placeholder="{{ 'Search' | translate }}"
                [(ngModel)]="searchText">
            </div>
          </div>
          <div class="col-md-8 col-xs-12">
            <p *ngIf="restaurants" class="text-xs-right">
              <a class="btn btn-gray md-xs" (click)="goBack()">
                <i class="fa fa-arrow-left"></i> {{ 'Back' | translate }}
              </a>
              <a *ngIf="categorySelected" class="btn btn-success" (click)="openAddEditProductModal(null, addEditProductModal)">
                <i class="fa fa-plus"></i> {{ 'Add' | translate }}
              </a>
            </p>
          </div>
        </div>


        <div *ngIf="restaurants" class="form-group">
          <div *ngIf="menuItem">
            <div *ngIf="menuItem.length <= 0">
              <!-- <div class="alert alert-info alert-dismissable">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                <strong>{{'NoDataToShow' | translate }}</strong>
                            </div> -->
              <alert type="danger">{{'NoDataToShow' | translate }}</alert>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="txtcntr">{{ 'Name' | translate }}</th>
                  <th class="txtcntr">{{ 'Price' | translate }}</th>
                  <th class="txtcntr">{{ 'Orders' | translate }}</th>
                  <th class="txtcntr">{{ 'Actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of menuItem | SearchPipe : searchText">
                  <td>
                    {{item.name}}
                  </td>
                  <td align="right">
                    <div *ngFor="let size of item.sizes">
                      <div *ngIf="size.isRegularPrice == true">
                        {{size.price | currency:'USD':true:'1.2-2' }}
                      </div>
                    </div>
                  </td>
                  <td class="txtcntr">0</td>
                  <td align="center">
                    <button class="btn btn-secondary mb-xs" type="button" title="{{ 'ShowDetails' | translate }}"
                      (click)="showProduct(item, showProductModal)">
                      <i class="fa fa-eye text-info"></i></button>
                    <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Edit' | translate }}" (click)="openEditProductModal(item)">
                      <i class="fa fa-pencil-square-o text-warning"></i></button>
                    <button class="btn btn-secondary mb-xs" type="button" title="{{ 'Delete' | translate }}" (click)="confirmDelete(item, 'product')">
                      <i class="fa fa-eraser text-danger"></i></button>
                    <!-- <button *ngIf="item.images.length" class="btn btn-secondary mb-xs" type="button" title="{{ 'Image' | translate }}" (click)="showImagesProduct(item, showImagesProductModal)">
                                                <i class="fa fa-picture-o text-link"></i></button> -->
                    <!-- <button *ngIf="!item.images.length" class="btn btn-secondary mb-xs" type="button" title="{{ 'AddImage' | translate }}" (click)="addImagesProduct(item)">
                                                <i class="fa fa-plus text-success"></i></button> -->
                    <!-- <button *ngIf="restaurants.isFromLevel && item.videoPath" class="btn btn-secondary mb-xs" type="button" title="{{ 'ShowVideo' | translate }}"
                                            (click)="showVideoModal(item)">
                                                <i class="fa fa-video-camera"></i></button> -->
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal para mostrar los detalles de un producto -->
      <div bsModal #showProductModal="bs-modal" [config]="{ignoreBackdropClick: true, keyboard: false}" class="modal fade"
        tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-xs-center fw-bold mt">{{ 'DetailsProduct' | translate }}</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideShowProductModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="selectedProduct">
                <div class="row">
                  <div *ngIf="newImage" class="col-sm-12">
                    <span class="thumb-xlg thumbnail thmb-lg">
                      <img [src]="getImagePath(newImage)" alt="{{newImage.title}}" />
                    </span>
                  </div>
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-striped table-hover tablesorter">
                        <thead>
                          <tr>
                            <th class="txtcntr">{{ 'Name' | translate }}</th>
                            <th class="txtcntr">{{ 'Ingredients' | translate }}</th>
                            <th class="txtcntr">{{ 'Category' | translate }}</th>
                            <th class="txtcntr">{{ 'Status' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ selectedProduct.name }}</td>
                            <td>{{ selectedProduct.ingredients }}</td>
                            <td class="txtcntr" *ngIf="categorySelected">{{ categorySelected.name }}</td>
                            <td class="txtcntr">{{ selectedProduct.statusDescription }}</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th colspan="4" class="txtcntr">{{ 'Sizes' | translate }}</th>
                          </tr>
                          <tr>
                            <th class="txtcntr">{{ 'Name' | translate }}</th>
                            <th class="txtcntr">{{ 'Price' | translate }}</th>
                            <th class="txtcntr" colspan="2">{{ 'ByDefault' | translate }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let size of selectedProduct.sizes">
                            <td>{{size.name}}</td>
                            <td align="right">{{size.price | currency:'USD':true:'1.2-2' }}</td>
                            <td class="txtcntr" colspan="2" *ngIf="size.isRegularPrice">{{ 'Yes' | translate }}</td>
                            <td class="txtcntr" colspan="2" *ngIf="!size.isRegularPrice">{{ 'No' | translate }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para agregar/editar un producto -->
      <div bsModal #addEditProductModal="bs-modal" [config]="{ignoreBackdropClick: true, keyboard: false}" class="modal fade"
        tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-xs-center fw-bold mt">{{ 'ProductMenu' | translate }}</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeAddEditModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form *ngIf="!isResetting" class="form-horizontal" [formGroup]="productForm">
                <div *ngIf="selectedProduct">
                  <div class="col-md-12">
                    <!-- <input type="hidden" id="id" [(ngModel)]="selectedProduct.id" formControlName="id"> -->

                    <div *ngIf="newImage" class="col-sm-12">
                      <ul class="thmb">
                        <li class="col-md-12">
                          <span class="thumb-xlg thumbnail thmb-lg">
                            <img [src]="getImagePath(newImage)" alt="{{newImage.title}}" />
                          </span>
                          <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editAttrImage(image, selectedProduct)"><i
                              class="fa fa-edit"></i></button>
                        </li>
                      </ul>
                    </div>

                    <div *ngIf="canEdit && userLang == 'es'" class="form-group row">
                      <label class="col-sm-3 control-label">{{ 'Status' | translate }}</label>
                      <div class="col-sm-9">
                        <select id="selectStatus" formControlName="status" name="status" class="form-control"
                          [(ngModel)]="selectedProduct.status">
                          <option>{{ 'Select' | translate }}</option>
                          <option *ngFor="let item of statesEs; let i = index" [value]="i+1">
                            {{item}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="canEdit && userLang != 'es'" class="form-group row">
                      <label class="col-sm-3 control-label">{{ 'Status' | translate }}</label>
                      <div class="col-sm-9">
                        <select id="selectStatus2" formControlName="status" name="status" class="form-control"
                          [(ngModel)]="selectedProduct.status">
                          <option>{{ 'Select' | translate }}</option>
                          <option *ngFor="let item of statesEn; let i = index" [value]="i+1">
                            {{item}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div *ngIf="canCreate" class="form-group row">
                      <label class="col-md-3">{{ 'Image' | translate }} </label>
                      <div class="col-md-7">
                        <!-- <input id="fileImageProduct" formControlName='image' type="file" class="form-control" (change)="captureImage($event)"> -->
                        <selector-images (onChange)="captureImage($event)"></selector-images>
                      </div>
                      <div *ngIf="errorExist" class="alert alert-danger cien">{{textWarn}}</div>
                      <label style="color: #9098ab">{{ 'LOGO_MINIMUN_DIMENSION' | translate: {'height':
                        IMAGE_PRODUCT_MIN_HEIGHT, 'width': IMAGE_PRODUCT_MIN_WIDTH} }} </label>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 control-label">{{ 'Name' | translate }} <b class="reqe">*</b></label>
                      <div class="col-sm-9">
                        <input id="nameProduct" formControlName="name" type="text" class="form-control" placeholder="{{ 'Name' | translate }}"
                          required="required" [(ngModel)]="selectedProduct.name">
                      </div>
                    </div>

                    <!-- <div *ngIf="restaurants.isFromLevel" class="form-group row">
                                            <label class="col-sm-3 control-label">{{ 'Description' | translate }} </label>
                                            <div class="col-sm-9">
                                                <textarea formControlName="description" class="form-control" placeholder="{{ 'Description' | translate }}" [(ngModel)]="selectedProduct.description"></textarea>
                                            </div>
                                        </div> -->

                    <div class="form-group row">
                      <label class="col-sm-3 control-label">{{ 'Description' | translate }}</label>
                      <div class="col-sm-9">
                        <textarea formControlName="description" class="form-control" placeholder="{{ 'Description' | translate }}"
                          [(ngModel)]="selectedProduct.description"></textarea>
                      </div>
                    </div>

                    <!-- <div *ngIf="restaurants.isFromLevel" class="form-group row">
                                            <label class="col-sm-3 control-label">{{ 'CodeVideo' | translate }}</label>
                                            <div class="col-sm-9">
                                                <input id="videoProduct" formControlName="videoPath" type="text" class="form-control" placeholder="https://youtu.be/FhfLkB3XdBM"
                                                    [(ngModel)]="selectedProduct.videoPath">
                                            </div>
                                        </div> -->

                    <div *ngIf="canEdit" class="form-group row">
                      <label class="col-sm-3 control-label">{{ 'Category' | translate }}</label>
                      <div class="col-sm-9">
                        <select id="selectCategory" formControlName="category" name="category" class="form-control"
                          [(ngModel)]="selectedProduct.category">
                          <option>{{ 'Select' | translate }}</option>
                          <option *ngFor="let item of category" [value]="item.id">
                            {{item.name}}
                          </option>
                        </select>
                      </div>
                    </div>


                    <!-- <div *ngIf="restaurants.isFromLevel && canCreate" class="form-group row">
                                            <label class="col-sm-3 control-label">{{ 'Image' | translate }}</label>
                                            <div class="col-sm-9">
                                                <input id="fileProduct" type="file" class="form-control" (change)="captureImage()">
                                            </div>
                                        </div> -->


                    <div class="form-group legend">
                      <label class="control-label">{{ 'Sizes' | translate }}</label>
                      <button type="button" class="btn btn-success pull-xs-right mb-xs" (click)="addArrayLiSize(arrayLiSizes)"><i
                          class="fa fa-plus"></i></button>
                    </div>


                    <div *ngIf="cantSize && canCreate" class="row">
                      <ul id="divSizes" formGroupName="size">
                        <li *ngFor="let ctrl of cantSize; let i = index" formGroupName="size" id="liSize{{i+1}}"
                          #liSize>
                          <div class="row">
                            <div class="col-md-4">
                              {{ctrl}}:
                            </div>
                            <div class="col-md-4">
                              {{ 'Price' | translate }}:
                            </div>
                            <div class="col-md-4">
                              {{ 'ByDefault' | translate }}:
                            </div>

                          </div>
                          <div class="row">
                            <div class="col-md-4 col-xs-12">
                              <input #inputSize id="inputSize{{i+1}}" class="form-control" formControlName="nameSize"
                                placeholder="{{ctrl}}" value="{{ctrl}}">
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <input (keypress)="onlyNumbers($event, i+1, 'number')" #priceSizeCreate id="priceSize{{i+1}}"
                                class="form-control number{{i+1}}" formControlName="price" placeholder="{{ 'Price' | translate }}"
                                (keyup)="validarPunto(i+1, null, 'priceSizeCreate')">
                            </div>
                            <div class="col-md-2 col-xs-12 cntrH">
                              <input #defaultSize id="defaultSize{{i+1}}" type="radio" name="isDefault" #isChecked
                                formControlName="isDefault">
                            </div>
                            <div class="col-md-2">
                              <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                (click)="removeItemSize(inputSize.id, priceSizeCreate.id, defaultSize.id, liSize.id)"><i
                                  class="fa fa-minus"></i></button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div *ngIf="cantSize && canEdit" class="row">
                      <ul id="divSizes" formGroupName="size">
                        <li *ngFor="let ctrl of cantSize; let i = index" formGroupName="size" id="liSize{{i+1}}"
                          #liSize>
                          <div class="row">
                            <div class="col-md-4">
                              {{ 'Size' | translate }}:
                            </div>
                            <div class="col-md-4">
                              {{ 'Price' | translate }}:
                            </div>
                            <div class="col-md-4">
                              {{ 'ByDefault' | translate }}:
                            </div>

                          </div>
                          <div class="row">
                            <div class="col-md-4 col-xs-12">
                              <input id="inputSize{{i+1}}" class="form-control" formControlName="nameSize" placeholder="{{ctrl}}"
                                value="{{ctrl.name}}">
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <input (keypress)="onlyNumbers($event, i+1, 'number')" #priceSizeEdit id="priceSize{{i+1}}"
                                class="form-control number{{i+1}}" formControlName="price" value="{{ctrl.price}}"
                                placeholder="{{ 'Price' | translate }}" (keyup)="validarPunto(i+1, null, 'priceSizeEdit')">
                            </div>
                            <div class="col-md-2 col-xs-12 cntrH">
                              <input id="defaultSize{{i+1}}" type="radio" name="isDefault" formControlName="isDefault"
                                [checked]="ctrl.isRegularPrice==true">
                            </div>
                            <div class="col-md-2">
                              <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                (click)="removeItemSize(inputSize.id, priceSize.id, priceSizeEdit.id, liSize.id)"><i
                                  class="fa fa-minus"></i></button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>


                    <!-- <div *ngIf="cantSize && !restaurants.isFromLevel" class="row"> -->
                    <div *ngIf="cantSize" class="row">
                      <ul id="divSizesNew" formGroupName="size">
                        <li *ngFor="let item of arrayLiSizes; let i = index" formGroupName="size" id="liSizeNew{{i+1}}"
                          #liSizeNew>
                          <div class="row">
                            <div class="col-md-4">
                              {{ 'Size' | translate }}:
                            </div>
                            <div class="col-md-4">
                              {{ 'Price' | translate }}:
                            </div>
                            <div class="col-md-4">
                              {{ 'ByDefault' | translate }}:
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4 col-xs-12">
                              <input #inputSizeNew id="inputSizeNew{{i+1}}" class="form-control" formControlName="nameSize"
                                placeholder="{{ 'Size' | translate }}">
                            </div>
                            <div class="col-md-4 col-xs-12">
                              <input (keypress)="onlyNumbers($event, i+1, 'numberNew')" #priceSizeNew id="priceSizeNew{{i+1}}"
                                class="form-control numberNew{{i+1}}" formControlName="price" placeholder="{{ 'Price' | translate }}">
                            </div>
                            <div class="col-md-2 col-xs-12 cntrH">
                              <input #defaultSizeNew id="defaultSizeNew{{i+1}}" type="radio" name="isDefault"
                                #isChecked formControlName="isDefault">
                            </div>
                            <div class="col-md-2">
                              <button class="btn btn-danger mb-xs" type="button" title="{{ 'Delete' | translate }}"
                                (click)="removeItemSizeNew(item)"><i class="fa fa-minus"></i></button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

              </form>
            </div>
            <div class="modal-footer">
              <div class="text-xs-right">
                <button [disabled]="buttonStatus" [hidden]="buttonStatus" id="saveProductBtn" type="submit" class="btn btn-success md-xs"
                  (click)="onSubmitProduct(productForm.value, productForm.valid)">{{ 'Save' | translate }}</button>
                <button class="btn btn-gray flx" [hidden]="!buttonStatus" [disabled]="buttonStatus">{{ 'processing' |
                  translate }}&nbsp;
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para mostrar las imagenes de un producto -->
      <ng-template #showImagesProductModal>
        <div class="modal-header">
          <h4 class="modal-title text-xs-center fw-bold mt">borrar {{ 'Image' | translate }}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body">
          <div>
            <div class="row" style="    min-height: 200px;">
              <div class="col-sm-12">
                <ul class="thmb">
                  <li class="col-md-12">
                    <span class="thumb-xlg thumbnail thmb-lg">
                      <img [src]="getImagePath(newImage)" alt="{{newImage.title}}" />
                    </span>
                    <button role="button" class="btn btn-danger" title="{{ 'Delete' | translate }}" (click)="confirmDelete(image, 'image')"><i
                        class="fa fa-eraser"></i></button>
                    <!-- <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editAttrImage(image, selectedProduct)"><i class="fa fa-edit"></i></button> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- para borrar  -->
      <!-- <ng-template class="modal fade" #showImagesroductModal>
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            <h4 class="modal-title text-xs-center fw-bold mt">rrr{{ 'Image' | translate }}</h4>
                        </div>
                        <div class="modal-body bg-body-gray">
                            <div *ngIf="selectedProduct">
                                <div *ngIf="newImage" class="row">
                                    <div *ngFor="let image of newImage" class="col-sm-12">
                                        <ul class="thmb">
                                            <li class="col-md-12">
                                                <span class="thumb-xlg thumbnail thmb-lg">
                                                    <h4 *ngIf="!image.title">OpenMyTab</h4>
                                                    <h4 *ngIf="image.title">{{image.title}}</h4>
                                                    <img [src]="getImagePath(image)" alt="{{image.title}}" />
                                                </span>
                                                <button role="button" class="btn btn-danger" title="{{ 'Delete' | translate }}" (click)="confirmDelete(image, 'image')"><i class="fa fa-eraser"></i></button>
                                                <button role="button" class="btn btn-info" title="{{ 'Edit' | translate }}" (click)="editAttrImage(image, selectedProduct)"><i class="fa fa-edit"></i></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template> -->

      <!-- Modal para agregar/editar imagen de un producto -->
      <div bsModal #addImagesProductModal="bs-modal" [config]="{ignoreBackdropClick: true, keyboard: false}" class="modal fade"
        tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 *ngIf="canCreate" class="modal-title text-xs-center fw-bold mt">{{ 'AddImage' | translate }}</h4>
              <h4 *ngIf="!canCreate" class="modal-title text-xs-center fw-bold mt">{{ 'EditImage' | translate }}</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideAddImagesProductModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="form-horizontal" [formGroup]="productForm" novalidate>
                <div *ngIf="selectedProduct">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label class="col-md-3">{{ 'Image' | translate }} <b class="reqe">*</b></label>
                      <div class="col-md-7" *ngIf="!errorExist">
                        <!-- <input id="fileImageProduct" formControlName='image' type="file" class="form-control" (change)="captureImage($event)"
                          required="required" accept="image/jpeg,image/png"> -->
                          <selector-images (onChange)="captureImage($event)"></selector-images>
                      </div>
                      <div *ngIf="errorExist" class="alert alert-danger cien">{{textWarn}}</div>
                      <label style="color: #9098ab">{{ 'LOGO_MINIMUN_DIMENSION' | translate: {'height':
                        IMAGE_PRODUCT_MIN_HEIGHT, 'width': IMAGE_PRODUCT_MIN_WIDTH} }} </label>
                    </div>

                  </div>
                </div>
                <div class="modal-footer">
                  <div class="pull-right">
                    <button id="btnUploadImage" [disabled]="buttonStatusImagen" [hidden]="buttonStatusImagen" type="button"
                      class="btn btn-success mb-xs" (click)="onSubmitImageProductEdit(productForm.value, productForm.valid, selectedProduct)">
                      <span class="glyphicon glyphicon-upload"></span>{{ 'Save' | translate}}</button>
                    <button class="btn btn-gray flx" [hidden]="!buttonStatusImagen" [disabled]="buttonStatusImagen">{{
                      'processing' | translate }}&nbsp;
                      <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para mostrar video de producto -->
      <div class="modal fade" id="videoModal" data-backdrop="static">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" (click)="closeVideoModal()" aria-hidden="true">&times;</button>
              <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18"><i class="fa fa-video-camera"></i>
                {{ 'Video' | translate }}
              </h4>
            </div>
            <div class="modal-body bg-gray-lighter">
              <div *ngIf="selectedProduct">
                <!-- <iframe id="iframeVideo" width="568" height="365" class="video" [src]='videoUrl' frameborder="0" allowfullscreen></iframe> -->
              </div>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para confirmar acción eliminar -->
      <div bsModal #canDeleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-xs-center fw-bold mt label-warning" id="myModalLabel18"><i class="fa fa-exclamation-triangle"></i>
                {{ 'Caution' | translate }}
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="hideConfirmDelete(true,'canDeleteModal')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="modal-body bg-gray-lighter">
                <p class="text-xs-center">
                  {{ 'WantDelete' | translate }}
                  <strong *ngIf="section == 'product'">{{ 'Product' | translate }} ?</strong>
                  <strong *ngIf="section == 'image'">{{ 'Image' | translate }} ?</strong>
                </p>
              </div>
              <div class="modal-footer">
                <div *ngIf="itemToDelete">
                  <button type="button" class="btn btn-gray" data-dismiss="modal" aria-hidden="true" (click)="hideConfirmDelete(showImagesProductModal)">{{
                    'Cancel' | translate }}</button>
                  <button id="deleteBtn" type="button" [hidden]="processingResponse" class="btn btn-success" (click)="deleteSection(itemToDelete, $event, section)">{{
                    'Confirm' | translate }}</button>
                  <button class="btn btn-gray flx" [hidden]="!processingResponse" [disabled]="processingResponse">{{
                    'processing' | translate }}&nbsp;
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Notificaciones-->
      <!-- <simple-notifications [options]="options"></simple-notifications> -->
    </section>
  </div>
</div>
<!-- <inspectPermits></inspectPermits> -->
